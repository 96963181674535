// import {
//     DimmerIcon,
//     DwsensorIcon,
//     EnergyIcon,
//     HumidityIcon,
//     LuxIcon,
//     PoweroutletIcon,
//     SmokeIcon,
//     SwitchIcon,
//     TempIcon,
//     UltravioletIcon,
//     MotionIcon,
//     PressureIcon,
//     LowBatteryIcon,
//     LogoIcon,
//     BellIcon,
//     AmperIcon,
//     KWHIcon,
//     WattIcon,
//     VoltIcon,
// } from '../icons';

export const ITEM_ICONS_MAPPER = {
    // 'Multisensor': MotionIcon,
    // 'Dimmer': DimmerIcon,
    // 'Switch': SwitchIcon,
    // 'Door Lock': DwsensorIcon,
    // 'basic': SwitchIcon,
    // 'dwsensor': DwsensorIcon,
    // 'dw_state': DwsensorIcon,
    // 'water_leak_alarm': PressureIcon,
    // 'security_threat': SwitchIcon,
    // 'thermostat_setpoint': TempIcon,
    // 'thermostat_setpoint_heating': TempIcon,
    // 'thermostat_setpoint_cooling': TempIcon,
    // 'energy': EnergyIcon,
    // 'humidity': HumidityIcon,
    // 'lux': LuxIcon,
    // 'siren_strobe': SwitchIcon,
    // 'motion': MotionIcon,
    // 'pressure': PressureIcon,
    // 'athmo_pressure': PressureIcon,
    // 'bar_pressure': PressureIcon,
    // multisensor:
    // panicbutton:
    // 'poweroutlet': PoweroutletIcon,
    // 'smoke': SmokeIcon,
    // 'switch': SwitchIcon,
    // 'temp': TempIcon,
    // 'ultraviolet': UltravioletIcon,
    // 'dimmer': DimmerIcon,
    // 'alarm_intrusion': MotionIcon,
    // 'battery': LowBatteryIcon,
    // 'alarm_door': SwitchIcon,
    // 'door_lock': SwitchIcon,
    // 'bell_icon': BellIcon,
    // 'EMPTY_DEFAULT_ICON': LogoIcon,
    // 'electric_meter_amper': AmperIcon,
    // 'electric_meter_kwh': KWHIcon,
    // 'electric_meter_watt': WattIcon,
    // 'electric_meter_volt': VoltIcon,
};

export const SETTING_TYPE_INT = 'int';
export const SETTING_TYPE_STRING = 'string';
export const CONFIRMATION = 'confirm';
export const MOVING_CHILD_DEVICE = 'moving child device';
export const SETTING_TYPE_TEMP = 'temperature';
export const SETTING_TYPE_BOOL = 'bool';
export const SETTING_TYPE_TOKEN = 'token';
export const SETTING_TYPE_ARRAY_TOKEN = 'array.token';
export const SETTING_TYPE_RGB = 'rgb';
export const SETTING_TYPE_ACTION = 'action';
export const SETTING_TYPE_DICTIONARY = 'dictionary';
export const SETTING_OPERATION_ADDED = 'added';
export const SETTING_OPERATION_UPDATED = 'updated';
export const SETTING_OPERATION_REMOVED = 'removed';
export const REMOVE_DEVICE = 'remove_device';
export const PK_ACCOUNT_CHILD_EMPTY = 0;
export const SETTING_NAMES = { FIRST_START: 'first_start' };

export const DEVICE_SETTINGS_UPDATED = 'hub.device.setting.updated';
export const DEVICE_SETTINGS_UPDATED_DICTIONARY = 'hub.device.setting.dictionary.updated';
export const BROADCAST_DEVICE_REMOVER = 'hub.device.removed';

export const deviceSettingFormatList = [
    { name: 'Decimal', value: 'dec' },
    { name: 'Hexa', value: 'hex' },
];
export const deviceSettingSizeList = [
    { name: '1 byte', value: 1 },
    { name: '2 bytes', value: 2 },
    { name: '3 bytes', value: 3 },
    { name: '4 bytes', value: 4 },
];

export const DEVICE_DESCRIPTION = {
    CAMERA: 'camera',
    CAMERA_STREAM_NAME: 'camera_stream',
    CAMERA_STREAM_VALUE_TYPE: 'cameraStream',
};

export const NO_STREAM = 'No stream';
export const SELECTED_ELEMENT_INDEX = 0;
export const SELECTED_ELEMENT_VALUE_INDEX = 1;
export const DELAY = 3000;
export const ABSTRACT_TYPE = 'abstract';
export const TCP_MANAGER = 'tcp_manager';
export const ON = 'On';

export const OFF = 'Off';
export const OFF_LOVER_CASE = 'off';
export const ON_LOVER_CASE = 'on';
export const VALUE_TO_UNASSIGN_ROOM = 'Unassigned';
export const MAX_DEVICE_NAME_LENGTH = 128;
export const ZWAVE_CONFIGURATION = 'zwave_configuration';
export const TAB = 'Tab';
export const TRUE_STRING = 'true';
export const MENU_LIST_GROW = 'menuListGrow';
export const BOTTOM_START = 'bottom-start';
export const LEFT_TOP = 'left top';
export const LEFT_BOTTOM = 'left bottom';
export const BOTTOM = 'bottom';
export const CENTER_TOP = 'center top';
export const CENTER_BOTTOM = 'center bottom';
export const STRING = 'string';
export const OBJECT = 'object';
export const DEVICE_INFO = 'Device info';
export const ZWAVE = 'zwave';
export const SMALL = 'small';
export const PRIMARY = 'primary';
export const DEVICE_NAME = 'Device name';
export const INDEX_MISSING_ELEMENT = -1;
export const ROOM_NOT_AVAILABLE = 'Room info not available';
export const ZWAVE_NODE = 'zwave.node';
export const GREY = 'grey';
export const DEVICE_NAME_FIELD = 'device_name';
export const NMA_CONTROLLER_STATUS_ONLINE = 1;
export const DIVIDER = 'divider';
export const UNASSIGN = 'unassign';
export const ADDRESSABLE_LED_LABEL_TAG = 'addressable_led_ip_address_label';
export const EZLOPI_ADDRESSABLE_LED_PATH = '/ezlo/led/';
