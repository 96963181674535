import React from 'react';

const IcDeviceSensorNoLeak = (props) => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28 30L30 28L23.1727 21.1727L12.15 10.15L10 8L8 6L5 3L4 2L2 0L0 2L4 6V9H7L10.1555 12.1555C7.30226 15.2741 7.38375 20.1837 10.4 23.2C13.4163 26.2163 18.3259 26.2977 21.4445 23.4445L28 30ZM21.7 12C23.3557 13.6557 24.1271 15.8534 24.0142 18.0142L14.15 8.15L16 6.3L21.7 12ZM28 3H9L6 0H28V3ZM20 6L22.1 8.1C22.7 8.7 23.4 9 24.2 9H28V6H20Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcDeviceSensorNoLeak;
