import React from 'react';

export const DropDownUpIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 16 8" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 0L0 8L16 8L8 0Z" />
        </svg>
    );
};
export default DropDownUpIcon;
