import moment from 'moment';
import {
    DATETIME_FORMAT_12HR_AMPM_ISO8601,
    DATETIME_FORMAT_ISO8601_WITH_OFFSET,
    MINUTES,
    ZERO_INT,
    ZERO_STRING,
} from '../constants';

/**
 * Compares two date and time values to check if the current time is before the incoming time.
 *
 * @param {string} currentTime - The current date and time in string format (ISO 8601).
 * @param {string} incomingTime - The incoming date and time to compare with.
 * @returns {boolean} - Returns true if the current time is before the incoming time, otherwise false.
 * @example
 * const isBefore = compareDateTimes('2023-09-18T10:30:00Z', '2023-09-18T12:00:00Z');
 * // Returns true
 */
export const compareDateTimes = (currentTime, incomingTime) => {
    const currentMoment = moment(currentTime);
    const incomingMoment = moment(incomingTime);

    return currentMoment.isBefore(incomingMoment);
};

/**
 * Formats a given time in 12-hour AM/PM format.
 *
 * @param {string} currentTime - The time to format in string format (ISO 8601).
 * @returns {string} - The formatted time in 12-hour AM/PM format.
 * @example
 * const formattedTime = formatTimeInAMPM('2023-09-18T14:45:00Z');
 * // Returns '02:45 PM'
 */
export const formatTimeInAMPM = (currentTime) => {
    if (!currentTime || currentTime === ZERO_STRING || currentTime === ZERO_INT || currentTime === '') {
        return '';
    }

    const currentMoment = moment(currentTime);
    const formattedTime = currentMoment.format(DATETIME_FORMAT_12HR_AMPM_ISO8601);

    return formattedTime;
};

/**
 * Adds a specified number of minutes to the current time.
 *
 * @param {string} currentTime - The current date and time in string format (ISO 8601).
 * @param {number} minutes - The number of minutes to add.
 * @returns {string} - The updated date and time in ISO 8601 format with offset.
 * @example
 * const updatedTime = addDurationToCurrentTime('2023-09-18T10:00:00Z', 30);
 * // Returns '2023-09-18T10:30:00Z'
 */
export const addDurationToCurrentTime = (currentTime, minutes) => {
    const currentMoment = moment(currentTime);
    const updatedMoment = currentMoment.add(minutes, MINUTES);
    const payload = updatedMoment.format(DATETIME_FORMAT_ISO8601_WITH_OFFSET);

    return payload;
};

/**
 * Creates a data object for uploading installer access data.
 *
 * @param {string} type - The type of data.
 * @param {string} unique_key - The unique key associated with the data.
 * @param {string} value - The value to be stored.
 * @returns {Object} - The data object with type, public, key, and value properties.
 * @example
 * const installerData = uploadInstallerAccessData('location', 'allow_installer_access_deadline', '2023-09-18T10:30:00Z');
 * // Returns { type: 'location', public: 1, key: 'allow_installer_access_deadline', value: 2023-09-18T10:30:00Z }
 */
export const uploadInstallerAccessData = (type, unique_key, value) => {
    const data = {
        type: type,
        public: 1,
        key: unique_key,
        value: value,
    };

    return data;
};

export default {
    compareDateTimes,
    addDurationToCurrentTime,
    uploadInstallerAccessData,
};
