import { apiGetSubscriptionData, apiSetDataToKvs } from '../api/apiScenes';
import { toast } from 'react-toastify';
import { TOAST_TYPE } from '../components/Toast';
import { SUCCESS } from '../constants/MeshbotConstant';
import { CloudMeshbotActions } from './CloudMeshbotActions';
import {
    parseData,
    uploadSubscriptionData,
} from '../containers/Ezlo/EzloRule/EditForm/RuleSettings/components/PAAS/utils';

const actions = {
    getNucalSubscriptionData: (subscription_id, trigger_id) => async (dispatch) => {
        const subscriptionData = await apiGetSubscriptionData(subscription_id);
        if (subscriptionData.data?.data?.list?.[0]?.value && subscriptionData.data?.status === SUCCESS) {
            const decodedSubscriptionData = atob(subscriptionData.data?.data?.list[0]?.value);
            const parseSubscriptionData = parseData(decodedSubscriptionData);

            dispatch(
                CloudMeshbotActions.updateCloudNucalSubscriptionValueFromKvs(
                    parseSubscriptionData[subscription_id],
                    trigger_id,
                ),
            );
        }
    },

    checkNucalSubscriptionInKvsExists: (subscription_id, trigger_id) => async (dispatch) => {
        const subscriptionData = await apiGetSubscriptionData(subscription_id);
        if (subscriptionData.data.status === SUCCESS) {
            dispatch(
                CloudMeshbotActions.updateCloudNucalSubscriptionInKvsExists(subscriptionData.data.data, trigger_id),
            );
        }
    },

    handleUploadSubscriptionData: (data, id) => async () => {
        const kvsSetPayload = uploadSubscriptionData(data, id);
        apiSetDataToKvs(kvsSetPayload).catch((err) => {
            toast(err, { type: TOAST_TYPE.ERROR });
        });
    },
};

export default actions;
