import { DOMAIN_PARTNER_UUID, LOCALSTORAGE_TOP_LEVEL_DOMAIN } from '../../../constants/localStorageKeys';

/**
 * @returns {boolean} true if top level domain else return false.
 * */
export const getTopLevelDomain = () => {
    return Boolean(localStorage.getItem(LOCALSTORAGE_TOP_LEVEL_DOMAIN));
};

/**
 * @returns {boolean} true if uuid domain else return false.
 * */
export const getUuidDomain = () => {
    return Boolean(localStorage.getItem(DOMAIN_PARTNER_UUID));
};

/**
 * @returns {boolean} true if have uuid or partner domain else return false.
 * */
export const checkPartnerDomain = () => {
    const isTLD = getTopLevelDomain();
    const isPartnerUuid = getUuidDomain();

    return isTLD || isPartnerUuid;
};
