import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { labelsSelector } from '../../utils';
import useLabelsActions from '../../../../../hooks/useLabelsActions';
import { SELECTED_LABEL_UUID_FOR_FILTERING } from '../../../../../reducers/labels';

const useSelectedLabelForFiltering = () => {
    const { setLabelUuidForFiltering } = useLabelsActions();
    const labelsUiState = useSelector(labelsSelector);
    const selectedLabelUuid = labelsUiState[SELECTED_LABEL_UUID_FOR_FILTERING];

    const handlerClickLabel = (labelUuid) => {
        if (selectedLabelUuid === labelUuid) {
            setLabelUuidForFiltering(null);

            return;
        }

        setLabelUuidForFiltering(labelUuid);
    };

    // Clearing the state of the filter in before unmounting the component
    useEffect(() => () => setLabelUuidForFiltering(null), []);

    return {
        handlerClickLabel,
        selectedLabelUuid,
    };
};

export default useSelectedLabelForFiltering;
