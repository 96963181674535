import Axios from 'axios';
import {
    GET_ADDRESS_ELEMENT_URL,
    GET_ALL_INSTALLATIONS_URL,
    GET_GET_OR_UPDATE_MAILING_BILLING_ADDRESSES_URL,
    UPDATE_INSTALLATION_URL,
} from '../constants/URLs';
import { simpleConfig } from './config';
import { ADDRESS_ELEMENT_TYPES } from '../constants/AccountInfo';

export const apiGetInstallations = async (PK_Account) => {
    const installationsData = await Axios.get(GET_ALL_INSTALLATIONS_URL(PK_Account), simpleConfig());

    if (installationsData.data.Installations) {
        return installationsData.data.Installations;
    }
};
export const apiGetMailingBillingAddresses = async (PK_Account) => {
    const mailingBillingData = await Axios.get(
        GET_GET_OR_UPDATE_MAILING_BILLING_ADDRESSES_URL(PK_Account),
        simpleConfig(),
    );

    if (mailingBillingData.data) {
        return mailingBillingData.data;
    }
};
export const apiUpdateInstallation = async (PK_Account, installationData) => {
    const { PK_Installation, payload } = installationData;

    return await Axios.put(UPDATE_INSTALLATION_URL(PK_Account, PK_Installation), payload, simpleConfig());
};
export const apiUpdateMailingBillingAddresses = async (PK_Account, addressesData) => {
    return await Axios.put(GET_GET_OR_UPDATE_MAILING_BILLING_ADDRESSES_URL(PK_Account), addressesData, simpleConfig());
};

export const apiGetCountries = async () => {
    const requestResponse = await Axios.get(GET_ADDRESS_ELEMENT_URL(ADDRESS_ELEMENT_TYPES.COUNTRIES), simpleConfig());

    return await requestResponse?.data?.Country;
};

export const apiGetRegions = async (PK_Country) => {
    return await Axios.get(GET_ADDRESS_ELEMENT_URL(ADDRESS_ELEMENT_TYPES.REGIONS, PK_Country), simpleConfig());
};

export const apiGetCities = async (PK_Country, PK_Region) => {
    return await Axios.get(
        GET_ADDRESS_ELEMENT_URL(ADDRESS_ELEMENT_TYPES.CITIES, PK_Country, PK_Region),
        simpleConfig(),
    );
};
