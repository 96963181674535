import React from 'react';

export const IcVisualLocations = () => {
    return (
        <svg width="104" height="109" viewBox="0 0 104 109" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M78.1115 76.9979H25.8885C19.8282 76.9979 14.288 80.422 11.5777 85.8425L0 108.998H104L92.4223 85.8425C89.712 80.422 84.1718 76.9979 78.1115 76.9979Z"
                fill="#3E46AE"
            />
            <path
                d="M92.4222 85.8425C89.712 80.422 84.1717 76.9979 78.1114 76.9979H54.3198L52.0002 98.0539L62.9443 108.998H104L92.4222 85.8425Z"
                fill="black"
            />
            <path
                d="M80.5027 69.5515C87.7969 62.2566 92.3088 52.1799 92.3088 41.0491C92.3088 29.9177 87.7969 19.8404 80.5027 12.5461C73.2084 5.25187 63.1311 0.73999 52.0003 0.73999C40.8689 0.73999 30.7922 5.25187 23.4973 12.5461C16.203 19.8404 11.6912 29.9177 11.6912 41.0491C11.6912 52.1799 16.203 62.2566 23.4973 69.5515H23.4979H23.4973L52.0003 98.0539L80.5027 69.5515H80.502H80.5027Z"
                fill="#FE5C15"
            />
            <path
                d="M80.5027 69.5515C87.7969 62.2566 92.3088 52.1799 92.3088 41.0491C92.3088 29.9177 87.7969 19.8404 80.5027 12.5461C73.2084 5.25187 63.1311 0.73999 52.0003 0.73999C40.8689 0.73999 30.7922 5.25187 23.4973 12.5461C16.203 19.8404 11.6912 29.9177 11.6912 41.0491C11.6912 52.1799 16.203 62.2566 23.4973 69.5515H23.4979H23.4973L52.0003 98.0539L80.5027 69.5515H80.502H80.5027Z"
                fill="#FE5C15"
            />
            <path
                d="M52 65.0488C65.2548 65.0488 76 54.3037 76 41.0488C76 27.794 65.2548 17.0488 52 17.0488C38.7452 17.0488 28 27.794 28 41.0488C28 54.3037 38.7452 65.0488 52 65.0488Z"
                fill="#E82E10"
            />
            <path
                d="M60 41.0488C60 43.258 59.1046 45.258 57.6569 46.7057C56.2091 48.1534 54.2091 49.0488 52 49.0488C49.7909 49.0488 47.7909 48.1534 46.3431 46.7057C44.8954 45.258 44 43.258 44 41.0488C44 38.8397 44.8954 36.8397 46.3431 35.392C47.7909 33.9443 49.7909 33.0488 52 33.0488C54.2091 33.0488 56.2091 33.9443 57.6569 35.392C59.1046 36.8397 60 38.8397 60 41.0488Z"
                fill="white"
            />
        </svg>
    );
};

export default IcVisualLocations;
