import hash from '../../../../../constants/uniqueHash';
import { CUSTOM_PREFIX, SIMPLE_CODE_FUNCTIONS, STRING, VALUE_TYPE } from './constants';
import { convertCodeFromArray } from './utils';
import {
    STRINGIFY_FUNCTION,
    STRINGIFY_STR,
} from '../../../EzloRule/EditForm/RuleSettings/components/PAAS/paas-constants';

export const mapTypes = {
    SIMPLE: 'Simple',
    ADVANCED: 'Advanced',
};
export const getItemTemplate = () => ({
    uuid: hash(),
    capability: '',
    value: `${JSON_FUNCTION_PATTERN}`,
});
export const listOfFunctions = [SIMPLE_CODE_FUNCTIONS.SLICE, SIMPLE_CODE_FUNCTIONS.FIND, SIMPLE_CODE_FUNCTIONS.JSON];
export const SAVE_TO_LOCAL_VARIABLE = 'Save output to local variable';
export const JSON_FUNCTION_PATTERN = 'raw_json';
export const generateCodeStringTemplate = (value, forLocalRun) => {
    const parsedValue = convertCodeFromArray(value);

    return forLocalRun
        ? `${STRINGIFY_FUNCTION} var output = {state: ${parsedValue}}; output; `
        : `// Simple \n ${STRINGIFY_FUNCTION} var output = {state: ${parsedValue}}; output; `;
};
export const convertSimpleArrayToCodeFormatting = (simpleCodeState) => {
    return simpleCodeState.map((item) => {
        if (!item.capability?.startsWith(CUSTOM_PREFIX)) {
            item.capability = `${CUSTOM_PREFIX}${item.capability}`;
        }

        return [item.capability, VALUE_TYPE, `${STRINGIFY_STR}${item.value})`, STRING];
    });
};
export const generateCodeStringTemplateFromCodeArray = (codeArray) => {
    const convertedArray = convertSimpleArrayToCodeFormatting(codeArray);
    const parsedValue = convertCodeFromArray(convertedArray);

    return `// Simple \n ${STRINGIFY_FUNCTION} var output = {state: ${parsedValue}}; output; `;
};
export const createDataForTableResult = (name, value) => {
    return { name, value };
};
export const CLOUD_VARIABLE_INITIAL_STATE = {
    code: '',
    codeArray: [],
    isSimpleDisabled: false,
    mapType: mapTypes.SIMPLE,
    selectedSaveOutput: false,
};
