import React from 'react';

const IcActionsAvatar = (props) => {
    return (
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M36.75 21C36.75 25.9 32.9 29.75 28 29.75C23.1 29.75 19.25 25.9 19.25 21C19.25 16.1 23.1 12.25 28 12.25C32.9 12.25 36.75 16.1 36.75 21ZM36.75 31.5L35.875 34.3C35.35 35.7 34.125 36.75 32.55 36.75H23.45C21.875 36.75 20.65 35.7 20.125 34.3L19.25 31.5C16.1 31.5 13.475 33.6 12.95 36.575L8.75 56H47.25L43.05 36.75C42.525 33.6 39.725 31.5 36.75 31.5Z"
                fill="#90A0AF"
            />
        </svg>
    );
};

export default IcActionsAvatar;
