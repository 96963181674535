import { CLOUD_TOKEN, HASH, HASH_CS, IDENTITY, IDENTITY_SIGNATURE, NAME, PK_User } from 'constants/localStorageKeys';
import { apiGetCloudToken } from 'api/apiAuth';
import { bugsnagNotify } from 'containers/ErrorBoundary/utils';
import { apiLogin } from './network/loginApi';
import { validateGetJwtTokenResponse, validateLoginApiResponse, validateParamsForLoginApi } from './utils';

/**
 * Retrieves login data from local storage.
 *
 * @returns {Object} An object containing login data properties (name, hash, and hashCS).
 */
export const getLoginData = () => ({
    name: localStorage.getItem(NAME),
    hash: localStorage.getItem(HASH),
    hashCS: localStorage.getItem(HASH_CS),
});

/**
 * Sets MMS tokens in local storage.
 *
 * @param {string} Identity - The MMS Identity token to be stored.
 * @param {string} IdentitySignature - The MMS Identity Signature token to be stored.
 */
export const setMmsTokens = (Identity, IdentitySignature) => {
    localStorage.setItem(IDENTITY, Identity);
    localStorage.setItem(IDENTITY_SIGNATURE, IdentitySignature);
};

/**
 * Sets the cloud token in local storage.
 *
 * @param {string} jwtToken - The JWT token from the cloud service to be stored.
 */
export const setCloudToken = (jwtToken) => {
    localStorage.setItem(CLOUD_TOKEN, jwtToken);
};

/**
 * Retrieves the cloud JWT token by making an API call and validating the response.
 *
 * @throws {Error} Throws an error if there is an issue with the API call or the response is invalid.
 * @returns {Promise<string>} A promise that resolves to the cloud JWT token.
 */
export const getCloudJwtToken = async () => {
    try {
        const PKUser = +localStorage.getItem(PK_User);
        const response = await apiGetCloudToken(PKUser);
        validateGetJwtTokenResponse(response);

        return response.data.token;
    } catch (e) {
        /**
         * Notify Bugsnag about the error and rethrow it.
         *
         * @param {Error} e - The error object.
         * @param {Object} options - Additional options for Bugsnag notification.
         * @param {string} options.fn - The name of the function where the error occurred.
         */
        bugsnagNotify(e, { fn: 'getCloudJwtToken' });
        throw e;
    }
};

/**
 * Retrieves and sets the cloud JWT token in local storage.
 *
 * @throws {Error} Throws an error if there is an issue with the API call or the response is invalid.
 * @returns {Promise<string>} A promise that resolves to the cloud JWT token.
 */
export const getSetCloudToken = async () => {
    const jwtToken = await getCloudJwtToken();
    setCloudToken(jwtToken);

    return jwtToken;
};

/**
 * Logs in a user by making an API call and validating the response.
 *
 * @param {string} userName - The username of the user.
 * @param {string} hash - The password hash of the user.
 * @param {string} hashCS - The password hash checksum of the user.
 * @throws {Error} Throws an error if there is an issue with the API call or the response is invalid.
 * @returns {Promise<object>} A promise that resolves to the response object from the login API.
 */
export const loginUser = async (userName, hash, hashCS) => {
    try {
        validateParamsForLoginApi(userName, hash, hashCS);
        const response = await apiLogin(userName, hash, hashCS);
        validateLoginApiResponse(response);

        return response;
    } catch (e) {
        /**
         * Notify Bugsnag about the error and rethrow it.
         *
         * @param {Error} e - The error object.
         * @param {Object} options - Additional options for Bugsnag notification.
         * @param {string} options.fn - The name of the function where the error occurred.
         * @param {Array} options.arg - The arguments passed to the function.
         */
        if (e.response && e.response.status === 404) {
            throw e;
        } else {
            bugsnagNotify(e, { fn: 'loginUser', arg: [userName, hash, hashCS] });
            throw e;
        }
    }
};
