import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import labelsSlice from '../../reducers/labels';

const useLabelsActions = () => {
    const dispatch = useDispatch();

    return bindActionCreators(labelsSlice.actions, dispatch);
};

export default useLabelsActions;
