/**
 * Initial state structure for controller in a group.
 * Based on the legacy 'data' structure in 'ezlo' reducer.
 * */
export const buildInitialGroupControllerState = () => ({
    isConnecting: false,
    isConnected: false,
    info: {},
    items: [],
    devices: [],
    gateways: [],
    rules: [],
    settings: [],
    scenes: {
        list: [],
        abstracts: [],
        currentScene: {},
    },
    properties: {
        ezloLocation: {
            longitude: null,
            latitude: null,
            timeOffset: null,
        },
    },
    favorites: {
        devices: [],
        items: [],
        rules: [],
    },
});

/**
 * Controllers group validation.
 * Required array item fields: uuid
 * @param {array} controllers - array of controllers
 * @returns {boolean} is valid result
 * */
export const isControllersGroupValid = (controllers) => {
    if (Array.isArray(controllers) && controllers.length > 0) {
        let isValid = true;
        for (const controller of controllers) {
            if (!controller) {
                isValid = false;
                break;
            }
        }

        return isValid;
    }

    return false;
};

/**
 * Returns array of uuid values from group controllers array
 * @param {array} controllers - group controllers
 * @returns {array} array of uuids, e.g. ['a27947f0-edcc-...', 'a2794830-fedc-...'];
 * */
export const extractUuidsFromGroupControllers = (controllers) => {
    if (!isControllersGroupValid(controllers)) {
        throw new Error('#extractUuidsFromGroupControllers, invalid params: controllers is not valid');
    }

    return controllers.map((controller) => controller.uuid);
};

/**
 * Returns ezlo.data object based on controller get report response
 * @param {Object} controllerGetReportRes - controller get report response
 * @param {array} controllers - group controllers
 * @returns {Object} mapped controllers state
 * */
export const mapControllerGetReportToGroupState = (controllerGetReportRes, controllers) => {
    let mappedResult = null;
    if (controllers) {
        mappedResult = controllers.reduce((acc, controller) => {
            // 1. set default state for every controller
            const serial = controller.id;
            acc[serial] = buildInitialGroupControllerState();
            acc[serial].uuid = controller.uuid;
            // 2. add information from report response if available
            // (sometimes cloud do not send any controller report, need to clarify why)
            if (controllerGetReportRes && controllerGetReportRes.data && controllerGetReportRes.data[controller.uuid]) {
                const controllerReport = controllerGetReportRes.data[controller.uuid];
                // devices
                acc[serial].devices = (controllerReport.device_list && controllerReport.device_list.raw) || [];
                // items (based on legacy 'getEzloData' workaround)
                acc[serial].items =
                    (controllerReport.item_list &&
                        controllerReport.item_list.raw.map((item) => {
                            // readonly item
                            const updatedItem = { ...item };
                            if (updatedItem.name === 'rgbcolor') {
                                updatedItem.show = true;
                            }

                            if (updatedItem.name === 'siren_strobe') {
                                updatedItem.isSetting = true;
                            }

                            return updatedItem;
                        })) ||
                    [];
                // rules
                acc[serial].rules = (controllerReport.scene_list && controllerReport.scene_list.raw) || [];
            }

            return acc;
        }, {});
    }

    return mappedResult;
};

/**
 * Returns first connected controller in group data object.
 * If all controllers are offline then return first controller in a group data
 * @param {string|number} data - ezlo 'data' value, object with the following structure
 * {'92000001': {...controller}, '100000745': {...otherController}};
 * @returns {Object} controller
 * */
export const getDefaultControllerInGroupData = (data) => {
    if (data) {
        const connectedController = Object.values(data).find(
            (value) => value.isConnected && value.rules && value.serial,
        );

        return connectedController ? connectedController.serial : Object.keys(data)[0];
    }
};

/**
 * Returns controller by serial number in group data object
 * @param {string|number} serial - controller serial number
 * @param {string|number} data - ezlo 'data' value, object with the following structure
 * {'92000001': {...controller}, '100000745': {...otherController}};
 * @returns {Object} controller
 * */
export const getControllerBySerialInGroupData = (serial, data) => {
    if (serial && data) {
        const unifiedSerial = typeof serial === 'number' ? serial.toString() : serial;

        return Object.values(data).find((controller) => controller.serial === unifiedSerial);
    }
};

/**
 * Returns first connected controller from the controllers array.
 * In case if all controllers offline, return first of them.
 * @param {array} controllers - array of controllers
 * @returns {Object} default controller
 * */
// export const getDefaultControllerFromArray = (controllers) => {
//     if (controllers && controllers.length > 0) {
//         const connectedController = controllers.find((c) => c.NMAControllerStatus === 1);
//         return connectedController ? connectedController : controllers[0];
//     }
// };

//Hidden for task 3085 called "Remove grouping of hubs on UI".
// /**
//  * Check in controller valid.
//  * Required properties: NMAControllerStatus, PK_Device, NMAUuid
//  * @param {Object} controller - controller
//  * @returns {boolean} is valid result
//  * */
// const isControllerValid = (controller) => {
//     return !!(controller && controller.NMAControllerStatus && controller.PK_Device && controller.NMAUuid);
// };

//Hidden for task 3085 called "Remove grouping of hubs on UI".
// /**
//  * Wrap controller to the group controllers array.
//  * @param {Object} controller - controller
//  * @returns {array} default controller
//  * */
// export const wrapControllerToGroupControllers = (controller) => {
//     if (!isControllerValid(controller)) {
//         throw new Error('#wrapControllerToGroupControllers, invalid params, controller required!');
//     }

//     return [{ online: controller.NMAControllerStatus, id: controller.PK_Device, uuid: controller.NMAUuid }];
// };

// The function changed for task 3085 called "Remove grouping of hubs on UI".
/**
 * The function checks the presence of required controller parameter.
 * @param {Object} controller - controller
 * @returns {boolean} is validation result
 * */
export const isControllerValid = (controller) => {
    return !!controller?.PK_Device;
};

// The function changed for task 3085 called "Remove grouping of hubs on UI".
/**
 * The function emulates a group of all account's controllers.
 * @param {array} controllers - all account's controllers
 * @returns {array} emulated group of all account's controllers
 * */
export const wrapControllersToGroupControllers = (controllers) => {
    try {
        const result = controllers.map((controller) => {
            if (!isControllerValid(controller)) {
                throw new Error('#wrapControllersToGroupControllers: Invalid controller parameters.');
            }

            return {
                online: controller.NMAControllerStatus,
                id: controller.PK_Device,
                uuid: controller.NMAUuid,
            };
        });

        return result;
    } catch (error) {
        // eslint-disable-next-line
        console.error(error);
    }
};
