import React from 'react';

export const IcVisualsSoftwareUpgrade = (props) => {
    return (
        <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M85.2791 40H115.721L122.178 63.0769L144.779 57.0769L160 83.3846L143.395 100L160 117.077L144.779 143.385L122.178 137.385L115.721 160H85.2791L78.8217 136.923L56.2209 142.923L41 116.615L57.6047 100L41 83.3846L56.2209 56.6154L79.2829 62.6154L85.2791 40Z"
                fill="#3E46AE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M146.697 96.6966L143.395 100L160 117.077L144.779 143.385L122.178 137.385L115.721 160H105L64 119L79 110.5L89.5 78L126 76L146.697 96.6966Z"
                fill="#1F2581"
            />
            <path
                d="M77.0306 94.6044C78.147 87.3723 82.4119 80.6917 89.2391 76.75C96.0662 72.8083 103.984 72.4552 110.806 75.1044"
                stroke="#FE5C15"
                strokeWidth="16"
                strokeMiterlimit="10"
            />
            <path
                d="M114.239 120.051C126.192 113.15 130.291 97.8537 123.39 85.9007C120.43 80.7749 115.927 77.0935 110.805 75.1045"
                stroke="#50C1D6"
                strokeWidth="16"
                strokeMiterlimit="10"
            />
            <path
                d="M84.5844 116.604C84.0654 116.122 83.5655 115.569 83.0656 115.016C81.9165 113.754 80.9479 112.389 80.0885 110.901C77.1291 105.775 76.1926 100.034 77.0307 94.6045"
                stroke="#00B491"
                strokeWidth="16"
                strokeMiterlimit="10"
            />
            <path
                d="M94.3109 101.534L87.1554 124.141L64 119.034L73.0933 113.784L79.1554 110.284L85.2176 106.784L94.3109 101.534Z"
                fill="white"
            />
        </svg>
    );
};
