import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { languageService } from '../../../services/language';
import { getLangTranslate } from '../utils';

export const useTranslate = () => {
    const locals = useSelector((state) => state?.ezloCustomization?.locals);
    const languageKey = languageService.getLanguageKey();
    const { t: translate } = useTranslation();

    const t = (key) => {
        const translateValue = getLangTranslate(locals, languageKey, key, translate);

        return translateValue;
    };

    return { t, languageKey };
};
