import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';

import { getIsLocalSceneStoppingInitiatedByIds } from 'components/MeshbotRun/utils';
import useFeatureSupportByHubAdvancedScenes from '../useFeatureSupportByHubAdvancedScenes';
import { HUB_ADVANCED_SCENES_VERSION_SUPPORTING_HUB_SCENES_STOP } from 'services/hub';
import { SCENE_RUN_STATUSES } from 'constants/MeshbotConstant';
import { setIsSceneStoppingInitiated } from 'reducers/trackRunScenes';
import { MeshBotAction } from 'actions';

const useLocalMeshBotRunState = ({ id, serial, isDisabled, status, runScene }) => {
    const dispatch = useDispatch();
    const isSceneStoppingInitiated = useSelector(({ trackRunScenes }) =>
        getIsLocalSceneStoppingInitiatedByIds(trackRunScenes, id, serial),
    );
    const isHubSupportedStopScene = useFeatureSupportByHubAdvancedScenes(
        serial,
        HUB_ADVANCED_SCENES_VERSION_SUPPORTING_HUB_SCENES_STOP,
    );

    useEffect(() => {
        if (isSceneStoppingInitiated && status !== SCENE_RUN_STATUSES.STARTED) {
            dispatch(setIsSceneStoppingInitiated({ hubSerial: serial, sceneId: id, value: false }));
        }
    }, [status, isSceneStoppingInitiated]);

    const handleButtonClick = useCallback(() => {
        runScene();
        if (status === SCENE_RUN_STATUSES.STARTED) {
            dispatch(MeshBotAction.stopScene(serial, id));
        }
    }, [serial, id, status, runScene]);

    return {
        handleButtonClick,
        isButton: !status || (status === SCENE_RUN_STATUSES.STARTED && !isSceneStoppingInitiated),
        isButtonDisabled:
            isDisabled ||
            isSceneStoppingInitiated ||
            (!isHubSupportedStopScene && status === SCENE_RUN_STATUSES.STARTED),
    };
};
export default useLocalMeshBotRunState;
