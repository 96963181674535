import React from 'react';

const IcDeviceMiscGarageDoor2 = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
            <path
                d="M26.8 7.9L16 2L5.2 7.9C3.9 8.6 3 10 3 11.5V25C3 26.7 4.3 28 6 28V12C6 10.9 6.9 10 8 10H24C25.1 10 26 10.9 26 12V28C27.7 28 29 26.7 29 25V11.5C29 10 28.1 8.6 26.8 7.9Z"
                fill="#1C1E21"
            />
            <path d="M21 28H11C9.3 28 8 26.7 8 25H24C24 26.7 22.7 28 21 28Z" fill="#1C1E21" />
            <path d="M21 22H11C9.3 22 8 20.7 8 19H24C24 20.7 22.7 22 21 22Z" fill="#1C1E21" />
            <path d="M21 16H11C9.3 16 8 14.7 8 13H24C24 14.7 22.7 16 21 16Z" fill="#1C1E21" />
        </svg>
    );
};

export default IcDeviceMiscGarageDoor2;
