import React from 'react';

const IcActionsController = (props) => {
    return (
        <svg width="30" height="16" viewBox="0 0 30 16" fill="none" {...props}>
            <path
                d="M25.4 15.7H4.6C3.6 15.7 2.7 15.2 2.1 14.4L1 12.7L0 5.69995H30L29 12.7L27.9 14.4C27.3 15.2 26.4 15.7 25.4 15.7Z"
                fill="black"
            />
            <path
                d="M15 0C10.6 0 6.2 0.2 1.8 0.6C0.8 0.7 0 1.6 0 2.6V2.8H30V2.6C30 1.6 29.2 0.7 28.2 0.6C23.8 0.2 19.4 0 15 0Z"
                fill="black"
            />
        </svg>
    );
};

export default IcActionsController;
