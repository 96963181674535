import React from 'react';

const IcDeviceSensorSmoke = (props) => {
    return (
        <svg width="23" height="27" viewBox="0 0 23 27" fill="#1C1E21" {...props}>
            <path d="M19.2 7.8L16.25 4.9C17.22 5.85 17.52 9.03 14.79 10.34C11.82 11.76 5.25 9 11.25 0L3.3 7.8C1.26 9.83 0 12.64 0 15.75C0 18.86 1.26 21.67 3.3 23.7C4.33 24.73 5.55 25.55 6.91 26.12C5.88 25.05 5.25 23.6 5.25 22C5.25 20.14 6.09 18.49 7.42 17.39C8.05 20.03 10.42 22 13.25 22C14.71 22 16.04 21.48 17.08 20.61C17.19 21.06 17.25 21.52 17.25 22C17.25 23.6 16.62 25.05 15.6 26.13C16.96 25.56 18.18 24.73 19.21 23.71C21.25 21.67 22.51 18.86 22.51 15.76C22.5 12.64 21.24 9.83 19.2 7.8Z" />
        </svg>
    );
};

export default IcDeviceSensorSmoke;
