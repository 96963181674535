import React from 'react';

export const TriggerEvent = () => (
    <svg viewBox="0 0 32 32" fill="none" className="triggerEvent">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.6618 23.738V26.1191L27.6064 21.9522L21.6618 17.7857V20.1665H20.4124C18.8973 20.1665 17.5021 19.3417 16.7718 18.014C14.8318 14.4868 11.917 9.18711 11.917 9.18711C11.6033 8.61625 11.0035 8.26196 10.3524 8.26196H3.80469V11.8334H9.2964L13.6427 19.7351C15.0004 22.2043 17.5947 23.738 20.4124 23.738H21.6618Z"
            fill="#1C1E21"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.909 17.2351L12.5995 20.3089C12.713 20.5154 12.8341 20.7166 12.9627 20.9117L11.917 22.8129C11.6033 23.3837 11.0035 23.738 10.3524 23.738H3.80469V20.1666H9.2964L10.909 17.2351ZM14.3687 11.1746C15.8195 9.35257 18.0367 8.262 20.4124 8.262H21.6618V5.88086L27.6064 10.0477L21.6618 14.2143V11.8334H20.4124C18.8973 11.8334 17.5021 12.6583 16.7718 13.986L16.3435 14.7649L14.3687 11.1746Z"
            fill="#1C1E21"
        />
    </svg>
);

export default TriggerEvent;
