import {
    EXCLUDED_BUTTON,
    INCLUDED_BUTTON,
    INHERITED_BUTTON,
} from '../containers/Ezlo/EzloDeviceGroups/LanguageTokensConstants';

export const DEVICE_GROUP_CATEGORY = 'category';
export const DEVICE_GROUP_SUBCATEGORY = 'subcategory';
export const DEVICE_GROUP_DEVICE_ITEM = 'device';
export const DEVICE_GROUP_OTHERS_SUBCATEGORY = 'Others';
export const DEVICE_GROUP_OTHERS_SUBCATEGORY_ID = '_others';

export const NOT_EXIST_INDEX = -1;

export const DEVICE_GROUP_TREE_ITEM_TYPES = {
    ROOT: 'ROOT',
    CATEGORY: 'CATEGORY',
    SUBCATEGORY: 'SUBCATEGORY',
    DEVICE: 'DEVICE',
};

export const NAMES_OF_IDS = {
    GARAGE_DOOR: 'garage_door',
    DOORBELL: 'doorbell',
    HVAC: 'hvac',
    INDOOR_CAM: 'indoor_cam',
    OUTDOOR_CAM: 'outdoor_cam',
    DOORBELL_CAM: 'doorbell_cam',
    WINDOW_COV: 'window_cov',
    SMOKE: 'smoke',
    MOISTURE: 'moisture',
    GENERIC_IO: 'generic_io',
};

export const NAMES_OF_SUBCATEGORIES = {
    GARAGE_DOOR: 'Garage Door',
    DOORBELL: 'Doorbell',
    HVAC: 'HVAC',
    INDOOR: 'Indoor',
    OUTDOOR: 'Outdoor',
    WINDOW_COVERING: 'Window Covering',
    MOISTURE: 'Moisture',
    IR_TRANSMITTER: 'IR Transmitter',
    GENERIC_IO: 'Generic I/O',
};

export const NAMES_OF_CATEGORIES = {
    GARAGE_DOOR: 'Garage Door',
    DOORBELL: 'Doorbell',
    HVAC: 'HVAC',
    WINDOW_COVERING: 'Window Covering',
    IR_TRANSMITTER: 'IR Transmitter',
    GENERIC_IO: 'Generic I/O',
};

export const DEVICE_GROUP_TREE_ITEM = {
    ROOT: {
        TYPE: 'ROOT',
        STATES: {
            DEFAULT: 'ROOT.DEFAULT',
        },
    },
    CATEGORY: {
        TYPE: 'CATEGORY',
        STATES: {
            EXCLUDED: 'CATEGORY.EXCLUDED',
            INCLUDED: 'CATEGORY.INCLUDED',
        },
    },
    SUBCATEGORY: {
        TYPE: 'SUBCATEGORY',
        STATES: {
            INHERITED: 'SUBCATEGORY.INHERITED',
            INCLUDED: 'SUBCATEGORY.INCLUDED',
            INHERITED_INCLUDED: 'SUBCATEGORY.INHERITED_INCLUDED',
        },
    },
    DEVICE: {
        TYPE: 'DEVICE',
        STATES: {
            INHERITED: 'DEVICE.INHERITED',
            INCLUDED: 'DEVICE.INCLUDED',
            INHERITED_INCLUDED: 'DEVICE.INHERITED_INCLUDED',
            EXCLUDED: 'DEVICE.EXCLUDED',
        },
    },
};

export const DEVICES_TREE_OBJECT = {
    _ui: {
        type: DEVICE_GROUP_TREE_ITEM.ROOT.TYPE,
        state: DEVICE_GROUP_TREE_ITEM.ROOT.STATES.DEFAULT,
    },
    categories: {
        dimmable_light: {
            id: 'dimmable_light',
            category_name: 'Dimmable Light',
            _ui: {
                type: DEVICE_GROUP_TREE_ITEM.CATEGORY.TYPE,
                state: DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.EXCLUDED,
            },
            subcategories: {
                dimmable_bulb: {
                    id: 'dimmable_bulb',
                    subcategory_name: 'Bulb',
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                dimmable_plugged: {
                    id: 'dimmable_plugged',
                    subcategory_name: 'Plugged',
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                dimmable_in_wall: {
                    id: 'dimmable_in_wall',
                    subcategory_name: 'In Wall',
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                dimmable_colored: {
                    id: 'dimmable_colored',
                    subcategory_name: 'RGB',
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                _others: {
                    id: DEVICE_GROUP_OTHERS_SUBCATEGORY_ID,
                    subcategory_name: DEVICE_GROUP_OTHERS_SUBCATEGORY,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
            },
        },
        switch: {
            id: 'switch',
            category_name: 'Switch',
            _ui: {
                type: DEVICE_GROUP_TREE_ITEM.CATEGORY.TYPE,
                state: DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.EXCLUDED,
            },
            subcategories: {
                interior_plugin: {
                    id: 'interior_plugin',
                    subcategory_name: 'Interior',
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                exterior_plugin: {
                    id: 'exterior_plugin',
                    subcategory_name: 'Exterior',
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                in_wall: {
                    id: 'in_wall',
                    subcategory_name: 'In Wall',
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                refrigerator: {
                    id: 'refrigerator',
                    subcategory_name: 'Refrigerator',
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                garage_door: {
                    id: NAMES_OF_IDS.GARAGE_DOOR,
                    subcategory_name: NAMES_OF_SUBCATEGORIES.GARAGE_DOOR,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                doorbell: {
                    id: NAMES_OF_IDS.DOORBELL,
                    subcategory_name: NAMES_OF_SUBCATEGORIES.DOORBELL,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                valve: {
                    id: 'valve',
                    subcategory_name: 'Valve',
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                relay: {
                    id: 'relay',
                    subcategory_name: 'Relay',
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                _others: {
                    id: DEVICE_GROUP_OTHERS_SUBCATEGORY_ID,
                    subcategory_name: DEVICE_GROUP_OTHERS_SUBCATEGORY,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
            },
        },
        hvac: {
            id: NAMES_OF_IDS.HVAC,
            category_name: NAMES_OF_CATEGORIES.HVAC,
            _ui: {
                type: DEVICE_GROUP_TREE_ITEM.CATEGORY.TYPE,
                state: DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.EXCLUDED,
            },
            subcategories: {
                hvac: {
                    id: NAMES_OF_IDS.HVAC,
                    subcategory_name: NAMES_OF_SUBCATEGORIES.HVAC,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                heater: {
                    id: 'heater',
                    subcategory_name: 'Heater',
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                custom_hvac: {
                    id: 'custom_hvac',
                    subcategory_name: 'Custom HVAC',
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                _others: {
                    id: DEVICE_GROUP_OTHERS_SUBCATEGORY_ID,
                    subcategory_name: DEVICE_GROUP_OTHERS_SUBCATEGORY,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
            },
        },
        door_lock: {
            id: 'door_lock',
            category_name: 'Door Lock',
            subcategories: {
                _others: {
                    id: DEVICE_GROUP_OTHERS_SUBCATEGORY_ID,
                    subcategory_name: DEVICE_GROUP_OTHERS_SUBCATEGORY,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
            },
            _ui: {
                type: DEVICE_GROUP_TREE_ITEM.CATEGORY.TYPE,
                state: DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.EXCLUDED,
            },
        },
        camera: {
            id: 'camera',
            category_name: 'Camera',
            _ui: {
                type: DEVICE_GROUP_TREE_ITEM.CATEGORY.TYPE,
                state: DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.EXCLUDED,
            },
            subcategories: {
                indoor_cam: {
                    id: NAMES_OF_IDS.INDOOR_CAM,
                    subcategory_name: NAMES_OF_SUBCATEGORIES.INDOOR,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                outdoor_cam: {
                    id: NAMES_OF_IDS.OUTDOOR_CAM,
                    subcategory_name: NAMES_OF_SUBCATEGORIES.OUTDOOR,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                doorbell_cam: {
                    id: NAMES_OF_IDS.DOORBELL_CAM,
                    subcategory_name: NAMES_OF_SUBCATEGORIES.DOORBELL,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                _others: {
                    id: DEVICE_GROUP_OTHERS_SUBCATEGORY_ID,
                    subcategory_name: DEVICE_GROUP_OTHERS_SUBCATEGORY,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
            },
        },
        camera_suite: {
            id: 'camera_suite',
            category_name: 'Camera Suite',
            _ui: {
                type: DEVICE_GROUP_TREE_ITEM.CATEGORY.TYPE,
                state: DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.EXCLUDED,
            },
            subcategories: {
                indoor_cam: {
                    id: NAMES_OF_IDS.INDOOR_CAM,
                    subcategory_name: NAMES_OF_SUBCATEGORIES.INDOOR,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                outdoor_cam: {
                    id: NAMES_OF_IDS.OUTDOOR_CAM,
                    subcategory_name: NAMES_OF_SUBCATEGORIES.OUTDOOR,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                doorbell_cam: {
                    id: NAMES_OF_IDS.DOORBELL_CAM,
                    subcategory_name: NAMES_OF_SUBCATEGORIES.DOORBELL,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                _others: {
                    id: DEVICE_GROUP_OTHERS_SUBCATEGORY_ID,
                    subcategory_name: DEVICE_GROUP_OTHERS_SUBCATEGORY,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
            },
        },
        doorbell: {
            id: NAMES_OF_IDS.DOORBELL,
            category_name: NAMES_OF_CATEGORIES.DOORBELL,
            subcategories: {
                _others: {
                    id: DEVICE_GROUP_OTHERS_SUBCATEGORY_ID,
                    subcategory_name: DEVICE_GROUP_OTHERS_SUBCATEGORY,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
            },
            _ui: {
                type: DEVICE_GROUP_TREE_ITEM.CATEGORY.TYPE,
                state: DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.EXCLUDED,
            },
        },
        window_cov: {
            id: NAMES_OF_IDS.WINDOW_COV,
            category_name: NAMES_OF_CATEGORIES.WINDOW_COVERING,
            _ui: {
                type: DEVICE_GROUP_TREE_ITEM.CATEGORY.TYPE,
                state: DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.EXCLUDED,
            },
            subcategories: {
                window_cov: {
                    id: NAMES_OF_IDS.WINDOW_COV,
                    subcategory_name: NAMES_OF_SUBCATEGORIES.WINDOW_COVERING,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                zrtsi: {
                    id: 'zrtsi',
                    subcategory_name: 'ZRTSI',
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                _others: {
                    id: DEVICE_GROUP_OTHERS_SUBCATEGORY_ID,
                    subcategory_name: DEVICE_GROUP_OTHERS_SUBCATEGORY,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
            },
        },
        garage_door: {
            id: NAMES_OF_IDS.GARAGE_DOOR,
            category_name: NAMES_OF_CATEGORIES.GARAGE_DOOR,
            subcategories: {
                _others: {
                    id: DEVICE_GROUP_OTHERS_SUBCATEGORY_ID,
                    subcategory_name: DEVICE_GROUP_OTHERS_SUBCATEGORY,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
            },
            _ui: {
                type: DEVICE_GROUP_TREE_ITEM.CATEGORY.TYPE,
                state: DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.EXCLUDED,
            },
        },
        security_sensor: {
            id: 'security_sensor',
            category_name: 'Security Sensor',
            _ui: {
                type: DEVICE_GROUP_TREE_ITEM.CATEGORY.TYPE,
                state: DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.EXCLUDED,
            },
            subcategories: {
                door: {
                    id: 'door',
                    subcategory_name: 'Door Sensor',
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                leak: {
                    id: 'leak',
                    subcategory_name: 'Leak Sensor',
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                motion: {
                    id: 'motion',
                    subcategory_name: 'Motion Sensor',
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                smoke: {
                    id: NAMES_OF_IDS.SMOKE,
                    subcategory_name: 'Smoke Sensor',
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                co: {
                    id: 'co',
                    subcategory_name: 'CO Sensor',
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                glass: {
                    id: 'glass',
                    subcategory_name: 'Glass Break Sensor',
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                co2: {
                    id: 'co2',
                    subcategory_name: 'CO2 Sensor',
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                gas: {
                    id: 'gas',
                    subcategory_name: 'Gas Sensor',
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                heat: {
                    id: 'heat',
                    subcategory_name: 'Heat Sensor',
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                _others: {
                    id: DEVICE_GROUP_OTHERS_SUBCATEGORY_ID,
                    subcategory_name: DEVICE_GROUP_OTHERS_SUBCATEGORY,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
            },
        },
        light_sensor: {
            id: 'light_sensor',
            category_name: 'Light Sensor',
            subcategories: {
                _others: {
                    id: DEVICE_GROUP_OTHERS_SUBCATEGORY_ID,
                    subcategory_name: DEVICE_GROUP_OTHERS_SUBCATEGORY,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
            },
            _ui: {
                type: DEVICE_GROUP_TREE_ITEM.CATEGORY.TYPE,
                state: DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.EXCLUDED,
            },
        },
        temperature: {
            id: 'temperature',
            category_name: 'Temperature Sensor',
            subcategories: {
                _others: {
                    id: DEVICE_GROUP_OTHERS_SUBCATEGORY_ID,
                    subcategory_name: DEVICE_GROUP_OTHERS_SUBCATEGORY,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
            },
            _ui: {
                type: DEVICE_GROUP_TREE_ITEM.CATEGORY.TYPE,
                state: DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.EXCLUDED,
            },
        },
        humidity: {
            id: 'humidity',
            category_name: 'Humidity Sensor',
            subcategories: {
                _others: {
                    id: '_others',
                    subcategory_name: DEVICE_GROUP_OTHERS_SUBCATEGORY,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
            },
            _ui: {
                type: DEVICE_GROUP_TREE_ITEM.CATEGORY.TYPE,
                state: DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.EXCLUDED,
            },
        },
        voltage_sensor: {
            id: 'voltage_sensor',
            category_name: 'Voltage Sensor',
            subcategories: {
                _others: {
                    id: DEVICE_GROUP_OTHERS_SUBCATEGORY_ID,
                    subcategory_name: DEVICE_GROUP_OTHERS_SUBCATEGORY,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
            },
            _ui: {
                type: DEVICE_GROUP_TREE_ITEM.CATEGORY.TYPE,
                state: DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.EXCLUDED,
            },
        },
        level_sensor: {
            id: 'level_sensor',
            category_name: 'Level Sensor',
            _ui: {
                type: DEVICE_GROUP_TREE_ITEM.CATEGORY.TYPE,
                state: DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.EXCLUDED,
            },
            subcategories: {
                co2: {
                    id: 'co2',
                    subcategory_name: 'CO2',
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                co: {
                    id: 'co',
                    subcategory_name: 'CO',
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                current: {
                    id: 'current',
                    subcategory_name: 'Current',
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                velocity: {
                    id: 'velocity',
                    subcategory_name: 'Velocity',
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                capacity: {
                    id: 'capacity',
                    subcategory_name: 'Capacity',
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                water: {
                    id: 'water',
                    subcategory_name: 'Water',
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                particulate_matter: {
                    id: 'particulate_matter',
                    subcategory_name: 'Particulate Matter',
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                frequency: {
                    id: 'frequency',
                    subcategory_name: 'Frequency',
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                health: {
                    id: 'health',
                    subcategory_name: 'Health',
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                modulation: {
                    id: 'modulation',
                    subcategory_name: 'Modulation',
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                smoke: {
                    id: NAMES_OF_IDS.SMOKE,
                    subcategory_name: 'Smoke',
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                soil: {
                    id: 'soil',
                    subcategory_name: 'Soil',
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                moisture: {
                    id: NAMES_OF_IDS.MOISTURE,
                    subcategory_name: NAMES_OF_SUBCATEGORIES.MOISTURE,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                air_pollution: {
                    id: 'air_pollution',
                    subcategory_name: 'Air Pollution',
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                electricity: {
                    id: 'electricity',
                    subcategory_name: 'Electricity',
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                sound: {
                    id: 'sound',
                    subcategory_name: 'Sound',
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                navigation: {
                    id: 'navigation',
                    subcategory_name: 'Navigation',
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                seismicity: {
                    id: 'seismicity',
                    subcategory_name: 'Seismicity',
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                time: {
                    id: 'time',
                    subcategory_name: 'Time',
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                _others: {
                    id: DEVICE_GROUP_OTHERS_SUBCATEGORY_ID,
                    subcategory_name: DEVICE_GROUP_OTHERS_SUBCATEGORY,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
            },
        },
        uv_sensor: {
            id: 'uv_sensor',
            category_name: 'UV Sensor',
            subcategories: {
                _others: {
                    id: DEVICE_GROUP_OTHERS_SUBCATEGORY_ID,
                    subcategory_name: DEVICE_GROUP_OTHERS_SUBCATEGORY,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
            },
            _ui: {
                type: DEVICE_GROUP_TREE_ITEM.CATEGORY.TYPE,
                state: DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.EXCLUDED,
            },
        },
        state_sensor: {
            id: 'state_sensor',
            category_name: 'State Sensor',
            _ui: {
                type: DEVICE_GROUP_TREE_ITEM.CATEGORY.TYPE,
                state: DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.EXCLUDED,
            },
            subcategories: {
                light: {
                    id: 'light',
                    subcategory_name: 'Light',
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                rain: {
                    id: 'rain',
                    subcategory_name: 'Rain',
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                moisture: {
                    id: NAMES_OF_IDS.MOISTURE,
                    subcategory_name: NAMES_OF_SUBCATEGORIES.MOISTURE,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                freeze: {
                    id: 'freeze',
                    subcategory_name: 'Freeze',
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                power: {
                    id: 'power',
                    subcategory_name: 'Power',
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                _others: {
                    id: DEVICE_GROUP_OTHERS_SUBCATEGORY_ID,
                    subcategory_name: DEVICE_GROUP_OTHERS_SUBCATEGORY,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
            },
        },
        flow_meter: {
            id: 'flow_meter',
            category_name: 'Flow meter',
            subcategories: {
                _others: {
                    id: DEVICE_GROUP_OTHERS_SUBCATEGORY_ID,
                    subcategory_name: DEVICE_GROUP_OTHERS_SUBCATEGORY,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
            },
            _ui: {
                type: DEVICE_GROUP_TREE_ITEM.CATEGORY.TYPE,
                state: DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.EXCLUDED,
            },
        },
        power_meter: {
            id: 'power_meter',
            category_name: 'Power Meter',
            subcategories: {
                _others: {
                    id: DEVICE_GROUP_OTHERS_SUBCATEGORY_ID,
                    subcategory_name: DEVICE_GROUP_OTHERS_SUBCATEGORY,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
            },
            _ui: {
                type: DEVICE_GROUP_TREE_ITEM.CATEGORY.TYPE,
                state: DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.EXCLUDED,
            },
        },
        scene_controller: {
            id: 'scene_controller',
            category_name: 'Scene Controller',
            subcategories: {
                _others: {
                    id: DEVICE_GROUP_OTHERS_SUBCATEGORY_ID,
                    subcategory_name: DEVICE_GROUP_OTHERS_SUBCATEGORY,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
            },
            _ui: {
                type: DEVICE_GROUP_TREE_ITEM.CATEGORY.TYPE,
                state: DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.EXCLUDED,
            },
        },
        keypad: {
            id: 'keypad',
            category_name: 'Keypad',
            subcategories: {
                _others: {
                    id: DEVICE_GROUP_OTHERS_SUBCATEGORY_ID,
                    subcategory_name: DEVICE_GROUP_OTHERS_SUBCATEGORY,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
            },
            _ui: {
                type: DEVICE_GROUP_TREE_ITEM.CATEGORY.TYPE,
                state: DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.EXCLUDED,
            },
        },
        ir_tx: {
            id: 'ir_tx',
            category_name: NAMES_OF_CATEGORIES.IR_TRANSMITTER,
            _ui: {
                type: DEVICE_GROUP_TREE_ITEM.CATEGORY.TYPE,
                state: DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.EXCLUDED,
            },
            subcategories: {
                irt: {
                    id: 'irt',
                    subcategory_name: NAMES_OF_SUBCATEGORIES.IR_TRANSMITTER,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                usbuirt: {
                    id: 'usbuirt',
                    subcategory_name: 'USB UIRT',
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                _others: {
                    id: DEVICE_GROUP_OTHERS_SUBCATEGORY_ID,
                    subcategory_name: DEVICE_GROUP_OTHERS_SUBCATEGORY,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
            },
        },
        remote_control: {
            id: 'remote_control',
            category_name: 'Remote Control',
            subcategories: {
                _others: {
                    id: DEVICE_GROUP_OTHERS_SUBCATEGORY_ID,
                    subcategory_name: DEVICE_GROUP_OTHERS_SUBCATEGORY,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
            },
            _ui: {
                type: DEVICE_GROUP_TREE_ITEM.CATEGORY.TYPE,
                state: DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.EXCLUDED,
            },
        },
        alarm_panel: {
            id: 'alarm_panel',
            category_name: 'Alarm Panel',
            subcategories: {
                _others: {
                    id: DEVICE_GROUP_OTHERS_SUBCATEGORY_ID,
                    subcategory_name: DEVICE_GROUP_OTHERS_SUBCATEGORY,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
            },
            _ui: {
                type: DEVICE_GROUP_TREE_ITEM.CATEGORY.TYPE,
                state: DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.EXCLUDED,
            },
        },
        alarm_partition: {
            id: 'alarm_partition',
            category_name: 'Alarm Partition',
            subcategories: {
                _others: {
                    id: DEVICE_GROUP_OTHERS_SUBCATEGORY_ID,
                    subcategory_name: DEVICE_GROUP_OTHERS_SUBCATEGORY,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
            },
            _ui: {
                type: DEVICE_GROUP_TREE_ITEM.CATEGORY.TYPE,
                state: DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.EXCLUDED,
            },
        },
        generic_sensor: {
            id: 'generic_sensor',
            category_name: 'Generic Sensor',
            subcategories: {
                _others: {
                    id: DEVICE_GROUP_OTHERS_SUBCATEGORY_ID,
                    subcategory_name: DEVICE_GROUP_OTHERS_SUBCATEGORY,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
            },
            _ui: {
                type: DEVICE_GROUP_TREE_ITEM.CATEGORY.TYPE,
                state: DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.EXCLUDED,
            },
        },
        generic_io: {
            id: NAMES_OF_IDS.GENERIC_IO,
            category_name: NAMES_OF_CATEGORIES.GENERIC_IO,
            _ui: {
                type: DEVICE_GROUP_TREE_ITEM.CATEGORY.TYPE,
                state: DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.EXCLUDED,
            },
            subcategories: {
                generic_io: {
                    id: NAMES_OF_IDS.GENERIC_IO,
                    subcategory_name: NAMES_OF_SUBCATEGORIES.GENERIC_IO,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                repeater: {
                    id: 'repeater',
                    subcategory_name: 'Repeater',
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
                _others: {
                    id: DEVICE_GROUP_OTHERS_SUBCATEGORY_ID,
                    subcategory_name: DEVICE_GROUP_OTHERS_SUBCATEGORY,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
            },
        },
        av: {
            id: 'av',
            category_name: 'A/V',
            subcategories: {
                _others: {
                    id: DEVICE_GROUP_OTHERS_SUBCATEGORY_ID,
                    subcategory_name: DEVICE_GROUP_OTHERS_SUBCATEGORY,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
            },
            _ui: {
                type: DEVICE_GROUP_TREE_ITEM.CATEGORY.TYPE,
                state: DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.EXCLUDED,
            },
        },
        appliance: {
            id: 'appliance',
            category_name: 'Appliance',
            subcategories: {
                _others: {
                    id: DEVICE_GROUP_OTHERS_SUBCATEGORY_ID,
                    subcategory_name: DEVICE_GROUP_OTHERS_SUBCATEGORY,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
            },
            _ui: {
                type: DEVICE_GROUP_TREE_ITEM.CATEGORY.TYPE,
                state: DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.EXCLUDED,
            },
        },
        siren: {
            id: 'siren',
            category_name: 'Siren',
            subcategories: {
                _others: {
                    id: DEVICE_GROUP_OTHERS_SUBCATEGORY_ID,
                    subcategory_name: DEVICE_GROUP_OTHERS_SUBCATEGORY,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
            },
            _ui: {
                type: DEVICE_GROUP_TREE_ITEM.CATEGORY.TYPE,
                state: DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.EXCLUDED,
            },
        },
        interface: {
            id: 'interface',
            category_name: 'Interface',
            subcategories: {
                _others: {
                    id: DEVICE_GROUP_OTHERS_SUBCATEGORY_ID,
                    subcategory_name: DEVICE_GROUP_OTHERS_SUBCATEGORY,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
            },
            _ui: {
                type: DEVICE_GROUP_TREE_ITEM.CATEGORY.TYPE,
                state: DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.EXCLUDED,
            },
        },
        zwave_int: {
            id: 'zwave_int',
            category_name: 'Z-Wave Interface',
            subcategories: {
                _others: {
                    id: DEVICE_GROUP_OTHERS_SUBCATEGORY_ID,
                    subcategory_name: DEVICE_GROUP_OTHERS_SUBCATEGORY,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
            },
            _ui: {
                type: DEVICE_GROUP_TREE_ITEM.CATEGORY.TYPE,
                state: DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.EXCLUDED,
            },
        },
        insteon_int: {
            id: 'insteon_int',
            category_name: 'Insteon Interface',
            subcategories: {
                _others: {},
            },
            _ui: {
                type: DEVICE_GROUP_TREE_ITEM.CATEGORY.TYPE,
                state: DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.EXCLUDED,
            },
        },
        philips_controller: {
            id: 'philips_controller',
            category_name: 'Philips Controller',
            subcategories: {
                _others: {
                    id: DEVICE_GROUP_OTHERS_SUBCATEGORY_ID,
                    subcategory_name: DEVICE_GROUP_OTHERS_SUBCATEGORY,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
            },
            _ui: {
                type: DEVICE_GROUP_TREE_ITEM.CATEGORY.TYPE,
                state: DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.EXCLUDED,
            },
        },
        serial_port: {
            id: 'serial_port',
            category_name: 'Serial Port',
            subcategories: {
                _others: {
                    id: DEVICE_GROUP_OTHERS_SUBCATEGORY_ID,
                    subcategory_name: DEVICE_GROUP_OTHERS_SUBCATEGORY,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
            },
            _ui: {
                type: DEVICE_GROUP_TREE_ITEM.CATEGORY.TYPE,
                state: DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.EXCLUDED,
            },
        },
        mouse_trap: {
            id: 'mouse_trap',
            category_name: 'Mouse Trap',
            subcategories: {
                _others: {
                    id: DEVICE_GROUP_OTHERS_SUBCATEGORY_ID,
                    subcategory_name: DEVICE_GROUP_OTHERS_SUBCATEGORY,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
            },
            _ui: {
                type: DEVICE_GROUP_TREE_ITEM.CATEGORY.TYPE,
                state: DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.EXCLUDED,
            },
        },
        weather: {
            id: 'weather',
            category_name: 'Weather',
            subcategories: {
                _others: {
                    id: DEVICE_GROUP_OTHERS_SUBCATEGORY_ID,
                    subcategory_name: DEVICE_GROUP_OTHERS_SUBCATEGORY,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
            },
            _ui: {
                type: DEVICE_GROUP_TREE_ITEM.CATEGORY.TYPE,
                state: DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.EXCLUDED,
            },
        },
        googleHome: {
            id: 'googleHome',
            category_name: 'Google Home',
            subcategories: {
                _others: {
                    id: DEVICE_GROUP_OTHERS_SUBCATEGORY_ID,
                    subcategory_name: DEVICE_GROUP_OTHERS_SUBCATEGORY,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
            },
            _ui: {
                type: DEVICE_GROUP_TREE_ITEM.CATEGORY.TYPE,
                state: DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.EXCLUDED,
            },
        },
        amazonAlexa: {
            id: 'amazonAlexa',
            category_name: 'Amazon Alexa',
            subcategories: {
                _others: {
                    id: DEVICE_GROUP_OTHERS_SUBCATEGORY_ID,
                    subcategory_name: DEVICE_GROUP_OTHERS_SUBCATEGORY,
                    _ui: {
                        type: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.TYPE,
                        state: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    },
                },
            },
            _ui: {
                type: DEVICE_GROUP_TREE_ITEM.CATEGORY.TYPE,
                state: DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.EXCLUDED,
            },
        },
    },
};

export const TREE_ELEMENTS_STATE_OPTIONS_LIST = {
    [DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.EXCLUDED]: EXCLUDED_BUTTON,
    [DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.INCLUDED]: INCLUDED_BUTTON,
    [DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED]: INHERITED_BUTTON,
    [DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED_INCLUDED]: INHERITED_BUTTON,
    [DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INCLUDED]: INCLUDED_BUTTON,
    [DEVICE_GROUP_TREE_ITEM.DEVICE.STATES.INHERITED]: INHERITED_BUTTON,
    [DEVICE_GROUP_TREE_ITEM.DEVICE.STATES.INHERITED_INCLUDED]: INHERITED_BUTTON,
    [DEVICE_GROUP_TREE_ITEM.DEVICE.STATES.INCLUDED]: INCLUDED_BUTTON,
    [DEVICE_GROUP_TREE_ITEM.DEVICE.STATES.EXCLUDED]: EXCLUDED_BUTTON,
};

export const CATEGORY_ID = 'categoryId';
export const CATEGORY = 'category';
export const SUBCATEGORY_ID = 'subcategoryId';
export const SUBCATEGORIES = 'subcategories';
export const LABEL_DEFAULT_BUTTON = 'Inherited';
export const SELECTED_ELEMENT_INDEX = 1;
export const STARTING_INDEX_DISPLAED_LIST = 0;
export const END_INDEX_DISPLAED_LIST = 20;

export const MENU_BUTTONS_STATES = {
    EXCLUDED: 'excluded',
    INCLUDED: 'included',
    INHERITED: 'inherited',
    INHERITED_INCLUDED: 'inheritedIncluded',
};

export const PAYLOAD_STRUCTURE = {
    ID: 'id',
    NAME: 'name',
    CATEGORIES: 'categories',
    DEVICES: 'devices',
    EXCEPTIONS: 'exceptions',
    ENTRY_DELAY: 'entryDelay',
    FOLLOW_ENTRY: 'followEntry',
    ROLE: 'role',
    PACKAGE_ID: 'packageId',
};

export const DEVICE_GROUP_DEVICE_KEYS = {
    UI: {
        KEY: 'type',
    },
};

export const MUI_PROPS = {
    COMPACT: 'compact',
    CENTER: 'center',
    LEFT: 'left',
    OUTLINED: 'outlined',
    RIGHT: 'right',
    NUMBER: 'number',
};

export const EMPTY_DEVICE_GROUP_PAYLOAD = {
    categories: [],
    devices: [],
    exceptions: [],
};

export const EDIT_DEVICE_GROUPS_URL = '/ezlo/device-groups/edit';
