import _ from 'lodash';

/**
 * Manager for building, providing and storing throttle functions
 * @example
 * new ThrottleManager()
 * */
export default class ThrottleManager {
    constructor() {
        this.storage = new Map();
    }

    /**
     * Initialize Throttle fn and provide it
     * @param {String} id - Unique ID under which the function is stored
     * @param {Function} cb - The function that will call by throttle fn
     * @param {Number} timeout - Delay in calling cb
     * @returns {Function} throttle fn
     * @example
     * initializeThrottle('412fdevgb412', someFn, 5000)
     * */
    initializeThrottle(id, cb, timeout) {
        if (!this.storage.has(id)) {
            const throttleWrapper = _.throttle(cb, timeout);
            this.storage.set(id, throttleWrapper);
        }

        return this.storage.get(id);
    }
}
