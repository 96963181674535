import React from 'react';

export const MiosLogo = () => {
    return (
        <svg width="88" height="24" viewBox="0 0 88 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mios">
            <path
                d="M35.3684 10.4211H40.4211V23.6842H35.3684V10.4211ZM67.579 12C67.579 18.6316 62.2105 24 55.579 24C48.9474 24 43.579 18.6316 43.579 12C43.579 5.36842 48.9474 0 55.579 0C62.2105 0 67.579 5.36842 67.579 12ZM62.5263 12C62.5263 8.17895 59.4 5.05263 55.579 5.05263C51.7579 5.05263 48.6316 8.17895 48.6316 12C48.6316 15.8211 51.7579 18.9474 55.579 18.9474C59.4 18.9474 62.5263 15.8211 62.5263 12ZM22.4211 0C19.8 0 17.4632 1.10526 15.7895 2.84211C14.1158 1.07368 11.7789 0 9.1579 0C4.10526 0 0 4.10526 0 9.15789V23.6842H5.05263V9.15789C5.05263 6.88421 6.88421 5.05263 9.1579 5.05263C11.4316 5.05263 13.2632 6.88421 13.2632 9.15789V23.6842H18.3158V9.15789C18.3158 6.88421 20.1474 5.05263 22.4211 5.05263C24.6947 5.05263 26.5263 6.88421 26.5263 9.15789V23.6842H31.579V9.15789C31.579 4.10526 27.4737 0 22.4211 0ZM82.2948 10.3263C81.6632 10.1684 76.2947 8.77895 76.2947 8.77895C75.2842 8.49474 74.7474 7.89474 74.8105 7.10526C74.8737 6.31579 75.6316 5.30526 77.5895 5.11579C79.8632 4.89474 82.9579 5.46316 85.2632 6.66316L87.1579 1.95789C84.0632 0.442105 80.2421 -0.221053 77.1158 0.0947366C72.4105 0.568421 70.0737 3.75789 69.8211 6.69474C69.5369 9.94737 71.6211 12.7579 75 13.6737C76.0105 13.9579 80.4 15.0632 81 15.2211C82.0105 15.5053 82.5474 16.1053 82.4842 16.8947C82.4211 17.6842 81.6632 18.6947 79.7053 18.8842C77.2421 19.1368 73.7684 18.4105 71.0842 17.0211L69.1895 21.7263C72.1263 23.1789 75.7579 24 78.7263 24C79.2316 24 79.7369 23.9684 80.2421 23.9368C84.9474 23.4632 87.2842 20.2737 87.5369 17.3368C87.7579 14.0842 85.6737 11.2737 82.2948 10.3263Z"
                fill="black"
            />
            <path
                d="M37.8946 6.31579C39.6386 6.31579 41.0525 4.90195 41.0525 3.15789C41.0525 1.41384 39.6386 0 37.8946 0C36.1505 0 34.7367 1.41384 34.7367 3.15789C34.7367 4.90195 36.1505 6.31579 37.8946 6.31579Z"
                fill="#3E46AE"
            />
        </svg>
    );
};
