import React from 'react';

export const MeshbotIcon = (props) => (
    <svg width="32" height="32" fill="none" {...props} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11 3H8V0H11V3ZM24 0H21V3H24V0ZM11 29H8V32H11V29ZM24 29H21V32H24V29ZM32 11V8H29V11H32ZM32 24V21H29V24H32ZM3 11V8H0V11H3ZM3 24V21H0V24H3ZM27 8V24C27 25.7 25.7 27 24 27H8C6.3 27 5 25.7 5 24V8C5 6.3 6.3 5 8 5H24C25.7 5 27 6.3 27 8ZM24 8H8V24H24V8ZM21 11H11V21H21V11Z"
            fill="#1C1E21"
        />
    </svg>
);

export default MeshbotIcon;
