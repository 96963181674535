import { lAST_ACTIVE_CONTROLLERS_GROUP_UUID } from '../../../constants/localStorageKeys';
import { logs } from '../../../services/hub';
import { isArray } from 'lodash';
import isFunction from 'lodash/isFunction';
import { CONTROLLER_MODELS } from '../EzloMeshbots/constants';

/**
 * Returns 'params' object for SET controller group request's payload.
 * More info {@link https://confluence.mios.com/display/EPD/Controller+Group+-+Set here}
 * @param {array} controllers - array of controllers
 * @param {string} [name] - name of controller group
 * @param {array} [controllerGroupUuid] - uuid of existing group
 * @returns {object} params - prepared 'params' object.
 * */
export const buildControllerGroupSetParams = (controllers, name, controllerGroupUuid) => {
    const params = { controllers };

    if (name) {
        params.name = name;
    }

    if (controllerGroupUuid) {
        params.uuid = controllerGroupUuid;
    }

    return params;
};

/**
 * Returns 'params' object for 'controller_get_report' request's payload.
 * More info {@link https://confluence.mios.com/display/EPD/Controller+-+Get+Report here}
 * @param {array} controllers - array of controllers' uuid or id
 * @returns {object} params - prepared 'params' object.
 * */
export const buildControllerGetReportParams = (controllers) => {
    return { controllers };
};

/**
 * Add controller to group or create a new group
 * Returns body object for 'controller_group_set' request.
 * More info {@link https://confluence.mios.com/display/EPD/Controller+Group+-+Set here}
 * @returns {object} body - request body.
 * */
export const buildControllerGroupSetRequestBody = (params) => {
    return { call: 'controller_group_set', params };
};

/**
 * Get all groups the current user has access to.
 * Returns body object for 'controller_group_list' request.
 * More info {@link https://confluence.mios.com/pages/viewpage.action?spaceKey=EPD&title=Controller+Group+-+List here}
 * @returns {object} body - request body.
 * */
export const buildControllerGroupListRequestBody = () => {
    return { call: 'controller_group_list' };
};

/**
 * Get last reported information by controller(s).
 * Returns body object for 'controller_get_report' request.
 * More info {@link https://confluence.mios.com/display/EPD/Controller+-+Get+Report here}
 * @returns {object} body - request body.
 * */
export const buildControllerGetReportRequestBody = (params) => {
    return { call: 'controller_get_report', params };
};

export const filterAvailableEzlosFromGroups = (allControllers, groups) => {
    const usedControllers = Object.values(groups).reduce((acc, group) => [...acc, ...group.controllers], []);

    return allControllers.filter(
        (controller) => !usedControllers.find((usedController) => usedController.id === controller.serial),
    );
};

/**
 * Returns last used user's controllers group or first available group from 'groups' array
 * @param {array} groups - controllers groups
 * @returns {object} last used group
 * */
export const getLastUsedControllersGroup = (groups) => {
    if (groups) {
        const uuid = localStorage.getItem(lAST_ACTIVE_CONTROLLERS_GROUP_UUID);

        return groups[uuid] || Object.values(groups)[0];
    }
};

/**
 * Set last used user's controllers group uuid to localStorage
 * @param {object} group - controllers group
 * */
export const setLastUsedControllersGroupUuid = (group) => {
    if (group) {
        localStorage.setItem(lAST_ACTIVE_CONTROLLERS_GROUP_UUID, group.uuid);
    }
};

/**
 * Remove last used user's controllers group from localStorage
 * */
export const removeLastUsedControllersGroupUuid = () => {
    localStorage.removeItem(lAST_ACTIVE_CONTROLLERS_GROUP_UUID);
};

/**
 * A value in ms, used to wait before dialog closing.
 * Just for smoothness user experience.
 * */
export const CLOSE_DIALOG_DELAY = 200;

export const getDataSingleController = (serial, status, id) => {
    return [
        {
            id: serial,
            online: status,
            uuid: id,
        },
    ];
};

/**
 * Object mapping log settings names to corresponding functions for building log parameters to pass them in hub.log.local.set call.
 *
 * @constant
 * @type {Object}
 * @see {[hub.log.local.set](https://log.ezlo.com/new/hub/logging/#hubloglocalset)}
 */
const functionsForBuildingLogsParams = {
    [logs.level]: (severity) => ({
        severity,
    }),
    [logs.color]: (severity, color) => ({
        severity,
        color,
    }),
    [logs.indent]: (severity, indent) => ({
        severity,
        indent: Number(indent),
    }),
};

/**
 * Get the severity value based on the log setting name, value, and settings array.
 *
 * @param {string} name - Log setting name.
 * @param {string|boolean} value - Log setting value.
 * @param {Object[]} settings - Array of log settings.
 *
 * @returns {string} The severity value.
 */
export const getSeverity = (name, value, settings) => {
    if (name === logs.level) {
        return value;
    }

    if (!isArray(settings)) {
        throw new Error('settings must be array.');
    }

    return settings.find(({ name }) => name === logs.level)?.value;
};

/**
 * Build log parameters based on settings and specific log parameters to pass them in hub.log.local.set call.
 *
 * @param {Object[]} settings - Array of log settings.
 * @param {Object} params - Specific log parameters.
 * @param {string} params.name - Log setting name.
 * @param {string|boolean} params.value - Log setting value.
 *
 * @returns {Object} The built log parameters.
 * @see { [hub.log.local.set](https://log.ezlo.com/new/hub/logging/#hubloglocalset) }
 */
export const buildLogsParams = (settings, params = {}) => {
    if (!params.hasOwnProperty('name') || !params.hasOwnProperty('value')) {
        throw new Error('name and value is required.');
    }
    const buildingLogsParams = functionsForBuildingLogsParams[params?.name];

    if (!isFunction(buildingLogsParams)) {
        return {};
    }
    const severity = getSeverity(params?.name, params?.value, settings);

    return buildingLogsParams(severity, params?.value);
};

/**
 * This function checks that the controller matches a configuration key called "type"
 * @param {object} controller - controller
 * @param {string} type - model of the controller
 * @returns {boolean}
 */
export const isControllerTypeMatch = (controller, type) => {
    if (!controller || !type) {
        return;
    }

    if (Array.isArray(type) && type.length) {
        return type.includes(controller?.model);
    }

    if (type === CONTROLLER_MODELS.EZLOPI) {
        const isEzloPi = controller?.model?.startsWith(CONTROLLER_MODELS.EZLOPI);

        return isEzloPi;
    } else {
        return controller?.model === type;
    }
};
