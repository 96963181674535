import React from 'react';

export const DeviceCategory = (props) => (
    <svg {...props} viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.76923 24H21.2308C22.8 24 24 22.8 24 21.2308V2.76923C24 1.2 22.8 0 21.2308 0H2.76923C1.2 0 0 1.2 0 2.76923V21.2308C0 22.8 1.2 24 2.76923 24ZM4 7C4 8.65 5.35 10 7 10C8.65 10 10 8.65 10 7C10 5.35 8.65 4 7 4C5.35 4 4 5.35 4 7Z"
        />
    </svg>
);

export default DeviceCategory;
