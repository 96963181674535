import React from 'react';
import { useTranslate } from '../../../../../features/languages';
import { EZLOGIC_TITLE_PASSWORD_RESET_SUCCESSFUL_ICON } from '../../../../../constants/language_tokens';

export const ResetPasswordLock = (props) => {
    const { t } = useTranslate();

    return (
        <svg width="104" height="109" viewBox="0 0 104 109" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <title>{t(EZLOGIC_TITLE_PASSWORD_RESET_SUCCESSFUL_ICON)}</title>
            <path
                d="M79 71.3316H25V35.2199C25 20.3083 37.0883 8.21997 52 8.21997H52C66.9117 8.21997 79 20.3083 79 35.2199V71.3316Z"
                stroke="#3E46AE"
                strokeWidth="16"
                strokeMiterlimit="10"
            />
            <path
                d="M71 34.9957H33L0 46.9957V96.9957C0 103.623 5.37258 108.996 12 108.996H92C98.6274 108.996 104 103.623 104 96.9957V46.9957L71 34.9957Z"
                fill="#3E46AE"
            />
            <path d="M0 46.9957V96.9957L33 34.9957L0 46.9957Z" fill="#1F2581" />
            <path d="M104 46.9957L71 34.9957L104 96.9957V46.9957Z" fill="#1F2581" />
            <path
                d="M52 79.9957C56.4183 79.9957 60 76.414 60 71.9957C60 67.5774 56.4183 63.9957 52 63.9957C47.5817 63.9957 44 67.5774 44 71.9957C44 76.414 47.5817 79.9957 52 79.9957Z"
                fill="#FE5C15"
            />
            <path
                d="M26 77.9957C29.3137 77.9957 32 75.3094 32 71.9957C32 68.682 29.3137 65.9957 26 65.9957C22.6863 65.9957 20 68.682 20 71.9957C20 75.3094 22.6863 77.9957 26 77.9957Z"
                fill="white"
            />
            <path
                d="M78 77.9957C81.3137 77.9957 84 75.3094 84 71.9957C84 68.682 81.3137 65.9957 78 65.9957C74.6863 65.9957 72 68.682 72 71.9957C72 75.3094 74.6863 77.9957 78 77.9957Z"
                fill="#50C1D6"
            />
        </svg>
    );
};
export default ResetPasswordLock;
