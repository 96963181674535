export const EXPRESSIONS_TYPE = {
    EXPRESSION: 'expression',
    VARIABLE: 'variable',
};

export const COMPARED_TYPE_VALUE = 'value';
export const VALUE_TYPE = 'valueType';
export const COMPARATOR = 'comparator';
export const BOOLEAN_TYPE = 'boolean';
export const ONE_INT = 1;
export const ZERO_INT = 0;
export const RGB_COLORS = {
    RED: 'red',
    GREEN: 'green',
    BLUE: 'blue',
};
