/** PROXY_PREFIX = '/proxy/' */
const PROXY_PREFIX = '/proxy/';
/** HTTPS_PROTOCOL_PREFIX = 'https://' */
export const HTTPS_PROTOCOL_PREFIX = 'https://';

/**
 * Replace URL prefix by new prefix value.
 * @param {string} url - url to analyze
 * @param {string} [prefix] - current prefix to replace, by default {@link PROXY_PREFIX}
 * @param {string} [newPrefix] - new prefix to use instead, by default {@link HTTPS_PROTOCOL_PREFIX}
 * @returns {string} new URL value
 *
 * @example:
 * url = /proxy/download-cloud.ezlo.com/?uuid=ea67e5f0-db35-11ec-815b-011926f52502&key=8PvxRhMQsO16HLOwqzw3fnPo2pdQ5dmO6c&redirect_support=1
 * result = https://download-cloud.ezlo.com/?uuid=ea67e5f0-db35-11ec-815b-011926f52502&key=8PvxRhMQsO16HLOwqzw3fnPo2pdQ5dmO6c&redirect_support=1
 * */
export const replaceUrlPrefix = (url, prefix = PROXY_PREFIX, newPrefix = HTTPS_PROTOCOL_PREFIX) => {
    if (!url) {
        return url;
    }

    if (typeof url !== 'string') {
        return url;
    }

    return url.replace(prefix, newPrefix);
};
