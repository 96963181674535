import * as types from '../constants/ActionTypes/VirtualDeviceManager';
import { API_CLOUD_EZLO_REQUEST_URL, GET_VIRTUAL_CONTAINER } from '../constants/URLs';
import {
    buildAbstractCommandRequestBody,
    buildAbstractListDetailedRequestBody,
    buildVirtualDeviceListRequestBody,
    buildVirtualDeviceSetRequestBody,
} from '../containers/Ezlo/EzloVirtualDeviceManager/utils';
import { axiosCloudConfig } from '../api/config';
import { bugsnagNotify } from '../containers/ErrorBoundary/utils';
import { toast, TOAST_TYPE } from 'components/Toast';
import { t } from 'helpers/language';
import {
    EZLOGIC_TOAST_VIRTUAL_CONTAINER_SUCCESSFULLY_SAVED,
    EZLOGIC_TOAST_VIRTUAL_CONTAINER_SUCCESSFULLY_DELETED,
} from 'constants/language_tokens';

const actions = {
    fetchVirtualDeviceList: () => (dispatch) => {
        new Promise((resolve, reject) => {
            const fetchStorageList = () => {
                fetch(API_CLOUD_EZLO_REQUEST_URL(), {
                    method: 'post',
                    ...axiosCloudConfig(),
                    body: JSON.stringify(buildVirtualDeviceListRequestBody()),
                })
                    .then((res) => {
                        return res.json();
                    })
                    .then((payload) => {
                        const virtualDeviceList = payload.data.virtual_devices;
                        dispatch({ type: types.VDM_FETCH_VIRTUAL_DEVICE_LIST_ON_SUCCESS, virtualDeviceList });
                        resolve();
                    })
                    .catch((error) => {
                        bugsnagNotify(error, { type: types.VDM_FETCH_VIRTUAL_DEVICE_LIST_ON_ERROR });
                        dispatch({ type: types.VDM_FETCH_VIRTUAL_DEVICE_LIST, error });
                        reject();
                    });
            };

            fetchStorageList();
        });
    },

    deleteVirtualDevice: (params) => (dispatch) => {
        new Promise((resolve, reject) => {
            const sendDeleteVirtualDevice = () => {
                fetch(API_CLOUD_EZLO_REQUEST_URL(), {
                    method: 'post',
                    ...axiosCloudConfig(),
                    body: JSON.stringify(buildVirtualDeviceSetRequestBody(params)),
                })
                    .then((res) => {
                        return res.json();
                    })
                    .then((payload) => {
                        dispatch({ type: types.VDM_DELETE_VIRTUAL_DEVICE_ON_SUCCESS, uuid: payload.data.uuid });
                        toast(t(EZLOGIC_TOAST_VIRTUAL_CONTAINER_SUCCESSFULLY_DELETED), {
                            type: TOAST_TYPE.SUCCESS,
                        });
                        resolve();
                    })
                    .catch((error) => {
                        bugsnagNotify(error, { type: types.VDM_DELETE_VIRTUAL_DEVICE_ON_ERROR, params });
                        dispatch({ type: types.VDM_DELETE_VIRTUAL_DEVICE_ON_ERROR, error });
                        reject();
                    });
            };

            sendDeleteVirtualDevice();
        });
    },

    createVirtualDevice: (params, navigate) => (dispatch) => {
        new Promise((resolve, reject) => {
            dispatch({ type: types.VDM_CREATE_VIRTUAL_DEVICE });
            const sendCreateVirtualDevice = () => {
                fetch(API_CLOUD_EZLO_REQUEST_URL(), {
                    method: 'post',
                    ...axiosCloudConfig(),
                    body: JSON.stringify(buildVirtualDeviceSetRequestBody(params)),
                })
                    .then((res) => {
                        return res.json();
                    })
                    .then((payload) => {
                        dispatch({ type: types.VDM_CREATE_VIRTUAL_DEVICE_ON_SUCCESS, uuid: payload.data.uuid });
                        dispatch(actions.fetchVirtualDeviceList());
                        navigate(GET_VIRTUAL_CONTAINER());
                        toast(t(EZLOGIC_TOAST_VIRTUAL_CONTAINER_SUCCESSFULLY_SAVED), {
                            type: TOAST_TYPE.SUCCESS,
                        });
                        resolve();
                    })
                    .catch((error) => {
                        bugsnagNotify(error, { type: types.VDM_CREATE_VIRTUAL_DEVICE_ON_ERROR, params });
                        dispatch({ type: types.VDM_CREATE_VIRTUAL_DEVICE_ON_ERROR, error });
                        reject();
                    });
            };

            sendCreateVirtualDevice();
        });
    },

    fetchAbstractListDetailed: () => (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch({ type: types.VDM_FETCH_ABSTRACT_LIST_DETAILED });

            const fetchAbstractListDetailedReq = () => {
                fetch(API_CLOUD_EZLO_REQUEST_URL(), {
                    method: 'post',
                    ...axiosCloudConfig(),
                    body: JSON.stringify(buildAbstractListDetailedRequestBody()),
                })
                    .then((res) => {
                        return res.json();
                    })
                    .then((payload) => {
                        dispatch({
                            type: types.VDM_FETCH_ABSTRACT_LIST_DETAILED_ON_SUCCESS,
                            abstracts: payload.data.abstracts,
                        });
                        resolve();
                    })
                    .catch((error) => {
                        bugsnagNotify(error, { type: types.VDM_FETCH_ABSTRACT_LIST_DETAILED_ON_ERROR });
                        dispatch({ type: types.VDM_FETCH_ABSTRACT_LIST_DETAILED_ON_ERROR, error });
                        reject();
                    });
            };

            fetchAbstractListDetailedReq();
        });
    },

    selectVirtualDevice: (virtualDevice) => (dispatch) => {
        dispatch({ type: types.VDM_SELECT_VIRTUAL_DEVICE, selectedVirtualDevice: virtualDevice });
    },

    submitTextCommand: (params) => (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: types.VDM_SEND_TEXT_COMMAND,
                payload: { uuid: params.uuid, input: params.parameters.text },
            });

            const sendAbstractCommandReq = () => {
                fetch(API_CLOUD_EZLO_REQUEST_URL(), {
                    method: 'post',
                    ...axiosCloudConfig(),
                    body: JSON.stringify(buildAbstractCommandRequestBody(params)),
                })
                    .then((res) => {
                        return res.json();
                    })
                    .then((payload) => {
                        const output =
                            (payload.data &&
                                payload.data.capabilities &&
                                payload.data.capabilities.text_command &&
                                payload.data.capabilities.text_command.variables &&
                                payload.data.capabilities.text_command.variables.text) ||
                            'Unable to run command';
                        dispatch({
                            type: types.VDM_SEND_TEXT_COMMAND_ON_SUCCESS,
                            payload: { uuid: params.uuid, output },
                        });
                        resolve();
                    })
                    .catch((error) => {
                        bugsnagNotify(error, { type: types.VDM_SEND_TEXT_COMMAND_ON_ERROR, params });
                        dispatch({ type: types.VDM_SEND_TEXT_COMMAND_ON_ERROR, payload: { uuid: params.uuid, error } });
                        reject();
                    });
            };

            sendAbstractCommandReq();
        });
    },

    submitAbstractCommand: (params) => (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: types.VDM_SUBMIT_ABSTRACT_COMMAND,
                payload: { ...params },
            });

            const sendAbstractCommandReq = () => {
                fetch(API_CLOUD_EZLO_REQUEST_URL(), {
                    method: 'post',
                    ...axiosCloudConfig(),
                    body: JSON.stringify(buildAbstractCommandRequestBody(params)),
                })
                    .then((res) => {
                        return res.json();
                    })
                    .then((payload) => {
                        dispatch({ type: types.VDM_SUBMIT_ABSTRACT_COMMAND_ON_SUCCESS, payload });
                        resolve();
                    })
                    .catch((error) => {
                        bugsnagNotify(error, { type: types.VDM_SUBMIT_ABSTRACT_COMMAND_ON_ERROR, params });
                        dispatch({ type: types.VDM_SUBMIT_ABSTRACT_COMMAND_ON_ERROR, error });
                        reject();
                    });
            };

            sendAbstractCommandReq();
        });
    },

    setVirtualDevice: (virtualDevice) => (dispatch) => {
        dispatch({ type: types.SET_VIRTUAL_DEVICE, virtualDevice });
    },

    setSavedVirtualDevice: (virtualDevice) => (dispatch) => {
        dispatch({ type: types.SET_SAVED_VIRTUAL_DEVICE, virtualDevice });
    },
};

export default actions;
