export const ABSTRACT_TYPES = {
    integration: 'Integrations',
    device: 'Devices',
    meshbot: 'MeshBots',
    virtual_device: 'Virtual device',
};

export const API_CAPABILITY_ABSTRACT = 'api';

export const DEFAULT_VIRTUAL_DEVICE_TYPE = {
    apiName: 'plug_interior',
    uiLabel: 'Simple On / Off Switch',
    isVisible: true,
};

export const DEFAULT_VIRTUAL_DEVICE_COUNT = 1;

export const DEFAULT_VIRTUAL_DEVICE = {
    device_type: DEFAULT_VIRTUAL_DEVICE_TYPE,
    devices_count: DEFAULT_VIRTUAL_DEVICE_COUNT,
};

export const VIRTUAL_DEVICE_TYPES = [
    {
        apiName: 'air_conditioner_sensor',
        uiLabel: 'Air Conditioner Sensor',
        isVisible: true,
    },
    {
        apiName: 'air_pollution_sensor',
        uiLabel: 'Air Pollution Sensor',
        isVisible: true,
    },
    {
        apiName: 'bulb_rgb',
        uiLabel: 'Bulb - RGB',
        isVisible: true,
    },
    {
        apiName: 'co_sensor',
        uiLabel: 'Carbon Monoxide sensor',
        isVisible: true,
    },
    {
        apiName: 'combo_sensor',
        uiLabel: 'Combo Sensor',
        isVisible: true,
    },
    {
        apiName: 'cooling_meter',
        uiLabel: 'Cooling Meter',
        isVisible: true,
    },
    {
        apiName: 'plug_dimmable',
        uiLabel: 'Dimmer Switch',
        isVisible: true,
    },
    {
        apiName: 'door_lock',
        uiLabel: 'Door Lock',
        isVisible: true,
    },
    {
        apiName: 'dw_sensor',
        uiLabel: 'Door/Window Contact Sensor',
        isVisible: true,
    },
    {
        apiName: 'electric_meter',
        uiLabel: 'Electric Meter',
        isVisible: true,
    },
    {
        apiName: 'electric_sensor',
        uiLabel: 'Electric Sensor',
        isVisible: true,
    },
    {
        apiName: 'fitness_sensor',
        uiLabel: 'Fitness Sensor',
        isVisible: true,
    },
    {
        apiName: 'force_sensor',
        uiLabel: 'Force Sensor',
        isVisible: true,
    },
    {
        apiName: 'garage_door',
        uiLabel: 'Garage Door',
        isVisible: true,
    },
    {
        apiName: 'gas_meter',
        uiLabel: 'Gas Meter',
        isVisible: true,
    },
    {
        apiName: 'heat_sensor',
        uiLabel: 'Heat Sensor',
        isVisible: true,
    },
    {
        apiName: 'heating_meter',
        uiLabel: 'Heating Meter',
        isVisible: true,
    },
    {
        apiName: 'light_sensor',
        uiLabel: 'Light Lux Sensor',
        isVisible: true,
    },
    {
        apiName: 'motion_sensor',
        uiLabel: 'Motion Sensor',
        isVisible: true,
    },
    {
        apiName: 'rf_signal_sensor',
        uiLabel: 'Radio Frequency (RF) Sensor',
        isVisible: true,
    },
    {
        apiName: 'rotation_sensor',
        uiLabel: 'Rotation Sensor',
        isVisible: true,
    },
    {
        apiName: 'scene_controller',
        uiLabel: 'Scene Controller',
        isVisible: true,
    },
    {
        apiName: 'seismicity_sensor',
        uiLabel: 'Seismic Sensor',
        isVisible: true,
    },
    DEFAULT_VIRTUAL_DEVICE_TYPE,
    {
        apiName: 'siren_sensor',
        uiLabel: 'Siren Sensor',
        isVisible: true,
    },
    {
        apiName: 'soil_sensor',
        uiLabel: 'Soil Moisture Sensor',
        isVisible: true,
    },
    {
        apiName: 'sound_sensor',
        uiLabel: 'Sound Sensor',
        isVisible: true,
    },
    {
        apiName: 'stopwatch',
        uiLabel: 'Stopwatch',
        isVisible: true,
    },
    {
        apiName: 'temperature_sensor',
        uiLabel: 'Temperature Sensor',
        isVisible: true,
    },
    {
        apiName: 'thermostat',
        uiLabel: 'Thermostat',
        isVisible: true,
    },
    {
        apiName: 'uv_sensor',
        uiLabel: 'Ultraviolet (UV) sensor',
        isVisible: true,
    },
    {
        apiName: 'water_leak_sensor',
        uiLabel: 'Water Leak Sensor',
        isVisible: true,
    },
    {
        apiName: 'water_meter',
        uiLabel: 'Water Meter',
        isVisible: true,
    },
    {
        apiName: 'water_sensor',
        uiLabel: 'Water Sensor',
        isVisible: true,
    },
    {
        apiName: 'weather_sensor',
        uiLabel: 'Weather Sensor',
        isVisible: true,
    },
    {
        apiName: 'plug_interior_with_dictionary_settings',
        uiLabel: '',
        isVisible: false,
    },
    {
        apiName: 'plug_interior_with_pretty_settings',
        uiLabel: '',
        isVisible: false,
    },
    {
        apiName: 'plug_interior_with_hw_state',
        uiLabel: '',
        isVisible: false,
    },
    {
        apiName: 'default',
        uiLabel: '',
        isVisible: false,
    },
    {
        apiName: 'infos_set',
        uiLabel: '',
        isVisible: false,
    },
    {
        apiName: 'navigation_sensor',
        uiLabel: '',
        isVisible: false,
    },
    {
        apiName: 'shutter_SWF',
        uiLabel: '',
        isVisible: false,
    },
    {
        apiName: 'home_health',
        uiLabel: '',
        isVisible: false,
    },
];

export const FORM_BUTTON_TITLES = {
    ADD: 'Add more',
    SUBMIT: 'Create',
};

export const SETTINGS_OF_CONFIRM_DIALOG = {
    BUTTON_TEXT: 'Create',
    TITLE: 'Create virtual devices',
    TEXT: 'Are you sure you want to create virtual devices?',
};

export const VIRTUAL_DEVICE_ARRAY_NAME = 'virtualDevices';
export const TITLES_OF_VIRTUAL_DEVICE_PAGES = {
    CREATE: 'Create Virtual Devices',
};

export const SETTINGS_OF_VIRTUAL_DEVICE_FIELD = {
    DEVICE_TYPE: {
        LABEL: 'Virtual device type',
    },
    QUANTITY: {
        MAX: 100,
        MIN: 1,
        STEP: 1,
        LABEL: 'Quantity',
    },
    VIRTUAL_DEVICES: {
        MAX: 100,
    },
};

export const TEST_PLUGIN_ID = 'test_plugin';

export const FONT_SIZE = '16px';
