import React from 'react';
import { PlayArrow } from 'lib/@mui/Icons';
import { isBoolean, isObject } from 'lodash';

import StopIconWithCircularProgress from 'components/StopIconWithCircularProgress';
import { IcFinishedStatus, IcPartiallyFinishedStatus, IcFailedStatus, IcStoppedStatus } from 'assets/icons';
import { SCENE_RUN_STATUSES } from 'constants/MeshbotConstant';
import { ErrorToastContentWithTitleAndDetails } from '../Toast/components';
import { EZLOGIC_TITLE_SCENE_STOP } from 'constants/language_tokens';
import { bugsnagNotifyWrapper } from 'containers/ErrorBoundary/utils';

import styles from './styles.module.scss';

export const updateCloudSceneStatus = ({ local, cloud, scene_id, status }) => {
    const updatedCloud = {
        ...cloud,
        [scene_id]: { status },
    };
    const updatedLocal = { ...local };

    return {
        local: { ...updatedLocal },
        cloud: { ...updatedCloud },
    };
};
/**
 * Returns an active scene by its type and status.
 *
 * @param {Object} activeRunScenes - The active run scenes.
 * @param {string} id - The id of the scene.
 * @param {string|number} serial - The serial number of the hub.
 * @returns {string|null} The status of the active scene or null if not found.
 */
export const getActiveRunSceneStatusById = (activeRunScenes, id, serial) => {
    if (!activeRunScenes || !id) {
        return null;
    }

    if (serial) {
        return activeRunScenes.local?.[serial]?.[id]?.status || null;
    }

    return activeRunScenes.cloud?.[id]?.status || null;
};
/**
 * Returns isLocalSceneStoppingInitiated value by scenes ids.
 *
 * @param {Object} trackRunScenes - trackRunScenes state.
 * @param {string} id - The id of the scene.
 * @param {string|number} serial - The serial number of the hub.
 * @returns {boolean} The isLocalSceneStoppingInitiated value of the active scene.
 */
export const getIsLocalSceneStoppingInitiatedByIds = ({ local }, id, serial) => {
    if (!isBoolean(local?.[serial]?.[id]?.isSceneStoppingInitiated)) {
        return false;
    }

    return local[serial][id].isSceneStoppingInitiated;
};

/**
 * Returns the icon for the active run scene status.
 *
 * @param {string|null} status - Run seven process status.
 * @param {string} [stopIconColor] - stop icon color.
 * @returns {React.ReactElement} The icon corresponding to the status of the active run scene.
 */
export const getIconByRunSceneProcessStatus = (status, stopIconColor) => {
    switch (status) {
        case SCENE_RUN_STATUSES.FAILED:
            return <IcFailedStatus className={styles.error} />;
        case SCENE_RUN_STATUSES.FINISHED:
            return <IcFinishedStatus className={styles.primary} />;
        case SCENE_RUN_STATUSES.PARTIALLY_FINISHED:
            return <IcPartiallyFinishedStatus className={styles.error} />;
        case SCENE_RUN_STATUSES.STOPPED:
            return <IcStoppedStatus className={styles.primary} />;
        case SCENE_RUN_STATUSES.STARTED:
            return <StopIconWithCircularProgress className={styles[stopIconColor]} />;
        default:
            return <PlayArrow className={styles.playArrow} />;
    }
};

/**
 * Removes a scene from the state under a specified controller.
 *
 * @param {Object} state - The current state.
 * @param {Object} payload - The scene payload object to remove.
 * @param {string} payload.controller_id - ID of the controller from which the scene will be removed.
 * @param {string} payload.scene_id - ID of the scene to be removed.
 */
export const removeSceneFromState = (state, { controller_id, scene_id }) => {
    if (!isObject(state?.[controller_id]?.[scene_id])) {
        return;
    }
    delete state[controller_id][scene_id];
};

/**
 * Updates the status of a scene in the state.
 *
 * @param {Object} state - The current state.
 * @param {Object} payload - The scene payload object to update.
 * @param {string} payload.controller_id - ID of the controller the scene belongs to.
 * @param {string} payload.scene_id - ID of the scene to be updated.
 * @param {string} payload.status - The new status for the scene.
 */
export const updateSceneStatusInState = (state, { controller_id, scene_id, status }) => {
    if (!isObject(state?.[controller_id])) {
        state[controller_id] = { [scene_id]: { status } };

        return;
    }

    if (!isObject(state?.[controller_id]?.[scene_id])) {
        state[controller_id][scene_id] = { status };

        return;
    }

    state[controller_id][scene_id].status = status;
};
/**
 * Handles errors when trying to stop a scene.
 *
 * @param {Error} error - The error received when trying to stop the scene.
 * @param {string} sceneId - The ID of the scene where the error occurred.
 * @param {Array<Object>} scenes - The list of scenes.
 */
export const handleStopSceneError = (error, sceneId, scenes) => {
    const sceneData = scenes.find((scene) => scene._id === sceneId);
    const errorContent = (
        <ErrorToastContentWithTitleAndDetails
            title={sceneData?.name}
            operationTKey={EZLOGIC_TITLE_SCENE_STOP}
            errorMessage={error.message}
        />
    );
    bugsnagNotifyWrapper(errorContent, { error, call: 'hub.scenes.stop', sceneData });
};
