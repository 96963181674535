import * as types from '../constants/ActionTypes/Integrations';
import produce from 'immer';
import { LOGOUT } from '../constants/ActionTypes';
import { ABSTRACT_SOURCES } from '../containers/Ezlo/EzloVirtualDeviceManager/constants';
import { getParjectNamesToFilter } from '../containers/Ezlo/EzloRule/EditForm/RuleSettings/components/PAAS/utils';
import { PAASAuth } from '../containers/Ezlo/EzloRule/EditForm/RuleSettings/components/PAAS/paas-constants';
import { MY_INTEGRATIONS_TAB } from '../constants/Integrations';

const INITIAL_STATE = {
    integrations: {},
    abstractState: [],
    cloudVariables: {
        currentValue: {},
    },
    userIntegrations: {},
    isIntegrationsLoading: true,
    isIntegrationUpdating: false,
    isEnrollmentInProgress: false,
    isAbstractsLoading: false,
    fetchIntegrationsError: null,
    fetchAbstractsError: null,
    accountsInfo: {},
    isAccountInfoLoading: false,
    activeIntegrationsTab: MY_INTEGRATIONS_TAB,
    isOpenIntegrationWaitingModal: false,
    isOpenIntegrationSuccessfulModal: false,
    isOpenIntegrationAbortedModal: false,
    isOpenIntegrationRemoveModal: false,
};

export default produce((draft, action) => {
    switch (action.type) {
        case types.FETCH_INTEGRATIONS_LIST: {
            draft.isIntegrationsLoading = true;
            draft.integrations = {};
            draft.fetchIntegrationsError = null;
            break;
        }
        case types.FETCH_INTEGRATIONS_LIST_ON_SUCCESS: {
            draft.isIntegrationsLoading = false;
            draft.integrations = action.integrations;
            break;
        }
        case types.FETCH_INTEGRATIONS_LIST_ON_ERROR: {
            draft.isIntegrationsLoading = false;
            draft.integrations = {};
            draft.fetchIntegrationsError = action.error;
            break;
        }
        case types.FETCH_ABSTRACT_LIST_DETAILED: {
            draft.isAbstractsLoading = true;
            draft.abstracts = {};
            draft.fetchAbstractsError = null;
            break;
        }

        case types.FETCH_ACCOUNTS_INFO: {
            draft.isAccountInfoLoading = true;
            break;
        }

        case types.FETCH_ABSTRACT_LIST_DETAILED_ON_SUCCESS: {
            const parjectsToFilter = getParjectNamesToFilter();
            draft.isAbstractsLoading = false;
            draft.abstracts =
                action.abstracts &&
                action.abstracts
                    .filter((abstract) => abstract.details && abstract.details.source === ABSTRACT_SOURCES.INTEGRATION)
                    .filter(({ name }) => !parjectsToFilter.includes(name))
                    .filter(({ name }) => !name.includes(PAASAuth));
            break;
        }
        case types.FETCH_ABSTRACT_LIST_DETAILED_ON_ERROR: {
            draft.isAbstractsLoading = false;
            draft.abstracts = {};
            draft.fetchAbstractsError = action.error;
            break;
        }
        case types.ENROLL_TO_INTEGRATION: {
            draft.isEnrollmentInProgress = true;
            break;
        }
        case types.ENROLL_TO_INTEGRATION_ON_SUCCESS: {
            draft.isEnrollmentInProgress = false;
            break;
        }
        case types.ENROLL_TO_INTEGRATION_ON_ERROR: {
            draft.isEnrollmentInProgress = false;
            break;
        }
        case types.SET_USER_INTEGRATIONS: {
            draft.userIntegrations = action.userIntegrations;
            break;
        }
        case types.UPDATE_INTEGRATION: {
            draft.isIntegrationUpdating = true;
            break;
        }
        case types.UPDATE_INTEGRATION_ON_SUCCESS: {
            draft.isIntegrationUpdating = false;
            break;
        }
        case types.UPDATE_INTEGRATION_ON_ERROR: {
            draft.isIntegrationUpdating = false;
            break;
        }
        case types.FETCH_ACCOUNTS_INFO_SUCCESS: {
            draft.isAccountInfoLoading = false;
            draft.accountsInfo = action.accountsInfo;
            break;
        }
        case types.UPDATE_CLOUD_VARIABLE_CURRENT_VALUE: {
            const { abstractUuid, variableName, variableData } = action.payload;
            const { currentValue } = draft.cloudVariables;
            const isCurrentValue = currentValue[abstractUuid] && currentValue[abstractUuid][variableName];
            const variableCurrentValueData = isCurrentValue ? currentValue[abstractUuid][variableName] : {};

            draft.cloudVariables.currentValue[abstractUuid] = {
                ...currentValue[abstractUuid],
                [variableName]: {
                    ...variableCurrentValueData,
                    ...variableData,
                },
            };
            break;
        }

        case types.UPDATE_ABSTRACT_STATE: {
            draft.abstractState = action.abstractsWithVariables;
            break;
        }
        case types.SET_ACTIVE_INTEGRATIONS_TAB: {
            draft.activeIntegrationsTab = action.data;
            break;
        }
        case types.SET_OPEN_INTEGRATION_WAITING_MODAL: {
            draft.isOpenIntegrationWaitingModal = action.data;
            break;
        }
        case types.SET_OPEN_INTEGRATION_SUCCESSFUL_MODAL: {
            draft.isOpenIntegrationWaitingModal = false;
            draft.isOpenIntegrationSuccessfulModal = action.data;
            break;
        }
        case types.SET_OPEN_INTEGRATION_ABORTED_MODAL: {
            draft.isOpenIntegrationWaitingModal = false;
            draft.isOpenIntegrationAbortedModal = action.data;
            break;
        }
        case types.SET_OPEN_INTEGRATION_REMOVE_MODAL: {
            draft.isOpenIntegrationRemoveModal = action.data;
            break;
        }

        case LOGOUT: {
            return INITIAL_STATE;
        }
    }
}, INITIAL_STATE);
