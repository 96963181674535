import React from 'react';

const IcDeviceMiscGarageDoorBattery = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.7819 10C13.8554 7.59196 16.9096 6.05242 20.3244 6.00131L13 2L2.2 7.9C0.9 8.6 0 10 0 11.5V25C0 26.7 1.3 28 3 28V12C3 10.9 3.9 10 5 10H11.7819ZM5 25C5 26.7 6.3 28 8 28H15.8026C14.2492 27.304 12.8785 26.2734 11.7819 25H5ZM9.91377 22H8C6.3 22 5 20.7 5 19H9.09694C9.23388 20.0512 9.51278 21.0578 9.91377 22ZM9.09694 16H8C6.3 16 5 14.7 5 13H9.91377C9.51278 13.9422 9.23388 14.9488 9.09694 16Z"
                fill="#1C1E21"
            />
            <path
                d="M26.125 12H14.875C13.875 12 13 12.9625 13 14.0625V20.9375C13 22.0375 13.875 23 14.875 23H26.125C27.125 23 28 22.0375 28 20.9375V14.0625C28 12.9625 27.125 12 26.125 12Z"
                fill="#1C1E21"
            />
            <path d="M30 20V15C31.1333 15 32 16.0833 32 17.5C32 18.9167 31.1333 20 30 20Z" fill="black" />
            <rect x="16" y="15" width="9" height="5" fill="white" />
            <path d="M23 17.25H21.0556V15L18 17.75H19.9444V20L23 17.25Z" fill="#1C1E21" />
        </svg>
    );
};

export default IcDeviceMiscGarageDoorBattery;
