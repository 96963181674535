import React, { Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeProvider } from 'lib/@mui';
import 'matchmedia-polyfill';
import 'matchmedia-polyfill/matchMedia.addListener';

import {
    Login,
    SignUp,
    ResetPasswordPage,
    FirstLoginPage,
    ForgotPassword,
    LoginWithToken,
    ItarianSso,
    ValidateEmailPage,
    Ezlo,
    AccessRevoked,
    NotFoundPage,
    EzloPolicies,
} from 'features/lazyLoading';
import Preloader from 'containers/Preloader';
import ErrorDialog from 'containers/ErrorDialog/ErrorDialog';
import LocationContainer from './LocationContainer';

// eslint-disable-next-line
import { POLICIES_AGREEMENT_PATH, LOGIN, MIOS_SMART_HOME_TITLE } from '../../constants/URLs';
import { useLocation, Routes, Route, useNavigate } from 'react-router-dom';
// import ResendConfirmation from '../ResendConfirmation/ResendConfirmation';
// import EmailConfirmation from '../EmailConfirmation/EmailConfirmation';
import PrivacyPolicyAction from '../../features/EzloPolicies/actions/EzloPoliciesAction';
import { API_ERROR_MESSAGES } from '../../constants/Errors';
import { ACCOUNT_TYPES } from '../../constants/Users';
import {
    getTheme,
    getPartnerUuidFromDomain,
    removePartnerDomainDataFromLocalStorage,
    getRootDomain,
    checkUuidFormat,
    handleFaviconUpdate,
} from '../Ezlo/utils';
import EzloCustomizationAction from '../../actions/EzloCustomization';
// import InstallerActions from '../../features/InstallerAccess/actions/InstallerAccessRights';
import { colors, ENDUSER_PERMISSION, INSTALLER_PERMISSION, ZERO } from '../Ezlo/EzloCustomization/constants';
import { getUpdatedColors, getUpdatedColorTheme, setUpdatedColors } from '../Ezlo/EzloCustomization/utils';
import { checkPartnerDomain } from '../AuthorizationPages/Login/utils';
import { MainAction } from '../../actions';
import genericActions from '../../actions/GenericActions';
import { getPoliciesAccepted, checkPoliciesAccepted } from '../../features/EzloPolicies/utils';
import { HASH, HASH_CS, NAME } from '../../constants/localStorageKeys';
import * as actions from '../../actions';
import { useTranslate } from '../../features/languages';
import { EZLOGIC_TITLE_NOTIFICATION_ONLY_RECIPIENT } from '../../constants/language_tokens';
import AccountActions from '../../actions/AccountActions';

// TODO: refactoring needed
const Main = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [delayNotFoundPage, setDelayNotFoundPage] = useState(false);
    const [currentThemeData, setCurrentThemeData] = useState({});
    // Do not remove below commented code it may need in future related to ENWT-4042
    // const { logged, isLogging, permissionRole, ezloPolicies, isCustomizationProgressing, installerAccessRights } =
    //     props.redux.state;
    const { logged, isLogging, permissionRole, ezloPolicies, isCustomizationProgressing } = props.redux.state;
    const defaultColorTheme = useSelector((state) => state?.ezloCustomization?.defaultColorTheme);
    const [policiesNotAccepted, setPoliciesNotAccepted] = useState();
    const policiesAccepted = useRef();
    // eslint-disable-next-line
    const { t } = useTranslate();

    const colorTheme = useSelector((state) => state?.ezloCustomization?.initialColorTheme);
    const customizedPartnerInfo = useSelector((state) => state?.ezloCustomization?.partnerInfo);
    const customizationData = useSelector((state) => state?.ezloCustomization);
    const isPartnerDomain = checkPartnerDomain();
    const dispatch = useDispatch();
    const { logout, showError } = props.redux.actions.GenericActions;

    useEffect(() => {
        const partnerName = customizedPartnerInfo?.name;
        if (isPartnerDomain) {
            document.title = partnerName || window?.location?.hostname;
        } else {
            document.title = MIOS_SMART_HOME_TITLE;
        }
    }, [customizedPartnerInfo?.name]);

    useEffect(() => {
        handleFaviconUpdate(customizationData?.status, customizationData?.faviconIcon);
    }, [customizationData?.status, customizationData?.faviconIcon]);

    // TODO: set ENV only once!
    useEffect(() => {
        removePartnerDomainDataFromLocalStorage();
        const { hostname, protocol } = window?.location;
        const partnerUuidFromDomain = getPartnerUuidFromDomain(hostname);
        let domain = hostname;
        if (partnerUuidFromDomain?.length) {
            domain = getRootDomain(hostname);
        }

        dispatch(MainAction.initialSetup(domain));

        const isUuidFormat = checkUuidFormat(hostname);
        if (isUuidFormat) {
            dispatch(EzloCustomizationAction.uuidDomainCustomization(hostname, protocol));
        } else {
            dispatch(EzloCustomizationAction.topLevelDomainCustomization(hostname));
        }
        const defaultUpdatedColors = getUpdatedColors(colors);
        dispatch(EzloCustomizationAction.defaultColorTheme(defaultUpdatedColors));
    }, []);

    useEffect(() => {
        if (logged) {
            dispatch(AccountActions.getProfilePicture());
        }
    }, [logged]);

    // below code may need in future ENWT-4042
    // useEffect(() => {
    //     if (logged && installerAccessRights?.isInstallerLoggedInAsResident) {
    //         const cleanupInterval = dispatch(InstallerActions.startInstallerAccessCheckInterval(navigate));

    //         return cleanupInterval;
    //     }
    // }, [logged, installerAccessRights?.isInstallerLoggedInAsResident]);

    // todo: login case

    useEffect(() => {
        if (colorTheme?.length === ZERO) {
            setUpdatedColors(defaultColorTheme);
        }
        const isPartnerDomain = checkPartnerDomain();
        const updatedColorTheme = getUpdatedColorTheme(colors, colorTheme, permissionRole, isPartnerDomain);
        if (permissionRole === ENDUSER_PERMISSION || permissionRole === INSTALLER_PERMISSION || isPartnerDomain) {
            setUpdatedColors(colorTheme);
        }
        const updatedTheme = getTheme(updatedColorTheme);
        setCurrentThemeData(updatedTheme);
    }, [colorTheme]);

    const isPreloader = useMemo(() => {
        return logged && isCustomizationProgressing;
    }, [isCustomizationProgressing, logged]);

    const isAccountReady = useMemo(() => {
        return logged && !isCustomizationProgressing;
    }, [isCustomizationProgressing, logged]);

    const getRedirectPath = () => {
        return dispatch(genericActions.getPathOfFirstPageAfterLogin);
    };

    useEffect(() => {
        if (
            !location.pathname.includes('passwordreset') &&
            !location.pathname.includes('verify') &&
            !location.pathname.includes('login-user-with-token') &&
            !location.pathname.includes('firstlogin')
        ) {
            // TODO: used to keep legacy behaviour, login if possible
            const name = localStorage.getItem(NAME);
            const hash = localStorage.getItem(HASH);
            const hashCS = localStorage.getItem(HASH_CS);
            if (name && hash && hashCS) {
                dispatch(actions.GenericActions.login(name, hash, hashCS));
            }
        }
    }, []);

    useEffect(() => {
        if (isAccountReady) {
            if (
                permissionRole ===
                ACCOUNT_TYPES.find((type) => t(type.name) === t(EZLOGIC_TITLE_NOTIFICATION_ONLY_RECIPIENT)).value
            ) {
                logout();
                dispatch(showError(API_ERROR_MESSAGES.NO_ACCESS));
            }

            const path = getRedirectPath();

            if (policiesAccepted.current && path) {
                dispatch(PrivacyPolicyAction.setPreviousPath(path));
                navigate(POLICIES_AGREEMENT_PATH);
            } else if (path) {
                navigate(path);
            }
            setDelayNotFoundPage(true);
        } else if (
            !location.pathname.includes('passwordreset') &&
            !location.pathname.includes('verify') &&
            !location.pathname.includes('login-user-with-token') &&
            !location.pathname.includes('firstlogin')
        ) {
            // TODO: more possible issues here, applied only a hotfix.
            // TODO: we must not redirect all the time when isAccountReady = false and policiesAccepted.current changes.
            // TODO: allocate time and design application loading scheme on miro together with permissions tasks
            navigate(LOGIN);
            setDelayNotFoundPage(false);
        }
    }, [isAccountReady, policiesAccepted.current]);

    // todo: offline case
    // useEffect(() => {
    //     if (ezlos) {
    //         const firstAvailableEzlo = ezlos.find((ezlo) => ezlo.connected);
    //         if (!firstAvailableEzlo) {
    //             setOffline(true)
    //             navigate(GET_OFFLINE_EZLO(ezlos[0].serial))
    //         }
    //     }
    // }, [ezlos]);

    useEffect(() => {
        const policiesAccepted = getPoliciesAccepted(ezloPolicies);
        dispatch(PrivacyPolicyAction.policiesAccepted(policiesAccepted));
    }, [ezloPolicies?.eula, ezloPolicies?.setConsent, ezloPolicies?.notices]);

    useEffect(() => {
        const isPoliciesNotAccepted = checkPoliciesAccepted(ezloPolicies?.policiesAccepted);
        policiesAccepted.current = isPoliciesNotAccepted;
        setPoliciesNotAccepted(policiesAccepted.current);
    }, [ezloPolicies?.policiesAccepted]);

    useEffect(() => {
        if (policiesAccepted.current) {
            navigate(POLICIES_AGREEMENT_PATH);
        }
    }, [location.pathname]);

    return (
        <Suspense>
            <ThemeProvider theme={currentThemeData}>
                <LocationContainer>
                    <main className="page-container">
                        {isPreloader && <Preloader />}
                        {!logged && (
                            <Routes>
                                <Route path="/login" Component={Login} />
                                <Route path="/login-user-with-token" Component={LoginWithToken} />
                                <Route path="/access-revoked" Component={AccessRevoked} />
                                <Route path="/itarian-sso" Component={ItarianSso} />
                                <Route path="/passwordreset/*" Component={ResetPasswordPage} />
                                <Route path="/firstlogin/*" Component={FirstLoginPage} />
                                <Route path="/register" Component={SignUp} />
                                <Route path="/forgot-password" Component={ForgotPassword} />
                                <Route path="/verify/*" Component={ValidateEmailPage} />
                                {/* <Route path="/resend-confirmation" component={ResendConfirmation} /> */}
                                {!isLogging ? <Route path="/*" Component={NotFoundPage} /> : null}
                            </Routes>
                        )}
                        {logged && policiesNotAccepted && (
                            <Routes>
                                <Route path={POLICIES_AGREEMENT_PATH} Component={EzloPolicies} />
                            </Routes>
                        )}
                        {isAccountReady && !policiesNotAccepted && (
                            <Routes>
                                <Route
                                    path="/ezlo/*"
                                    element={
                                        <Suspense fallback={<Preloader />}>
                                            <Ezlo {...props} />
                                        </Suspense>
                                    }
                                />
                                {delayNotFoundPage ? <Route path="*" Component={NotFoundPage} /> : null}
                            </Routes>
                        )}
                        <ErrorDialog />
                    </main>
                </LocationContainer>
            </ThemeProvider>
        </Suspense>
    );
};

export default Main;
