import React, { useEffect, useState } from 'react';
import Bugsnag from '@bugsnag/js';
import oemManager from '../../../services/oem';
import { initializeBugsnag, isBugsnagInitialized } from '../initialize';
import { BUGSNAG_REACT_PLUGIN_NAME } from '../constants';

// Hook to initialize ErrorBoundary component
const useErrorBoundary = () => {
    const [ErrorBoundaryInstance, setReactErrorBoundaryInstance] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (isBugsnagInitialized) {
            return;
        }

        const config = oemManager.getBugsnagConfig();

        // try to initialize bugsnag
        const isInitialized = initializeBugsnag(config);

        if (isInitialized) {
            const ErrorBoundary = Bugsnag.getPlugin(BUGSNAG_REACT_PLUGIN_NAME).createErrorBoundary(React);
            setReactErrorBoundaryInstance(() => ErrorBoundary);
        }
        setLoading(false);
    }, []);

    return [ErrorBoundaryInstance, loading];
};

export default useErrorBoundary;
