import React from 'react';

const IcActionsThermostatModeHeat = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
            <path
                d="M25.9999 11.9V6.00002H20.0999L15.9999 1.90002L11.8999 6.00002H5.9999V11.9L1.8999 16L5.9999 20.1V26H11.8999L15.9999 30.1L20.0999 26H25.9999V20.1L30.0999 16L25.9999 11.9ZM15.9999 22C12.6999 22 9.9999 19.3 9.9999 16C9.9999 12.7 12.6999 10 15.9999 10C19.2999 10 21.9999 12.7 21.9999 16C21.9999 19.3 19.3999 22 15.9999 22Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcActionsThermostatModeHeat;
