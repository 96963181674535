import React from 'react';
import { DatePicker } from 'lib/@mui';
import { dayjs } from 'lib/daysjs';

import { EZLOGIC_TITLE_INCORRECT_DATE } from 'constants/language_tokens';
import { useTranslate } from 'features/languages';
import { INLINE, NORMAL } from 'constants/MeshbotConstant';
import { YEAR_LABEL, MONTH_LABEL, DAY_LABEL } from '../../../../constants';

const SystemHistorySearchByDatePicker = (props) => {
    const { selectedDate, handleChangeDate, placeholder, isDateValid } = props;
    const { t } = useTranslate();

    return (
        <DatePicker
            disableToolbar
            variant={INLINE}
            views={[YEAR_LABEL, MONTH_LABEL, DAY_LABEL]}
            margin={NORMAL}
            value={dayjs(selectedDate)}
            onChange={handleChangeDate}
            placeholder={placeholder}
            error={!isDateValid}
            helperText={!isDateValid && t(EZLOGIC_TITLE_INCORRECT_DATE)}
        />
    );
};

export default SystemHistorySearchByDatePicker;
