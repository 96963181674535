import { toast } from 'react-toastify';

export const TOAST_TYPE = toast.TYPE;

export const DEFAULT_TOAST_OPTIONS = {
    type: toast.TYPE.INFO,
    position: toast.POSITION.TOP_RIGHT,
    theme: 'colored',
    autoClose: 5000,
};

/**
 * Build options for 'react-toastify' toast function
 * @param {Object} [options] - provided options
 * @returns {Object} prepared options
 * */
export const buildToastOptions = (options) => {
    let mergedOptions = DEFAULT_TOAST_OPTIONS;
    if (options && typeof options === 'object') {
        mergedOptions = { ...mergedOptions, ...options };
    }

    return mergedOptions;
};
