import { INT_RANGE } from '../../constants/DynamicUI';

export const getNumberError = (min, max, value) => {
    if (value !== '') {
        const numberValue = Number(value);
        if (!!min && numberValue < min) {
            return `min: ${min}`;
        }

        if (!!max && numberValue > max) {
            return `max: ${max}`;
        }
    }

    return false;
};

export const getMinMax = (input) => {
    const { format } = input;
    const fieldSettings = {
        min: input.minimum,
        max: input.maximum,
    };

    if (format) {
        const { minimum, maximum } = input;
        const intMin = INT_RANGE[format].min;
        const intMax = INT_RANGE[format].max;

        fieldSettings.min = minimum && minimum > intMin ? minimum : intMin;
        fieldSettings.max = maximum && maximum < intMax ? maximum : intMax;
    }

    return fieldSettings;
};
