import moment from 'moment';
import { formatPhoneNumberIntl, isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';

export const getTomorrowDate = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    return moment(tomorrow).format('YYYY-MM-DD hh:mm:ss');
};

export const getIssueForSupportTicket = ({ issue }) => {
    return issue;
};

export const getTicketCreatePayload = ({
    first_name,
    last_name,
    email,
    phone,
    issue,
    userUuid,
    priority,
    helptopic,
}) => {
    return {
        data: {
            type: 'tickets',
            attributes: {
                duedate: getTomorrowDate(),
                ticket_thread: {
                    subject: 'Resident Request',
                    detail: getIssueForSupportTicket({ first_name, last_name, email, phone, issue }),
                    notes: '',
                },
            },
            relationships: {
                slaplan: {
                    data: {
                        type: 'slaplans',
                        id: '1',
                    },
                },
                user: {
                    data: {
                        type: 'users',
                        id: String(userUuid),
                    },
                },
                staff: {
                    data: {
                        type: 'users',
                        id: String(userUuid),
                    },
                },
                helptopic: {
                    data: {
                        type: 'helptopics',
                        id: helptopic,
                    },
                },
                ticketpriority: {
                    data: {
                        type: 'ticketpriorities',
                        id: priority,
                    },
                },
            },
        },
    };
};

export const getDataForUpdateTicket = (value, name) => {
    return {
        key: name,
        value,
    };
};

export const getEncodeValue = (customizationData) => {
    return customizationData?.data?.data?.list?.find((data) => data.value)?.value || '';
};
export const isValidWebsite = (url) => {
    const urlRegex = /^(https:\/\/|http:\/\/)([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?$/;

    return urlRegex.test(url);
};

export const getCurrentPhone = ({ supportPhone, phoneFromConfig }) => {
    if (supportPhone && isPossiblePhoneNumber(supportPhone) && isValidPhoneNumber(supportPhone)) {
        return formatPhoneNumberIntl(supportPhone);
    }

    if (phoneFromConfig && isPossiblePhoneNumber(phoneFromConfig) && isValidPhoneNumber(phoneFromConfig)) {
        return formatPhoneNumberIntl(phoneFromConfig);
    }

    return '';
};

export const getCurrentWebsite = ({ supportWebsite, websiteFromConfig }) => {
    if (supportWebsite && isValidWebsite(supportWebsite)) {
        return supportWebsite;
    }

    if (websiteFromConfig && isValidWebsite(websiteFromConfig)) {
        return websiteFromConfig;
    }

    return '';
};

export const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(email);
};

export const getCurrentEmail = ({ supportEmail, emailFromConfig }) => {
    if (supportEmail && isValidEmail(supportEmail)) {
        return supportEmail;
    }

    if (emailFromConfig && isValidEmail(emailFromConfig)) {
        return emailFromConfig;
    }

    return '';
};
