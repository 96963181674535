import { ADDRESSABLE_LED_LABEL_TAG } from 'constants/Devices';
import { ADDRESSABLE_MAIN, LED_STRIP } from 'constants/SystemHistory';
import {
    EZLOGIC_TITLE_DEVICE_NOT_FOUND,
    EZLOGIC_TITLE_NOT_SUPPORTED,
    EZLOGIC_TITLE_ADDRESSABLE_LED_DEVICE_NOT_FOUND,
} from 'constants/language_tokens';
import { DEVICE_NOT_FOUND, DEVICE_NOT_SUPPORTED, HTTP_PROTOCOL_PREFIX, STANDARD_HTTPS_PORT } from '../constants';
import { HTTPS_PROTOCOL_PREFIX } from 'services/oem/src/utils';

/**
 * Retrieves the addressable LED setting data for a specific device.
 * Filters the provided settings based on the device ID, label lang tag, and non-empty value.
 *
 * @param {array} settings - The array of settings to search through.
 * @param {string} deviceId - The ID of the target device.
 * @returns {object|null} The addressable LED setting data for the specified device or null if not found.
 */
export const getAddressableLedSettingData = (settings, deviceId) => {
    const addressableSettingsData = settings?.find(
        (setting) =>
            setting?.deviceId === deviceId &&
            setting?.label?.lang_tag === ADDRESSABLE_LED_LABEL_TAG &&
            setting?.value !== '',
    );

    return addressableSettingsData || null;
};

/**
 * Retrieves details for a specific device based on its ID from the provided array of devices.
 * Checks for the existence of the device and its settings, and validates if it is a supported LED_STRIP category device.
 *
 * @param {string} deviceId - The ID of the target device.
 * @param {array} devices - The array of devices to search through.
 * @returns {object} An object containing device details, including ipAddress and error information.
 */
export const getDeviceDetails = (deviceId, devices) => {
    const device = devices?.find((device) => device?._id === deviceId);

    if (!device) {
        return {
            title: EZLOGIC_TITLE_DEVICE_NOT_FOUND,
            description: EZLOGIC_TITLE_ADDRESSABLE_LED_DEVICE_NOT_FOUND,
            error: DEVICE_NOT_FOUND,
            ipAddress: null,
        };
    }

    const ipAddressSetting = getAddressableLedSettingData(device?.settings, deviceId);

    if (!device?.settings || !ipAddressSetting || !ipAddressSetting?.value || device?.category !== LED_STRIP) {
        return {
            title: EZLOGIC_TITLE_NOT_SUPPORTED,
            description: EZLOGIC_TITLE_ADDRESSABLE_LED_DEVICE_NOT_FOUND,
            error: DEVICE_NOT_SUPPORTED,
            ipAddress: null,
        };
    }

    const ipAddress = ipAddressSetting?.value;

    return {
        ipAddress: ipAddress,
        error: DEVICE_NOT_FOUND,
    };
};

/**
 * Retrieves an array of addressable LED devices from the provided devices.
 * Filters devices based on category and subcategory, specifically led_strip and addressable_main.
 *
 * @param {array} devices - The array of devices to filter.
 * @returns {array} An array of addressable LED devices, each containing name, _id, and serial properties.
 */
export const getAddressableLedDevices = (devices) => {
    if (!devices) {
        return [];
    }

    return devices?.reduce((addressableLedDevices, device) => {
        if (device?.category === LED_STRIP && device?.subcategory === ADDRESSABLE_MAIN) {
            const ledSettingData = getAddressableLedSettingData(device?.settings, device?._id);
            addressableLedDevices?.push({
                name: device?.name,
                _id: device?._id,
                serial: device?.serial,
                category: device?.category,
                ipAddress: ledSettingData?.value,
            });
        }

        return addressableLedDevices;
    }, []);
};

/**
 * Finds a menu item or sub-item in a menu structure based on the provided pathname.
 * The function recursively searches through the menuItems array and its subItems.
 *
 * @param {string} pathname - The current pathname to match against menu item routes.
 * @param {array} menuItems - The array of menu items to search through.
 * @returns {object|null} The matching menu item or sub-item, or null if not found.
 */
export const getMenuItemByPathname = (pathname, menuItems) => {
    if (!Array.isArray(menuItems)) {
        return null;
    }

    for (const menuItem of menuItems) {
        if (menuItem?.route && menuItem?.route?.test(pathname)) {
            return menuItem;
        }

        if (menuItem?.subItems) {
            const nestedMenuItem = getMenuItemByPathname(pathname, menuItem.subItems);
            if (nestedMenuItem) {
                return nestedMenuItem;
            }
        }
    }

    return null;
};

export const createIpAddressUrl = (ipAddress) => {
    if (!ipAddress) {
        return '';
    }

    return `${HTTPS_PROTOCOL_PREFIX}${ipAddress}:${STANDARD_HTTPS_PORT}`;
};

export const createHttpIpAddressUrl = (ipAddress) => {
    if (!ipAddress) {
        return '';
    }

    return `${HTTP_PROTOCOL_PREFIX}${ipAddress}`;
};

export default {
    getDeviceDetails,
    getAddressableLedSettingData,
    getAddressableLedDevices,
    getMenuItemByPathname,
    createHttpIpAddressUrl,
};
