import React from 'react';

export const VirtualDeviceIcon = (props) => (
    <svg {...props} viewBox="0 0 56 56" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M40.9905 20.5L28.0001 13L15.0098 20.5V35.5L28.0001 43L40.9905 35.5V20.5ZM37.9593 22.25L28 16.5L18.0407 22.25V33.75L28 39.5L37.9593 33.75V22.25Z"
        />
        <path d="M26.4361 36L21 22H24.7473L27.2855 29.1418L28.025 31.5224C28.2148 30.9146 28.3448 30.5195 28.3947 30.3271C28.5146 29.932 28.6445 29.5369 28.7744 29.1418L31.3326 22H35L29.6538 35.9899L26.4361 36Z" />
    </svg>
);

export default VirtualDeviceIcon;
