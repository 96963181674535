import React from 'react';

export const ExpandArrowUp = (props) => (
    <svg {...props} viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>ezlo</title>
        <path
            d="M15.2287 6.97143L9.42121 1.16395C9.07841 0.821152 8.61347 0.62857 8.12868 0.62857C7.64389 0.62857 7.17896 0.821152 6.83616 1.16395L1.02868 6.97143C0.192969 7.80714 0.192968 9.16428 1.02868 10L8.12868 2.9L15.2287 10C16.0644 9.16429 16.0644 7.80714 15.2287 6.97143Z"
            fill="#1C1E21"
        />
    </svg>
);

export default ExpandArrowUp;
