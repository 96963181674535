import React from 'react';

const IcCategoryDevice = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="black" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.76923 28H25.2308C26.8 28 28 26.8 28 25.2308V6.76923C28 5.2 26.8 4 25.2308 4H6.76923C5.2 4 4 5.2 4 6.76923V25.2308C4 26.8 5.2 28 6.76923 28ZM8 11C8 12.65 9.35 14 11 14C12.65 14 14 12.65 14 11C14 9.35 12.65 8 11 8C9.35 8 8 9.35 8 11Z"
            />
        </svg>
    );
};

export default IcCategoryDevice;
