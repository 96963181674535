import { API_CLOUD_EZLO_REQUEST_URL } from '../constants/URLs';
import { axiosCloudConfig } from './config';

/**
 * Sends 'controller_group_set' API call to Cloud
 * More info you can find here {@link https://confluence.mios.com/pages/viewpage.action?spaceKey=EPD&title=Controller+Group+-+Set controller_group_set}
 * @param {string} body - "controller_group_set" request body
 * @returns {Promise} API call response body
 * */
export const apiControllerGroupSet = async (body) => {
    const res = await fetch(API_CLOUD_EZLO_REQUEST_URL(), {
        method: 'post',
        ...axiosCloudConfig(),
        body,
    });

    return res.json();
};
