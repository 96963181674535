export default {
    CREATE_ITEM: {
        success: 'CREATE_ITEM/success',
        rejected: 'CREATE_ITEM/rejected',
    },
    DELETE_ITEM: {
        success: 'DELETE_ITEM/success',
        rejected: 'DELETE_ITEM/rejected',
    },
    CREATE_ITEM_IN_GROUP: {
        success: 'CREATE_ITEM_IN_GROUP/success',
        rejected: 'CREATE_ITEM_IN_GROUP/rejected',
    },
    DELETE_ITEM_IN_GROUP: {
        success: 'DELETE_ITEM_IN_GROUP/success',
        rejected: 'DELETE_ITEM_IN_GROUP/rejected',
    },
    CHANGE_TO_GROUP: {
        success: 'CHANGE_TO_GROUP/success',
        rejected: 'CHANGE_TO_GROUP/rejected',
    },
    UPDATE_ITEM: {
        success: 'UPDATE_ITEM/success',
        rejected: 'UPDATE_ITEM/rejected',
    },
    CREATE_ITEM_SELECT: {
        success: 'CREATE_ITEM_SELECT/success',
        rejected: 'CREATE_ITEM_SELECT/rejected',
    },
    GROUP_INFO: {
        success: 'GROUP_INFO/success',
        rejected: 'GROUP_INFO/rejected',
    },
    DELETE_GROUP_INFO: {
        success: 'DELETE_GROUP_INFO/success',
        rejected: 'DELETE_GROUP_INFO/rejected',
    },
    CREATE_ACTION: {
        success: 'CREATE_ACTION/success',
        rejected: 'CREATE_ACTION/rejected',
    },
    CREATE_ACTION_GROUP_TRIGGER: {
        success: 'CREATE_ACTION_GROUP_TRIGGER/success',
        rejected: 'CREATE_ACTION_GROUP_TRIGGER/rejected',
    },
    UPDATE_ACTION_FIELD: {
        success: 'UPDATE_ACTION_FIELD/success',
        rejected: 'UPDATE_ACTION_FIELD/rejected',
    },
    UPDATE_FIELD_ACTION: {
        success: 'UPDATE_ACTION_FIELD/success',
        rejected: 'UPDATE_ACTION_FIELD/rejected',
    },
    COPE_ACTION: {
        success: 'COPE_ACTION/success',
        rejected: 'COPE_ACTION/rejected',
    },
    SAVED_GROUP: {
        success: 'SAVED_GROUP/success',
        rejected: 'SAVED_GROUP/rejected',
    },
    SAVED_ACTIONS: {
        success: 'SAVED_ACTIONS/success',
        rejected: 'SAVED_ACTIONS/rejected',
    },

    //--------------------------with-api-----------------------------------

    GET_DEVICE_BLOCKS: {
        success: 'GET_DEVICE_BLOCKS/success',
        rejected: 'GET_DEVICE_BLOCKS/rejected',
    },
    SAVE_DEVICE_BLOCKS: {
        success: 'SAVE_DEVICE_BLOCKS/success',
        rejected: 'SAVE_DEVICE_BLOCKS/rejected',
    },
    GET_DEVICE_BLOCKS_ACTION: {
        success: 'GET_DEVICE_BLOCKS_ACTION/success',
        rejected: 'GET_DEVICE_BLOCKS_ACTION/rejected',
    },
    ADD_TRIGGER: {
        success: 'ADD_TRIGGER/success',
        rejected: 'ADD_TRIGGER/rejected',
    },
    ADD_BLOCK_WHEN: {
        success: 'ADD_BLOCK_WHEN/success',
        rejected: 'ADD_BLOCK_WHEN/rejected',
    },
    ADD_BLOCK_THEN: {
        success: 'ADD_BLOCK_THEN/success',
        rejected: 'ADD_BLOCK_THEN/rejected',
    },
    ADD_TRIGGER_ACTION: {
        success: 'ADD_TRIGGER_ACTION/success',
        rejected: 'ADD_TRIGGER_ACTION/rejected',
    },
    DELETE_TRIGGER: {
        success: 'DELETE_TRIGGER/success',
        rejected: 'DELETE_TRIGGER/rejected',
    },
    DELETE_TRIGGER_ACTION: {
        success: 'DELETE_TRIGGER_ACTION/success',
        rejected: 'DELETE_TRIGGER_ACTION/rejected',
    },
    UPDATE_TRIGGER: {
        success: 'UPDATE_TRIGGER/success',
        rejected: 'UPDATE_TRIGGER/rejected',
    },
    UPDATE_SELECTED_RULE_NOT: {
        success: 'UPDATE_SELECTED_RULE_NOT/success',
        rejected: 'UPDATE_SELECTED_RULE_NOT/rejected',
    },
    UPDATE_TRIGGER_ACTION: {
        success: 'UPDATE_TRIGGER_ACTION/success',
        rejected: 'UPDATE_TRIGGER_ACTION/rejected',
    },
    UPDATE_FIELD_BLOCK: {
        success: 'UPDATE_FIELD_BLOCK/success',
        rejected: 'UPDATE_FIELD_BLOCK/rejected',
    },
    UPDATE_TRIGGER_BLOCK: {
        success: 'UPDATE_TRIGGER_BLOCK/success',
        rejected: 'UPDATE_TRIGGER_BLOCK/rejected',
    },
    UPDATE_FIELD_BLOCK_ACTION: {
        success: 'UPDATE_FIELD_BLOCK_ACTION',
        rejected: 'UPDATE_FIELD_BLOCK_ACTION',
    },
    UPDATE_FIELD_SELECT_RULE: {
        success: 'UPDATE_FIELD_SELECT_RULE/success',
        rejected: 'UPDATE_FIELD_SELECT_RULE/rejected',
    },
    UPDATE_FIELD_SELECT_RULE_FOR_ACTIONS: {
        success: 'UPDATE_FIELD_SELECT_RULE_FOR_ACTIONS',
        rejected: 'UPDATE_FIELD_SELECT_RULE_FOR_ACTIONS',
    },
    CLEAR_FIELD_SELECTED_RULE: {
        success: 'CLEAR_FIELD_SELECTED_RULE/success',
        rejected: 'CLEAR_FIELD_SELECTED_RULE/rejected',
    },
    CLEAR_FIELD_RULE_TRIGGERS: {
        success: 'CLEAR_FIELD_RULE_TRIGGERS/success',
        rejected: 'CLEAR_FIELD_RULE_TRIGGERS/rejected',
    },
    CLEAR_RULE_SELECTED_BLOCK: {
        success: 'CLEAR_RULE_SELECTED_BLOCK/success',
        rejected: 'CLEAR_RULE_SELECTED_BLOCK/rejected',
    },
    CHANGE_ACTIVE_BLOCK: {
        success: 'CHANGE_ACTIVE_BLOCK/success',
        rejected: 'CHANGE_ACTIVE_BLOCK/rejected',
    },
    CHANGE_ACTIVE_BLOCK_ACTION: {
        success: 'CHANGE_ACTIVE_BLOCK_ACTION',
        rejected: 'CHANGE_ACTIVE_BLOCK_ACTION',
    },
    UPDATE_SELECT_DATE: {
        success: 'UPDATE_SELECT_DATE/success',
        rejected: 'UPDATE_SELECT_DATE/rejected',
    },
    UPDATE_DATE_VALUE: {
        success: 'UPDATE_DATE_VALUE/success',
        rejected: 'UPDATE_DATE_VALUE/rejected',
    },
    UPDATE_NAME_GROUP: {
        success: 'UPDATE_NAME_GROUP/success',
        rejected: 'UPDATE_NAME_GROUP/rejected',
    },
    GET_EXPRESSION_LIST: {
        success: 'GET_EXPRESSION_LIST/success',
        rejected: 'GET_EXPRESSION_LIST/rejected',
    },
    UPDATE_EXPRESSION: {
        success: 'UPDATE_EXPRESSION/success',
        rejected: 'UPDATE_EXPRESSION/rejected',
    },
    CREATE_EXPRESSION: {
        success: 'CREATE_EXPRESSION/success',
        rejected: 'CREATE_EXPRESSION/rejected',
    },
    DELETE_EXPRESSION: {
        success: 'DELETE_EXPRESSION/success',
        rejected: 'DELETE_EXPRESSION/rejected',
    },
    UPDATE_BLOCK_EXPRESSION: {
        success: 'UPDATE_BLOCK_EXPRESSION/success',
        rejected: 'UPDATE_BLOCK_EXPRESSION/rejected',
    },
};
