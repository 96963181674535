import { cloneDeep } from 'lodash';

import at from '../constants/ActionTypes/DeviceGroups';
import { DEVICE_GROUP_TREE_ITEM } from '../constants/DevicesGroups';

import { bugsnagNotifyWrapper, bugsnagNotify } from '../containers/ErrorBoundary/utils';
import {
    setCategoriesValues,
    setInitialDeviceGroupTreeValues,
    prepareListIntegratedDevicesForUI,
} from '../containers/Ezlo/EzloDeviceGroups/utils';
import wsm from '../helpers/wsm';
import { setStaticDataIntegratedDevicesInfo, deleteStaticDataIntegratedDevicesInfo } from '../reducers/staticData';

const DeviceGroupsActions = {
    fetchDeviceGroupsList: (serial) => async (dispatch) => {
        await wsm.send(
            serial,
            'hub.device.groups.list',
            {},
            (data) => {
                dispatch({ type: at.SET_DEVICE_GROUPS_LIST.success, data, serial });
            },
            (error) => {
                bugsnagNotify(error, { serial });
            },
        );
    },

    removeDeviceGroup: (serial, id) => async (dispatch) => {
        await wsm.send(
            serial,
            'hub.device.group.delete',
            { id },
            () => {
                dispatch({ type: at.REMOVE_DEVICE_GROUP.success, serial, id });
            },
            (error) => {
                bugsnagNotifyWrapper(error, { type: at.REMOVE_DEVICE_GROUP.rejected });
            },
        );
    },

    createDeviceGroup: (serial, params) => async (dispatch) => {
        await wsm.send(
            serial,
            'hub.device.group.create',
            { ...params },
            (data) => {
                dispatch({ type: at.CREATE_DEVICE_GROUP.success, serial, data });
            },
            (error) => {
                bugsnagNotifyWrapper(error, { type: at.CREATE_DEVICE_GROUP.rejected });
            },
        );
    },

    getDeviceGroupById: (serial, id) => async (dispatch) => {
        await wsm.send(
            serial,
            'hub.device.group.get',
            { id },
            (data) => {
                dispatch({ type: at.GET_DEVICE_GROUP.success, serial, data });
                dispatch(DeviceGroupsActions.setInitialDeviceGroupTreeState(data));
                dispatch(DeviceGroupsActions.setDeviceGroupId(data?._id));
                dispatch(DeviceGroupsActions.setDeviceGroupSerial(serial));
            },
            (error) => {
                bugsnagNotifyWrapper(error, { type: at.GET_DEVICE_GROUP.rejected });
            },
        );
    },

    updateDeviceGroup: (serial, params) => async (dispatch) => {
        await wsm.send(
            serial,
            'hub.device.group.update',
            { ...params },
            (data) => {
                dispatch({ type: at.UPDATE_DEVICE_GROUP.success, serial, data });
            },
            (error) => {
                bugsnagNotifyWrapper(error, { type: at.UPDATE_DEVICE_GROUP.rejected });
            },
        );
    },

    buildDeviceGroupTreeState:
        (devicesTreeObject = {}, devicesList = []) =>
        (dispatch) => {
            const deviceGroupState = cloneDeep(devicesTreeObject);
            const { categories } = deviceGroupState;

            setCategoriesValues(categories, devicesList);

            dispatch({
                type: at.SET_DEVICE_GROUP_TREE_STATE,
                deviceGroupState,
            });
        },

    setInitialDeviceGroupTreeState: (payload) => (dispatch, getState) => {
        const state = getState();
        const { categories, devices, exceptions } = payload;
        const { deviceGroupTreeState } = state?.deviceGroups;
        const newDeviceGroupTreeState = cloneDeep(deviceGroupTreeState);

        setInitialDeviceGroupTreeValues({
            categories,
            devices,
            exceptions,
            newDeviceGroupTreeState,
        });

        dispatch({
            type: at.SET_INITIAL_DEVICE_GROUP_TREE_STATE,
            newDeviceGroupTreeState,
        });
    },

    updateDeviceGroupTreeStateByCategory: (payload) => ({
        type: at.UPDATE_DEVICE_GROUP_TREE_STATE_BY_CATEGORY,
        payload,
    }),

    updateDeviceGroupTreeStateBySubcategory: (payload) => ({
        type: at.UPDATE_DEVICE_GROUP_TREE_STATE_BY_SUBCATEGORY,
        payload,
    }),

    updateDeviceGroupTreeStateByDevice: (payload) => ({
        type: at.UPDATE_DEVICE_GROUP_TREE_STATE_BY_DEVICE,
        payload,
    }),

    buildDeviceGroupObject: (deviceGroupObject) => ({
        type: at.BUILD_DEVICE_GROUP_OBJECT,
        deviceGroupObject,
    }),

    setDeviceGroupName: (deviceGroupName) => ({
        type: at.SET_DEVICE_GROUP_NAME,
        deviceGroupName,
    }),

    setDeviceGroupCategory: (deviceGroupCategory) => ({
        type: at.SET_DEVICE_GROUP_CATEGORY,
        deviceGroupCategory,
    }),

    setDeviceGroupSubcategory: (deviceGroupSubcategory) => (dispatch, getState) => {
        const state = getState();
        const { deviceGroupTreeState } = state?.deviceGroups;
        const deviceGroup = cloneDeep(state?.deviceGroups?.currentDeviceGroup);

        const deviceGroupCategory = Object.values(deviceGroupTreeState?.categories).find((category) => {
            return category.subcategories[deviceGroupSubcategory];
        });

        const selectedDeviceGroupCategory = deviceGroup?.categories.find((category) => {
            return category.category === deviceGroupCategory.id;
        });

        const selectedDeviceGroupCategoryIndex = deviceGroup.categories.findIndex((category) => {
            return selectedDeviceGroupCategory === category;
        });

        if (selectedDeviceGroupCategory && !selectedDeviceGroupCategory?.subcategories) {
            deviceGroup.categories[selectedDeviceGroupCategoryIndex] = {
                category: selectedDeviceGroupCategory.category,
                subcategories: [deviceGroupSubcategory],
            };
        }

        if (selectedDeviceGroupCategory?.subcategories) {
            deviceGroup.categories[selectedDeviceGroupCategoryIndex] = {
                category: selectedDeviceGroupCategory.category,
                subcategories: [
                    ...deviceGroup.categories[selectedDeviceGroupCategoryIndex].subcategories,
                    deviceGroupSubcategory,
                ],
            };
        }

        if (!selectedDeviceGroupCategory) {
            deviceGroup.categories = [
                {
                    category: deviceGroupCategory.id,
                    subcategories: [deviceGroupSubcategory],
                },
                ...deviceGroup.categories,
            ];
        }

        dispatch({
            type: at.SET_DEVICE_GROUP_SUBCATEGORY,
            deviceGroup,
        });
    },

    removeDeviceGroupSubcategory: (subcategory) => (dispatch, getState) => {
        const state = getState();
        const { deviceGroupTreeState } = state?.deviceGroups;
        const deviceGroup = cloneDeep(state?.deviceGroups?.currentDeviceGroup);

        const deviceGroupCategory = Object.values(deviceGroupTreeState?.categories).find((category) => {
            return category.subcategories[subcategory];
        });

        const selectedDeviceGroupCategory = deviceGroup?.categories.find((category) => {
            return category.category === deviceGroupCategory.id;
        });

        const selectedDeviceGroupCategoryIndex = deviceGroup.categories.findIndex((category) => {
            return selectedDeviceGroupCategory === category;
        });

        deviceGroup.categories[selectedDeviceGroupCategoryIndex].subcategories =
            selectedDeviceGroupCategory?.subcategories?.filter((selectedSubcategory) => {
                return selectedSubcategory !== subcategory;
            });

        dispatch({
            type: at.REMOVE_DEVICE_GROUP_SUBCATEGORY,
            deviceGroup,
        });

        if (
            !selectedDeviceGroupCategory?.subcategories.length &&
            deviceGroupCategory._ui.state !== DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.INCLUDED
        ) {
            dispatch(DeviceGroupsActions.removeDeviceGroupCategory(selectedDeviceGroupCategory.category));
        }
    },

    setDeviceGroupDeviceItem: (deviceItem) => ({
        type: at.SET_DEVICE_GROUP_DEVICE_ITEM,
        deviceItem,
    }),

    setDeviceGroupExceptionItem: (exceptedItem) => ({
        type: at.SET_DEVICE_GROUP_EXCEPT_DEVICE,
        exceptedItem,
    }),

    removeDeviceGroupCategory: (category) => ({
        type: at.REMOVE_DEVICE_GROUP_CATEGORY,
        category,
    }),

    removeDeviceGroupDeviceItem: (device) => ({
        type: at.REMOVE_DEVICE_GROUP_DEVICE_ITEM,
        device,
    }),

    removeDeviceGroupExceptedDeviceItem: (device) => ({
        type: at.REMOVE_DEVICE_GROUP_EXCEPTED_DEVICE_ITEM,
        device,
    }),

    setDeviceGroupId: (id) => ({
        type: at.SET_DEVICE_GROUP_ID,
        id,
    }),

    setDeviceGroupSerial: (serial) => ({
        type: at.SET_DEVICE_GROUP_SERIAL,
        serial,
    }),

    subscribeAddedDeviceGroup: (serial, cb) => () => {
        wsm.subscribe(serial, 'hub.device.group.created', cb);
    },

    subscribeDeviceGroupRemoved: (serial, cb) => () => {
        wsm.subscribe(serial, 'hub.device.group.deleted', cb);
    },

    unsubscribeDeviceGroupRemoved: (serial) => () => {
        wsm.unsubscribe(serial, 'hub.device.group.deleted');
    },

    unsubscribeAddedDeviceGroup: (serial) => () => {
        wsm.unsubscribe(serial, 'hub.device.group.created');
    },

    subscribeUpdatedDeviceGroup: (serial, cb) => () => {
        wsm.subscribe(serial, 'hub.device.group.updated', cb);
    },

    unsubscribeUpdatedDeviceGroup: (serial) => () => {
        wsm.unsubscribe(serial, 'hub.device.group.updated');
    },

    getIntegratedDevicesStaticInfo: (serial) => async (dispatch) => {
        try {
            const response = await fetch('/storage/devices/devices_info.json');
            const data = await response.json();
            const listIntegratedDevicesForUI = prepareListIntegratedDevicesForUI(data);

            dispatch(setStaticDataIntegratedDevicesInfo(listIntegratedDevicesForUI));
        } catch (error) {
            bugsnagNotify(error, { serial });
        }
    },

    deleteIntegratedDevicesStaticInfo: () => (dispatch) => {
        dispatch(deleteStaticDataIntegratedDevicesInfo());
    },
};

export default DeviceGroupsActions;
