import { createSlice } from '@reduxjs/toolkit';
import { ITEM_GROUPS_STATUS } from '../constants/ItemGroups';
import { SUBSCRIPTIONS_STATUS } from '../constants/Subscription';

const initialState = {
    local: {
        flagsContainer: {
            itemGroupsStatus: ITEM_GROUPS_STATUS.DEFAULT,
            subscriptionStatus: SUBSCRIPTIONS_STATUS.DEFAULT,
        },
        preprocessingDataContainer: {
            itemGroupsContainer: {
                capabilitiesList: {},
            },
        },
    },
};

const meshBotV2Slice = createSlice({
    name: 'meshBotV2',
    initialState,
    reducers: {
        setMeshBotV2LocalPreprocessingItemGroupsCapabilitiesList: (state, action) => {
            state.local.preprocessingDataContainer.itemGroupsContainer.capabilitiesList = action.payload;
        },
        setMeshBotV2LocalFlagsItemGroupsStatus: (state, action) => {
            state.local.flagsContainer.itemGroupsStatus = action.payload;
        },
        updateMeshBotV2LocalItemGroupsContainerCapabilityStatus: (state, action) => {
            state.local.preprocessingDataContainer.itemGroupsContainer.capabilitiesList[action.payload].status = true;
        },
        setMeshBotV2LocalFlagsSubscriptionStatus: (state, action) => {
            state.local.flagsContainer.subscriptionStatus = action.payload;
        },
    },
});

export const {
    setMeshBotV2LocalPreprocessingItemGroupsCapabilitiesList,
    setMeshBotV2LocalFlagsItemGroupsStatus,
    updateMeshBotV2LocalItemGroupsContainerCapabilityStatus,
    setMeshBotV2LocalFlagsSubscriptionStatus,
} = meshBotV2Slice.actions;
export default meshBotV2Slice.reducer;
