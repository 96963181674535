import React, { useState } from 'react';
import classname from 'classnames';

import { MESHBOT_LABELS } from '../../../../../services/kvs';
import { DRAGGING_ELEMENT_DATA } from '../../constants';

import styles from './styles.module.scss';

const DraggingMeshBotLabel = ({ backgroundColor, name, id }) => {
    const [dragging, setDragging] = useState(false);

    const handleDragStart = (e) => {
        setDragging((prevState) => !prevState);
        e.dataTransfer.setData(DRAGGING_ELEMENT_DATA, JSON.stringify({ id, type: MESHBOT_LABELS }));
        setTimeout(() => setDragging((prevState) => !prevState), 1);
    };

    const className = classname(styles.dragLabelContainer, {
        [styles.dragging]: dragging,
    });

    return (
        <div onDragStart={handleDragStart} draggable={true} className={className}>
            <div className={styles.dragLabel} style={{ backgroundColor }}>
                {name}
            </div>
        </div>
    );
};

export default DraggingMeshBotLabel;
