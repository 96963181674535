import React from 'react';

import { getIconByRunSceneProcessStatus } from './utils';
import { INHERIT, PRIMARY } from 'lib/@mui';
import MeshbotRun from './MeshbotRun';

import styles from './styles.module.scss';

const MeshbotRunCell = ({ status, isDisabled, isButton, handleButtonClick }) => {
    const icon = getIconByRunSceneProcessStatus(status, isDisabled ? INHERIT : PRIMARY);

    return (
        <div className={styles.meshbotRunWrapper}>
            <MeshbotRun handleButtonClick={handleButtonClick} icon={icon} isButton={isButton} isDisabled={isDisabled} />
        </div>
    );
};

export default MeshbotRunCell;
