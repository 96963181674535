import * as types from '../constants/ActionTypes';

import produce from 'immer';

const INITIAL_STATE = {
    menuBar: [],
    listUi: {},
    meshbot: {},
    primaryColor: '',
    nodesControllables: [],
    logo: null,
    status: null,
    initialMenuUi: [],
    initialListingUi: [],
    initialMeshbotUi: [],
    initialNodesControllables: [],
    initialLogo: null,
    initialColorTheme: [],
    defaultMenuUi: [],
    defaultListingUi: [],
    defaultMeshbotUi: [],
    defaultColorTheme: [],
    defaultNodesControllables: [],
    defaultLogo: null,
    colorTheme: [],
    partnerDomainStatus: null,
    partnerInfo: {},
    faviconIcon: '',
};
export default produce((draft, action) => {
    switch (action.type) {
        case types.UPDATE_EZLO_CUSTOMIZATION:
            draft.menuBar = action.data?.ezlogicPortal?.menuBar;
            draft.listUi = action.data?.ezlogicPortal?.listUi;
            draft.meshbot = action.data?.ezlogicPortal?.meshbot;
            draft.nodesControllables = action.data?.ezlogicPortal?.nodesControllables;
            draft.logo = action.data?.images?.logo;
            draft.colorTheme = action.data?.ezlogicPortal?.colorTheme;
            draft.partnerInfo = action.data?.partnerInfo;
            draft.faviconIcon = action.data?.images?.favicon;
            break;
        case types.INITIAL_CUSTOMIZATION:
            draft.initialMenuUi = action.data?.menuBar;
            draft.initialListingUi = action.data?.listingUi;
            draft.initialMeshbotUi = action.data?.meshbotUi;
            draft.initialNodesControllables = action.data?.defaultNodesControllables;
            draft.initialLogo = action.data?.logo;
            draft.initialColorTheme = action.data?.colorTheme;
            break;
        case types.DEFAULT_CUSTOMIZATION:
            draft.defaultMenuUi = action.data?.menuBar;
            draft.defaultListingUi = action.data?.listingUi;
            draft.defaultMeshbotUi = action.data?.meshbotUi;
            draft.defaultNodesControllables = action.data?.defaultNodesControllables;

            draft.defaultLogo = action.data?.logo;
            break;
        case types.DEFAULT_COLOR_THEME:
            draft.defaultColorTheme = action.data;
            break;
        case types.SET_DEFAULT_CUSTOMIZATION_TO_UPDATE_CUSTOMIZATION:
            draft.menuBar = action.data?.defaultMenuUi;
            draft.listUi = action.data?.defaultListingUi;
            draft.meshbot = action.data?.defaultMeshbotUi;
            draft.nodesControllables = action?.data?.defaultNodesControllables;
            draft.logo = action.data?.images?.logo;
            draft.primaryColor = action.data?.colors?.themeColor;
            break;
        case types.CUSTOMIZATION_API_STATUS:
            draft.status = action?.data;
            break;
        case types.PARTNER_DOMAIN_TO_PARTNER_UUID_API_STATUS:
            draft.partnerDomainStatus = action?.data;
            break;
        case types.COLOR_THEME_ACTION:
            draft.colorTheme = action?.data;
            break;
        case types.DEFAULT_LOGO:
            draft.defaultLogo = action?.data;
            break;
        case types.LOGOUT_RESET:
            draft.menuBar = INITIAL_STATE.menuBar;
            draft.listUi = INITIAL_STATE.listUi;
            draft.meshbot = INITIAL_STATE.meshbot;
            draft.logo = INITIAL_STATE.logo;
            draft.primaryColor = INITIAL_STATE.primaryColor;
            draft.status = INITIAL_STATE.status;
            draft.initialMenuUi = INITIAL_STATE.initialMenuUi;
            draft.initialListingUi = INITIAL_STATE.initialListingUi;
            draft.initialMeshbotUi = INITIAL_STATE.initialMeshbotUi;
            draft.initialLogo = INITIAL_STATE.initialLogo;
            draft.initialColorTheme = INITIAL_STATE.initialColorTheme;
            draft.defaultMenuUi = INITIAL_STATE.defaultMenuUi;
            draft.defaultListingUi = INITIAL_STATE.defaultListingUi;
            draft.defaultMeshbotUi = INITIAL_STATE.defaultMeshbotUi;
            draft.defaultLogo = INITIAL_STATE.defaultLogo;
            draft.colorTheme = INITIAL_STATE.colorTheme;
            break;
        default:
            return draft;
    }
}, INITIAL_STATE);
