import produce from 'immer';
import * as types from '../constants/Subscription';

const INITIAL_STATE = {
    payload: [],
    addedItems: [],
    devicesList: [],
};

export default produce((draft, action) => {
    switch (action.type) {
        case types.FORM_PAYLOAD_FOR_SUBSCRIPTIONS:
            draft.countOfItems = action.countOfVariables;
            draft.payload = action.data;
            break;
        case types.GET_DEVICES_LIST:
            draft.devicesList = action.devices;
            break;
        case types.UPDATE_VALUES:
            draft.payload = action.updatedPayload;
            draft.addedItems = [...draft.addedItems, action.newItem];
            break;
        case types.CLEAR_SUBSCRIPTIONS_STATE:
            draft.payload = [];
            draft.addedItems = [];
            draft.devicesList = [];
            break;
    }
}, INITIAL_STATE);
