export const FORM_PAYLOAD_FOR_SUBSCRIPTIONS = 'FORM_PAYLOAD_FOR_SUBSCRIPTIONS';
export const UPDATE_VALUES = 'UPDATE_VALUES';
export const GET_DEVICES_LIST = 'GET_DEVICES_LIST';
export const CLEAR_SUBSCRIPTIONS_STATE = 'CLEAR_SUBSCRIPTIONS_STATE';
export const SUBSCRIPTIONS_STATUS = {
    DEFAULT: 'default',
    NOT_USED: 'not_used',
    IN_PROGRESS: 'in_progress',
    COMPLETED: 'completed',
    ERROR: 'error',
};
