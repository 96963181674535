/**
 * Constant representing the WebSocket call for hub backup restore operations.
 *
 * @constant
 * @type {string}
 */
export const HUB_BACKUP_RESTORE = 'hub.backup.restore';
/**
 * Constant representing the WebSocket call for hub backup restore progress updates.
 *
 * @constant
 * @type {string}
 */
export const HUB_BACKUP_RESTORE_PROCESS = 'hub.backup.restore.progress';
/**
 * Constant representing the method for setting local logs on the hub.
 *
 * @constant
 * @type {string}
 * @see { [hub.log.local.set](https://log.ezlo.com/new/hub/logging/#hubloglocalset) }
 */
export const HUB_LOG_LOCAL_SET = 'hub.log.local.set';
/**
 * Represents the method for hub item dictionary updates broadcast.
 *
 * @constant
 * @type {string}
 * @see { [hub.item.dictionary.updated](https://log.ezlo.com/new/hub/broadcasts/#hubitemdictionaryupdated) }
 */
export const HUB_ITEM_DICTIONARY_UPDATED = 'hub.item.dictionary.updated';
/**
 * Represents the method for hub scenes stop.
 *
 * @constant
 * @type {string}
 * @see {[hub.scenes.stop](https://log.ezlo.com/new/hub/scenes/local_scenes_api/#hubscenesstop) }
 */
export const HUB_SCENES_STOP = 'hub.scenes.stop';
