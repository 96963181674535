import { send, validateSerial } from '../../common/utils';
import { HUB_SCENES_STOP } from '../../common/methods';
import { isString } from 'lodash';

/**
 * Validates the parameters required for the hub.scenes.stop call.
 *
 * @param {Object} params - The parameters object.
 * @param {string} params.sceneId - Id of the scene to be stopped.
 * @param {string} [params.block] - Type of stopped scenes action blocks. Now available only then.
 * @param {string} [params.group] - Reserved for block types thenGroups, elseGroups: asks to stop all actions of a specific group
 * @throws {Error} - Throws an error if required parameters are missing or not of the correct type.
 * @returns {void}
 * @see{[Doc for hub.scenes.stop call](https://log.ezlo.com/new/hub/scenes/local_scenes_api/#hubscenesstop)}
 */
export const validateParams = (params) => {
    if (!isString(params.sceneId)) {
        throw new Error('params.sceneId is required and must be string');
    }

    if (params.hasOwnProperty('block') && !isString(params.block)) {
        throw new Error('params.block must be string');
    }

    if (params.hasOwnProperty('group') && !isString(params.group)) {
        throw new Error('params.group must be string');
    }
};
/**
 * @typedef {Object} HunScenesStopParams
 * @property {string} sceneId - The id of the scene to stop.
 * @property {string} [block] - The type of stopping scene's action block. Currently, only "then" is available.
 * @property {string} [group] - Reserved for block types "thenGroups", "elseGroups". Stops all actions of a specific group.
 */

/**
 * Sends a command to the Hub to stop a specific scene.
 *
 * @param {string} serial - The serial number of the Hub.
 * @param {HunScenesStopParams} params - The parameters for the stop function.
 * @param {Function} onSuccess - A callback function that executes when the request is successful.
 * @param {Function} onError - A callback function that executes when the request fails.
 *
 * @throws {Error} Throws an error if "serial" is not a valid serial or if the required parameters in "params" are missing or not of the correct type.
 * @returns {Promise}
 * @see{[Doc for hub.scenes.stop call](https://log.ezlo.com/new/hub/scenes/local_scenes_api/#hubscenesstop)}
 */
export const hubScenesStopApi = (serial, params, onSuccess, onError) => {
    validateSerial(serial);
    validateParams(params);

    return send(serial, HUB_SCENES_STOP, params, onSuccess, onError);
};
/**
 * A wrapper function for `hubScenesStopApi`.
 *
 * @param {string} serial - The serial number of the Hub.
 * @param {HunScenesStopParams} params - The parameters for the stop function.
 * @param {Function} onSuccess - A callback function that executes when the request is successful.
 * @param {Function} onError - A callback function that executes when the request fails.
 *
 * @returns {Promise}
 * @see{[Doc for hub.scenes.stop call](https://log.ezlo.com/new/hub/scenes/local_scenes_api/#hubscenesstop)}
 */
export const stop = (serial, params, onSuccess, onError) => hubScenesStopApi(serial, params, onSuccess, onError);
