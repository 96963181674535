import React from 'react';

const IcFlameOverSensorIcon = (props) => {
    return (
        <svg width="24" height="30" viewBox="0 0 24 30" fill="#1C1E21" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.8309 3.00254L16.7943 4.77612C18.1537 6.01914 18.9933 7.73976 19 9.65021C19 11.5484 18.1604 13.269 16.801 14.5182C16.1146 15.1427 15.3016 15.651 14.3954 16C15.0751 15.3387 15.4949 14.4508 15.4949 13.4711C15.4949 13.1772 15.4549 12.8955 15.3816 12.62C14.6886 13.1527 13.8023 13.4711 12.8294 13.4711C10.9436 13.4711 9.36428 12.2648 8.94447 10.6483C8.0582 11.3219 7.49845 12.3322 7.49845 13.4711C7.49845 14.4508 7.91826 15.3387 8.60462 15.9939C7.69836 15.6449 6.88538 15.1427 6.19902 14.5121C4.83963 13.269 4 11.5484 4 9.64409C4 7.73976 4.83963 6.01914 6.19902 4.77612L11.4967 0C7.49845 5.51091 11.8765 7.20092 13.8556 6.33142C15.6726 5.53027 15.4754 3.58689 14.8309 3.00254ZM0 27V18H24V27C24 28.7 22.7 30 21 30H3C1.3 30 0 28.7 0 27ZM21 24C21 22.9 20.1 22 19 22C17.9 22 17 22.9 17 24C17 25.1 17.9 26 19 26C20.1 26 21 25.1 21 24Z"
            />
        </svg>
    );
};

export default IcFlameOverSensorIcon;
