import React from 'react';

export const VideoEvent = () => (
    <svg viewBox="0 0 32 32" fill="none">
        <path
            d="M30 22C26.7 22 24 19.3 24 16C24 12.7 26.7 10 30 10V22ZM22 7V25C22 26.7 20.7 28 19 28H5C3.3 28 2 26.7 2 25V7C2 5.3 3.3 4 5 4H19C20.7 4 22 5.3 22 7ZM15 16C15 14.3 13.7 13 12 13C10.3 13 9 14.3 9 16C9 17.7 10.3 19 12 19C13.7 19 15 17.7 15 16Z"
            fill="#1C1E21"
        />
    </svg>
);

export default VideoEvent;
