import React from 'react';

const IcCategoryGarageDoorCrossedOut = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.1375 27.1375L28 32L30 30L27.5754 27.5754L26 26L21.4362 21.4362L19 19L16 16L13 13L10 10L6.94611 6.94611L2 2L0 4L4.43295 8.43295C3.5593 9.1972 3 10.3175 3 11.5V25C3 26.7 4.3 28 6 28V12C6 11.45 6.225 10.95 6.5875 10.5875L9 13H8C8 14.7 9.3 16 11 16H12L15 19H8C8 20.7 9.3 22 11 22H18L21 25H8C8 26.7 9.3 28 11 28H21C21.85 28 22.6 27.675 23.1375 27.1375ZM29 11.5V25L26 22V12C26 10.9 25.1 10 24 10H14L9.53293 5.53293L16 2L26.8 7.9C28.1 8.6 29 10 29 11.5ZM21 16H20L17 13H24C24 14.7 22.7 16 21 16Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcCategoryGarageDoorCrossedOut;
