import { useState } from 'react';

const useMenuState = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);

    const openMenu = (e) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };
    const closeMenu = (e) => {
        e?.stopPropagation();
        setAnchorEl(null);
    };

    return { menuOpen, openMenu, closeMenu, anchorEl };
};

export default useMenuState;
