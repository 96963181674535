/**
 * Promise states
 * More info {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise here}
 * */
export const PROMISE_STATE = {
    PENDING: 'pending',
    FULFILLED: 'fulfilled',
    REJECTED: 'rejected',
};

/**
 * Custom implementation for Promise.allSettled method.
 * Returns a promise that resolves after all of the given promises have either fulfilled or rejected,
 * with an array of objects that each describes the outcome of each promise.
 * Can I use coverage for <b>Promise.allSettled</b> less than
 * {@link https://caniuse.com/mdn-javascript_builtins_promise_allsettled 91%}
 * <br/>
 * More info
 * {@link https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Global_Objects/Promise/allSettled here:}
 * @param {array} promises - array of promises
 * @returns {Promise} Promise with array of resolved results
 * */
export const allSettled = (promises) => {
    Promise.allSettled =
        Promise.allSettled ||
        ((promises) =>
            Promise.all(
                promises.map((p) =>
                    p
                        .then((value) => ({ status: PROMISE_STATE.FULFILLED, value }))
                        .catch((reason) => ({ status: PROMISE_STATE.REJECTED, reason })),
                ),
            ));

    return Promise.allSettled(promises);
};

/**
 * Returns value for "fulfilled" settled promise and null otherwise.
 * @param {Object} promise - settled promise
 * @returns {Object} value
 * */
const getSettledPromiseValue = (promise) => {
    if (promise?.status === PROMISE_STATE.FULFILLED) {
        return promise.value;
    }

    return null;
};

/**
 * Returns values from array of settled promises.
 * Returns null for "rejected" promises.
 * @param {array} promises - array of promises
 * @returns {array} array of values
 * */
export const getAllSettledValues = (promises) => {
    if (!Array.isArray(promises)) {
        throw new Error('#getAllSettledValues: invalid args, "promises" is not an array');
    }

    return promises.map(getSettledPromiseValue);
};
