import { getCachedDataFromStorage } from '../../../../helpers/mainActionUtils';
import { isObject } from 'lodash';
import { bugsnagNotify } from '../../../ErrorBoundary/utils';
import { fetchIntegrationPreview } from './fetchIntegrationPreview';

/**
 * Retrieves the integration preview for a given integration UUID.
 * Attempts to retrieve the preview from cache first; if not available, fetches it.
 *
 * @async
 * @param {string} integrationUuid - The UUID of the integration to retrieve the preview for.
 * @returns {Promise<Object|undefined>} The integration preview object, or undefined if not found.
 */
export const getIntegrationPreview = async (integrationUuid) => {
    try {
        const { parsedData } = getCachedDataFromStorage() || {};
        const integrationPreviewFromCache = parsedData.integrations_preview[integrationUuid];
        if (isObject(integrationPreviewFromCache)) {
            return integrationPreviewFromCache;
        }

        const response = await fetchIntegrationPreview(integrationUuid);

        return response.integrations[integrationUuid];
    } catch (e) {
        bugsnagNotify(e, { type: 'getIntegrationPreview catch error' });
    }
};
