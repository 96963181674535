import React from 'react';
import { useTranslate } from 'features/languages';
import { EZLOGIC_TITLE_ERROR, EZLOGIC_TITLE_OPERATION } from 'constants/language_tokens';

import styles from './styles.module.scss';

const ErrorToastContentWithTitleAndDetails = ({ title, operationTKey, errorMessage }) => {
    const { t } = useTranslate();

    return (
        <div className={styles.contentBox}>
            <h4>{t(title || EZLOGIC_TITLE_ERROR)}</h4>
            <div>
                {t(EZLOGIC_TITLE_OPERATION)}: {t(operationTKey)}
            </div>
            <div>
                {t(EZLOGIC_TITLE_ERROR)}: {errorMessage}
            </div>
        </div>
    );
};

export default ErrorToastContentWithTitleAndDetails;
