import React from 'react';

export const LeftMenuSystemHistoryIcon = () => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2.13673 1.7434L2.13672 1.74339C1.69877 1.30549 0.95 1.61566 0.95 2.23496V8.09679C0.95 8.48073 1.26122 8.79195 1.64516 8.79195H7.50697C8.12627 8.79195 8.43644 8.04317 7.9985 7.60522L5.98855 5.59527C7.30306 4.37598 9.06347 3.63065 11 3.63065C15.0727 3.63065 18.3693 6.92662 18.3693 11C18.3693 15.0728 15.0734 18.3694 11 18.3694C9.26896 18.3694 7.67826 17.7741 6.42128 16.7766L6.3902 16.8158L6.42127 16.7766C6.01631 16.4553 5.43359 16.4957 5.06892 16.8604L4.6146 17.3147C4.18785 17.7415 4.22277 18.4455 4.69271 18.8247C6.41898 20.218 8.6158 21.0517 11.0073 21.05C16.5446 21.0461 21.0602 16.5183 21.05 10.981C21.0397 5.43928 16.5441 0.95 11 0.95C8.32484 0.95 5.89378 1.99541 4.09305 3.69973L2.13673 1.7434ZM9.65967 12.2763V12.3007L9.67897 12.3157L12.3164 14.3671C12.7601 14.7122 13.3995 14.6323 13.7446 14.1886L14.1407 13.6793C14.4858 13.2356 14.4059 12.5962 13.9622 12.2511L12.3403 10.9896V6.80646C12.3403 6.24437 11.8847 5.78872 11.3226 5.78872H10.6774C10.1153 5.78872 9.65967 6.24437 9.65967 6.80646V12.2763Z"
            stroke="#3E46AE"
            strokeWidth="0.1"
        />
    </svg>
);
export default LeftMenuSystemHistoryIcon;
