import { CLOUD_TOKEN } from 'constants/localStorageKeys';
import { decodeCloudToken } from 'containers/Ezlo/EzloCustomization/utils';
import {
    PK_COUNTRIES_OF_COUNTRIES_WITH_ZIP,
    PK_COUNTRY,
    PK_COUNTRY_LIST_OF_ALLOWED_COUNTRIES,
    VALUE_TYPES,
} from '../../../../constants/AccountInfo';
import { cropImage } from '../components/ProfileImageCropper/utils/utils';
import { buildFileFormData } from 'services/mediaStore';
import { uploadImage } from 'features/MediaStoragePage/actions/media';
import { UUID_VALIDATION_REGEXP } from 'containers/Ezlo/EzloCustomization/constants';
import { OBJECT, ZERO_INT } from 'constants/MeshbotConstant';
import { OBJECT_TYPE, ONE_INT } from 'constants/Variables';

const getCountry = (countryCode, countriesList) => {
    return countriesList.find(({ PK_Country }) => Number(PK_Country) === Number(countryCode));
};

const getAddressFields = (address, countries) => {
    return {
        Address1: address?.Address1 || '',
        Address2: address?.Address2 || '',
        City: address?.City || '',
        FirstName: address?.FirstName || '',
        LastName: address?.LastName || '',
        Country: address?.PK_Country ? getCountry(address?.PK_Country, countries) || '' : '',
        PostalCode: address?.PostalCode || '',
        State: address?.State || '',
    };
};

export const getInitialValues = (installations, countries, mailingBillingAddresses) => {
    const filteredInstallationsData = getFilterInstallationsWithDevices(installations);
    const initialValues = {
        Installations: filteredInstallationsData?.map((installation) => ({
            ...getAddressFields(installation, countries),
            PK_Installation: installation.PK_Installation,
            Devices: installation?.Devices,
        })),
    };

    if (mailingBillingAddresses) {
        initialValues.Billing = getAddressFields(mailingBillingAddresses.Billing, countries);
        initialValues.Mailing = getAddressFields(mailingBillingAddresses.Mailing, countries);
    }

    return initialValues;
};

export const getRequestAddressData = (address) => {
    const addressData = {
        ...address,
        City: address.City.Name,
        State: address.State.Name,
        PK_Country: Number(address.Country.PK_Country),
    };
    delete addressData.Country;

    return addressData;
};

const getAlphabeticallySortedCountries = (countries) => {
    return countries.sort((a, b) => {
        const nameA = a.Name.toUpperCase();
        const nameB = b.Name.toUpperCase();
        if (nameA < nameB) {
            return -1;
        }

        if (nameA > nameB) {
            return 1;
        }

        return 0;
    });
};
/**
 * Sort countries.
 * @param {Object[]} countriesList - List of countries
 * @returns {Object[]} sorted list of countries
 * */
const sortCountries = (countriesList) => {
    const newCountryList = [];
    // Sort alphabetically.
    const sortCountries = getAlphabeticallySortedCountries(countriesList);
    // Put USA and Canada at the top of the list of countries.
    sortCountries.forEach((country) => {
        if (country.PK_Country === PK_COUNTRY.CANADA || country.PK_Country === PK_COUNTRY.USA) {
            newCountryList.unshift(country);
        } else {
            newCountryList.push(country);
        }
    });

    return newCountryList;
};

/**
 * Filters countries.
 * @param {Object[]} countriesList - List of countries
 * @returns {Object[]} filtered list of countries
 * */
const filterCountries = (countriesList) => {
    return countriesList.filter((country) => PK_COUNTRY_LIST_OF_ALLOWED_COUNTRIES.includes(country.PK_Country));
};
/**
 * Filter and sort countries
 * @param {Object[]} countriesList - List of countries
 * @returns {Object[]} filtered and sorted countries
 * */
export const filterAndSortCountries = (countriesList) => {
    const filteredCountries = filterCountries(countriesList);

    return sortCountries(filteredCountries);
};

/**
 * Determine if postalcode is required for a country using the country's PK_Country
 * @param {Number} PK_Country - PK_Country
 * @returns {Boolean} return true if the required postalcode
 * */
export const getIsPostalCodeRequired = (PK_Country) => {
    if (!PK_Country) {
        return false;
    }

    return PK_COUNTRIES_OF_COUNTRIES_WITH_ZIP.includes(PK_Country);
};

/**
 * Uploads a profile image to Key-Value Store (KVS).
 *
 * @param {string} type - The type of the profile image.
 * @param {string} unique_key - The unique key associated with the profile image.
 * @param {string} value - The value of the profile image, usually a JSON string.
 * @returns {Object} - An object containing type, key, and value.
 */
export const uploadProfileImageInKvs = (type, unique_key, value) => {
    const data = {
        type: type || '',
        key: unique_key || '',
        value: value || '{}',
    };

    return data;
};

/**
 * Parses the response and extracts necessary data for a profile picture.
 *
 * @param {Object} response - The response object containing key and uuid.
 * @returns {string|null} - A JSON string containing key and uuid, or null if response is falsy.
 */
export const getProfilePicturePayloadData = (response) => {
    if (response) {
        const profilePictureData = {
            key: response?.key,
            uuid: response?.uuid,
        };

        return JSON.stringify(profilePictureData);
    }

    return null;
};

/**
 * Generates a KVS key using the provided profile picture key and user UUID.
 *
 * @param {string} profilePictureKey - The key associated with the profile picture.
 * @param {string} userUuid - The UUID of the user.
 * @returns {string|null} - The generated KVS key or null if either parameter is falsy.
 */
export const getProfilePictureKvsKey = (profilePictureKey, userUuid) => {
    if (profilePictureKey && userUuid) {
        return `${profilePictureKey}_${userUuid}`;
    }

    return null;
};

/**
 * Retrieves the user UUID from the stored Cloud Token in local storage.
 *
 * @returns {string|null} - The user UUID extracted from the Cloud Token, or null if not found.
 */
export const getUserUuidFromToken = () => {
    const CloudToken = localStorage.getItem(CLOUD_TOKEN);
    const decodedToken = decodeCloudToken(CloudToken);
    const parsedTokenData = JSON.parse(decodedToken);
    const userUuid = parsedTokenData?.user?.uuid;

    return userUuid;
};

/**
 * Crops the provided image using the specified parameters.
 *
 * @param {string} image - The base64 representation of the original image.
 * @param {Object} croppedAreaPixels - The pixel coordinates for cropping.
 * @param {File} imageFile - The File object representing the original image file.
 * @param {Function} setIsImageUploadError - Function to set the image upload error state.
 * @param {Function} setImageFile - Function to set the cropped image file.
 * @returns {Promise<void>} - A promise that resolves after the image is cropped.
 */
export const getCropImage = async (image, croppedAreaPixels, imageFile, setIsImageUploadError, setImageFile) => {
    return await cropImage(image, croppedAreaPixels, imageFile.type, imageFile.name)
        .then(async (croppedBase64) => {
            setIsImageUploadError(false);
            setImageFile(croppedBase64);
        })
        .catch(() => {
            setIsImageUploadError(true);
        });
};

/**
 * Uploads and processes an image.
 *
 * @param {Object} imagesData - The image data to upload.
 * @param {boolean} isProfilePicture - Indicates if the image is a profile picture.
 * @returns {Object} - The response object from the image upload.
 */
export const uploadAndProcessImage = async (imagesData, isProfilePicture) => {
    const multipartFormData = buildFileFormData(imagesData);

    return await uploadImage(multipartFormData, { file: imagesData }, isProfilePicture);
};

/**
 * Checks if the upload response contains necessary keys for a valid upload.
 *
 * @param {Object} uploadImageResponse - The response object from an image upload.
 * @returns {boolean} - True if the response contains 'key' and 'uuid', otherwise false.
 */
export const isValidUploadResponse = (uploadImageResponse) => {
    return !!uploadImageResponse?.key && !!uploadImageResponse?.uuid;
};

/**
 * Find the UUID part from an array of URL parts.
 * @param {string[]} urlParts - Array of URL parts
 * @returns {string} The UUID part if found, otherwise an empty string
 */
export const findUuidPart = (urlParts = []) => {
    return urlParts?.find((part) => part?.startsWith('uuid=')) || '';
};

/**
 * Check if a string is a valid UUID format.
 * @param {string} uuid - The UUID string
 * @returns {boolean} True if valid UUID format, otherwise false
 */
const isValidUuid = (uuid) => {
    return UUID_VALIDATION_REGEXP.test(uuid);
};

/**
 * Extract the UUID value from a UUID part.
 * @param {string} uuidPart - The UUID part
 * @returns {string} The extracted UUID value if valid, otherwise an empty string
 */
export const extractUuidValue = (uuidPart) => {
    if (!uuidPart) {
        return '';
    }
    const [, uuid] = uuidPart?.split('=');
    if (!uuid || !isValidUuid(uuid)) {
        return '';
    }

    return uuid;
};

/**
 * Extracts the UUID from an image URL.
 * @param {string} url - The image URL
 * @returns {string} The extracted UUID if found and valid, otherwise an empty string
 */
export const extractUuidFromImgUrl = (url) => {
    const urlParts = url?.split(/[?&]/);
    const uuidPart = findUuidPart(urlParts);

    return extractUuidValue(uuidPart);
};

/**
 * Compares two fields from initial and final objects, handling object values.
 *
 * @param {Object} initialAddress - The initial object to compare.
 * @param {Object} finalAddress - The final object to compare.
 * @param {string} addressField - The field to compare.
 * @returns {boolean} - True if the fields are different, false otherwise.
 *
 * @example
 * const initial = { State: { Name: 'California' } };
 * const final = { State: 'California' };
 * const result = compareFields(initial, final, 'State');
 * // result will be false since both are representing 'California'.
 */
export const compareFields = (initialAddress, finalAddress, addressField) => {
    const initialAddressValue =
        typeof initialAddress[addressField] === OBJECT
            ? initialAddress[addressField]?.Name
            : initialAddress[addressField];
    const finalAddressValue =
        typeof finalAddress[addressField] === OBJECT ? finalAddress[addressField]?.Name : finalAddress[addressField];

    return initialAddressValue !== finalAddressValue;
};

/**
 * Compares addresses between initial and final objects.
 * Handles comparisons for fields such as State, City, Country, etc.
 *
 * @param {Object} initialAddress - The initial address object to compare.
 * @param {Object} finalAddress - The final address object to compare.
 * @returns {boolean} - True if any address fields are different, false otherwise.
 *
 * @example
 * const initialAddress = {
 *    FirstName: 'John',
 *    LastName: 'Doe',
 *    PostalCode: '12345',
 *    State: { Name: 'California' },
 *    City: { Name: 'Los Angeles' },
 *    PK_Installation: '123'
 * };
 * const finalAddress = {
 *    FirstName: 'John',
 *    LastName: 'Doe',
 *    PostalCode: '12345',
 *    State: { Name: 'New York' },
 *    City: { Name: 'Los Angeles' },
 *    PK_Installation: '123'
 * };
 * const result = compareAddresses(initialAddress, finalAddress);
 * // result will be true since State is different.
 */
export const compareAddresses = (initialAddress, finalAddress) => {
    const fieldsToCompare = [
        'FirstName',
        'LastName',
        'PostalCode',
        'Address1',
        'Address2',
        'State',
        'City',
        'PK_Installation',
        'Country',
    ];

    for (const addressField of fieldsToCompare) {
        if (addressField === 'State') {
            if (
                compareFields(initialAddress, finalAddress, addressField) &&
                initialAddress.State !== finalAddress?.State?.PK_Region
            ) {
                return true;
            }
        } else {
            if (compareFields(initialAddress, finalAddress, addressField)) {
                return true;
            }
        }
    }

    return false;
};

/**
 * Determines whether to enable the Save button based on address changes.
 *
 * @param {Object} installationAddressInitialValues - The initial address values.
 * @param {Object} installationAddressFinalValues - The final address values.
 * @returns {boolean} - True if any address changes are detected, false otherwise.
 *
 * @example
 * const initialValues = {
 *    Installations: [{ PK_Installation: '123' }],
 *    Billing: { FirstName: 'Billing Address' },
 *    Mailing: { FirstName: 'Mailing Address' }
 * };
 * const finalValues = {
 *    Installations: [{ PK_Installation: '123' }],
 *    Billing: { FirstName: 'Billing Address' },
 *    Mailing: { FirstName: 'Updated Mailing Address' }
 * };
 * const result = shouldEnableSaveButton(initialValues, finalValues);
 * // result will be true since Mailing Address is different.
 */
export const shouldEnableSaveButton = (installationAddressInitialValues, installationAddressFinalValues) => {
    if (
        installationAddressInitialValues?.Installations &&
        installationAddressFinalValues?.Installations &&
        installationAddressInitialValues?.Installations?.length ===
            installationAddressFinalValues?.Installations?.length
    ) {
        const hasDifferentInstallation = installationAddressInitialValues?.Installations?.some(
            (initialInstallation, index) =>
                compareAddresses(initialInstallation, installationAddressFinalValues?.Installations[index]),
        );

        if (hasDifferentInstallation) {
            return true;
        }
    }

    if (
        installationAddressInitialValues?.Billing &&
        installationAddressFinalValues?.Billing &&
        compareAddresses(installationAddressInitialValues?.Billing, installationAddressFinalValues?.Billing)
    ) {
        return true;
    }

    if (
        installationAddressInitialValues?.Mailing &&
        installationAddressFinalValues?.Mailing &&
        compareAddresses(installationAddressInitialValues?.Mailing, installationAddressFinalValues?.Mailing)
    ) {
        return true;
    }

    return false;
};

/**
 * Filters installations based on the presence of devices.
 *
 * If there is only one installation, all installations are returned.
 * If there are multiple installations, only those with devices are returned.
 *
 * @param {Object[]} installations - The array of installations to filter.
 * @returns {Object[]} - The filtered array of installations.
 *
 * @example
 * const installations = [
 *    {
 *        "PK_Installation": "1058632",
 *        "FirstName": "Home",
 *        "Devices": [
 *            {
 *                "PK_Device": 90000406
 *            },
 *            {
 *                "PK_Device": 90028535
 *            }
 *        ]
 *    },
 *    {
 *        "PK_Installation": "1087692",
 *        "FirstName": "Living Room",
 *        "Devices": []
 *    },
 *    {
 *        "PK_Installation": "1088302",
 *        "FirstName": "narnia",
 *        "Devices": []
 *    }
 * ];
 *
 * const filteredInstallations = getFilterInstallationsWithDevices(installations);
 * // filteredInstallations will be:
 * // [
 * //     {
 * //         "PK_Installation": "1058632",
 * //         "FirstName": "Home",
 * //         "Devices": [
 * //             {
 * //                 "PK_Device": 90000406
 * //             },
 * //             {
 * //                 "PK_Device": 90028535
 * //             }
 * //         ]
 * //     }
 * // ]
 */
export const getFilterInstallationsWithDevices = (installations) => {
    if (!Array.isArray(installations) || !installations?.length) {
        return [];
    }

    if (installations?.length === ONE_INT) {
        return installations;
    } else {
        return installations?.filter((installation) => installation?.Devices?.length > ZERO_INT);
    }
};

/**
 * Compares an option to a value.
 *
 * @param {object} option The option to compare.
 * @param {string|object} value The value to compare against. Can be either a string or an object.
 * @returns {boolean} Returns true if the option matches the value, otherwise returns false.
 */
export const compareOptionToValue = (option, value) => {
    if (typeof value === VALUE_TYPES.STRING) {
        return option?.Name === value;
    } else if (typeof value === OBJECT_TYPE) {
        return option?.Name === value?.Name;
    }

    return false;
};
