import { createSlice } from '@reduxjs/toolkit';
import { GEOFENCE_SETTINGS } from '../constants/SlicesName';

const initialState = {
    geofenceLocations: null,
    geofenceRegisteredDevices: null,
};

const geofenceSlice = createSlice({
    name: GEOFENCE_SETTINGS,
    initialState,
    reducers: {
        updateGeofenceLocations: (state, action) => {
            state.geofenceLocations = action.payload.data;
        },
        updateGeofenceRegisteredDevices: (state, action) => {
            state.geofenceRegisteredDevices = action.payload.data;
        },
    },
});

export const { updateGeofenceLocations, updateGeofenceRegisteredDevices } = geofenceSlice.actions;
export default geofenceSlice;
