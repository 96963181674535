import React, { useMemo } from 'react';

import { useTranslate } from '../../features/languages';
import { getMeshBotTypeCellConfig } from '../../containers/Ezlo/EzloMeshbots/utils';

import styles from './styles.module.scss';

/**
 * React component for displaying Meshbot types in a table cell.
 *
 * @component
 * @param {Object} params - Props for the MeshbotType component.
 * @param {Object} params.row - Data for the Meshbot.
 * @returns {JSX.Element} JSX element representing the MeshbotType component.
 */
const MeshbotType = ({ row: { original: params } }) => {
    const { t } = useTranslate();
    const meshBotTypeCellData = useMemo(() => getMeshBotTypeCellConfig(params), [params]);

    if (!meshBotTypeCellData) {
        return <span> {params.type} </span>;
    }

    const { MeshBotTypeIcon, hoverTitleKey, tableTitleKey } = meshBotTypeCellData;

    return (
        <div className={styles.meshbotTypeCell} title={t(hoverTitleKey)}>
            <MeshBotTypeIcon />
            <span>{t(tableTitleKey)}</span>
        </div>
    );
};

export default MeshbotType;
