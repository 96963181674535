import { getUserAuthInfoTemplate } from '../common/utils';
import { JAVASCRIPT_TYPES } from '../../../../constants/common/js_types';
import { ITARIAN_SSO_DATA_KEYS, ITARIAN_SSO_TYPE } from './constants';

/**
 * Extracts data from the Cloud API response object.
 * @see {@link https://confluence.mios.com/display/EPD/SSO+Integration+-+Auth SSO Integration Auth}
 * @param {Object} response - Cloud API response payload
 * @returns {Object} data
 * */
export const extractItarianSsoData = (response) => {
    return response?.data?.data;
};

/**
 * TODO: consider JSON shape object validation scheme
 * Returns validation result for Itarian SSO data object structure
 * @param {Object} ssoData - Itarian SSO data
 * @returns {boolean} validation result
 *
 * @example:
 * Input:
 * {
 *   "token": "eyJhbGciOiJIUzI1NiIs...gGVdJ3MxXRjGD6Ro",
 *   "expires": 1669126012,
 *   "legacy_token": {
 *     "auth": "eyJFeHBpcmVzIjoxNj...zTmV3QWNjb3VudCI6MX0=",
 *     "sig": "j0T1f0qBppbJQ+MJEo...uCL/EQkWtai0becKs8v6NYDPTuiA=="
 *   }
 * }
 *
 * Output:
 * true
 * */
const validateItarianSsoDataObjectStructure = (ssoData) => {
    return (
        ssoData &&
        typeof ssoData === JAVASCRIPT_TYPES.OBJECT &&
        ssoData.hasOwnProperty(ITARIAN_SSO_DATA_KEYS.TOKEN) &&
        ssoData.hasOwnProperty(ITARIAN_SSO_DATA_KEYS.LEGACY_TOKEN)
    );
};

/**
 * Returns user auth related info based on Itarian SSO data
 * @param {Object} ssoData - Itarian SSO data
 * @returns {Object|null} unified user auth data object or null
 *
 * @example
 * Input:
 * {
 *   "token": "eyJhbGciOiJIUzI1NiIs...gGVdJ3MxXRjGD6Ro",
 *   "expires": 1669126012,
 *   "legacy_token": {
 *     "auth": "eyJFeHBpcmVzIjoxNj...zTmV3QWNjb3VudCI6MX0=",
 *     "sig": "j0T1f0qBppbJQ+MJEo...uCL/EQkWtai0becKs8v6NYDPTuiA=="
 *   }
 * }
 *
 * Output:
 * {
 *     "path": "",
 *     "Server_Account": null,
 *     "Server_Account_Alt": null,
 *     "Identity": "eyJFeHBpcmVzIjoxNj...zTmV3QWNjb3VudCI6MX0=",
 *     "IdentitySignature": "j0T1f0qBppbJQ+MJEo...uCL/EQkWtai0becKs8v6NYDPTuiA==",
 *     "token": "eyJhbGciOiJIUzI1NiIs...gGVdJ3MxXRjGD6Ro"
 * }
 *
 * */
export const buildUserAuthInfoByItarianSsoData = (ssoData) => {
    // Explicitly return null in case of not valid data
    if (!validateItarianSsoDataObjectStructure(ssoData)) {
        return null;
    }

    const authInfo = getUserAuthInfoTemplate();

    authInfo.data = {
        ...authInfo.data,
        Identity: ssoData.legacy_token.auth,
        IdentitySignature: ssoData.legacy_token.sig,
        token: ssoData.token,
    };

    return authInfo;
};

/**
 * Returns Itarian SSO Type
 * @returns {string} Itarian SSO type
 * */
export const getItarianSsoType = () => {
    return ITARIAN_SSO_TYPE;
};
