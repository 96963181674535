import { DOMAIN_PARTNER_UUID, LOCALSTORAGE_TOP_LEVEL_DOMAIN } from '../../../constants/localStorageKeys';
import { INTERNAL_EZLOGIC_DOMAIN_URLS } from '../../../constants/URLs';
import { tokenParser } from '../../../helpers/auth';
import { isNumber } from 'lodash';
import {
    EZLOGIC_TITLE_INVALID_DATA_TYPE,
    EZLOGIC_TITLE_PREVENT_PARTNER_AND_INSTALLER_TO_LOGIN_MESSAGE,
} from 'constants/language_tokens';
import { t } from 'helpers/language';
import { OEM_IDS } from 'services/oem/src/constants/oems';
import oemManager from 'services/oem';
import {
    INSTALLER_PERMISSION,
    PARTNER_INSTALLER_LOGIN_ERROR,
    PARTNER_PERMISSION,
} from 'containers/Ezlo/EzloCustomization/constants';
/**
 * @returns {Boolean} of domain value for Environment dropdown
 * */
export const isEnvSwitcherEnabled = (host) => {
    return INTERNAL_EZLOGIC_DOMAIN_URLS.some((domainUrl) => domainUrl === host);
};

/**
 * @returns {boolean} true if top level domain else return false.
 * */
export const getTopLevelDomain = () => {
    return Boolean(localStorage.getItem(LOCALSTORAGE_TOP_LEVEL_DOMAIN));
};

/**
 * @returns {boolean} true if uuid domain else return false.
 * */
export const getUuidDomain = () => {
    return Boolean(localStorage.getItem(DOMAIN_PARTNER_UUID));
};

/**
 * @returns {boolean} true if have uuid or partner domain else return false.
 * */
export const checkPartnerDomain = () => {
    const isTLD = getTopLevelDomain();
    const isPartnerUuid = getUuidDomain();

    return isTLD || isPartnerUuid;
};
/**
 * Validates the parsed identity data, specifically the "Expires" property.
 *
 * @param {number} Expires - The expiration time in timestamp in seconds.
 * @throws {Error} Throws an error if Expires is not a number.
 */
export const validateParsedIdentityData = (Expires) => {
    if (!isNumber(Expires)) {
        throw new Error(t(EZLOGIC_TITLE_INVALID_DATA_TYPE));
    }
};

/**
 * @param {String} userIdentity - User's identity received during authorization
 * @returns {Object} with (PK_Account, PK_PermissionRole, PK_User, Expires) properties obtained from Identity.
 * */
export const getDataFromUserIdentity = (userIdentity) => {
    if (!userIdentity) {
        throw new Error(t(EZLOGIC_TITLE_INVALID_DATA_TYPE));
    }
    const { PK_Account, PK_AccountChild, PK_PermissionRole, PK_User, Expires, PK_Oem } = tokenParser(userIdentity);
    validateParsedIdentityData(Expires);

    return { PK_Account: PK_AccountChild || PK_Account, PK_PermissionRole, PK_User, Expires, PK_Oem };
};

/**
 * Retrieves an error object indicating that a partner installer is trying to log in.
 * @returns {Object} An error object containing data and message.
 * @property {string} data - The error identifier.
 * @property {string} message - The error message with a placeholder for the partner portal URL.
 */
export const getPreventPartnerInstallerLoginError = () => {
    const partnerPortalUrl = oemManager.oem?.getPartnerPortalUrl();

    return {
        data: PARTNER_INSTALLER_LOGIN_ERROR,
        message: `${t(EZLOGIC_TITLE_PREVENT_PARTNER_AND_INSTALLER_TO_LOGIN_MESSAGE)} ${partnerPortalUrl}`,
    };
};

/**
 * Checks if the user is a partner or installer for either the Security or Vera OEMs.
 * @param {number} PK_Oem The PK_Oem value of the user.
 * @param {number} PK_PermissionRole The PK_PermissionRole value of the user.
 * @returns {boolean} True if the user is a partner or installer for either the Security or Vera OEMs, otherwise false.
 * * @example
 * // Returns false
 * isSecurityOrVeraPartnerInstallerUser(1, 10);
 *
 * // Returns true
 * isSecurityOrVeraPartnerInstallerUser(1, 5);
 */
export const isSecurityOrVeraPartnerInstallerUser = (PK_Oem, PK_PermissionRole) => {
    return (
        (PK_Oem === OEM_IDS.VERA_OEM_ID || PK_Oem === OEM_IDS.SECURITY_OEM_ID) &&
        (PK_PermissionRole === INSTALLER_PERMISSION || PK_PermissionRole === PARTNER_PERMISSION)
    );
};
