import React from 'react';

export const IcActionsCall = (props) => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0_19963_18305)">
                <path
                    d="M12.9214 23.4C16.4378 26.9164 22.1336 26.9164 25.65 23.4L22.86 21.3107C22.3521 20.9314 21.6643 20.925 21.15 21.2914L19.6457 22.365C19.1764 22.6993 18.5336 22.6479 18.1221 22.2364L14.0786 18.1929C13.6736 17.7879 13.6157 17.145 13.95 16.6693L15.03 15.1714C15.3964 14.6571 15.39 13.9629 15.0107 13.4614L12.9214 10.6714C9.40498 14.1879 9.40498 19.8836 12.9214 23.4Z"
                    fill="#1C1E21"
                />
                <path
                    d="M22.8214 17.0357C22.8214 17.0357 19.7164 17.0357 19.2857 17.0357V13.5C20.2307 13.5 21.1178 13.8664 21.7864 14.535C22.455 15.2036 22.8214 16.0907 22.8214 17.0357Z"
                    fill="#1C1E21"
                />
                <path
                    d="M26.6786 17.0357H24.75C24.75 15.5764 24.1843 14.2071 23.1493 13.1721C22.1143 12.1371 20.745 11.5714 19.2857 11.5714V9.64286C21.2593 9.64286 23.1171 10.4143 24.5121 11.8093C25.9071 13.2043 26.6786 15.0621 26.6786 17.0357Z"
                    fill="#1C1E21"
                />
            </g>
            <defs>
                <clipPath id="clip0_19963_18305">
                    <rect width="16.3929" height="16.3929" fill="white" transform="translate(10.2857 9.64286)" />
                </clipPath>
            </defs>
        </svg>
    );
};
