import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'lib/@mui';
import { IcControlsInputEraceNoBg } from 'assets/icons';

import style from './style.module.scss';

const Modal = (props) => {
    const { children, visible, onCancel, className } = props;

    return (
        <Dialog open={visible} onClose={onCancel} className={`${style.modalContainer} ${className}`}>
            <div className={style.closeIconContainer}>
                <IcControlsInputEraceNoBg className={style.closeIcon} onClick={onCancel} />
            </div>
            <div className={style.contentWrapper}>{children}</div>
        </Dialog>
    );
};

Modal.propTypes = {
    closable: PropTypes.bool,
    className: PropTypes.string,
    centered: PropTypes.bool,
    keyboard: PropTypes.bool,
    maskClosable: PropTypes.bool,
    children: PropTypes.element,
    bodyStyle: PropTypes.object,
    okText: PropTypes.string,
};

Modal.defaultProps = {
    centered: true,
    keyboard: true,
    maskClosable: true,
    okText: 'Save',
};

export default Modal;
