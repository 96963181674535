/**
 * Constant representing a small size.
 *
 * @constant
 * @type {string}
 */ export const SMALL_SIZE = 'small';

/**
 * Options for density in Material UI.
 *
 * @constant
 * @type {Object}
 * @property {string} STANDARD - Standard density option.
 */
export const DENSITY_OPTIONS = { STANDARD: 'standard' };
/**
 * Field identifier for the selection column in Material UI.
 *
 * @constant
 * @type {string}
 */
export const SELECTION_COLUMN_FIELD = '__check__';
/**
 * Sizes options in Material UI.
 *
 * @constant
 * @type {Object}
 * @property {string} XS - Extra Small size option.
 * @property {string} SM - Small size option.
 * @property {string} MD - Medium size option.
 * @property {string} LG - Large size option.
 * @property {string} XL - Extra Large size option.
 */
export const SIZES = {
    XS: 'xs',
    SM: 'sm',
    MD: 'md',
    LG: 'lg',
    XL: 'xl',
};
/**
 * @description The main color.
 * @type {"primary"}
 * @const
 */
export const PRIMARY = 'primary';
/**
 * @description The color to be inherited from the parent component.
 * @type {"inherit"}
 * @const
 */
export const INHERIT = 'inherit';
