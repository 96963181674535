import React from 'react';

const IcControlsClose32 = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
            <path
                d="M27.24 6.87994C26.07 5.70994 24.17 5.70994 23 6.87994L16.12 13.7599L9.24 6.87994C8.07 5.70994 6.17 5.70994 5 6.87994L13.94 15.8199L5 24.7599C6.17 25.9299 8.07 25.9299 9.24 24.7599L16.12 17.8799L23 24.7599C24.17 25.9299 26.07 25.9299 27.24 24.7599L18.3 15.8199L27.24 6.87994Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcControlsClose32;
