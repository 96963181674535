/**
 * Redux slice for managing alert history state.
 *
 * @module alertHistory
 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LOGOUT } from 'constants/ActionTypes';
import { buildListOfHistoryEvents, getAlertHistoryList } from 'services/alertHistory';
import { IDS } from 'services/alertHistory/constants';
import { bugsnagNotifyWrapper } from 'containers/ErrorBoundary/utils';
import {
    SELECTED_DATE_TIMESTAMP_IN_MS,
    LIST,
    SELECTED_CATEGORY_ID,
    ALERT_HISTORY,
    CURRENT_PAGE,
    SEARCH_VALUE,
    IS_LOADING,
    FIRST_PAGE_NUMBER,
    IS_EMPTY_ALERT_HISTORY_LIST,
} from './constants';
import { getParamsFromState, getStartOfCurrentDayTimestamp } from './utils';
import { isEmpty } from 'lodash';
/**
 * Represents the structure of properties associated with alert history.
 *
 * @typedef {Object} AlertHistoryState
 * @property {Array<HistoryEvent> | null} LIST - List of HistoryEvent.
 * @property {boolean} IS_LOADING - A flag indicating whether the alert history is loading.
 * @property {string} SELECTED_CATEGORY_ID - The selected category ID.
 * @property {string} SEARCH_VALUE - The search value.
 * @property {string} SELECTED_DATE_TIMESTAMP_IN_MS - The selected date timestamp in milliseconds.
 * @property {number} CURRENT_PAGE - The current page number.
 * @property {boolean} IS_EMPTY_ALERT_HISTORY_LIST - A flag indicating whether the alert history list is empty in response from cloud.
 */

/**
 * Initial state for the alert history slice.
 *
 * @type {AlertHistoryState}
 */
const initialState = {
    [LIST]: null,
    [IS_LOADING]: false,
    [SELECTED_CATEGORY_ID]: IDS.ALL_ACTIONS,
    [SEARCH_VALUE]: '',
    [SELECTED_DATE_TIMESTAMP_IN_MS]: getStartOfCurrentDayTimestamp(),
    [CURRENT_PAGE]: FIRST_PAGE_NUMBER,
    [IS_EMPTY_ALERT_HISTORY_LIST]: false,
};

/**
 * Slice for alert history state management.
 *
 * @type {import('@reduxjs/toolkit').Slice}
 */
const alertHistory = createSlice({
    name: ALERT_HISTORY,
    initialState,
    reducers: {
        /**
         * Reducer to set the selected category ID in the alert history state.
         *
         * @param {AlertHistoryState} state - The current state.
         * @param {import('@reduxjs/toolkit').PayloadAction<string | null>} action - The action containing the payload.
         * @param {string | null} action.payload - The selected category ID.
         */
        setCategoryId: (state, action) => {
            state[SELECTED_CATEGORY_ID] = action.payload;
        },
        /**
         * Reducer to set the search value in the alert history state.
         *
         * @param {AlertHistoryState} state - The current state.
         * @param {import('@reduxjs/toolkit').PayloadAction<string>} action - The action containing the payload.
         * @param {string} action.payload - The search value.
         */
        setSearchValue: (state, action) => {
            state[SEARCH_VALUE] = action.payload;
        },
        /**
         * Reducer to set the selected date timestamp in the alert history state.
         *
         * @param {AlertHistoryState} state - The current state.
         * @param {import('@reduxjs/toolkit').PayloadAction<number>} action - The action containing the payload.
         * @param {number} action.payload - The selected date timestamp.
         */
        setSelectedDateTimestamp: (state, action) => {
            state[SELECTED_DATE_TIMESTAMP_IN_MS] = action.payload;
        },
        /**
         * Reducer to set the current page number in the alert history state.
         *
         * @param {AlertHistoryState} state - The current state.
         * @param {import('@reduxjs/toolkit').PayloadAction<number>} action - The action containing the payload.
         * @param {number} action.payload - The current page number.
         */
        setPage: (state, action) => {
            state[CURRENT_PAGE] = action.payload;
        },
        /**
         * Reducer to reset the alert history state to its initial state.
         */
        setInitialState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(LOGOUT, () => initialState)
            .addCase(fetchAlertHistoryList.fulfilled, (state, action) => {
                state[LIST] = action.payload[LIST];
                state[IS_EMPTY_ALERT_HISTORY_LIST] = action.payload[IS_EMPTY_ALERT_HISTORY_LIST];
                state.isLoading = false;
            })
            .addCase(fetchAlertHistoryList.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchAlertHistoryList.rejected, (state) => {
                state.isLoading = false;
            });
    },
});

export const fetchAlertHistoryList = createAsyncThunk(
    'alert/history/fetchList',
    async (payload, { getState, dispatch }) => {
        const params = getParamsFromState(getState(), payload);
        try {
            const historyEventsRange = await getAlertHistoryList(params);
            await dispatch(setPage(params.page));

            return {
                [LIST]: buildListOfHistoryEvents(historyEventsRange),
                [IS_EMPTY_ALERT_HISTORY_LIST]: isEmpty(historyEventsRange),
            };
        } catch (error) {
            bugsnagNotifyWrapper(error, { params, thunkCall: 'alert/history/fetchList' });
            throw error;
        }
    },
);
export const { setCategoryId, setSearchValue, setSelectedDateTimestamp, setPage, setInitialState } =
    alertHistory.actions;
export default alertHistory;
export const alertHistoryReducer = alertHistory.reducer;
