import React from 'react';

export const IcCategoryDevice = (props) => {
    return (
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.7692 40H37.2308C38.8 40 40 38.8 40 37.2308V18.7692C40 17.2 38.8 16 37.2308 16H18.7692C17.2 16 16 17.2 16 18.7692V37.2308C16 38.8 17.2 40 18.7692 40ZM20 23C20 24.65 21.35 26 23 26C24.65 26 26 24.65 26 23C26 21.35 24.65 20 23 20C21.35 20 20 21.35 20 23Z"
                fill="#1C1E21"
            />
        </svg>
    );
};
