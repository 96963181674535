import { ROOM_NOT_AVAILABLE } from '../../../constants/Devices';
// todo: use selector instead?
export const prepareDeviceById = (state, deviceId) => {
    let device = {};
    if (deviceId) {
        for (const [serial, controller] of Object.entries(state.ezlo.data)) {
            if (controller && controller.devices && controller.devices.length > 0) {
                device = controller.devices.find((i) => i._id === deviceId);
                if (device) {
                    device = {
                        ...device,
                        serial,
                        isConnected: controller.isConnected,
                        isFavorite:
                            controller.favorites &&
                            controller.favorites.devices &&
                            controller.favorites.devices.some((favorite) => favorite === device._id),
                        items:
                            controller.items &&
                            controller.items.filter((item) => item.deviceId === device._id && item.show),
                        gateway:
                            controller.gateways &&
                            controller.gateways.find((gateway) => gateway._id === device.gatewayId),
                        settings:
                            controller.settings &&
                            controller.settings.filter((setting) => setting.deviceId === device._id),
                    };
                    break;
                }
            }
        }
    }

    return device;
};

// todo: use selector instead?
export const prepareDevices = (state) => {
    return Object.entries(state.ezlo.data).reduce((acc, [serial, controller]) => {
        if (controller && controller.devices) {
            return acc.concat(
                controller.devices.map((controllerDevice) => {
                    const room =
                        state.ezlo.data[serial].pages &&
                        state.ezlo.data[serial].pages.find((room) => room._id === controllerDevice.roomId);
                    const isFavorite =
                        state.ezlo.data[serial].favorites.devices &&
                        state.ezlo.data[serial].favorites.devices.some((favorite) => favorite === controllerDevice._id);

                    return {
                        ...controllerDevice,
                        room,
                        isFavorite,
                        serial,
                        isConnected: controller.isConnected,
                    };
                }),
            );
        }

        return acc;
    }, []);
};

// todo: use selector instead?
export const prepareRooms = (state) => {
    return Object.entries(state.ezlo.data).reduce((acc, [serial, controller]) => {
        if (controller && controller.pages && controller.pages.length > 0) {
            return acc.concat(
                controller.pages.map((controllerPage) => {
                    return {
                        ...controllerPage,
                        serial,
                        isConnected: controller.isConnected,
                    };
                }),
            );
        }

        return acc;
    }, []);
};

// todo: use selector instead?
export const prepareRules = (state) => {
    return Object.entries(state.ezlo.data).reduce((acc, [serial, controller]) => {
        if (controller && controller.rules && controller.rules.length > 0) {
            return acc.concat(
                controller.rules.map((controllerPage) => {
                    return {
                        ...controllerPage,
                        serial,
                        isConnected: controller.isConnected,
                    };
                }),
            );
        }

        return acc;
    }, []);
};

// todo: use selector instead?
export const prepareGateways = (state) => {
    return Object.entries(state.ezlo.data).reduce((acc, [serial, controller]) => {
        if (controller && controller.gateways && controller.gateways.length > 0) {
            return acc.concat(
                controller.gateways.map((controllerGateway) => {
                    return {
                        ...controllerGateway,
                        serial,
                        isConnected: controller.isConnected,
                    };
                }),
            );
        }

        return acc;
    }, []);
};

// todo: use selector instead?
export const getItemById = (state, itemId) => {
    let item = null;
    if (itemId) {
        for (const [serial, controller] of Object.entries(state.ezlo.data)) {
            if (controller && controller.items && controller.items.length > 0) {
                item = controller.items.find((i) => i._id === itemId);
                if (item) {
                    item = {
                        ...item,
                        serial,
                        isConnected: controller.isConnected,
                    };
                    break;
                }
            }
        }
    }

    return item;
};

export const getRoomName = (roomsList, roomId) => {
    if (!roomsList.length) {
        return ROOM_NOT_AVAILABLE;
    }

    return roomsList.find((room) => room._id === roomId)?.name;
};
