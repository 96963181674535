import React from 'react';
import { IconButton, PRIMARY } from 'lib/@mui';
import classNames from 'classnames';

const MeshbotRun = ({ isDisabled, handleButtonClick, icon, isButton }) => {
    if (isButton) {
        return (
            <IconButton
                color={PRIMARY}
                onClick={handleButtonClick}
                disabled={isDisabled}
                className={classNames({ disabled: isDisabled })}
            >
                {icon}
            </IconButton>
        );
    }

    return icon;
};

export default MeshbotRun;
