/**
 * Enumeration of element types used in 'item' entity.
 *
 * @constant
 * @type {Object}
 * @property {string} USER_CODE - Represents the user code element type.
 *
 * @example
 * // Usage of element types
 * const elementType = ELEMENT_TYPES.USER_CODE;
 * // Result: 'userCode'
 */
export const ELEMENT_TYPES = {
    USER_CODE: 'userCode',
};
/**
 * Enumeration of item names used in 'item' entity.
 *
 * @constant
 * @type {Object}
 * @property {string} USER_CODE - Represents the user code item name.
 *
 * @example
 * // Usage of item names
 * const itemName = ITEM_NAMES.USER_CODE;
 * // Result: 'user_codes'
 */
export const ITEM_NAMES = {
    USER_CODE: 'user_codes',
};
