import React from 'react';

import MeshBotLabelsInTableCellBox from './MeshBotLabelsInTableCellBox';
import MeshBotLabelInTableCell from './MeshBotLabelInTableCell';
import { SLICE_RANGE_FOR_LABELS_TRIMMING } from '../../constants';
import { getLastLabelsInList } from '../../utils';

import styles from './styles.module.scss';

const StrippedDownMeshBotLabelsInTable = ({ labelsData, toggleLabels }) => {
    const strippedDownLabels = labelsData.slice(...SLICE_RANGE_FOR_LABELS_TRIMMING);
    const { name, backgroundColor, uuid } = getLastLabelsInList(labelsData);

    return (
        <MeshBotLabelsInTableCellBox labelsData={strippedDownLabels} toggleLabels={toggleLabels}>
            <div className={styles.ellipses}>...</div>
            <MeshBotLabelInTableCell key={uuid} name={name} backgroundColor={backgroundColor} />
        </MeshBotLabelsInTableCellBox>
    );
};

export default StrippedDownMeshBotLabelsInTable;
