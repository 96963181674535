import React from 'react';

export const IcVisualMobilePhone = (props) => {
    return (
        <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M77.7778 33C68 33 60 41.6143 60 52.1429V147.857C60 158.386 68 167 77.7778 167H122.222C132 167 140 158.386 140 147.857V52.1429C140 41.6143 132 33 122.222 33H77.7778Z"
                fill="#1C1E21"
            />
            <path d="M140 52H60V148H140V52Z" fill="#3E46AE" />
            <path d="M140 120L60 52H140V120Z" fill="#1F2581" />
            <rect x="90" y="41" width="20" height="6" rx="3" fill="black" />
        </svg>
    );
};

export default IcVisualMobilePhone;
