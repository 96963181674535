export const INT_RANGE = {
    int32: {
        min: -21474836488,
        max: 2147483647,
    },
    int64: {
        min: -9223372036854775808,
        max: 9223372036854775807,
    },
};

export const DYNAMIC_FIELDS_TYPES = {
    string: 'string',
    integer: 'integer',
    number: 'number',
    boolean: 'boolean',
};
