import React from 'react';

export const EzloLogo = () => {
    return (
        <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            width="70"
            height="30"
            viewBox="0 0 1557 645.58"
            className="ezlo"
        >
            <path d="M1557,442.46c-3.26,11.94-6.52,23.89-9.79,35.83-29.78,108.9-139.77,180.51-253.67,165.15-109.64-14.78-195.27-110.69-197.31-220.96-2.27-123.25,86.15-223.43,208.55-236.29,116.42-12.23,225.15,71.15,247.66,189.76,.73,3.85,3,7.42,4.56,11.12v55.4Zm-230.73,106.71c74.32-.23,134.95-61.02,134.32-134.68-.63-73.33-62.52-133.69-136.41-133.02-72.91,.66-132.31,60.66-132.49,133.81-.18,74.55,59.7,134.12,134.57,133.89Z" />
            <path d="M180.7,544.02h236.91c-32.17,67.87-140.81,114.6-224.81,98.3C84.1,621.23,6.15,533.76,.31,426.31c-5.47-100.56,62.39-198.1,159.45-229.19,99.76-31.96,207.85,6.07,264.77,95.72-82.41,80.72-165.47,162.07-248.53,243.42,1.57,2.58,3.13,5.17,4.7,7.75Zm-74.38-82.12c57.67-57.14,115.32-114.26,172.05-170.46-46.53-20.4-103.6-9.4-142.55,29.52-38.73,38.71-49.66,96.53-29.5,140.94Z" />
            <path d="M730.57,286.49h-254.06v-93.27h387.06c0,29.7,1.73,58.87-.97,87.64-.91,9.69-12.15,19.24-20.22,27.32-71.19,71.32-142.75,142.29-214.14,213.42-5.32,5.3-10.17,11.08-18.97,20.71h252.8v94.76H475.71c0-29.6-1.52-60.33,.96-90.73,.71-8.72,12.48-17.03,20.08-24.63,71.27-71.23,142.76-142.25,214.11-213.4,5.26-5.24,9.99-11.02,19.71-21.82Z" />
            <path d="M939.88,0h90.54V637.44h-90.54V0Z" />
        </svg>
    );
};
