export const HTTP_METHODS = {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
};

export const HTTP_HEADERS = {
    CONTENT_TYPE: {
        key: 'Content-type',
        values: {
            json: 'application/json',
            charset: 'charset=UTF-8',
        },
    },
};
