import axios from 'axios';
import { APP_CONFIG_URL } from './constants';

/**
 * Fetch config.json file
 * @returns {Promise<Object>} api response
 * */
export const apiGetApplicationConfig = () => {
    return axios.get(APP_CONFIG_URL);
};
