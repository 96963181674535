import React, { useState } from 'react';
import { isEmpty } from 'lodash';

import useMeshBotLabelsState from '../../hooks/useMeshBotLabelsState';
import StrippedDownMeshBotLabelsInTable from './StrippedDownMeshBotLabelsInTable';
import MeshBotLabelsInTableCellBox from './MeshBotLabelsInTableCellBox';
import { MAX_NUM_OF_LABELS_OPENED_BY_DEFAULT } from '../../constants';

import styles from './styles.module.scss';

const MeshBotLabelsInTableCell = ({ labelsUuids, meshBotUuid }) => {
    const labelsData = useMeshBotLabelsState(labelsUuids, meshBotUuid);
    const [open, setOpen] = useState(false);

    if (isEmpty(labelsData)) {
        return null;
    }

    const toggleLabels = (e) => {
        e.stopPropagation();
        setOpen((prevState) => !prevState);
    };

    if (labelsData.length > MAX_NUM_OF_LABELS_OPENED_BY_DEFAULT && !open) {
        return <StrippedDownMeshBotLabelsInTable labelsData={labelsData} toggleLabels={toggleLabels} />;
    }

    return (
        <MeshBotLabelsInTableCellBox className={styles.allLabels} labelsData={labelsData} toggleLabels={toggleLabels} />
    );
};

export default MeshBotLabelsInTableCell;
