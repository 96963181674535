import React from 'react';

const IcAlarmIcon = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
            <path
                d="M29 29.7V31H26H6H3V29.7C3 28.6 3.4 27.6 4.2 26.9L6 25H9H10.9H16V9C12.1 9 9 12.1 9 16C9 18 9 20.2 9 22H6V16C6 10.3 10.8 5.6 16.7 6C22 6.4 26 11 26 16.3V25L27.8 26.8C28.6 27.6 29 28.6 29 29.7ZM17 2C17 1.4 16.6 1 16 1C15.4 1 15 1.4 15 2V4H17V2ZM7.2 4.8C6.8 4.4 6.2 4.4 5.8 4.8C5.4 5.2 5.4 5.8 5.8 6.2L7.2 7.6L8.6 6.2L7.2 4.8ZM23.4 6.2L24.8 7.6L26.2 6.2C26.6 5.8 26.6 5.2 26.2 4.8C25.8 4.4 25.2 4.4 24.8 4.8L23.4 6.2Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcAlarmIcon;
