import { get, isArray, isNumber, isObject, isString } from 'lodash';
import {
    eventsParams,
    hubInfoChangedParams,
    hubModesSwitchedParamsById,
    lowBatteryEventParams,
    modesAlarmedByPass,
    modesAlarmedEntryDelayBegin,
    modesAlarmedEntryDelayFinish,
    modesAlarmedMainDone,
    networkChangedEventParams,
    networkChangedToDownStatusEventParams,
    paramsOfSubclassTypeProgress,
    tooLowBatteryEventParams,
} from '../constants/config';
import { CATEGORY_ID, DESCRIPTION_CONFIG, LOW_BATTERY } from '../constants';
import { getDeviceEventParamsByResultStructure } from './getDeviceEventParamsByResultStructure';
import { getEventParamsByMsgSubclass } from './getEventParamsByMsgSubclass';

/**
 * Retrieves the value corresponding to a placeholder from a history event item based on the provided paths.
 * If the value cannot be retrieved, it returns 'undefined'.
 *
 * @param {string} placeholder - The placeholder whose value needs to be retrieved.
 * @param {Object} historyEventItem - The history event item object containing the data.
 * @param {Object} placeholdersPaths - An object containing paths to the values corresponding to placeholders.
 * @returns {string} The value corresponding to the placeholder, or 'undefined' if not found or an error occurs.
 */
export const getPlaceholderValue = (placeholder, historyEventItem, placeholdersPaths) => {
    try {
        const path = placeholdersPaths[placeholder];
        if (!isString(path)) {
            return 'undefined';
        }
        const value = get(historyEventItem, path);

        if (isArray(value)) {
            return value.join(', ');
        }

        if (isString(value) || isNumber(value)) {
            return value;
        }

        return 'undefined';
    } catch (error) {
        return 'undefined';
    }
};
/**
 * Checks if the provided object is a valid history parameters object.
 * A valid history parameters object is an object that has properties 'categoryId' and 'descriptionConfig'.
 *
 * @param {Object} obj - The object to be checked.
 * @returns {boolean} Returns true if the object is a valid history parameters object, otherwise false.
 */
export const isHistoryParamsObject = (obj) =>
    isObject(obj) && obj.hasOwnProperty(CATEGORY_ID) && obj.hasOwnProperty(DESCRIPTION_CONFIG);

/**
 * Retrieves the parameters for a battery event based on the result and message subclass.
 * If the value is provided in the result, it returns the corresponding event parameters.
 *
 * @param {Object} result - The result object containing information about the battery event.
 * @returns {AlertHistoryEventParams|undefined} The event parameters corresponding to the battery, or undefined if no value is provided.
 */
export const getBatteryEventParams = (result) => {
    if (result?.value == 10) {
        return tooLowBatteryEventParams;
    }

    if (result?.value == 20) {
        return lowBatteryEventParams;
    }
};
/**
 * Retrieves the parameters for a user lock operation event based on the result and message subclass.
 * If the action is provided in the result, it returns the corresponding event parameters.
 *
 * @param {Object} result - The result object containing information about the user lock operation.
 * @param {string} msg_subclass - The message subclass for the user lock operation event.
 * @returns {AlertHistoryEventParams | undefined} The event parameters corresponding to the user lock operation, or undefined if no action is provided.
 */
export const getUserLockOperationParams = (result, msg_subclass) => {
    const { action } = result?.value || {};
    if (action) {
        return eventsParams?.[msg_subclass]?.[result.name]?.[action];
    }
};
/**
 * Checks if the provided dictionary contains the specified value.
 *
 * @param {Object} dictionary - The dictionary object to search in.
 * @param {*} searchValue - The value to search for in the dictionary.
 * @returns {boolean} True if the dictionary contains the specified value, otherwise false.
 */
export const isDictionaryWithValue = (dictionary, searchValue) =>
    isObject(dictionary) && Object.values(dictionary).some((value) => value === searchValue);
/**
 * Retrieves the parameters for barrier problem sensors event based on the result and message subclass.
 * If the result contains the 'low_battery' value, it returns the corresponding event parameters.
 *
 * @param {Object} result - The result object containing information about the event.
 * @param {string} msg_subclass - The message subclass for the event.
 * @returns {AlertHistoryEventParams | undefined} The event parameters corresponding to the barrier problem sensors event with low battery, or undefined if not found.
 */
export const getBarrierProblemSensorsParams = (result, msg_subclass) => {
    if (isDictionaryWithValue(result?.value, LOW_BATTERY)) {
        return eventsParams?.[msg_subclass]?.[result.name]?.[LOW_BATTERY];
    }
};
/**
 * Retrieves the parameters for hub.modes.alarmed broadcast based on the result phase and status.
 *
 * @param {Object} result - The result object containing information about the event.
 * @returns {AlertHistoryEventParams | undefined} The event parameters corresponding to the hub modes alarmed event, or undefined if not found.
 */
export const getHubModesAlarmedParams = (result) => {
    if (result?.phase === 'bypass' && result?.status === 'done') {
        return modesAlarmedByPass;
    }

    if (result?.phase === 'entryDelay' && result?.status === 'done') {
        return modesAlarmedEntryDelayFinish;
    }

    if (result?.phase === 'main' && result?.status === 'done') {
        return modesAlarmedMainDone;
    }

    if (result?.phase === 'entryDelay' && result?.status === 'begin') {
        return modesAlarmedEntryDelayBegin;
    }
};

/**
 * Retrieves the parameters for hub.modes.switched broadcast based on the result "to" and status.
 *
 * @param {Object} result - The result object containing information about the event.
 * @returns {AlertHistoryEventParams | undefined} The event parameters corresponding to the hub modes alarmed event, or undefined if not found.
 */
export const getHubModesSwitchedParams = (result) => {
    if (result?.status === 'done') {
        return hubModesSwitchedParamsById[result?.to];
    }
};
/**
 * Retrieves the parameters for event of 'hub.info.changed' broadcast based on the battery status, state of charge,
 * remaining time, and health.
 *
 * @param {Object} result - The result object containing information about the event.
 * @returns {AlertHistoryEventParams | undefined} The event parameters corresponding to the hub information changed event, or undefined if not found.
 */
export const getHubInfoChangedParams = (result) => {
    if (!isObject(result?.battery)) {
        return;
    }
    const { status, stateOfCharge, remainingTime, health } = result.battery || {};

    if (status && hubInfoChangedParams?.battery?.status?.[status]) {
        return hubInfoChangedParams.battery.status[status];
    }

    if (stateOfCharge) {
        return hubInfoChangedParams?.battery?.stateOfCharge;
    }

    if (remainingTime) {
        return hubInfoChangedParams?.battery?.remainingTime;
    }

    if (health) {
        return hubInfoChangedParams?.battery?.health;
    }
};
/**
 * Retrieves the parameters for event of 'hub.network.changed' broadcast based on the result object.
 *
 * @param {Object} result - The result object containing information about the network.
 * @returns {AlertHistoryEventParams | undefined} The event parameters for the hub network changed event, or undefined if not found.
 */
export const getHubNetworkChangedParams = (result) => {
    if (result?.interfaces?.[0]?._id === 'eth0' && result?.interfaces?.[0]?.status === 'down') {
        return networkChangedToDownStatusEventParams;
    }

    if (result?.interfaces?.[0]?._id && result?.interfaces?.[0]?.status) {
        return networkChangedEventParams;
    }
};
/**
 * Retrieves the parameters for an updated button_state event based on the provided result object and message subclass.
 *
 * @param {Object} result - The result object containing information about the button state.
 * @param {string} msg_subclass - The message subclass.
 * @returns {AlertHistoryEventParams | undefined} The event parameters for the updated button state event, or undefined if not found.
 */
export const getUpdatedButtonStateParams = (result, msg_subclass) => {
    const { button_state } = result?.value || {};

    if (button_state) {
        return eventsParams[msg_subclass]?.[result.name]?.[button_state];
    }
};
/**
 * Retrieves the parameters for a progress type message subclass('hub.software.update.progress', 'hub.backup.restore.progress', 'hub.backup.create.progress') based on the provided result and msg_subclass.
 *
 * @param {Object} result - The result object containing information about the progress.
 * @param {string} msg_subclass - The message subclass.
 * @returns {AlertHistoryEventParams|undefined} The parameters for the progress type msg_subclass.
 */
export const getProgressTypeMsgSubclassesParams = (result, msg_subclass) => {
    if (!result) {
        return;
    }
    const status = result?.error === null ? 'success' : 'error';

    return paramsOfSubclassTypeProgress?.[msg_subclass]?.[status];
};
/**
 * Retrieves the parameters for an event based on the provided event history item.
 *
 * @param {ComHistoryEvent} eventHistoryItem - The event history item containing information about the event.
 * @returns {AlertHistoryEventParams | undefined} The parameters for the event, or undefined if the event is not recognized.
 */
export const getEventParams = (eventHistoryItem) => {
    const msg_subclass = eventHistoryItem?.data?.msg_subclass;
    const result = eventHistoryItem?.data?.result;

    if (isHistoryParamsObject(eventsParams?.[msg_subclass])) {
        return eventsParams[msg_subclass];
    }

    if (isHistoryParamsObject(eventsParams?.[msg_subclass]?.[result?.name])) {
        return eventsParams[msg_subclass][result?.name];
    }

    if (isHistoryParamsObject(eventsParams?.[msg_subclass]?.[result?.name]?.[result.value])) {
        return eventsParams[msg_subclass][result?.name][result.value];
    }

    const paramsByMsgSubclass = getEventParamsByMsgSubclass(msg_subclass, result);
    if (isHistoryParamsObject(paramsByMsgSubclass)) {
        return paramsByMsgSubclass;
    }

    const deviceParamsByResultStructure = getDeviceEventParamsByResultStructure(result);
    if (isHistoryParamsObject(deviceParamsByResultStructure)) {
        return deviceParamsByResultStructure;
    }
};
