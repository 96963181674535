import React from 'react';

export const ArrowUpIcon = (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 32">
        <title>ezlo</title>
        <path d="M19.855 4.092c1.088 0 2.074 0.442 2.786 1.155l15.967 15.963c0.684 0.708 1.107 1.674 1.107 2.738 0 2.177-1.765 3.941-3.941 3.941-1.064 0-2.030-0.422-2.739-1.107l-13.179-13.175-13.18 13.18c-0.708 0.684-1.674 1.107-2.738 1.107-2.177 0-3.941-1.765-3.941-3.941 0-1.064 0.422-2.030 1.107-2.739l15.966-15.966c0.713-0.713 1.698-1.155 2.786-1.155z"></path>
    </svg>
);

export default ArrowUpIcon;
