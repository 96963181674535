export const GROUPS_FETCH_CONTROLLER_GROUP_LIST = 'GROUPS_FETCH_CONTROLLER_GROUP_LIST';
export const GROUPS_FETCH_CONTROLLER_GROUP_LIST_ON_SUCCESS = 'GROUPS_FETCH_CONTROLLER_GROUP_LIST_ON_SUCCESS';
export const GROUPS_FETCH_CONTROLLER_GROUP_LIST_ON_ERROR = 'GROUPS_FETCH_CONTROLLER_GROUP_LIST_ON_ERROR';

export const GROUPS_CONTROLLER_GET_REPORT = 'GROUPS_CONTROLLER_GET_REPORT';
export const GROUPS_CONTROLLER_GET_REPORT_ON_SUCCESS = 'GROUPS_CONTROLLER_GET_REPORT_ON_SUCCESS';
export const GROUPS_CONTROLLER_GET_REPORT_ON_ERROR = 'GROUPS_CONTROLLER_GET_REPORT_ON_ERROR';

export const CLEAR_GROUPS_STATE_FROM_REMOVED_CONTROLLER = 'CLEAR_GROUPS_STATE_FROM_REMOVED_CONTROLLER';
export const SET_ALL_CONTROLLERS_INFORMATION_TO_GROUPS_STATE = 'SET_ALL_CONTROLLERS_INFORMATION_TO_GROUPS_STATE';

export const GROUPS_CREATE_CONTROLLER_GROUP = 'GROUPS_CREATE_CONTROLLER_GROUP';
export const GROUPS_CREATE_CONTROLLER_GROUP_IN_PROGRESS = 'GROUPS_CREATE_CONTROLLER_GROUP_IN_PROGRESS';
export const GROUPS_CREATE_CONTROLLER_GROUP_ON_SUCCESS = 'GROUPS_CREATE_CONTROLLER_GROUP_ON_SUCCESS';
export const GROUPS_CREATE_CONTROLLER_GROUP_ON_ERROR = 'GROUPS_CREATE_CONTROLLER_GROUP_ON_ERROR';

export const GROUPS_DELETE_CONTROLLER_GROUP = 'GROUPS_DELETE_CONTROLLER_GROUP';
export const GROUPS_DELETE_CONTROLLER_GROUP_ON_SUCCESS = 'GROUPS_DELETE_CONTROLLER_GROUP_ON_SUCCESS';
export const GROUPS_DELETE_CONTROLLER_GROUP_ON_ERROR = 'GROUPS_DELETE_CONTROLLER_GROUP_ON_ERROR';

export const GROUPS_UPDATE_CONTROLLER_GROUP = 'GROUPS_UPDATE_CONTROLLER_GROUP';
export const GROUPS_UPDATE_CONTROLLER_GROUP_ON_SUCCESS = 'GROUPS_UPDATE_CONTROLLER_GROUP_ON_SUCCESS';
export const GROUPS_UPDATE_CONTROLLER_GROUP_ON_ERROR = 'GROUPS_UPDATE_CONTROLLER_GROUP_ON_ERROR';

export const GROUPS_FETCH_ACCOUNT_EZLOS = 'GROUPS_FETCH_ACCOUNT_EZLOS';
export const GROUPS_FETCH_ACCOUNT_EZLOS_SUCCESS = 'GROUPS_FETCH_ACCOUNT_EZLOS_SUCCESS';
export const GROUPS_FETCH_ACCOUNT_EZLOS_ERROR = 'GROUPS_FETCH_ACCOUNT_EZLOS_ERROR';
