import './style.scss';
import React from 'react';
import classNames from 'classnames';
import { CONTROLLER_STATUS } from '../../constants/Hubs';
import { DISABLE } from '../../containers/Ezlo/EzloCustomization/constants';

const MeshbotSerial = ({ row: { original: params } }) => {
    const { isDisabled, serial } = params;

    return (
        <div className="meshbot-cell">
            {serial && (
                <span className="serial">
                    {serial}
                    <span
                        title={!isDisabled ? CONTROLLER_STATUS.ONLINE : CONTROLLER_STATUS.OFFLINE}
                        className={classNames('status', { online: !isDisabled && params.id !== DISABLE })}
                    />
                </span>
            )}
        </div>
    );
};

export default MeshbotSerial;
