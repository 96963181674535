import WSPool from './WSPool';
import WSEvents from './WSEvents';

import isString from 'lodash/isString';
import isObject from 'lodash/isObject';
import merge from 'lodash/merge';
import pako from 'pako';
import { bugsnagNotify } from '../../../containers/ErrorBoundary/utils';

const WSStatus = {
    CONNECTING: 0,
    OPEN: 1,
    CLOSING: 2,
    CLOSED: 3,
};

export default class WS {
    constructor() {
        this.ws = null;
        this.reconnectCount = 0;
        this.wsPool = new WSPool();
        this.wsEvents = new WSEvents();
        this.config = {
            reconnectTimeout: 5000,
            reconnectCount: 3,
            poolSize: 100,
            poolTimeout: 1000, // life time packet
        };
    }

    static getParsedData(msg) {
        let data = null;

        try {
            data = JSON.parse(msg);
        } catch (error) {
            const [id] = msg.match(/id":\ ?"\w*-\w*-\w*-\w*-\w*/gi)[0].match(/\w*-\w*-\w*-\w*-\w*/g);

            data = { error, id, result: {} };
        } finally {
            return data;
        }
    }

    start(config) {
        if (config && config.url && !this.isOnline()) {
            merge(this.config, config);

            return new Promise((resolve, reject) => {
                this.connect(resolve, reject);
            });
        } else {
            bugsnagNotify('Websocket Start Error', { config });

            return Promise.reject();
        }
    }

    connect(resolve, reject) {
        this.ws = new WebSocket(this.config.url);
        this.ws.binaryType = 'arraybuffer';

        this.ws.onopen = (e) => {
            this.reconnectTimeoutHandler = null;
            this.reconnectCount = 0;
            this.ws.onmessage = this.onMessage.bind(this);
            this.wsPool.init(this.config.poolSize);
            resolve(e);
        };

        this.ws.onclose = (e) => {
            if (this.lostConnection && this.reconnectCount < this.config.reconnectCount) {
                this.reconnectCount++;
                this.reconnectTimeoutHandler = setTimeout(() => {
                    this.connect(resolve, reject);
                }, this.config.reconnectTimeout);
            } else {
                reject(e);
            }
        };

        this.ws.onerror = () => {
            this.lostConnection = true;
        };
    }

    close() {
        if (this.ws) {
            this.ws.close();
        }
    }

    isOnline() {
        if (this.ws) {
            switch (this.ws.readyState) {
                case WSStatus.OPEN:
                    return true;
                case WSStatus.CONNECTING:
                case WSStatus.CLOSED:
                case WSStatus.CLOSING:
                    return false;
            }
        }

        return false;
    }

    onMessage(data) {
        try {
            let msg = data.data;
            if (data.data instanceof ArrayBuffer) {
                this.ws.isBinary = true;
                msg = pako.inflate(new Uint8Array(data.data), { to: 'string' });
            }
            this.resolve(WS.getParsedData(msg));
        } catch (e) {
            // eslint-disable-next-line
            console.log('onMessage, e:', e);
        }
    }

    resolve(response) {
        if (response.hasOwnProperty('msg_subclass')) {
            this.wsEvents.resolveEvent(response);
        } else {
            this.wsPool.resolvePacket(response);
        }
    }

    send(method, params) {
        return new Promise((resolve, reject) => {
            const packet = this.wsPool.createPacket();
            packet.create(method, params, resolve, reject);

            if (this.isOnline() && isString(method) && isObject(params)) {
                let data = JSON.stringify(packet.getMessage());
                packet.sourceOut = data;
                if (this.isBinary) {
                    data = pako.deflate(data, { gzip: true }).buffer;
                }
                this.ws.send(data);
            } else {
                packet.reject('Failed connection');
            }
        });
    }

    subscribe(name, cb) {
        return this.wsEvents.subscribe(name, cb);
    }

    unsubscribe(name, cb) {
        return this.wsEvents.unsubscribe(name, cb);
    }
}
