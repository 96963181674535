import React from 'react';

const IcActionsSoftwareFailure = (props) => {
    return (
        <svg width="29" height="29" viewBox="0 0 29 29" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26.07 20.07C27.88 18.26 29 15.76 29 13C29 7.48 24.52 3 19 3C16.2374 3 13.74 4.12158 11.9319 5.93193L26.07 20.07ZM23.7829 21.7829L29 27L27 29L20.8323 22.8323C20.2382 22.9424 19.6258 23 19 23H8C4.13 23 1 19.87 1 16C1 12.4481 3.63664 9.5195 7.0621 9.0621L0 2L2 0L10.22 8.21998L23.7829 21.7829Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcActionsSoftwareFailure;
