import React from 'react';

export const GarageDoor = (props) => (
    <svg {...props} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.8 5.9L13 0L2.2 5.9C0.9 6.6 0 8 0 9.5V23C0 24.7 1.3 26 3 26V10C3 8.9 3.9 8 5 8H21C22.1 8 23 8.9 23 10V26C24.7 26 26 24.7 26 23V9.5C26 8 25.1 6.6 23.8 5.9Z" />
        <path d="M18 26H8C6.3 26 5 24.7 5 23H21C21 24.7 19.7 26 18 26Z" />
        <path d="M18 20H8C6.3 20 5 18.7 5 17H21C21 18.7 19.7 20 18 20Z" />
        <path d="M18 14H8C6.3 14 5 12.7 5 11H21C21 12.7 19.7 14 18 14Z" />
    </svg>
);

export default GarageDoor;
