import { localStorageService } from '../../../localstorage';
import { ENGLISH_KEY, LANGUAGE_TO_DIRECTORY_NAME_CONTAINER } from '../constant/lang';
import { LANGUAGE, TRANSLATIONS } from './../constant/lang';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

export const getLocalStorageLanguageKey = () => {
    const getLanguageKeyFromLocalStorage = localStorageService.getLocalStorage(LANGUAGE);
    const getValidLangkey = LANGUAGE_TO_DIRECTORY_NAME_CONTAINER[getLanguageKeyFromLocalStorage?.slice(0, 2)];

    return getValidLangkey;
};

/**
 * @returns language key from browser
 * */

export const getBrowserKey = () => {
    const getDefaultBrowserKey = navigator.language;
    const getValidLangkey = LANGUAGE_TO_DIRECTORY_NAME_CONTAINER[getDefaultBrowserKey.slice(0, 2)];

    return getValidLangkey;
};

/**
 * Returns onle verified language key that Ezlogic support getting from user local storage or browser.
 * @param {string} localStorageKey - getting language key from local stroage.
 * @param {string} browserKey - getting browser default key.
 * @returns {string} If localStorageKey ,If browserKey or by default "en"
 *
 * @example:
 *
 * Output:
 *
 * "en"
 *
 **/

export const getLanguageByBrowserOrUserDefine = () => {
    const getlocalStorageKey = getLocalStorageLanguageKey();
    if (getlocalStorageKey) {
        return getlocalStorageKey;
    }

    const browserKey = getBrowserKey();
    if (browserKey) {
        return browserKey;
    }

    return ENGLISH_KEY;
};

/**
 * @returns language key from localstorage
 * */

/**
 * Returns extracted data collect from languages payload
 * @param {string} key - USER_LANGUAGE_KEY Or browser default language key
 * @param {object} langPayload - Collect payload object from selected lanuguages 
 * @returns {Function: JSON} Call a function that contain a Promise and extract the language data into JSON form and return it.
 * 
 * @example:
 * Input:
 * key = "en";
 *  
 * Output:
 * {
    "ezlo.intro.subtitle.experience_it": ", and I'm excited for you to experience it.",
    "ezlo.intro.subtitle.tap_finger": " tap of your finger"
}
**/

export const getLanguageDataByKey = async (languageKey) => {
    try {
        const langPayload = await require(`../../../../locales/${languageKey}/translation.json`);
        const lang53 = await require(`../../../../locales/${languageKey}/53.json`);

        return { ...langPayload, ...lang53 };
    } catch (error) {
        return null;
    }
};

/**
 * Returns extracted data collect from languages payload
 * @param {string} langKey - USER_LANGUAGE_KEY
 * @param {object} langPayload - JSON payload 
 * 
 * @example:
 * 
 * Input:
 * 
 * langKey = "en";
 * langPayload = {
 *  "ezlo.intro.subtitle.experience_it": ", and I'm excited for you to experience it.",
    "ezlo.intro.subtitle.tap_finger": " tap of your finger"
 * }

 * Output:
 * Configure the current language by default or when user need to select
 * 
**/

export const languageConfigure = (langKey, langPayload) => {
    try {
        const languageTranslatinos = {
            [langKey]: {
                translations: langPayload,
            },
        };
        i18n.use(initReactI18next).init({
            fallbackLng: langKey,
            lng: langKey,
            resources: languageTranslatinos,
            ns: [TRANSLATIONS],
            defaultNS: TRANSLATIONS,
        });
        window.translationData = langPayload;

        return true;
    } catch (error) {
        return false;
    }
};
