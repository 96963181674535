import React from 'react';

export const CheckboxNewIcon = (props) => (
    <svg {...props} width="40" height="40" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="17.5" y="17.5" width="21" height="21" rx="2.5" fill="#3E46AE" stroke="#3E46AE" strokeWidth="3" />
        <path
            d="M24.55 35.6C23.8777 35.6 23.2329 35.3329 22.7575 34.8575L18 30.1L20.1 28L24.6 32.4L36 21L38.1 23.1L26.3425 34.8575C25.8671 35.3329 25.2223 35.6 24.55 35.6Z"
            fill="white"
        />
    </svg>
);

export default CheckboxNewIcon;
