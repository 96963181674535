/**
 * Represents the model identifier for the EZLO Plus model.
 * @type {string}
 */
export const EZLO_PLUS_MODEL = 'h2.1';
/**
 * Represents the model identifier for the EZLO Security model.
 * @type {string}
 */
export const EZLO_SECURITY_MODEL = 'h2_secure.1';

/**
 * Enum representing possible statuses for hub backup operations.
 *
 * @readonly
 * @enum {string}
 * @property {string} FINISHED - The operation has finished successfully.
 * @property {string} ABORTED - The operation has been aborted.
 * @property {string} PROCESS - The operation is currently in progress.
 * @property {string} STARTED - The operation has started.
 */
export const STATUSES = {
    FINISHED: 'finished',
    ABORTED: 'aborted',
    PROCESS: 'process',
    STARTED: 'started',
};

/**
 * Enumeration of hub features with associated names.
 *
 * @readonly
 * @enum {string}
 * @property {string} BACKUPS - backups feature name on hub.
 */
export const HUB_FEATURES_NAMES = {
    BACKUPS: 'backups',
};
/**
 * Enumeration of operations used in hub broadcasts.
 *
 * @constant
 * @type {Object}
 * @property {string} ADDED - Represents the 'added' operation.
 * @property {string} UPDATED - Represents the 'updated' operation.
 * @property {string} REMOVED - Represents the 'removed' operation.
 *
 * @example
 * // Usage of operations
 * const operation = OPERATIONS.ADDED;
 * // Result: 'added'
 */
export const OPERATIONS = {
    ADDED: 'added',
    UPDATED: 'updated',
    REMOVED: 'removed',
};

/**
 * Constant object representing different models of hubs.
 *
 * @example
 * import { MODELS } from './models';
 * console.log(MODELS['h2.1']); // Should output 'h2.1'
 *
 * @type {Object.<string, string>}
 * @property {string} h2.1 - Represents hub model 'h2.1'
 * @property {string} h2_secure.1 - Represents hub model 'h2_secure.1'
 * @property {string} 32.1 - Represents hub model '32.1'
 * @property {string} ATOM32 - Represents hub model 'ATOM32'
 * @property {string} vhub - Represents hub model 'vhub'
 * @property {string} plughub.1 - Represents hub model 'plughub.1'
 * @property {string} local - Represents hub model 'local'
 * @const
 * @exports
 */
export const MODELS = {
    'h2.1': 'h2.1',
    'h2_secure.1': 'h2_secure.1',
    '32.1': '32.1',
    'ATOM32': 'ATOM32',
    'vhub': 'vhub',
    'plughub.1': 'plughub.1',
    'local': 'local',
    'ezlopi_generic': 'ezlopi_generic',
};
