import React from 'react';
import PropTypes from 'prop-types';
import { DEFAULT_APP_VERSION } from './constants';

/**
 * AppVersion - component, used as a label for showing current application version.
 *
 * @component
 * @example
 * const publicVersion = "1.35.1"
 * const buildVersion = "1.2.3.4"
 * return (
 *   <AppVersion publicVersion={publicVersion} buildVersion={buildVersion} />
 * )
 * */
const AppVersion = (props) => {
    const { publicVersion, buildVersion, ...other } = props;

    return (
        <span {...other} title={buildVersion}>
            {`v. ${publicVersion}`}
        </span>
    );
};

AppVersion.propTypes = {
    publicVersion: PropTypes.string,
    buildVersion: PropTypes.string,
};

AppVersion.defaultProps = {
    publicVersion: DEFAULT_APP_VERSION,
    buildVersion: DEFAULT_APP_VERSION,
};

export default AppVersion;
