export const SUPPORT = {
    HELPTOPIC: '1',
    PRIORITY: '1',
};
export const ISSUE = 'issue';
export const PRIORITY = 'priority';
export const HELPTOPIC = 'helptopic';
export const CREATE_TICKET_URL = '/ezlo/create-ticket';
export const BLANK = '_blank';
export const TEL_HREF = 'tel:';
export const EMAIL_HREF = 'mailto:';
export const STATUSES = {
    SUCCESS: 'success',
    FAILED: 'failed',
    PENDING: 'pending',
    DEFAULT: 'default',
};
