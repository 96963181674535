import WSPacket from './WSPacket';

export default class WSPool {
    constructor() {
        this.current = 1;
        this.pool = new Set();
        this.packets = new Map();
        this.index = 0;
    }

    init(size) {
        for (let i = 0; i < size; i++) {
            this.pool.add(new WSPacket());
        }
    }

    /**
     * Generates and returns random identifier
     * @returns {string} random id
     * */
    static getIndex() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = (Math.random() * 16) | 0;
            const v = c === 'x' ? r : (r & 0x3) | 0x8;

            return v.toString(16);
        });
    }

    clear() {
        this.pool.clear();
        this.packets.clear();
    }

    getLastPacket() {
        const packets = [...this.pool], // SetObject to Array
            packet = packets[packets.length - 1];
        this.pool.delete(packet);

        return packet;
    }

    /**
     * @return {WSPacket} packet
     * */
    createPacket() {
        let packet = null;
        const index = WSPool.getIndex();
        if (this.pool.size) {
            packet = this.getLastPacket();
            packet.id = index;
        } else {
            packet = new WSPacket(index);
        }

        this.packets.set(index, packet);

        return packet;
    }

    resolvePacket(resp) {
        const packet = this.packets.get(resp.id);
        if (packet && packet instanceof WSPacket) {
            packet.sourceIn = resp.sourceIn;
            if (resp.error) {
                packet.reject(resp.error);
            } else {
                packet.resolve(resp.result);
            }
            packet.clear();
            this.packets.delete(resp.id);
            this.pool.add(packet);
        }
    }
}
