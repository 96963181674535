import moment from 'moment/moment';
import { t } from 'helpers/language';
import { getPlaceholderValue } from './utils';

/**
 * Data for displaying the event on the UI
 *
 * @typedef {Object} UiData
 * @property {string} description - The description of the ui alert/history event.
 * @property {string} time - The time the event happened.
 * @property {string} categoryId - The category id of the event.
 * @property {string} [color] - The color of the event.
 * @property {React.FC} [icon] - The icon of the event.
 */

/**
 * Full History Event Data
 *
 * @typedef {Object} HistoryEvent
 * @property {ComHistoryEvent} cloudData - Data received from the cloud.
 * @property {UiData} uiData - Data for displaying the event on the UI.
 */

/**
 * Service for building UI data for alert/history event.
 */
export class HistoryEventService {
    /**
     * Constructor for the HistoryEventService.
     * @param {ComHistoryEvent} historyEventItem - The alert/history event item.
     * @param {AlertHistoryEventParams} params - Parameters to build event on UI.
     */
    constructor(historyEventItem, params) {
        this.historyEventItem = historyEventItem;
        this.eventParams = params;
    }

    /**
     * Builds the description for the event based on the description configuration.
     * @param {EventDescriptionConfig} descriptionConfig - The description configuration.
     * @returns {string|null} The built description or null if descriptionConfig is falsy.
     */
    buildDescription(descriptionConfig) {
        if (!descriptionConfig) {
            return null;
        }
        const descriptionTemplate = t(descriptionConfig.translationKey);
        const replacer = (match, placeholder) =>
            getPlaceholderValue(placeholder, this.historyEventItem, descriptionConfig.placeholdersPaths);

        return descriptionTemplate.replace(/<([^>]+)>/g, replacer);
    }
    /**
     * Builds the UI data object for the history event.
     * @returns {UiData} The UI data object.
     */
    buildUiData() {
        const timestamp = this.historyEventItem.timestamp_ms;
        const { descriptionConfig, ...rest } = this.eventParams || {};

        return {
            description: this.buildDescription(descriptionConfig),
            time: this.getUiTime(timestamp),
            ...rest,
        };
    }

    /**
     * Formats the timestamp into a human-readable UI time format.
     * @param {string} timestamp - The timestamp in milliseconds.
     * @returns {string} The formatted UI time.
     */
    getUiTime(timestamp) {
        return moment(Number(timestamp)).format('hh:mm a');
    }

    /**
     * Builds the UI history event object containing cloud data and UI data.
     * @returns {HistoryEvent} Full History Event Data.
     */
    buildUiHistoryEvent() {
        return {
            cloudData: this.historyEventItem,
            uiData: this.buildUiData(),
        };
    }
}
