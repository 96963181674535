import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from 'lib/@mui';
import { Edit } from 'lib/@mui/Icons';
import classNames from 'classnames';

import { setLocalStorage } from '../../helpers/helpersMeshBot';

import './style.scss';
import withAbilityGoEditMeshbot from '../../containers/Ezlo/EzloMeshbots/HOCs/withAbilityGoEditMeshbot';
import { PRIMARY } from '../../constants/MeshbotConstant';

const MeshbotEditRule = ({ renderedCellValue: params, onClick }) => {
    // TODO: refactoring needed, no localstorage here!!!
    const handleEdit = () => {
        setLocalStorage('createType', params.type);
        onClick(params);
    };

    return (
        <div className="meshbot-edit__wrapper">
            <IconButton
                color={PRIMARY}
                onClick={handleEdit}
                disabled={params.isDisabled}
                className={classNames({ disabled: params.isDisabled })}
            >
                <Edit color={PRIMARY} />
            </IconButton>
        </div>
    );
};

export default withAbilityGoEditMeshbot(MeshbotEditRule);

MeshbotEditRule.propTypes = {
    renderedCellValue: PropTypes.object,
};
