import './style.scss';
import React from 'react';
import classNames from 'classnames';
import { CheckboxDefaultIcon, CheckboxNewIcon } from '../../assets/icons';

const Checkbox = ({ className, style, checked, index, handleChangeTypes }) => {
    return (
        <div
            className={classNames('checkbox', className, {})}
            style={style}
            onChange={(event) => handleChangeTypes(event, index)}
        >
            {checked ? <CheckboxNewIcon /> : <CheckboxDefaultIcon />}
        </div>
    );
};

export default Checkbox;
