import { createSlice } from '@reduxjs/toolkit';
import {
    MIGRATION_FORM_PAGE,
    MIGRATION,
    MIGRATION_PROCESS_PAGE,
    MIGRATION_ERROR_PAGE,
    MIGRATION_SUCCESS_PAGE,
    FIELDS,
} from '../constants';

/**
 * Initial state for the migration feature slice.
 *
 * @constant
 * @type {Object}
 * @property {Object} migrationPage - State object for the migration page.
 * @property {string} migrationPage.view - Current view of the migration page.
 * @property {Object} migrationForm - State object for the migration form.
 * @property {File|null} migrationForm.file - Selected file for migration.
 * @property {Object|null} migrationForm.choseHub - Selected hub for migration.
 */
const initialState = {
    migrationPage: { view: MIGRATION_FORM_PAGE },
    migrationForm: {
        [FIELDS.FILE]: null,
        [FIELDS.CHOSE_HUB]: null,
    },
};

/**
 * Redux toolkit slice for managing the migration feature state.
 *
 * @constant
 * @type {Object}
 * @property {string} name - Name of the slice.
 * @property {Object} initialState - Initial state of the slice.
 * @property {Object} reducers - Redux reducers for the slice.
 */
const migration = createSlice({
    name: MIGRATION,
    initialState,
    reducers: {
        /**
         * Reducer function for setting the migration error page.
         *
         * @function
         * @name setMigrationErrorPage
         *
         * @param {Object} state - The current state of the reducer.
         * @param {Object} action - The action object dispatched with a specific payload.
         * @returns {void}
         */
        setMigrationErrorPage: (state, action) => {
            state.migrationPage = { view: MIGRATION_ERROR_PAGE, ...action.payload };
        },
        /**
         * Reducer function for setting the migration form page.
         *
         * @function
         * @name setMigrationFormPage
         *
         * @param {Object} state - The current state of the reducer.
         * @returns {void}
         */
        setMigrationFormPage: (state) => {
            state.migrationPage = { view: MIGRATION_FORM_PAGE };
        },
        /**
         * Reducer function for setting the migration success page and resetting the migration form.
         *
         * @function
         * @name setMigrationSuccessPage
         *
         * @param {Object} state - The current state of the reducer.
         * @returns {void}
         */
        setMigrationSuccessPage: (state) => {
            state.migrationPage = { view: MIGRATION_SUCCESS_PAGE };
            state.migrationForm = initialState.migrationForm;
        },
        /**
         * Reducer function for setting the migration process page.
         *
         * @function
         * @name setMigrationProcessPage
         *
         * @param {Object} state - The current state of the reducer.
         * @param {Object} action - The action object dispatched with a specific payload.
         * @returns {void}
         */
        setMigrationProcessPage: (state, action) => {
            state.migrationPage = { view: MIGRATION_PROCESS_PAGE, ...action.payload };
        },
        /**
         * Reducer function for updating the migration form.
         *
         * @function
         * @name updateMigrationForm
         *
         * @param {Object} state - The current state of the reducer.
         * @param {Object} action - The action object dispatched with a specific payload.
         * @returns {void}
         */
        updateMigrationForm: (state, action) => {
            state.migrationForm = { ...state.migrationForm, ...action.payload };
        },
        /**
         * Reducer function for resetting the state to its initial values.
         *
         * @function
         * @name reset
         *
         * @returns {Object} - initial migration state
         */
        reset: () => initialState,
    },
});

/**
 * Action creators for the migration slice.
 *
 * @constant
 * @type {Object}
 * @property {Function} setMigrationErrorPage - Action creator for setting the migration error page.
 * @property {Function} setMigrationProcessPage - Action creator for setting the migration process page.
 * @property {Function} setMigrationSuccessPage - Action creator for setting the migration success page.
 */
export const {
    setMigrationErrorPage,
    setMigrationProcessPage,
    setMigrationSuccessPage,
    setMigrationFormPage,
    reset,
    updateMigrationForm,
} = migration.actions;
export default migration;
export const migrationReducer = migration.reducer;
