import { backup } from './backup';
import { log } from './log';
import { item } from './item';
import { scenes } from './scenes';

export const hub = {
    backup,
    log,
    item,
    scenes,
};
