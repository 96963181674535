import { isString } from 'lodash';
import { ERROR_TYPES, EzloError } from '../../../utilityService';
import { t } from 'helpers/language';
import { EZLOGIC_TITLE_INVALID_DATA_TYPE } from 'constants/language_tokens';

/**
 * Validates parameters for the login API.
 *
 * @param {string} userName - The username for login.
 * @param {string} hash - The hash for login.
 * @param {string} hashCS - The client-side hash for login.
 * @throws {Error} Throws an error if any of the parameters is not a string.
 */
export const validateParamsForLoginApi = (userName, hash, hashCS) => {
    if (!isString(userName) || !isString(hash) || !isString(hashCS)) {
        throw new EzloError(t(EZLOGIC_TITLE_INVALID_DATA_TYPE), ERROR_TYPES.ACCESS_CLOSED);
    }
};
/**
 * Validates the login API response.
 *
 * @param {object} response - The response object from the login API.
 * @throws {Error} Throws an error if the required fields are not strings.
 */
export const validateLoginApiResponse = (response) => {
    const { Identity, IdentitySignature } = response?.data || {};
    if (!isString(Identity) || !isString(IdentitySignature)) {
        throw new Error(t(EZLOGIC_TITLE_INVALID_DATA_TYPE));
    }
};
/**
 * Validates the response object from the getJwtToken API call.
 *
 * @param {object} response - The response object from the getJwtToken API call.
 * @throws {Error} Throws an error if the response data's "token" property is not a string.
 */
export const validateGetJwtTokenResponse = (response) => {
    if (!isString(response?.data?.token)) {
        throw new Error(t(EZLOGIC_TITLE_INVALID_DATA_TYPE));
    }
};
