import React from 'react';
import { SvgIcon } from 'lib/@mui';

export const PlusIcon = (props) => (
    <SvgIcon {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M10.5 0H13.5V10.5H24V13.5H13.5V24H10.5V13.5H0V10.5H10.5V0Z" />
    </SvgIcon>
);

export default PlusIcon;
