import { PDM_REQUESTED_HIDDEN_CLOUD_CAPABILITIES } from '../../EzloMeshbot/constants';

/**
 * Get rid of hidden capability, returns a list of open capability
 * @param { Array } capabilities abstract device capabilities list
 * @returns { Array } Array of open capability
 * @example
 * prepareDevices(state.ezlo.data)
 */
export const filterPdmRequestedCloudCapabilities = (capabilities) => {
    return capabilities.filter((capability) => {
        const capabilityName = capability?.capability_name || capability;

        return !PDM_REQUESTED_HIDDEN_CLOUD_CAPABILITIES.includes(capabilityName);
    });
};
