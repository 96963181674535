import hash from '../constants/uniqueHash';
import { toast } from '../components/Toast';
import { getErrorUntilAcknowledgedPayload } from '../containers/EzloToast/utils';
import { updateToast, updateToasts, deleteToast, clearToasts } from '../reducers/toasts';

const toastsActions = {
    showToast: (payload) => (dispatch) => {
        if (payload?.isSave) {
            dispatch(toastsActions.setToast(payload));
        } else {
            dispatch(toastsActions.showLibraryToast(payload.message, payload.options));
        }
    },
    setToast: (payload) => (dispatch) => {
        const toast = {
            [hash()]: {
                message: payload.message,
                options: payload.options,
                isShow: false,
            },
        };
        dispatch(updateToasts({ toast }));
    },
    updateToast: (toastId, toast) => (dispatch) => {
        dispatch(updateToast({ toastId, toast }));
    },
    showLibraryToast: (content, options) => () => {
        toast(content, options);
    },
    deleteToast: (toastId) => (dispatch) => {
        dispatch(deleteToast({ toastId }));
    },
    clearToasts: () => (dispatch) => {
        dispatch(clearToasts());
    },
    displayToasts: () => (dispatch, getState) => {
        const { toasts } = getState().toasts;
        for (const toastId in toasts) {
            const toast = toasts[toastId];
            if (!toast.isShow) {
                const onClose = () => dispatch(toastsActions.deleteToast(toastId));
                const payload = getErrorUntilAcknowledgedPayload(toast, onClose, toastId);

                dispatch(toastsActions.showToast(payload));
                dispatch(toastsActions.updateToast(toastId, { ...toast, isShow: true }));
            }
        }
    },
};

export default toastsActions;
