import React from 'react';

export const LeftMenuVirtualDeviceManagerIcon = (props) => (
    <svg {...props} width="26" height="30" viewBox="0 0 26 30" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.0001 0L25.9905 7.5V22.5L13.0001 30L0.00976562 22.5V7.5L13.0001 0ZM13 3.5L22.9593 9.25V20.75L13 26.5L3.04071 20.75V9.25L13 3.5ZM6 9L11.4361 23L14.6538 22.9899L20 9H16.3326L13.7744 16.1418C13.6445 16.5369 13.5146 16.932 13.3947 17.3271C13.3605 17.4589 13.2887 17.686 13.1858 18.0113C13.1386 18.1608 13.0848 18.3311 13.025 18.5224L12.2855 16.1418L9.74732 9H6Z"
        />
    </svg>
);

export default LeftMenuVirtualDeviceManagerIcon;
