import produce from 'immer';
import at from '../constants/ActionTypes/Notification';
import { LOGOUT } from '../constants/ActionTypes';
import { LOCATION_CHANGE } from 'constants/App';

const INITIAL_STATE = {
    channels: [],
    users: [],
    uuids: [],
    errors: [],
    dashboards: [],
    notificationType: '',
    notificationSelectedCapability: '',
    notificationCategories: [],
    notificationSettingsCategories: [],
    notificationTemplates: [],
    isLoading: false,
};
export default produce((draft, action) => {
    switch (action.type) {
        case at.GET_USERS_LIST.success:
            draft.users = action.data;
            break;
        case at.GET_UUID_LIST.success:
            draft.uuids = action.data;
            break;
        case at.GET_CHANNELS_LIST.success:
            draft.channels = action.data;
            break;
        case at.SET_NOTIFICATION_TEMPLATE.pending:
            draft.isLoading = true;
            break;
        case at.SET_NOTIFICATION_TEMPLATE.rejected:
            draft.isLoading = false;
            break;
        case at.SET_NOTIFICATION_TEMPLATE.success:
            draft.isLoading = false;
            break;
        case at.GET_NOTIFICATION_TEMPLATES.pending:
            draft.isLoading = true;
            break;
        case at.GET_NOTIFICATION_TEMPLATES.success:
            draft.notificationTemplates = action.data;
            draft.isLoading = false;
            break;
        case at.GET_NOTIFICATION_TEMPLATES.reject:
            draft.isLoading = false;
            break;
        case at.GET_DASHBOARD_LIST.success:
            draft.dashboards = action.data;
            break;
        case at.UPDATE_NOTIFICATION_TYPE.success:
            draft.notificationType = action.data;
            break;
        case at.GET_NOTIFICATION_CATEGORIES.success:
            draft.notificationCategories = action.data;
            break;
        case at.GET_NOTIFICATION_CATEGORIES_SETTINGS.success:
            draft.notificationSettingsCategories = action.data;
            break;
        case at.SET_SELECTED_NOTIFICATION_CAPABILITY.success:
            draft.notificationSelectedCapability = action.data;
            break;
        case LOCATION_CHANGE:
            draft.notificationSelectedCapability = '';
            break;
        case LOGOUT:
            draft.notificationCategories = [];
            draft.notificationSettingsCategories = [];
            draft.notificationType = '';
            draft.dashboards = [];
            draft.notificationTemplates = [];
            draft.channels = [];
            draft.uuids = [];
            draft.users = [];
            break;
        default:
            return draft;
    }
}, INITIAL_STATE);
