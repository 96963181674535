import {
    NUCAL_INTEGRATIONS_ACCOUNTS_TITLE,
    EZLOGIC_TITLE_ACCOUNT,
    NUCAL_INTEGRATIONS_ADD_BTN_TITLE,
    NUCAL_INTEGRATIONS_CANCEL_BTN_TITLE,
    EZLOGIC_BTN_DELETE_ENROLLMENT_TITLE,
    NUCAL_INTEGRATIONS_ENROLLMENT_OPERATIONS_TITLE,
    NUCAL_INTEGRATIONS_MAIN_HEADING,
    NUCAL_INTEGRATIONS_NAME_FIELD_TITLE,
    NUCAL_INTEGRATIONS_NO_RESULTS_TITLE,
    EZLOGIC_BUTTON_SAVE,
    EZLOGIC_BUTTON_SETTINGS,
} from '../../../constants/language_tokens';

export const PARJECTS_PAGES = {
    NEW: 'new',
};

export const ABSTRACT_SOURCES = {
    CONTROLLER: 'controller',
    INTEGRATION: 'integration',
    VIRTUAL_DEVICE: 'virtual_device',
};

/**
 * Delay to allow cloud creates an virtual device
 * In millis
 * */
export const ENROLLING_DELAY = 12000;

export const ACCOUNT_INFO_VALUE = {
    OBFUSCATED_TOKEN: 'obfuscated_token',
    EMAIL: 'email',
};

export const INTEGRATION_BUTTON_TEXT = {
    ADD: NUCAL_INTEGRATIONS_ADD_BTN_TITLE,
    SETTINGS: EZLOGIC_BUTTON_SETTINGS,
    DELETE: EZLOGIC_BTN_DELETE_ENROLLMENT_TITLE,
    CANCEL: NUCAL_INTEGRATIONS_CANCEL_BTN_TITLE,
    SAVE: EZLOGIC_BUTTON_SAVE,
};

export const NUCAL_INTEGRATION_CONTENT = {
    TITLE: NUCAL_INTEGRATIONS_MAIN_HEADING,
    DIALOG: {
        SUBTEXT: NUCAL_INTEGRATIONS_ENROLLMENT_OPERATIONS_TITLE,
        BREAKPOINT_FULL_SCREEN: 'xs',
        MAX_WIDTH: 'sm',
    },
    NAME_FIELD: {
        LABEL: NUCAL_INTEGRATIONS_NAME_FIELD_TITLE,
    },
    ABSTRACT_AMOUNT: {
        ONE: 1,
    },
    ACCOUNT: EZLOGIC_TITLE_ACCOUNT,
    ACCOUNTS: NUCAL_INTEGRATIONS_ACCOUNTS_TITLE,
    NO_RESULTS: NUCAL_INTEGRATIONS_NO_RESULTS_TITLE,
};
