import React from 'react';

const IcActionsThermostatModeCool = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
            <path
                d="M26.4903 23.6207C27.0927 22.8238 27.5946 21.9272 27.9961 21.0307L25.2857 19.5364L28.7992 17.9425C28.8996 17.2452 29 16.5479 29 15.8506C29 15.1533 28.8996 14.4559 28.7992 13.7586L25.2857 12.1648L27.9961 10.6705C27.5946 9.77395 27.0927 8.87739 26.4903 8.08046L23.8803 9.57471L24.2819 5.68966C23.1776 4.7931 21.973 4.09579 20.668 3.5977L17.556 5.88889V3.09962C17.0541 3 16.5521 3 16.0502 3C15.5483 3 15.0463 3 14.5444 3.09962V6.18774L11.4324 3.89655C10.1274 4.39464 8.92278 5.09195 7.81853 5.88889L8.22008 9.77395L5.61004 8.27969C5.00772 9.07663 4.50579 9.87356 4.10425 10.7701L6.81467 12.2644L3.30116 13.8582C3.10039 14.5556 3 15.2529 3 15.9502C3 16.6475 3.10039 17.3448 3.20077 18.0421L6.71429 19.636L4.00386 21.1303C4.40541 22.0268 4.90734 22.9234 5.50965 23.7203L8.11969 22.2261L7.71815 26.1111C8.82239 27.0077 10.027 27.705 11.332 28.2031L14.444 25.9119V29C15.0463 28.9004 15.5483 28.9004 16.0502 28.9004C16.5521 28.9004 17.0541 28.9004 17.556 28.8008V25.7126L20.668 28.0038C21.973 27.5057 23.1776 26.8084 24.2819 25.9119L23.8803 22.0268L26.4903 23.6207ZM5.9112 15.9502L9.92664 14.1571L12.9382 15.9502L9.92664 17.7433L5.9112 15.9502ZM14.5444 22.0268L11.0309 24.6169L11.5328 20.2337L14.5444 18.4406V22.0268ZM14.5444 13.3602L11.5328 11.567L11.0309 7.28352L14.5444 9.87356V13.3602ZM17.556 9.87356L21.0695 7.28352L20.5676 11.6667L17.556 13.4598V9.87356ZM17.556 22.0268V18.5402L20.5676 20.3333L21.0695 24.7165L17.556 22.0268ZM19.0618 15.9502L22.0734 14.1571L26.0888 15.9502L22.0734 17.7433L19.0618 15.9502Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcActionsThermostatModeCool;
