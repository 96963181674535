import React from 'react';

const IcDeviceSensorGasLeak = (props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="#1C1E21" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.5997 9.50039L11.9997 5.10039L16.3997 9.50039C17.9997 11.1004 18.4997 13.5004 17.9997 15.5004C20.2997 12.6004 19.9997 8.30039 17.3997 5.70039L11.9997 0.400391L6.69969 5.70039C3.99969 8.40039 3.7997 12.6004 6.0997 15.6004C5.4997 13.5004 5.9997 11.2004 7.5997 9.50039ZM3.1 14.5H0V17.5H5.2C4.3 16.6 3.6 15.6 3.1 14.5ZM24 20.5H0V23.5H24V20.5ZM18.7998 17.5C19.6998 16.6 20.3998 15.6 20.8998 14.5H23.9998V17.5H18.7998ZM11.9998 8.40039L14.6998 11.1004C16.0998 12.6004 16.0998 15.0004 14.5998 16.4004C13.1998 17.9004 10.7998 17.9004 9.2998 16.4004C7.7998 15.0004 7.7998 12.6004 9.2998 11.1004L11.9998 8.40039Z"
            />
        </svg>
    );
};

export default IcDeviceSensorGasLeak;
