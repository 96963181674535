import at from '../constants/ActionTypes/geofences';
import produce from 'immer';

const initialState = {
    geofences: [],
};

export default produce((draft, action) => {
    switch (action.type) {
        case at.UPDATE_GEOFENCES: {
            draft.geofences = action.geofences;
            break;
        }

        default:
            break;
    }
}, initialState);
