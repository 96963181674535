import React from 'react';

export const IcActionsMail = (props) => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M23.0786 11.5714H12.9214L18 16.65L23.0786 11.5714Z" fill="#1C1E21" />
            <path
                d="M16.65 18L10.2857 11.6357V22.5C10.2857 23.5929 11.1214 24.4286 12.2143 24.4286H23.7857C24.8786 24.4286 25.7143 23.5929 25.7143 22.5V11.6357L19.35 18C18.5786 18.7714 17.4214 18.7714 16.65 18Z"
                fill="#1C1E21"
            />
        </svg>
    );
};
