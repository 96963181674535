import { t } from './language';
import * as regexp from '../constants/Regexp';

export const reduxFormValidation = (values) => {
    const errors = {};

    // Name Validation
    if (!values.name) {
        errors.name = t`ezlo.error.message.name_should_not_empty`;
    }

    // Email Validation
    if (!values.email) {
        errors.email = t`ezlo.error.message.provide_email`;
    } else {
        if (!regexp.EMAIL_REGEXP.test(values.email)) {
            errors.email = t`ezlo.error.message.email_should_be_valid`;
        }

        if (
            values.email.indexOf('@') > 64 ||
            values.email.length - values.email.indexOf('@') > 253 //Probably 252 but we add '@' symbol to compare
        ) {
            errors.email = t`ezlo.error.message.email_should_be_valid`;
        }
    }

    // Password Validation
    if (!values.pass || values.pass.length < 8) {
        errors.pass = t('ezlo.error.message.password_at_least') + ' 8 ' + t('ezlo.error.message.symbols_length');
    } else {
        if (values.pass.length < 8) {
            errors.pass = t('ezlo.error.message.password_at_least') + ' 8 ' + t('ezlo.error.message.symbols_length');
        }

        if (!regexp.PASSWORD_REGEXP.test(values.pass)) {
            errors.pass = t('ezlo.error.message.password_contain_specific_letters');
        }

        if (values.pass.length > 100) {
            errors.pass = t('ezlo.error.message.password_maximum') + ' 100 ' + t('ezlo.error.message.symbols_length');
        }
    }

    // Hub name Validation
    if (!values.hubName) {
        errors.hubName = true;
    }

    return errors;
};

/**
 * Returns true in case if controller serial number is supported or false otherwise
 * @param {string|number} serial - serial number
 * @returns {boolean} is supported result
 * */
export const isControllerSupportedBySerial = (serial) => {
    if (!serial) {
        return false;
    }
    const type = typeof serial;
    if (type !== 'number' && type !== 'string') {
        return false;
    }
    const preparedSerial = typeof serial === 'number' ? serial.toString() : serial;

    return regexp.SUPPORTED_CONTROLLER_SERIAL_NUMBER_REGEXP.test(preparedSerial);
};
