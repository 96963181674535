import './style.scss';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Dialog as MuiDialog } from 'lib/@mui';

// TODO: combine with Dialog, Modal and use single component if possible
const Dialog = (props) => {
    const { children, className, ...rest } = props;

    return (
        <MuiDialog {...rest} className={classNames('dialog', { [className]: className })}>
            {children}
        </MuiDialog>
    );
};

Dialog.propTypes = {
    open: PropTypes.bool.isRequired,
    scroll: PropTypes.oneOf(['paper', 'body']),
};

Dialog.defaultProps = {
    open: false,
    scroll: 'paper',
};

export default Dialog;
