import moment from 'moment';
import { getDefaultHourAndMinute } from '../../constants/CloudMeshbotConstant';

export const SCHEDULE_DATE_TYPES = {
    INTERVAL: 'interval',
    DAILY: 'daily',
    WEEKLY: 'weekly',
    MONTHLY: 'monthly',
    ONCE: 'once',
    SPECIAL_TIME_OF_THE_DAY: 'specialTimeOfTheDay',
};

export const getIntervalTemplate = () => {
    const { INTERVAL } = SCHEDULE_DATE_TYPES;

    return {
        name: INTERVAL,
        measurement: 'minute',
        value: 1,
    };
};

export const getDailyTemplate = () => {
    const { DAILY } = SCHEDULE_DATE_TYPES;

    return {
        name: DAILY,
        times: getDefaultHourAndMinute(),
    };
};

export const getWeeklyTemplate = () => {
    const { WEEKLY } = SCHEDULE_DATE_TYPES;

    return {
        name: WEEKLY,
        days: [],
        time: getDefaultHourAndMinute(),
    };
};

export const getMonthlyTemplate = () => {
    const { MONTHLY } = SCHEDULE_DATE_TYPES;

    return {
        name: MONTHLY,
        days: [],
        time: getDefaultHourAndMinute(),
    };
};

export const getOnceTemplate = () => {
    const { ONCE } = SCHEDULE_DATE_TYPES;

    return {
        name: ONCE,
        year: Number(moment().format('YYYY')),
        month: Number(moment().format('MM')),
        day: Number(moment().format('DD')),
        hour: 12,
        minute: 0,
    };
};

export const getSpecialTimeOfTheDay = () => {
    return {
        name: 'sunrise',
        position: {
            latitude: '....',
            longitude: '....',
        },
        offset: 1,
        time: getDefaultHourAndMinute(),
    };
};
