export const FETCH_ITEM_GROUPS_LIST = {
    success: 'FETCH_ITEM_GROUPS_LIST/success',
    rejected: 'FETCH_ITEM_GROUPS_LIST/rejected',
};

export const SET_ITEM_GROUP = {
    success: 'SET_ITEM_GROUP/success',
    rejected: 'SET_ITEM_GROUP/rejected',
};

export const SET_ITEM_GROUP_ID = {
    success: 'SET_ITEM_GROUP_ID/success',
    rejected: 'SET_ITEM_GROUP_ID/rejected',
};
