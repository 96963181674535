import React from 'react';

const IcEthernetPort = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
            <path
                d="M24.05 10V7H21.05V4H11.05V7H8.05005V10H4.05005V25C4.05005 26.7 5.35005 28 7.05005 28H8.05005V22H11.05V28H14.55V22H17.55V28H21.05V22H24.05V28H25.05C26.75 28 28.05 26.7 28.05 25V10H24.05Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcEthernetPort;
