import { isArray, isEmpty, isObject } from 'lodash';
import { HistoryEventService } from './HistoryEventService';
import { isJsonToParse } from '../utilityService';
import { getEventParams } from './utils';
import { bugsnagNotify } from 'containers/ErrorBoundary/utils';
import { UNKNOWN_EVENTS } from './constants';
/**
 * Represents a history event.
 *
 * @typedef {Object} ComHistoryEventFromCloud
 * @property {string} id - The ID of the history event.
 * @property {string} comm_format - The communication format of the history event.
 * @property {string} comm_type - The communication type of the history event.
 * @property {string} data - The data associated with the history event.
 * @property {string} location_uuid - The location UUID of the history event.
 * @property {string} source - The source of the history event.
 * @property {string} source_type - The source type of the history event.
 * @property {string} target - The target of the history event.
 * @property {string} target_type - The target type of the history event.
 * @property {string} timestamp_ms - The timestamp of the history event in milliseconds.
 */

/**
 * Data structure representing a range of history events.
 *
 * @typedef {Object} HistoryEventsRange
 * @property {number} start - The starting ID of the range.
 * @property {number} end - The ending ID of the range.
 * @property {Array<ComHistoryEventFromCloud>} list - List of history events within the range.
 */

/**
 * Notifies about unknown events using bugsnag.
 *
 * @param {Array<ComHistoryEvent>} unknownEvents - List of unknown events.
 */
const notifyOfUnknownEvents = (unknownEvents) => {
    const error = new Error('Log of unknown events on Alert/History page');
    bugsnagNotify(error, { unknownEvents, type: UNKNOWN_EVENTS });
};

/**
 * Gets the history event data based on parameters and history event item.
 *
 * @param {AlertHistoryEventParams} params - Parameters for building the event on UI.
 * @param {ComHistoryEvent} historyEventItem - History event item.
 * @returns {HistoryEvent|null} History event data.
 */
const getHistoryEventData = (params, historyEventItem) => {
    if (!isObject(params)) {
        return null;
    }

    const historyEventService = new HistoryEventService(historyEventItem, params);

    return historyEventService.buildUiHistoryEvent();
};

/**
 * Builds the history event data based on the history event item.
 *
 * @param {ComHistoryEventFromCloud} historyEventItem - History event item.
 * @returns {HistoryEvent|null} History event data for ui.
 */
const buildHistoryEventData = (historyEventItem) => {
    if (isJsonToParse(historyEventItem?.data)) {
        historyEventItem.data = JSON.parse(historyEventItem.data);
    }

    const params = getEventParams(historyEventItem);

    return getHistoryEventData(params, historyEventItem);
};

/**
 * Builds a list of history events from the provided range of history events.
 *
 * @param {Array} historyEventsRangeFromCloud - Range of history events fetched from the cloud.
 * @returns {Array} List of history events.
 */
const buildListOfHistoryEvents = (historyEventsRangeFromCloud) => {
    const list = [];

    if (!isArray(historyEventsRangeFromCloud)) {
        return list;
    }

    const unknownEvents = [];
    historyEventsRangeFromCloud.forEach((rangeItem) => {
        if (!isArray(rangeItem.list)) {
            return;
        }

        rangeItem.list.forEach((listItem) => {
            const uiData = buildHistoryEventData(listItem);

            if (!isObject(uiData)) {
                unknownEvents.push(listItem);

                return;
            }

            list.push(uiData);
        });
    });
    if (!isEmpty(unknownEvents)) {
        notifyOfUnknownEvents(unknownEvents);
    }

    return list;
};

export { buildListOfHistoryEvents };
