import React, { createContext, useContext } from 'react';
import useDialogState from '../../../../../hooks/useDialogState/useDialogState';

export const MeshBotsTableContext = createContext({});

const useMeshBotsTableContext = () => {
    return useContext(MeshBotsTableContext);
};

export const MeshBotsTableContextProvider = ({ value = {}, children }) => {
    const { open: labelsPanelOpen, toggleOpenDialog: toggleOpenLabelsPanel } = useDialogState();

    return (
        <MeshBotsTableContext.Provider
            value={{
                ...value,
                labelsPanelOpen,
                toggleOpenLabelsPanel,
            }}
        >
            {children}
        </MeshBotsTableContext.Provider>
    );
};

export default useMeshBotsTableContext;
