import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MeshBotAction } from 'actions';
import { SCENE_RUN_STATUSES } from 'constants/MeshbotConstant';
import { getActiveRunSceneStatusById } from './utils';
import { MESHBOT_TYPES } from 'containers/Ezlo/EzloMeshbots/constants';
import MeshbotRunCell from './MeshbotRunCell';
import LocalMeshbotRun from './LocalMeshbotRun';

const MeshbotRunWrapper = ({ renderedCellValue: { id, serial, type, isDisabled } }) => {
    const dispatch = useDispatch();
    const status = useSelector(({ trackRunScenes }) => getActiveRunSceneStatusById(trackRunScenes, id, serial));

    const runScene = useCallback(() => {
        if (!status) {
            dispatch(MeshBotAction.runScene({ id, serial, type }));
        }
    }, [serial, id, type, status]);

    if (type === MESHBOT_TYPES.LOCAL) {
        return <LocalMeshbotRun {...{ id, serial, isDisabled, status, runScene }} />;
    }

    return (
        <MeshbotRunCell
            status={status}
            isButton={!status}
            isDisabled={isDisabled || status === SCENE_RUN_STATUSES.STARTED}
            handleButtonClick={runScene}
        />
    );
};

export default MeshbotRunWrapper;
