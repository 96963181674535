import './style.scss';
import React from 'react';
import classNames from 'classnames';

const Toggle = ({ toggled, disabled, onToggle, className, ...otherProps }) => {
    const handleClick = (e) => {
        e.stopPropagation();

        !disabled && onToggle(!toggled);
    };

    return (
        <div
            {...otherProps}
            className={classNames('toggle', className, {
                disabled: disabled,
                toggled: toggled,
            })}
            onClick={handleClick}
        >
            <div className="toggle-container">
                <div className="toggle-handle" />
            </div>
        </div>
    );
};

Toggle.defaultProps = {
    toggled: false,
    disabled: false,
    onToggle: function () {},
};

export default Toggle;
