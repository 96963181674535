import React from 'react';

export const IcWarningVisualsNotResponding = () => {
    return (
        <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M100.002 148.002C126.511 148.002 148.002 126.511 148.002 100.002C148.002 73.492 126.511 52.0017 100.002 52.0017C73.492 52.0017 52.0017 73.492 52.0017 100.002C52.0017 126.511 73.492 148.002 100.002 148.002Z"
                stroke="#FE5C15"
                strokeWidth="16"
                strokeMiterlimit="10"
            />
            <path d="M150.913 49.09L49.09 150.913" stroke="#FE5C15" strokeWidth="16" strokeMiterlimit="10" />
            <path
                d="M133.378 77.9388C136.653 82.8706 138.772 88.4512 139.601 94.3433L152.771 81.1736C150.902 75.9226 148.24 70.9724 144.854 66.4636L133.378 77.9388Z"
                fill="#E82E10"
            />
            <path
                d="M94.3434 139.601C88.4514 138.772 82.8709 136.653 77.9392 133.378L66.464 144.853C70.9727 148.239 75.9228 150.901 81.1738 152.771L94.3434 139.601Z"
                fill="#E82E10"
            />
        </svg>
    );
};
