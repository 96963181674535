import React from 'react';

const IcDeviceSensorFreeze = (props) => {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="#1C1E21" {...props}>
            <path d="M23.4903 20.6207C24.0927 19.8238 24.5946 18.9272 24.9961 18.0307L22.2857 16.5364L25.7992 14.9425C25.8996 14.2452 26 13.5479 26 12.8506C26 12.1533 25.8996 11.4559 25.7992 10.7586L22.2857 9.16475L24.9961 7.6705C24.5946 6.77395 24.0927 5.87739 23.4903 5.08046L20.8803 6.57471L21.2819 2.68966C20.1776 1.7931 18.973 1.09579 17.668 0.597701L14.556 2.88889V0.0996169C14.0541 0 13.5521 0 13.0502 0C12.5483 0 12.0463 0 11.5444 0.0996169V3.18774L8.43243 0.896552C7.12741 1.39464 5.92278 2.09195 4.81853 2.88889L5.22008 6.77395L2.61004 5.27969C2.00772 6.07663 1.50579 6.87356 1.10425 7.77011L3.81467 9.26437L0.301158 10.8582C0.100386 11.5556 0 12.2529 0 12.9502C0 13.6475 0.100386 14.3448 0.200772 15.0421L3.71429 16.636L1.00386 18.1303C1.40541 19.0268 1.90734 19.9234 2.50965 20.7203L5.11969 19.2261L4.71815 23.1111C5.82239 24.0077 7.02703 24.705 8.33205 25.2031L11.444 22.9119V26C12.0463 25.9004 12.5483 25.9004 13.0502 25.9004C13.5521 25.9004 14.0541 25.9004 14.556 25.8008V22.7126L17.668 25.0038C18.973 24.5057 20.1776 23.8084 21.2819 22.9119L20.8803 19.0268L23.4903 20.6207ZM2.9112 12.9502L6.92664 11.1571L9.93822 12.9502L6.92664 14.7433L2.9112 12.9502ZM11.5444 19.0268L8.03089 21.6169L8.53282 17.2337L11.5444 15.4406V19.0268ZM11.5444 10.3602L8.53282 8.56705L8.03089 4.28352L11.5444 6.87356V10.3602ZM14.556 6.87356L18.0695 4.28352L17.5676 8.66667L14.556 10.4598V6.87356ZM14.556 19.0268V15.5402L17.5676 17.3333L18.0695 21.7165L14.556 19.0268ZM16.0618 12.9502L19.0734 11.1571L23.0888 12.9502L19.0734 14.7433L16.0618 12.9502Z" />
        </svg>
    );
};

export default IcDeviceSensorFreeze;
