import React, { useState } from 'react';

import LabelMenu from './LabelMenu';
import LabelDialogsBySelectedMenuItem from '../LabelDialogsBySelectedMenuItem';

const LabelMenuContainer = ({ anchorEl, closeMenu, menuOpen, labelUuid, labelData }) => {
    const [selectedLabelMenuItem, setSelectedLabelMenuItem] = useState(null);

    const onClickMenuItem = (e, selectedLabelMenuItem) => {
        e.stopPropagation();
        setSelectedLabelMenuItem(selectedLabelMenuItem);
        closeMenu();
    };

    return (
        <>
            <LabelMenu
                anchorEl={anchorEl}
                onClickMenuItem={onClickMenuItem}
                closeMenu={closeMenu}
                menuOpen={menuOpen}
            />
            <LabelDialogsBySelectedMenuItem
                labelUuid={labelUuid}
                labelData={labelData}
                setSelectedLabelMenuItem={setSelectedLabelMenuItem}
                selectedLabelMenuItem={selectedLabelMenuItem}
            />
        </>
    );
};

export default LabelMenuContainer;
