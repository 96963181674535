import React from 'react';
import styles from './pluginVersionCell.module.scss';
import { Upgrade } from '../../../../../../assets/icons';

const PluginVersionCell = (props) => {
    const { row, name } = props.params || {};
    const { version } = row.original?.config || {};
    const { onClick } = props;
    const shouldUpdate = row.original?.shouldUpdate;
    const handleClick = () => {
        onClick(row.original);
    };

    return (
        <div className={styles.versionCell} title={name}>
            <span>{version}</span>
            {shouldUpdate && <Upgrade className={styles.upgradeBtn} onClick={handleClick} />}
            {shouldUpdate && (
                <span className={styles.tooltipTextUpgrade}>
                    New version {row?.original?.versionForUpdate} available! <br />
                    Upgrade now
                </span>
            )}
        </div>
    );
};

export default PluginVersionCell;
