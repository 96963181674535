import React from 'react';

const IcActionsThermostatModeFanMediumOnly = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
            <path d="M16 2C19.87 2 23 5.13 23 9C23 12.87 19.87 16 16 16V2Z" fill="#1C1E21" />
            <path d="M16 16C16 19.87 19.13 23 23 23C26.87 23 30 19.87 30 16H16Z" fill="#1C1E21" />
            <path d="M16 16C12.13 16 9 19.13 9 23C9 26.87 12.13 30 16 30V16Z" fill="#1C1E21" />
            <path d="M16 16C16 12.13 12.87 9 9 9C5.13 9 2 12.13 2 16H16Z" fill="#1C1E21" />
        </svg>
    );
};

export default IcActionsThermostatModeFanMediumOnly;
