import React from 'react';

const IcPartiallyFinishedStatus = (props) => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" {...props}>
            <circle cx="20" cy="20" r="16.5" stroke="#90A0AF" strokeOpacity="0.25" strokeWidth="3" />
            <path
                d="M16.8657 26C16.2634 26 15.6857 25.7616 15.2586 25.337L11 21.1027L12.8806 19.2329L16.9104 23.1507L27.1194 13L29 14.8699L18.4728 25.337C18.0457 25.7616 17.4679 26 16.8657 26Z"
                fill="currentColor"
            />
            <path
                d="M3.5 20C3.5 28.8366 10.8873 36.5 20 36.5C29.1127 36.5 36.5 28.8366 36.5 20C36.5 11.1634 29.6127 3.5 20.5 3.5"
                stroke="currentColor"
                strokeWidth="3"
                strokeLinecap="round"
            />
        </svg>
    );
};

export default IcPartiallyFinishedStatus;
