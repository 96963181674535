import React from 'react';
import { useTranslate } from '../../features/languages';
import { EZLOGIC_TITLE_LOADING } from '../../constants/language_tokens';
import './style.scss';
import { SpinnerIcon } from '../../assets/icons';
import Navigation from '../Navigation';

const Preloader = () => {
    const { t } = useTranslate();

    return (
        <div className="preloader ezlo-page-wrapper">
            <Navigation />
            <div className="content-wrapper">
                <div className="loader">
                    <SpinnerIcon height="200" width="200" className="loader__icon" />
                    <div className="loader__title">
                        <span className="animated-dots">{t(EZLOGIC_TITLE_LOADING)}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Preloader;
