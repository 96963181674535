import React from 'react';

const IcThermostatIconWithLeaf = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M29 16C29 23.1797 23.1797 29 16 29C8.8203 29 3 23.1797 3 16C3 8.8203 8.8203 3 16 3C20.6276 3 24.6905 5.41798 26.9942 9.05945C21.9312 9.66138 18 14.7613 18 21V23H19V20.9412C23.5836 20.3995 27.2408 16.1713 27.8955 10.7481C28.6056 12.3542 29 14.131 29 16ZM19 14H15.5V21H12.5V14H9V11H12.5H15.5H19V14Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcThermostatIconWithLeaf;
