import * as types from '../constants/ActionTypes';
import _ from 'lodash';

const initialState = {
    error: {
        show: false,
        message: '',
    },
    notification: {
        show: false,
        message: '',
        type: 'success',
    },
    deviceTemplates: {},
    deviceTemplateId: null,
    forgotMessage: '',
    appLoading: false,
    reconnecting: false,
    checkingConnection: false,
    isAboutPopupShowed: false,
    isDevicePageEditMode: false,
    lineLoading: false,
};

export default function (state = initialState, action) {
    const actions = {
        [types.SET_DEVICE_PAGE_EDIT_MODE]: () => {
            const newState = { ...state };

            newState.isDevicePageEditMode = action.isEditMode;

            return newState;
        },
        [types.SHOW_NOTIFICATION]: () => ({
            ...state,
            notification: {
                show: true,
                message: action.message,
                type: action.messageType,
            },
        }),
        [types.HIDE_NOTIFICATION]: () => ({
            ...state,
            notification: {
                show: false,
                message: '',
                type: state.notification.type,
            },
        }),
        [types.SHOW_ERROR]: () => ({
            ...state,
            error: {
                show: true,
                message: action.errorMessage,
                errorTitle: action.errorTitle,
            },
        }),
        [types.HIDE_ERROR]: () => ({
            ...state,
            error: {
                show: false,
                message: '',
            },
        }),
        [types.STORE_DEVICE_TEMPLATE]: () => {
            const newState = { ...state };

            newState.deviceTemplates[action.deviceId] = action.template;

            return newState;
        },
        [types.REMOVE_DEVICE_TEMPLATE]: () => {
            const newState = { ...state };
            const deviceTemplates = {};

            Object.keys(newState.deviceTemplates).forEach((deviceId) => {
                if (deviceId !== action.deviceId) {
                    deviceTemplates[deviceId] = newState.deviceTemplates[deviceId];
                }
            });

            newState.deviceTemplates = deviceTemplates;

            return newState;
        },
        [types.SET_FORGOT_MSG_FOR_LOGIN_PAGE]: () => {
            const newState = { ...state };

            newState.forgotMessage = action.forgotMessage;

            return newState;
        },
        [types.SET_DEVICE_TEMPLATE]: () => ({ ...state, deviceTemplateId: action.templateId }),
        [types.UNSET_DEVICE_TEMPLATE]: () => ({ ...state, deviceTemplateId: null }),
        [types.APP_LOADING]: () => ({ ...state, appLoading: true }),
        [types.APP_LOADED]: () => ({ ...state, appLoading: false }),
        [types.START_RECONNECT]: () => ({ ...state, reconnecting: true }),
        [types.STOP_RECONNECT]: () => ({ ...state, reconnecting: false }),
        [types.START_CHECK_CONNECTION]: () => ({ ...state, checkingConnection: true }),
        [types.STOP_CHECK_CONNECTION]: () => ({ ...state, checkingConnection: false }),
        [types.SHOW_ABOUT_POPUP]: () => ({ ...state, isAboutPopupShowed: true }),
        [types.HIDE_ABOUT_POPUP]: () => ({ ...state, isAboutPopupShowed: false }),
        [types.SHOW_SELECT_UI_POPUP]: () => ({
            ...state,
            isSelectUIPopupShowed: true,
            movedToThisSerial: action.movedToThisSerial,
        }),
        [types.HIDE_SELECT_UI_POPUP]: () => ({ ...state, isSelectUIPopupShowed: false, movedToThisSerial: '' }),
        [types.SET_LINE_LOADING]: () => ({ ...state, lineLoading: action.payload }),
        [types.LOGOUT]: () => initialState,
    };

    return _.isFunction(actions[action.type]) ? actions[action.type]() : state;
}
