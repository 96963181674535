import { axiosCloudConfig } from '../../../../../api/config';
import { apiGetMeshSceneDetails } from '../../../../../api/apiScenes';
import { bugsnagNotifyWrapper } from '../../../../ErrorBoundary/utils';
import { bugsnagNotify } from '../../../../ErrorBoundary/utils';
import { buildAbstractStateGetRequestBody } from '../../../EzloParjectsSettings/utils';
import { API_CLOUD_EZLO_REQUEST_URL } from '../../../../../constants/URLs';
import {
    CAPABILITY_NAME_CUSTOM,
    CUSTOM_ENROLL_INFORMATION,
    CUSTOM_PREFIX,
    CUSTOM_SUBSCRIPTION,
    DETAILS_SOURCE_INTEGRATION,
} from './constants';

const SUCCESS_STATUS = 1;
export const filterAbstractsWithCustomVars = (abstracts) => {
    return (
        abstracts?.length &&
        abstracts
            .filter(({ details }) => details.source === DETAILS_SOURCE_INTEGRATION)
            .filter(({ capabilities }) =>
                capabilities.some(
                    (capability) =>
                        capability.startsWith(CUSTOM_PREFIX) &&
                        capability !== CUSTOM_ENROLL_INFORMATION &&
                        capability !== CUSTOM_SUBSCRIPTION,
                ),
            )
    );
};

export const getAbstractListDetailed = async () => {
    try {
        const response = await apiGetMeshSceneDetails();
        const { data } = response;
        if (data.status === SUCCESS_STATUS) {
            return data.data.abstracts;
        }
    } catch (error) {
        bugsnagNotifyWrapper(error);
    }
};

export const buildArrayWithFilteredIntegrationsUuid = (abstracts) => {
    const filteredArray = abstracts?.map(({ details }) => details.integration_uuid) || [];

    return [...new Set(filteredArray)];
};

export const getVariableValue = (capabilityValues, variableName) => {
    const result = capabilityValues?.find((value) => value[1] === variableName);

    return result ? result[result.length - 1] : [];
};

export const apiGetAbstractStateGet = async (abstractUUid) => {
    try {
        const response = await fetch(API_CLOUD_EZLO_REQUEST_URL(), {
            method: 'post',
            ...axiosCloudConfig(),
            body: JSON.stringify(buildAbstractStateGetRequestBody(abstractUUid)),
        });

        const result = await response.json();

        if (result.status === 0) {
            throw result.data;
        }

        return result.data.abstract_capability_values;
    } catch (error) {
        bugsnagNotify(error);
    }
};

export const extractTypeOfVariable = (capabilities) => {
    const customCapability = Object.values(capabilities).find(
        ({ capability_name }) => capability_name === CAPABILITY_NAME_CUSTOM,
    );
    const typeOfValue = customCapability?.definition.variables.value.type;

    return typeOfValue;
};

export const checkIfSubscriptionItemExist = (data) => {
    const { ezlo, selectedVariable, selectedAbstract } = data;
    const { serial } = ezlo;
    const devicesList = ezlo.data[serial].devices;
    const itemsList = ezlo.data[serial].items;

    const foundDevice = devicesList.find(({ name }) => name === selectedAbstract.uuid);
    const foundItem = itemsList?.find(
        ({ deviceId, name }) => deviceId === foundDevice?._id && name.split('.')[0] === selectedVariable,
    );

    return foundItem ? { _id: foundItem._id } : {};
};

export const extractActiveIntegrations = (integrations, filteredIntegrations) => {
    return integrations?.filter(({ uuid }) => filteredIntegrations.includes(uuid));
};

export const extractInfoAboutCurrentIntegrations = (activeIntegrationsList, selectedIntegrationId) => {
    return activeIntegrationsList?.find(({ uuid }) => uuid === selectedIntegrationId);
};
/**
 * Function that collects only required fields for cloud variable in trigger
 * @param {Object} cloudVariableTrigger - trigger object of cloud variable in local meshbot
 * @returns {Object} returned trigger object with only required fields
 */
export const collectCloudVariableRequiredFields = (cloudVariableTrigger) => {
    const cloudVariableTriggerCopy = { ...cloudVariableTrigger };
    delete cloudVariableTriggerCopy.abstractStateGetResult;

    return cloudVariableTriggerCopy;
};

/**
 * Function that collects updated data in the correct format to update this data(for the trigger) in the store
 * @param {Object} variableData - Selected variable data(cloudVariableName, abstract, integrationUuid)
 * @param {Object} triggerIds - trigger ids
 * @returns {Object} - Object with updated data
 * @example {
 *     ruleId: 'hghjb8652255b',
 *     idGroup: 'fhbh56256gho',
 *     selectedIntegrationId: 'gj7566jhgv',
 *     selectedAbstract: {
 *        uuid: 'd98f149f-3841-4af6-9602-a66a9c19c70c',
 *        name: 'EzloWeather',
 *        capabilities: ['custom_idLesia', 'custom_temperatureLesia', 'custom_cityIdLesia', 'custom_temperature78'],
 *        details: {
 *            source: 'integration',
 *            integration_uuid: 'a4bdb8d9-393d-417e-af0b-4989c2387b99',
 *        },
 *        return: '',
 *     },
 *     selectedVariable: 'custom_cityIdLesia',
 * }
 */
export const collectUpdatedCloudVariableDataForTrigger = (triggerIds, variableData) => {
    const { integrationUuid, cloudVariableName, abstract } = variableData;

    return {
        ...triggerIds,
        selectedIntegrationId: integrationUuid,
        selectedVariable: cloudVariableName,
        selectedAbstract: abstract,
        selectedComparator: null,
        comparingValue: null,
    };
};
