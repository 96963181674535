import './style.scss';
import React, { Component } from 'react';
import isUndefined from 'lodash/isUndefined';
import classNames from 'classnames';
import * as domHelper from '../../helpers/dom';
import DropdownMenuItem from './MenuItem';

const BASE_CSS_CLASS_NAME = 'dropdown';
const OPEN_ANIMATION_SPEED = 200;
const CLOSE_ANIMATION_SPEED = 200;

const generateTransition = (speed, ease) => {
    return `opacity ${speed}ms ${ease},
          visibility ${speed}ms ${ease},
          max-height ${speed}ms ${ease}`;
};

class Dropdown extends Component {
    // static propTypes = {
    //   isWizardMode: PropTypes.bool, // TODO change 'isWizardMode' in to 'disable'
    //   onChange: PropTypes.func,
    //   itemsContainerStyle: PropTypes.object,
    //   itemsContainerMaxHeight: PropTypes.number,
    //   value: PropTypes.any,
    //   defaultValue: PropTypes.any
    // };
    //
    static defaultProps = {
        itemsContainerStyle: {},
        itemsContainerMaxHeight: 235,
        transitionAnimation: true,
        onChange: function () {},
    };

    hasCustomContent = false;

    state = {
        open: false,
        isDropUp: false,
        animation: false,
    };

    componentWillUnmount() {
        this.unsetEventListeners();
        clearTimeout(this.animationTimeout);
    }

    setAnimationState(open) {
        const speed = open ? OPEN_ANIMATION_SPEED : CLOSE_ANIMATION_SPEED;

        this.setState({
            animation: true,
        });

        this.animationTimeout = setTimeout(
            () =>
                this.setState({
                    animation: false,
                }),
            speed,
        );
    }

    clickAway = (e) => {
        const { target } = e;
        const isTargetArrow = () => {
            const node = target.parentNode || target;

            return node.classList.contains(`${BASE_CSS_CLASS_NAME}-arrow`);
        };

        if (domHelper.isDescendant(this.domNode, target) || isTargetArrow()) {
            return;
        }

        this.close();
    };

    setEventListeners() {
        window.addEventListener('click', this.clickAway, false);
    }

    unsetEventListeners() {
        window.removeEventListener('click', this.clickAway, false);
    }

    open = () => {
        const readOnly =
            !this.hasCustomContent && !this.props.defaultValue && React.Children.count(this.props.children) <= 1;

        if (readOnly) {
            return;
        }

        this.setState({ open: true });

        this.updateDropUpState();
        this.setAnimationState(true);
        this.setEventListeners();
    };

    close = () => {
        this.setState({ open: false });
        this.unsetEventListeners();
        this.setAnimationState(false);
    };

    toggle = () => {
        if (this.state.open) {
            this.close();

            return;
        }

        this.open();
    };

    getDisplayValue() {
        let displayValue = '';
        const { defaultValue } = this.props;

        if (defaultValue) {
            return defaultValue;
        }

        React.Children.forEach(this.props.children, (child) => {
            if (child && this.props.value === child.props.value) {
                displayValue = child.props.label || child.props.children;
            }
        });

        return displayValue;
    }

    stopPropagation = (e) => {
        e.stopPropagation();
    };

    onItemClick(child) {
        if (this.props.value !== child.props.value) {
            this.props.onChange(child.props.value);
        }

        this.close();
    }

    getModifiedChildren() {
        return React.Children.map(this.props.children, (child) => {
            const { value, defaultValue } = this.props;

            if (child.type !== DropdownMenuItem) {
                this.hasCustomContent = true;

                return child;
            }

            if (!isUndefined(value) && value === child.props.value && !defaultValue) {
                return null;
            }

            return React.cloneElement(child, {
                baseClassName: BASE_CSS_CLASS_NAME,
                active: this.props.value === child.props.value,
                onClick: (event) => {
                    this.onItemClick(child);
                    if (child.props.onClick) {
                        child.props.onClick(event);
                    }
                },
            });
        });
    }

    updateDropUpState() {
        const { itemsContainerMaxHeight } = this.props;
        const dropdownRect = this.domNode.getBoundingClientRect();
        const parentNode = domHelper.findScrollableParent(this.domNode);
        const parentRect = parentNode ? parentNode.getBoundingClientRect() : { top: 0, bottom: window.innerHeight };
        const itemsContainerMaxBottomPosition = dropdownRect.bottom + itemsContainerMaxHeight;
        const itemsContainerMaxTopPosition = dropdownRect.top - itemsContainerMaxHeight;
        const isDropUp =
            itemsContainerMaxBottomPosition > parentRect.bottom && itemsContainerMaxTopPosition > parentRect.top;

        this.setState({
            isDropUp,
        });
    }

    render() {
        const {
            className,
            itemsContainerStyle,
            itemsContainerMaxHeight,
            children,
            defaultValue,
            toggleElement,
            isWizardMode,
            transitionAnimation,
            ...otherProps
        } = this.props;
        const { open, isDropUp, animation } = this.state;
        const readOnly = !this.hasCustomContent && !defaultValue && React.Children.count(children) <= 1;
        const getToggleContent = () => {
            if (toggleElement) {
                return toggleElement;
            }

            return (
                <div>
                    <span>{this.getDisplayValue()}</span>
                </div>
            );
        };
        const transition =
            transitionAnimation === true
                ? open
                    ? generateTransition(CLOSE_ANIMATION_SPEED, 'ease-out')
                    : generateTransition(OPEN_ANIMATION_SPEED, 'ease-in')
                : null;
        const maxHeight = open ? itemsContainerMaxHeight : 0;

        return (
            <div
                {...otherProps}
                className={classNames(BASE_CSS_CLASS_NAME, className, {
                    'open': open,
                    'read-only': readOnly,
                    'drop-up': isDropUp,
                    'animation': animation,
                })}
                ref={(node) => (this.domNode = node)}
            >
                <div
                    className={`${BASE_CSS_CLASS_NAME}-toggle${isWizardMode === true ? ' not-allow' : ''}`}
                    onClick={isWizardMode === true ? null : this.toggle}
                >
                    {getToggleContent()}
                </div>
                <div
                    className={`${BASE_CSS_CLASS_NAME}-content`}
                    style={{
                        ...itemsContainerStyle,
                        transition,
                        maxHeight,
                    }}
                    // onClick={(e) => { this.close();  isWizardMode === true ? null : this.stopPropagation(e); }}
                >
                    {this.getModifiedChildren()}
                </div>
            </div>
        );
    }
}

export default Dropdown;
