import React from 'react';

export const IcControlsArrowLeft = (props) => {
    return (
        <svg width="13" height="20" viewBox="0 0 13 20" fill="none" {...props}>
            <path
                d="M7.88012 0.879951L0.749649 8.01042C0.269732 8.49034 0.000117302 9.14124 0.000117302 9.81995C0.000117302 10.4987 0.269732 11.1496 0.74965 11.6295L7.88012 18.76C9.05012 19.93 10.9501 19.93 12.1201 18.76L3.18012 9.81995L12.1201 0.879951C10.9501 -0.290049 9.05012 -0.290049 7.88012 0.879951Z"
                fill="#1C1E21"
            />
        </svg>
    );
};
