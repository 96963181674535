export const ITEM_GROUPS_STATUS = {
    DEFAULT: 'default',
    NOT_USED: 'not_used',
    IN_PROGRESS: 'in_progress',
    COMPLETED: 'completed',
    ERROR: 'error',
};

export const CAPABILITY_VALUE_TYPE = {
    TOKEN: 'token',
    BOOLEAN: 'bool',
    INTEGER: 'int',
    STRING: 'string',
};

export const FIELDS_NAMES = {
    ITEM_GROUP: 'itemGroup',
};

export const FIELD_TYPE_NAMES = {
    ITEM_GROUP_TYPE: 'item_group',
    DEVICE_GROUP_TYPE: 'device_group',
};
