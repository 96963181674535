/**
 * */
import { JAVASCRIPT_TYPES } from '../../../../constants/common/js_types';
import { APP_CONFIG_CONTAINER_INVALID_CONFIG } from './constants/errors';
import { BUGSNAG, ENVIRONMENTS } from '../constants/configKeys';
import { DEFAULT_ENVIRONMENT_INDEX } from '../OEMConfigContainer/constants/constants';

/**
 * AppConfigContainer - class is used as a wrapper around application configuration file.
 * Contains config.json content and util method to manage it.
 * */
export default class AppConfigContainer {
    /**
     * Application config object, config.json content
     * */
    #config;

    /**
     * Used to validate base config object
     * @param {Object} config - OEM config
     * @return boolean - validation result
     * */
    static isValidConfig(config) {
        return config && typeof config === JAVASCRIPT_TYPES.OBJECT;
    }

    /**
     * Validate #setConfig params
     * @param {Object} config - config object
     * @return {undefined}
     * @throw {Error} Validation error
     * */
    static validateSetConfigParams(config) {
        if (!AppConfigContainer.isValidConfig(config)) {
            throw new Error(APP_CONFIG_CONTAINER_INVALID_CONFIG);
        }
    }

    /**
     * Checks if there is #config
     * @return {Boolean} If #config has set and exists it will return true
     * */
    #isConfig() {
        return Boolean(this.#config);
    }

    /**
     * Set App configuration
     * @param {Object} config - Configuration object, contains app config values
     * @return {undefined}
     * */
    setConfig(config) {
        AppConfigContainer.validateSetConfigParams(config);
        this.#config = JSON.parse(JSON.stringify(config));
    }

    /**
     * Get OEM configuration
     * @return {Object} Configuration object, contains OEM and environment values
     * */
    getConfig() {
        if (!this.#isConfig()) {
            return {};
        }

        return JSON.parse(JSON.stringify(this.#config));
    }

    /**
     * Returns "environments" value from application config object.
     * @returns {Object} environments object
     * @example
     * {
     *     "vera": {
     *       "name": "vera",
     *       "pk_oem": 1,
     *       "x_app": "6f4753b6-4437-47a2-8fa3-c5a6cd30151f",
     *       "env_uuid": "a18dbbe0-344d-11ed-949d-bfe191cb81d0",
     *       "oem_template_master_pk_account": "13207662",
     *       "dashboard_url": "https://ezlodashboard.mios.com",
     *       "eula_url": "https://www.ezlo.com/legal/eula/",
     *       "privacy_policy_url": "https://www.ezlo.com/legal/privacy-policy/",
     *       "endpoints": {
     *         "cloud": "https://cloud.ezlo.com",
     *         "api-cloud": "https://api-cloud.ezlo.com",
     *         "media-cloud": "https://media-cloud.ezlo.com",
     *         "download-cloud": "https://download-cloud.ezlo.com",
     *         "upload-cloud": "https://upload-cloud.ezlo.com",
     *         "autha": "https://vera-us-oem-autha11.mios.com",
     *         "account": "https://vera-us-oem-account11.mios.com",
     *         "device": "https://vera-us-oem-device11.mios.com"
     *       }
     *     },
     * }
     * */
    getEnvironments() {
        return this.#config[ENVIRONMENTS];
    }

    /**
     * Returns default (0-index) environment config entry: [key, config] object.
     * @returns {array} [key, config] value
     * @example
     * [
     *     'vera',
     *     {
     *         name: 'vera',
     *         pk_oem: 1,
     *         x_app: '6f4753b6-4437-47a2-8fa3-c5a6cd30151f',
     *         env_uuid: 'a18dbbe0-344d-11ed-949d-bfe191cb81d0',
     *         oem_template_master_pk_account: '13207662',
     *         dashboard_url: 'https://ezlodashboard.mios.com',
     *         endpoints: {
     *             'cloud': 'https://cloud.ezlo.com',
     *             'api-cloud': 'https://api-cloud.ezlo.com',
     *             'media-cloud': 'https://media-cloud.ezlo.com',
     *             'download-cloud': 'https://download-cloud.ezlo.com',
     *             'upload-cloud': 'https://upload-cloud.ezlo.com',
     *             'autha': 'https://vera-us-oem-autha11.mios.com',
     *             'account': 'https://vera-us-oem-account11.mios.com',
     *             'device': 'https://vera-us-oem-device11.mios.com',
     *         },
     *     },
     * ]
     * */
    getDefaultEnvironmentConfigEntry() {
        return Object.entries(this.#config[ENVIRONMENTS])[DEFAULT_ENVIRONMENT_INDEX];
    }

    /**
     * Returns environment config by key.
     * @returns {Object} environment object
     * */
    getEnvironmentConfigByKey(key) {
        return this.#config[ENVIRONMENTS][key];
    }

    /**
     * Returns Bugsnag config
     * @returns {object | undefined} bugsnag config
     * @example
     * {
     *     "apiKey":"76b2b5cc2224ba9d928d16a188f77691"
     * }
     * */
    getBugsnagConfig() {
        return this.#config[BUGSNAG];
    }
}
