export const MESHBOT_LABELS = 'meshbot_labels';
export const LOCATION = 'location';
export const LABELS_TYPES = {
    MESHBOT: 'meshBot',
};
export const MESHBOT_CONFIG_KVS_KEY = 'ezlogic_automation_meshbots_config';
export const USER_TYPE = 'user';
export const TIMEOUT_OF_SAVING_MESHBOTS_PAGE_CONFIG_IN_KVS = 60000;
export const SHOW_SYSTEM_MESHBOTS = 'showSystemMeshBots';
export const DENSITY = 'density';
export const COLUMN_VISIBILITY = 'columnVisibility';
export const COLUMN_SIZING = 'columnSizing';
