export const CONTROLLER_FEATURES_NAMES = {
    ADVANCED_SCENES: 'advanced_scenes',
    BACKUPS: 'backups',
    CAMERAS: 'cameras',
    CLOUD_DEVICES: 'cloud_devices',
    DEVICE_SETTINGS: 'device_settings',
    LOCAL_MODE: 'local_mode',
    PROTECT: 'protect',
};

/**
 * To find more information about advanced_scenes versions go here:
 * @see {@link https://log.ezlo.com/new/hub/scenes/changelog/}
 */

export const ADVANCED_SCENES = {
    VERSION_1_1: '1.1',
    VERSION_1_2: '1.2',
    VERSION_1_3: '1.3',
    VERSION_1_4: '1.4',
    VERSION_1_5: '1.5',
    VERSION_1_6: '1.6',
    VERSION_1_7: '1.7',
    VERSION_1_8: '1.8',
    VERSION_1_9: '1.9',
    VERSION_1_10: '1.10',
    VERSION_1_11: '1.11',
    VERSION_1_12: '1.12',
    VERSION_1_13: '1.13',
    VERSION_1_14: '1.14',
    VERSION_1_15: '1.15',
    VERSION_1_16: '1.16',
    VERSION_1_17: '1.17',
    VERSION_1_18: '1.18',
    VERSION_1_19: '1.19',
    VERSION_1_20: '1.20',
    VERSION_1_21: '1.21',
    VERSION_1_22: '1.22',
    VERSION_1_23: '1.23',
    VERSION_1_24: '1.24',
    VERSION_1_25: '1.25',
    VERSION_1_26: '1.26',
    VERSION_1_27: '1.27',
    VERSION_1_28: '1.28',
    VERSION_1_29: '1.29',
    VERSION_1_30: '1.30',
    VERSION_1_31: '1.31',
    VERSION_1_32: '1.32',
    VERSION_1_33: '1.33',
    VERSION_1_34: '1.34',
    VERSION_1_35: '1.35',
    VERSION_1_36: '1.36',
    VERSION_1_37: '1.37',
    VERSION_1_38: '1.38',
    VERSION_1_39: '1.39',
    VERSION_1_40: '1.40',
    VERSION_1_41: '1.41',
    VERSION_1_42: '1.42',
    VERSION_1_43: '1.43',
    VERSION_1_44: '1.44',
    VERSION_1_45: '1.45',
    VERSION_1_46: '1.46',
    VERSION_1_47: '1.47',
    VERSION_1_48: '1.48',
    VERSION_1_49: '1.49',
    VERSION_1_50: '1.50',
    VERSION_1_51: '1.51',
    VERSION_1_52: '1.52',
    VERSION_1_53: '1.53',
    VERSION_1_55: '1.55',
    VERSION_1_57: '1.57',
    VERSION_1_61: '1.61',
    VERSION_1_62: '1.62',
    VERSION_1_66: '1.66',
    VERSION_1_67: '1.67',
    VERSION_1_70: '1.70',
};
