export const CONNECT_EZLO_GROUP = 'CONNECT_EZLO_GROUP';
export const CONNECT_EZLO_GROUP_SUCCESS = 'CONNECT_EZLO_GROUP_SUCCESS';
export const CONNECT_EZLO_GROUP_ERROR = 'CONNECT_EZLO_GROUP_ERROR';

export const DISCONNECT_EZLO_GROUP = 'DISCONNECT_EZLO_GROUP';
export const DISCONNECT_EZLO_GROUP_SUCCESS = 'DISCONNECT_EZLO_GROUP_SUCCESS';
export const DISCONNECT_EZLO_GROUP_ERROR = 'DISCONNECT_EZLO_GROUP_ERROR';

export const SET_INITIAL_EZLO_GROUP = 'SET_INITIAL_EZLO_GROUP';

export const UPDATE_EZLO_GROUP_DATA = 'UPDATE_EZLO_GROUP_DATA';

export const SELECT_GROUP = 'SELECT_GROUP';
