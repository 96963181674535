import { lazy } from 'react';

export const Login = lazy(() => import('containers/AuthorizationPages/Login'));
export const SignUp = lazy(() => import('containers/AuthorizationPages/SignUp/SignUp'));
export const ForgotPassword = lazy(() => import('containers/AuthorizationPages/ForgotPassword/ForgotPassword'));
export const ResetPasswordPage = lazy(() => import('containers/AuthorizationPages/ResetPasswordPage'));
export const FirstLoginPage = lazy(() => import('containers/AuthorizationPages/FirstLoginPage'));
export const ValidateEmailPage = lazy(() => import('containers/AuthorizationPages/ValidateEmailPage'));
export const EzloOffline = lazy(() => import('containers/Ezlo/EzloOffline'));
export const LoginWithToken = lazy(() => import('containers/AuthorizationPages/LoginWithToken/LoginWithToken'));
export const AccessRevoked = lazy(() => import('features/InstallerAccess/components/AccessRevoked/AccessRevoked'));
export const ItarianSso = lazy(() => import('containers/SsoPages/ItarianSso'));
export const NotFoundPage = lazy(() => import('containers/NotFoundPage'));
export const EzloPolicies = lazy(() => import('features/EzloPolicies/container'));
export const Ezlo = lazy(() => import('containers/Ezlo'));
export const EzloHubBackups = lazy(() => import('containers/Ezlo/EzloHubBackups'));
export const EzloDashboard = lazy(() => import('containers/Ezlo/EzloDashboard'));
export const EzloDevices = lazy(() => import('containers/Ezlo/EzloDevices'));
export const EzloGroups = lazy(() => import('containers/Ezlo/EzloGroups'));
export const EzloParjectsSettings = lazy(() => import('containers/Ezlo/EzloParjectsSettings'));
export const EzloMeshbots = lazy(() => import('containers/Ezlo/EzloMeshbots'));
export const EzloMeshBotLoc = lazy(() => import('containers/Ezlo/EzloMeshbot/MeshbotLocal'));
export const EzloPlugins = lazy(() => import('containers/Ezlo/EzloPlugins'));
export const MeshBotCloud = lazy(() => import('containers/Ezlo/EzloMeshbot/MeshbotCloud'));
export const EzloVirtualContainer = lazy(() => import('containers/Ezlo/EzloVirtualContainer'));
export const VirtualDevices = lazy(() => import('containers/Ezlo/VirtualDevices'));
export const EzloVirtualDevice = lazy(() => import('containers/Ezlo/EzloVirtualDevice'));
export const Customization = lazy(() => import('containers/Ezlo/EzloCustomization'));
export const GeofenceSettings = lazy(() => import('containers/Ezlo/EzloGeofenceSettings'));
export const NotificationsTemplateCreate = lazy(() =>
    import('containers/Ezlo/EzloNotificationTemplate/TemplateCreate'),
);
export const NotificationsTemplateEdit = lazy(() => import('containers/Ezlo/EzloNotificationTemplate/TemplateEdit'));
export const NotificationSettings = lazy(() => import('containers/Ezlo/EzloNotificationSettings'));
export const EzloDeviceGroups = lazy(() => import('containers/Ezlo/EzloDeviceGroups'));
export const DebouncedWebSocketComponent = lazy(() => import('containers/Ezlo/EzloDevices'));
export const UserManagement = lazy(() => import('containers/Ezlo/EzloAccount/UserManagement'));
export const EzloExpressions = lazy(() => import('containers/Ezlo/EzloExpressions'));
export const EzloLuaScripts = lazy(() => import('containers/Ezlo/EzloLuaScripts/EzloLuaScripts'));
export const EzloAssociations = lazy(() => import('containers/Ezlo/EzloDeviceAssociations'));
export const CreateTicketPage = lazy(() => import('containers/Ezlo/EzloSupport/EzloSupportCreateTicket'));
export const SupportLayout = lazy(() => import('containers/Ezlo/EzloSupport/EzloSupportLayout'));
export const EzloAccount = lazy(() => import('containers/Ezlo/EzloAccount/MyAccount'));
export const MediaStoragePage = lazy(() => import('features/MediaStoragePage'));
export const Migration = lazy(() => import('features/Migration'));
export const AddressableLed = lazy(() => import('features/EzloAddressableLed'));
export const EzloAccountSettings = lazy(() => import('containers/Ezlo/EzloAccount/AccountSettings'));
export const EzloSystemHistory = lazy(() => import('containers/Ezlo/EzloSystemHistory'));
export const EzloOEMNTemplateNotifications = lazy(() =>
    import('containers/Ezlo/EzloOEMNotifications/EzloOEMNotifications'),
);
export const EzloVariables = lazy(() => import('containers/Ezlo/EzloExpressions/EzloVariables'));
export const PinCodes = lazy(() => import('containers/Ezlo/EzloAccount/PinCodes'));
export const EzloInsights = lazy(() => import('containers/Ezlo/EzloInsights'));
