import { CATEGORY_ID, DESCRIPTION_CONFIG, ICON, IDS, VALUE, VALUE_FORMATTED } from '../constants';
import { getIconByDeviceCategory } from 'containers/Ezlo/EzloSystemHistory/2v/utils';

import styles from 'containers/Ezlo/EzloSystemHistory/2v/systemHistory.module.scss';
import { isArray, isObject } from 'lodash';

/**
 * @typedef DeviceEventResult
 * @type {object}
 * @property {string} deviceName - The name of the device.
 * @property {string} name - The name of the event.
 * @property {string|number|boolean|object|array} [value] - The value of the event.
 * @property {string} [valueFormatted] - The formatted value of the event.
 * @property {string} deviceCategory - The category identifier of the device.
 */

/**
 * Returns the description value from the given device event result.
 *
 * @param {DeviceEventResult} result - The device event result object.
 * @returns {string} - The description value if available, otherwise an empty string.
 */
const getDeviceEventValueFromResultForDescription = (result) => {
    if (result.hasOwnProperty(VALUE_FORMATTED)) {
        return result[VALUE_FORMATTED];
    }

    if (!result.hasOwnProperty(VALUE)) {
        return '';
    }

    if (isObject(result[VALUE]) || isArray(result[VALUE])) {
        return JSON.stringify(result[VALUE]);
    }

    return result[VALUE];
};
/**
 * Builds a device event description.
 *
 * @param {DeviceEventResult} result - The result object containing device name and event name.
 * @returns {string} The device event description.
 */
const buildDeviceEventDescription = (result = {}) => {
    const { deviceName, name } = result;

    return `${deviceName}, ${name} = ${getDeviceEventValueFromResultForDescription(result)}`;
};
/**
 * Returns the parameters for a device event.
 *
 * @param {string} category - The category of the device.
 * @param {DeviceEventResult} result - The result of the device event.
 * @returns {AlertHistoryEventParams} - The parameters for the device event.
 */
const getDeviceEventParams = (category, result) => ({
    [ICON]: getIconByDeviceCategory(category, { className: styles.deviceIcon }),
    [CATEGORY_ID]: IDS.ALL_ACTIONS,
    [DESCRIPTION_CONFIG]: {
        translationKey: buildDeviceEventDescription(result),
    },
});

/**
 * Retrieves device event parameters based on the given result structure.
 *
 * @param {DeviceEventResult} result - The result structure containing device information.
 * @returns {AlertHistoryEventParams|undefined} - The device event parameters if available, undefined otherwise.
 */
export const getDeviceEventParamsByResultStructure = (result) => {
    if (!result || !result.deviceName || !result.name) {
        return;
    }

    if (result.hasOwnProperty(VALUE_FORMATTED) || result.hasOwnProperty(VALUE)) {
        return getDeviceEventParams(result.deviceCategory, result);
    }
};
