import { updateGeofenceLocations, updateGeofenceRegisteredDevices } from '../reducers/geofenceSettings';

const actions = {
    updateGeofenceLocationList: (geofenceLocationList) => async (dispatch) => {
        dispatch(actions.updateGeofenceLocationListAction(geofenceLocationList));
    },
    updateGeofenceRegisteredDevicesList: (geofenceDevicesList) => async (dispatch) => {
        dispatch(actions.updateGeofenceRegisteredDevicesListAction(geofenceDevicesList));
    },
    updateGeofenceLocationListAction: (data) => (dispatch) => {
        dispatch(updateGeofenceLocations({ data }));
    },
    updateGeofenceRegisteredDevicesListAction: (data) => (dispatch) => {
        dispatch(updateGeofenceRegisteredDevices({ data }));
    },
};

export default actions;
