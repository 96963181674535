import React from 'react';
import classNames from 'classnames';
import { FormControl, InputLabel, Select, FormHelperText } from 'lib/@mui';

import InfoButton from '../InfoButton';

import { optionsSelect } from '../../../../../constants/MeshbotConstant';

import { useTranslate } from '../../../../../features/languages';
import { EZLOGIC_TITLE_DESCRIPTION } from '../../../../../constants/language_tokens';
import styles from './SelectComponent.module.scss';

const { wrapper, select, select_small, MuiPopover_paper, errorText, configureSelectWrapper } = styles;

const SelectComponent = ({
    value,
    label,
    name,
    small,
    onChange,
    children,
    disabled,
    info,
    deviceSetting,
    renderValue,
    placeholder,
    variant,
    inputProps,
    error,
    currentValue,
    helperText,
    isPluginConfigure,
    classNameCustom,
}) => {
    const { t } = useTranslate();
    const className = classNames(select, {
        [select_small]: small,
    });

    return (
        <div className={classNames(wrapper, classNameCustom ? configureSelectWrapper : '')}>
            <FormControl className={className} error={error}>
                <InputLabel>{t(label)}</InputLabel>
                <Select
                    MenuProps={{ ...optionsSelect, classes: { paper: MuiPopover_paper } }}
                    name={name}
                    value={value}
                    defaultValue={value}
                    onChange={onChange}
                    endAdornment={
                        info &&
                        !isPluginConfigure && (
                            <InfoButton>
                                <h4>{t(EZLOGIC_TITLE_DESCRIPTION)}</h4>
                                <div dangerouslySetInnerHTML={{ __html: t(info) }}></div>
                            </InfoButton>
                        )
                    }
                    disabled={disabled}
                    renderValue={renderValue}
                    placeholder={placeholder}
                    variant={variant}
                    inputProps={inputProps}
                    aria-label={label}
                >
                    {children}
                </Select>
                {deviceSetting}
                {currentValue}
                {error && <FormHelperText className={errorText}>{helperText}</FormHelperText>}
            </FormControl>
        </div>
    );
};

export default SelectComponent;
