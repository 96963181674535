import React from 'react';
import { useFormikContext } from 'formik';

import LabelDialogContainer from './LabelDialogContainer';

const FormikLabelDialogContainer = ({ open, closeDialog, dialogTitle, labelData, children }) => {
    const { resetForm } = useFormikContext();

    const handlerCloseDialog = () => {
        closeDialog();
        resetForm(labelData);
    };

    return (
        <LabelDialogContainer open={open} closeDialog={handlerCloseDialog} dialogTitle={dialogTitle}>
            {children}
        </LabelDialogContainer>
    );
};

export default FormikLabelDialogContainer;
