import at from '../constants/ActionTypes/Notification';

import {
    apiGetAllUser,
    apiGetChannels,
    apiGetUserUuid,
    apiGetDashboardsList,
    apiNotificationTemplate,
    apiSetNotificationTemplate,
    apiSetNotificationCategory,
    apiGetNotificationCategories,
    apiSetNotificationCategorySettings,
    apiGetNotificationCategoriesSettingsList,
    validateHtmlApi,
} from '../api/apiScenes';
import { bugsnagNotifyWrapper } from '../containers/ErrorBoundary/utils';
import { createUsersDataArray } from '../helpers/notification';
import { toast, TOAST_TYPE } from '../components/Toast';
import { ERROR_STATUS, SUCCESS_STATUS } from '../constants/Variables';
import {
    getDisplayNameForCategory,
    removeTemplateTags,
    replaceHref,
    replaceSrc,
} from '../containers/Ezlo/EzloNotificationTemplate/utils';
import { DATA_FROM_VALUE_FROM_FIRST_BLOCK, waiting } from '../constants/NotificationTemplates';
import { returnTemplatesListWithCustomDetected } from '../containers/Ezlo/EzloVirtualDevice/utils';
import {
    EZLOGIC_TITLE_CATEGORY_NOT_CREATED,
    EZLOGIC_TITLE_TEMPLATE_SUCCESSFULLY_CREATED,
    EZLOGIC_TITLE_TEMPLATE_SUCCESSFULLY_DELETED,
    EZLOGIC_TITLE_TEMPLATE_SUCCESSFULLY_UPDATED,
} from '../constants/language_tokens';

export const actions = {
    getUsersData: () => async (dispatch) => {
        dispatch({ type: at.GET_USERS_LIST.pending });
        try {
            const usersList = await apiGetAllUser();
            if (usersList?.length) {
                dispatch({
                    type: at.GET_USERS_LIST.success,
                    data: usersList,
                });
                const PkUsersList = usersList.map((item) => item.PK_User);
                const PkUUIDList = await apiGetUserUuid(PkUsersList);
                const result = createUsersDataArray(PkUUIDList, usersList);
                dispatch({
                    type: at.GET_UUID_LIST.success,
                    data: result,
                });
            }
        } catch (error) {
            bugsnagNotifyWrapper(error, { type: at.GET_USERS_LIST.rejected });
            dispatch({ type: at.GET_USERS_LIST.rejected });
        }
    },

    getNotificationTemplates: () => async (dispatch) => {
        try {
            Promise.all([
                apiNotificationTemplate(),
                dispatch({
                    type: at.GET_NOTIFICATION_TEMPLATES.pending,
                }),
            ]).then(
                (result) => {
                    const notificationTemplatesCustom = result[DATA_FROM_VALUE_FROM_FIRST_BLOCK] || [];
                    const statusApproved = 200;
                    if (
                        notificationTemplatesCustom?.status === statusApproved &&
                        notificationTemplatesCustom?.data?.data?.list
                    ) {
                        dispatch({
                            type: at.GET_NOTIFICATION_TEMPLATES.success,
                            data: [
                                ...returnTemplatesListWithCustomDetected(notificationTemplatesCustom?.data?.data?.list),
                            ],
                        });
                    }
                },
                (error) => {
                    bugsnagNotifyWrapper(error, { type: at.GET_NOTIFICATION_TEMPLATES.rejected });
                },
            );
        } catch (error) {
            bugsnagNotifyWrapper(error, { type: at.GET_NOTIFICATION_TEMPLATES.rejected });
            dispatch({ type: at.GET_NOTIFICATION_TEMPLATES.reject });
        }
    },

    getChannelsData: () => async (dispatch) => {
        dispatch({ type: at.GET_CHANNELS_LIST.pending });
        try {
            const getChannels = await apiGetChannels();
            if (getChannels && getChannels.length > 0) {
                dispatch({
                    type: at.GET_CHANNELS_LIST.success,
                    data: getChannels,
                });
            }
        } catch (error) {
            bugsnagNotifyWrapper(error, { type: at.GET_CHANNELS_LIST.rejected });
            dispatch({ type: at.GET_CHANNELS_LIST.rejected });
        }
    },

    getDashboardsList: () => async (dispatch) => {
        dispatch({ type: at.GET_DASHBOARD_LIST.pending });

        try {
            const dashboardsListArray = await apiGetDashboardsList();
            dispatch({
                type: at.GET_DASHBOARD_LIST.success,
                data: dashboardsListArray,
            });
        } catch (error) {
            bugsnagNotifyWrapper(error, { type: at.GET_DASHBOARD_LIST.rejected });
            dispatch({ type: at.GET_DASHBOARD_LIST.rejected });
        }
    },

    updateNotificationType: (params) => async (dispatch) => {
        dispatch({
            type: at.UPDATE_NOTIFICATION_TYPE.success,
            data: params,
        });
    },

    setNotificationTemplate: (data, t) => async (dispatch) => {
        dispatch({ type: at.SET_NOTIFICATION_TEMPLATE.pending });
        await apiSetNotificationTemplate(data)
            .then((res) => {
                if (res?.data?.status === ERROR_STATUS) {
                    bugsnagNotifyWrapper(res.data.data.error_message, { data });
                } else if (res?.data?.status === SUCCESS_STATUS) {
                    dispatch({ type: at.SET_NOTIFICATION_TEMPLATE.success });
                    const toastMessages = {
                        update: t(EZLOGIC_TITLE_TEMPLATE_SUCCESSFULLY_UPDATED),
                        delete: t(EZLOGIC_TITLE_TEMPLATE_SUCCESSFULLY_DELETED),
                        create: t(EZLOGIC_TITLE_TEMPLATE_SUCCESSFULLY_CREATED),
                    };
                    const toastMessage = toastMessages[data?.action] || '';
                    toast(toastMessage, { type: TOAST_TYPE.SUCCESS });
                    dispatch(actions.getNotificationTemplates());
                }
            })
            .catch((err) => {
                bugsnagNotifyWrapper(err, { data });
                dispatch({ type: at.SET_NOTIFICATION_TEMPLATE.rejected });
            });
    },

    setNotificationCategory: (data, toastText, t) => async (dispatch) => {
        dispatch({ type: at.SET_NOTIFICATION_CATEGORY.pending });

        await apiSetNotificationCategory(data).then((res) => {
            if (res?.data?.status === ERROR_STATUS) {
                bugsnagNotifyWrapper(t(EZLOGIC_TITLE_CATEGORY_NOT_CREATED));
            } else if (res?.data?.status === SUCCESS_STATUS) {
                toast(t(toastText), { type: TOAST_TYPE.SUCCESS });
                dispatch(actions.getNotificationCategories());
                dispatch({ type: at.SET_NOTIFICATION_CATEGORY.success });
            }
        });
    },

    setNotificationCategorySettings: (params) => async (dispatch) => {
        dispatch({ type: at.SET_NOTIFICATION_CATEGORY_SETTINGS.pending });
        toast(waiting, { type: TOAST_TYPE.INFO });
        await apiSetNotificationCategorySettings(params)
            .then(() => {
                dispatch({ type: at.SET_NOTIFICATION_CATEGORY_SETTINGS.success });
            })
            .catch(() => {
                toast(ERROR_STATUS, { type: TOAST_TYPE.ERROR });
                dispatch({ type: at.SET_NOTIFICATION_CATEGORY_SETTINGS.rejected });
            });
    },

    setAllNotificationCategorySettings: (updatedNotificationCategoriesList, newState) => async (dispatch) => {
        dispatch({ type: at.SET_NOTIFICATION_CATEGORY_SETTINGS.pending });
        toast(waiting, { type: TOAST_TYPE.INFO });
        updatedNotificationCategoriesList?.map(async (item) => {
            const params = {
                category_uuid: item?.uuid,
                state: newState,
            };

            await apiSetNotificationCategorySettings(params)
                .then(() => {
                    dispatch({ type: at.SET_NOTIFICATION_CATEGORY_SETTINGS.success });
                })
                .catch(() => {
                    toast(ERROR_STATUS, { type: TOAST_TYPE.ERROR });
                    dispatch({ type: at.SET_NOTIFICATION_CATEGORY_SETTINGS.rejected });
                });
        });
    },

    getNotificationCategories: () => async (dispatch, getState) => {
        await apiGetNotificationCategories()
            .then(async (res) => {
                const data = res?.data?.data?.list;
                const notificationCategories = getState()?.notifications?.notificationCategories;
                if (!notificationCategories?.length) {
                    dispatch({ type: at.GET_NOTIFICATION_CATEGORIES.success, data });
                } else {
                    const result = [];

                    data.map(function (categoryFromData) {
                        const currentCategoryFromState = notificationCategories.find(
                            (category) => category.uuid === categoryFromData.uuid,
                        );

                        result.push({
                            uuid: categoryFromData?.uuid,
                            display_name: getDisplayNameForCategory(currentCategoryFromState, categoryFromData),
                            isEdit: currentCategoryFromState?.isEdit,
                            nameBeforeEditing: categoryFromData?.display_name,
                            meta: {},
                        });
                    });

                    dispatch({ type: at.GET_NOTIFICATION_CATEGORIES.success, data: result });
                }

                await apiGetNotificationCategoriesSettingsList()
                    .then((res) => {
                        const settingsListData = res?.data?.data?.list;
                        dispatch({ type: at.GET_NOTIFICATION_CATEGORIES_SETTINGS.success, data: settingsListData });
                    })
                    .catch((error) => {
                        bugsnagNotifyWrapper(error, { type: at.GET_NOTIFICATION_CATEGORIES_SETTINGS.rejected });
                        dispatch({ type: at.GET_NOTIFICATION_CATEGORIES_SETTINGS.rejected });
                    });
            })
            .catch((error) => {
                bugsnagNotifyWrapper(error, { type: at.GET_NOTIFICATION_CATEGORIES.rejected });
                dispatch({ type: at.GET_NOTIFICATION_CATEGORIES.rejected });
            });
    },

    setNotificationSelectedCapability: (params) => async (dispatch) => {
        dispatch({
            type: at.SET_SELECTED_NOTIFICATION_CAPABILITY.success,
            data: params,
        });
    },
    validateHtmlAction: (htmlContent, setHtmlError) => async () => {
        const updatedWithRemovedTemplateTags = removeTemplateTags(htmlContent);
        const updatedWithReplaceSrc = replaceSrc(updatedWithRemovedTemplateTags);
        const updatedWithReplaceHref = replaceHref(updatedWithReplaceSrc);

        try {
            const response = await validateHtmlApi(updatedWithReplaceHref);
            const errors = response?.data?.messages.filter((msg) => msg?.type === 'error');

            if (errors?.length > 0) {
                setHtmlError(
                    'Error: Invalid HTML detected. Please correct it. ' + errors?.map((err) => err?.message).join(', '),
                );
            } else {
                setHtmlError(null);
            }
        } catch (error) {
            setHtmlError('Error validating HTML');
        }
    },
};

export default actions;
