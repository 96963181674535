import React from 'react';

const IcDeviceCrossedOutPincodeIcon = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="#1C1E21" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28 32L30 30L24.6552 24.6552L13.5 13.5L2 2L0 4L7.06085 11.0609C6.88103 11.021 6.69338 11 6.5 11C5.1 11 4 12.1 4 13.5C4 14.9 5.1 16 6.5 16C7.9 16 9 14.9 9 13.5C9 13.3066 8.97901 13.119 8.93915 12.9391L13.5 17.5V22L7.5 19C6.3 20.8 6.4 23.1 7.8 24.8L13.5 32H22.5L23.7791 27.7791L28 32ZM16 10C17.4 10 18.5 11.1 18.5 12.5V14.5L14.4931 10.4931C14.9097 10.1824 15.43 10 16 10ZM16 2C14.6 2 13.5 3.1 13.5 4.5C13.5 5.9 14.6 7 16 7C17.4 7 18.5 5.9 18.5 4.5C18.5 3.1 17.4 2 16 2ZM25.5 2C24.1 2 23 3.1 23 4.5C23 5.9 24.1 7 25.5 7C26.9 7 28 5.9 28 4.5C28 3.1 26.9 2 25.5 2ZM25.5 11C24.1 11 23 12.1 23 13.5C23 14.9 24.1 16 25.5 16C26.9 16 28 14.9 28 13.5C28 12.1 26.9 11 25.5 11Z"
            />
        </svg>
    );
};

export default IcDeviceCrossedOutPincodeIcon;
