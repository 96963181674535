import React from 'react';

export const EyeOffIcon = (props) => (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M15.3128 26.6L19.7128 22.2L19.8128 22.1L15.8128 18.1L18.0128 16L24.5128 22.5L27.1128 25.1L40.0128 38L37.9128 40.1L33.6128 35.8C31.9128 36.8 29.9128 37.3 28.0128 37.3C25.1128 37.3 22.1128 36.2 19.8128 33.9L15.4128 29.5C14.6128 28.7 14.6128 27.4 15.3128 26.6ZM21.9128 31.8C23.5128 33.4 25.7128 34.3 27.9128 34.3C29.1128 34.3 30.2128 34.1 31.3128 33.6L28.7128 31C28.5128 31.1 28.2128 31.1 27.9128 31.1C26.2128 31.1 24.9128 29.8 24.9128 28.1C24.9128 27.8 24.9128 27.6 25.0128 27.3L22.0128 24.3L21.9128 24.4L18.2128 28.1L21.9128 31.8ZM40.5128 29.5C41.3128 28.7 41.3128 27.5 40.5128 26.7L36.1128 22.2C33.8128 19.9 30.9128 18.8 27.9128 18.8C27.0128 18.8 26.2128 18.9 25.3128 19.1L28.1128 21.8C30.3128 21.8 32.4128 22.7 34.0128 24.3L37.7128 28L36.0128 29.7L38.1128 31.9L40.5128 29.5Z"
            fill="#1C1E21"
        />
    </svg>
);

export default EyeOffIcon;
