import { Menu, MenuItem } from 'lib/@mui';
import React from 'react';

import { LABEL_MENU_ITEMS } from '../../../constants';
import { useTranslate } from '../../../../../languages';

import styles from './styles.module.scss';

const LabelMenu = ({ anchorEl, menuOpen, onClickMenuItem, closeMenu }) => {
    const { t } = useTranslate();

    return (
        <Menu
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={closeMenu}
            PaperProps={{
                elevation: 1,
            }}
        >
            {LABEL_MENU_ITEMS.map(({ value, labelTranslationKey, icon }) => (
                <MenuItem className={styles.menuItem} key={value} onClick={(e) => onClickMenuItem(e, value)}>
                    {icon} <span className={styles.menuItemText}>{t(labelTranslationKey)}</span>
                </MenuItem>
            ))}
        </Menu>
    );
};

export default LabelMenu;
