import at from '../constants/ActionTypes/Navigation';
import { LOGOUT } from '../constants/ActionTypes';
import produce from 'immer';

const INITIAL_STATE = {
    activeMenuItems: ['dashboard'],
};

export default produce((draft, action) => {
    switch (action.type) {
        case at.SET_ACTIVE_MENU_ITEM: {
            draft.activeMenuItems = action.data;
            break;
        }
        case LOGOUT: {
            return INITIAL_STATE;
            break;
        }
    }
}, INITIAL_STATE);
