import { subscribe, unsubscribe, validateSerial } from '../../common/utils';
import { HUB_ITEM_DICTIONARY_UPDATED } from '../../common/methods';
import isFunction from 'lodash/isFunction';

/**
 * Subscribes to hub item dictionary updated events for a specific hub.
 *
 * @function
 * @name hubItemDictionaryUpdatedSubscribeApi
 * @param {string|number} serial - Controller serial number.
 * @param {function} callback - Callback function to handle the event data.
 * @returns {Promise}
 */
export const hubItemDictionaryUpdatedSubscribeApi = (serial, callback) => {
    validateSerial(serial);

    return subscribe(serial, HUB_ITEM_DICTIONARY_UPDATED, callback);
};
/**
 * Represents the arguments for hubItemDictionaryUpdatedUnsubscribeApi.
 * @typedef {Array} HubItemDictionaryUpdatedUnsubscribeArgs
 * @property {string|number} 0 - The serial.
 * @property {Function} [1] - The optional callback function.
 */

/**
 * Unsubscribes from hub item dictionary updated events for a specific hub.
 *
 * @function
 * @name hubItemDictionaryUpdatedUnsubscribeApi
 * @param {string|number} serial - Controller serial number.
 * @param {function} [callback] - Callback function previously used for subscription.
 * @returns {Promise}
 */
export const hubItemDictionaryUpdatedUnsubscribeApi = (serial, callback) => {
    validateSerial(serial);

    return unsubscribe(serial, HUB_ITEM_DICTIONARY_UPDATED, callback);
};
/**
 * Subscribes to hub item dictionary updated events for multiple hubs.
 *
 * @function
 * @name hubItemDictionaryUpdatedSubscribeForHubs
 * @param {Array<string|number>} serials - Array of controller serial numbers.
 * @param {function} callback - Callback function to handle the event data.
 * @returns {void}
 */
export const hubItemDictionaryUpdatedSubscribeForHubs = (serials, callback) =>
    serials.forEach((serial) => hubItemDictionaryUpdatedSubscribeApi(serial, (data) => callback(serial, data)));

/**
 * Unsubscribes from hub item dictionary updated events for multiple hubs.
 *
 * @function
 * @name hubItemDictionaryUpdatedUnsubscribeForHubs
 * @param {Array<string|number>} serials - Array of controller serial numbers.
 * @param {function} callback - Callback function previously used for unsubscription.
 * @returns {void}
 */
export const hubItemDictionaryUpdatedUnsubscribeForHubs = (serials, callback) =>
    serials.forEach((serial) => {
        /**
         * @type {HubItemDictionaryUpdatedUnsubscribeArgs}
         */
        const args = [serial];
        if (isFunction(callback)) {
            args.push((data) => callback(serial, data));
        }

        hubItemDictionaryUpdatedUnsubscribeApi(...args);
    });

/**
 * Object containing subscription and unsubscription functions for hub item dictionary updates.
 *
 * @constant
 * @type {Object}
 *
 * @property {function} subscribe - Function to subscribe to hub item dictionary updated events for a specific hub.
 * @property {function} unsubscribe - Function to unsubscribe from hub item dictionary updated events for a specific hub.
 * @property {function} subscribeForHubs - Function to subscribe to hub item dictionary updated events for multiple hubs.
 * @property {function} unsubscribeForHubs - Function to unsubscribe from hub item dictionary updated events for multiple hubs.
 * @see { [hub.item.dictionary.updated](https://log.ezlo.com/new/hub/broadcasts/#hubitemdictionaryupdated) }
 */
export const updated = {
    subscribe: hubItemDictionaryUpdatedSubscribeApi,
    unsubscribe: hubItemDictionaryUpdatedUnsubscribeApi,
    subscribeForHubs: hubItemDictionaryUpdatedSubscribeForHubs,
    unsubscribeForHubs: hubItemDictionaryUpdatedUnsubscribeForHubs,
};
