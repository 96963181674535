import * as ActionTypes from '../constants/ActionTypes';

export default function (state = [], action) {
    switch (action.type) {
        case ActionTypes.ADD_MODAL: {
            return [...state, action.payload];
        }
        case ActionTypes.REMOVE_MODAL: {
            return [...state.slice(0, -1)];
        }
        case ActionTypes.LOGOUT: {
            return [];
        }
        default:
            return [];
    }
}
