import React, { useMemo, useRef, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import classname from 'classnames';
import { ThemeProvider, useTheme } from 'lib/@mui/styles';

import { getCustomTableTheme } from './customTheme';
import { useTranslate } from 'features/languages';
import { getTableLocalization } from '../utils';
import useScrollToTop from '../hooks/useScrollToTop/useScrollToTop';
import { DEFAULT_TABLE_PAGE_INDEX, DEFAULT_TABLE_ROWS_PER_PAGE } from '../constants';

import styles from './styles.module.scss';

const Table = (props) => {
    const { t } = useTranslate();
    const tableRef = useRef();
    const [pagination, setPagination] = useState({
        pageSize: props.state?.pagination?.pageSize || DEFAULT_TABLE_ROWS_PER_PAGE,
        pageIndex: props.state?.pagination?.pageIndex || DEFAULT_TABLE_PAGE_INDEX,
    });
    const localization = useMemo(() => getTableLocalization(t, props.localization), [t, props.localization]);
    const globalTheme = useTheme();
    const tableTheme = useMemo(() => getCustomTableTheme(globalTheme), [globalTheme]);
    const classNames = classname(styles.materialReactTableContainer, { [props.className]: props.className });
    useScrollToTop(tableRef, props.state?.pagination?.pageIndex || pagination?.pageIndex);

    const handlePageChange = (paginationState) => setPagination(paginationState);

    return (
        <ThemeProvider theme={tableTheme}>
            <MaterialReactTable
                enableStickyHeader
                enableColumnResizing
                positionPagination="top"
                autoResetPageIndex={false}
                muiTableContainerProps={{ ref: tableRef }}
                onPaginationChange={handlePageChange}
                {...props}
                state={{ pagination, ...props.state }}
                muiTablePaperProps={{ className: classNames, ...props.muiTablePaperProps }}
                localization={localization}
            />
        </ThemeProvider>
    );
};

export default Table;
