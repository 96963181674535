import React from 'react';

export const RefreshIcon = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.943 8.05714C14.8716 6.98571 13.5001 6.42857 12.0001 6.42857V5.35714L10.7144 6.21428L9.42871 7.07143L10.7144 7.92857L12.0001 8.78571V7.71428C13.1573 7.71428 14.2287 8.14285 15.043 8.95714C15.8573 9.77142 16.2859 10.8429 16.2859 12C16.2859 13.1571 15.8573 14.2286 15.043 15.0429C14.2287 15.8571 13.1573 16.2857 12.0001 16.2857C10.843 16.2857 9.77157 15.8571 8.95728 15.0429C8.143 14.2286 7.71443 13.1571 7.71443 12C7.71443 10.8429 8.143 9.77142 8.95728 8.95714L8.05728 8.05714C7.02871 9.12857 6.42871 10.5 6.42871 12C6.42871 13.5 7.02871 14.8714 8.05728 15.9429C9.12871 17.0143 10.5001 17.5714 12.0001 17.5714C13.5001 17.5714 14.8716 16.9714 15.943 15.9429C17.0144 14.8714 17.5716 13.5 17.5716 12C17.5716 10.5 16.9716 9.12857 15.943 8.05714Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default RefreshIcon;
