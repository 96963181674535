import React from 'react';
import moment from 'moment/moment';
import {
    CAMERA_SUITE,
    DIMMABLE_LIGHT,
    DOOR_LOCK,
    DOORBELL,
    FLOW_METER,
    GARAGE_DOOR,
    GENERIC_SENSOR,
    HUMIDITY,
    HVAC,
    INTERFACE,
    LEVEL_SENSOR,
    LIGHT_SENSOR,
    POWER_METER,
    SCENE_CONTROLLER,
    SECURITY_SENSOR,
    SIREN,
    SWITCH,
    TEMPERATURE,
    UV_SENSOR,
    STATE_SENSOR,
    LED_STRIP,
    VOLTAGE_SENSOR,
    WINDOW_COV,
} from 'constants/SystemHistory';
import { ABSTRACT, CAMERA } from 'constants/MeshbotConstant';
import {
    AlarmEvent,
    Blinds,
    CameraCategory,
    ClimateCategory,
    DeviceCategory,
    Doorbell,
    DoorLocksCategory,
    GarageDoor,
    IcActionsRecord,
    IcActionsSceneController,
    IcDeviceSensorPowerMeter,
    IcControlsLedStrip,
    OtherCategory,
    SensorCategory,
    SwitchesCategory,
    VirtualDevicesCategory,
    FlowMeter,
} from 'assets/icons';
import { EZLOGIC_TITLE_TODAY } from 'constants/language_tokens';

export const getIconByDeviceCategory = (category, iconProps = {}) => {
    switch (category) {
        case SIREN:
            return <AlarmEvent {...iconProps} />;
        case SECURITY_SENSOR:
        case GENERIC_SENSOR:
        case LEVEL_SENSOR:
        case LIGHT_SENSOR:
        case TEMPERATURE:
        case HUMIDITY:
        case VOLTAGE_SENSOR:
        case UV_SENSOR:
        case STATE_SENSOR:
            return <SensorCategory {...iconProps} />;
        case CAMERA:
            return <CameraCategory {...iconProps} />;
        case DIMMABLE_LIGHT:
        case LED_STRIP:
            return <IcControlsLedStrip {...iconProps} />;
        case SWITCH:
            return <SwitchesCategory {...iconProps} />;
        case DOOR_LOCK:
            return <DoorLocksCategory {...iconProps} />;
        case ABSTRACT:
            return <VirtualDevicesCategory {...iconProps} />;
        case HVAC:
            return <ClimateCategory {...iconProps} />;
        case INTERFACE:
            return <OtherCategory {...iconProps} />;
        case WINDOW_COV:
            return <Blinds {...iconProps} />;
        case DOORBELL:
            return <Doorbell {...iconProps} />;
        case FLOW_METER:
            return <FlowMeter {...iconProps} />;
        case GARAGE_DOOR:
            return <GarageDoor {...iconProps} />;
        case POWER_METER:
            return <IcDeviceSensorPowerMeter {...iconProps} />;
        case SCENE_CONTROLLER:
            return <IcActionsSceneController {...iconProps} />;
        case CAMERA_SUITE:
            return <IcActionsRecord {...iconProps} />;
        default:
            return <DeviceCategory {...iconProps} />;
    }
};

export const getUniqueObjectsFromArray = (array) => {
    return [...new Set(array.map((o) => JSON.stringify(o)))].map((element) => JSON.parse(element));
};

export const getUiDate = (timestamp, localeKey) => {
    const today = moment();
    today.locale(localeKey);
    const formattedToday = today.format('dddd, MMMM DD, YYYY');

    const dateByTimestamp = moment(Number(timestamp));
    dateByTimestamp.locale(localeKey);
    const formattedDateByTimestamp = dateByTimestamp.format('dddd, MMMM DD, YYYY');

    return formattedToday === formattedDateByTimestamp ? EZLOGIC_TITLE_TODAY : formattedDateByTimestamp;
};
