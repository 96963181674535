import * as types from '../constants/ActionTypes';
import at from '../constants/ActionTypes/AccountInfo';
import produce from 'immer';

const initialState = {
    logged: false,
    data: null,
    isLogging: false,
    isCustomizationProgressing: false,
    polling: false,
    isCredentialsError: false,
    installationsList: [],
    mailingBillingAddresses: null,
    countriesList: [],
    profilePicture: '',
    isUploadingProfilePicture: false,
    isProfilePictureError: false,
};

export default produce((draft, action) => {
    switch (action.type) {
        case types.ACCOUNT_LOGGED: {
            draft.isLogging = false;
            draft.logged = true;
            break;
        }
        case types.SET_INFO_OF_LOGGED_USER: {
            const prevData = draft.data || {};
            draft.data = {
                ...prevData,
                ...action.user,
            };

            break;
        }
        case types.SET_IS_CUSTOMIZATION_PROGRESSING: {
            draft.isCustomizationProgressing = action.value;
            break;
        }

        case types.LOGOUT: {
            return initialState;
            break;
        }

        case types.LOGIN: {
            draft.logged = false;
            draft.data = null;
            draft.isLogging = true;
            draft.isCredentialsError = false;
            break;
        }

        case types.LOGIN_CREDENTIALS_ERROR: {
            draft.isCredentialsError = true;
            break;
        }

        case types.START_POLLING: {
            draft.polling = true;
            break;
        }
        case types.STOP_POLLING: {
            draft.polling = false;
            break;
        }

        case types.UPDATE_PROFILE: {
            draft.data = { ...draft.data, ...action.data };
            break;
        }

        case at.SET_INSTALLATIONS_LIST.success: {
            draft.installationsList = action.installations;
            break;
        }

        case at.SET_MAILING_BILLING_ADDRESSES.success: {
            draft.mailingBillingAddresses = action.mailingBillingAddresses;
            break;
        }

        case at.SET_COUNTRIES_LIST.success: {
            draft.countriesList = action.countriesList;
            break;
        }

        case at.PROFILE_PICTURE: {
            draft.profilePicture = action.profileImageUrl;
            break;
        }

        case at.PROFILE_PICTURE_ERROR: {
            draft.isProfilePictureError = action.profilePictureUrlError;
            break;
        }

        case at.UPLOADING_PROFILE_PICTURE.request: {
            draft.isUploadingProfilePicture = true;
            break;
        }
        case at.UPLOADING_PROFILE_PICTURE.success: {
            draft.isUploadingProfilePicture = false;
            break;
        }
        case at.UPLOADING_PROFILE_PICTURE.rejected: {
            draft.isUploadingProfilePicture = false;
            break;
        }

        default:
            break;
    }
}, initialState);
