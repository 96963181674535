import {
    EZLOGIC_TITLE_FIRMWARE,
    EZLOGIC_TITLE_HUB_TYPE,
    EZLOGIC_TITLE_SERIAL,
} from '../../../../constants/language_tokens';

export const MAIN_CONTENT_VIEW = {
    CREATE: 'CREATE',
    RESTORE: 'RESTORE',
    NOT_SUPPORTED: 'NOT_SUPPORTED',
};

export const CREATE_BACKUP_STEPS = {
    CREATE_BACKUP: 'CREATE_BACKUP',
    IN_PROGRESS: 'IN_PROGRESS',
    BACKUP_COMPLETED: 'BACKUP_COMPLETED',
    BACKUP_ERROR: 'BACKUP_ERROR',
};

export const RESTORE_BACKUP_STEPS = {
    RESTORE_BACKUP: 'RESTORE_BACKUP',
    CONFIRM_RESTORING: 'CONFIRM_RESTORING',
    IN_PROGRESS: 'IN_PROGRESS',
    BACKUP_RESTORED: 'BACKUP_RESTORED',
    BACKUP_ERROR: 'BACKUP_ERROR',
};

const BACKUP_FILE_TYPE = 'archive_controller_backup';

export const BACKUP_SUPPORTED_ARCHITECTURES = ['armv7l']; // architecture types
export const INTEGRATIONS_SUPPORTED_ARCHITECTURES = ['armv7l']; // for now, same as for backups

export const BACKUP_FILES_NUMBER_PER_PAGE = 20;
export const INITIAL_PAGE_NUMBER = 1;
export const FIRST_BACKUP_INDEX = 0;
export const CALL_VERSION = '2';
const TIMESTAMP = 'timestamp';
const DESC = 'desc';
export const BACKUP_CALL_DEFAULT_FILTERS = {
    page: INITIAL_PAGE_NUMBER,
    per_page: BACKUP_FILES_NUMBER_PER_PAGE,
    type: BACKUP_FILE_TYPE,
    order_by: TIMESTAMP,
    order_type: DESC,
};
export const REQUEST_METHOD_POST = 'post';
export const STORAGE_LIST_CALL = 'storage_list';
export const CONTROLLER_BACKUPS_BUCKET = 'controller_backups';
export const DEFAULT_BUCKET = 'default';
export const PREV = 'PREV';
export const NEXT = 'NEXT';
export const PAGE_STEP = 1;
export const TIMESTAMP_AFTER = 'timestamp_after';
export const TIMESTAMP_BEFORE = 'timestamp_before';
export const FROM_DATE = 'from_date';
export const TO_DATE = 'to_date';
export const PATHS = {
    HUB_TYPE: 'meta.controller_info.config.hub_type',
    FIRMWARE: 'meta.controller_info.config.software.firmware',
    SERIAL: 'meta.controller_info.config.serial',
};
export const SHORT_BACKUP_INFO_ITEMS = [
    {
        propertyPath: PATHS.HUB_TYPE,
        uiNameKey: EZLOGIC_TITLE_HUB_TYPE,
    },
    {
        propertyPath: PATHS.FIRMWARE,
        uiNameKey: EZLOGIC_TITLE_FIRMWARE,
    },
];
export const BACKUP_INFO_ITEMS = [
    {
        propertyPath: PATHS.SERIAL,
        uiNameKey: EZLOGIC_TITLE_SERIAL,
    },
    ...SHORT_BACKUP_INFO_ITEMS,
];
export const popoverConfig = {
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
    },
    transformOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    },
    PaperProps: { elevation: 4 },
};
