import React from 'react';
import { LinearProgress } from 'lib/@mui';

import styles from './styles.module.scss';

const BoxWithLinearProgress = ({ isLinearProgress, children, editProfileDialog }) => {
    return (
        <div className={editProfileDialog ? styles.lineProgressBoxWrapperTop : styles.lineProgressBox}>
            {isLinearProgress && (
                <div className={editProfileDialog ? styles.lineProgressTop : styles.lineProgress}>
                    <LinearProgress />
                </div>
            )}
            {children}
        </div>
    );
};

export default BoxWithLinearProgress;
