import React from 'react';

const IcModesAtHome = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="#1C1E21" {...props}>
            <path d="M13.9 7.4L19.5 13H27.5H30.5L22.7 5.2C21.9 4.4 20.9 4 19.8 4H14.5H12.2C11.1 4 10.1 4.4 9.3 5.2L1.5 13H4.5V25C4.5 26.7 5.8 28 7.5 28H24.5C26.2 28 27.5 26.7 27.5 25V16H24.5H22.5H19.7C18.9 16 18.1 15.7 17.6 15.1L10.5 8L11.1 7.4C11.9 6.6 13.1 6.6 13.9 7.4ZM7.5 18H14.5V25H7.5V18Z" />
        </svg>
    );
};

export default IcModesAtHome;
