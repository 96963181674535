import React from 'react';
import { setLocalStorage } from '../../../../../helpers/helpersMeshBot';

import classNames from 'classnames';
import { MeshBotLabelsInTableCell } from '../../../../../features/Labels/MeshBotsLabels';
import { getMeshBotDropZoneAttributes } from '../../utils';
import withAbilityGoEditMeshbot from '../../HOCs/withAbilityGoEditMeshbot';

import styles from './MeshBotNameCell.module.scss';

const MeshBotNameCell = ({ row: { original: params }, onClick }) => {
    // TODO: refactoring needed, legacy approach, no localstorage here!
    const handleClick = () => {
        setLocalStorage('createType', params.type);
        onClick(params);
    };

    return (
        <div
            {...getMeshBotDropZoneAttributes(params)}
            onClick={handleClick}
            title={params.name}
            className={classNames(styles.cell, { [styles.disabled]: params.isDisabled })}
        >
            <MeshBotLabelsInTableCell labelsUuids={params.labelsUuids} meshBotUuid={params.id} />
            <span className={styles.name}>{params.name}</span>
        </div>
    );
};

export default withAbilityGoEditMeshbot(MeshBotNameCell);
