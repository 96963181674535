import React from 'react';

export const Doorbell = (props) => {
    return (
        <svg {...props} viewBox="0 0 13 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 19H13V29C13 30.1046 12.1046 31 11 31H2C0.89543 31 0 30.1046 0 29V19Z" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 0C0.895431 0 0 0.895431 0 2V16H13V2C13 0.895431 12.1046 0 11 0H2ZM10 6.5C10 8.433 8.433 10 6.5 10C4.567 10 3 8.433 3 6.5C3 4.567 4.567 3 6.5 3C8.433 3 10 4.567 10 6.5Z"
            />
        </svg>
    );
};
export default Doorbell;
