import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { IconButton } from 'lib/@mui';
import { PhonelinkSetup } from 'lib/@mui/Icons';

import { getSumInstalledPlugins } from '../../utils';

import styles from '../../PluginStyled.module.scss';

const CountInstalledPlugin = (props) => {
    const { privatePlugins, listInstalledPlugins } = useSelector((state) => state.plugins);

    const { onClick } = props;
    const { row } = props.params || {};

    const countInstalledPlugin = useMemo(() => {
        return getSumInstalledPlugins(privatePlugins, listInstalledPlugins);
    }, [listInstalledPlugins, privatePlugins]);

    const handleClick = () => {
        onClick(row.original);
    };

    return (
        <div className={styles.pluginsListCount}>
            <span className={styles.pluginsListSumPlugins}>
                {countInstalledPlugin[`${row?.original?.nameId + '_' + row?.original?.version}`]}
            </span>
            <IconButton onClick={handleClick}>
                <PhonelinkSetup />
            </IconButton>
        </div>
    );
};

export default CountInstalledPlugin;
