import React from 'react';

const IcActionsPanicButton = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
            <path d="M25 19H7C5.3 19 4 20.3 4 22V27H28V22C28 20.3 26.7 19 25 19Z" fill="#1C1E21" />
            <path d="M16 6C11.6 6 8 9.6 8 14V16H24V14C24 9.6 20.4 6 16 6Z" fill="#1C1E21" />
        </svg>
    );
};

export default IcActionsPanicButton;
