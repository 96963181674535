import React from 'react';
import { useTranslate } from '../../../../features/languages';
import {
    EZLOGIC_TITLE_FAILED_TO_GET_BACKUPS,
    EZLOGIC_TITLE_HINT_TRY_TO_USE_SHORTER_DATE_RANGE,
} from '../../../../constants/language_tokens';
import { toast, TOAST_TYPE } from '../../../../components/Toast';

const BackupsToastErrorMessage = (props) => {
    const { t } = useTranslate();

    return (
        <div>
            <div>{t(EZLOGIC_TITLE_FAILED_TO_GET_BACKUPS) + ' ' + props.error.message}</div>
            <div>{t(EZLOGIC_TITLE_HINT_TRY_TO_USE_SHORTER_DATE_RANGE)}</div>
        </div>
    );
};

export default BackupsToastErrorMessage;

export const displayBackupsErrorToast = (error) => {
    toast(<BackupsToastErrorMessage error={error} />, { type: TOAST_TYPE.ERROR, autoClose: false });
};
