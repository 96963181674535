import { useEffect } from 'react';

import { ZERO_INT } from 'constants/MeshbotConstant';

/**
 * Hook to scroll to the top of a table or container when a dependency changes.
 *
 * @param {React.RefObject} ref - The reference to the table or container element.
 * @param {number} pageIndex - The value to watch for changes that triggers the scroll.
 */
const useScrollToTop = (ref, pageIndex) => {
    useEffect(() => {
        if (ref?.current && ref?.current?.scrollTop !== ZERO_INT) {
            ref.current.scrollTop = ZERO_INT;
        }
    }, [ref, pageIndex]);
};

export default useScrollToTop;
