export const EXCLUDED_BUTTON = 'ezlo.device_groups.excluded_button';
export const INCLUDED_BUTTON = 'ezlo.device_groups.included_button';
export const INHERITED_BUTTON = 'ezlo.device_groups.inherited_button';
export const TREE_TITLE = 'ezlo.device_groups.tree.title';
export const TOOLTIP = 'ezlo.device_groups.tooltip';
export const EDIT_PAGE = 'ezlo.device_groups.edit.title';
export const CREATE_PAGE = 'ezlo.device_groups.create.title';
export const INPUT_PLACEHOLDER = 'ezlo.device_groups.name_input.placeholder';
export const LISTING_PAGE = 'ezlo.device_groups.listing_page.title';
export const CREATE_BUTTON = 'ezlo.device_groups.create_button';
