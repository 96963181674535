import React from 'react';
import { useSelector } from 'react-redux';

import { useTranslate } from '../../../../../languages';
import useKvsActions from '../../../../../../hooks/useKvsActions';
import {
    EZLOGIC_SUBTITLE_DELETE_LABEL_DIALOG,
    EZLOGIC_TITLE_DELETE,
    EZLOGIC_TITLE_DELETE_LABEL_DIALOG,
} from '../../../../../../constants/language_tokens';
import DialogWithIconTitleSubTitleAndFullWidthButton from '../../../../../../components/DialogWithIconTitleSubTitleAndFullWidthButton';
import { IcWarningDelete } from '../../../../../../assets/icons';
import { kvsIsLoadingSelector } from '../../../utils';

import styles from './styles.module.scss';

const DeleteLabelDialog = ({ dialogProps, labelUuid }) => {
    const { t } = useTranslate();
    const { deleteMeshBotLabel } = useKvsActions();
    const { isLoading } = useSelector(kvsIsLoadingSelector);

    const deleteSelectedLabel = async () => {
        await deleteMeshBotLabel(labelUuid);
    };

    const dialogTypography = {
        title: t(EZLOGIC_TITLE_DELETE_LABEL_DIALOG),
        subTitle: t(EZLOGIC_SUBTITLE_DELETE_LABEL_DIALOG),
        buttonText: t(EZLOGIC_TITLE_DELETE),
    };

    return (
        <DialogWithIconTitleSubTitleAndFullWidthButton
            {...dialogProps}
            {...dialogTypography}
            icon={<IcWarningDelete />}
            onButtonClick={deleteSelectedLabel}
            buttonProps={{ className: styles.deleteButton }}
            linearProgressProps={{ className: styles.linearProgress }}
            isLinearProgress={isLoading}
        />
    );
};

export default DeleteLabelDialog;
