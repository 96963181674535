import React from 'react';

export const IcVisualsCopyDocs = (props) => {
    return (
        <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                opacity="0.25"
                d="M48.8999 50.9C42.4999 52.6 38.6999 59.2 40.3999 65.6L61.0999 142.9C62.7999 149.3 69.3999 153.1 75.7999 151.4L129.9 136.9C136.3 135.2 140.1 128.6 138.4 122.2L117.7 44.9C116 38.5 109.4 34.7 103 36.4L48.8999 50.9Z"
                fill="#3E46AE"
            />
            <path
                opacity="0.25"
                d="M96.4998 50.9C90.0998 49.2 83.4998 53 81.7998 59.4L61.0998 136.7C59.3998 143.1 63.1998 149.7 69.5998 151.4L123.7 165.9C130.1 167.6 136.7 163.8 138.4 157.4L159.1 80C160.8 73.6 157 67 150.6 65.3L96.4998 50.9Z"
                fill="#3E46AE"
            />
            <path
                d="M116.7 47.8H72.7C66.1 47.8 60.7 53.2 60.7 59.8V139.8C60.7 146.4 66.1 151.8 72.7 151.8H128.7C135.3 151.8 140.7 146.4 140.7 139.8V71.8L116.7 47.8Z"
                fill="#3E46AE"
            />
            <path d="M140.7 90.5V71.8L119.3 69.1L140.7 90.5Z" fill="#1F2581" />
            <path d="M125 108H77V116H125V108Z" fill="#1F2581" />
            <path d="M116.7 47.8L140.7 71.8H125.7C120.7 71.8 116.7 67.8 116.7 62.8V47.8Z" fill="#FE5C15" />
            <path
                d="M116.7 47.8L128.7 59.8L140.7 71.8H133.2H125.7C123.2 71.8 121 70.8 119.3 69.2C117.7 67.6 116.7 65.3 116.7 62.8V55.3V47.8Z"
                fill="#FE5C15"
            />
            <path d="M111 96H77V104H111V96Z" fill="white" />
        </svg>
    );
};
