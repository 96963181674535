import React from 'react';

export const Blinds = (props) => {
    return (
        <svg {...props} viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M28 18H26V4C27.1 4 28 4.9 28 6V18ZM28 23V21H26V23C26 23.6 26.4 24 27 24C27.6 24 28 23.6 28 23ZM24 9H0C0 10.7 1.3 12 3 12H21C22.7 12 24 10.7 24 9ZM24 15H0C0 16.7 1.3 18 3 18H21C22.7 18 24 16.7 24 15ZM24 21H0C0 22.7 1.3 24 3 24H21C22.7 24 24 22.7 24 21ZM24 3C24 1.3 22.7 0 21 0H3C1.3 0 0 1.3 0 3V6H24V3ZM24 3C24 1.3 22.7 0 21 0H3C1.3 0 0 1.3 0 3V6H24V3Z" />
        </svg>
    );
};
export default Blinds;
