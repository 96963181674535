import { ADVANCED_SCENES } from 'constants/ControllerFeatures';
import { MINUS_ONE_INT, ZERO_INT } from 'constants/MeshbotConstant';
import { EZLOGIC_TITLE_COPY_OF } from 'constants/language_tokens';
import { MESHBOT_TYPES } from 'containers/Ezlo/EzloMeshbots/constants';
import { compareAdvancedScenesVersion } from 'helpers/controllers';

/**
 * Extracts relevant fields for a duplicate meshbot from the provided meshbot data.
 *
 * @param {Object} meshbotData - The original meshbot data.
 * @returns {Object} - The extracted duplicate meshbot fields.
 * @example
 * const meshbotData = { id: '123', name: 'Original Meshbot', type: 'local', serial: '12345' };
 * const result = extractDuplicateMeshbotData(meshbotData);
 * // Returns { meshBotId: '123', meshBotName: 'Copy of Original Meshbot', type: 'local', serial: '12345', isDuplicate: true, editAfterDuplicate: false }
 */
export const extractDuplicateMeshbotData = (meshbotData, t) => {
    const { id = '', name = '', type = '', serial = '' } = meshbotData || {};

    const duplicateMeshbotFields = {
        meshBotId: id || '',
        meshBotName: `${t(EZLOGIC_TITLE_COPY_OF)} ${name || ''}`,
        type: type || '',
        serial: serial || '',
        isDuplicate: true,
        editAfterDuplicate: false,
    };

    return duplicateMeshbotFields;
};

/**
 * Retrieves cloud meshbot data from the provided response.
 *
 * @param {Object} response - The response object.
 * @returns {Object} - The cloud meshbot data.
 * @example
 * const response = { status: 200, data: { key: 'value' } };
 * const result = getCloudMeshbotData(response);
 * // Returns { key: 'value' }
 */
export const getCloudMeshbotData = (response) => {
    if (!response || response?.status === ZERO_INT || !response?.data) {
        return {};
    }

    return response?.data;
};

/**
 * Creates an updated payload for a duplicate cloud meshbot based on the provided cloud meshbot payload and name.
 *
 * @param {Object} cloudMeshbotPayload - The original cloud meshbot payload.
 * @param {string} duplicateMeshbotName - The name for the duplicate meshbot.
 * @returns {Object} - The updated cloud meshbot payload.
 * @example
 * const cloudMeshbotPayload = { enabled: 1, triggers: { trigger1: 'value1' }, actions: ['action1'], meta: { key: 'value' } };
 * const duplicateMeshbotName = 'Test Meshbot';
 * const result = getUpdatedCloudDuplicateMeshbotPayload(cloudMeshbotPayload, duplicateMeshbotName);
 * // Returns { name: 'Test Meshbot', enabled: 1, triggers: { trigger1: 'value1' }, actions: ['action1'], meta: { key: 'value' } }
 */
export const getUpdatedCloudDuplicateMeshbotPayload = (cloudMeshbotPayload, duplicateMeshbotName) => {
    const payload = {
        name: duplicateMeshbotName || '',
        enabled: cloudMeshbotPayload?.enabled || 0,
        triggers: cloudMeshbotPayload?.triggers || {},
        actions: cloudMeshbotPayload?.actions || [],
        meta: cloudMeshbotPayload?.meta || {},
    };

    return payload;
};

/**
 * Updates the name and uuid in the provided cloud meshbot data.
 *
 * @param {Object} cloudMeshBotData - The original cloud meshbot data.
 * @param {string} name - The new name for the meshbot.
 * @param {string} uuid - The new uuid for the meshbot.
 * @returns {Object} - The updated cloud meshbot data.
 * @example
 * const cloudMeshBotData = { name: 'OldName', uuid: '1234', otherProperty: 'SomeValue' };
 * const newName = 'NewName';
 * const newUuid = '5678';
 * const result = getCloudMeshbotDataWithUpdatedName(cloudMeshBotData, newName, newUuid);
 * // Returns { name: 'NewName', uuid: '5678', otherProperty: 'SomeValue' }
 */
export const getCloudMeshbotDataWithUpdatedName = (cloudMeshBotData, name, uuid) => {
    if (!cloudMeshBotData) {
        return {};
    }

    if (name && uuid) {
        return {
            ...cloudMeshBotData,
            name,
            uuid,
        };
    }

    return cloudMeshBotData;
};

/**
 * Determines whether the tooltip should be shown for a local meshbot duplcate icon based on parameters and Ezlo data.
 *
 * @param {Object} params - The parameters for the meshbot.
 * @param {Object} ezloData - The Ezlo data.
 * @returns {boolean} - Whether the tooltip should be shown.
 *
 * The function checks if the meshbot is local and if its advanced version is less than 1.70.
 * Returns true only when both conditions are met, indicating that the tooltip should be shown.
 *
 * @example
 * const params = { type: MESHBOT_TYPES.LOCAL };
 * const ezloData = { features: { advanced_scenes: { version: '1.69' } } };
 * const result = shouldShowTooltipForLocalMeshbot(params, ezloData);
 * // Returns true
 */
export const shouldShowTooltipForLocalMeshbot = (params, ezloData) => {
    const isLocalMeshbot = params?.type === MESHBOT_TYPES.LOCAL;
    const advancedVersion = ezloData?.features?.advanced_scenes?.version;

    return (
        isLocalMeshbot && compareAdvancedScenesVersion(advancedVersion, ADVANCED_SCENES.VERSION_1_70) === MINUS_ONE_INT
    );
};

/**
 * Determines whether the duplicate button should be enabled based on the provided name and duplicate meshbot state.
 *
 * @param {string} name - The name of the meshbot.
 * @param {Object} duplicateMeshbotState - The state of the duplicate meshbot.
 * @returns {boolean} - Whether the duplicate button should be enabled.
 * @example
 * const result = isDuplicateButtonEnabled('MeshbotName', { type: MESHBOT_TYPES.CLOUD, cloudMeshbotPayload: {} });
 * // Returns true
 */
export const isDuplicateButtonEnabled = (name, duplicateMeshbotState = {}) => {
    const isMeshbotNameNotEmpty = !!name?.length;
    const isCloudMeshbotPayloadNotEmpty = !checkCloudMeshbotPayloadEmpty(duplicateMeshbotState);
    const isButtonEnabled = !(duplicateMeshbotState?.isButtonDisabled ?? false);

    return isMeshbotNameNotEmpty && isCloudMeshbotPayloadNotEmpty && isButtonEnabled;
};

/**
 * Checks if the given meshbot type is a cloud meshbot type.
 *
 * @param {string} meshbotType - The type of the meshbot.
 * @returns {boolean} - Whether the meshbot is a cloud meshbot type.
 * @example
 * const result = checkCloudMeshbotType(MESHBOT_TYPES.CLOUD);
 * // Returns true
 */
const checkCloudMeshbotType = (meshbotType) => {
    return (
        meshbotType &&
        [MESHBOT_TYPES.CLOUD, MESHBOT_TYPES.CLOUD_INTERACTION, MESHBOT_TYPES.CLOUD_NOTIFICATION].includes(meshbotType)
    );
};

/**
 * Checks if the cloud meshbot payload is empty based on the provided duplicate meshbot state.
 *
 * @param {Object} duplicateMeshBotState - The state of the duplicate meshbot.
 * @returns {boolean} - Whether the cloud meshbot payload is empty.
 * @example
 * const result = checkCloudMeshbotPayloadEmpty({ type: MESHBOT_TYPES.CLOUD, cloudMeshbotPayload: {} });
 * // Returns true
 */
export const checkCloudMeshbotPayloadEmpty = (duplicateMeshBotState) => {
    const isCloudMeshbotType = checkCloudMeshbotType(duplicateMeshBotState?.type);

    return (
        isCloudMeshbotType &&
        (!duplicateMeshBotState?.cloudMeshbotPayload ||
            Object.keys(duplicateMeshBotState?.cloudMeshbotPayload).length === ZERO_INT)
    );
};

/**
 * Determines if the given meshbot state represents a cloud meshbot.
 *
 * @param {Object} duplicateMeshbotState - The state of the duplicate meshbot.
 * @returns {boolean} - Whether the meshbot is a cloud meshbot.
 * @example
 * const result = isCloudMeshbot({ meshBotId: '123', type: MESHBOT_TYPES.CLOUD });
 * // Returns true
 */
export const isCloudMeshbot = (duplicateMeshbotState) =>
    (duplicateMeshbotState?.meshBotId && duplicateMeshbotState?.type === MESHBOT_TYPES.CLOUD) ||
    MESHBOT_TYPES.CLOUD_INTERACTION ||
    MESHBOT_TYPES.CLOUD_NOTIFICATION;

/**
 * Creates a payload for a duplicate local meshbot based on the provided duplicate meshbot state and name.
 *
 * @param {Object} duplicateMeshbotState - state of the duplicate meshbot.
 * @param {string} duplicateMeshbotName - name for the duplicate meshbot.
 * @returns {Object} - The payload for the duplicate local meshbot.
 * @example
 * const duplicateMeshbotState = { meshBotId: '123' };
 * const duplicateMeshbotName = 'Test Meshbot';
 * const result = createDuplicateLocalMeshbotPayload(duplicateMeshbotState, duplicateMeshbotName);
 * // Returns { _id: '123', name: 'Test Meshbot' }
 */
export const createDuplicateLocalMeshbotPayload = (duplicateMeshbotState, duplicateMeshbotName) => ({
    _id: duplicateMeshbotState?.meshBotId || '',
    name: duplicateMeshbotName || '',
});

export default {
    createDuplicateLocalMeshbotPayload,
    isCloudMeshbot,
    isDuplicateButtonEnabled,
    shouldShowTooltipForLocalMeshbot,
    getCloudMeshbotDataWithUpdatedName,
    getCloudMeshbotData,
    extractDuplicateMeshbotData,
    checkCloudMeshbotPayloadEmpty,
};
