import {
    CONTROLLER_CAPABILITY_RESET,
    INDEX_SELECTED_BLOCKS_ELEMENT,
    DELAY,
} from '../../../../../constants/MeshbotConstant';
import { TYPE, ENUM } from '../../../EzloRule/EditForm/RuleSettings/components/PAAS/paas-constants';

export const generateControllerNodeActionFields = (blockType, methodName, isShowedDelay) => ({
    blockOptions: {
        method: {
            name: methodName,
            args: {},
        },
    },
    blockType: blockType,
    fields: [],
    ...(isShowedDelay === DELAY && { delay: { days: 0, hours: 0, minutes: 0, seconds: 0 } }),
});

export const generateControllerNodeResetCapabilityFields = (blockType, value, isShowedDelay) => ({
    blockOptions: {
        method: {
            name: CONTROLLER_CAPABILITY_RESET,
            args: {
                type: TYPE,
            },
        },
    },
    blockType: blockType,
    fields: [
        {
            name: TYPE,
            type: ENUM,
            value: value,
        },
    ],
    ...(isShowedDelay === DELAY && { delay: { days: 0, hours: 0, minutes: 0, seconds: 0 } }),
});

export const getControllerNodeValue = (controllerBlock) => {
    return controllerBlock?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT]?.fields?.find((field) => field.value)?.value;
};
