import { createSlice } from '@reduxjs/toolkit';
import { LOGOUT } from '../constants/ActionTypes';

export const LABELS = 'labels';
export const SELECTED_LABEL_UUID_FOR_FILTERING = 'selectedLabelUuidForFiltering';
export const NEW_ADDED_LABELS_UUIDS_BY_MESHBOTS = 'newAddedLabelsUuidsByMeshbots';

const initialState = {
    [SELECTED_LABEL_UUID_FOR_FILTERING]: null,
    [NEW_ADDED_LABELS_UUIDS_BY_MESHBOTS]: {},
};

const labels = createSlice({
    name: LABELS,
    initialState,
    reducers: {
        setLabelUuidForFiltering: (state, action) => {
            state[SELECTED_LABEL_UUID_FOR_FILTERING] = action.payload;
        },
        setNewLabelsUuidByMeshBots: (state, action) => {
            const { meshBotUuid, labelUuid } = action.payload;
            if (state[NEW_ADDED_LABELS_UUIDS_BY_MESHBOTS].hasOwnProperty(meshBotUuid)) {
                state[NEW_ADDED_LABELS_UUIDS_BY_MESHBOTS][meshBotUuid].push(labelUuid);
            } else {
                state[NEW_ADDED_LABELS_UUIDS_BY_MESHBOTS][meshBotUuid] = [labelUuid];
            }
        },
        clearLabelsUuidByMeshBots: (state, action) => {
            const { meshBotUuid, newLabelsUuids } = action.payload;
            const allLabels = state[NEW_ADDED_LABELS_UUIDS_BY_MESHBOTS][meshBotUuid];
            state[NEW_ADDED_LABELS_UUIDS_BY_MESHBOTS][meshBotUuid] = allLabels.filter(
                (labelUuid) => !newLabelsUuids.includes(labelUuid),
            );
        },
    },
    extraReducers: (builder) => {
        builder.addCase(LOGOUT, () => initialState);
    },
});

export const { setLabelUuidForFiltering, setNewLabelsUuidByMeshBots, clearLabelsUuidByMeshBots } = labels.actions;
export default labels;
export const labelsReducer = labels.reducer;
