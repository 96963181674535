import { createSlice } from '@reduxjs/toolkit';

import { LOGOUT } from '../../../constants/ActionTypes';
import { INSTALLER_ACCESS } from '../constants';

const initialState = {
    installerAccessStatus: null,
    initialAccessStatus: null,
    installerAccessRightDeadlineData: '',
    isLoading: false,
    isInstallerLoggedInAsResident: false,
};

const InstallerAccessRights = createSlice({
    name: INSTALLER_ACCESS,
    initialState,
    reducers: {
        setInstallerAccessStatus: (state, action) => {
            state.installerAccessStatus = action.payload;
        },
        setInitialAccessStatus: (state, action) => {
            state.initialAccessStatus = action.payload;
        },
        setIsInstallerLoggedInAsResident: (state, action) => {
            state.isInstallerLoggedInAsResident = action.payload;
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        updateInstallerAccessRightsDeadlineData: (state, action) => {
            state.installerAccessRightDeadlineData = action.payload;
        },
        resetInstallerAccessState: (state) => {
            state.installerAccessStatus = null;
            state.installerAccessRightDeadlineData = '';
            state.isLoading = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(LOGOUT, () => initialState);
    },
});

export const {
    extraReducers,
    setInstallerAccessStatus,
    updateInstallerAccessRightsDeadlineData,
    setIsLoading,
    resetInstallerAccessState,
    setIsInstallerLoggedInAsResident,
    setInitialAccessStatus,
} = InstallerAccessRights.actions;

export default InstallerAccessRights;
export const installerAccessStoreReducer = InstallerAccessRights.reducer;
