import produce from 'immer';
import at from '../constants/ActionTypes/Devices';
import { LOGOUT } from '../constants/ActionTypes';

const INITIAL_STATE = {
    errors: null,
    isLoading: false,
};

export default produce((draft, action) => {
    switch (action.type) {
        case at.DEVICE_FORCE_REMOVE.rejected:
            draft.errors = action.payload;
            break;

        case at.CLEAR_ERRORS:
            draft.errors = null;
            break;
        case at.CREATE_ASSOCIATION.pending:
            draft.isLoading = true;
            break;
        case at.CREATE_ASSOCIATION.success:
            draft.isLoading = false;
            break;
        case LOGOUT:
            return INITIAL_STATE;
            break;

        default:
            break;
    }
}, INITIAL_STATE);
