import React from 'react';

export const OtherCategory = (props) => (
    <svg {...props} viewBox="0 0 56 56" fill="none">
        <circle cx="19.5" cy="28.5" r="2.5" />
        <circle cx="28.5" cy="28.5" r="2.5" />
        <circle cx="37.5" cy="28.5" r="2.5" />
    </svg>
);

export default OtherCategory;
