import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Header } from 'components';
import EzlosDropdown from 'containers/EzlosDropdown';
import { ConfirmModal } from 'components/ConfirmationModal/ConfirmationModal';

import { CONTINUE } from 'constants/ComponentConstants';
import { EzloActions } from 'actions';
import { isControllersSelectVisible } from '../../utils';

import { useTranslate } from 'features/languages';
import {
    EZLOGIC_HEADING_CHANGE_THE_CONTROLLER,
    EZLOGIC_TITLE_LOSE_YOUR_CURRENT_PROGRESS,
} from 'constants/language_tokens';
import { EZLOGIC_TITLE_SYSTEM_HISTORY } from '../../constants';

import styles from './systemHistoryHeader.module.scss';
const { controllerWrapper, hide } = styles;

const SystemHistoryHeaderContainer = () => {
    const dispatch = useDispatch();
    const controllers = useSelector((state) => state.ezlos.items);
    const [newSerial, setNewSerial] = useState('');
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const { t } = useTranslate();

    useEffect(() => {
        if (isConfirmed) {
            dispatch(EzloActions.selectController(newSerial));
            setIsConfirmed(false);
        }
    }, [isConfirmed]);

    const handleConfirmation = () => {
        setIsConfirmed(true);
        setIsConfirmModalVisible(false);
    };

    const handleCancel = () => {
        setIsConfirmed(false);
        setIsConfirmModalVisible(false);
    };

    const openConfirmModal = (newSerial) => {
        setNewSerial(newSerial);
        setIsConfirmModalVisible(true);
    };

    return (
        <Header>
            <h1>{t(EZLOGIC_TITLE_SYSTEM_HISTORY)}</h1>
            <div className={`${controllerWrapper} ${!isControllersSelectVisible(controllers) && hide}`}>
                <EzlosDropdown openConfirmModal={openConfirmModal} isAlertsHistory={true} />

                <ConfirmModal
                    isOpened={isConfirmModalVisible}
                    onClose={setIsConfirmModalVisible}
                    title={t(EZLOGIC_HEADING_CHANGE_THE_CONTROLLER)}
                    text={t(EZLOGIC_TITLE_LOSE_YOUR_CURRENT_PROGRESS)}
                    confirmButtonTitle={t(CONTINUE)}
                    onCancel={handleCancel}
                    onConfirm={handleConfirmation}
                />
            </div>
        </Header>
    );
};

export default SystemHistoryHeaderContainer;
