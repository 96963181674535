import React from 'react';

export const IcActionsBrowser = (props) => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <circle cx="18" cy="18" r="8.35714" fill="#1C1E21" />
            <circle cx="18" cy="18" r="6.42857" fill="white" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.1787 10.7625L15.7732 16.7143L20.2271 19.2857L22.1787 10.7625ZM15.7729 16.7143L20.2268 19.2857L13.8213 25.2375L15.7729 16.7143ZM18.0763 19.5288L16.0072 21.4513L16.6377 18.6981L18.0763 19.5288Z"
                fill="#1C1E21"
            />
            <ellipse cx="23.7857" cy="18" rx="0.642858" ry="0.642857" fill="#1C1E21" />
            <ellipse cx="12.2143" cy="18" rx="0.642857" ry="0.642857" fill="#1C1E21" />
            <ellipse cx="18" cy="12.2143" rx="0.642857" ry="0.642857" fill="#1C1E21" />
            <ellipse cx="18" cy="23.7857" rx="0.642857" ry="0.642858" fill="#1C1E21" />
        </svg>
    );
};
