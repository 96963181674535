import _ from 'lodash';
import * as at from '../constants/ActionTypes/ItemGroups';
import { bugsnagNotifyWrapper } from '../containers/ErrorBoundary/utils';
import wsm from '../helpers/wsm';
import {
    setMeshBotV2LocalPreprocessingItemGroupsCapabilitiesList as setCapabilitiesList,
    setMeshBotV2LocalFlagsItemGroupsStatus as setItemGroupsFlagStatus,
    updateMeshBotV2LocalItemGroupsContainerCapabilityStatus as updateCapabilityStatus,
} from '../reducers/meshBotV2';
import { CAPABILITY_VALUE_TYPE, ITEM_GROUPS_STATUS } from '../constants/ItemGroups';
import {
    isItemGroupInList,
    updateWhenBlocks,
    defineCapabilitiesGeneralStatus,
} from '../containers/Ezlo/EzloMeshbot/MeshbotLocal/MeshBotLocalForm/utils';
import { toast, TOAST_TYPE } from '../components/Toast';

const actions = {
    fetchItemGroupsList: (serial) => async (dispatch) => {
        await wsm.send(
            serial,
            'hub.item.groups.list',
            {},
            (data) => {
                dispatch({ type: at.FETCH_ITEM_GROUPS_LIST.success, data, serial });
            },
            (error) => {
                bugsnagNotifyWrapper(error, { type: at.FETCH_ITEM_GROUPS_LIST.rejected });
            },
        );
    },

    setCapabilitiesList: (data) => setCapabilitiesList(data),

    setItemGroupsFlagStatus: (value) => setItemGroupsFlagStatus(value),

    onError: (error) => (dispatch) => {
        toast(error.message, { type: TOAST_TYPE.ERROR });
        dispatch(actions.setItemGroupsFlagStatus(ITEM_GROUPS_STATUS.DEFAULT));
    },

    createItemGroup: (serial, params, onSuccess, onError) => async () => {
        await wsm.send(
            serial,
            'hub.item.group.create',
            params,
            () => {},
            (error) => {
                onError(error);
            },
        );
    },

    createMissingItemGroups: () => (dispatch, getState) => {
        const {
            ezlo: { serial },
            meshBotV2: {
                local: {
                    preprocessingDataContainer: {
                        itemGroupsContainer: { capabilitiesList },
                    },
                },
            },
        } = getState();
        const onError = (error) => dispatch(actions.onError(error));

        Object.values(capabilitiesList).map((capability) => {
            const params = {
                name: capability.name,
                itemNames: [capability.name],
                valueType: capability.valueType,
                enum: capability.valueType === CAPABILITY_VALUE_TYPE.TOKEN ? capability.enum : [],
            };

            return dispatch(actions.createItemGroup(serial, params, null, onError));
        });
    },

    setMeshBotLocalItemGroupsList: (itemGroup, serial) => ({
        type: at.SET_ITEM_GROUP.success,
        itemGroup,
        serial,
    }),

    addItemGroupInLocalMeshBot: (response) => (dispatch, getState) => {
        const {
            ezlo: { serial, data },
        } = getState();

        if (!isItemGroupInList(data, serial, response)) {
            dispatch(actions.setMeshBotLocalItemGroupsList(response.result, serial));
        }
    },

    setIdItemGroupInWhenBlockLocalMeshBot: (response) => (dispatch, getState) => {
        const state = getState();
        const { capabilitiesList } = state.meshBotV2.local.preprocessingDataContainer.itemGroupsContainer;
        const whenBlocks = _.cloneDeep(state.meshBot.local.selectedRule.when);

        updateWhenBlocks(whenBlocks, capabilitiesList, response);

        dispatch({
            type: at.SET_ITEM_GROUP_ID.success,
            whenBlocks,
        });
    },

    updateMeshBotV2LocalFlagsItemGroupsStatus: () => (dispatch, getState) => {
        const {
            meshBotV2: {
                local: {
                    preprocessingDataContainer: {
                        itemGroupsContainer: { capabilitiesList },
                    },
                },
            },
        } = getState();

        const isCapabilitiesGeneralStatus = defineCapabilitiesGeneralStatus(capabilitiesList);

        if (!isCapabilitiesGeneralStatus) {
            dispatch(actions.setItemGroupsFlagStatus(ITEM_GROUPS_STATUS.COMPLETED));
        }
    },

    processCreatedItemGroupForLocalMeshBot: (response) => (dispatch) => {
        dispatch(actions.addItemGroupInLocalMeshBot(response));
        dispatch(actions.setIdItemGroupInWhenBlockLocalMeshBot(response));
        dispatch(updateCapabilityStatus(response.result.name));
        dispatch(actions.updateMeshBotV2LocalFlagsItemGroupsStatus());
    },

    subscribeItemGroupCreated: (serial, cb) => () => {
        wsm.subscribe(serial, 'hub.item.group.created', cb);
    },

    unsubscribeItemGroupCreated: (serial) => () => {
        wsm.unsubscribe(serial, 'hub.item.group.created');
    },
};

export default actions;
