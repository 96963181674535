export const NAME_REGEXP = /^[a-zA-Z0-9 ,.'-]+$/i;
export const PASSWORD_REGEXP = /^([a-zA-Z0-9&*!#$%+\/=?^_`'{|}~\-@,;\\.\(\)\:\[\]]){8,100}$/;
export const EMAIL_REGEXP =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const SERIAL_REGEXP = /^[a-z0-9]{16}$/;
export const WIFI_SSID_REGEXP = /^[a-zA-Z0-9$@^`,|%;.~\(\)\/\\{\}:\?\[\]=\-\+_#!*"&<>]{1,32}$/;
export const WIFI_PASSWORD_REGEXP = /^[a-zA-Z0-9$@^`,|%;.~\(\)\/\\{\}:\?\[\]=\-\+_#!*"&<>]{8,32}$/;
export const NON_LATIN_CHARS = /[^a-zA-Z0-9$&+.,:;=?@#|'"`<> ^*()_[\]{}\\/%!~-]+/g;
export const DEVICE_NAME = /[^a-zA-Z0-9\s-]+/g;
export const DUBL_SPACE = /\s{2}/g;
export const DSK_KEY_REGEXP = /\d{5}/g;
export const atomRE = /^0000/;

/**
 * Regexp to define is controller serial number supported.
 * Supports serials which start from 7, 8, 9 and 10.
 */
export const SUPPORTED_CONTROLLER_SERIAL_NUMBER_REGEXP = /^([789]\d{7,})|(10\d{7,})/;
