import { createTheme } from '../../@mui/styles';
import { MAIN_FONT_FAMILY } from '../../../containers/cutomThemeColors';

export const getCustomTableTheme = (globalTheme) =>
    createTheme({
        ...globalTheme,
        components: {
            ...globalTheme.components,
            MuiSwitch: {
                ...globalTheme.components.MuiSwitch,
                defaultProps: {
                    size: 'small',
                },
            },
            MuiTypography: {
                styleOverrides: {
                    root: {
                        fontSize: '14px',
                        fontFamily: MAIN_FONT_FAMILY,
                        fontWeight: 400,
                    },
                },
            },
            MuiTooltip: {
                defaultProps: {
                    placement: 'bottom-end',
                },
            },
        },
    });
