import { throttleManager } from '../../index';
import { store } from '../../../../store/configureStore';
import { isString, isObject } from 'lodash';
import { INTERNAL_EZLOGIC_DOMAIN_URLS } from '../../../../constants/URLs';

/**
 * Generate string from arguments
 * @param {string[]} argumentsArray - An array of strings to be combined into a single string
 * @param {string} separator - separator
 * @returns {string} A string of array values separated by a separator
 * @example
 * generateStringFromArguments(['one', 'some'], '_')
 * */
export function generateStringFromArguments(argumentsArray, separator = '') {
    return argumentsArray.join(separator);
}
/**
 * Provides the Throttle function for thunk creator Fn
 * @param {{id: String, timeout: Number, thunkCreatorFn: Function}} params - id: Unique ID under which the function is stored, timeout: Delay in calling thunkCreatorFn, thunkCreatorFn: The thunkCreatorFn name that will call by throttle fn
 * @returns {Function} throttle fn for thunkCreatorFn
 * @example
 * throttleThunk({id: '412fdevgb412', timeout: 5000, thunkCreatorFn: someThunkCreatorFn})
 * */
export function throttleThunk({ id, timeout, thunkCreatorFn }) {
    const throttleCb = (payload) => store.dispatch(thunkCreatorFn(payload));

    return throttleManager.initializeThrottle(id, throttleCb, timeout);
}
/**
 * Provides the Throttle function
 * @param {{id: String, timeout: Number, cb: Function}} params - id: Unique ID under which the function is stored, timeout: Delay in calling cb, cb: The function that will call by throttle fn
 * @returns {Function} throttle fn
 * @example
 * throttle({id: '412fdevgb412', timeout: 5000, cb: someFn})
 * */
export function throttle({ id, timeout, cb }) {
    return throttleManager.initializeThrottle(id, cb, timeout);
}

/**
 * Filters the list of objects by search value
 * @param {String} searchValue - The value of the search
 * @param {Object[]} list - A list in which we will search for elements by search value
 * @param {String} searchByProperty - On what property in the object we will filter list
 * @returns {Object[]} Felted list
 * @example
 * filterObjectsListBySearchValue('Lab', [{name: 'Label'}, {name: 'Hello'}], 'name')
 * */
export const filterObjectsListBySearchValue = (searchValue, list = [], searchByProperty) => {
    if (!searchValue || !searchByProperty) {
        return list;
    }

    return list.filter((option) => {
        return option[searchByProperty].toLowerCase().includes(searchValue.toLowerCase());
    });
};

/**
 * Sorts the list of objects alphabetically
 * @param {Object[]} list - A list in which we will search for elements by search value
 * @param {String} sortByProperty - On what property in the object we will sort list
 * @returns {Object[]} sorted list
 * @example
 * sortObjectsListAlphabetically('Lab', [{name: 'Label'}, {name: 'Hello'}], 'name')
 * */
export const sortObjectsListAlphabetically = (list = [], sortByProperty) => {
    if (!sortByProperty) {
        return list;
    }

    return list.sort((a, b) => {
        const fieldA = a[sortByProperty].toUpperCase();
        const fieldB = b[sortByProperty].toUpperCase();
        if (fieldA < fieldB) {
            return -1;
        }

        if (fieldA > fieldB) {
            return 1;
        }

        return 0;
    });
};
/**
 * Checks if the given item can be parsed as JSON and represents a valid JSON object.
 * If the input item is not a string, it will be converted to a JSON string representation.
 *
 * @param {any} item - The item to be checked for JSON validity.
 * @returns {boolean} True if the item is a valid JSON object, false otherwise.
 *
 * @example
 * const validJson = { key: "value" };
 * const validJsonStr = '{"key":"value"}';
 * const invalidJson = "invalid";
 *
 * console.log(isJson(validJson)); // Output: true
 * console.log(isJson(validJsonStr)); // Output: true
 * console.log(isJson(invalidJson)); // Output: false
 */
export function isJson(item) {
    let value = typeof item !== 'string' ? JSON.stringify(item) : item;
    try {
        value = JSON.parse(value);
    } catch (e) {
        return false;
    }

    return typeof value === 'object' && value !== null;
}

/**
 * Checks if the given item can be parsed as JSON
 *
 * @param {string} item - The item to be checked for JSON validity.
 * @returns {boolean} True if the item is a valid JSON string to parse, false otherwise.
 *
 * @example
 * const validJsonStr = '{"key":"value"}';
 * const invalidJson = "invalid";
 *
 * console.log(isJsonToParse(validJsonStr)); // Output: true
 * console.log(isJsonToParse(invalidJson)); // Output: false
 */
export function isJsonToParse(item) {
    try {
        JSON.parse(item);
    } catch (e) {
        return false;
    }

    return true;
}

export function findParentAttributeValueByName(htmlElement, searchParentAttributeName) {
    // Start from the current element and traverse up the DOM tree
    while (htmlElement) {
        // Check if the current element has a data-meshbotId attribute
        if (htmlElement.hasAttribute(searchParentAttributeName)) {
            // Found an ancestor with the attribute, return it
            return JSON.parse(htmlElement.getAttribute(searchParentAttributeName));
        }
        // Move up to the parent element
        htmlElement = htmlElement.parentElement;
    }

    // If no ancestor with the attribute is found, return null
    return null;
}
/**
 * Function checks the file name is in the ISO-8859-1 format
 * @param {string} fileName - file name
 * @returns {boolean} if the name is valid, then return true
 * @example
 * isFileNameValid('logo.png')
 */
export function isFileNameValid(fileName) {
    // eslint-disable-next-line no-control-regex
    const regex = /[^\x00-\xFF]/; // Regular expression to search for non-ISO-8859-1 characters

    return !regex.test(fileName);
}
/**
 * Generates a unique key for a list item based on the item and its index.
 *
 * @param {string|Object} item - The list item to generate a key for.
 * @param {number} i - The index of the list item.
 * @returns {string|number} - The generated key for the list item.
 */
export const getListItemKey = (item, i) => {
    /**
     * Check if the item is a string and use it as the key.
     * @type {string}
     */
    if (isString(item)) {
        return item;
    }

    /**
     * Check if the item is an object with an 'id' property and use it as the key.
     * @type {string}
     */
    if (isObject(item) && item.id) {
        return item.id;
    }

    /**
     * Use the index as the key if no suitable key is found in the item.
     * @type {number}
     */
    return i;
};
/**
 * Checks if the current domain is an internal Ezlogic domain.
 *
 * @function
 * @returns {boolean} - A boolean indicating whether the current domain is internal.
 * @description This function retrieves the host from the window location and checks
 *              if it matches any of the internal Ezlogic domain URLs.
 * @example
 * const isInternal = isEzlogicInternalDomain();
 * // Result: true or false
 */
export const isEzlogicInternalDomain = () => {
    const { host } = window.location || {};

    return INTERNAL_EZLOGIC_DOMAIN_URLS.some((domainUrl) => domainUrl === host);
};

export const calculateTimestampInMs = (timestampInSec) => timestampInSec * 1000;

export function convertTimestampMsToSec(timestampInMillis) {
    // Ensure that the input is a valid number
    if (typeof timestampInMillis !== 'number' || isNaN(timestampInMillis)) {
        throw new Error('Invalid input. Please provide a valid timestamp in milliseconds.');
    }

    // Convert milliseconds to seconds
    return Math.floor(timestampInMillis / 1000);
}
