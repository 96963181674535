import React from 'react';

const IcDeviceClosedWindow = (props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
            <path
                d="M21 0H3C1.3 0 0 1.3 0 3V21C0 22.7 1.3 24 3 24H21C22.7 24 24 22.7 24 21V3C24 1.3 22.7 0 21 0ZM21 10.5H13.5V3H21V10.5ZM3 10.5V3H10.5V10.5V13.5V21H3V13.5V10.5ZM13.5 21V13.5H21V21H13.5Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcDeviceClosedWindow;
