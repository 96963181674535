import React from 'react';

const IcActionsNoBattery = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22 8H4C2.4 8 1 9.4 1 11V21C1 22.6 2.4 24 4 24H22C23.6 24 25 22.6 25 21V11C25 9.4 23.6 8 22 8ZM22 21H4V11H22V21ZM28 13C29.7 13 31 14.3 31 16C31 17.7 29.7 19 28 19V13ZM10.4142 12.0001L9 13.4143L11.5 15.9143L9 18.4143L10.4142 19.8285L12.9142 17.3285L15.364 19.7783L16.7782 18.3641L14.3284 15.9143L16.7782 13.4646L15.364 12.0503L12.9142 14.5001L10.4142 12.0001Z"
                fill="black"
            />
        </svg>
    );
};

export default IcActionsNoBattery;
