import React from 'react';
import useErrorBoundary from './hooks/useErrorBoundary';

const ErrorBoundary = ({ children }) => {
    const [ErrorBoundaryInstance, loading] = useErrorBoundary();

    // Do not render anything until ErrorBoundary finish its initialization
    if (loading) {
        return;
    }

    // no ErrorBoundaryInstance - return children as is
    if (!ErrorBoundaryInstance) {
        return children;
    }

    return <ErrorBoundaryInstance>{children}</ErrorBoundaryInstance>;
};

export default ErrorBoundary;
