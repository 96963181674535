import React from 'react';

export const IcVisualsSuccess = (props) => {
    return (
        <svg width="112" height="112" viewBox="0 0 112 112" fill="none" {...props}>
            <path
                d="M104 56C104 82.5097 82.5097 104 56 104C29.4903 104 8 82.5097 8 56C8 29.4903 29.4903 8 56 8"
                stroke="#00B491"
                strokeWidth="16"
                strokeMiterlimit="10"
            />
            <path
                d="M89.5188 11.1669L56 44.6865L42.2715 30.957L30.957 42.2715L50.3428 61.6572H61.6572L100.833 22.4811C97.6196 18.1937 93.8061 14.3806 89.5188 11.1669Z"
                fill="#00B491"
            />
            <path d="M56 44.6865L67.314 56.0005V33.3723L56 44.6865Z" fill="#009377" />
            <path
                d="M78.042 89.3564L89.5188 100.833C103.158 90.61 112 74.3184 112 56.0001H96C96 69.9155 88.8535 82.1882 78.042 89.3564Z"
                fill="#009377"
            />
        </svg>
    );
};
