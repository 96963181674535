import React from 'react';
import styles from './styles.module.scss';

export const StopIconWithCircularProgress = (props) => {
    return (
        <div className={styles.container}>
            <div className={styles.circularProgressBox}>
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" {...props}>
                    <circle cx="20" cy="20" r="16.5" stroke="#90A0AF" strokeOpacity="0.25" strokeWidth="3" />
                    <circle
                        className={styles.animatedCircular}
                        cx="20"
                        cy="20"
                        r="16.5"
                        strokeWidth="3"
                        stroke="currentColor"
                    />
                </svg>
            </div>
            <svg width="40" height="40" viewBox="0 0 40 40" {...props}>
                <rect x="14" y="14" width="12" height="12" fill="currentColor" />
            </svg>
        </div>
    );
};

export default StopIconWithCircularProgress;
