import React from 'react';

const IcActionsZeroTiltAngle = (props) => {
    return (
        <svg width="30" height="24" viewBox="0 0 30 24" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19 3H17.6834C17.6831 4.65658 16.3637 5.9994 14.7362 5.9994C13.1087 5.9994 11.7893 4.65658 11.789 3H10V9H19V3ZM22 9H24.1055C25.7332 9 27.0528 7.65685 27.0528 6C27.0528 4.34314 25.7332 3 24.1055 3H22V9ZM5.89448 3H7V9H5.89448C4.26676 9 2.94724 7.65686 2.94724 6C2.94724 4.34315 4.26677 3 5.89448 3ZM5.89448 0C2.63905 0 0 2.68629 0 6C0 9.31371 2.63905 12 5.89448 12H24.1055C27.361 12 30 9.31371 30 6C30 2.68629 27.361 0 24.1055 0H5.89448ZM30 15H0V24H30V15Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcActionsZeroTiltAngle;
