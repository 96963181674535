import * as types from '../constants/ActionTypes/Groups';
import * as localStorageKeys from '../constants/localStorageKeys';
import { axiosCloudConfig } from '../api/config';
import { apiDevices } from '../api/apiDevices';
import { tokenParser } from '../helpers/auth';
import { API_CLOUD_EZLO_REQUEST_URL } from '../constants/URLs';
import {
    buildControllerGetReportRequestBody,
    buildControllerGroupListRequestBody,
    buildControllerGroupSetParams,
    buildControllerGroupSetRequestBody,
    getLastUsedControllersGroup,
} from '../containers/Ezlo/EzloGroups/utils';
import {
    DEFAULT_CONTROLLERS_GROUP_NAME,
    MIN_ALLOWED_CONTROLLERS_IN_GROUP_LIMIT,
    MAX_ALLOWED_CONTROLLERS_IN_GROUP_LIMIT,
    MAX_ALLOWED_CONTROLLERS_IN_GROUP_UPPER_LIMIT,
} from '../containers/Ezlo/EzloGroups/constants';
import EzloGroupActions from './EzloGroupActions';
import { apiControllerGroupSet } from '../api/apiGroups';
import { bugsnagNotify } from '../containers/ErrorBoundary/utils';
import { PK_ACCOUNT_CHILD_EMPTY } from '../constants/Devices';

const actions = {
    fetchControllerGroupList: () => async (dispatch) => {
        return fetch(API_CLOUD_EZLO_REQUEST_URL(), {
            method: 'post',
            ...axiosCloudConfig(),
            body: JSON.stringify(buildControllerGroupListRequestBody()),
        })
            .then((res) => {
                return res.json();
            })
            .then((payload) => {
                const { groups } = payload.data;
                const group = groups ? Object.values(groups)[0] : null;
                if (group && group.controllers) {
                    Object.entries(groups).forEach(([key, group]) => {
                        group.uuid = key;
                    });
                    dispatch({ type: types.GROUPS_FETCH_CONTROLLER_GROUP_LIST_ON_SUCCESS, groups });

                    return payload;
                } else {
                    dispatch({ type: types.GROUPS_FETCH_CONTROLLER_GROUP_LIST_ON_SUCCESS, groups: {} });
                }
            })
            .catch((err) => {
                bugsnagNotify(err, { type: types.GROUPS_FETCH_CONTROLLER_GROUP_LIST_ON_ERROR });
                dispatch({ type: types.GROUPS_FETCH_CONTROLLER_GROUP_LIST_ON_ERROR, error: err });
                throw err;
            });
    },

    updateAndConnectControllerGroup: (params) => async (dispatch) => {
        await dispatch(actions.updateControllerGroup(params));
        await dispatch(actions.connectActiveControllerGroup(params));
    },

    connectActiveControllerGroup: (params) => async (dispatch, getState) => {
        const isActiveGroup = getState().groups.selectedGroup.uuid === params.uuid;
        if (isActiveGroup) {
            const group = getState().groups.groups[params.uuid];
            await dispatch(EzloGroupActions.connectEzloGroup(group));
        }
    },

    updateControllerGroup: (params) => async (dispatch) => {
        dispatch({ type: types.GROUPS_UPDATE_CONTROLLER_GROUP, params });
        try {
            await apiControllerGroupSet(JSON.stringify(buildControllerGroupSetRequestBody(params)));
            setTimeout(() => dispatch(actions.fetchControllerGroupList()), 200);
        } catch (err) {
            bugsnagNotify(err, { type: types.GROUPS_UPDATE_CONTROLLER_GROUP_ON_ERROR, params });
            dispatch({ type: types.GROUPS_UPDATE_CONTROLLER_GROUP_ON_ERROR, error: err });
        }
    },

    fetchControllerGetReport: (params) => async (dispatch) => {
        dispatch({ type: types.GROUPS_CONTROLLER_GET_REPORT });

        return fetch(API_CLOUD_EZLO_REQUEST_URL(), {
            method: 'post',
            ...axiosCloudConfig(),
            body: JSON.stringify(buildControllerGetReportRequestBody(params)),
        })
            .then((res) => {
                return res.json();
            })
            .then((payload) => {
                dispatch({ type: types.GROUPS_CONTROLLER_GET_REPORT_ON_SUCCESS, payload });

                return payload;
            })
            .catch((err) => {
                bugsnagNotify(err, { type: types.GROUPS_CONTROLLER_GET_REPORT_ON_ERROR, params });
                dispatch({ type: types.GROUPS_CONTROLLER_GET_REPORT_ON_ERROR, error: err });
            });
    },

    /**
     * Register default group if user doesn't have any group yet or return first available group from group list
     */
    registerDefaultControllersGroup: () => async (dispatch, getState) => {
        await dispatch(actions.fetchControllerGroupList());
        const state = getState();
        const { groups } = state.groups;

        if (Object.values(groups).length === 0) {
            const { PK_Account, PK_AccountChild } = tokenParser(localStorage.getItem(localStorageKeys.IDENTITY));
            const currentPK_Account =
                PK_Account && PK_AccountChild != PK_ACCOUNT_CHILD_EMPTY ? PK_AccountChild : PK_Account;

            // register default group if user doesn't have any group yet
            let devices = await apiDevices(currentPK_Account, true);

            let serials = [];
            if (devices.length < MIN_ALLOWED_CONTROLLERS_IN_GROUP_LIMIT) {
                // It's not possible to create group for single controller (single controller workaround)
                return false;
            } else if (devices.length > MAX_ALLOWED_CONTROLLERS_IN_GROUP_UPPER_LIMIT) {
                devices = devices.slice(0, MAX_ALLOWED_CONTROLLERS_IN_GROUP_LIMIT);
            }
            serials = devices.map((controller) => ({ id: parseInt(controller.PK_Device) }));

            const result = await dispatch(
                actions.updateControllerGroup(buildControllerGroupSetParams(serials, DEFAULT_CONTROLLERS_GROUP_NAME)),
            );
            if (result.data && result.data.error_text) {
                // something went wrong, fallback (single controller workaround)
                // eslint-disable-next-line
                console.error('#registerDefaultControllersGroup, error_text:', result.data.error_text);

                return false;
            } else {
                // group successfully created, call #registerDefaultControllersGroup again to select default group;
                return await dispatch(actions.registerDefaultControllersGroup());
            }
        } else {
            return getLastUsedControllersGroup(groups);
        }
    },

    fetchAccountControllers: () => async (dispatch) => {
        try {
            dispatch({ type: types.GROUPS_FETCH_ACCOUNT_EZLOS });
            const { PK_Account, PK_AccountChild } = tokenParser(localStorage.getItem(localStorageKeys.IDENTITY));
            const currentPK_Account =
                PK_Account && PK_AccountChild != PK_ACCOUNT_CHILD_EMPTY ? PK_AccountChild : PK_Account;
            const devices = await apiDevices(currentPK_Account, true);
            const ezlos = devices.map((controller) => ({
                ...controller,
                serial: controller.PK_Device,
            }));
            dispatch({ type: types.GROUPS_FETCH_ACCOUNT_EZLOS_SUCCESS, ezlos });
        } catch (error) {
            bugsnagNotify(error, { type: types.GROUPS_FETCH_ACCOUNT_EZLOS_ERROR });
            dispatch({ type: types.GROUPS_FETCH_ACCOUNT_EZLOS_ERROR, error });
        }
    },

    clearGroupsStateFromController: (serial, groupKey) => async (dispatch) => {
        dispatch({ type: types.CLEAR_GROUPS_STATE_FROM_REMOVED_CONTROLLER, serial, groupKey });
    },
    setAllControllerInfoToGroupsState: (allControllersInfo) => async (dispatch) => {
        dispatch({ type: types.SET_ALL_CONTROLLERS_INFORMATION_TO_GROUPS_STATE, allControllersInfo });
    },
};

export default actions;
