import React from 'react';

const IcFinishedStatus = (props) => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" {...props}>
            <circle cx="20" cy="20" r="16.5" stroke="currentColor" strokeWidth="3" />
            <path
                d="M17.8657 26C17.2634 26 16.6857 25.7616 16.2586 25.337L12 21.1027L13.8806 19.2329L17.9104 23.1507L28.1194 13L30 14.8699L19.4728 25.337C19.0457 25.7616 18.4679 26 17.8657 26Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default IcFinishedStatus;
