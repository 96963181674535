import { getKvs, setKvs } from '../network/kvsApi';
import {
    buildLabelsWithUpdatedLabel,
    buildParamsOfSetLabelsInKvs,
    buildUpdatedLabels,
    buildValidLabelsList,
    filterMeshBotLabelsByDeletedField,
    getMeshBotLabelsFromValue,
    getParamsOfReadLabelsFromKvs,
    removeLabelFromLabels,
} from '../utils/utils';
import { LABELS_TYPES, MESHBOT_LABELS } from '../constants';
import { SUCCESS_STATUS } from '../../../../constants/api';
import { responseErrorHandling } from '../../../utilityService';

/**
 * Set MeshBot Labels to Cloud
 * @param {string} value - value(can be encoded) that stores MeshBot labels
 * @returns {Promise<Object>} request response
 * @example
 * setMeshBotLabels('b4125cfdbg')
 * */
function setMeshBotLabels(value) {
    const params = buildParamsOfSetLabelsInKvs(LABELS_TYPES.MESHBOT, value);

    return setKvs(params);
}
/**
 * Read MeshBot labels from Cloud(read MeshBot labels and return them)
 * @returns {Promise<Object>} meshBot labels list
 * @example
 * getMeshBotLabels()
 * */
export async function getMeshBotLabels() {
    const params = getParamsOfReadLabelsFromKvs(LABELS_TYPES.MESHBOT);
    const response = await getKvs(params);
    responseErrorHandling(response);

    if (response.data.data.list && response.data.status === SUCCESS_STATUS) {
        const meshBotLabelsValue = response.data.data.list.find(({ key }) => key === MESHBOT_LABELS)?.value;

        if (meshBotLabelsValue) {
            const labels = getMeshBotLabelsFromValue(meshBotLabelsValue);
            const validLabels = buildValidLabelsList(labels);

            // TODO Remove filtration in 2024year, replace this line "return filterMeshBotLabelsByDeletedField(labels);" with this "return labels;"
            // Support for the first implementation of label deletion. In which "deleted: 1" was added during deletion
            return filterMeshBotLabelsByDeletedField(validLabels);
        }

        return {};
    }

    return {};
}

/**
 * Update MeshBot labels on Cloud(adds a new label to all created labels on the cloud)
 * @param {Object} newLabelData - new label that need to save on cloud
 * @returns {Promise<Object>} request response
 * @example
 * updateMeshBotLabels({name: 'newLabel', backgroundColor: '#ce0b0b'})
 * */
export async function updateMeshBotLabels(newLabelData) {
    const cloudMeshBotLabels = await getMeshBotLabels();
    const updateMeshBotLabels = buildUpdatedLabels(cloudMeshBotLabels, newLabelData);
    const value = JSON.stringify(updateMeshBotLabels);

    const response = await setMeshBotLabels(value);
    responseErrorHandling(response);

    return response;
}
/**
 * Edit a MeshBot label on Cloud
 * @param {Object} updatedLabelData - updated label that need to save on cloud
 * @param {string} labelUuid - label uuid to update on the cloud
 * @returns {Promise<Object>} request response
 * @example
 * editMeshBotLabels({name: 'updated Label', backgroundColor: '#ce0b0b'}, '5965vfrt14)
 * */
export async function editMeshBotLabels(updatedLabelData, labelUuid) {
    const cloudMeshBotLabels = await getMeshBotLabels();
    const updateMeshBotLabels = buildLabelsWithUpdatedLabel(cloudMeshBotLabels, updatedLabelData, labelUuid);
    const value = JSON.stringify(updateMeshBotLabels);

    const response = await setMeshBotLabels(value);
    responseErrorHandling(response);

    return response;
}

/**
 * Delete a MeshBot label on Cloud
 * @param {string} labelUuid - label uuid to delete on the cloud
 * @returns {Promise<Object>} request response
 * @example
 * deleteMeshBotLabels('5965vfrt14)
 * */
export async function deleteMeshBotLabels(labelUuid) {
    const cloudMeshBotLabels = await getMeshBotLabels();
    const updateMeshBotLabels = removeLabelFromLabels(cloudMeshBotLabels, labelUuid);
    const value = JSON.stringify(updateMeshBotLabels);

    const response = await setMeshBotLabels(value);
    responseErrorHandling(response);

    return response;
}
