import * as types from '../actions/EzloPoliciesActionTypes';
import update from 'immutability-helper';
const defaultState = {
    domainPath: '',
    policiesAccepted: {},
    eula: {
        accepted: null,
        eulaContent: null,
        pkEula: null,
    },
    notices: {
        accepted: null,
        LongDescription: null,
        title: null,
        pkNotice: null,
    },
    consent: {
        pkConsentGroup: null,
        description: null,
        title: null,
        consentSections: [],
    },
    setConsent: {
        UserAgent: '',
        Accepted: true,
        PK_ConsentGroup: null,
        AcceptArray: [],
    },
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case types.END_USER_LICENSE_AGREEMENT:
            return update(state, {
                eula: {
                    eulaContent: { $set: action.data?.EulaContent },
                    accepted: { $set: action.data?.Accepted },
                    pkEula: { $set: action.data?.PK_Eula },
                },
            });

        case types.UPDATE_NOTICES:
            return update(state, {
                notices: {
                    LongDescription: { $set: action.data?.longDescription },
                    accepted: { $set: action.data?.Accepted },
                    title: { $set: action.data?.Title },
                    pkNotice: { $set: action.data?.pkNotice },
                },
            });

        case types.UPDATE_CONSENT:
            return update(state, {
                consent: {
                    description: { $set: action.data?.description },
                    pkConsentGroup: { $set: action.data?.pkConsentGroup },
                    title: { $set: action.data?.title },
                    consentSections: { $set: action.data?.consentSections },
                },
            });

        case types.SET_UPDATE_CONSENT:
            return update(state, {
                setConsent: {
                    UserAgent: { $set: action.data?.UserAgent },
                    PK_ConsentGroup: { $set: action.data?.PK_ConsentGroup },
                    AcceptArray: { $set: action.data?.AcceptArray },
                },
            });

        case types.SET_DOMAIN_PATH:
            return update(state, {
                domainPath: { $set: action.data },
            });

        case types.SET_POLICIES_ACCEPTED:
            return update(state, {
                policiesAccepted: { $set: action.data },
            });

        default:
            return state;
    }
};
