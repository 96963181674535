import at from '../constants/ActionTypes/Plugins';
import { WIFI_PLUGIN_MODEL, CODE, SUCCESS, REJECTED } from '../constants/Plugins';
import produce from 'immer';
import * as types from '../constants/ActionTypes';

const INITIAL_STATE = {
    loaderList: false,
    loaderInstalled: false,
    privatePlugins: [],
    publicPlugins: [],
    marketplacePlugins: [],
    privateWifiTemplates: [],
    publicWifiTemplates: [],
    marketplaceWifiTemplates: [],
    isRequestStatus: '',
    isRequestStatusMessage: '',
    currentJob: null,
    newJobUuid: '',
    listInstalledPlugins: {},
    prefixesList: [],
    pluginUpgradeWaiting: false,
    activeInstalling: [],
    isPluginIntegrationInProcess: false,
    isPluginIntegrationAdded: false,
    isRequestRejected: false,
    rejectedMessage: '',
    configureErrorMessage: '',
    isPrefixLoading: false,
    protocolsList: [],
    capabilityList: [],
    supportedDevices: [],
    isLogoLoadingInProcess: false,
};
export default produce((draft, action) => {
    switch (action.type) {
        case at.SET_ACTIVE_INSTALLING:
            draft.activeInstalling = Array.from(new Set([...draft.activeInstalling, action.data]));
            break;
        case at.REMOVE_ACTIVE_INSTALLING:
            draft.activeInstalling = action.data;
            break;
        case at.REMOVE_PLUGIN.pending:
            draft.loaderInstalled = true;
            draft.loaderList = true;
            break;
        case at.REMOVE_PLUGIN.success:
            draft.loaderInstalled = false;
            draft.loaderList = false;
            break;
        case at.REMOVE_PLUGIN.rejected:
            draft.loaderInstalled = false;
            draft.loaderList = false;
            break;
        case at.GET_PRIVATE_PLUGINS.success:
            if (action.typeDashboard === CODE) {
                draft.privatePlugins = action.payload;
            }

            if (action.typeDashboard === WIFI_PLUGIN_MODEL) {
                draft.privateWifiTemplates = action.payload;
            }
            draft.loaderInstalled = false;
            draft.loaderList = false;
            break;
        case at.GET_PRIVATE_PLUGINS.pending:
            draft.loaderInstalled = true;
            draft.loaderList = true;
            break;
        case at.GET_PRIVATE_PLUGINS.rejected:
            draft.loaderInstalled = false;
            draft.loaderList = false;
            break;
        case at.DEPLOY_PLUGIN.pending:
            draft.loaderInstalled = true;
            draft.loaderList = true;
            break;
        case at.DEPLOY_PLUGIN.success:
            draft.loaderInstalled = false;
            break;
        case at.DEPLOY_PLUGIN.rejected:
            draft.loaderInstalled = false;
            break;
        case at.GET_PUBLIC_PLUGINS.success:
            if (action.creator) {
                if (action.typeDashboard === CODE) {
                    draft.publicPlugins = action.payload;
                }

                if (action.typeDashboard === WIFI_PLUGIN_MODEL) {
                    draft.publicWifiTemplates = action.payload;
                }
            }

            if (!action.creator) {
                if (action.typeDashboard === CODE) {
                    draft.marketplacePlugins = action.payload;
                }

                if (action.typeDashboard === WIFI_PLUGIN_MODEL) {
                    draft.marketplaceWifiTemplates = action.payload;
                }
            }

            draft.loaderInstalled = false;
            draft.loaderList = false;
            break;
        case at.GET_PUBLIC_PLUGINS.pending:
            draft.loaderInstalled = true;
            draft.loaderList = true;
            draft.publicPlugins = [];
            draft.marketplacePlugins = [];
            break;
        case at.GET_PUBLIC_PLUGINS.rejected:
            draft.loaderInstalled = false;
            draft.loaderList = false;
            break;
        case at.CREATE_DASHBOARD.success:
            draft.newJobUuid = action.payload;
            break;
        case at.SET_PREFIXES.pending:
            draft.isPrefixLoading = true;
            break;
        case at.SET_PREFIXES.success:
            draft.isPrefixLoading = false;
            break;
        case at.SET_PREFIXES.rejected:
            draft.isPrefixLoading = false;
            break;
        case at.SET_REQUEST_STATUS.success:
            draft.isRequestStatus = SUCCESS;
            draft.isRequestStatusMessage = action.payload;
            draft.supportedDevices = [];
            break;
        case at.SET_REQUEST_STATUS.rejected:
            draft.isRequestStatus = REJECTED;
            draft.isRequestStatusMessage = action.payload;
            draft.loaderInstalled = false;
            break;
        case at.SET_REQUEST_STATUS.clear:
            draft.isRequestStatus = '';
            draft.isRequestStatusMessage = '';
            break;
        case at.CREATE_DASHBOARD_WIFI.pending:
            draft.loaderInstalled = true;
            draft.loaderList = true;
            break;
        case at.CREATE_DASHBOARD_WIFI.success:
            draft.loaderInstalled = false;
            draft.loaderList = false;
            break;
        case at.CREATE_DASHBOARD_WIFI.rejected:
            draft.loaderInstalled = false;
            draft.loaderList = false;
            break;
        case at.CLEAR_JOB_UUID:
            draft.newJobUuid = '';
            draft.currentJob = null;
            break;
        case at.GET_DASHBOARD_CREATE_LIST.success:
            draft.currentJob = action.payload;
            break;
        case at.GET_LIST_INSTALLED_PLUGINS.pending:
            draft.loaderInstalled = true;
            draft.loaderList = true;
            break;
        case at.GET_LIST_INSTALLED_PLUGINS.success:
            const newController = action.payload;
            draft.loaderInstalled = false;
            draft.loaderList = false;
            draft.listInstalledPlugins = { ...draft.listInstalledPlugins, ...newController };
            break;
        case at.GET_LIST_INSTALLED_PLUGINS.rejected:
            draft.loaderInstalled = false;
            draft.loaderList = false;
            break;
        case at.GET_PREFIXES_LIST.success:
            draft.prefixesList = action.payload;
            break;
        case at.UPGRADE_PLUGIN.pending:
            draft.pluginUpgradeWaiting = true;
            break;
        case at.UPGRADE_PLUGIN.success:
            draft.pluginUpgradeWaiting = false;
            break;
        case at.UPGRADE_PLUGIN.rejected:
            draft.pluginUpgradeWaiting = false;
            break;
        case at.SET_PLUGIN_INTEGRATION.pending:
            draft.isPluginIntegrationInProcess = true;
            draft.isPluginIntegrationAdded = false;
            break;
        case at.SET_PLUGIN_INTEGRATION.success:
            draft.isPluginIntegrationInProcess = false;
            draft.isPluginIntegrationAdded = true;
            break;
        case at.SET_PLUGIN_INTEGRATION.rejected:
            draft.isPluginIntegrationInProcess = false;
            draft.isPluginIntegrationAdded = false;
            draft.isRequestRejected = true;
            draft.rejectedMessage = action?.data;
            break;
        case at.SET_INTEGRATION_CONFIGURE_PLUGIN.rejected:
            draft.isRequestRejected = true;
            draft.isPluginIntegrationInProcess = false;
            draft.configureErrorMessage = action?.data;
            break;
        case at.HANDLE_CLOSE_REJECTED_MODAL:
            draft.isRequestRejected = false;
            draft.rejectedMessage = '';
            draft.configureErrorMessage = '';
            break;
        case at.HANDLE_CLOSE_SUCCESS_PLUGIN_MODAL:
            draft.isPluginIntegrationAdded = false;
            break;
        case at.HANDLE_CLOSE_PENDING_MODAL:
            draft.isPluginIntegrationInProcess = false;
            break;
        case at.ADD_DEVICE_WIFI_GENERATOR.pending:
            draft.loaderInstalled = true;
            break;
        case at.ADD_DEVICE_WIFI_GENERATOR.rejected:
            draft.loaderInstalled = false;
            break;
        case at.ADD_DEVICE_WIFI_GENERATOR.success:
            draft.loaderInstalled = false;
            break;
        case at.SET_PROTOCOLS_LIST:
            draft.protocolsList = action.data;
            break;
        case at.SET_CAPABILITY_LIST:
            draft.capabilityList = action.data;
            break;
        case at.SET_SUPPORTED_DEVICES:
            draft.supportedDevices = [...draft.supportedDevices, action.data];
            break;
        case at.SET_INITIAL_SUPPORTED_DEVICES:
            draft.supportedDevices = action.data;
            break;
        case at.REMOVE_SUPPORTED_DEVICES:
            draft.supportedDevices = draft.supportedDevices.filter((device) => device.id !== action.data);
            break;
        case at.EDIT_SUPPORTED_DEVICE:
            draft.supportedDevices = draft.supportedDevices.reduce((acc, device) => {
                if (device && device?.id === action?.data?.id) {
                    acc.push({ ...device, ...action.data });
                } else {
                    acc.push(device);
                }

                return acc;
            }, []);
            break;
        case at.GET_LOGO_LINK_PENDING:
            draft.isLogoLoadingInProcess = true;
            break;
        case at.GET_LOGO_LINK_SUCCESS:
            draft.isLogoLoadingInProcess = false;
            break;
        case at.GET_LOGO_LINK_REJECT:
            draft.isLogoLoadingInProcess = false;
            break;
        case types.LOGOUT:
            return INITIAL_STATE;

        default:
            break;
    }
}, INITIAL_STATE);
