export const CONNECT_EZLO_CONTROLLER = 'CONNECT_EZLO_CONTROLLER';
export const CONNECT_EZLO_CONTROLLER_SUCCESS = 'CONNECT_EZLO_CONTROLLER_SUCCESS';
export const CONNECT_EZLO_CONTROLLER_ERROR = 'CONNECT_EZLO_CONTROLLER_ERROR';

export const UPDATE_CONTROLLER_FAVORITES = 'UPDATE_CONTROLLER_FAVORITES';
export const UPDATE_CONTROLLER_DATA = 'UPDATE_CONTROLLER_DATA';

export const SELECT_EZLO_CONTROLLER = 'SELECT_EZLO_CONTROLLER';
export const UPDATE_DATA_GATEWAY = {
    pending: 'UPDATE_DATA_GATEWAY/pending',
    success: 'UPDATE_DATA_GATEWAY/success',
    rejected: 'UPDATE_DATA_GATEWAY/rejected',
};

export const GET_NEW_EZLO_DATA = {
    pending: 'GET_NEW_EZLO_DATA/pending',
    success: 'GET_NEW_EZLO_DATA/success',
    rejected: 'GET_NEW_EZLO_DATA/rejected',
};

export const GET_BLOCK_DATA_LIST = {
    success: 'GET_BLOCK_DATA_LIST/success',
    rejected: 'GET_BLOCK_DATA_LIST/rejected',
};

export const SET_ONLINE_CONTROLLER_LIST = 'SET_ONLINE_CONTROLLER_LIST';

export const START_RANDOMIZE_VALUES = {
    rejected: 'START_RANDOMIZE_VALUES/rejected',
};
export const STOP_RANDOMIZE_VALUES = {
    rejected: 'STOP_RANDOMIZE_VALUES/rejected',
};
