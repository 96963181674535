import React, { Component } from 'react';
import classNames from 'classnames';

import { Dropdown, DropdownMenuItem } from '../../components';
import { EZLO_NEW } from '../../constants/URLs';

import styles from './EzlosDropdown.module.scss';
import { filteredEzlosControllers, getValveControllers } from '../Ezlo/EzloPlugins/utils';
import { getControllers } from '../../helpers/helpersPlugins';
import { ControllerIcon } from '../../assets/icons';
import { ZERO } from '../../constants/App';
import { MESHBOT_TYPES } from '../../containers/Ezlo/EzloMeshbots/constants';
import { getControllersByConfig, isPageNotSupportedByEzlopi } from '../Ezlo/EzloMeshbots/utils';
import { configEzlopiControllers } from '../../containers/Ezlo/EzloMeshbots/components/modalCreate/constants';
import { excludeEzlopiControllers } from 'containers/Navigation/utils';
import { INDEX_OF_ZERO } from 'constants/MeshbotConstant';

const {
    ezlos_dropdown,
    dropdown_menu_item,
    connected,
    not_connected,
    dropdown_item_content,
    controller_icon,
    icon_container,
    online,
    controller__name,
} = styles;

class EzlosDropdown extends Component {
    componentDidMount() {
        const { selectController } = this.props.redux.actions.EzloActions;
        const { connected } = this.props.redux.state.currentEzlo;
        const { ezlos } = this.props.redux.state;

        if (!connected && this.props.isAlertsHistory) {
            selectController(ezlos[ZERO].serial);
        } else if (!connected) {
            const onlineController = ezlos.find((controller) => controller.connected)?.serial;
            selectController(onlineController);
        }
    }

    handleAddNewEzloClick = () => {
        const { linkTo } = this.props.redux.actions.GenericActions;

        linkTo(EZLO_NEW);
    };

    handleDropdownMenuItemClick = (ezlo) => () => {
        const { serial } = ezlo;
        const { openConfirmModal } = this.props;

        if (openConfirmModal) {
            openConfirmModal(serial);

            return;
        }
    };

    getDropdownTemplate(ezlos, currentEzlo) {
        const { wizardMode, isShowOnlyOnlineControllers, controllerSerialWhileEditing, isPlugins, meshbotType } =
            this.props;
        const { ezlo } = this.props?.redux?.state;
        const currentUrl = window.location.hash.slice(1);
        const isWizardMode = wizardMode === true;
        let ezloControllers = ezlos;
        const valueWhileCreating = currentEzlo && currentEzlo.serial ? currentEzlo.serial : ezlos[INDEX_OF_ZERO].serial;
        const controllers = getControllers(ezlo.data);

        if (isShowOnlyOnlineControllers) {
            ezloControllers = ezlos.filter(({ connected }) => connected);
        }

        if (isShowOnlyOnlineControllers && isPlugins) {
            ezloControllers = filteredEzlosControllers(ezloControllers, getValveControllers(controllers));
        }

        if (isShowOnlyOnlineControllers && meshbotType === MESHBOT_TYPES.EZLOPI) {
            ezloControllers = getControllersByConfig(controllers, configEzlopiControllers);
        }

        if (isShowOnlyOnlineControllers && isPageNotSupportedByEzlopi(currentUrl)) {
            ezloControllers = excludeEzlopiControllers(controllers);
        }

        return (
            <Dropdown
                data-testid="hubDropdown"
                className={classNames(ezlos_dropdown, this.props.className)}
                value={controllerSerialWhileEditing ? controllerSerialWhileEditing : valueWhileCreating}
                itemsContainerMaxHeight={400}
                onChange={this.handleDropdownChange}
                isWizardMode={isWizardMode}
            >
                {ezloControllers.map((ezlo) => (
                    <DropdownMenuItem
                        key={ezlo.serial}
                        value={ezlo.serial}
                        className={classNames(dropdown_menu_item, {
                            [connected]: ezlo.connected,
                            [not_connected]: !ezlo.connected,
                        })}
                        baseClassName="dropdown"
                        onClick={this.handleDropdownMenuItemClick(ezlo)}
                    >
                        <div className={dropdown_item_content}>
                            <span
                                className={classNames(`${controller_icon} ${icon_container}`, {
                                    [online]: ezlo.connected,
                                })}
                            >
                                <ControllerIcon />
                            </span>
                            <span className={classNames(controller__name, { [online]: ezlo.connected })}>
                                {ezlo.serial}
                            </span>
                        </div>
                    </DropdownMenuItem>
                ))}
            </Dropdown>
        );
    }

    render() {
        const {
            redux: {
                state: { ezlos, currentEzlo },
            },
            isAlertsHistory,
        } = this.props;

        if (isAlertsHistory) {
            return this.getDropdownTemplate(ezlos, currentEzlo);
        }

        if (!ezlos || !ezlos.length || !currentEzlo.serial || !currentEzlo.connected) {
            return null;
        }

        return this.getDropdownTemplate(ezlos, currentEzlo);
    }
}

export default EzlosDropdown;
