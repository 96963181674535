import * as ActionTypes from '../constants/ActionTypes';

export const addModal = (payload) => ({
    type: ActionTypes.ADD_MODAL,
    payload,
});

export const removeModal = () => ({
    type: ActionTypes.REMOVE_MODAL,
});
