import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Drawer, IconButton } from 'lib/@mui';
import { Menu as MenuIcon } from 'lib/@mui/Icons';
import { useMediaQuery } from 'lib/@mui';
import * as Actions from '../../actions';
import { LIST_OF_MENU_ITEMS } from './constants';

const DrawerMenu = (props) => {
    const { isShowNavigation, MainAction } = props;
    const hidden = useMediaQuery('(min-width: 1201px)');

    const onHandlerDrawer = (e) => {
        if (!e.length && !e?.target?.textContent?.length) {
            return;
        }

        if (!LIST_OF_MENU_ITEMS.includes(e.target.textContent)) {
            return MainAction.hideNavigation();
        }
    };

    return (
        <>
            {hidden ? (
                <Drawer anchor="left" open variant="persistent" onClose={MainAction.hideNavigation}>
                    {props.children}
                </Drawer>
            ) : (
                <Drawer
                    anchor="left"
                    open={isShowNavigation}
                    variant="temporary"
                    onClose={MainAction.hideNavigation}
                    onClick={(e) => onHandlerDrawer(e)}
                >
                    <IconButton onClick={MainAction.hideNavigation} className="drawwer-tablet">
                        <MenuIcon />
                    </IconButton>
                    {props.children}
                </Drawer>
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    isShowNavigation: state.main?.isShowNavigation,
});

const mapDispatchToProps = (dispatch) => {
    const obj = {};

    Object.keys(Actions).map(function (actionsName) {
        if (actionsName !== '__esModule') {
            obj[actionsName] = bindActionCreators(Actions[actionsName], dispatch);
        }
    });

    return obj;
};

export default connect(mapStateToProps, mapDispatchToProps)(DrawerMenu);
