import React from 'react';

const IcActionWrench = ({ props }) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.3591 2.87774C17.2582 0.978602 19.865 0.183943 22.4372 0.469814C23.1905 0.553544 23.4297 1.45498 22.8937 1.99096L18.2675 6.6171L19.9295 11.603L24.9154 13.2649L29.5415 8.63879C30.0775 8.10281 30.9789 8.34198 31.0627 9.09533C31.3485 11.6674 30.5539 14.2743 28.6547 16.1734C26.2415 18.5866 22.6856 19.2165 19.5102 18.1121C19.099 17.9691 18.6348 18.0685 18.3481 18.3961L8.71298 29.4077C8.58642 29.5736 8.44735 29.733 8.29576 29.8846C6.46001 31.7203 3.48367 31.7203 1.64792 29.8846C-0.187826 28.0488 -0.187826 25.0725 1.64792 23.2367C1.79952 23.0851 1.95888 22.9461 2.12475 22.8195L13.1364 13.1843C13.464 12.8977 13.5634 12.4335 13.4204 12.0223C12.316 8.84688 12.9458 5.29097 15.3591 2.87774ZM6.6338 28.2226C7.55167 27.3047 7.55167 25.8166 6.6338 24.8987C5.71592 23.9808 4.22775 23.9808 3.30988 24.8987C2.39201 25.8166 2.39201 27.3047 3.30988 28.2226C4.22776 29.1405 5.71592 29.1405 6.6338 28.2226Z"
            />
        </svg>
    );
};

export default IcActionWrench;
