import React from 'react';

const IcActionsDoorbellCamera = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="#1C1E21" {...props}>
            <path d="M9 20H22V30C22 31.1046 21.1046 32 20 32H11C9.89543 32 9 31.1046 9 30V20Z" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 1C9.89543 1 9 1.89543 9 3V17H22V3C22 1.89543 21.1046 1 20 1H11ZM19 7.5C19 9.433 17.433 11 15.5 11C13.567 11 12 9.433 12 7.5C12 5.567 13.567 4 15.5 4C17.433 4 19 5.567 19 7.5Z"
            />
            <circle cx="15.5" cy="7.5" r="1.5" />
        </svg>
    );
};

export default IcActionsDoorbellCamera;
