import React from 'react';

const IcControlsInputEraseNoBg = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.293 11.7072L14.5859 16.0001L10.293 20.293L11.7072 21.7072L16.0001 17.4143L20.293 21.7072L21.7072 20.293L17.4143 16.0001L21.7072 11.7072L20.293 10.293L16.0001 14.5859L11.7072 10.293L10.293 11.7072Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcControlsInputEraseNoBg;
