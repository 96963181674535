import React from 'react';

const IcDeviceSensorLeak = (props) => {
    return (
        <svg width="24" height="26" viewBox="0 0 24 26" fill="#1C1E21" {...props}>
            <path d="M3.8 9H0V6H8L5.9 8.1C5.3 8.7 4.6 9 3.8 9ZM16 6L18.1 8.1C18.7 8.7 19.4 9 20.2 9H24V6H16ZM17.7 23.2C14.6 26.3 9.5 26.3 6.4 23.2C3.3 20.1 3.3 15 6.4 11.9L12 6.3L17.7 12C20.8 15.1 20.8 20.1 17.7 23.2ZM24 0H0V3H24V0Z" />
        </svg>
    );
};

export default IcDeviceSensorLeak;
