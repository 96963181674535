import { DIGITS, LOWERCASE_LETTERS, SPECIAL_CHARACTERS, UPPERCASE_LETTERS } from './constants';

function getRandomCharacter(characterSet) {
    return characterSet[Math.floor(Math.random() * characterSet.length)];
}

/**
 * Returns password value, based on Cloud requirements:
 * - minimum password length - 8
 * - maximum password length - 16
 * - minimum number of digits in the password - 1
 * - minimum number of letters in the password - 1
 * - minimum number of non-alphanumeric characters - 1
 * - minimum number of letters in uppercase - 1
 * - minimum number of letters in lowercase - 1
 * */
export function generatePassword() {
    const passwordLength = Math.floor(Math.random() * 8) + 8; // 8 - 15

    const allCharacters = UPPERCASE_LETTERS + LOWERCASE_LETTERS + SPECIAL_CHARACTERS + DIGITS;

    // Ensure at least one character from each category
    const randomUppercase = getRandomCharacter(UPPERCASE_LETTERS);
    const randomLowercase = getRandomCharacter(LOWERCASE_LETTERS);
    const randomSpecialChar = getRandomCharacter(SPECIAL_CHARACTERS);
    const randomDigitChar = getRandomCharacter(DIGITS);

    // Generate the remaining characters
    const remainingLength = passwordLength - 4; // 4 characters already chosen
    const randomString = Array.from({ length: remainingLength }, () => getRandomCharacter(allCharacters)).join('');

    // Concatenate all parts to get the final string
    return randomUppercase + randomLowercase + randomSpecialChar + randomDigitChar + randomString;
}
