import React, { useRef } from 'react';
import { TextField, FormHelperText } from 'lib/@mui';
import * as meshBot from '../../constants/MeshbotConstant';
import classNames from 'classnames';

import InputNumberArrows from '../InputNumberArrows/InputNumberArrows';

import styles from './InputMaterial.module.scss';
import { EZLOGIC_TITLE_HOURS, EZLOGIC_TITLE_MINS, EZLOGIC_TITLE_SECS } from '../../constants/language_tokens';
import { useTranslate } from '../../features/languages';
import { OBJECT_TYPE } from '../../constants/Variables';

const {
    input_block,
    select_small,
    select_xs_small,
    fullWidth_input,
    style_for_functionBlock,
    without_margin,
    content_of_time_format_style,
} = styles;
// TODO: need to review the usage of this component, probably, the better option to avoid custom components for now?
const Input = (props) => {
    const {
        label,
        title,
        name,
        type,
        value,
        defaultValue,
        rows,
        min,
        max,
        placeholder,
        multiline,
        step,
        error,
        onChange,
        onBlur,
        autoFocus,
        required,
        disabled,
        endAdornment,
        focused,
        autoComplete,
        InputProps = {},
        helperText,
        small,
        xsSmall,
        fullWidthInput,
        styleForFunctionBlock,
        secsContentType,
        minsContentType,
        hoursContentType,
        otherClassName,
        maxLength,
        withoutMargin,
        onKeyPress,
    } = props;
    const textRef = useRef();

    const className = classNames(input_block, {
        [select_small]: small,
        [select_xs_small]: xsSmall,
        [fullWidth_input]: fullWidthInput,
        [style_for_functionBlock]: styleForFunctionBlock,
        // [secs_content_type]: secsContentType,
        // [mins_content_type]: minsContentType,
        // [hours_content_type]: hoursContentType,
        [without_margin]: withoutMargin,
    });
    const { t } = useTranslate();
    const contentOfTimeFormat = () => {
        if (secsContentType) {
            return t(EZLOGIC_TITLE_SECS);
        }

        if (minsContentType) {
            return t(EZLOGIC_TITLE_MINS);
        }

        if (hoursContentType) {
            return t(EZLOGIC_TITLE_HOURS);
        }

        return undefined;
    };

    return (
        <div className={className}>
            <TextField
                defaultValue={defaultValue}
                variant="standard"
                id="standard-basic"
                value={value}
                label={typeof label === OBJECT_TYPE ? t(label?.props?.children) : label}
                type={type}
                title={title}
                name={name}
                multiline={multiline}
                minRows={rows}
                fullWidth
                disabled={disabled}
                placeholder={placeholder}
                error={error}
                inputRef={textRef}
                InputProps={{
                    inputProps: { min: min, max: max, step: step, autoComplete: autoComplete, maxLength: maxLength },
                    endAdornment: endAdornment ? (
                        { ...endAdornment }
                    ) : (
                        <div className={content_of_time_format_style}> {contentOfTimeFormat()}</div>
                    ),
                    ...InputProps,
                }}
                onBlur={onBlur}
                autoFocus={autoFocus}
                required={required}
                onChange={onChange}
                onKeyPress={onKeyPress}
                focused={focused}
                autoComplete={autoComplete}
                className={otherClassName}
                helperText={
                    error && (
                        <FormHelperText component="span" id="component-error-text">
                            {helperText}
                        </FormHelperText>
                    )
                }
            />
            {type === meshBot.NUMBER && (
                <InputNumberArrows
                    disabled={disabled}
                    value={value}
                    onChange={onChange}
                    name={name}
                    max={max}
                    min={min}
                    step={step}
                />
            )}
        </div>
    );
};

export default Input;

Input.defaultProps = {
    type: 'string',
    step: 1,
    min: 0, // todo check
    autoComplete: 'on',
    // max: 99,
    // maxLength: 99,
};
