import React from 'react';

export const IcActionsIcPlugin = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.6054 7.25C18.6184 7.1059 18.625 6.95998 18.625 6.8125C18.625 4.15463 16.4704 2 13.8125 2C11.1546 2 9 4.15463 9 6.8125C9 6.95998 9.00663 7.1059 9.01962 7.25H4C2.89543 7.25 2 8.14543 2 9.25V14.0096C2.13876 13.997 2.27928 13.9907 2.42129 13.9907C4.98072 13.9907 7.05555 16.0655 7.05555 18.6249C7.05555 21.1844 4.98072 23.2592 2.42129 23.2592C2.27928 23.2592 2.13876 23.2528 2 23.2403V28C2 29.1046 2.89543 30 4 30H8.75964C8.74713 29.8612 8.74074 29.7207 8.74074 29.5787C8.74074 27.0192 10.8156 24.9444 13.375 24.9444C15.9344 24.9444 18.0093 27.0192 18.0093 29.5787C18.0093 29.7207 18.0029 29.8612 17.9904 30H22.75C23.8546 30 24.75 29.1046 24.75 28V22.9804C24.8941 22.9934 25.04 23 25.1875 23C27.8454 23 30 20.8454 30 18.1875C30 15.5296 27.8454 13.375 25.1875 13.375C25.04 13.375 24.8941 13.3816 24.75 13.3946V9.25C24.75 8.14543 23.8546 7.25 22.75 7.25H18.6054Z"
                fill="#1C1E21"
            />
        </svg>
    );
};
