export const calculateNewValue = (inputValue, up = false, max, min, step = 1) => {
    let newValue = inputValue;
    if (up) {
        if (max === undefined || newValue < max) {
            newValue = Number(newValue) + Number(step);
            if (newValue > max) {
                newValue = max;
            }
        }
    } else {
        if (min === undefined || newValue > min) {
            newValue = Number(newValue) - Number(step);
            if (newValue < min) {
                newValue = min;
            }
        }
    }

    return newValue;
};
