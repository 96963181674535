import axios from 'axios';
import { HTTP_METHODS } from '../../../../../network/http/constants';
import { CLOUD_CALLS, SSO_TYPE } from './constants';
import { getContentTypeJsonHeader } from '../../../../../network/http/HttpHeadersManager/utils';
import oemManager from '../../../../oem';

class ItarianApi {
    constructor() {}

    #buildItarianSsoIntegrationAuthRequestPayload(token) {
        return {
            call: CLOUD_CALLS.SSO_INTEGRATION_AUTH,
            params: {
                sso_type: SSO_TYPE,
                sso_data: {
                    token: token,
                },
            },
        };
    }

    #buildItarianSsoIntegrationAuthRequestConfig(token) {
        return {
            url: oemManager.oem.getCloudApiUrl(),
            method: HTTP_METHODS.POST,
            headers: getContentTypeJsonHeader(),
            data: this.#buildItarianSsoIntegrationAuthRequestPayload(token),
        };
    }

    /**
     * Fetch data from Cloud by Itarian SSO token
     * @see {@link https://confluence.mios.com/display/EPD/SSO+Integration+-+Auth SSO Integration Auth}
     * @param {string} token - SSO token
     * @returns {Promise<Object>} API response
     * */
    async fetchItarianSsoDataByToken(token) {
        const requestConfig = this.#buildItarianSsoIntegrationAuthRequestConfig(token);

        return axios.request(requestConfig);
    }
}

export { ItarianApi };
