export const TIMEZONE_API_URL = 'https://maps.googleapis.com/maps/api/timezone/json';
export const GEOCODE_API_URL = 'https://maps.googleapis.com/maps/api/geocode/json';
export const API_KEY = 'AIzaSyCK44DEtfeZEf6M_yMk7Hac57Vd6jKswwQ';

export const DEFAULT_EZLO_LOCATION = {
    latitude: 40.830228,
    longitude: -74.205965,
    timeOffset: -14400,
    timeZoneId: 'America/New_York',
};
export const MAP_MARKER_ICON_URL = './images/icons/map-marker-icon.png';
