export const groupResultByAbstractUuid = (arrayOfVariable) => {
    const mappedResult = [];

    arrayOfVariable.forEach((item) => {
        const existingAbstractUuid = mappedResult.find((abstract) => abstract.abstractUUID === item.uuid);

        const { uuid } = item;
        delete item.uuid;
        if (existingAbstractUuid) {
            existingAbstractUuid.mappings.push(item);
        } else {
            mappedResult.push({
                abstractUUID: uuid,
                mappings: [item],
            });
        }
    });

    return mappedResult;
};
