import md5 from 'md5';
import { tt } from './language';
// import wsm from './wsm';
// import { UPLOAD_URL } from '../constants/URLs';
import { bugsnagNotify } from '../containers/ErrorBoundary/utils';
// import * as types from '../constants/ActionTypes/VirtualDeviceManager';

let idCounter = 0;

export const findInObject = function (arraytosearch, key, valuetosearch) {
    for (let i = 0; i < arraytosearch.length; i++) {
        if (arraytosearch[i][key] == valuetosearch) {
            return i;
        }
    }

    return null;
};

export const getPageName = function (pageObj) {
    return tt(pageObj.name);
};

export const getFromHashMap = (hashMap, key, value) => {
    return hashMap.reduce((obj, item) => (item[key] === value ? item : obj), {});
};

export const getUsersPosition = () =>
    new Promise((resolve, reject) => {
        const onSuccess = (pos) =>
            resolve({
                latitude: pos.coords.latitude,
                longitude: pos.coords.longitude,
            });
        const onError = (e) => {
            bugsnagNotify(e, { function: 'getUsersPosition' });
            reject(e);
        };

        navigator.geolocation.getCurrentPosition(onSuccess, onError);
    });

export const getDistanceByCoords = (coords1, coords2) => {
    const R = 6371;
    const deg2Rad = (deg) => (deg * Math.PI) / 180;
    const { lat1, lon1, lat2, lon2 } = {
        lat1: deg2Rad(coords1.latitude),
        lon1: deg2Rad(coords1.longitude),
        lat2: deg2Rad(coords2.latitude),
        lon2: deg2Rad(coords2.longitude),
    };
    const x = (lon2 - lon1) * Math.cos((lat1 + lat2) / 2);
    const y = lat2 - lat1;

    return Math.sqrt(x * x + y * y) * R;
};

export const getEzloCoords = (ezlo) => {
    const { latitude = 0, longitude = 0 } = ezlo.properties.ezloLocation;

    return {
        latitude,
        longitude,
    };
};

export const getNearestEzloTo = (userCoords, ezlos) => {
    let minDistance = Infinity;
    let nearestEzlo = null;

    ezlos.forEach((ezlo) => {
        const distanceToEzlo = getDistanceByCoords(userCoords, getEzloCoords(ezlo));
        if (distanceToEzlo < minDistance) {
            minDistance = distanceToEzlo;
            nearestEzlo = ezlo;
        }
    });

    return nearestEzlo;
};

export const changeHSL = (hexColor, { hue = 0, saturation = 0, lightness = 0 }) => {
    //https://en.wikipedia.org/wiki/HSL_and_HSV#Formal_derivation
    const rgb = [hexColor.substr(1, 2), hexColor.substr(3, 2), hexColor.substr(5, 2)].map(
        (hex) => Number.parseInt(hex, 16) / 255,
    );
    const [r, g, b] = [...rgb];
    const M = Math.max(...rgb);
    const m = Math.min(...rgb);
    const C = M - m;
    const H =
        (() => {
            if (M === r) {
                return ((g - b) / C) % 6;
            } else if (M === g) {
                return (b - r) / C + 2;
            } else if (M === b) {
                return (r - g) / C + 4;
            } else {
                return 0;
            }
        })() * 60;
    const L = (M + m) / 2;
    const S = (() => {
        if (L === 1) {
            return 0;
        } else {
            return C / (1 - Math.abs(2 * L - 1));
        }
    })();
    const hsl = [H, S * 100, L * 100].map(Math.round);
    const [h, s, l] = [hsl[0] + hue, hsl[1] + saturation, hsl[2] + lightness];

    return `hsl(${h}, ${s}%, ${l}%)`;
};

// eslint-disable-next-line
export const uploadDataToStaticServer = (formData, serial) =>
    // eslint-disable-next-line
    new Promise((resolve, reject) => {
        // if (!formData) {
        //   reject('uploadDataToStaticServer: Data can\'t be empty');
        //   return;
        // }
        //
        // // network.send('getStaticToken', { serial }, (data) => {
        // wsm.send(serial, 'getStaticToken', { serial }, (data) => {
        //   jQuery.ajax({
        //     url: UPLOAD_URL + data.token,
        //     data: formData,
        //     cache: false,
        //     contentType: false,
        //     processData: false,
        //     type: 'POST',
        //     success: (response) => {
        //       resolve(response);
        //     },
        //     error: (error) => {
        //       reject(error);
        //     }
        //   });
        // }, () => {}, error => console.error('uploadDataToStaticServer: getStaticToken error'));
    });

export const uniqueId = (prefix) => {
    const id = ++idCounter + '';

    return md5(prefix ? prefix + id : id);
};

export const keepMinMax = (value, min, max) => {
    if (value < min) {
        value = min;
    }

    if (value > max) {
        value = max;
    }

    return value;
};
