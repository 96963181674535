import { createSlice } from '@reduxjs/toolkit';
import { DUPLICATE_MESHBOT } from '../constants';
import { LOGOUT } from 'constants/ActionTypes';

const initialState = {
    isDuplicate: false,
    isButtonDisabled: false,
    type: null,
    meshBotId: '',
    serial: '',
    editAfterDuplicate: false,
    cloudMeshbotPayload: {},
};

const meshbotDuplicate = createSlice({
    name: DUPLICATE_MESHBOT,
    initialState,
    reducers: {
        setDuplicateMeshBotData: (state, action) => {
            Object.assign(state, action.payload);
        },
        updateDuplicateMeshBotCheckbox: (state, action) => {
            state.editAfterDuplicate = action.payload;
        },
        updateDuplicateCloudMeshBotPayload: (state, action) => {
            state.cloudMeshbotPayload = action.payload;
        },
        updateIsDuplicateMeshBot: (state, action) => {
            state.isDuplicate = action.payload;
        },
        updateIsDuplicateButtonDisabled: (state, action) => {
            state.isButtonDisabled = action.payload;
        },
        resetDuplicateMeshBotState: (state) => {
            Object.assign(state, { ...initialState });
        },
    },
    extraReducers: (builder) => {
        builder.addCase(LOGOUT, () => initialState);
    },
});

export const {
    extraReducers,
    setDuplicateMeshBotData,
    updateDuplicateMeshBotName,
    updateDuplicateMeshBotCheckbox,
    updateDuplicateCloudMeshBotPayload,
    updateIsDuplicateMeshBot,
    updateIsDuplicateButtonDisabled,
    resetDuplicateMeshBotState,
} = meshbotDuplicate.actions;

export default meshbotDuplicate;
export const meshbotDuplicateStoreReducer = meshbotDuplicate.reducer;
