import produce from 'immer';

import at from '../../../../constants/ActionTypes/DeviceGroups';
import { NOT_EXIST_INDEX } from '../../../../constants/DevicesGroups';
import {
    updateDeviceGroupTreeStateByDevice,
    updateDeviceGroupTreeStateBySubcategory,
    updateDeviceGroupTreeStateByCategory,
} from './utils';

const INITIAL_STATE = {
    currentDeviceGroup: {},
    deviceGroupTreeState: {},
    initialDeviceGroup: {},
};

export default produce((draft, action) => {
    switch (action.type) {
        case at.BUILD_DEVICE_GROUP_OBJECT:
            draft.currentDeviceGroup = action.deviceGroupObject;

            break;
        case at.SET_DEVICE_GROUP_NAME:
            draft.currentDeviceGroup.name = action.deviceGroupName;

            break;
        case at.SET_DEVICE_GROUP_CATEGORY:
            const categoryIndex = draft.currentDeviceGroup.categories?.findIndex((category) => {
                return category.category === action.deviceGroupCategory;
            });

            if (categoryIndex === NOT_EXIST_INDEX) {
                draft.currentDeviceGroup.categories = [
                    ...draft.currentDeviceGroup.categories,
                    { category: action.deviceGroupCategory },
                ];
            }
            break;
        case at.SET_DEVICE_GROUP_ID:
            draft.currentDeviceGroup.id = action.id;

            break;
        case at.SET_DEVICE_GROUP_SUBCATEGORY:
            draft.currentDeviceGroup.categories = action.deviceGroup.categories;

            break;

        case at.REMOVE_DEVICE_GROUP_CATEGORY:
            draft.currentDeviceGroup.categories = [
                ...draft.currentDeviceGroup.categories.filter((category) => category.category !== action.category),
            ];

            break;
        case at.REMOVE_DEVICE_GROUP_SUBCATEGORY:
            draft.currentDeviceGroup.categories = action.deviceGroup.categories;

            break;

        case at.SET_DEVICE_GROUP_TREE_STATE:
            draft.deviceGroupTreeState = action.deviceGroupState;

            break;
        case at.UPDATE_DEVICE_GROUP_TREE_STATE_BY_CATEGORY:
            updateDeviceGroupTreeStateByCategory(draft, action.payload);

            break;
        case at.UPDATE_DEVICE_GROUP_TREE_STATE_BY_SUBCATEGORY:
            updateDeviceGroupTreeStateBySubcategory(draft, action.payload);

            break;
        case at.UPDATE_DEVICE_GROUP_TREE_STATE_BY_DEVICE:
            updateDeviceGroupTreeStateByDevice(draft, action.payload);

            break;
        case at.GET_DEVICE_GROUP.success:
            draft.initialDeviceGroup = action.data;
            draft.initialDeviceGroup.id = action?.data?.id;
            draft.currentDeviceGroup.name = action.data.name;
            draft.currentDeviceGroup.categories = action.data.categories;
            draft.currentDeviceGroup.devices = action.data.devices;
            draft.currentDeviceGroup.exceptions = action.data.exceptions;

            break;
        case at.SET_INITIAL_DEVICE_GROUP_TREE_STATE:
            draft.deviceGroupTreeState = action.newDeviceGroupTreeState;

            break;
        case at.SET_DEVICE_GROUP_SERIAL:
            draft.currentDeviceGroup.serial = action.serial;

            break;
        default:
            break;
    }
}, INITIAL_STATE);
