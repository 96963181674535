import React from 'react';

export const IcControlsLedStrip = (props) => {
    return (
        <svg {...props} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26 12C25.4507 12.6137 24.6524 13 23.7639 13H9.00002C7.34317 13 6.00002 14.3431 6.00002 16V18H6V19H6.99978C7.5491 18.3863 8.34739 18 9.23589 18H24C25.6569 18 27 16.6569 27 15V13H26.9998V12H26ZM18 15.5C18 16.3284 17.3285 17 16.5 17C15.6716 17 15 16.3284 15 15.5C15 14.6716 15.6716 14 16.5 14C17.3285 14 18 14.6716 18 15.5Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24 5H6.00002V10H23.7639C24.6524 10 25.4507 10.3863 26 11H26.9998V10H27V8C27 6.34315 25.6569 5 24 5ZM10.5 9C11.3285 9 12 8.32843 12 7.5C12 6.67157 11.3285 6 10.5 6C9.6716 6 9.00002 6.67157 9.00002 7.5C9.00002 8.32843 9.6716 9 10.5 9ZM24 7.5C24 8.32843 23.3284 9 22.5 9C21.6716 9 21 8.32843 21 7.5C21 6.67157 21.6716 6 22.5 6C23.3284 6 24 6.67157 24 7.5Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.23589 21C8.34739 21 7.5491 20.6137 6.99978 20H6V21H6.00002V23C6.00002 24.6569 7.34317 26 9.00002 26H27V21H9.23589ZM10.5 25C11.3285 25 12 24.3284 12 23.5C12 22.6716 11.3285 22 10.5 22C9.6716 22 9.00002 22.6716 9.00002 23.5C9.00002 24.3284 9.6716 25 10.5 25ZM24 23.5C24 24.3284 23.3284 25 22.5 25C21.6716 25 21 24.3284 21 23.5C21 22.6716 21.6716 22 22.5 22C23.3284 22 24 22.6716 24 23.5Z"
            />
        </svg>
    );
};
