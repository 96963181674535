import React, { useMemo } from 'react';
import { LABEL_MENU_VALUES } from '../../../constants';
import EditLabelDialog from '../EditLabelDialog';
import DeleteLabelDialog from '../DeleteLabelDialog';

const LabelDialogsBySelectedMenuItem = ({ selectedLabelMenuItem, setSelectedLabelMenuItem, labelData, labelUuid }) => {
    const closeDialog = () => {
        setSelectedLabelMenuItem(null);
    };

    const isOpen = useMemo(
        () => ({
            editDialog: selectedLabelMenuItem === LABEL_MENU_VALUES.EDIT,
            deleteDialog: selectedLabelMenuItem === LABEL_MENU_VALUES.DELETE,
        }),
        [selectedLabelMenuItem],
    );

    return (
        <>
            <EditLabelDialog
                open={isOpen.editDialog}
                closeDialog={closeDialog}
                labelData={labelData}
                labelUuid={labelUuid}
            />
            <DeleteLabelDialog
                dialogProps={{
                    open: isOpen.deleteDialog,
                    closeDialog,
                }}
                labelUuid={labelUuid}
            />
        </>
    );
};

export default LabelDialogsBySelectedMenuItem;
