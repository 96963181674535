import React from 'react';

const IcDoorLocksDoorLockUnlocked = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
            <path
                d="M30 9C30 11.2 28.2 13 26 13H14C12.9 13 12 12.1 12 11C12 9.9 12.9 9 14 9H30ZM23 15V21C23 26 19 30 14 30C9 30 5 26 5 21V11C5 6 9 2 14 2C17.5 2 20.6 4 22.1 7H14C11.8 7 10 8.8 10 11C10 13.2 11.8 15 14 15H23ZM14 19C13 19 12.2 19.9 12.3 21L12.8 24C12.9 24.6 13.4 25 14 25C14.6 25 15.1 24.6 15.2 24L15.7 21C15.8 19.9 15 19 14 19Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcDoorLocksDoorLockUnlocked;
