import React from 'react';
import { Table } from 'lib/material-react-table';

import { ROWS_PER_PAGE } from '../../../../../EzloMeshbots/constants';
import { ONE_INT } from 'constants/Variables';

export const SystemHistoryTableUI = (props) => {
    const { columns, systemHistoryList } = props;

    return (
        <Table
            columns={columns}
            data={systemHistoryList}
            initialState={{
                pagination: {
                    pageSize: ROWS_PER_PAGE[ONE_INT],
                },
            }}
            muiTablePaginationProps={{
                rowsPerPageOptions: ROWS_PER_PAGE,
            }}
        />
    );
};
