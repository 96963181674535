import React from 'react';
import { IconButton } from 'lib/@mui';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { Tooltip } from 'components';
import { MeshBotAction } from '../../actions';
import { shouldShowTooltipForLocalMeshbot } from './utils';
import { DuplicateIcon } from '../../assets/icons';
import { useTranslate } from 'features/languages';
import { EZLOGIC_TITLE_CLONE_MESHBOT_FROM_ADVANCED_SCENE_1_70 } from 'constants/language_tokens';
import { MESHBOT_TYPES } from 'containers/Ezlo/EzloMeshbots/constants';

import styles from './meshbotDuplicate.module.scss';

const MeshbotDuplicate = ({ renderedCellValue: params }) => {
    const dispatch = useDispatch();
    const { t } = useTranslate();
    const ezloData = useSelector((state) => state.ezlo?.data?.[params?.serial]);
    const isShowToolTip = shouldShowTooltipForLocalMeshbot(params, ezloData);
    const isEzlopiType = params.type === MESHBOT_TYPES.EZLOPI;

    const handleSelectRule = () => {
        dispatch(MeshBotAction.updateDuplicateMeshbotData(params, t));
    };

    return (
        <div className={styles.meshbotDuplicateWrapper}>
            <Tooltip
                title={
                    isShowToolTip && (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: t(EZLOGIC_TITLE_CLONE_MESHBOT_FROM_ADVANCED_SCENE_1_70),
                            }}
                        ></div>
                    )
                }
                position="top"
            >
                <div>
                    <IconButton
                        color="primary"
                        onClick={handleSelectRule}
                        disabled={params?.isDisabled || isShowToolTip || isEzlopiType}
                        className={classNames({
                            [styles.disabled]: params?.isDisabled || isShowToolTip || isEzlopiType,
                        })}
                    >
                        <DuplicateIcon />
                    </IconButton>
                </div>
            </Tooltip>
        </div>
    );
};

export default MeshbotDuplicate;
