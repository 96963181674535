import React from 'react';

export const IcControlsDownload = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M14.5 15H11L15.2929 19.2929C15.6834 19.6834 16.3166 19.6834 16.7071 19.2929L21 15H17.5V4H14.5V15ZM28 25C28 26.7 26.7 28 25 28H7C5.3 28 4 26.7 4 25H7H25H28Z"
                fill="#1C1E21"
            />
        </svg>
    );
};
