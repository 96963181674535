import React from 'react';
import { LinearProgress } from 'lib/@mui';
import { toast, TOAST_TYPE } from '../../../../components/Toast';
import { useTranslate } from '../../../languages';

import styles from './styles.module.scss';

/**
 * React component for displaying a warning toast with translated content and linear progress indicator.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.contentTranslateKey - The translation key for the content of the warning message.
 * @returns {React.Component} Returns the React component for the warning toast content.
 */
const WarningToastContent = ({ contentTranslateKey }) => {
    const { t } = useTranslate();

    return (
        <div className={styles.warningMessage}>
            {t(contentTranslateKey)}
            <div className={styles.progressBox}>
                <LinearProgress color="inherit" />
            </div>
        </div>
    );
};

/**
 * Displays a warning toast with a linear progress indicator.
 *
 * @param {string} contentTranslateKey - The translation key for the content of the warning message.
 * @param {Function} onClose - Callback function to be executed when the toast is closed.
 * @returns {string|number} Returns the identifier for the displayed toast.
 */
export const showWarningToast = (contentTranslateKey, onClose) => {
    return toast(<WarningToastContent contentTranslateKey={contentTranslateKey} />, {
        type: TOAST_TYPE.WARNING,
        autoClose: false,
        onClose,
        className: styles.warningToastBox,
        hideProgressBar: false,
    });
};
