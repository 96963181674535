export default {
    SET_USERS_LIST: {
        success: 'SET_USERS_LIST/success',
        rejected: 'SET_USERS_LIST/rejected',
    },
    CREATE_NEW_USER: {
        rejected: 'CREATE_NEW_USER/rejected',
    },
    UPDATE_EXISTING_USER: {
        rejected: 'UPDATE_EXISTING_USER/rejected',
    },
    UPDATE_CURRENT_ACCOUNT: {
        rejected: 'UPDATE_CURRENT_ACCOUNT/rejected',
    },
    DELETE_SELECTED_USER: {
        rejected: 'DELETE_SELECTED_USER/rejected',
    },
    SET_USER_PIN_CODES_LIST: {
        success: 'SET_USER_PIN_CODES_LIST/success',
        rejected: 'SET_USER_PIN_CODES_LIST/rejected',
    },
    CREATE_PIN_CODE: {
        rejected: 'CREATE_PIN_CODE/rejected',
    },
    UPDATE_PIN_CODE: {
        rejected: 'UPDATE_PIN_CODE/rejected',
    },
    DELETE_PIN_CODE: {
        rejected: 'DELETE_PIN_CODE/rejected',
    },
    SET_SELECTED_USER: 'SET_SELECTED_USER',
    SET_SELECTED_PIN_CODE: 'SET_SELECTED_PIN_CODE',
    SET_SELECTED_USER_DETAILS: 'SET_SELECTED_USER_DETAILS',
    SET_IS_LOADING: 'SET_IS_LOADING',
    ADD_PIN_CODE_TO_DEVICE: {
        rejected: 'ADD_PIN_CODE_TO_DEVICE/rejected',
    },
    UPDATE_PIN_CODE_TO_DEVICE: {
        rejected: 'UPDATE_PIN_CODE_TO_DEVICE/rejected',
    },
    REMOVE_PIN_CODE_TO_DEVICE: {
        rejected: 'REMOVE_PIN_CODE_TO_DEVICE/rejected',
    },
    CHANGE_USER_PASSWORD: {
        rejected: 'CHANGE_USER_PASSWORD/rejected',
    },
    RESEND_EMAIL: {
        rejected: 'RESEND_EMAIL/rejected',
    },
    SET_REQUEST_STATUS: {
        success: 'SET_REQUEST_STATUS/success',
        clear: 'SET_REQUEST_STATUS/clear',
    },
};
