import * as lt from 'constants/language_tokens';
import { SIZES } from 'lib/@mui';
import { EZLO_PLUS_MODEL, EZLO_SECURITY_MODEL } from 'services/hub';

/**
 * Accepted file format for controller file upload.
 * @type {string[]}
 */
export const ACCEPT_FILE_FORMAT_FOR_CONTROLLER_FILE_UPLOAD = ['.tgz'];
/**
 * Error code for an invalid file name.
 * @type {string}
 */
export const FILE_INVALID_NAME = 'file-invalid-name';
/**
 * Error code for an invalid file type.
 * @type {string}
 */
export const FILE_INVALID_TYPE = 'file-invalid-type';
/**
 * Error code for a required field.
 * @type {string}
 */
export const FIELD_IS_REQUIRED = 'field-is-required';
/**
 * Error messages corresponding to error codes.
 * @type {Object.<string, string>}
 */
export const FORM_ERRORS_MESSAGE_BY_CODE = {
    [FILE_INVALID_NAME]: lt.EZLOGIC_TITLE_FILE_INVALID_NAME_ERROR_TEXT,
    [FILE_INVALID_TYPE]: lt.EZLOGIC_TITLE_FILE_INVALID_TYPE_ERROR_TEXT,
    [FIELD_IS_REQUIRED]: lt.EZLOGIC_TITLE_THE_FIELD_IS_REQUIRED,
};
/**
 * The field identifier for the hub file.
 * @type {string}
 */
export const HUB_FILE_FIELD = 'hub-file-field';
/**
 * The field identifier for the select hub field.
 * @type {string}
 */
export const SELECT_HUB_FIELD = 'select-hub-field';
/**
 * Settings object for configuring a slider component.
 *
 * @type {Object}
 * @property {number} slidesToShow - The number of slides to show at once.
 * @property {number} slidesToScroll - The number of slides to scroll when navigating.
 */
export const sliderSettings = {
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
        {
            breakpoint: 550,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                rows: 4,
            },
        },
    ],
};
/**
 * Configuration for a warning dialog with a maximum width of extra small.
 *
 * @constant
 * @type {Object}
 */
export const warningDialogConfig = { maxWidth: SIZES.XS };
/**
 * Identifier for the migration form page.
 *
 * @constant
 * @type {string}
 */
export const MIGRATION_FORM_PAGE = 'MIGRATION_FORM_PAGE';
/**
 * Identifier for the migration error page.
 *
 * @constant
 * @type {string}
 */
export const MIGRATION_ERROR_PAGE = 'MIGRATION_ERROR_PAGE';
/**
 * Identifier for the migration success page.
 *
 * @constant
 * @type {string}
 */
export const MIGRATION_SUCCESS_PAGE = 'MIGRATION_SUCCESS_PAGE';
/**
 * Identifier for the migration process page.
 *
 * @constant
 * @type {string}
 */
export const MIGRATION_PROCESS_PAGE = 'MIGRATION_PROCESS_PAGE';
/**
 * Identifier for the migration module.
 *
 * @constant
 * @type {string}
 */
export const MIGRATION = 'migration';

/**
 * Array containing supported migration EZLO models.
 * @type {string[]}
 */
export const SUPPORTED_MIGRATION_EZLO_MODELS = [EZLO_PLUS_MODEL, EZLO_SECURITY_MODEL];
/**
 * Field identifiers for the migration form.
 *
 * @constant
 * @type {Object}
 * @property {string} FILE - Identifier for the file field.
 * @property {string} CHOSE_HUB - Identifier for the choseHub field.
 */
export const FIELDS = {
    FILE: 'file',
    CHOSE_HUB: 'choseHub',
};
