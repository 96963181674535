import React from 'react';

const IcControlsEdit = (props) => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M24 25H6.4H0V20.9C0 19.4 0.6 18 1.6 17L14.5 4.10002L16.6 6.20002L3.7 19.1C3.3 19.6 3 20.2 3 20.9V22H5.1L18.7 8.40002L20.8 10.5L9.4 22H21C22.7 22 24 23.3 24 25ZM23 2.00002C21.2 0.200024 18.4 0.200024 16.6 2.00002L23 8.40002C24.7 6.60002 24.7 3.80002 23 2.00002Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcControlsEdit;
