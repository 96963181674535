export const BACKUPS_FETCH_DEVICE_BACKUPS = 'BACKUPS_FETCH_DEVICE_BACKUPS';
export const SET_BACKUPS_FILTERS = 'SET_BACKUPS_FILTERS';
export const SET_INITIAL_BACKUPS_FILTERS = 'SET_INITIAL_BACKUPS_FILTERS';
export const BACKUPS_FETCH_DEVICE_BACKUPS_ON_SUCCESS = 'BACKUPS_FETCH_DEVICE_BACKUPS_ON_SUCCESS';
export const BACKUPS_FETCH_DEVICE_BACKUPS_ON_ERROR = 'BACKUPS_FETCH_DEVICE_BACKUPS_ON_ERROR';

export const BACKUPS_CREATE_BACKUP = 'BACKUPS_CREATE_BACKUP';
export const BACKUPS_CREATE_BACKUP_IN_PROGRESS = 'BACKUPS_CREATE_BACKUP_IN_PROGRESS';
export const BACKUPS_CREATE_BACKUP_ON_SUCCESS = 'BACKUPS_CREATE_BACKUP_ON_SUCCESS';
export const BACKUPS_CREATE_BACKUP_ON_ERROR = 'BACKUPS_CREATE_BACKUP_ON_ERROR';

export const BACKUPS_SELECT_BACKUP = 'BACKUPS_SELECT_BACKUP';
export const BACKUPS_CLEAR_SELECTED_BACKUP = 'BACKUPS_CLEAR_SELECTED_BACKUP';
export const BACKUPS_SELECT_DEFAULT_BACKUP = 'BACKUPS_SELECT_DEFAULT_BACKUP';
export const BACKUPS_RESTORE_BACKUP = 'BACKUPS_RESTORE_BACKUP';
export const BACKUPS_RESTORE_BACKUP_CONFIRM = 'BACKUPS_RESTORE_BACKUP_CONFIRM';
export const BACKUPS_RESTORE_BACKUP_IN_PROGRESS = 'BACKUPS_RESTORE_BACKUP_IN_PROGRESS';
export const BACKUPS_RESTORE_BACKUP_ON_SUCCESS = 'BACKUPS_RESTORE_BACKUP_ON_SUCCESS';
export const BACKUPS_RESTORE_BACKUP_ON_ERROR = 'BACKUPS_RESTORE_BACKUP_ON_ERROR';

export const BACKUPS_SET_TOKEN = 'BACKUPS_SET_TOKEN';
export const BACKUPS_ON_FINISH = 'BACKUPS_ON_FINISH';
export const BACKUPS_IS_BACKUP_SUPPORTED = 'BACKUPS_IS_BACKUP_SUPPORTED';
export const BACKUPS_KEEP_BACKUP = 'BACKUPS_KEEP_BACKUP'; // not supported
