import React from 'react';

const IcDeviceVoiceAssistants = (props) => {
    return (
        <svg width="26" height="30" viewBox="0 0 26 30" fill="#1C1E21" {...props}>
            <path d="M6 14V7C6 3.1 9.1 0 13 0C16.9 0 20 3.1 20 7V14C20 17.9 16.9 21 13 21C9.1 21 6 17.9 6 14ZM26 14H23C23 19.5 18.5 24 13 24C7.5 24 3 19.5 3 14H0C0 20.7 5 26.2 11.5 26.9V30H14.5V26.9C21 26.2 26 20.7 26 14Z" />
        </svg>
    );
};

export default IcDeviceVoiceAssistants;
