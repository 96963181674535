import { setLocalStorageProcess, getLocalStorageProcess, removeLocalStorageProcess } from './utils';

// TODO: simplify methods names
class LocalStorageService {
    constructor() {}

    setLocalStorage(key, payload) {
        return setLocalStorageProcess(key, payload);
    }

    getLocalStorage(languageKey) {
        return getLocalStorageProcess(languageKey);
    }

    removeLocalStorage(key) {
        return removeLocalStorageProcess(key);
    }
}

export { LocalStorageService };
