import wsm from 'helpers/wsm';
import { isNumber, isString } from 'lodash';

/**
 * Unsubscribes from a websocket event using the WebsocketManager instance.
 *
 * @function
 * @name unsubscribe
 * @memberof wsm
 * @instance
 * @method
 *
 * @param {string|number} serial - controller serial number
 * @param {string} name - event name
 * @param {function} cb - callback to execute
 * @returns {void}
 */
export const unsubscribe = wsm.unsubscribe.bind(wsm);

/**
 * Subscribes to a websocket event using the WebsocketManager instance.
 *
 * @function
 * @name subscribe
 * @memberof wsm
 * @instance
 * @method
 *
 * @param {string|number} serial - controller serial number
 * @param {string} name - event name
 * @param {Function} cb - The callback function to be invoked on event reception.
 * @returns {void}
 */
export const subscribe = wsm.subscribe.bind(wsm);

/**
 * The method enqueues the specified data to be transmitted to the server
 * over the websocket connection by controller serial number.
 * More info about JSON-RPC protocol you can find {@link https://www.jsonrpc.org/specification here}
 *
 * @function
 * @name send
 * @memberof wsm
 * @instance
 * @method
 *
 * @param {string|number} serial - controller serial number
 * @param {string} method - json-rpc method name
 * @param {Object} params - json-rpc params
 * @param {function} onSuccess - onsuccess callback
 * @param {function} onError - onerror callback
 * @param {boolean} unDetect - json-rpc detect notificationCb
 * @returns {void}
 */
export const send = wsm.send.bind(wsm);

/**
 * Validates the hub serial for calls.
 *
 * @param {String|Number} serial - hub serial.
 * @throws {Error} - Throws an error if required parameters are missing or not of the correct type.
 * @returns {void}
 */
export const validateSerial = (serial) => {
    if (!isString(serial) && !isNumber(serial)) {
        throw new Error('serial must be string or number');
    }
};
