import { ON_CHANGE, STATIC } from '../../constants/MeshbotConstant';
import {
    getDailyTemplate,
    getIntervalTemplate,
    getMonthlyTemplate,
    getOnceTemplate,
    getSpecialTimeOfTheDay,
    getWeeklyTemplate,
    SCHEDULE_DATE_TYPES,
} from './dateTemplatesForCloudMeshbot';
import { NOTIFICATION_TEMPLATE } from '../../constants/NotificationTemplates';

const { INTERVAL, DAILY, WEEKLY, MONTHLY, ONCE, SPECIAL_TIME_OF_THE_DAY } = SCHEDULE_DATE_TYPES;

const createDateBlock = (type) => {
    switch (type) {
        case INTERVAL:
            return getIntervalTemplate();

        case DAILY:
            return getDailyTemplate();

        case WEEKLY:
            return getWeeklyTemplate();

        case MONTHLY:
            return getMonthlyTemplate();

        case ONCE:
            return getOnceTemplate();

        case SPECIAL_TIME_OF_THE_DAY:
            return getSpecialTimeOfTheDay();

        default:
            return;
    }
};

// specialDate have two meaning
// for device triggers - variable
// for date triggers - date value

export const rgbObjectForRequest = (values, uuid, capability) => {
    return [
        {
            name: '==',
            parameters: [
                {
                    name: 'av',
                    parameters: [
                        {
                            abstract: uuid,
                            capability,
                            variable: 'red',
                        },
                    ],
                },
                {
                    name: STATIC,
                    parameters: [
                        {
                            value: '0',
                        },
                    ],
                },
            ],
        },
        {
            name: '==',
            parameters: [
                {
                    name: 'av',
                    parameters: [
                        {
                            abstract: uuid,
                            capability,
                            variable: 'green',
                        },
                    ],
                },
                {
                    name: STATIC,
                    parameters: [
                        {
                            value: '0',
                        },
                    ],
                },
            ],
        },
        {
            name: '==',
            parameters: [
                {
                    name: 'av',
                    parameters: [
                        {
                            abstract: uuid,
                            capability,
                            variable: 'blue',
                        },
                    ],
                },
                {
                    name: STATIC,
                    parameters: [
                        {
                            value: '0',
                        },
                    ],
                },
            ],
        },
    ];
};

const createNotificationTemplateMeshBot = () => {
    return [
        {
            name: '==',
            parameters: [
                {
                    name: 'av',
                    parameters: [
                        {
                            capability: '',
                            variable: '',
                            abstract: '{abstract.uuid}',
                        },
                    ],
                },
                {
                    name: 'static',
                    parameters: [
                        {
                            value: '',
                            transform: '',
                        },
                    ],
                },
            ],
        },
    ];
};

const blockCloudTriggerTemplate = (type, dataForDevice, dataForTime, specialDate) => {
    const createStaticObj = () => {
        switch (type) {
            case STATIC:
                return { value: dataForDevice?.value, transform: null };

            case 'schedule':
                return createDateBlock(specialDate);

            default:
                const variable = specialDate && dataForDevice?.variable?.find((item) => item === specialDate);

                return {
                    name: 'av',
                    parameters: [
                        {
                            abstract: dataForDevice?.id,
                            capability: dataForDevice?.capability,
                            variable: variable ? variable : dataForDevice?.variable?.[0],
                        },
                    ],
                };
        }
    };

    const createNotObj = () => {
        if (type === 'schedule') {
            return {
                name: 'timezone',
                timezone: 'America/New_York',
            };
        }

        return {
            name: STATIC,
            parameters: [dataForTime.value],
        };
    };

    const createDeviceOnChangeObj = () => {
        return [
            {
                name: ON_CHANGE,
                parameters: [
                    {
                        abstract: dataForDevice?.id,
                        capability: dataForDevice?.capability,
                        variable: dataForDevice?.variable,
                    },
                ],
            },
        ];
    };

    const createDeviceObj = (name) => {
        return [
            {
                name: name,
                parameters: [
                    {
                        name: 'av',
                        parameters: [
                            {
                                abstract: dataForDevice.id,
                                capability: dataForDevice.capability,
                                variable: dataForDevice.variable,
                            },
                        ],
                    },
                    {
                        name: STATIC,
                        parameters: [
                            {
                                value: '',
                                transform: '',
                            },
                        ],
                    },
                ],
            },
        ];
    };

    switch (type) {
        case 'manual':
            return { name: type, parameters: [] };
        case NOTIFICATION_TEMPLATE:
            return createNotificationTemplateMeshBot();
        case STATIC:
            return {
                name: type,
                parameters: [createStaticObj()],
            };
        case 'not':
            return {
                name: type,
                parameters: [createNotObj()],
            };
        case ON_CHANGE:
            return createDeviceOnChangeObj(ON_CHANGE);
        case '==':
            return createDeviceObj('==');
        case '!==':
            return createDeviceObj('!==');
        case '<':
            return createDeviceObj('<');
        case '>':
            return createDeviceObj('>');
        case 'deviceState':
            return createDeviceObj('==');
        default:
            return {
                name: type,
                parameters: [createStaticObj(), createNotObj()],
            };
    }
};

export default blockCloudTriggerTemplate;
