import React from 'react';

export const PageNotFound = (props) => (
    <svg {...props} width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M173.289 153.596H138.3V140.241L170.199 93H190.177V138.143H200V153.596H190.177V166.179H173.289V153.596ZM173.289 138.254V121.808L173.731 113.199L157.506 137.15V138.143H173.289V138.254Z"
            fill="#1F2581"
        />
        <path
            d="M98.7858 161.653C117.195 161.653 132.119 146.73 132.119 128.32C132.119 109.911 117.195 94.9868 98.7858 94.9868C80.3763 94.9868 65.4525 109.911 65.4525 128.32C65.4525 146.73 80.3763 161.653 98.7858 161.653Z"
            fill="#1F2581"
        />
        <path
            d="M132.009 126.002C123.841 130.086 114.018 134.28 103.422 138.143C91.9425 142.337 81.1257 145.538 71.7438 147.746C74.6136 151.719 78.2559 155.031 82.5606 157.459C90.5076 155.362 98.8961 152.713 107.505 149.622C115.452 146.753 122.958 143.662 129.801 140.461C131.236 136.708 132.119 132.624 132.119 128.32C132.119 127.547 132.119 126.775 132.009 126.002Z"
            fill="black"
        />
        <path
            d="M169.978 102.382C166.556 93.0002 131.898 96.8634 92.6047 111.212C53.3111 125.561 24.2824 144.766 27.7041 154.148C31.1257 163.53 65.7836 159.667 105.077 145.318C144.371 131.08 173.399 111.874 169.978 102.382ZM100.883 134.06C69.5363 145.428 42.384 149.954 40.1765 144.104C38.0794 138.254 61.6997 124.236 93.1566 112.757C124.503 101.389 151.656 96.8634 153.863 102.713C155.96 108.563 132.229 122.581 100.883 134.06Z"
            fill="#FE5C15"
        />
        <path
            d="M83.9954 139.688C60.2647 146.753 41.9424 148.85 40.2868 144.104C38.6312 139.578 52.4281 130.196 73.1786 120.814C73.0683 120.262 73.0683 119.711 72.9579 119.159C44.1499 131.962 24.8343 146.421 27.704 154.148C31.1257 163.419 64.9005 159.666 103.532 145.87C103.09 145.87 102.649 145.98 102.207 145.98C95.2537 145.98 88.9623 143.552 83.9954 139.688Z"
            fill="#E82E10"
        />
        <path
            d="M99.3376 128.982C81.6775 134.28 65.5627 146.753 65.5627 128.32C65.5627 109.887 80.4634 94.9868 98.7857 94.9868C111.92 94.9868 123.289 102.603 128.697 113.64C130.905 118.055 108.168 126.333 99.3376 128.982Z"
            fill="#1F2581"
        />
        <path
            d="M73.8412 124.126C73.9516 124.678 74.0619 125.119 74.2827 125.671V125.782C74.5034 126.554 74.8346 127.327 75.1657 128.099C75.2761 128.32 75.3864 128.651 75.4968 128.872C75.7176 129.424 76.0487 129.976 76.2694 130.528C76.3798 130.859 76.6006 131.08 76.7109 131.411C77.0421 132.073 77.4836 132.735 77.9251 133.397C78.1458 133.839 78.4769 134.17 78.6977 134.501C78.9184 134.832 79.1392 135.053 79.3599 135.384C79.6911 135.826 80.0222 136.267 80.3533 136.598C80.4637 136.709 80.5741 136.929 80.7948 137.04C81.6778 138.033 82.5608 138.916 83.5542 139.689C89.073 138.033 94.9229 136.157 100.994 133.949C112.473 129.755 122.848 125.23 131.457 120.925C131.347 120.263 131.126 119.601 130.905 118.938C130.795 118.497 130.574 117.945 130.464 117.503C130.353 117.062 130.133 116.731 130.022 116.4C129.801 115.737 129.47 114.965 129.139 114.302C129.029 114.082 128.918 113.751 128.808 113.53C128.367 112.647 127.925 111.874 127.484 111.102C127.373 110.991 127.263 110.77 127.152 110.66C126.601 109.667 125.938 108.784 125.166 107.901C118.985 100.064 109.492 94.9868 98.786 94.9868C89.5145 94.9868 81.126 98.85 75.0553 104.921C73.6204 108.453 72.8478 112.316 72.8478 116.4C72.8478 117.835 72.9582 119.269 73.1789 120.704C73.2893 121.918 73.5101 123.022 73.8412 124.126Z"
            fill="#3E46AE"
        />
        <path
            d="M35.0993 153.596H0V140.241L31.8985 93H51.8764V138.143H61.6998V153.596H51.8764V166.179H35.0993V153.596ZM35.0993 138.254V121.808L35.5408 113.199L19.3157 137.15V138.143H35.0993V138.254Z"
            fill="#3E46AE"
        />
    </svg>
);

export default PageNotFound;
