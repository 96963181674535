import React from 'react';

const IcControlsDelete = (props) => {
    return (
        <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M24 5H0C0 3.3 1.3 2 3 2H9V0H15V2H21C22.7 2 24 3.3 24 5ZM19 23H5V8H2V23C2 24.7 3.3 26 5 26H19C20.7 26 22 24.7 22 23V8H19V23Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcControlsDelete;
