export const PAASAuth = 'PAASAuthentication';
export const VOI_CONTROL = 'voi_control';
export const EZLO = 'Ezlo';
export const APIKEY = 'Apikey';
export const BASIC = 'Basic';
export const BEARER = 'Bearer';
export const OAUTH = 'Oauth';
export const NONE = 'none';
export const X_AUTH_TYPE = 'x-authentication-type';
export const DESCRIPTION_KEY = 'description';
export const ENUM_LIST_BY_FUNCTION = 'byFunction';
export const X_GET_LIST_KEY = 'x-get-list-key';
export const X_GET_LIST_VALUE = 'x-get-list-value';
export const X_GET_LIST_BY_FUNCTION_PATH = 'x-get-list-by-function-path';
export const TYPE = 'type';
export const ENUM = 'enum';
export const CALLBACK_METHODS = [
    'newCustomerCallback',
    'newChargeCallback',
    'canceledSubscriptionCallback',
    'getDeviceIdNamePair',
    'getDoorIndexList',
    'getCustomerIdList',
    'propertiesIdNamePair',
];
export const IS_CALLBACK_URL = 'isCallbackUrl';
export const TYPE_FIELD_BOOLEAN = 'boolean';
export const TYPE_FIELD_STRING = 'string';
export const TYPE_FIELD_LIST = 'list';
export const TYPE_FIELD_ARRAY = 'array';
export const TYPE_FIELD_ENUM = 'enum';
export const TYPE_FIELD_OBJECT = 'object';

export const MAX_POLLING_REQUESTS_AMOUNT = 10;

export const SUCCESS_STATUS = 1;
export const METHOD_SLICE = 1;

export const SLEEP_TIME = 2000;

export const INDEX_OF_CAPABILITY = 0;
export const INDEX_OF_VALUE = 2;

export const mapTypes = {
    STANDARD: 'STANDARD',
    ADVANCED: 'ADVANCED',
};
export const STRINGIFY = 'stringify';
export const STRINGIFY_STR = `${STRINGIFY}(`;
export const LAST_CHARACTER_NUMBER = -1;

export const INDEX_OF_UUID_INDEX = 0;
export const INJECT_VALUE = 1;

export const EXCLUDED_METHODS = ['getCityList'];

export const NOT_REPRESENTED_INDEX = -1;
export const GET_PAYLOAD_STATUSES = {
    START: 'START',
    ERROR: 'ERROR',
    SUCCESS: 'SUCCESS',
};
export const ARRAY_FIELD_TYPES = {
    STRING: 'string',
    NUMBER: 'number',
    ENUM: 'enum',
};
export const AUTOCOMPLETE_INPUT_ROLE = 'autocompleteInput';
export const STRINGIFY_FUNCTION = `const ${STRINGIFY} = (value) => {switch((typeof value).toLowerCase()){case "string":return value; default: return JSON.stringify(value);}};`;
