import { LABELS_TYPES, MESHBOT_LABELS, LOCATION, MESHBOT_CONFIG_KVS_KEY } from '../constants';
import hash from '../../../../constants/uniqueHash';
import { BOOLINT } from '../../../../constants/App';
import { INITIAL_LABEL_BACKGROUND_COLOR, LABEL_FIELDS } from '../../../../features/Labels/MeshBotsLabels/constants';
import { Base64 } from 'js-base64';
import { isObject, isString } from 'lodash';
import { isJson } from '../../../utilityService';
import { USER_TYPE } from '../constants';
import { KVS_NAME } from '../../../../reducers/kvs';

/**
 * Provides a kvs key depending on the type of label
 * @param {string} labelsType - labels type
 * @returns {string|null} kvs labels key
 * @example
 * getLabelsKeyByType('meshBot')
 * */
export function getLabelsKeyByType(labelsType) {
    switch (labelsType) {
        case LABELS_TYPES.MESHBOT:
            return MESHBOT_LABELS;

        default:
            return null;
    }
}

/**
 * Build params for API that read labels from kvs
 * @param {string} labelsType - labels type
 * @returns {Object} params for API that read labels from kvs
 * @example
 * getParamsOfReadLabelsFromKvs('meshBot')
 * */
export const getParamsOfReadLabelsFromKvs = (labelsType) => {
    const key = getLabelsKeyByType(labelsType);
    if (!key) {
        throw new Error('Missing key in getParamsOfReadLabelsFromKvs fn');
    }

    return {
        list: [{ key, type: LOCATION }],
    };
};
/**
 * Build params for API that set labels in kvs
 * @param {string} labelsType - labels type
 * @param {string} value - value(JSON string) that stores all labels of the specified type
 * @returns {Object} params for set labels in Kvs API
 * @example
 * buildParamsOfSetLabelsInKvs('meshBot', '"{\"01af32b6-0d9b-c5cb-13a6-3270b82b8f55\":{\"name\":\"♠ ♣ ♥ ♦\",\"backgroundColor\":\"#FCB20D\"}}")
 * */
export const buildParamsOfSetLabelsInKvs = (labelsType, value) => {
    const key = getLabelsKeyByType(labelsType);
    if (!key) {
        throw new Error('Missing key in buildParamsOfSetLabelsInKvs fn');
    }

    return {
        key,
        type: LOCATION,
        value,
        public: BOOLINT.TRUE,
    };
};
/**
 * Create a unique uuid and check whether it is busy or not, if it is not busy, it will return it, and if it is busy, it will create a new uuid
 * @param {Object} createdLabels - Labels from cloud
 * @returns {string} a new unique uuid
 * @example
 * getUniqueUuid({'4125vfrt14b': {name: 'Label1', backgroundColor: '#ce0b0b'}})
 * */
export function getUniqueUuid(createdLabels) {
    const uuid = hash();

    if (createdLabels.hasOwnProperty(uuid)) {
        return getUniqueUuid(createdLabels);
    }

    return uuid;
}
/**
 * Builds a new object with labels, adds a new label to the created labels
 * @param {Object} kvsLabels - Labels from cloud kvs
 * @param {Object} newLabelData - new label that need to save on cloud
 * @returns {Object} A new object with created labels on cloud kvs and a new label
 * @example
 * buildUpdatedLabels({'4125vfrt14b': {name: 'Label1', backgroundColor: '#ce0b0b'}}, {name: 'newLabel', backgroundColor: '#ce0b0b'})
 * */
export function buildUpdatedLabels(kvsLabels, newLabelData) {
    const newLabelUuid = getUniqueUuid(kvsLabels);

    return {
        ...kvsLabels,
        [newLabelUuid]: newLabelData,
    };
}
/**
 * Updates the specified label in the list and returns a new object with labels
 * @param {Object} kvsLabels - Labels from cloud kvs
 * @param {Object} updatedLabelData - updated label that need to save on cloud
 * @param {string} labelUuid - label uuid to update on the cloud kvs
 * @returns {Object} A new object with created labels on cloud kvs and a updated label
 * @example
 * buildLabelsWithUpdatedLabel({'5965vfrt14b': {name: 'Label2', backgroundColor: '#ce0b0b'}}, {name: 'updated Label', backgroundColor: '#ce0b0b'}, '5965vfrt14b)
 * */
export function buildLabelsWithUpdatedLabel(kvsLabels, updatedLabelData, labelUuid) {
    return {
        ...kvsLabels,
        [labelUuid]: updatedLabelData,
    };
}
/**
 * Removes the specified label from the lists of all labels and returns the new list of labels
 * @param {Object} labels - Labels from kvs
 * @param {string} labelUuid - label uuid to update on the cloud(remove from list)
 * @returns {Object} A new object without specified label
 * @example
 * removeLabelFromLabels({'5965vfrt14b': {name: 'Label2', backgroundColor: '#ce0b0b'}}, '5965vfrt14b)
 * */
export function removeLabelFromLabels(labels, labelUuid) {
    const copyOfLabels = { ...labels };
    delete copyOfLabels[labelUuid];

    return copyOfLabels;
}
/**
 * Converts data to JSON string and encodes it in Base 64
 * @param {any} data - Any data
 * @returns {string} encoded string
 * @example
 * encodeData({'4125vfrt14b': {name: 'Label1', backgroundColor: '#ce0b0b'}})
 * */
export function encodeData(data) {
    const jsonData = JSON.stringify(data);

    return Base64.encode(jsonData);
}

/**
 * Decode base 64 string and parse the string if it's valid JSON
 * @param {string} data - encoded string
 * @returns {any} data from encoded string or the plain string if not JSON
 * @example
 * decodeData('bvgdgbvh552')
 */
export function decodeData(data) {
    const decodedString = Base64.decode(data);

    try {
        return JSON.parse(decodedString);
    } catch (error) {
        return decodedString;
    }
}

/**
 * Parse value from the given kvs value by either parsing JSON or decoding data.
 *
 * @param {string} value - kvs value from which to parse value.
 * @throws {Error} Throws an error if the value is missing, not a string.
 * @returns {any} Value parsed from the kvs value. if value in base64 format than value decoded and return result.
 *
 * @example
 * const valueInJsonString = '{"some": {"name": "Name"}}';
 * const decodedValue = "J3sibGFiZWxVdWlkMSI6IHsibmFtZSI6ICJMYWJlbCBuYW1lIn19Jw==";
 *
 * try {
 *     const value = parseKvsValue(valueInJsonString);
 *     console.log(value); // Output: {"some": {"name": "Name"}}
 *
 *     const decodedValue = parseKvsValue(decodedValue);
 *     console.log(decodedValue); // Output: {"hihi": {"name": "mi mi name"}}
 * } catch (error) {
 *     console.error(error.message);
 * }
 */
export const parseKvsValue = (value) => {
    if (!value) {
        throw new Error('Invalid function arguments, "value" is missing. parseKvsValue fn');
    }

    if (!isString(value)) {
        throw new Error('Invalid "value", "value" should be string. parseKvsValue fn');
    }

    if (isJson(value)) {
        return JSON.parse(value);
    }

    return decodeData(value);
};

/**
 * Retrieves mesh bot labels from the given value by either parsing JSON or decoding data.
 *
 * @param {string} value - The value from which to retrieve mesh bot labels.
 * @throws {Error} Throws an error if the value is missing, not a string.
 * @returns {Object} The mesh bot labels parsed from the value. if value in base64 format than value decoded and return result.
 *
 * @example
 * const valueWithLabelsInJsonString = '{"labelUuid1": {"name": "Label name"}}';
 * const decodedValue = "J3sibGFiZWxVdWlkMSI6IHsibmFtZSI6ICJMYWJlbCBuYW1lIn19Jw==";
 *
 * try {
 *     const labels = getMeshBotLabelsFromValue(valueWithLabelsInJsonString);
 *     console.log(labels); // Output: {"labelUuid1": {"name": "Label name"}}
 *
 *     const decodedLabels = getMeshBotLabelsFromValue(decodedValue);
 *     console.log(decodedLabels); // Output: {"labelUuid1": {"name": "Label name"}}
 * } catch (error) {
 *     console.error(error.message);
 * }
 */
export const getMeshBotLabelsFromValue = (value) => {
    if (!value) {
        throw new Error('Invalid function arguments, "value" is missing');
    }

    if (!isString(value)) {
        throw new Error('Invalid "value", "value" should be string');
    }

    if (isJson(value)) {
        return JSON.parse(value);
    }

    return decodeData(value);
};

/**
 * Builds a valid label object by ensuring it has the required properties.
 *
 * @function
 * @name buildValidLabel
 *
 * @param {Object} label - The label object to validate.
 * @returns {Object} - The valid label object.
 * @example
 * const label = { name: 'Example Label' };
 * const validLabel = buildValidLabel(label);
 * // Result: { name: 'Example Label', background_color: '#FCB20D' }
 */
const buildValidLabel = (label) => {
    const copiedLabel = { ...label };
    if (!copiedLabel.hasOwnProperty(LABEL_FIELDS.NAME)) {
        copiedLabel[LABEL_FIELDS.NAME] = '';
    }

    if (!copiedLabel.hasOwnProperty(LABEL_FIELDS.BACKGROUND_COLOR)) {
        copiedLabel[LABEL_FIELDS.BACKGROUND_COLOR] = INITIAL_LABEL_BACKGROUND_COLOR;
    }

    return copiedLabel;
};
/**
 * Builds a valid labels list by ensuring each label has the required properties.
 *
 * @function
 * @name buildValidLabelsList
 *
 * @param {Object} labels - The labels list to validate.
 * @returns {Object} - The valid labels list.
 *
 * @example
 * const labels = {
 *   label1: { name: 'Label 1' },
 *   label2: { color: '#123456' },
 * };
 * const validLabels = buildValidLabelsList(labels);
 * // Result: { label1: { name: 'Label 1', background_color: '#FCB20D' },
 * //           label2: { name: '', background_color: '#FCB20D', color: '#123456' } }
 */
export const buildValidLabelsList = (labels) => {
    if (!labels || !isObject(labels)) {
        return {};
    }

    const validLabels = {};
    for (const key in labels) {
        validLabels[key] = buildValidLabel(labels[key]);
    }

    return validLabels;
};
/**
 * Builds an updated label by merging label form values with the initial label state.
 *
 * @function
 * @name buildUpdatedLabel
 *
 * @param {Object} labelFormValues - Updated label values from form.
 * @param {string} labelUuid - The UUID of the label to be updated.
 * @param {Object} state - The current state containing the labels data.
 * @returns {Object} - The updated label object with all properties.
 */
export const buildUpdatedLabel = (labelFormValues, labelUuid, state) => {
    const initialLabel = state[KVS_NAME][MESHBOT_LABELS][labelUuid];

    return { ...initialLabel, ...labelFormValues };
};
/**
 * Filters mesh bot labels based on the presence and value of the "deleted" field.
 *
 * @param {Object} labels - The mesh bot labels to be filtered.
 * @returns {Object} A new object containing only the labels that have a "deleted" field
 *                   with a value not equal to the specified boolean integer constant.
 *
 * @example
 * const meshBotLabels = {
 *     label1: { value: "abc" },
 *     label2: { deleted: 1, value: "def" },
 *     label3: { value: "ghi" }
 * };
 *
 * const filteredLabels = filterMeshBotLabelsByDeletedField(meshBotLabels);
 * console.log(filteredLabels);
 * // Output: { label1: { value: "abc" }, label3: { value: "ghi" } }
 */
export const filterMeshBotLabelsByDeletedField = (labels) => {
    const filteredLabels = {};

    for (const key in labels) {
        const label = labels[key];

        //Support for the first implementation of label deletion. In which deleted: 1 was added during deletion
        if (label[LABEL_FIELDS.DELETED] !== BOOLINT.TRUE) {
            filteredLabels[key] = label;
        }
    }

    return filteredLabels;
};

export const buildKvsGetParam = (unique_key, type = USER_TYPE) => {
    if (!unique_key) {
        throw new Error('unique_key missed in buildKvsGetParam function. unique_key is required');
    }

    return {
        list: [
            {
                key: unique_key,
                type: type,
            },
        ],
    };
};

export const uploadMeshbotConfigData = (meshbotConfigData) => ({
    type: USER_TYPE,
    public: 1,
    key: MESHBOT_CONFIG_KVS_KEY,
    value: meshbotConfigData,
});
/**
 * Builds the payload for the Mesh Bots table configuration.
 *
 * @param {object} meshbotsTableState - The state of the Mesh Bots table.
 * @returns {object} The payload containing column data and density value.
 */
export const buildMeshBotsTablePayload = (meshbotsTableState) => {
    const lookupColumns = meshbotsTableState.columns?.lookup;
    const columnData = lookupColumns
        ? Object.entries(lookupColumns).map(([columnName, { width, hide, field }]) => ({
              columnName,
              width,
              hide,
              field,
          }))
        : [];

    const densityValue = meshbotsTableState.density?.value ? meshbotsTableState.density?.value : '';

    return { columnData, densityValue };
};
