import { useState } from 'react';

const useDialogState = () => {
    const [open, setOpen] = useState(false);

    const toggleOpenDialog = () => {
        setOpen((prevState) => !prevState);
    };

    return { open, toggleOpenDialog, setOpen };
};

export default useDialogState;
