import React from 'react';

const IcFailedStatus = (props) => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" {...props}>
            <circle cx="20" cy="20" r="16.5" stroke="currentColor" strokeWidth="3" />
            <rect
                x="15.0503"
                y="16.4645"
                width="2"
                height="12"
                transform="rotate(-45 15.0503 16.4645)"
                fill="currentColor"
            />
            <rect
                x="16.4644"
                y="24.9497"
                width="2"
                height="12"
                transform="rotate(-135 16.4644 24.9497)"
                fill="currentColor"
            />
        </svg>
    );
};

export default IcFailedStatus;
