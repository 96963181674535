import {
    apiGetConsentData,
    apiGetEulaData,
    apiGetNoticesData,
    apiSetConsentData,
    apiSetCountryData,
    apiSetEulaData,
    apiSetNoticesData,
} from '../../../api/apiScenes';
import * as types from '../actions/EzloPoliciesActionTypes';
import { SUCCESS_API_STATUS } from '../constants';
import {
    getConsentPayload,
    getCountryParamsData,
    getPayloadConsent,
    getPayloadNotices,
    setEulaPayload,
    setNoticesPayload,
    setUpdatedPoliciesPayload,
} from '../utils';

const actions = {
    getEulaData: () => async (dispatch) => {
        const getEulaData = await apiGetEulaData();
        if (getEulaData?.status == SUCCESS_API_STATUS) {
            dispatch(actions.updateEula(getEulaData?.data));
        }
    },

    getNoticesData: () => async (dispatch) => {
        const getNoticesData = await apiGetNoticesData();
        if (getNoticesData?.status == SUCCESS_API_STATUS) {
            const payloadGetNotices = getPayloadNotices(getNoticesData);
            dispatch(actions.updateNotices(payloadGetNotices));
        }
    },

    getConsentData: () => async (dispatch) => {
        const getConsentData = await apiGetConsentData();
        if (getConsentData?.status == SUCCESS_API_STATUS) {
            const payloadGetConsent = getPayloadConsent(getConsentData);
            dispatch(actions.updateConsent(payloadGetConsent));
            dispatch(actions?.setConsentData(payloadGetConsent));
        }
    },

    setConsentData: (consent) => async (dispatch) => {
        const consentPayload = getConsentPayload(consent);
        dispatch(actions.setConsent(consentPayload));
    },

    setPrivacyPolicy: () => async (dispatch, getState) => {
        const state = getState();
        const eulaData = setEulaPayload(state?.ezloPolicy?.eula);
        const updatedPoliciesAccepted = setUpdatedPoliciesPayload();
        dispatch(actions.policiesAccepted(updatedPoliciesAccepted));
        const noticesData = setNoticesPayload(state?.ezloPolicy?.notices);
        apiSetEulaData(eulaData);
        apiSetNoticesData(noticesData);
        apiSetConsentData(state?.ezloPolicy?.setConsent);
        const countryParamData = getCountryParamsData();
        apiSetCountryData(countryParamData);
    },

    //----------------------------------------- REDUX ACTIONS SECTION -----------------------------------------------//

    updateEula: (data) => ({
        type: types.END_USER_LICENSE_AGREEMENT,
        data,
    }),

    updateNotices: (data) => ({
        type: types.UPDATE_NOTICES,
        data,
    }),

    updateConsent: (data) => ({
        type: types.UPDATE_CONSENT,
        data,
    }),

    setConsent: (data) => ({
        type: types.SET_UPDATE_CONSENT,
        data,
    }),

    policiesAccepted: (data) => ({
        type: types.SET_POLICIES_ACCEPTED,
        data,
    }),

    setPreviousPath: (data) => ({
        type: types.SET_DOMAIN_PATH,
        data,
    }),
};

export default actions;
