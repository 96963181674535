import React from 'react';

const IcDeviceNo4GCrossed = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28 32L30 30L24.0522 24.0522L15.4374 15.4374L11.5464 11.5464L10.3138 10.3138L2 2L0 4L7.24318 11.2432L2 18.998V21.4615H8.44636V23.7814H11.5464V21.4615H13.353V18.6284H11.5464V15.5464L16.2273 20.2273C16.8977 21.5182 17.9312 22.5477 19.2214 23.2214L28 32ZM30.0679 17.8894C30.0679 20.0609 28.9611 21.9638 27.0756 23.0756L24.8396 20.8396C25.9257 20.4961 26.666 19.7966 26.8447 18.7106H22.7106L22.3487 18.3487V15.7954H30.0679V17.8894ZM22.9235 12.0179C21.0045 12.0179 19.4841 13.139 18.9057 14.9057L16.4486 12.4486C17.7336 10.3092 20.0685 9 22.9235 9C25.7361 9 28.0765 10.1497 29.4109 12.1411L27.0705 14.1119C25.7977 12.6954 24.5248 12.0179 22.9235 12.0179ZM8.44636 18.6284H5.55166V18.4436L8.52848 14.0297L8.65166 14.1324L8.44636 15.6105V18.6284Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcDeviceNo4GCrossed;
