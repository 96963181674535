import React from 'react';

const IcFireAlarm = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
            <path
                d="M24.2 9.8L21.25 6.9C22.22 7.85 22.52 11.03 19.79 12.34C16.82 13.76 10.25 11 16.25 2L8.3 9.8C6.26 11.83 5 14.64 5 17.75C5 20.86 6.26 23.67 8.3 25.7C9.33 26.73 10.55 27.55 11.91 28.12C10.88 27.05 10.25 25.6 10.25 24C10.25 22.14 11.09 20.49 12.42 19.39C13.05 22.03 15.42 24 18.25 24C19.71 24 21.04 23.48 22.08 22.61C22.19 23.06 22.25 23.52 22.25 24C22.25 25.6 21.62 27.05 20.6 28.13C21.96 27.56 23.18 26.73 24.21 25.71C26.25 23.67 27.51 20.86 27.51 17.76C27.5 14.64 26.24 11.83 24.2 9.8Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcFireAlarm;
