import React from 'react';

export const IcPlugAndPlayIcon = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.9674 9.14468L15.4621 10.5911L20.2895 18.9525L22.7948 17.506C24.2297 16.6776 24.7213 14.8428 23.8929 13.408L23.7033 13.0796L25.7477 11.8993C25.8935 12.252 26.0194 12.609 26.1259 12.9688C27.5553 17.7978 25.4995 23.1911 20.8759 25.8605C16.8115 28.2071 11.9159 27.7838 8.43362 25.1626C7.79294 24.6803 6.87152 24.8102 6.37557 25.4526C5.87963 26.0951 5.99696 27.0068 6.63764 27.4891C11.0229 30.7899 17.1949 31.3303 22.3242 28.3689C28.1576 25.0009 30.7445 18.2006 28.9422 12.1117C28.6392 11.0879 28.2124 10.0855 27.6575 9.12441L26.9334 7.87021L22.2551 10.5712L22.0654 10.2428C21.237 8.80788 19.4022 8.31626 17.9674 9.14468Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.37319 19.0296C3.94383 14.2005 5.99957 8.80729 10.6232 6.13787C14.6876 3.79124 19.5831 4.21452 23.0655 6.83576C23.7062 7.31802 24.6276 7.18817 25.1235 6.54573C25.6195 5.90329 25.5021 4.99154 24.8615 4.50928C20.4762 1.20841 14.3042 0.668068 9.17493 3.62946C3.34144 6.99743 0.754642 13.7978 2.55692 19.8867C2.85994 20.9104 3.28665 21.9128 3.84156 22.8739L4.56567 24.1281L9.244 21.4271L9.43365 21.7556C10.2621 23.1905 12.0968 23.6821 13.5317 22.8537L16.037 21.4072L15.0717 19.7352L16.787 18.7449C17.2488 18.4783 17.407 17.8878 17.1404 17.426C16.8738 16.9642 16.2833 16.806 15.8215 17.0726L14.1062 18.063L13.1409 16.391L14.8562 15.4006C15.3179 15.134 15.4762 14.5435 15.2096 14.0817C14.9429 13.62 14.3525 13.4617 13.8907 13.7284L12.1754 14.7187L11.2096 13.0459L8.7043 14.4923C7.26942 15.3208 6.7778 17.1555 7.60622 18.5904L7.79577 18.9187L5.75144 20.099C5.60562 19.7463 5.47969 19.3894 5.37319 19.0296Z"
            />
        </svg>
    );
};
