import { MODELS } from './constants';
import {
    EZLOGIC_TITLE_EZLO_ATOM,
    EZLOGIC_TITLE_EZLO_PLUS,
    EZLOGIC_TITLE_EZLO_SECURE,
    EZLOGIC_TITLE_EZLOPI,
    EZLOGIC_TITLE_LOCAL_FIRMWARE,
    EZLOGIC_TITLE_PLUGHUB,
    EZLOGIC_TITLE_VIRTUAL_HUB,
} from 'constants/language_tokens';
import {
    plusFrontImg,
    secureFrontImg,
    atomFrontImg,
    plugHubFrontImg,
    ezloPiImg,
    softHubImg,
    veraLiteImg,
    vera3Img,
    veraEdgeImg,
    veraPlusImg,
    veraSecureImg,
    waterValveFrontImg,
} from 'assets/images';

/**
 * ezloPlus data properties
 * @constant
 * @type {object}
 * @property {object} images - The images related to the model
 * @property {string} friendlyName - The friendly name of hub
 */
const ezloPlus = {
    images: {
        front: plusFrontImg,
    },
    friendlyName: EZLOGIC_TITLE_EZLO_PLUS,
};
/**
 * ezloSecure data properties
 * @constant
 * @type {object}
 * @property {object} images - The images related to the model
 * @property {string} friendlyName - The friendly name of hub
 */
const ezloSecure = {
    images: {
        front: secureFrontImg,
    },
    friendlyName: EZLOGIC_TITLE_EZLO_SECURE,
};
/**
 * atom data properties
 * @constant
 * @type {object}
 * @property {object} images - The images related to the model
 * @property {string} friendlyName - The friendly name of hub
 */
const atom = {
    images: {
        front: atomFrontImg,
    },
    friendlyName: EZLOGIC_TITLE_EZLO_ATOM,
};
/**
 * plugHub data properties
 * @constant
 * @type {object}
 * @property {object} images - The images related to the model
 * @property {string} friendlyName - The friendly name of hub
 */
const plugHub = {
    images: {
        front: plugHubFrontImg,
    },
    friendlyName: EZLOGIC_TITLE_PLUGHUB,
};
/**
 * vhub data properties
 * @constant
 * @type {object}
 * @property {object} images - The images related to the model
 * @property {string} friendlyName - The friendly name of hub
 */
const vhub = {
    images: {
        front: softHubImg,
    },
    friendlyName: EZLOGIC_TITLE_VIRTUAL_HUB,
};
/**
 * ezloPi data properties
 * @constant
 * @type {object}
 * @property {object} images - The images related to the model
 * @property {string} friendlyName - The friendly name of hub
 */
const ezloPi = {
    images: {
        front: ezloPiImg,
    },
    friendlyName: EZLOGIC_TITLE_EZLOPI,
};
/**
 * veraLite data properties
 * @constant
 * @type {object}
 * @property {object} images - The images related to the model
 * @property {string} friendlyName - The friendly name of hub
 */
const veraLite = {
    images: {
        front: veraLiteImg,
    },
    friendlyName: '',
};
/**
 * vera3 data properties
 * @constant
 * @type {object}
 * @property {object} images - The images related to the model
 * @property {string} friendlyName - The friendly name of hub
 */
const vera3 = {
    images: {
        front: vera3Img,
    },
    friendlyName: '',
};
/**
 * veraEdge data properties
 * @constant
 * @type {object}
 * @property {object} images - The images related to the model
 * @property {string} friendlyName - The friendly name of hub
 */
export const veraEdge = {
    images: {
        front: veraEdgeImg,
    },
    friendlyName: '',
};
/**
 * veraSecure data properties
 * @constant
 * @type {object}
 * @property {object} images - The images related to the model
 * @property {string} friendlyName - The friendly name of hub
 */
const veraSecure = {
    images: {
        front: veraSecureImg,
    },
    friendlyName: '',
};
/**
 * veraPlus data properties
 * @constant
 * @type {object}
 * @property {object} images - The images related to the model
 * @property {string} friendlyName - The friendly name of hub
 */
const veraPlus = {
    images: {
        front: veraPlusImg,
    },
    friendlyName: '',
};
/**
 * ezloWaterValve data properties
 * @constant
 * @type {object}
 * @property {object} images - The images related to the model
 * @property {string} friendlyName - The friendly name of hub
 */
const ezloWaterValve = {
    images: {
        front: waterValveFrontImg,
    },
    friendlyName: '',
};

/**
 * Contains the data for each hub model, including its images and its friendly name.
 *
 * @example
 * import { HUB_DATA_BY_HUB_MODEL } from './hubData';
 * console.log(HUB_IMAGES_BY_HUB_MODULE[MODELS['h2.1']]); // Should output { images: [Object], friendlyName: string }
 *
 * @type {Object.<string, Object>}
 * @property {Object} 'h2.1' - ui hub data for hub with 'h2.1' model.
 * @property {Object} 'h2.1'.images - hub images.
 * @property {string} 'h2.1'.friendlyName - hub name.
 * @property {Object} 'h2_secure.1' - ui hub data for hub with 'h2_secure.1' model.
 * @const
 * @exports
 */
export const HUB_DATA_BY_HUB_MODEL = {
    [MODELS['h2.1']]: ezloPlus,
    [MODELS['h2_secure.1']]: ezloSecure,
    [MODELS.ATOM32]: atom,
    [MODELS['32.1']]: atom,
    [MODELS['plughub.1']]: plugHub,
    [MODELS.vhub]: vhub,
    [MODELS['ezlopi_generic']]: ezloPi,
};

/**
 * The range of serial numbers for Ezlo Plus hub
 * @constant
 * @type {array}
 */
const ezloPlusRange = [90000000.0, 90999999.0];
/**
 * The range of serial numbers for Ezlo Secure hub
 * @constant
 * @type {array}
 */
const ezloSecureRange = [92000000.0, 92999999.0];
/**
 * The range of serial numbers for Atom hub
 * @constant
 * @type {array}
 */
const atomRange = [70000000.0, 79999999];
/**
 * The range of serial numbers for EzloPi hub
 * @constant
 * @type {array}
 */
// By serial number we cannot distinguish between these controllers as the Vidoo hub has a number in the range of Ezlopi
// const ezloPiRange = [102000000.0, 102999999.0];
/**
 * The range of serial numbers for virtual hub (Vidoo hub)
 * @constant
 * @type {array}
 */
// const virtualHubRange = [100000000.0, 101000000.0];
/**
 * The range of serial numbers for PlugHub hub
 * @constant
 * @type {array}
 */
const plugHubRange = [80000000.0, 89999999];
/**
 * The range of serial numbers for Vera Lite hub
 * @constant
 * @type {array}
 */
const veraLiteRange = [35000000.0, 37999999.0];
/**
 * The range of serial numbers for Vera 3 hub
 * @constant
 * @type {array}
 */
const vera3Range = [30000000.0, 35999999.0];
/**
 * The range of serial numbers for Vera Edge hub
 * @constant
 * @type {array}
 */
const veraEdgeRange = [45000000.0, 50999999.0];
/**
 * The range of serial numbers for Vera Plus hub
 * @constant
 * @type {array}
 */
const veraPlusRange = [50000000.0, 55999999.0];
/**
 * The range of serial numbers for Vera Secure hub
 * @constant
 * @type {array}
 */
const veraSecureRange = [55000000.0, 79999999.0];
/**
 * The range of serial numbers for Water Valve hub
 * @constant
 * @type {array}
 */
const ezloWaterValveRange = [94000000.0, 94999999.0];

/**
 * An array of objects, where each object has a range of serial numbers
 * and corresponding data (images and friendly name) for each hub model
 * @type {Array<object>}
 * @export
 */
export const HUB_DATA_BY_HUB_SERIAL_RANGE = [
    {
        range: ezloPlusRange,
        data: ezloPlus,
    },
    {
        range: ezloSecureRange,
        data: ezloSecure,
    },
    {
        range: atomRange,
        data: atom,
    },
    {
        range: plugHubRange,
        data: plugHub,
    },
    // By serial number we cannot distinguish between these controllers as the Vidoo hub has a number in the range of Ezlopi
    // {
    //     range: virtualHubRange,
    //     data: vhub,
    // },
    // {
    //     range: ezloPiRange,
    //     data: ezloPi,
    // },
    {
        range: veraLiteRange,
        data: veraLite,
    },
    {
        range: vera3Range,
        data: vera3,
    },
    {
        range: veraEdgeRange,
        data: veraEdge,
    },
    {
        range: veraPlusRange,
        data: veraPlus,
    },
    {
        range: veraSecureRange,
        data: veraSecure,
    },
    {
        range: ezloWaterValveRange,
        data: ezloWaterValve,
    },
];

export const HUB_NAMES_KEYS_BY_HUB_TYPE = {
    [MODELS['h2.1']]: EZLOGIC_TITLE_EZLO_PLUS,
    [MODELS['h2_secure.1']]: EZLOGIC_TITLE_EZLO_SECURE,
    [MODELS['32.1']]: EZLOGIC_TITLE_EZLO_ATOM,
    [MODELS.ATOM32]: EZLOGIC_TITLE_EZLO_ATOM,
    [MODELS.vhub]: EZLOGIC_TITLE_VIRTUAL_HUB,
    [MODELS['plughub.1']]: EZLOGIC_TITLE_PLUGHUB,
    [MODELS.local]: EZLOGIC_TITLE_LOCAL_FIRMWARE,
    [MODELS.ezlopi_generic]: EZLOGIC_TITLE_EZLOPI,
};
