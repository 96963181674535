import React from 'react';
import classname from 'classnames';

import LabelsPanelHeader from '../LabelsPanelHeader';
import LabelsPanelBody from '../LabelsPanelBody';
import useMeshBotsTableContext from '../../../../../containers/Ezlo/EzloMeshbots/hooks/useMeshBotsTableContext';

import styles from './styles.module.scss';

const MeshBotLabelsPanel = () => {
    const { labelsPanelOpen, toggleOpenLabelsPanel } = useMeshBotsTableContext();

    const className = classname(styles.labelsPanelContainer, {
        [styles.labelsPanelOpen]: labelsPanelOpen,
        [styles.labelsPanelClose]: !labelsPanelOpen,
    });

    return (
        <div className={className}>
            <div className={styles.panelDrawer}>
                <div className={styles.panelDrawerContainer}>
                    <LabelsPanelHeader open={labelsPanelOpen} toggleOpenDialog={toggleOpenLabelsPanel} />
                    <LabelsPanelBody />
                </div>
            </div>
        </div>
    );
};

export default MeshBotLabelsPanel;
