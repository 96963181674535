export const FETCH_INTEGRATIONS_LIST = 'FETCH_INTEGRATIONS_LIST';
export const FETCH_INTEGRATIONS_LIST_ON_SUCCESS = 'FETCH_INTEGRATIONS_LIST_ON_SUCCESS';
export const FETCH_INTEGRATIONS_LIST_ON_ERROR = 'FETCH_INTEGRATIONS_LIST_ON_ERROR';

export const FETCH_ABSTRACT_LIST_DETAILED = 'FETCH_ABSTRACT_LIST_DETAILED';
export const FETCH_ABSTRACT_LIST_DETAILED_ON_SUCCESS = 'FETCH_ABSTRACT_LIST_DETAILED_ON_SUCCESS';
export const FETCH_ABSTRACT_LIST_DETAILED_ON_ERROR = 'FETCH_ABSTRACT_LIST_DETAILED_ON_ERROR';

export const SET_USER_INTEGRATIONS = 'SET_USER_INTEGRATIONS';

export const FETCH_INTEGRATIONS_CONFIG = 'FETCH_INTEGRATIONS_CONFIG';
export const FETCH_INTEGRATIONS_CONFIG_ON_SUCCESS = 'FETCH_INTEGRATIONS_CONFIG_ON_SUCCESS';
export const FETCH_INTEGRATIONS_CONFIG_ON_ERROR = 'FETCH_INTEGRATIONS_CONFIG_ON_ERROR';

export const ENROLL_TO_INTEGRATION = 'ENROLL_TO_INTEGRATION';
export const ENROLL_TO_INTEGRATION_ON_SUCCESS = 'ENROLL_TO_INTEGRATION_ON_SUCCESS';
export const ENROLL_TO_INTEGRATION_ON_ERROR = 'ENROLL_TO_INTEGRATION_ON_ERROR';
export const UPDATE_INTEGRATION = 'UPDATE_INTEGRATION';
export const UPDATE_INTEGRATION_ON_SUCCESS = 'UPDATE_INTEGRATION_ON_SUCCESS';
export const UPDATE_INTEGRATION_ON_ERROR = 'UPDATE_INTEGRATION_ON_ERROR';
export const FETCH_ACCOUNTS_INFO = 'FETCH ACCOUNTS INFO';
export const FETCH_ACCOUNTS_INFO_SUCCESS = 'FETCH ACCOUNTS INFO SUCCESS';
export const UPDATE_CLOUD_VARIABLE_CURRENT_VALUE = 'UPDATE_CLOUD_VARIABLE_CURRENT_VALUE';
export const UPDATE_ABSTRACT_STATE = 'UPDATE_ABSTRACT_STATE';
export const SET_ACTIVE_INTEGRATIONS_TAB = 'SET_ACTIVE_INTEGRATIONS_TAB';
export const SET_OPEN_INTEGRATION_WAITING_MODAL = 'SET_OPEN_INTEGRATION_WAITING_MODAL';
export const SET_OPEN_INTEGRATION_SUCCESSFUL_MODAL = 'SET_OPEN_INTEGRATION_SUCCESSFUL_MODAL';
export const SET_OPEN_INTEGRATION_ABORTED_MODAL = 'SET_OPEN_INTEGRATION_ABORTED_MODAL';
export const SET_OPEN_INTEGRATION_REMOVE_MODAL = 'SET_OPEN_INTEGRATION_REMOVE_MODAL';
