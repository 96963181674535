import React from 'react';
// import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import classNames from 'classnames';
import { Dialog } from '@mui/material';

import { PlusIcon } from '../../assets/icons';

import styles from './Dialog.module.scss';

/**
 * Create a new dialog.
 *
 * <Dialog
 *    title="Title",
 *    open={true|false},
 *    actions={<Button>}
 *    onHide={function () {}},
 * >
 *     ..........
 * </Dialog>
 */
const CustomDialog = ({
    title,
    open,
    showCloseButton,
    onHide,
    children,
    actions,
    className,
    style,
    headerStyle,
    bodyStyle,
    footerStyle,
}) => {
    const containerClassName = classNames(styles.dialogContainer, className);
    const isLayerDisplayed = open ? styles.layerShow : styles.layerHide;

    return (
        <Dialog className={classNames(styles.dialogLayer, isLayerDisplayed)} open={open} onClose={onHide}>
            <div className={containerClassName} style={style}>
                <div className={styles.dialogHeader}>
                    <div className={styles.div} style={headerStyle}>
                        {title}
                    </div>
                    {showCloseButton && <PlusIcon className={styles.dialogCloseBtn} onClick={onHide} />}
                </div>
                <div className={styles.dialogBody} style={bodyStyle}>
                    {children}
                </div>
                {actions && (
                    <div className={styles.dialogFooter} style={footerStyle}>
                        {actions}
                    </div>
                )}
            </div>
        </Dialog>
    );
};

CustomDialog.defaultProps = {
    open: false,
    showCloseButton: true,
    actions: null,
    onHide: function () {},
};

export default CustomDialog;
