import { useSelector } from 'react-redux';

/**
 * Extracts the version of Advanced Scenes from the state by the hub's serial.
 *
 * @param {Object} state - The state object from Redux.
 * @param {string} serial - The serial ID of the hub.
 * @return {number} The version of Advanced Scenes.
 */
const extractHubAdvancedScenesVersionFromStateBySerial = (state, serial) => {
    return state?.ezlo?.data?.[serial]?.features?.advanced_scenes?.version;
};

/**
 * Hook that determines if the feature supports the specified version of Advanced Scenes or higher.
 *
 * @param {string} hubSerial - The serial of the hub.
 * @param {number} supportFromAdvancedScenesVersion - The minimal Advanced Scenes version that should be supported.
 * @return {boolean} True if the feature supports specified version or higher of Advanced Scenes, or false if not or if no hubSerial or supportFromAdvancedScenesVersion is provided.
 */
const useFeatureSupportByHubAdvancedScenes = (hubSerial, supportFromAdvancedScenesVersion) => {
    return useSelector((state) => {
        try {
            if (!hubSerial && !supportFromAdvancedScenesVersion) {
                return false;
            }
            const hubAdvancedScenesVersion = extractHubAdvancedScenesVersionFromStateBySerial(state, hubSerial);

            return Number(hubAdvancedScenesVersion) >= supportFromAdvancedScenesVersion;
        } catch (e) {
            return false;
        }
    });
};

export default useFeatureSupportByHubAdvancedScenes;
