import { t } from '../../../../helpers/language';

export const validateRule = (newRule) => {
    const errors = [];
    const warnings = [];

    if (!newRule.name) {
        errors.push(t`ezlo.error.message.provide_rule_name`);
    }

    return {
        errors,
        warnings,
    };
};
