import './style.scss';
import React from 'react';
import classNames from 'classnames';

const RadioButton = ({ className, style, checked, disabled, contentPosition, children, onClick }) => {
    const isContentLeft = contentPosition === 'left';

    return (
        <div
            className={classNames('radio-button', className, {
                'disabled': disabled,
                'checked': checked,
                'content-left': isContentLeft,
            })}
            style={style}
            onClick={onClick}
        >
            {isContentLeft && children}
            <div className="radio-button-container">
                <div className="radio-button-checkmark" />
                <input className="radio-button-input" type="radio" checked={checked} disabled={disabled} />
            </div>
            {!isContentLeft && children}
        </div>
    );
};

RadioButton.defaultProps = {
    checked: false,
    disabled: false,
    contentPosition: 'right',
    onClick: function () {},
};

export default RadioButton;
