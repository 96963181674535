import './style.scss';

import React, { Component } from 'react';
import classNames from 'classnames';
import { ArrowDownIcon, ArrowUpIcon } from '../../assets/icons';

/**
 * Create a new number input.
 *
 * <NumberInput
 *    value="1",
 *    min="0",
 *    max="10",
 *    step="1",
 *    disabled="false"
 *    onChange={function () {}},
 * />
 */
class NumberInput extends Component {
    intervalId = null;

    // static propTypes = {
    //   className: PropTypes.string,
    //   max: PropTypes.number,
    //   min: PropTypes.number,
    //   step: PropTypes.number,
    //   disabled: PropTypes.bool,
    //   value: PropTypes.number,
    //   onChange: PropTypes.func
    // };
    //
    static defaultProps = {
        max: null,
        min: null,
        step: 1,
        disabled: false,
        onChange: function () {},
    };

    state = {
        value: this.props.value,
    };

    constructor(props, context) {
        super(props, context);

        this.handlePlusClick = this.handlePlusClick.bind(this);
        this.handleMinusClick = this.handleMinusClick.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
        this.handleInputBlur = this.handleInputBlur.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.updateStateFromProps(nextProps);
    }

    componentDidMount() {
        this.updateStateFromProps(this.props);
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    updateStateFromProps(props) {
        this.setState({
            value: props.value,
        });
    }

    normalizeValue(value) {
        const { step, min, max } = this.props;
        const stepArray = step.toString().split('.');
        const fixedAccuracy = stepArray.length > 1 ? stepArray[1].length : 0;

        value = Number.parseFloat(value.toFixed(fixedAccuracy));

        if (min !== null && value < min) {
            value = min;
        } else if (max !== null && value > max) {
            value = max;
        }

        return value;
    }

    plus() {
        const { onChange, step } = this.props;
        const { value } = this.state;
        const newValue = this.normalizeValue(value + step);

        this.setState(
            {
                value: newValue,
            },
            () => onChange(newValue),
        );
    }

    minus() {
        const { onChange, step } = this.props;
        const { value } = this.state;
        const newValue = this.normalizeValue(value - step);

        this.setState(
            {
                value: newValue,
            },
            () => onChange(newValue),
        );
    }

    handlePlusClick(e) {
        if (e.button !== 0) {
            return;
        }

        this.plus();

        this.intervalId = setInterval(() => this.plus(), 150);
    }

    handleMinusClick(e) {
        if (e.button !== 0) {
            return;
        }

        this.minus();

        this.intervalId = setInterval(() => this.minus(), 150);
    }

    handleMouseUp() {
        clearInterval(this.intervalId);
    }

    handleInputChange(e) {
        const value = e.target.value
            .trim()
            .replace(',', '.')
            .replace(/[^\d.-]/g, '');

        this.setState({
            value: value !== '' ? Number.parseFloat(value) : 0,
        });
    }

    handleInputBlur() {
        const { onChange } = this.props;
        const newValue = this.normalizeValue(this.state.value);

        this.setState(
            {
                value: newValue,
            },
            () => onChange(newValue),
        );
    }

    render() {
        const { className, disabled, ...otherProps } = this.props;
        const { value: stateValue } = this.state;

        return (
            <div {...otherProps} className={classNames(className, 'number-input')}>
                <div className="number-input-minus" onMouseDown={this.handleMinusClick} onMouseUp={this.handleMouseUp}>
                    <ArrowDownIcon />
                </div>
                <input
                    type="text"
                    value={stateValue}
                    disabled={disabled}
                    onChange={this.handleInputChange}
                    onBlur={this.handleInputBlur}
                />
                <div className="number-input-plus" onMouseDown={this.handlePlusClick} onMouseUp={this.handleMouseUp}>
                    <ArrowUpIcon />
                </div>
            </div>
        );
    }
}

export default NumberInput;
