/**
 * TX-ID property name. <br/>
 * Docs here: {@link https://jira.mios.com/browse/EPPT-5794 Capture the TX-ID of the device}:
 * */
export const TX_ID_PROP_NAME = '2gig.txId';

/**
 * Device category constants
 * @const DEVICE_CATEGORIES
 * @type {Object}
 * @property {string} SOS_BUTTON - Represents a SOS button
 * @example { SOS_BUTTON: 'sos_button' }
 * @see{[Doc for device categories](https://log.ezlo.com/new/devices/categories/)}
 */
export const DEVICE_CATEGORIES = {
    SOS_BUTTON: 'sos_button',
};

/**
 * An array to list categories of devices that are unavailable on Devices page.
 * @type {string[]}
 */
export const CATEGORIES_OF_UNAVAILABLE_DEVICES = [DEVICE_CATEGORIES.SOS_BUTTON];
