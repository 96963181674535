import React from 'react';

const IcTestModeTurnedOff = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28 32L30 30L2 2L0 4L10 14V17.0001C10 21.0001 14 23.0001 16 23.0001V20L28 32Z"
                fill="#1C1E21"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.2045 8.20449L13.6271 7.51102L12.25 4.82795L9.94939 5.94939L12.2045 8.20449ZM8.51823 8.51823L9.12713 9.70456L9.51533 9.51533L8.51823 8.51823ZM22.8612 22.8612C22.1261 23.7222 21.2239 24.4433 20.2042 24.9759L21.6584 27.62C22.9337 26.9539 24.0712 26.0696 25.0184 25.0184L22.8612 22.8612ZM16 3L18.25 4.09677L16.8729 6.77984L16 6.35436L15.1271 6.77984L13.75 4.09677L16 3ZM7.07841 14.1317H4V17.3011C4 18.4823 4.17962 19.6227 4.51369 20.6976L7.45994 19.828C7.21254 19.0319 7.07841 18.1845 7.07841 17.3011V14.1317ZM8.12852 21.4L5.41458 22.8159C6.53345 24.8526 8.25268 26.5289 10.3416 27.62L11.7958 24.9759C10.242 24.1643 8.96099 22.9153 8.12852 21.4ZM13.4081 25.6279L12.5149 28.4988C13.6178 28.8248 14.788 29 16 29C17.212 29 18.3822 28.8248 19.4851 28.4988L18.5919 25.6279C17.7753 25.8692 16.9061 26 16 26C15.0939 26 14.2247 25.8692 13.4081 25.6279ZM24.9216 12.0188H28V8.84943L25.75 7.75267L24.3729 10.4357L24.9216 10.7032V12.0188ZM22.8729 9.70456L24.25 7.02149L19.75 4.82795L18.3729 7.51102L22.8729 9.70456ZM24.9216 14.1317H28V17.3011C28 18.4823 27.8204 19.6227 27.4863 20.6976L24.5401 19.828C24.7875 19.0319 24.9216 18.1845 24.9216 17.3011V14.1317ZM7.07841 12.0188H4V8.84943L6.25 7.75266L7.62713 10.4357L7.07841 10.7032V12.0188Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcTestModeTurnedOff;
