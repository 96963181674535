import React from 'react';

const IcDeviceMiscKeypad = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
            <path
                d="M9 4.5C9 5.9 7.9 7 6.5 7C5.1 7 4 5.9 4 4.5C4 3.1 5.1 2 6.5 2C7.9 2 9 3.1 9 4.5ZM16 2C14.6 2 13.5 3.1 13.5 4.5C13.5 5.9 14.6 7 16 7C17.4 7 18.5 5.9 18.5 4.5C18.5 3.1 17.4 2 16 2ZM25.5 2C24.1 2 23 3.1 23 4.5C23 5.9 24.1 7 25.5 7C26.9 7 28 5.9 28 4.5C28 3.1 26.9 2 25.5 2ZM6.5 11C5.1 11 4 12.1 4 13.5C4 14.9 5.1 16 6.5 16C7.9 16 9 14.9 9 13.5C9 12.1 7.9 11 6.5 11ZM25.5 11C24.1 11 23 12.1 23 13.5C23 14.9 24.1 16 25.5 16C26.9 16 28 14.9 28 13.5C28 12.1 26.9 11 25.5 11ZM22.5 32L24.5 25.4C25.1 23.4 24.2 21.3 22.5 20.3L18.5 18V12.5C18.5 11.1 17.4 10 16 10C14.6 10 13.5 11.1 13.5 12.5V22L7.5 19C6.3 20.8 6.4 23.1 7.8 24.8L13.5 32H22.5Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcDeviceMiscKeypad;
