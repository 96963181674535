import at from '../constants/ActionTypes/Users';
import { LOGOUT } from '../constants/ActionTypes';
import produce from 'immer';
import { SUCCESS } from '../constants/Users';

const initialState = {
    usersList: [],
    pinCodes: [],
    selectedUser: null,
    selectedPinCode: null,
    requestStatus: '',
    requestStatusMessage: '',
    selectedUserDetails: null,
    isLoading: false,
};

export default produce((draft, action) => {
    switch (action.type) {
        case at.SET_USERS_LIST.success: {
            draft.usersList = action.usersList;
            break;
        }

        case at.SET_SELECTED_USER: {
            draft.selectedUser = action.user;
            break;
        }

        case at.SET_SELECTED_USER_DETAILS: {
            draft.selectedUserDetails = action.userDetails;
            break;
        }

        case at.SET_IS_LOADING: {
            draft.isLoading = action.isLoading;
            break;
        }

        case at.SET_USER_PIN_CODES_LIST.success: {
            draft.pinCodes = action.pinCodesList;
            break;
        }
        case at.SET_SELECTED_PIN_CODE: {
            draft.selectedPinCode = action.pinCode;
            break;
        }
        case at.SET_REQUEST_STATUS.success:
            draft.requestStatus = SUCCESS;
            draft.requestStatusMessage = action.message;
            break;
        case at.SET_REQUEST_STATUS.clear:
            draft.requestStatus = '';
            draft.requestStatusMessage = '';
            break;
        case LOGOUT:
            return initialState;
            break;

        default:
            break;
    }
}, initialState);
