import { initializeEzlogicOem } from '../../oem';
import { initializeLanguage } from '../../languages';

/**
 * Used to do necessary steps to initialize EZLogic application:
 * - read app config file
 * - read language files
 * */
export const initializeEzlogic = async () => {
    return await Promise.all([initializeEzlogicOem(), initializeLanguage()]);
};
