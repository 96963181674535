import React from 'react';

const IcDeviceSensorMotion = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="#1C1E21" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.1349 3.59479C20.1349 5.02785 18.9732 6.18957 17.5401 6.18957C16.107 6.18957 14.9453 5.02785 14.9453 3.59479C14.9453 2.16173 16.107 1 17.5401 1C18.9732 1 20.1349 2.16173 20.1349 3.59479ZM19.6019 11.1423L17.09 19.3176L20.1232 22.9077V30.455H18.5118C17.6588 30.455 16.9716 29.7678 16.9716 28.9148V23.8318L12.1493 18.1091C11.6991 17.5759 11.5569 16.8413 11.7583 16.1778L12.8246 12.6944L13.5 10.5024H10.3483L10.2891 10.6802L10.064 11.4266L9.43602 13.4764C9.27014 14.0214 8.77251 14.3887 8.20379 14.3887H6L6.30806 13.3816L6.91232 11.4266L6.97156 11.237L7.13744 10.6802L7.48104 9.55458L7.86019 8.3342C8.02607 7.78917 8.5237 7.42188 9.09242 7.42188H11.2844H16.4621L19.6019 11.1423ZM21.2253 13.085L20.1826 16.4973L22.5641 19.329C23.0618 19.9215 23.9148 20.0518 24.5665 19.6371L26.0002 18.7366L21.2253 13.085ZM9.59016 23.2393L10.7513 19.5781L12.3982 21.5213C12.8129 22.007 12.9432 22.6705 12.7537 23.2867L11.1186 28.4999L10.5262 30.4431H9.40059C8.39348 30.4431 7.67073 29.4715 7.96694 28.5118L9.59016 23.2393Z"
            />
        </svg>
    );
};

export default IcDeviceSensorMotion;
