import { MINUS_ONE_INT, ZERO_INT } from 'constants/MeshbotConstant';
import { INTEGRATIONS_SUPPORTED_ARCHITECTURES } from '../containers/Ezlo/EzloHubBackups/constants';
import { ONE_INT } from 'constants/Variables';

/**
 * Returns is linux-based controller
 * @param {object} controller - controller data
 * @returns {boolean} is linux-based
 * */
export const isControllerLinuxBased = (controller) => {
    const architecture = controller?.info?.architecture;

    if (typeof architecture === 'string' && architecture !== '') {
        return INTEGRATIONS_SUPPORTED_ARCHITECTURES.includes(architecture);
    }

    return false;
};

/**
 * Returns is online status.
 * @param {object} controller - controller data
 * @returns {boolean} isOnline
 * */
export const isControllerOnline = (controller) => {
    return controller?.isConnected;
};

/**
 * Compares the provided advanced currentAdvancedVersion with a specified advancedSceneVersion170.
 *
 * @param {string|number} currentAdvancedVersion - The currentAdvancedVersion to be compared.
 * @param {string|number} advancedSceneVersion170 - The advancedSceneVersion170 for comparison.
 * @returns {number} - Returns -1 if the currentAdvancedVersion is less than the advancedSceneVersion170,
 * 0 if they are equal, and 1 if the currentAdvancedVersion is greater than the advancedSceneVersion170.
 *
 * The function parses the provided versions as floats and performs a numeric comparison.
 * Returns -1 if the parsed currentAdvancedVersion is less than the specified advancedSceneVersion170,
 * 0 if they are equal, and 1 if the currentAdvancedVersion is greater than the advancedSceneVersion170.
 *
 * If either of the input values is not a valid numeric representation, the function returns NaN.
 *
 * @example
 * const result = compareAdvancedScenesVersion('1.69', '1.70');
 * // Returns -1
 */
export const compareAdvancedScenesVersion = (currentAdvancedVersion, advancedSceneVersion170) => {
    const parsedCurrentAdvancedVersion = parseFloat(currentAdvancedVersion);
    const parsedAdvancedSceneVersion = parseFloat(advancedSceneVersion170);

    if (isNaN(parsedCurrentAdvancedVersion) || isNaN(parsedAdvancedSceneVersion)) {
        return NaN;
    }

    if (parsedCurrentAdvancedVersion < parsedAdvancedSceneVersion) {
        return MINUS_ONE_INT;
    } else if (parsedCurrentAdvancedVersion > parsedAdvancedSceneVersion) {
        return ONE_INT;
    }

    return ZERO_INT;
};
