export default {
    CLEAR_ERRORS: 'CLEAR_ERRORS',
    DEVICE_FORCE_REMOVE: {
        pending: 'DEVICE_FORCE_REMOVE/pending',
        success: 'DEVICE_FORCE_REMOVE/success',
        rejected: 'DEVICE_FORCE_REMOVE/rejected',
    },
    DEVICE_CHECK: {
        pending: 'DEVICE_CHECK/pending',
        success: 'DEVICE_CHECK/success',
        rejected: 'DEVICE_CHECK/rejected',
    },
    GET_SETTINGS_DEVICE: {
        pending: 'GET_SETTINGS_DEVICE/pending',
        success: 'GET_SETTINGS_DEVICE/success',
        rejected: 'GET_SETTINGS_DEVICE/rejected',
    },
    GET_DEVICE_LIST: {
        pending: 'GET_DEVICE_LIST/pending',
        success: 'GET_DEVICE_LIST/success',
        rejected: 'GET_DEVICE_LIST/rejected',
    },
    CREATE_ASSOCIATION: {
        pending: 'CREATE_ASSOCIATION/pending',
        success: 'CREATE_ASSOCIATION/success',
        reject: 'CREATE_ASSOCIATION/reject',
    },
};
