import React from 'react';

const IcActionsDoorlock = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
            <path
                d="M16 21C15 21 14.2 21.9 14.3 23L14.8 26C14.9 26.6 15.4 27 16 27C16.6 27 17.1 26.6 17.2 26L17.7 23C17.8 21.9 17 21 16 21Z"
                fill="#1C1E21"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24 1C20.9674 0.172041 18.9941 0.000507859 16 8.68223e-07C13.0059 -0.000502952 11.2544 0.218133 8 1V31C11.2478 31.8196 12.9898 32.0243 16 32C19.0102 31.9757 20.6757 31.7682 24 31V1ZM21 24C21 26.7614 18.7614 29 16 29C13.2386 29 11 26.7614 11 24C11 21.2386 13.2386 19 16 19C18.7614 19 21 21.2386 21 24ZM11 16C11.5523 16 12 15.5523 12 15C12 14.4477 11.5523 14 11 14C10.4477 14 10 14.4477 10 15C10 15.5523 10.4477 16 11 16ZM12 10C12 10.5523 11.5523 11 11 11C10.4477 11 10 10.5523 10 10C10 9.44772 10.4477 9 11 9C11.5523 9 12 9.44772 12 10ZM11 6C11.5523 6 12 5.55229 12 5C12 4.44772 11.5523 4 11 4C10.4477 4 10 4.44772 10 5C10 5.55229 10.4477 6 11 6ZM17 15C17 15.5523 16.5523 16 16 16C15.4477 16 15 15.5523 15 15C15 14.4477 15.4477 14 16 14C16.5523 14 17 14.4477 17 15ZM16 11C16.5523 11 17 10.5523 17 10C17 9.44772 16.5523 9 16 9C15.4477 9 15 9.44772 15 10C15 10.5523 15.4477 11 16 11ZM17 5C17 5.55229 16.5523 6 16 6C15.4477 6 15 5.55229 15 5C15 4.44772 15.4477 4 16 4C16.5523 4 17 4.44772 17 5ZM21 16C21.5523 16 22 15.5523 22 15C22 14.4477 21.5523 14 21 14C20.4477 14 20 14.4477 20 15C20 15.5523 20.4477 16 21 16ZM22 10C22 10.5523 21.5523 11 21 11C20.4477 11 20 10.5523 20 10C20 9.44772 20.4477 9 21 9C21.5523 9 22 9.44772 22 10ZM21 6C21.5523 6 22 5.55229 22 5C22 4.44772 21.5523 4 21 4C20.4477 4 20 4.44772 20 5C20 5.55229 20.4477 6 21 6Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcActionsDoorlock;
