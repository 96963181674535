import * as localStorageKeys from '../constants/localStorageKeys';
import oemManager from '../services/oem';
import { getFileExtension } from '../services/mediaStore/src/utils';

const getCloudPluginEnvToken = () => {
    return oemManager.oem.getXApp();
};

export const axiosConfig = () => {
    const ind = localStorage.getItem(localStorageKeys.IDENTITY);
    const indSign = localStorage.getItem(localStorageKeys.IDENTITY_SIGNATURE);

    return {
        headers: {
            'MMSAuth': ind,
            'MMSAuthSig': indSign,
            'Content-Type': 'application/json;charset=UTF-8',
        },
    };
};

export const axiosCloudConfig = () => {
    const cloudToken = localStorage.getItem(localStorageKeys.CLOUD_TOKEN);

    return {
        headers: {
            'authorization': `Bearer ${cloudToken}`,
            'Content-Type': 'application/json;charset=UTF-8',
        },
    };
};

export const axiosCloudConfigDownload = () => {
    const cloudToken = localStorage.getItem(localStorageKeys.CLOUD_TOKEN);

    return {
        headers: {
            authorization: `Bearer ${cloudToken}`,
        },
    };
};

export const axiosCloudPlugin = () => {
    const cloudToken = localStorage.getItem(localStorageKeys.CLOUD_TOKEN);

    return {
        headers: {
            'Authorization': `Bearer ${cloudToken}`,
            'X-APP': getCloudPluginEnvToken(),
        },
    };
};

export const axiosCloudFile = (updatedFile) => {
    const cloudToken = localStorage.getItem(localStorageKeys.CLOUD_TOKEN);
    const fileFormat = getFileExtension(updatedFile);

    return {
        headers: {
            'Authorization': `Bearer ${cloudToken}`,
            'X-APP': getCloudPluginEnvToken(),
            'p-owner_entity_type': 'user',
            'p-bucket': 'media',
            'p-type': 'multimedia',
            'p-file_format': fileFormat,
            'p-filename': `logo.${fileFormat}`,
        },
    };
};

export const axiosCloudConfigForRemoveController = () => {
    const ind = localStorage.getItem(localStorageKeys.IDENTITY);
    const indSign = localStorage.getItem(localStorageKeys.IDENTITY_SIGNATURE);

    return {
        headers: {
            MMSAuth: ind,
            MMSAuthSig: indSign,
        },
    };
};

export const axiosKVSConfig = (authorization) => {
    return {
        headers: {
            'Content-Type': 'application/vnd.api+json',
            'Authorization': `Basic ${authorization}`,
        },
    };
};

export const simpleConfig = (options) => {
    const ind = localStorage.getItem(localStorageKeys.IDENTITY);
    const indSign = localStorage.getItem(localStorageKeys.IDENTITY_SIGNATURE);

    return {
        headers: {
            ...options,
            MMSAuth: ind,
            MMSAuthSig: indSign,
        },
    };
};
