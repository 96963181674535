import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MeshBotAction } from '../../../../../actions';

const withAbilityGoEditMeshbot = (Component) => {
    const WrapperComponent = (props) => {
        const dispatch = useDispatch();
        const navigate = useNavigate();
        const onEditMeshbot = (data) => dispatch(MeshBotAction.goEditMeshbot(data, navigate));

        return <Component {...props} onClick={onEditMeshbot} />;
    };

    return WrapperComponent;
};

export default withAbilityGoEditMeshbot;
