import React from 'react';

export const IcVisualsUploadPlugin = (props) => {
    return (
        <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0_13165_397)">
                <path
                    d="M128.571 66C109.118 66 92.4964 77.997 85.5786 94.9695C82.9786 94.272 80.2857 93.9 77.5 93.9C59.5321 93.9 45 108.455 45 126.45C45 144.446 59.5321 159 77.5 159H128.571C154.2 159 175 138.168 175 112.5C175 86.832 154.2 66 128.571 66Z"
                    fill="#32388B"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M153.639 151.639L101 99V98.5H100.5L101 99V159H128.571C137.803 159 146.407 156.297 153.639 151.639Z"
                    fill="#1F2581"
                />
                <path
                    d="M108.571 42C89.1179 42 72.4964 53.997 65.5786 70.9695C62.9786 70.272 60.2857 69.9 57.5 69.9C39.5321 69.9 25 84.4545 25 102.45C25 120.446 39.5321 135 57.5 135H108.571C134.2 135 155 114.168 155 88.5C155 62.832 134.2 42 108.571 42Z"
                    fill="#FE5C15"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M131.14 129.14L100.5 98.5L100.783 135H108.571C116.761 135 124.458 132.873 131.14 129.14Z"
                    fill="#E2280B"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M107.801 159L92.6008 159L92.6008 123.4L80 123.4L100.2 97.9999L120.5 123.4L107.801 123.4L107.801 159Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_13165_397">
                    <rect width="200" height="200" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
