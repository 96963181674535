import React from 'react';

const IcDeviceLighthingBasicLight = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
            <path
                d="M20.1499 24.8C20.1499 27.1 18.2499 29 15.9499 29C13.6499 29 11.7499 27.1 11.7499 24.8V23.6H20.0499V24.8H20.1499ZM11.0499 20.7H20.8499L22.6499 19C24.3499 17.3 25.3499 15 25.3499 12.4C25.3499 9.8 24.3499 7.5 22.6499 5.8C20.9499 4.1 18.5499 3 16.0499 3C13.5499 3 11.0499 4 9.3499 5.7C7.6499 7.4 6.6499 9.8 6.6499 12.4C6.6499 15 7.6499 17.3 9.3499 19L11.0499 20.7Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcDeviceLighthingBasicLight;
