import React from 'react';

const IcDeviceAirQulitySensorClean = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M31 29L29 31L24.2487 26.2487C23.002 27.7521 21.1154 28.7 19 28.7C17.2001 28.7 15.5001 28 14.2001 26.7L16.3 24.6C16.3 24.6 16.5 24.7 16.6 24.8C17.3 25.4 18.1 25.7 19 25.7C20.28 25.7 21.4114 25.0685 22.0998 24.0998L1 3L3 1L22.4433 20.4433L25.5951 23.5951L31 29ZM3.30005 12.1H6.30005V15.1H3.30005V12.1ZM12.3 16L14.4 18.1L12.3 20.2L14.4 22.3L12.3 24.5L10.1 22.3L8.00002 24.5L5.90002 22.3L8.00002 20.2L5.90002 18.1L8.00002 16L10.1 18.1L12.3 16ZM30.9001 15.5C30.9001 17.9998 29.5345 20.2396 27.4326 21.4326L25.0168 19.0168C26.6506 18.5196 27.8 17.0866 27.8 15.3C27.8 13.4 26.5 11.8 24.7001 11.5C24.1 11.3 23.5 11.4 22.8 11.6L21.4001 12L22.2001 10.7C22.6 10.1 22.8 9.50002 22.8 8.80002V8.70003C22.8 6.60003 21.1 4.90002 19 4.90002C17.7001 4.90002 16.5 5.50003 15.8 6.60003L15.5 7.10003L13.3 4.90002C14.5 3.00002 16.6 1.90002 18.9 1.90002C22.6 1.90002 25.6 4.80002 25.7001 8.50002C28.7001 9.30002 30.8001 12 30.9001 15.5Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcDeviceAirQulitySensorClean;
