import * as meshBot from '../../../../../constants/MeshbotConstant';
import { OEM_IDS } from '../../../../../services/oem/src/constants/oems';
import {
    HOUSE_MODES,
    SECURITY_MODES,
    HOUSE_MODE_NODES,
    SECURITY_MODE_NODES,
    actionsInitialOptionsSelect,
    MESHBOT_NODE_TYPES,
    SECURITY_MODE,
} from '../../../../../constants/MeshbotConstant';
import { info, getSupportedEzlopiElements } from '../../utils';
import { MESHBOT_TYPES } from 'containers/Ezlo/EzloMeshbots/constants';

export const getHouseModeWhenBlock = (node, value) => [
    {
        blockOptions: {
            method: {
                name: node,
                args: {
                    houseMode: 'houseMode',
                },
            },
        },
        blockType: 'when',
        fields: [
            {
                name: 'houseMode',
                type: 'houseModeId_array',
                value,
            },
        ],
    },
];

const getSelectedModesByOemId = (oemId, value) => {
    if (oemId === OEM_IDS.SECURITY_OEM_ID) {
        return meshBot.SECURITY_MODES.find((mode) => mode.value === value);
    }

    return meshBot.HOUSE_MODES.find((mode) => mode.value === value);
};

export const getSelectedHouseModes = (blocks, oemId) => {
    return (
        (blocks &&
            blocks[meshBot.INDEX_SELECTED_VALUE]?.fields &&
            blocks[meshBot.INDEX_SELECTED_VALUE]?.fields[meshBot.INDEX_SELECTED_VALUE]?.value?.map((value) => {
                return getSelectedModesByOemId(oemId, value);
            })) ||
        []
    );
};

export const getSelectedNode = (blocks) => {
    return (blocks && blocks[0]?.blockOptions?.method?.name) || '';
};

export const getLabelByOemId = (oemId) => {
    if (oemId === OEM_IDS.SECURITY_OEM_ID) {
        return info.hints.securityModes;
    }

    return info.hints.houseModes;
};

export const getModesListByOemId = (oemId) => {
    if (oemId === OEM_IDS.SECURITY_OEM_ID) {
        return SECURITY_MODES;
    }

    return HOUSE_MODES;
};

/**
 * This function returns mode nodes per the passed parameters
 * @param {number} oemId -  oem id
 * @param {string} meshbotType - meshbot type
 * @param {string} firmwareVersion - firmware version
 * @returns {array}
 */
export const getSupportedModeNodes = (oemId, meshbotType, firmwareVersion) => {
    if (oemId === OEM_IDS.SECURITY_OEM_ID) {
        return meshbotType === MESHBOT_TYPES.EZLOPI
            ? getSupportedEzlopiElements(SECURITY_MODE_NODES, firmwareVersion)
            : SECURITY_MODE_NODES;
    }

    return meshbotType === MESHBOT_TYPES.EZLOPI
        ? getSupportedEzlopiElements(HOUSE_MODE_NODES, firmwareVersion)
        : HOUSE_MODE_NODES;
};

export const getNodesListForActionsLocalSectionByOemId = (oemId) => {
    if (oemId === OEM_IDS.SECURITY_OEM_ID) {
        return actionsInitialOptionsSelect.map((node) => {
            if (node.id === MESHBOT_NODE_TYPES.ACTION_HOUSE_MODE) {
                return { ...node, name: SECURITY_MODE };
            }

            return node;
        });
    }

    return actionsInitialOptionsSelect;
};
