import React from 'react';

const IcActionsSpeakOff = (props) => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26.9853 20.9853C28.2623 18.9699 29 16.5735 29 14H26C26 15.7271 25.5563 17.3556 24.7771 18.7771L26.9853 20.9853ZM22.536 16.536C22.836 15.7517 23 14.897 23 14V7C23 3.1 19.9 0 16 0C13.2682 0 10.9289 1.52102 9.77259 3.77259L22.536 16.536ZM25.2073 23.2073L30 28L28 30L22.9863 24.9863C21.369 26.0108 19.5059 26.684 17.5 26.9V30H14.5V26.9C8 26.2 3 20.7 3 14H6C6 19.5 10.5 24 16 24C17.7271 24 19.3556 23.5563 20.7771 22.7771L18.536 20.536C17.7517 20.836 16.897 21 16 21C12.1 21 9 17.9 9 14V11L0 2L2 0L9 7L20.9625 18.9625L23.0625 21.0625L25.2073 23.2073Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcActionsSpeakOff;
