import React from 'react';

export const IcControlsUpgrade = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M17.5 9H21L16.7071 4.70711C16.3166 4.31658 15.6834 4.31658 15.2929 4.70711L11 9H14.5V20H17.5V9ZM23 25C23 26.7 21.7 28 20 28H12C10.3 28 9 26.7 9 25H12H20H23Z"
                fill="#1C1E21"
            />
        </svg>
    );
};
