export const VIRTUAL_DEVICE_PAGES = {
    INDEX: 'virtual-device-manager',
    NEW: 'new',
    EDIT: 'edit',
    MAP: 'map',
};

export const MANAGE_VIRTUAL_DEVICE_FORM_CATEGORIES = {
    DEVICES: 'devices', // do not rename
    SERVICES: 'services', // do not rename
};

export const MANAGE_VIRTUAL_DEVICE_FORM_STEPS = {
    DEVICES_SERVICES_STEP: 1,
    CAPABILITIES_STEP: 2,
    CONFIRM_STEP: 3,
    // MAP_TO_TILES_STEP: 4,
};

export const ABSTRACT_SOURCES = {
    CONTROLLER: 'controller',
    INTEGRATION: 'integration',
    VIRTUAL_DEVICE: 'virtual_device',
    MESHBOT: 'meshbot',
    DEVICE: 'device',
};

export const CAPABILITIES = {
    TEXT_COMMAND: 'text_command',
    POWER_COMMAND: 'power_command',
    DIMMER_COMMAND: 'dimmer_command',
    SCENE_RUN: 'scene_run',
};

export const VOI_SUPPORTED_DEVICE_CATEGORIES = {
    SWITCH: 'switch',
    DIMMER: 'dimmer',
};
