import moment from 'moment';
import { toast } from 'react-toastify';

import { apiGetKvsDataByKey, apiSetDataToKvs } from '../../../api/apiScenes';
import {
    ACCESS_REVOKED,
    ALLOW_INSTALLER_ACCESS_DEADLINE_KVS_KEY,
    ALLOW_INSTALLER_ACCESS_KVS_KEY,
    CHECK_ACCESS_INTERVAL_TIMEOUT,
    LOCATION_TYPE,
    SUCCESS,
    ZERO_INT,
    DISABLE,
} from '../constants';
import { TOAST_TYPE } from '../../../components/Toast';
import GenericActions from '../../../actions/GenericActions';
import { compareDateTimes, uploadInstallerAccessData } from '../utils';
import {
    setInitialAccessStatus,
    setInstallerAccessStatus,
    setIsLoading,
    updateInstallerAccessRightsDeadlineData,
} from '../reducers/installerAccessRights';
import { getInstallerAccessRightsStatus } from './installerAccessRightsApi';

const actions = {
    uploadInstallerAccess: (installerAccessStatus) => async (dispatch) => {
        try {
            dispatch(setIsLoading(true));
            const kvsSetPayloadData = uploadInstallerAccessData(
                LOCATION_TYPE,
                ALLOW_INSTALLER_ACCESS_KVS_KEY,
                installerAccessStatus,
            );
            const installerAccessResponse = await apiSetDataToKvs(kvsSetPayloadData);
            if (installerAccessResponse?.data?.status === SUCCESS) {
                dispatch(setInitialAccessStatus(installerAccessStatus));
            }
        } catch (err) {
            toast(err, { type: TOAST_TYPE.ERROR });
        } finally {
            dispatch(setIsLoading(false));
        }
    },

    handleUploadInstallerAccessRightsDeadlineData: (installerAccessTime) => async (dispatch) => {
        try {
            dispatch(setIsLoading(true));
            const kvsSetPayloadData = uploadInstallerAccessData(
                LOCATION_TYPE,
                ALLOW_INSTALLER_ACCESS_DEADLINE_KVS_KEY,
                installerAccessTime,
            );
            await apiSetDataToKvs(kvsSetPayloadData);
        } catch (err) {
            toast(err, { type: TOAST_TYPE.ERROR });
        } finally {
            dispatch(setIsLoading(false));
        }
    },

    getInstallerAccessRightsDeadlineData:
        (shouldSetLoading = true) =>
        async (dispatch) => {
            try {
                if (shouldSetLoading) {
                    dispatch(setIsLoading(true));
                }
                const installerAccessRights = await apiGetKvsDataByKey(
                    ALLOW_INSTALLER_ACCESS_DEADLINE_KVS_KEY,
                    LOCATION_TYPE,
                );
                if (
                    installerAccessRights.data?.data?.list[ZERO_INT]?.value &&
                    installerAccessRights.data?.status === SUCCESS
                ) {
                    const installerAccessDeadline = installerAccessRights.data?.data?.list[ZERO_INT]?.value;
                    dispatch(updateInstallerAccessRightsDeadlineData(installerAccessDeadline));

                    return installerAccessDeadline;
                }
            } catch (error) {
                toast(error, { type: TOAST_TYPE.ERROR });
            } finally {
                if (shouldSetLoading) {
                    dispatch(setIsLoading(false));
                }
            }

            return null;
        },

    checkInstallerUserAccess: (navigate) => async (dispatch) => {
        const response = await dispatch(actions.getInstallerAccessRightsDeadlineData(false));
        const accessInstallerStatus = await getInstallerAccessRightsStatus();

        if (response) {
            const currentDateAndTime = moment();
            const isAccessEnabled = compareDateTimes(currentDateAndTime, response);

            if (!isAccessEnabled || accessInstallerStatus === DISABLE) {
                dispatch(GenericActions.logout());
                navigate(ACCESS_REVOKED);
            }
        }
    },

    startInstallerAccessCheckInterval: (navigate) => (dispatch) => {
        const accessCheckInterval = setInterval(() => {
            dispatch(actions.checkInstallerUserAccess(navigate));
        }, CHECK_ACCESS_INTERVAL_TIMEOUT);

        return () => {
            clearInterval(accessCheckInterval);
        };
    },

    getIntallerAccessStatus: async (dispatch) => {
        const response = await getInstallerAccessRightsStatus();
        dispatch(setInstallerAccessStatus(response));
        dispatch(setInitialAccessStatus(response));
    },
};

export default actions;
