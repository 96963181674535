import * as types from '../constants/ActionTypes';
import update from 'immutability-helper';
const defaultState = {
    kvsPayloadData: { images: { logo: null } },
    ezlogicPortal: {
        menuBar: {},
        listUi: {},
        meshbot: {},
        colorTheme: [],
        nodesControllables: {},
    },
    primaryColor: '',
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case types.MENU_UI_ACTION:
            return update(state, {
                ezlogicPortal: { menuBar: { $set: action?.data } },
            });
        case types.LISTING_UI_ACTION:
            return update(state, {
                ezlogicPortal: { listUi: { $set: action?.data } },
            });
        case types.MESHBOT_UI_ACTION:
            return update(state, {
                ezlogicPortal: { meshbot: { $set: action?.data } },
            });
        case types.EZLOGIC_PORTAL_COLOR_THEME:
            return update(state, {
                ezlogicPortal: { colorTheme: { $set: action?.data } },
            });
        case types.NODES_CONTROLLABLE_ACTION:
            return update(state, {
                ezlogicPortal: { nodesControllables: { $set: action?.data } },
            });
        case types.PRIMARY_COLOR_ACTION:
            return update(state, {
                primaryColor: { $set: action?.data },
            });
        case types.IS_VERA_USER:
            return update(state, {
                isVeraUser: { $set: action?.data },
            });
        case types.CURRENT_CUSTOMIZATION:
            return update(state, {
                ezlogicPortal: {
                    menuBar: { $set: action.data?.menuBar },
                    listUi: { $set: action.data?.listingUi },
                    meshbot: { $set: action.data?.meshbotUi },
                    colorTheme: { $set: action.data?.colorTheme },
                    nodesControllables: { $set: action.data?.defaultNodesControllables },
                },
            });
        case types.LOGO:
            const kvsPayloadData = state.kvsPayloadData || {};
            const images = kvsPayloadData.images || {};
            const updatedImages = { ...images, logo: action.data };
            const updatedKvsPayloadData = { ...kvsPayloadData, images: updatedImages };

            return { ...state, kvsPayloadData: updatedKvsPayloadData };
        case types.KVS_PAYLOAD_ACTION:
            return update(state, {
                kvsPayloadData: { $set: action?.data },
            });
        case types.LOGOUT:
            return defaultState;
        default:
            return state;
    }
};
