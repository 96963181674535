import React from 'react';

const IcThermostatSetpoint = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 30C23.732 30 30 23.732 30 16C30 15.9707 29.9999 15.9414 29.9997 15.9121C29.5542 16.2482 28.9998 16.448 28.4043 16.448C26.9802 16.448 25.7642 15.28 25.7642 13.84C25.7642 12.4 26.9802 11.248 28.4043 11.248C28.6872 11.248 28.9609 11.2925 29.218 11.3749C27.3079 5.91562 22.1112 2 16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30ZM13.3118 13.936L9.19975 22H11.0078L15.6478 12.752V11.504H8.14375V13.088H13.7758L13.7917 13.216L13.3118 13.936ZM17.4753 20.736V22H24.1313V20.464H19.7472L19.6992 20.352L20.4352 19.744L22.3073 17.632C23.5233 16.256 24.0993 15.488 24.0993 14.32C24.0993 12.512 22.6912 11.248 20.6273 11.248C18.9792 11.248 17.5072 12.272 17.1073 13.632L18.7073 14.192C18.8993 13.376 19.6353 12.752 20.5952 12.752C21.6833 12.752 22.4032 13.392 22.4032 14.352C22.4032 15.008 22.0352 15.52 21.0913 16.624L17.4753 20.736ZM29.5083 13.84C29.5083 14.512 29.0443 14.96 28.4043 14.96C27.7803 14.96 27.3003 14.512 27.3003 13.84C27.3003 13.184 27.7803 12.736 28.4043 12.736C29.0443 12.736 29.5083 13.184 29.5083 13.84Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcThermostatSetpoint;
