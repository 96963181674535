export const DEVICE_ASSOCIATIONS = {
    FIRMWARE_BUILD_VERSION: 1868,
    ALTERNATIVE_FIRMWARE_BUILD_VERSION: '0',
    FIRMWARE_DEVELOPMENT_BUILD_VERSION: 0,
    FIRMWARE_BUILD_VERSION_INDEX: 3,
};
export const ASSOCIATION_GROUP = 'association_group';
export const ARRAY_ASSOCIATION = 'array.association';
export const REMOVED = 'removed';
export const ADDED = 'added';
export const ZWAVE_NODE = 'zwave.node';
export const LARGE = 'large';
