import { createSlice } from '@reduxjs/toolkit';
import { MEDIA_STORE_NAME } from '../constants';

const initialState = {
    imagesList: [],
    isEndPage: false,
    uploadingImages: false,
};

const media = createSlice({
    name: MEDIA_STORE_NAME,
    initialState,
    reducers: {
        unshiftImages: (state, action) => {
            state.imagesList = [...action.payload, ...state.imagesList];
        },
        setImages: (state, action) => {
            state.imagesList = action.payload;
        },
        setIsEndPage: (state, action) => {
            state.isEndPage = action.payload;
        },
        toggleUploadingImages: (state) => {
            state.uploadingImages = !state.uploadingImages;
        },
        clearImagesList: (state) => {
            state.imagesList = [];
        },
    },
});

export const { setImages, clearImagesList, unshiftImages, toggleUploadingImages, setIsEndPage } = media.actions;
export default media;
export const mediaStoreReducer = media.reducer;
