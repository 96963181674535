import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import kvsSlice from '../../reducers/kvs';
import actionCreators from '../../actions/kvsActions';

const useKvsActions = () => {
    const dispatch = useDispatch();

    return bindActionCreators({ ...actionCreators, ...kvsSlice.actions }, dispatch);
};

export default useKvsActions;
