import { BLOCK_NAME, INDEX_OF_ZERO, ID_PROPERTY, ACTION_GROUP } from 'constants/MeshbotConstant';
import at from 'constants/ActionTypes/MeshBot';
import { createBlockForException, getFieldsStructure, groupBlock } from '../utils';
import { cloneDeep } from 'lodash';

/**
 * Modifies and returns a 'block' from a given trigger object. The block consists of either a group of blocks or
 * a single block at a specific index from the trigger's blocks. During this process, the block's 'id' and
 * 'action group' properties are deleted.
 *
 * @param   {Object} trigger - The input object from which the block is derived.
 *
 * @returns {Object} The resulting block, with 'id' and 'action group' properties removed.
 *
 * @throws  {Error} If the provided argument is not of expected type ('object')
 **/
export const getBlockFromTrigger = (trigger) => {
    const block = trigger.hasOwnProperty(BLOCK_NAME)
        ? groupBlock(trigger, getFieldsStructure(at.BLOCKS, at.BLOCKS, createBlockForException(trigger.blocks)))
        : cloneDeep(trigger.blocks[INDEX_OF_ZERO]);

    if (block?.hasOwnProperty(ID_PROPERTY)) {
        delete block[ID_PROPERTY];
    }

    if (block?.hasOwnProperty(ACTION_GROUP)) {
        delete block[ACTION_GROUP];
    }

    return block;
};
