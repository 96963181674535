import { prettierInteractions } from '../utils';
import { getIntegrationPreview } from './getIntegrationPreview';

/**
 * Retrieves the integration data for a given integration UUID.
 * Returns an object containing the integration preview and formatted interactions.
 *
 * @async
 * @param {string} integrationUuid - The UUID of the integration to retrieve data for.
 * @returns {Promise<{integrationPreview: Object, interactions: Array<Object>} An object containing the integration preview and interactions, or undefined if the preview is not available.
 */
export const getIntegrationData = async (integrationUuid) => {
    const integrationPreview = await getIntegrationPreview(integrationUuid);
    if (!integrationPreview) {
        return;
    }

    return {
        integrationPreview,
        interactions: prettierInteractions(integrationPreview),
    };
};
