import { PAGES_PATHS } from '../../constants/toastConstants';

export const isPathPartInPathname = (pathPart, pathname) => {
    if (!pathPart || !pathname) {
        return false;
    }

    return Boolean(pathname.includes(pathPart) && pathname.slice(0, pathPart.length) === pathPart);
};

export const getPathPart = (pathname) => {
    let pagePathPart = null;
    PAGES_PATHS.forEach((pagePath) => {
        if (isPathPartInPathname(`/${pagePath}`, pathname)) {
            pagePathPart = `/${pagePath}`;
        }
    });

    return pagePathPart;
};

export const isAnotherPage = (previousPath, currentPath) => {
    if (previousPath && currentPath) {
        const previousPathPart = getPathPart(previousPath);
        if (previousPathPart && !isPathPartInPathname(previousPathPart, currentPath)) {
            return true;
        }
    }

    return false;
};

export const getErrorUntilAcknowledgedPayload = (toast, onClose, toastId) => {
    const options = {
        ...toast.options,
        toastId,
        onClose,
        closeOnClick: false,
    };

    return {
        message: toast.message,
        options,
        isSave: false,
    };
};
