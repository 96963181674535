import React from 'react';
import { useSelector } from 'react-redux';

import { FAIL, SUCCESS } from '../../../constants/MeshbotConstant';
import oemManager from '../../../services/oem';
import { OemLogo } from './OemLogo';
import { checkPartnerDomain } from './utils';

import '../../../containers/Navigation/style.scss';
import '../../../containers/AuthorizationPages/style.scss';

// TODO: let's avoid copy/paste, refactoring needed.
export const AppLogo = ({ isHeader }) => {
    const isInitialized = useSelector((state) => state.oem.isInitialized);
    const oemId = isInitialized ? oemManager.oem.getOemId() : null;
    const logo = useSelector((state) => state.ezloCustomization?.logo);
    const getCustomizationData = useSelector((state) => state?.ezloCustomization);
    const isPartnerDomain = checkPartnerDomain();

    const getLogo = (kvsGetStatus, partnerDomainStatus) => {
        if (kvsGetStatus === null && partnerDomainStatus === null) {
            return '';
        }

        if (kvsGetStatus === FAIL || partnerDomainStatus === FAIL) {
            return <OemLogo oemId={oemId} />;
        }

        if (kvsGetStatus === SUCCESS) {
            return logo ? (
                <div className={`${isHeader ? '' : 'customized-logo'}`}>
                    {' '}
                    <img className={`${isHeader ? 'navigation__img' : ''}`} src={logo} />{' '}
                </div>
            ) : (
                <div>
                    {' '}
                    <OemLogo oemId={oemId} />
                </div>
            );
        }

        return '';
    };

    if (isPartnerDomain) {
        return getLogo(getCustomizationData?.status, getCustomizationData?.partnerDomainStatus);
    }

    return logo ? (
        <div className={` ${isHeader ? '' : 'customized-logo'}`}>
            {' '}
            <img src={logo} className={`${isHeader ? 'navigation__img' : ''}`} />{' '}
        </div>
    ) : (
        <div>
            <OemLogo oemId={oemId} />{' '}
        </div>
    );
};
export default AppLogo;
