import React from 'react';

const IcDeviceAirQulitySensorModereately = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.8998 2.90039C21.5998 2.90039 24.5998 5.80039 24.6998 9.50039C27.6998 10.3004 29.7998 13.0004 29.8998 16.5004C29.8998 19.6004 27.7998 22.3004 24.7998 23.1004C24.6998 26.8004 21.6998 29.7004 17.9998 29.7004C16.1998 29.7004 14.4998 29.0004 13.1998 27.7004L15.2998 25.6004C15.2998 25.6004 15.4998 25.7004 15.5998 25.8004C16.2998 26.4004 17.0998 26.7004 17.9998 26.7004C20.0998 26.7004 21.7998 25.0004 21.7998 22.9004V22.8004C21.6998 22.1004 21.4998 21.5004 21.1998 20.9004L20.2998 19.5004L21.7998 20.0004C22.4998 20.2004 22.9998 20.2004 23.6998 20.1004C25.4998 19.7004 26.7998 18.2004 26.7998 16.3004C26.7998 14.4004 25.4998 12.8004 23.6998 12.5004C23.0998 12.3004 22.4998 12.4004 21.7998 12.6004L20.3998 13.0004L21.1998 11.7004C21.5998 11.1004 21.7998 10.5004 21.7998 9.80039V9.70039C21.7998 7.60039 20.0998 5.90039 17.9998 5.90039C16.6998 5.90039 15.4998 6.50039 14.7998 7.60039L14.4998 8.10039L12.2998 5.90039C13.4998 4.00039 15.5998 2.90039 17.8998 2.90039ZM3 7H6V10H3V7ZM9 13H12V16H9V13ZM15 13H18V16H15V13ZM18 19H15V22H18V19ZM12 7H9V10H12V7ZM11.3004 17L13.4004 19.1L11.3004 21.2L13.4004 23.3L11.3004 25.5L9.10039 23.3L7.00039 25.5L4.90039 23.3L7.00039 21.2L4.90039 19.1L7.00039 17L9.10039 19.1L11.3004 17ZM6 13H3V16H6V13Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcDeviceAirQulitySensorModereately;
