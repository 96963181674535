import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AdapterDayjs, LocalizationProvider } from 'lib/@mui';
import SystemHistorySearchByDatePicker from './SystemHistorySearchByDatePicker';

import { systemHistoryActions } from '../../../actions';
import { getLastWeek } from '../../../utils';

import styles from './systemHistorySearchByDate.module.scss';
import { EZLOGIC_TITLE_END_DATE, EZLOGIC_TITLE_START_DATE } from 'constants/language_tokens';
import { useTranslate } from 'features/languages';

const SystemHistorySearchByDate = () => {
    const dispatch = useDispatch();
    const [selectedDateBefore, setSelectedDateBefore] = useState(null);
    const [selectedDateAfter, setSelectedDateAfter] = useState(null);
    const { isDateValid } = useSelector((state) => state.systemHistory);
    const { t } = useTranslate();

    useEffect(() => {
        const dateAfter = getLastWeek(new Date());
        const dateBefore = new Date();
        setSelectedDateAfter(dateAfter);
        setSelectedDateBefore(dateBefore);
        dispatch(systemHistoryActions.setHistorySearchByDateBefore(dateBefore?.setHours(23, 59, 59, 999)));
        dispatch(systemHistoryActions.setHistorySearchByDateAfter(dateAfter?.setHours(0, 0, 0, 0)));
    }, []);

    const handleChangeDateBefore = (value) => {
        const date = new Date(value);
        const dateBefore = date?.setHours(23, 59, 59, 999);
        setSelectedDateBefore(date);
        dispatch(systemHistoryActions.setHistorySearchByDateBefore(dateBefore));
    };

    const handleChangeDateAfter = (value) => {
        const date = new Date(value);
        const dateAfter = date?.setHours(0, 0, 0, 0);
        setSelectedDateAfter(date);
        dispatch(systemHistoryActions.setHistorySearchByDateAfter(dateAfter));
    };

    return (
        <div className={styles.searchByDateContainer}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <SystemHistorySearchByDatePicker
                    selectedDate={selectedDateAfter}
                    handleChangeDate={handleChangeDateAfter}
                    placeholder={t(EZLOGIC_TITLE_START_DATE)}
                    isDateValid={isDateValid}
                />
                <SystemHistorySearchByDatePicker
                    selectedDate={selectedDateBefore}
                    handleChangeDate={handleChangeDateBefore}
                    placeholder={t(EZLOGIC_TITLE_END_DATE)}
                    isDateValid={isDateValid}
                />
            </LocalizationProvider>
        </div>
    );
};

export default SystemHistorySearchByDate;
