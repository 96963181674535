import React from 'react';

const IcControlsRotate = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
            <path
                d="M25.2 6.8C22.7 4.3 19.5 3 16 3V0.5L13 2.5L10 4.5L13 6.5L16 8.5V6C18.7 6 21.2 7 23.1 8.9C25 10.8 26 13.3 26 16C26 18.7 25 21.2 23.1 23.1C21.2 25 18.7 26 16 26C13.3 26 10.8 25 8.9 23.1C7 21.2 6 18.7 6 16C6 13.3 7 10.8 8.9 8.9L6.8 6.8C4.4 9.3 3 12.5 3 16C3 19.5 4.4 22.7 6.8 25.2C9.3 27.7 12.5 29 16 29C19.5 29 22.7 27.6 25.2 25.2C27.7 22.7 29 19.5 29 16C29 12.5 27.6 9.3 25.2 6.8Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcControlsRotate;
