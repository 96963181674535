import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../actions/index';
import EzlosDropdown from './EzlosDropdown';

const mapStateToProps = (state, ownProps) => {
    let currentEzlo = {
        serial: state.ezlo.serial,
        connected: state.ezlo.data[state.ezlo.serial] && state.ezlo.data[state.ezlo.serial].isConnected,
    };

    if (ownProps.currentEzlo) {
        currentEzlo = {
            serial: ownProps.currentEzlo.serial,
            connected: ownProps.currentEzlo.connected,
        };
    }

    return {
        ezlos: state.ezlos.items.slice(),
        currentEzlo: currentEzlo,
        ezlo: state.ezlo,
    };
};

const mapDispatchToProps = (dispatch) => {
    const obj = {};

    Object.keys(Actions).map((actionsName) => {
        if (actionsName !== '__esModule') {
            obj[actionsName] = bindActionCreators(Actions[actionsName], dispatch);
        }
    });

    return obj;
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    redux: {
        state: stateProps,
        actions: dispatchProps,
    },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(EzlosDropdown);
