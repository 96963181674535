import hash from './uniqueHash';
import {
    EZLOGIC_TITLE_AFTER,
    EZLOGIC_TITLE_BEFORE,
    EZLOGIC_TITLE_EXACTLY_AT,
    EZLOGIC_TITLE_HOURS,
    EZLOGIC_TITLE_MINUTES,
    EZLOGIC_TITLE_SUNRISE,
    EZLOGIC_TITLE_SUNSET,
} from './language_tokens';

export const getCurrentYear = () => {
    return new Date().getFullYear();
};

// max input value
export const HOUR_MAX_INPUT_VALUE = 12;
export const HOUR_MIN_INPUT_VALUE = 1;

// month max input value
export const MONTH_MAX_INPUT_VALUE = 12;
export const MONTH_MIN_INPUT_VALUE = 1;

export const DAY_MAX_INPUT_VALUE = 31;
export const DAY_MIN_INPUT_VALUE = 1;

export const INTERVAL_MIN_INPUT_VALUE = 1;
export const INTERVAL_MAX_INPUT_VALUE = 59;

export const INTERVAL_MINUTE_OPTION = 'minute';

export const YEAR_MAX_INPUT_VALUE = 2999;

export const VARIABLES_OPTIONS = {
    STATUS: 'status',
    VALUE: 'value',
    RANGE: 'range',
};

export const getDefaultHourAndMinute = () => [12, 0];

export const specialTimeOfTheDayOptions = [
    { id: hash(), label: EZLOGIC_TITLE_SUNRISE, value: 'sunrise' },
    { id: hash(), label: EZLOGIC_TITLE_SUNSET, value: 'sunset' },
];

export const offsetOptions = [
    { id: hash(), label: EZLOGIC_TITLE_AFTER, value: 1 },
    { id: hash(), label: EZLOGIC_TITLE_BEFORE, value: -1 },
    { id: hash(), label: EZLOGIC_TITLE_EXACTLY_AT, value: 0 },
];

export const DATE_AND_TIME_SUB_NODES = {
    INTERVAL: 'interval',
    DAILY: 'daily',
    WEEKLY: 'weekly',
    MONTHLY: 'monthly',
    ONCE: 'once',
};

export const intervalOptions = [
    { value: 'minute', name: EZLOGIC_TITLE_MINUTES },
    { value: 'hour', name: EZLOGIC_TITLE_HOURS },
];
