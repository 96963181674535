import React from 'react';

const IcGenericSensorWithBypass = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
            <circle cx="4" cy="23" r="4" fill="#1C1E21" />
            <circle cx="16" cy="23" r="3" stroke="#1C1E21" strokeWidth="2" />
            <circle cx="28" cy="23" r="4" fill="#1C1E21" />
            <path
                d="M17.8349 4.44088C21.25 5.35594 23.9758 7.47723 25.7258 10.5083L27.8909 9.25832L27.5233 14.9576C27.4774 15.6697 26.7218 16.1059 26.0822 15.7896L20.9627 13.2583L23.1277 12.0083C21.7777 9.67005 19.6617 8.00498 17.0663 7.30953C14.4708 6.61409 11.8057 6.99806 9.46748 8.34806C7.12921 9.69806 5.46414 11.8141 4.76869 14.4095L1.90004 13.6409C2.8151 10.2258 4.93639 7.49998 7.96748 5.74998C10.9986 3.99998 14.4698 3.61242 17.8349 4.44088Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcGenericSensorWithBypass;
