import React from 'react';

const IcControlsClose = (props) => {
    return (
        <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M22.24 0.879941C21.07 -0.290059 19.17 -0.290059 18 0.879941L11.12 7.75994L4.24 0.879941C3.07 -0.290059 1.17 -0.290059 0 0.879941L8.94 9.81994L0 18.7599C1.17 19.9299 3.07 19.9299 4.24 18.7599L11.12 11.8799L18 18.7599C19.17 19.9299 21.07 19.9299 22.24 18.7599L13.3 9.81994L22.24 0.879941Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcControlsClose;
