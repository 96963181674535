/**
 * Application configuration file name. Please, do not rename it. <br/>
 * More info: {@link https://confluence.mios.com/display/WPT/Application+configuration+file%3A+config.json config.json}
 * */
export const APP_CONFIG_FILENAME = 'config.json';

/**
 * URL for application configuration file.
 * Currently, config file placed to "/public" folder, so the file path is relative. <br/>
 * More info: {@link https://confluence.mios.com/display/WPT/Application+configuration+file%3A+config.json config.json}
 * */
export const APP_CONFIG_URL = `/${APP_CONFIG_FILENAME}`;
