import React from 'react';

const IcActionsThermostatModeAuto = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
            <path
                d="M6 24.3C4.1 22 3 19.2 3 16C3 8.8 8.8 3 16 3C23.2 3 29 8.8 29 16C29 19.2 27.9 22 26 24.3L17.5 9H14.5L6 24.3ZM12.3 19.2H19.6L16 13L12.3 19.2ZM21.4 22.2H10.6L8.2 26.3C10.3 28 13 29 16 29C19 29 21.7 28 23.8 26.4L21.4 22.2Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcActionsThermostatModeAuto;
