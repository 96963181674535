/**
 * Object containing log-related settings.
 *
 * @constant
 * @type {Object}
 * @property {string} color - Log color setting.
 * @property {string} indent - Log indentation setting.
 * @property {string} level - Log level setting.
 */
export const logs = {
    color: 'logs.color',
    indent: 'logs.indent',
    level: 'logs.level',
};
/**
 * Array containing log-related command names.
 *
 * @constant
 * @type {string[]}
 */
export const logsCommandNames = [logs.color, logs.indent, logs.level];
