import React from 'react';

export const IcVisualsAddNew = () => {
    return (
        <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M140.2 91H156.4C152.9 66.5 133.5 47.1 109 43.6V59.8C124.6 63 137 75.4 140.2 91Z" fill="#00B491" />
            <path
                d="M109 138.2V154.4C133.5 150.9 152.9 131.5 156.4 107H140.2C137 122.6 124.6 135 109 138.2Z"
                fill="#00846A"
            />
            <path
                d="M93.0001 59.8V43.6C68.5001 47.1 49.1001 66.5 45.6001 91H61.8001C65.0001 75.4 77.4001 63 93.0001 59.8Z"
                fill="#C7CFD7"
            />
            <path
                d="M61.8001 107H45.6001C49.1001 131.5 68.5001 150.9 93.0001 154.4V138.2C77.4001 135 65.0001 122.6 61.8001 107Z"
                fill="#00B491"
            />
            <path d="M129 91H109V71H93V91H73V107H93V127H109V107H129V91Z" fill="#00B491" />
        </svg>
    );
};
