import React from 'react';

import MeshBotLabelInTableCell from './MeshBotLabelInTableCell';

import styles from './styles.module.scss';

const MeshBotLabelsInTableCellBox = ({ labelsData, toggleLabels, className, children }) => {
    return (
        <div className={`${styles.labelsContainer} ${className}`} onClick={toggleLabels}>
            {labelsData.map(({ backgroundColor, name, uuid }) => (
                <MeshBotLabelInTableCell key={uuid} name={name} backgroundColor={backgroundColor} />
            ))}
            {children}
        </div>
    );
};

export default MeshBotLabelsInTableCellBox;
