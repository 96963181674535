import React from 'react';

const IcActionsDoorlockLock = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
            <path
                d="M9.70846 21C8.70846 21 7.90846 21.9 8.00846 23L8.50846 26C8.60846 26.6 9.10846 27 9.70846 27C10.3085 27 10.8085 26.6 10.9085 26L11.4085 23C11.5085 21.9 10.7085 21 9.70846 21Z"
                fill="#1C1E21"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 8.73831e-07C12.9941 0.000506321 14.9674 0.172039 18 1V6.8519L12 7.9319V15.4081C12 16.901 12.2195 18.361 12.6352 19.75C11.8701 19.2746 10.9671 19 10 19C7.23858 19 5 21.2386 5 24C5 26.7614 7.23858 29 10 29C12.6146 29 14.7605 26.9931 14.9813 24.4357C15.8308 25.5836 16.8446 26.6166 18 27.4977V31C14.6757 31.7682 13.0102 31.9757 10 32C6.98983 32.0243 5.24784 31.8196 2 31V1C5.25443 0.218131 7.00593 -0.000504574 10 8.73831e-07ZM18 9.90012V23.4074C16.2214 21.3639 15.1488 18.7652 15.0144 15.9999C15.56 15.9922 16 15.5475 16 15C16 14.4477 15.5523 14 15 14V11C15.46 11 15.8474 10.6895 15.9641 10.2666L18 9.90012ZM6 15C6 15.5523 5.55229 16 5 16C4.44771 16 4 15.5523 4 15C4 14.4477 4.44771 14 5 14C5.55229 14 6 14.4477 6 15ZM5 11C5.55229 11 6 10.5523 6 10C6 9.44771 5.55229 9 5 9C4.44771 9 4 9.44771 4 10C4 10.5523 4.44771 11 5 11ZM6 5C6 5.55228 5.55229 6 5 6C4.44771 6 4 5.55228 4 5C4 4.44772 4.44771 4 5 4C5.55229 4 6 4.44772 6 5ZM10 16C10.5523 16 11 15.5523 11 15C11 14.4477 10.5523 14 10 14C9.44772 14 9 14.4477 9 15C9 15.5523 9.44772 16 10 16ZM11 10C11 10.5523 10.5523 11 10 11C9.44772 11 9 10.5523 9 10C9 9.44771 9.44772 9 10 9C10.5523 9 11 9.44771 11 10ZM10 6C10.5523 6 11 5.55228 11 5C11 4.44772 10.5523 4 10 4C9.44772 4 9 4.44772 9 5C9 5.55228 9.44772 6 10 6ZM16 5C16 5.55228 15.5523 6 15 6C14.4477 6 14 5.55228 14 5C14 4.44772 14.4477 4 15 4C15.5523 4 16 4.44772 16 5Z"
                fill="#1C1E21"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15 10.44L23 9L31 10.44V15.408C31 19.8864 28.5273 24.0192 24.5491 26.1648L23 27L21.4509 26.1648C17.4727 24.0192 15 19.8936 15 15.408V10.44ZM23.0001 12L28.0001 13V16C28.1668 17.6667 27.4001 21.6 23.0001 24C22.9998 22.0478 22.9999 20.2512 23 18.0896C23.0001 16.3757 23.0002 14.4323 23.0001 12Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcActionsDoorlockLock;
