import { getCloudJwtToken, getLoginData, loginUser, setCloudToken, setMmsTokens } from 'services/auth';
import { validateAccountToSaveData } from '../utils';

/**
 * Gets and sets MMS tokens by using the stored user credentials.
 *
 * @param {Function} dispatch - The Redux dispatch function.
 * @param {Function} getState - The Redux getState function.
 * @returns {Promise<Object>} A promise that resolves to the data object containing MMS tokens.
 */
export const getSetMmsTokens = async (dispatch, getState) => {
    const { name, hash, hashCS } = getLoginData();
    const { data } = (await loginUser(name, hash, hashCS)) || {};
    await validateAccountToSaveData(getState());
    setMmsTokens(data.Identity, data.IdentitySignature);

    return data;
};

/**
 * Gets and sets a JWT token obtained from the cloud service.
 *
 * @param {Function} dispatch - The Redux dispatch function.
 * @param {Function} getState - The Redux getState function.
 * @returns {Promise<string>} A promise that resolves to the JWT token obtained from the cloud service.
 */
export const getJwtToken = async (dispatch, getState) => {
    const jwtToken = await getCloudJwtToken();
    await validateAccountToSaveData(getState());
    setCloudToken(jwtToken);

    return jwtToken;
};
