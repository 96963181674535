import produce from 'immer';

import { at } from '../constants';
import * as types from 'constants/ActionTypes';

const INITIAL_STATE = {
    systemHistoryList: [],
    isLoading: false,
    isDateValid: false,
    systemHistoryFilter: {
        dateBefore: null,
        dateAfter: null,
        eventType: 0,
        eventDescription: '',
    },
};

export default produce((draft, action) => {
    switch (action.type) {
        case at.UPDATE_SYSTEM_HISTORY_TABLE.PENDING:
            draft.isLoading = action.data;
            break;
        case at.UPDATE_SYSTEM_HISTORY_TABLE.SUCCESS:
            draft.systemHistoryList = action.data.list;
            draft.isLoading = action.data.isLoading;
            break;
        case at.UPDATE_SYSTEM_HISTORY_TABLE.REJECT:
            draft.isLoading = action.data;
            break;
        case at.SET_HISTORY_SEARCH_BY_DATE_BEFORE:
            draft.systemHistoryFilter.dateBefore = action.data;
            break;
        case at.SET_HISTORY_SEARCH_BY_DATE_AFTER:
            draft.systemHistoryFilter.dateAfter = action.data;
            break;
        case at.SET_HISTORY_SEARCH_BY_EVENT_TYPE:
            draft.systemHistoryFilter.eventType = action.data;
            break;
        case at.SET_HISTORY_SEARCH_BY_DESCRIPTION:
            draft.systemHistoryFilter.eventDescription = action.data;
            break;
        case at.SET_IS_VALID_DATE:
            draft.isDateValid = action.data;
            break;
        case types.LOGOUT:
            return INITIAL_STATE;
    }
}, INITIAL_STATE);
