import React from 'react';
import { Tooltip } from 'lib/@mui';
import classname from 'classnames';

import './style.scss';

const ToolTip = ({ children, className, title, position, show }) => {
    return (
        <Tooltip
            role="mui-tooltip"
            classes={{ popper: classname('tooltip-popper', { [className]: className }) }}
            title={title}
            arrow
            placement={position}
            open={show}
            className={className}
        >
            {children}
        </Tooltip>
    );
};

export default ToolTip;
