import React from 'react';

const IcActionsUser = (props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M15.75 6C15.75 8.1 14.1 9.75 12 9.75C9.9 9.75 8.25 8.1 8.25 6C8.25 3.9 9.9 2.25 12 2.25C14.1 2.25 15.75 3.9 15.75 6ZM15.75 10.5L15.375 11.7C15.15 12.3 14.625 12.75 13.95 12.75H10.05C9.375 12.75 8.85 12.3 8.625 11.7L8.25 10.5C6.9 10.5 5.775 11.4 5.55 12.675L3.75 21H20.25L18.45 12.75C18.225 11.4 17.025 10.5 15.75 10.5Z"
                fill="#90A0AF"
            />
        </svg>
    );
};

export default IcActionsUser;
