import {
    apiGetCities,
    apiGetCountries,
    apiGetInstallations,
    apiGetMailingBillingAddresses,
    apiGetRegions,
    apiUpdateInstallation,
    apiUpdateMailingBillingAddresses,
} from '../api/apiAccount';
import GenericActions from './GenericActions';
import at from '../constants/ActionTypes/AccountInfo';
import { toast, TOAST_TYPE } from '../components/Toast';
import { bugsnagNotifyWrapper } from '../containers/ErrorBoundary/utils';
import {
    extractUuidFromImgUrl,
    filterAndSortCountries,
    getProfilePictureKvsKey,
    getProfilePicturePayloadData,
    getUserUuidFromToken,
    isValidUploadResponse,
    uploadAndProcessImage,
    uploadProfileImageInKvs,
} from '../containers/Ezlo/EzloAccount/MyAccount/utils';
import {
    EZLOGIC_TITLE_ACCOUNT_UPDATED,
    EZLOGIC_TITLE_INSTALLATION_ADDRESS_UPDATED,
    EZLOGIC_TITLE_YOUR_PROFILE_HAS_BEEN_DELETED_SUCCESSFULLY,
    EZLOGIC_TITLE_YOUR_PROFILE_HAS_BEEN_UPLOADED_SUCCESSFULLY,
} from '../constants/language_tokens';
import { apiGetKvsDataByKey, apiSetDataToKvs } from 'api/apiScenes';
import { LOCATION_TYPE, SUCCESS, ZERO_INT } from 'features/InstallerAccess/constants';
import { PROFILE_PICTURE_KVS_KEY } from 'containers/Ezlo/EzloAccount/constants/MyAccount';
import { buildDownloadImgLink, deleteFileFromCloud } from 'services/mediaStore';
import { ONE } from 'features/EzloPolicies/constants';
import { t } from 'helpers/language';
import { BOOLINT } from 'constants/App';

const AccountActions = {
    setInstallationsList: () => async (dispatch, getState) => {
        try {
            const {
                account: {
                    data: { PK_Account },
                    countriesList,
                },
            } = getState();

            if (!countriesList.length) {
                await dispatch(AccountActions.setCountries());
            }
            const installations = await apiGetInstallations(PK_Account);

            await dispatch({ type: at.SET_INSTALLATIONS_LIST.success, installations });
        } catch (error) {
            bugsnagNotifyWrapper(error, { type: at.SET_INSTALLATIONS_LIST.rejected });
        }
    },
    setMailingBillingAddresses: () => async (dispatch, getState) => {
        try {
            const {
                account: {
                    data: { PK_Account },
                },
            } = getState();
            const mailingBillingAddresses = await apiGetMailingBillingAddresses(PK_Account);
            await dispatch({ type: at.SET_MAILING_BILLING_ADDRESSES.success, mailingBillingAddresses });
        } catch (error) {
            bugsnagNotifyWrapper(error, { type: at.SET_MAILING_BILLING_ADDRESSES.rejected });
        }
    },
    setSecurityAccountInfo: () => async (dispatch) => {
        dispatch(GenericActions.setLineLoading(true));
        await dispatch(AccountActions.setInstallationsList());
        await dispatch(AccountActions.setMailingBillingAddresses());
        dispatch(GenericActions.setLineLoading(false));
    },
    setAccountInfo: () => async (dispatch) => {
        dispatch(GenericActions.setLineLoading(true));
        await dispatch(AccountActions.setInstallationsList());
        dispatch(GenericActions.setLineLoading(false));
    },
    updateSecurityAccountInfo: (addressesData, t) => async (dispatch) => {
        dispatch(GenericActions.setLineLoading(true));
        await dispatch(AccountActions.updateMailingBillingAddresses(addressesData, t));
        await dispatch(AccountActions.setMailingBillingAddresses());
        dispatch(GenericActions.setLineLoading(false));
    },
    updateAccountInfo: (addressesData, t) => async (dispatch) => {
        dispatch(GenericActions.setLineLoading(true));
        await dispatch(AccountActions.updateInstallationAddresses(addressesData, t));
        await dispatch(AccountActions.setInstallationsList());
        dispatch(GenericActions.setLineLoading(false));
    },
    updateInstallationAddresses: (installationsData, t) => async (dispatch, getState) => {
        try {
            const {
                account: {
                    data: { PK_Account },
                },
            } = getState();
            const dispatchListOfUpdateInstallationsAddress = installationsData.map((address) => {
                return dispatch(AccountActions.updateInstallationAddress(PK_Account, address, t));
            });
            await Promise.all(dispatchListOfUpdateInstallationsAddress);
        } catch (error) {
            bugsnagNotifyWrapper(error, { type: at.UPDATE_INSTALLATION_ADDRESSES.rejected });
        }
    },
    updateInstallationAddress: (PK_Account, address, t) => async () => {
        try {
            await apiUpdateInstallation(PK_Account, address);
            await toast(t(EZLOGIC_TITLE_INSTALLATION_ADDRESS_UPDATED), { type: TOAST_TYPE.SUCCESS });
        } catch (error) {
            bugsnagNotifyWrapper(error, { type: at.UPDATE_INSTALLATION_ADDRESS.rejected });
        }
    },
    updateMailingBillingAddresses: (addressesData, t) => async (dispatch, getState) => {
        try {
            const {
                account: {
                    data: { PK_Account },
                },
            } = getState();
            await apiUpdateMailingBillingAddresses(PK_Account, addressesData);
            await toast(t(EZLOGIC_TITLE_ACCOUNT_UPDATED), { type: TOAST_TYPE.SUCCESS });
        } catch (error) {
            bugsnagNotifyWrapper(error, { type: at.UPDATE_MAILING_BILLING_ADDRESSES.rejected });
        }
    },
    setCountries: () => async (dispatch) => {
        try {
            const countriesList = await apiGetCountries();
            const countries = filterAndSortCountries(countriesList);

            dispatch({ type: at.SET_COUNTRIES_LIST.success, countriesList: countries });
        } catch (error) {
            bugsnagNotifyWrapper(error, { type: at.SET_COUNTRIES_LIST.rejected });
        }
    },
    getRegions: (PK_Country) => async () => {
        try {
            const response = await apiGetRegions(PK_Country);

            return await response.data.Regions;
        } catch (error) {
            bugsnagNotifyWrapper(error, { type: at.SET_REGIONS_LIST.rejected });
        }
    },
    getCities: (PK_Country, PK_Region) => async () => {
        try {
            const response = await apiGetCities(PK_Country, PK_Region);

            return await response.data.Cities;
        } catch (error) {
            bugsnagNotifyWrapper(error, { type: at.SET_CITIES_LIST.rejected });
        }
    },
    getProfilePicture: () => async (dispatch) => {
        try {
            const userUuid = getUserUuidFromToken();
            const kvs_key = getProfilePictureKvsKey(PROFILE_PICTURE_KVS_KEY, userUuid);
            const response = await apiGetKvsDataByKey(kvs_key, LOCATION_TYPE);

            if (response.data?.status === SUCCESS) {
                const parseProfilePictureData = JSON.parse(response.data?.data?.list[ZERO_INT]?.value || '{}');
                const profileImageUrl = parseProfilePictureData?.key
                    ? buildDownloadImgLink(parseProfilePictureData, BOOLINT.TRUE)
                    : '';
                dispatch({ type: at.PROFILE_PICTURE, profileImageUrl });

                return parseProfilePictureData;
            }
        } catch (error) {
            bugsnagNotifyWrapper(error, { type: at.PROFILE_PICTURE });
        }
    },

    removeProfilePicture: (toggleOpenDialog) => async (dispatch, getState) => {
        try {
            const {
                account: { profilePicture },
            } = getState();
            const userUuid = getUserUuidFromToken();
            const profileImageKvsParamsData = uploadProfileImageInKvs(
                LOCATION_TYPE,
                getProfilePictureKvsKey(PROFILE_PICTURE_KVS_KEY, userUuid),
                '',
            );

            const deleteProfilePictureResponse = await apiSetDataToKvs(profileImageKvsParamsData);
            if (deleteProfilePictureResponse?.data?.status === ONE) {
                toast(`${t(EZLOGIC_TITLE_YOUR_PROFILE_HAS_BEEN_DELETED_SUCCESSFULLY)}`, {
                    type: TOAST_TYPE.SUCCESS,
                });
                toggleOpenDialog?.();
                dispatch({ type: at.PROFILE_PICTURE, profileImageUrl: '' });
                dispatch({
                    type: at.UPLOADING_PROFILE_PICTURE.success,
                });
                const fileUuid = extractUuidFromImgUrl(profilePicture);
                dispatch(AccountActions.deleteProfilePictureFromCloudStorage(fileUuid));
            } else {
                toggleOpenDialog?.();
                toast(`${deleteProfilePictureResponse?.data?.data?.error_message}`, {
                    type: TOAST_TYPE.ERROR,
                });
            }
        } catch (error) {
            toggleOpenDialog?.();
            toast(`${error?.error_message}`, {
                type: TOAST_TYPE.ERROR,
            });
        }
    },

    deleteProfilePictureFromCloudStorage: (fileUuid) => async () => {
        if (fileUuid) {
            const fileUuidArray = [fileUuid];
            await deleteFileFromCloud(fileUuidArray);
        }
    },

    handleImageUploadSuccess:
        (uploadImageResponse, setIsImageUploadError, toggleOpenDialog, imgLink) => async (dispatch, getState) => {
            try {
                const {
                    account: { profilePicture },
                } = getState();
                const userUuid = await getUserUuidFromToken();
                const profileImageKvsParamsData = uploadProfileImageInKvs(
                    LOCATION_TYPE,
                    getProfilePictureKvsKey(PROFILE_PICTURE_KVS_KEY, userUuid),
                    getProfilePicturePayloadData(uploadImageResponse),
                );
                const uploadImageInKvsResponse = await apiSetDataToKvs(profileImageKvsParamsData);

                if (uploadImageInKvsResponse?.data?.status === ONE) {
                    dispatch(AccountActions.uploadingProfilePicture(at.UPLOADING_PROFILE_PICTURE.success));
                    toast(`${t(EZLOGIC_TITLE_YOUR_PROFILE_HAS_BEEN_UPLOADED_SUCCESSFULLY)}`, {
                        type: TOAST_TYPE.SUCCESS,
                    });
                    toggleOpenDialog();
                    dispatch({ type: at.PROFILE_PICTURE, profileImageUrl: imgLink });
                    const fileUuid = extractUuidFromImgUrl(profilePicture);
                    dispatch(AccountActions.deleteProfilePictureFromCloudStorage(fileUuid));

                    return true;
                } else {
                    setIsImageUploadError(true);
                    dispatch(AccountActions.uploadingProfilePicture(at.UPLOADING_PROFILE_PICTURE.rejected));
                    toast(uploadImageInKvsResponse?.data?.data?.error_message, { type: TOAST_TYPE.ERROR });

                    return false;
                }
            } catch (error) {
                setIsImageUploadError(true);
                dispatch(AccountActions.uploadingProfilePicture(at.UPLOADING_PROFILE_PICTURE.rejected));
                toast(error?.error_message, { type: TOAST_TYPE.ERROR });

                return false;
            }
        },

    handleImageUploadError: (setIsImageUploadError, error) => (dispatch) => {
        setIsImageUploadError(true);
        dispatch(AccountActions.uploadingProfilePicture(at.UPLOADING_PROFILE_PICTURE.rejected));
        if (error) {
            toast(error?.error_message, { type: TOAST_TYPE.ERROR });
        }
    },

    uploadProfilePicture:
        (imagesData, setIsImageUploadError, toggleOpenDialog, isProfilePicture) => async (dispatch) => {
            try {
                const uploadImageResponse = await uploadAndProcessImage(imagesData, isProfilePicture);
                if (isValidUploadResponse(uploadImageResponse)) {
                    const imgLink = buildDownloadImgLink(uploadImageResponse, BOOLINT.TRUE);
                    const uploadedSuccessfully = await dispatch(
                        AccountActions.handleImageUploadSuccess(
                            uploadImageResponse,
                            setIsImageUploadError,
                            toggleOpenDialog,
                            imgLink,
                        ),
                    );

                    return uploadedSuccessfully ? imgLink : null;
                } else {
                    await dispatch(AccountActions.handleImageUploadError(setIsImageUploadError));

                    return null;
                }
            } catch (error) {
                dispatch(AccountActions.handleImageUploadError(setIsImageUploadError, error));

                return null;
            }
        },
    uploadingProfilePicture: (requestType) => async (dispatch) => {
        dispatch({
            type: requestType,
        });
    },
    setErrorProfilePicture: (profilePictureError) => async (dispatch) => {
        dispatch({ type: at.PROFILE_PICTURE_ERROR, profilePictureUrlError: profilePictureError });
    },
    removeProfileImage: () => async (dispatch) => {
        dispatch({ type: at.PROFILE_PICTURE, profileImageUrl: '' });
    },
};

export default AccountActions;
