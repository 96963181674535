import React from 'react';

export const DeleteModalIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M114 32H86V40H114V32Z" fill="#FE5C15" />
            <path
                d="M128 80V136H72V80H128ZM144 64H56V140C56 146.627 61.3726 152 68 152H132C138.627 152 144 146.627 144 140V64Z"
                fill="#FE5C15"
            />
            <path d="M152 48H48V64H152V48Z" fill="#FE5C15" />
            <path d="M144 64H56V80H144V64Z" fill="#E82E10" />
            <path d="M94 124V92H86V124H94Z" fill="#FE5C15" />
            <path d="M114 124V92H106V124H114Z" fill="#FE5C15" />
        </svg>
    );
};

export default DeleteModalIcon;
