import React from 'react';

const IcPositiveVersionOfTheSoftwareFailure = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
            <path
                d="M16.0001 6C16.6001 6 17.1001 6.4 17.3001 7L17.8001 8.7C18.2001 10.3 19.9001 11.2 21.4001 10.8L23.1001 10.3C23.7001 10.1 24.3001 10.4 24.6001 10.9C24.9001 11.4 24.8001 12.1 24.4001 12.5L23.1001 13.8C21.9001 15 21.9001 16.8 23.1001 18L24.4001 19.3C24.8001 19.7 24.9001 20.4 24.6001 20.9C24.3001 21.4 23.7001 21.7 23.1001 21.5L21.4001 21C19.8001 20.6 18.2001 21.5 17.8001 23.1L17.3001 25C17.1001 25.6 16.6001 26 16.0001 26C15.4001 26 14.9001 25.6 14.7001 25L14.2001 23.3C13.8001 21.7 12.1001 20.8 10.6001 21.2L8.9001 21.7C8.3001 21.9 7.7001 21.6 7.4001 21.1C7.1001 20.6 7.2001 19.9 7.6001 19.5L8.9001 18.2C10.1001 17 10.1001 15.2 8.9001 14L7.6001 12.6C7.1001 12.2 7.0001 11.5 7.4001 11C7.7001 10.5 8.3001 10.2 8.9001 10.4L10.6001 10.9C12.2001 11.3 13.8001 10.4 14.2001 8.8L14.7001 7C14.9001 6.4 15.4001 6 16.0001 6ZM19.3001 3H12.7001L11.4001 7.9L6.4001 6.6L3.1001 12.4L6.7001 16L3.1001 19.6L6.4001 25.3L11.3001 24L12.7001 29H19.3001L20.7001 24.1L25.6001 25.4L28.9001 19.7L25.3001 16L28.9001 12.4L25.6001 6.7L20.7001 8L19.3001 3Z"
                fill="#1C1E21"
            />
            <circle cx="22" cy="14" r="9" fill="white" />
            <path
                d="M27.3039 10.8181L25.1825 8.69678L22.0006 11.8788L18.8186 8.69678L16.6973 10.8181L19.8792 14.0001L16.6973 17.1821L18.8186 19.3034L22.0006 16.1214L25.1825 19.3034L27.3039 17.1821L24.1219 14.0001L27.3039 10.8181Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcPositiveVersionOfTheSoftwareFailure;
