import wsm from '../helpers/wsm';
import { groupResultByAbstractUuid } from '../helpers/subscriptions';
import ItemActions from './ItemActions';
import * as types from '../constants/Subscription';
import { bugsnagNotify } from '../containers/ErrorBoundary/utils';
import { apiGetAbstractStateGet } from '../containers/Ezlo/EzloMeshbot/components/PaasNodeForTrigger/utils';
import { EMPTY_STRING, FAIL, SUCCESS } from '../constants/MeshbotConstant';
import { generateSubscriptionsPayloadForKvsSet, setValueToHubSubscription } from '../containers/Ezlo/EzloMeshbot/utils';
import { apiGetSubscriptionData } from '../api/apiScenes';
import { apiSubscribeToCallBackForTrigger } from '../containers/Ezlo/EzloRule/EditForm/RuleSettings/components/PAAS/PaasAPI/paas-api-requests';
import SubscriptionCloudActions from './SubscriptionCloudActions';
import { toast, TOAST_TYPE } from '../components/Toast';
import { getPayloadForCreateSubscriptionsOnCloud } from '../containers/Ezlo/EzloMeshbot/MeshbotCloud/MeshBotCloudCreate/createMeshSceneRequestPayload';
import { setMeshBotV2LocalFlagsSubscriptionStatus as setSubscriptionFlagStatus } from '../reducers/meshBotV2';
import { SUBSCRIPTIONS_STATUS } from '../constants/Subscription';

const actions = {
    getAndUpdateItems: (serial) => (dispatch) =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'hub.items.list',
                {},
                (success) => {
                    dispatch(ItemActions.updateItems(serial, success.items));
                    resolve(success.items);
                },
                (error) => {
                    bugsnagNotify(error, { serial });
                    reject(error);
                },
            );
        }),

    createCloudSubscriptions: (serial, params) => (dispatch) =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'hub.cloud.subscription.create',
                params,
                () => {
                    resolve();
                },
                (error) => {
                    dispatch(actions.handleSubscriptionErrors(error, { serial }));
                    reject(error);
                },
            );
        }),

    updateSubscription: (serial, params) => (dispatch) =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'hub.cloud.subscription.update',
                params,
                () => {
                    resolve();
                },
                (error) => {
                    dispatch(actions.handleSubscriptionErrors(error, { serial }));
                    reject(error);
                },
            );
        }),

    formPayloadAndCreateOrUpdateSubscriptions: (arrayOfVariable) => (dispatch) => {
        const mappedResult = groupResultByAbstractUuid(arrayOfVariable);

        dispatch(actions.formPayload(mappedResult, arrayOfVariable.length));
        dispatch(actions.createOrUpdateSubscriptions(mappedResult));
    },
    collectDataAboutSubscriptionsInKvs: (cloudSubscriptions) => {
        const getIsSubscriptionInKvs = async (subscriptionId) => {
            const response = await apiGetSubscriptionData(subscriptionId);
            const subscriptionInKvsResponse = response.data;

            if (subscriptionInKvsResponse.status === SUCCESS) {
                return !!subscriptionInKvsResponse.data.list.length;
            }

            if (subscriptionInKvsResponse.status === FAIL) {
                throw subscriptionInKvsResponse.data;
            }
        };

        const subscriptionsPromises = cloudSubscriptions.map(async (subscription) => {
            subscription.isSubscriptionInKvs = await getIsSubscriptionInKvs(subscription.subscriptionId);
        });

        return Promise.all(subscriptionsPromises);
    },
    createSubscriptionsOnCloud: (cloudSubscriptions) => async (dispatch) => {
        const createSubscriptionsOnCloudRequests = cloudSubscriptions.map(async (subscription) => {
            if (subscription.isSubscriptionInKvs === false) {
                const payload = getPayloadForCreateSubscriptionsOnCloud(subscription);
                const subscriptionResponse = await apiSubscribeToCallBackForTrigger(payload);

                if (subscriptionResponse.status === SUCCESS) {
                    dispatch(actions.setSubscriptionInKvs(payload));
                }

                if (subscriptionResponse.status === FAIL) {
                    throw subscriptionResponse.data;
                }
            }
        });

        await Promise.all(createSubscriptionsOnCloudRequests);
    },
    setSubscriptionInKvs: (subscription) => (dispatch) => {
        const { kvsStructureForSubscription, subscriptionUniqueId } = generateSubscriptionsPayloadForKvsSet({
            subscription,
        });
        const stringifySubscriptionData = JSON.stringify(kvsStructureForSubscription);
        const encodedSubscriptionData = btoa(stringifySubscriptionData);
        dispatch(SubscriptionCloudActions.handleUploadSubscriptionData(encodedSubscriptionData, subscriptionUniqueId));
    },
    setCapabilitiesValueFromCloud: async (hubSubscriptions, abstractIds) => {
        const abstractsState = await apiGetAbstractStateGet(abstractIds);
        await hubSubscriptions.map((hubSubscription) => {
            if (hubSubscription.value === EMPTY_STRING) {
                setValueToHubSubscription(abstractsState, hubSubscription);
            }
        });
    },
    handleSubscriptionErrors: (error, errorData) => (dispatch) => {
        bugsnagNotify(error, errorData);
        dispatch(actions.setSubscriptionFlagStatus(SUBSCRIPTIONS_STATUS.DEFAULT));
        toast(JSON.stringify(error), { type: TOAST_TYPE.ERROR });
    },

    createOrUpdateSubscriptions: (mappedResult) => (dispatch, getState) => {
        const state = getState();
        const {
            ezlo: { serial },
        } = state;

        const devicesList = state.ezlo.data[serial].devices;

        mappedResult.forEach((item) => {
            const isDeviceIsExist = devicesList.some(({ name }) => name === item.abstractUUID);
            if (!isDeviceIsExist) {
                dispatch(actions.createCloudSubscriptions(serial, item));
            } else {
                dispatch(actions.updateSubscription(serial, item));
            }
        });
    },

    formPayload: (data, countOfVariables) => ({
        type: types.FORM_PAYLOAD_FOR_SUBSCRIPTIONS,
        data,
        countOfVariables,
    }),
    updateValues: (newItem, updatedPayload) => ({
        type: types.UPDATE_VALUES,
        newItem,
        updatedPayload,
    }),

    getDevicesList: (devices) => ({
        type: types.GET_DEVICES_LIST,
        devices,
    }),
    clearSubscriptionState: () => ({
        type: types.CLEAR_SUBSCRIPTIONS_STATE,
    }),
    setSubscriptionFlagStatus: (value) => setSubscriptionFlagStatus(value),
};

export default actions;
