import { languageService } from '../../../services/language';
import { localStorageService } from '../../../services/localstorage';
import { LANGUAGE } from '../../../services/language/src/constant/lang';

/**
 * Returns extracted data collect from languages payload
 * @param {string} getLangKey - Getting User language key from languageService
 * @param {object} getLangPayload - Collect payload object from languageService
 *
 * @example:
 *
 * Output:
 *
 * getLangkey
 * getLangPayload
 * Configuration Language
 **/
export const initializeLanguage = async () => {
    const getLangKey = languageService.getLanguageKey();
    const getLangPayload = await languageService.getTranslation(getLangKey);
    languageService.configureLangauge(getLangKey, getLangPayload);
};

export const getLangTranslate = (locals, languageKey, key, translate) => {
    const checkCustomizationKeyValue = locals?.[languageKey]?.[key];
    const checkDefaultKeyValue = translate(key) ?? key;

    if (checkCustomizationKeyValue) {
        return checkCustomizationKeyValue;
    }

    return checkDefaultKeyValue;
};

export const updateLang = (currentLang) => {
    localStorageService.setLocalStorage(LANGUAGE, currentLang);
    initializeLanguage();
};

export default {
    initializeLanguage,
};
