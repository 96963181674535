import Axios from 'axios';
import { API_CLOUD_EZLO_REQUEST_URL } from '../../../../constants/URLs';

/**
 * Returns prepared "user_password_reset_set" request payload.
 * <br/>
 * More info:
 * {@link https://confluence.mios.com/display/EPD/User+-+Password+Reset+Set Confluence page}
 *
 * @param {Object} params - params object. Should contain "action", "code", "pk_user" and "password" fields.
 * @returns {Object} request payload
 * */
export const buildUserPasswordResetSetPayload = (params) => {
    return {
        call: 'user_password_reset_set',
        params,
    };
};

/**
 * Sends "user_password_reset_set" Cloud API call.
 * <br/>
 * More info: {@link https://confluence.mios.com/display/EPD/User+-+Password+Reset+Set user_password_reset_set}
 *
 * @param {Object} params - params object
 * @returns {Promise<Object>} API response
 * */
export const apiUserPasswordResetSet = async (params) => {
    const payload = buildUserPasswordResetSetPayload(params);

    return await Axios.post(API_CLOUD_EZLO_REQUEST_URL(), payload);
};

/**
 * Returns prepared "user_validate_email" request payload.
 * <br/>
 * More info: {@link https://confluence.mios.com/display/EPD/User+-+Validate+Email user_validate_email}
 *
 * @param {Object} params - params object. Should contain "code", "pk_user" fields.
 * @returns {Object} request payload
 * */
export const buildUserValidateEmailPayload = (params) => {
    return {
        call: 'user_validate_email',
        params,
    };
};

/**
 * Sends "user_validate_email" Cloud API call.
 * <br/>
 * More info: {@link https://confluence.mios.com/display/EPD/User+-+Validate+EmailConfluence user_validate_email}
 *
 * @param {Object} params - params object
 * @returns {Promise<Object>} API response
 * */
export const apiUserValidateEmail = async (params) => {
    const payload = buildUserValidateEmailPayload(params);

    return await Axios.post(API_CLOUD_EZLO_REQUEST_URL(), payload);
};
