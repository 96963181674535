import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { toast as toastifyToast } from 'react-toastify';

import toastsActions from '../../actions/toastsActions';

const EzloToast = () => {
    const { toasts, isToastDismiss } = useSelector((state) => state.toasts);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(toastsActions.displayToasts());
    }, [toasts]);

    useEffect(() => {
        if (isToastDismiss) {
            toastifyToast.dismiss();
            dispatch(toastsActions.clearToasts());
        }
    }, [isToastDismiss]);

    return <ToastContainer newestOnTop />;
};

export default EzloToast;
