import React from 'react';

export const ErrorIcon = (props) => (
    <svg {...props} viewBox="0 0 114 114" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M57.0017 105.002C83.5114 105.002 105.002 83.5113 105.002 57.0017C105.002 30.492 83.5114 9.00168 57.0017 9.00168C30.492 9.00168 9.00171 30.492 9.00171 57.0017C9.00171 83.5113 30.492 105.002 57.0017 105.002Z"
            stroke="#FE5C15"
            strokeWidth="16"
            strokeMiterlimit="10"
        />
        <path d="M107.913 6.09L6.09009 107.913" stroke="#FE5C15" strokeWidth="16" strokeMiterlimit="10" />
        <path
            d="M90.3784 34.9389C93.6529 39.8707 95.7718 45.4512 96.6013 51.3434L109.771 38.1737C107.902 32.9226 105.24 27.9724 101.854 23.4637L90.3784 34.9389Z"
            fill="#E82E10"
        />
        <path
            d="M51.3436 96.6013C45.4515 95.7717 39.8711 93.6526 34.9393 90.3781L23.4641 101.853C27.9728 105.239 32.9229 107.901 38.1739 109.771L51.3436 96.6013Z"
            fill="#E82E10"
        />
    </svg>
);

export default ErrorIcon;
