import { isString } from 'lodash';
import { HUB_BACKUP_RESTORE, HUB_BACKUP_RESTORE_PROCESS } from '../../common/methods';
import { send, unsubscribe, subscribe, validateSerial } from '../../common/utils';

/**
 * Validates the parameters required for the hubBackupRestoreApi function.
 *
 * @param {Object} params - The parameters object.
 * @param {string} params.key - The key for the backup operation.
 * @param {string} params.uuid - The UUID for the backup operation.
 * @throws {Error} - Throws an error if required parameters are missing or not of the correct type.
 * @returns {void}
 */
const validateParams = (params) => {
    if (!params.key) {
        throw new Error('params.key is required');
    }

    if (!params.uuid) {
        throw new Error('params.uuid is required');
    }

    if (!isString(params.key) || !isString(params.uuid)) {
        throw new Error('params.uuid, params.uuid must be string');
    }
};

/**
 * Initiates a hub backup restore operation using the WebSocket Manager.
 *
 * @async
 * @param {string|number} serial - The serial number of the hub.
 * @param {Object} params - The parameters for the backup restore operation.
 * @param {string} params.key - The key for the backup operation.
 * @param {string} params.uuid - The UUID for the backup operation.
 * @param {Function} onSuccess - Callback function to handle the success of the operation.
 * @param {Function} onError - Callback function to handle errors during the operation.
 * @returns {Promise} - A promise representing the result of the operation.
 * @see {@link https://log.ezlo.com/new/hub/backup/#hubbackuprestore}
 */
export const hubBackupRestoreApi = async (serial, params, onSuccess, onError) => {
    validateParams(params);
    validateSerial(serial);

    return await send(serial, HUB_BACKUP_RESTORE, params, onSuccess, onError);
};

/**
 * Subscribes to hub backup and restore progress updates using the WebSocket Manager.
 *
 * @param {string} serial - The serial number of the hub.
 * @param {Function} cb - Callback function to handle progress updates.
 * @returns {Promise}
 * @see {@link https://log.ezlo.com/new/hub/broadcasts/#hubbackuprestoreprogress}
 */
export const subscribeHubBackupRestoreProgressApi = (serial, cb) => {
    return subscribe(serial, HUB_BACKUP_RESTORE_PROCESS, cb);
};
/**
 * Unsubscribes from hub backup and restore progress updates using the WebSocket Manager.
 *
 * @param {string} serial - The serial number of the hub.
 * @returns {Promise}
 * @see {@link https://log.ezlo.com/new/hub/broadcasts/#hubbackuprestoreprogress}
 */
export const unsubscribeHubBackupRestoreProgressApi = (serial) => {
    return unsubscribe(serial, HUB_BACKUP_RESTORE_PROCESS);
};
