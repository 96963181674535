import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    integratedDevicesInfo: [],
};

const staticDataSlice = createSlice({
    name: 'staticData',
    initialState,
    reducers: {
        setStaticDataIntegratedDevicesInfo: (state, action) => {
            state.integratedDevicesInfo = action.payload;
        },
        deleteStaticDataIntegratedDevicesInfo: (state) => {
            state.integratedDevicesInfo = [];
        },
    },
});

export const { setStaticDataIntegratedDevicesInfo, deleteStaticDataIntegratedDevicesInfo } = staticDataSlice.actions;

export default staticDataSlice.reducer;
