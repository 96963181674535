import React from 'react';

export const IcActionsSceneController = (props) => (
    <svg {...props} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 5C4.34315 5 3 6.34315 3 8V25C3 26.6569 4.34315 28 6 28H7C8.65685 28 10 26.6569 10 25V8C10 6.34315 8.65685 5 7 5H6ZM6.5 20C5.67157 20 5 20.6716 5 21.5V24.5C5 25.3284 5.67157 26 6.5 26C7.32843 26 8 25.3284 8 24.5V21.5C8 20.6716 7.32843 20 6.5 20Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26 5C24.3431 5 23 6.34315 23 8V25C23 26.6569 24.3431 28 26 28H27C28.6569 28 30 26.6569 30 25V8C30 6.34315 28.6569 5 27 5H26ZM26.5 20C25.6716 20 25 20.6716 25 21.5V24.5C25 25.3284 25.6716 26 26.5 26C27.3284 26 28 25.3284 28 24.5V21.5C28 20.6716 27.3284 20 26.5 20Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 5C14.3431 5 13 6.34315 13 8V25C13 26.6569 14.3431 28 16 28H17C18.6569 28 20 26.6569 20 25V8C20 6.34315 18.6569 5 17 5H16ZM16.5 7C15.6716 7 15 7.67157 15 8.5V11.5C15 12.3284 15.6716 13 16.5 13C17.3284 13 18 12.3284 18 11.5V8.5C18 7.67157 17.3284 7 16.5 7Z"
        />
    </svg>
);

export default IcActionsSceneController;
