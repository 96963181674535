import {
    HUB_USER_NOTIFICATION,
    IDS,
    WARNING_COLOR,
    GREEN_COLOR,
    RED_COLOR,
    CATEGORY_ID,
    DESCRIPTION_CONFIG,
    ICON,
    COLOR,
} from './index';
import * as icons from 'assets/icons';
import * as lt from 'constants/language_tokens';

/**
 * Configuration for the event description.
 *
 * @typedef {Object} EventDescriptionConfig
 * @property {string} translationKey - The translation key for the event description.
 * @property {Object} [placeholdersPaths] - Paths to placeholders in the event description.
 */

/**
 * Parameters for a history event representing.
 *
 * @typedef {Object} AlertHistoryEventParams
 * @property {string} categoryId - The category ID for the event.
 * @property {EventDescriptionConfig} descriptionConfig - Configuration for the event description.
 * @property {string} descriptionConfig.translationKey - The translation key for the event description.
 * @property {Object} [descriptionConfig.placeholdersPaths] - Paths to placeholders in the event description.
 * @property {React.FC | Element} icon - The icon associated with the event.
 * @property {string} [color] - The color of event.
 */

/**
 * @typedef {object} ComHistoryEvent
 * @property {string} id - The identifier of the event.
 * @property {string} comm_format - The communication format.
 * @property {string} comm_type - The communication type.
 * @property {Object} data - Contains the data related to the event.
 * @property {string} location_uuid - The UUID of the location.
 * @property {string} source - The source identifier.
 * @property {string} source_type - The type of the source.
 * @property {string} target - The target identifier
 * @property {string} target_type - The type of the target.
 * @property {string} timestamp_ms - The timestamp in milliseconds.
 * @see {[Doc](https://confluence.mios.com/pages/viewpage.action?pageId=57019628)}
 */

/**
 * Parameters for a history event representing a battery level too low event.
 *
 * @type {AlertHistoryEventParams}
 * @see {[Doc](https://log.ezlo.com/new/devices/items)}
 */
export const tooLowBatteryEventParams = {
    [CATEGORY_ID]: IDS.MAINTENANCE_LOW_BATTERY,
    [DESCRIPTION_CONFIG]: {
        translationKey: lt.HISTORY_MNT_BTRYOUT,
        placeholdersPaths: {
            Device_name: 'data.result.deviceName',
        },
    },
    [ICON]: icons.IcActionsBatteryHalf,
};
/**
 * Parameters for a history event representing a low battery level event.
 *
 * @type {AlertHistoryEventParams}
 * @see {[Doc](https://log.ezlo.com/new/devices/items)}
 */
export const lowBatteryEventParams = {
    [CATEGORY_ID]: IDS.MAINTENANCE_LOW_BATTERY,
    [DESCRIPTION_CONFIG]: {
        translationKey: lt.HISTORY_MNT_BTRYLOW,
        placeholdersPaths: {
            Device_name: 'data.result.deviceName',
        },
    },
    [ICON]: icons.IcActionsBatteryHalf,
};

export const eventsParams = {
    'hub.item.updated': {
        // MAINTENANCE_LOW_BATTERY category
        maintenance_state: {
            replacement_required: {
                [CATEGORY_ID]: IDS.MAINTENANCE_LOW_BATTERY,
                [ICON]: icons.IcCategoryDevice32,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_MNT_REPLACEREQ,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
            },
            device_end_of_life: {
                [CATEGORY_ID]: IDS.MAINTENANCE_LOW_BATTERY,
                [ICON]: icons.IcCategoryDevice32,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_MNT_DEVICEEOL,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
            },
            replace_main_filter: {
                [CATEGORY_ID]: IDS.MAINTENANCE_LOW_BATTERY,
                [ICON]: icons.IcCategoryDevice32,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_MNT_REPMAINFILT,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
            },
        },
        hw_state: {
            hardware_failure: {
                [CATEGORY_ID]: IDS.MAINTENANCE_LOW_BATTERY,
                [ICON]: icons.IcActionsHardwareFailure,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_MNT_HWFAIL,
                    placeholdersPaths: {
                        'device name': 'data.result.deviceName',
                    },
                },
            },
            hardware_ok: {
                [CATEGORY_ID]: IDS.MAINTENANCE_LOW_BATTERY,
                [ICON]: icons.IcPositiveVersionOfTheSoftwareFailure,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_MNT_HWOK,
                    placeholdersPaths: {
                        'device name': 'data.result.deviceName',
                    },
                },
            },
        },
        sw_state: {
            software_failure: {
                [CATEGORY_ID]: IDS.MAINTENANCE_LOW_BATTERY,
                [ICON]: icons.IcActionsSoftwareFailure,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_MNT_SWFAIL,
                    placeholdersPaths: {
                        'device name': 'data.result.deviceName',
                    },
                },
            },
            software_ok: {
                [CATEGORY_ID]: IDS.MAINTENANCE_LOW_BATTERY,
                [ICON]: icons.IcPositiveVersionOfTheSoftwareFailure,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_MNT_SWOK,
                    placeholdersPaths: {
                        'device name': 'data.result.deviceName',
                    },
                },
            },
        },

        // SECURITY_DEVICES category
        smoke_alarm: {
            no_smoke: {
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_SEC_NOSMOKE,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
                [ICON]: icons.IcDeviceSensorNoSmoke,
                [CATEGORY_ID]: IDS.SECURITY_DEVICES,
            },
            smoke_detected: {
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_SEC_SMOKE,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
                [ICON]: icons.IcDeviceSensorSmoke,
                [CATEGORY_ID]: IDS.SECURITY_DEVICES,
                [COLOR]: WARNING_COLOR,
            },
        },
        water_leak_alarm: {
            water_leak_detected: {
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_SEC_LEAK,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
                [ICON]: icons.IcDeviceSensorLeak,
                [CATEGORY_ID]: IDS.SECURITY_DEVICES,
                [COLOR]: WARNING_COLOR,
            },
            no_water_leak: {
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_SEC_NOLEAK,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
                [ICON]: icons.IcDeviceSensorNoLeak,
                [CATEGORY_ID]: IDS.SECURITY_DEVICES,
            },
        },
        co_alarm: {
            co_detected: {
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_SEC_CO,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
                [ICON]: icons.IcDeviceSensorCo,
                [CATEGORY_ID]: IDS.SECURITY_DEVICES,
                [COLOR]: WARNING_COLOR,
            },
            no_co: {
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_SEC_NOCO,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
                [ICON]: icons.IcDeviceSensorCo,
                [CATEGORY_ID]: IDS.SECURITY_DEVICES,
            },
        },
        heat_alarm: {
            heat_ok: {
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_SEC_HEATOK,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
                [ICON]: icons.IcFlameOverSensorIcon,
                [CATEGORY_ID]: IDS.SECURITY_DEVICES,
            },
            overheat_detected: {
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_SEC_HEATOVER,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
                [ICON]: icons.IcFlameOverSensorIcon,
                [CATEGORY_ID]: IDS.SECURITY_DEVICES,
                [COLOR]: WARNING_COLOR,
            },
            under_heat_detected: {
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_SEC_HEATUNDER,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
                [ICON]: icons.IcDeviceSensorFreeze,
                [CATEGORY_ID]: IDS.SECURITY_DEVICES,
                [COLOR]: WARNING_COLOR,
            },
        },
        tampering_cover_alarm: {
            tampering_cover: {
                [ICON]: icons.IcCategoryDevice32,
                [CATEGORY_ID]: IDS.SECURITY_DEVICES,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_SEC_TAMPERCOVER,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
                [COLOR]: WARNING_COLOR,
            },
            no_tampering_cover: {
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_SEC_NOTAMPERCOVER,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
                [ICON]: icons.IcCategoryDevice32,
                [CATEGORY_ID]: IDS.SECURITY_DEVICES,
            },
        },
        dw_state: {
            dw_is_opened: {
                [ICON]: icons.IcDeviceSensorDoorSensor,
                [CATEGORY_ID]: IDS.SECURITY_DEVICES,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_SEC_DWOPENED,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
                [COLOR]: WARNING_COLOR,
            },
            dw_is_closed: {
                [ICON]: icons.IcDeviceClosedWindow,
                [CATEGORY_ID]: IDS.SECURITY_DEVICES,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_SEC_DWCLOSED,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
            },
        },
        dw_handle_state: {
            dw_handle_is_opened: {
                [ICON]: icons.IcCategoryDevice32,
                [CATEGORY_ID]: IDS.SECURITY_DEVICES,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_SEC_DWHANDLEOPEN,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
                [COLOR]: WARNING_COLOR,
            },
            dw_handle_is_closed: {
                [ICON]: icons.IcCategoryDevice32,
                [CATEGORY_ID]: IDS.SECURITY_DEVICES,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_SEC_DWHANDLECLOSED,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
            },
        },
        tilt: {
            no_tilt: {
                [ICON]: icons.IcActionsZeroTiltAngle,
                [CATEGORY_ID]: IDS.SECURITY_DEVICES,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_SEC_NOTILT,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
            },
            tilt: {
                [ICON]: icons.IcActionsLevelSensor,
                [CATEGORY_ID]: IDS.SECURITY_DEVICES,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_SEC_TILT,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
                [COLOR]: WARNING_COLOR,
            },
        },
        motion: {
            true: {
                [ICON]: icons.IcDeviceSensorMotion,
                [CATEGORY_ID]: IDS.SECURITY_DEVICES,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_SEC_MOTION,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
                [COLOR]: WARNING_COLOR,
            },
            false: {
                [ICON]: icons.IcDeviceSensorNoMotion,
                [CATEGORY_ID]: IDS.SECURITY_DEVICES,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_SEC_NOMOTION,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
            },
        },
        glass_breakage_alarm: {
            glass_breakage: {
                [ICON]: icons.IcDeviceSensorGlasBreak,
                [CATEGORY_ID]: IDS.SECURITY_DEVICES,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_SEC_GLASSBREAK,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
                [COLOR]: WARNING_COLOR,
            },
            no_glass_breakage: {
                [ICON]: icons.IcDeviceNoBrokenGlass,
                [CATEGORY_ID]: IDS.SECURITY_DEVICES,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_SEC_NOGLASSBREAK,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
            },
        },
        co2_alarm: {
            co2_detected: {
                [ICON]: icons.IcDeviceSensorCo2,
                [CATEGORY_ID]: IDS.SECURITY_DEVICES,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_SEC_CO2,
                },
                [COLOR]: WARNING_COLOR,
            },
            no_co2: {
                [ICON]: icons.IcDeviceSensorCo2,
                [CATEGORY_ID]: IDS.SECURITY_DEVICES,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_SEC_NOCO2,
                },
            },
        },
        gas_alarm: {
            combustible_gas_detected: {
                [ICON]: icons.IcDeviceSensorGasLeak,
                [CATEGORY_ID]: IDS.SECURITY_DEVICES,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_SEC_COMBUSTABLEGAS,
                },
                [COLOR]: WARNING_COLOR,
            },
            toxic_gas_detected: {
                [ICON]: icons.IcDeviceSensorGasLeak,
                [CATEGORY_ID]: IDS.SECURITY_DEVICES,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_SEC_TOXICGAS,
                },
                [COLOR]: WARNING_COLOR,
            },
            no_gas: {
                [ICON]: icons.IcDeviceSensorNoGasLeak,
                [CATEGORY_ID]: IDS.SECURITY_DEVICES,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_SEC_NOGAS,
                },
            },
        },
        siren_alarm: {
            siren_active: {
                [ICON]: icons.IcDeviceVoiceAssistants,
                [CATEGORY_ID]: IDS.SECURITY_DEVICES,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_SEC_SIREN,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
                [COLOR]: WARNING_COLOR,
            },
            siren_inactive: {
                [ICON]: icons.IcActionsSpeakOff,
                [CATEGORY_ID]: IDS.SECURITY_DEVICES,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_SEC_NOSIREN,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
            },
        },
        temperature_changes: {
            rapid_temperature_rise: {
                [ICON]: icons.IcDeviceSensorTemperature,
                [CATEGORY_ID]: IDS.SECURITY_DEVICES,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_SEC_RAPIDTEMPRISE,
                },
                [COLOR]: WARNING_COLOR,
            },
            rapid_temperature_fail: {
                [ICON]: icons.IcCategoryDevice32,
                [CATEGORY_ID]: IDS.SECURITY_DEVICES,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_SEC_RAPIDTEMPFAIL,
                },
            },
        },
        intrusion_alarm: {
            intrusion: {
                [ICON]: icons.IcDeviceSensorMotion,
                [CATEGORY_ID]: IDS.SECURITY_DEVICES,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_SEC_INTRUSION,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
                [COLOR]: WARNING_COLOR,
            },
            no_intrusion: {
                [ICON]: icons.IcDeviceSensorMotion,
                [CATEGORY_ID]: IDS.SECURITY_DEVICES,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_SEC_NOINTRUSION,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
            },
        },
        tampering_move_alarm: {
            product_moved: {
                [ICON]: icons.IcCategoryDevice32,
                [CATEGORY_ID]: IDS.SECURITY_DEVICES,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_SEC_TAMPERMOVE,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
                [COLOR]: WARNING_COLOR,
            },
            no_moving: {
                [ICON]: icons.IcCategoryDevice32,
                [CATEGORY_ID]: IDS.SECURITY_DEVICES,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_SEC_NOTAMPERMOVE,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
            },
        },
        tampering_impact_alarm: {
            no_impact: {
                [ICON]: icons.IcCategoryDevice32,
                [CATEGORY_ID]: IDS.SECURITY_DEVICES,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_SEC_NOTAMPERIMPACT,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
            },
            impact_detected: {
                [ICON]: icons.IcCategoryDevice32,
                [CATEGORY_ID]: IDS.SECURITY_DEVICES,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_SEC_TAMPERIMPACT,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
                [COLOR]: WARNING_COLOR,
            },
        },
        tampering_invalid_code_alarm: {
            invalid_code: {
                [ICON]: icons.IcDeviceCrossedOutPincodeIcon,
                [CATEGORY_ID]: IDS.SECURITY_DEVICES,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_SEC_TAMPERINVALIDCODE,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
                [COLOR]: WARNING_COLOR,
            },
        },

        // DOOR_LOCKS category
        lock_operation: {
            manual_lock_operation: {
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_LOCK_MANUAL,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
                [ICON]: icons.IcDoorLocksDoorLockUnlocked,
                [CATEGORY_ID]: IDS.DOOR_LOCKS,
            },
            rf_lock_operation: {
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_LOCK_RF,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
                [ICON]: icons.IcDoorLocksDoorLockUnlocked,
                [CATEGORY_ID]: IDS.DOOR_LOCKS,
            },
            keypad_lock_operation: {
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_LOCK_KEYPAD,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
                [ICON]: icons.IcDoorLocksDoorLockUnlocked,
                [CATEGORY_ID]: IDS.DOOR_LOCKS,
            },
            manual_unlock_operation: {
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_LOCK_MANUALUNLOCK,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
                [ICON]: icons.IcActionsDoorlock,
                [CATEGORY_ID]: IDS.DOOR_LOCKS,
            },
            rf_unlock_operation: {
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_LOCK_RFUNLOCK,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
                [ICON]: icons.IcActionsDoorlock,
                [CATEGORY_ID]: IDS.DOOR_LOCKS,
            },
            keypad_unlock_operation: {
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_LOCK_KEYPADUNLOCK,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
                [ICON]: icons.IcActionsDoorlock,
                [CATEGORY_ID]: IDS.DOOR_LOCKS,
            },
            lock_jammed: {
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_LOCK_JAMMED,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
                [ICON]: icons.IcActionsDoorlockError,
                [CATEGORY_ID]: IDS.DOOR_LOCKS,
            },
            manual_not_fully_locked_operation: {
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_LOCK_NOTFULLMANUAL,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
                [ICON]: icons.IcActionsDoorlockError,
                [CATEGORY_ID]: IDS.DOOR_LOCKS,
            },
            rf_not_fully_locked_operation: {
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_LOCK_NOTFULLRF,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
                [ICON]: icons.IcActionsDoorlockError,
                [CATEGORY_ID]: IDS.DOOR_LOCKS,
            },
            auto_lock_not_fully_locked_operation: {
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_LOCK_NOTFULLAUTO,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
                [ICON]: icons.IcActionsDoorlockError,
                [CATEGORY_ID]: IDS.DOOR_LOCKS,
            },
            unlock_by_rf_with_invalid_user_code: {
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_LOCK_UNLOCKRFINVALIDUSER,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
                [ICON]: icons.IcActionsDoorlockError,
                [CATEGORY_ID]: IDS.DOOR_LOCKS,
            },
            lock_by_rf_with_invalid_user_code: {
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_LOCK_LOCKRFINVALIDUSER,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
                [ICON]: icons.IcActionsDoorlockError,
                [CATEGORY_ID]: IDS.DOOR_LOCKS,
            },
            unknown: {
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_LOCK_LOCKUNKNOWN,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
                [ICON]: icons.IcActionsDoorlockError,
                [CATEGORY_ID]: IDS.DOOR_LOCKS,
            },
        },
        door_lock: {
            unsecured: {
                [ICON]: icons.IcActionsDoorlock,
                [CATEGORY_ID]: IDS.DOOR_LOCKS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_LOCK_UNSECURED,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
            },
            unsecured_with_timeout: {
                [ICON]: icons.IcActionsDoorlock,
                [CATEGORY_ID]: IDS.DOOR_LOCKS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_LOCK_UNSECUREDTIMEOUT,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
            },
            unsecured_for_inside: {
                [ICON]: icons.IcActionsDoorlock,
                [CATEGORY_ID]: IDS.DOOR_LOCKS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_LOCK_UNSECUREDINSIDE,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
            },
            unsecured_for_inside_with_timeout: {
                [ICON]: icons.IcActionsDoorlock,
                [CATEGORY_ID]: IDS.DOOR_LOCKS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_LOCK_UNSECUREDINSIDETIMEOUT,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
            },
            unsecured_for_outside: {
                [ICON]: icons.IcActionsDoorlock,
                [CATEGORY_ID]: IDS.DOOR_LOCKS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_LOCK_UNSECUREDOUTSIDE,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
            },
            unsecured_for_outside_with_timeout: {
                [ICON]: icons.IcActionsDoorlock,
                [CATEGORY_ID]: IDS.DOOR_LOCKS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_LOCK_UNSECUREDOUTSIDETIMEOUT,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
            },
            secured: {
                [ICON]: icons.IcActionsDoorlockLock,
                [CATEGORY_ID]: IDS.DOOR_LOCKS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_LOCK_SECURED,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
            },
            unknown: {
                [ICON]: icons.IcActionsDoorlockError,
                [CATEGORY_ID]: IDS.DOOR_LOCKS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_LOCK_DOORLOCKUNKNOWN,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
            },
        },
        user_code_operation: {
            new_user_code_added: {
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_LOCK_USERNEW,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
                [ICON]: icons.IcDeviceMiscKeypad,
                [CATEGORY_ID]: IDS.DOOR_LOCKS,
            },
            single_user_code_deleted: {
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_LOCK_USESINGLEDELETE,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
                [ICON]: icons.IcDeviceCrossedOutPincodeIcon,
                [CATEGORY_ID]: IDS.DOOR_LOCKS,
            },
            all_user_codes_deleted: {
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_LOCK_USERALLDELETE,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
                [ICON]: icons.IcDeviceCrossedOutPincodeIcon,
                [CATEGORY_ID]: IDS.DOOR_LOCKS,
            },
            new_program_code_entered: {
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_LOCK_USERNEWPROGRAM,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
                [ICON]: icons.IcDeviceMiscKeypad,
                [CATEGORY_ID]: IDS.DOOR_LOCKS,
            },
        },
        latch_state: {
            latch_open: {
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_LOCK_LATCHOPEN,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
                [ICON]: icons.IcActionsDoorlock,
                [CATEGORY_ID]: IDS.DOOR_LOCKS,
            },
            latch_closed: {
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_LOCK_LATCHCLOSED,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
                [ICON]: icons.IcActionsDoorlockLock,
                [CATEGORY_ID]: IDS.DOOR_LOCKS,
            },
        },
        keypad_state: {
            keypad_temporary_disabled: {
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_LOCK_KEYPADTEMPDISABLED,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
                [ICON]: icons.IcDeviceCrossedOutPincodeIcon,
                [CATEGORY_ID]: IDS.DOOR_LOCKS,
            },
        },
        user_codes_keypad_mode: {
            vacation: {
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_LOCK_KPMODEVACATION,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
                [ICON]: icons.IcSecurityAlarmPanel,
                [CATEGORY_ID]: IDS.DOOR_LOCKS,
            },
            privacy: {
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_LOCK_KPMODEPRIVACY,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
                [ICON]: icons.IcSecurityAlarmPanel,
                [CATEGORY_ID]: IDS.DOOR_LOCKS,
            },
            locked_out: {
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_LOCK_KPMODELOCKEDOUT,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
                [ICON]: icons.IcDeviceCrossedOutPincodeIcon,
                [CATEGORY_ID]: IDS.DOOR_LOCKS,
            },
        },
        master_code_state: {
            enabled: {
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_LOCK_MASTERCODEENABLED,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
                [ICON]: icons.IcDeviceMiscKeypad,
                [CATEGORY_ID]: IDS.DOOR_LOCKS,
            },
            disabled: {
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_LOCK_MASTERCODEDISABLED,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
                [ICON]: icons.IcDeviceCrossedOutPincodeIcon,
                [CATEGORY_ID]: IDS.DOOR_LOCKS,
            },
        },
        user_lock_operation: {
            unlock: {
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_LOCK_USERUNLOCKED,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
                [ICON]: icons.IcDoorLocksDoorLockUnlocked,
                [CATEGORY_ID]: IDS.DOOR_LOCKS,
            },
            lock: {
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HISTORY_LOCK_USERLOCKED,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
                [ICON]: icons.IcDoorLocksDoorLockUnlocked,
                [CATEGORY_ID]: IDS.DOOR_LOCKS,
            },
        },

        // GARAGE_DOORS category
        barrier: {
            barrier_closed: {
                [ICON]: icons.IcDeviceMiscGarageDoor2,
                [CATEGORY_ID]: IDS.GARAGE_DOORS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_GRG_BARRIERCLOSED,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
            },
            barrier_opened: {
                [ICON]: icons.IcDeviceMiscGarageDoor,
                [CATEGORY_ID]: IDS.GARAGE_DOORS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_GRG_BARRIEROPENED,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
            },
        },
        barrier_initialization: {
            process_completed: {
                [ICON]: icons.IcDeviceMiscGarageDoor2,
                [CATEGORY_ID]: IDS.GARAGE_DOORS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_GRG_PROCESSCOMPLETED,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
            },
            not_started: {
                [ICON]: icons.IcDeviceMiscGarageDoor2,
                [CATEGORY_ID]: IDS.GARAGE_DOORS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_GRG_NOTSTARTED,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
            },
        },
        barrier_unattended_operation: {
            unattended_operation_enabled: {
                [ICON]: icons.IcDeviceMiscGarageDoor2,
                [CATEGORY_ID]: IDS.GARAGE_DOORS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_GRG_UNATTENDEDOPERATIONENABLED,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
            },
        },
        barrier_vacation_mode: {
            mode_enabled: {
                [ICON]: icons.IcDeviceMiscGarageDoor2,
                [CATEGORY_ID]: IDS.GARAGE_DOORS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_GRG_MODEENABLED,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
            },
            mode_disabled: {
                [ICON]: icons.IcDeviceMiscGarageDoor2,
                [CATEGORY_ID]: IDS.GARAGE_DOORS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_GRG_MODEDISABLED,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
            },
        },
        barrier_safety_beam_obstacle: {
            obstruction: {
                [ICON]: icons.IcCategoryGarageDoorCrossedOut,
                [CATEGORY_ID]: IDS.GARAGE_DOORS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_GRG_OBSTRUCTION,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
            },
            no_obstruction: {
                [ICON]: icons.IcDeviceMiscGarageDoor2,
                [CATEGORY_ID]: IDS.GARAGE_DOORS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_GRG_NOOBSTRUCTION,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
            },
        },
        barrier_problem_sensors: {
            low_battery: {
                [ICON]: icons.IcDeviceMiscGarageDoorBattery,
                [CATEGORY_ID]: IDS.GARAGE_DOORS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_GRG_LOWBATTERY,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
            },
        },
        barrier_short_circuit: {
            short_circuit_detected: {
                [ICON]: icons.IcDeviceMiscGarageDoor2,
                [CATEGORY_ID]: IDS.GARAGE_DOORS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_GRG_SHORTCIRCUITDETECTED,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
            },
        },
        barrier_fail_events: {
            barrier_operation_force_has_been_exceeded: {
                [ICON]: icons.IcDeviceMiscGarageDoor2,
                [CATEGORY_ID]: IDS.GARAGE_DOORS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_GRG_BARRIEROPFORCEEXCEEDED,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
            },
            barrier_motor_exceeded_operational_time_limit: {
                [ICON]: icons.IcDeviceMiscGarageDoor2,
                [CATEGORY_ID]: IDS.GARAGE_DOORS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_GRG_BARRIERMOTOREXCEEDEDOPTIMELIMIT,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
            },
            barrier_operation_exceeded_physical_mechanical_limits: {
                [ICON]: icons.IcDeviceMiscGarageDoor2,
                [CATEGORY_ID]: IDS.GARAGE_DOORS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_GRG_BARRIERMOTOREXCEEDEDPHYMECLIMIT,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
            },
            barrier_failed_to_perform_requested_operation_device_malfunction: {
                [ICON]: icons.IcDeviceMiscGarageDoor2,
                [CATEGORY_ID]: IDS.GARAGE_DOORS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_GRG_BARRIERDEVICE_MALFUNCTION,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                    },
                },
            },
        },

        // SCENE_CONTROLLERS category
        button_state: {
            press_1_time: {
                [ICON]: icons.IcActionsPanicButton,
                [CATEGORY_ID]: IDS.SCENE_CONTROLLERS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_SCN_PRESS1TIME,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                        'Button Name': 'data.result.value.button_number',
                    },
                },
            },
            released: {
                [ICON]: icons.IcActionsPanicButton,
                [CATEGORY_ID]: IDS.SCENE_CONTROLLERS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_SCN_RELEASED,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                        'Button Name': 'data.result.value.button_number',
                    },
                },
            },
            held_down: {
                [ICON]: icons.IcActionsPanicButton,
                [CATEGORY_ID]: IDS.SCENE_CONTROLLERS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_SCN_HELDDOWN,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                        'Button Name': 'data.result.value.button_number',
                    },
                },
            },
            press_2_times: {
                [ICON]: icons.IcActionsPanicButton,
                [CATEGORY_ID]: IDS.SCENE_CONTROLLERS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_SCN_PRESS2TIME,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                        'Button Name': 'data.result.value.button_number',
                    },
                },
            },
            press_3_times: {
                [ICON]: icons.IcActionsPanicButton,
                [CATEGORY_ID]: IDS.SCENE_CONTROLLERS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_SCN_PRESS3TIME,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                        'Button Name': 'data.result.value.button_number',
                    },
                },
            },
            press_4_times: {
                [ICON]: icons.IcActionsPanicButton,
                [CATEGORY_ID]: IDS.SCENE_CONTROLLERS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_SCN_PRESS4TIME,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                        'Button Name': 'data.result.value.button_number',
                    },
                },
            },
            press_5_times: {
                [ICON]: icons.IcActionsPanicButton,
                [CATEGORY_ID]: IDS.SCENE_CONTROLLERS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_SCN_PRESS5TIME,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                        'Button Name': 'data.result.value.button_number',
                    },
                },
            },
            on_to_off: {
                [ICON]: icons.IcControlsOnOff,
                [CATEGORY_ID]: IDS.SCENE_CONTROLLERS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_SCN_ONTOOFF,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                        'Button Name': 'data.result.value.button_number',
                    },
                },
            },
            off_to_on: {
                [ICON]: icons.IcControlsOnOff,
                [CATEGORY_ID]: IDS.SCENE_CONTROLLERS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_SCN_OFFTOON,
                    placeholdersPaths: {
                        'Device Name': 'data.result.deviceName',
                        'Button Name': 'data.result.value.button_number',
                    },
                },
            },
        },

        // LIGHTS_AND_SWITCHES category
        dimmer: {
            [ICON]: icons.IcDeviceLighthingBasicLight,
            [CATEGORY_ID]: IDS.LIGHTS_AND_SWITCHES,
            [DESCRIPTION_CONFIG]: {
                translationKey: lt.HST_LIGHT_DIMMER,
                placeholdersPaths: {
                    'device_name': 'data.result.deviceName',
                    'result.value': 'data.result.value',
                },
            },
        },
        dimmer_down: {
            [ICON]: icons.IcDeviceLighthingBasicLight,
            [CATEGORY_ID]: IDS.LIGHTS_AND_SWITCHES,
            [DESCRIPTION_CONFIG]: {
                translationKey: lt.HST_LIGHT_DIMMERDOWN,
                placeholdersPaths: {
                    device_name: 'data.result.deviceName',
                },
            },
        },
        dimmer_stop: {
            [ICON]: icons.IcDeviceLighthingBasicLight,
            [CATEGORY_ID]: IDS.LIGHTS_AND_SWITCHES,
            [DESCRIPTION_CONFIG]: {
                translationKey: lt.HST_LIGHT_DIMMERSTOP,
                placeholdersPaths: {
                    device_name: 'data.result.deviceName',
                },
            },
        },
        dimmer_up: {
            [ICON]: icons.IcDeviceLighthingBasicLight,
            [CATEGORY_ID]: IDS.LIGHTS_AND_SWITCHES,
            [DESCRIPTION_CONFIG]: {
                translationKey: lt.HST_LIGHT_DIMMERUP,
                placeholdersPaths: {
                    device_name: 'data.result.deviceName',
                },
            },
        },
        power: {
            [ICON]: icons.IcDeviceLighthingBasicLight,
            [CATEGORY_ID]: IDS.LIGHTS_AND_SWITCHES,
            [DESCRIPTION_CONFIG]: {
                translationKey: lt.HST_LIGHT_POWER,
                placeholdersPaths: {
                    device_name: 'data.result.deviceName',
                },
            },
        },
        switch: {
            true: {
                [ICON]: icons.IcDeviceLighthingBasicLight,
                [CATEGORY_ID]: IDS.LIGHTS_AND_SWITCHES,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_LIGHT_ON,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
            },
            false: {
                [ICON]: icons.IcDeviceLighthingBasicLight,
                [CATEGORY_ID]: IDS.LIGHTS_AND_SWITCHES,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_LIGHT_OFF,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
            },
        },

        // THERMOSTATS category
        thermostat_fan_mode: {
            auto_low: {
                [ICON]: icons.IcActionsThermostatModeFanMediumOnly,
                [CATEGORY_ID]: IDS.THERMOSTATS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_HVACFANM_AUTOLOW,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
            },
            low: {
                [ICON]: icons.IcActionsThermostatModeFanMediumOnly,
                [CATEGORY_ID]: IDS.THERMOSTATS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_HVACFANM_LOW,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
            },
            auto_high: {
                [ICON]: icons.IcActionsThermostatModeFanMediumOnly,
                [CATEGORY_ID]: IDS.THERMOSTATS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_HVACFANM_AUTOHIGH,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
            },
            high: {
                [ICON]: icons.IcActionsThermostatModeFanMediumOnly,
                [CATEGORY_ID]: IDS.THERMOSTATS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_HVACFANM_HIGH,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
            },
            auto_medium: {
                [ICON]: icons.IcActionsThermostatModeFanMediumOnly,
                [CATEGORY_ID]: IDS.THERMOSTATS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_HVACFANM_AUTOMEDIUM,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
            },
            medium: {
                [ICON]: icons.IcActionsThermostatModeFanMediumOnly,
                [CATEGORY_ID]: IDS.THERMOSTATS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_HVACFANM_MEDIUM,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
            },
            circulation: {
                [ICON]: icons.IcActionsThermostatModeFanMediumOnly,
                [CATEGORY_ID]: IDS.THERMOSTATS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_HVACFANM_CIRCULATION,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
            },
            humidity_circulation: {
                [ICON]: icons.IcActionsThermostatModeFanMediumOnly,
                [CATEGORY_ID]: IDS.THERMOSTATS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_HVACFANM_HUMIDITYCIRCULATION,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
            },
            left_and_right: {
                [ICON]: icons.IcActionsThermostatModeFanMediumOnly,
                [CATEGORY_ID]: IDS.THERMOSTATS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_HVACFANM_LEFTANDRIGHT,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
            },
            up_and_down: {
                [ICON]: icons.IcActionsThermostatModeFanMediumOnly,
                [CATEGORY_ID]: IDS.THERMOSTATS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_HVACFANM_UPANDDOWN,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
            },
            quiet: {
                [ICON]: icons.IcActionsThermostatModeFanMediumOnly,
                [CATEGORY_ID]: IDS.THERMOSTATS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_HVACFANM_QUIET,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
            },
            on: {
                [ICON]: icons.IcActionsThermostatModeFanMediumOnly,
                [CATEGORY_ID]: IDS.THERMOSTATS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_HVACFANM_ON,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
            },
            off: {
                [ICON]: icons.IcActionsThermostatModeFanMediumOnly,
                [CATEGORY_ID]: IDS.THERMOSTATS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_HVACFANM_OFF,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
            },
            auto: {
                [ICON]: icons.IcActionsThermostatModeFanMediumOnly,
                [CATEGORY_ID]: IDS.THERMOSTATS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_HVACFANM_AUTO,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
            },
        },
        thermostat_fan_state: {
            idle_off: {
                [ICON]: icons.IcActionsThermostatModeFanMediumOnly,
                [CATEGORY_ID]: IDS.THERMOSTATS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_HVACFANS_IDLEOFF,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
            },
            running_low: {
                [ICON]: icons.IcActionsThermostatModeFanMediumOnly,
                [CATEGORY_ID]: IDS.THERMOSTATS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_HVACFANS_RUNLOW,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
            },
            running_high: {
                [ICON]: icons.IcActionsThermostatModeFanMediumOnly,
                [CATEGORY_ID]: IDS.THERMOSTATS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_HVACFANS_RUNHIGH,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
            },
            running_medium: {
                [ICON]: icons.IcActionsThermostatModeFanMediumOnly,
                [CATEGORY_ID]: IDS.THERMOSTATS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_HVACFANS_RUNMEDIUM,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
            },
            circulation_mode: {
                [ICON]: icons.IcActionsThermostatModeFanMediumOnly,
                [CATEGORY_ID]: IDS.THERMOSTATS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_HVACFANS_CIRCULATION,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
            },
            humidity_circulation_mode: {
                [ICON]: icons.IcActionsThermostatModeFanMediumOnly,
                [CATEGORY_ID]: IDS.THERMOSTATS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_HVACFANS_HUMCIRCULATION,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
            },
            right_left_circulation_mode: {
                [ICON]: icons.IcActionsThermostatModeFanMediumOnly,
                [CATEGORY_ID]: IDS.THERMOSTATS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_HVACFANS_RIGHTLEFTCIRCULATION,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
            },
            up_down_circulation_mode: {
                [ICON]: icons.IcActionsThermostatModeFanMediumOnly,
                [CATEGORY_ID]: IDS.THERMOSTATS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_HVACFANS_UPDOWNCIRCULATION,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
            },
            quiet_circulation_mode: {
                [ICON]: icons.IcActionsThermostatModeFanMediumOnly,
                [CATEGORY_ID]: IDS.THERMOSTATS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_HVACFANS_QUIETCIRCULATION,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
            },
        },
        thermostat_mode: {
            off: {
                [ICON]: icons.IcClimateThermostat,
                [CATEGORY_ID]: IDS.THERMOSTATS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_HVACM_OFF,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
            },
            heat: {
                [ICON]: icons.IcActionsThermostatModeHeat,
                [CATEGORY_ID]: IDS.THERMOSTATS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_HVACM_HEAT,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
            },
            cool: {
                [ICON]: icons.IcActionsThermostatModeCool,
                [CATEGORY_ID]: IDS.THERMOSTATS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_HVACM_COOL,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
            },
            auto: {
                [ICON]: icons.IcActionsThermostatModeAuto,
                [CATEGORY_ID]: IDS.THERMOSTATS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_HVACM_AUTO,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
            },
            aux_mode: {
                [ICON]: icons.IcClimateThermostat,
                [CATEGORY_ID]: IDS.THERMOSTATS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_HVACM_AUX,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
            },
            fan_only: {
                [ICON]: icons.IcActionsThermostatModeFanMediumOnly,
                [CATEGORY_ID]: IDS.THERMOSTATS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_HVACM_FANONLY,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
            },
            eco: {
                [ICON]: icons.IcThermostatIconWithLeaf,
                [CATEGORY_ID]: IDS.THERMOSTATS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_HVACM_ECO,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
            },
        },
        thermostat_setpoint: {
            [ICON]: icons.IcThermostatSetpoint,
            [CATEGORY_ID]: IDS.LIGHTS_AND_SWITCHES,
            [DESCRIPTION_CONFIG]: {
                translationKey: lt.HST_HVAC_SETPOINT,
                placeholdersPaths: {
                    device_name: 'data.result.deviceName',
                },
            },
        },
        thermostat_setpoint_heating: {
            [ICON]: icons.IcThermostatSetpointIconWithFlame,
            [CATEGORY_ID]: IDS.LIGHTS_AND_SWITCHES,
            [DESCRIPTION_CONFIG]: {
                translationKey: lt.HST_HVAC_SETPOINTHEATING,
                placeholdersPaths: {
                    'device_name': 'data.result.deviceName',
                    'result.value': 'data.result.value',
                },
            },
        },
        thermostat_setpoint_cooling: {
            [ICON]: icons.IcThermostatSetpointIconWithFrost,
            [CATEGORY_ID]: IDS.LIGHTS_AND_SWITCHES,
            [DESCRIPTION_CONFIG]: {
                translationKey: lt.HST_HVAC_SETPOINTCOOLING,
                placeholdersPaths: {
                    'device_name': 'data.result.deviceName',
                    'result.value': 'data.result.value',
                },
            },
        },

        // OTHER_SENSORS category
        air_quality_grade: {
            excellent: {
                [ICON]: icons.IcDeviceAirQulitySensorClean,
                [CATEGORY_ID]: IDS.OTHER_SENSORS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_OTHER_AIRQEXCELLENT,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
            },
            good: {
                [ICON]: icons.IcDeviceAirQulitySensorClean,
                [CATEGORY_ID]: IDS.OTHER_SENSORS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_OTHER_AIRQGOOD,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
            },
            lightly_polluted: {
                [ICON]: icons.IcDeviceAirQulitySensorModereately,
                [CATEGORY_ID]: IDS.OTHER_SENSORS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_OTHER_AIRQLIGHTLYPOLLUTED,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
            },
            moderately_polluted: {
                [ICON]: icons.IcDeviceAirQulitySensorHighly,
                [CATEGORY_ID]: IDS.OTHER_SENSORS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_OTHER_AIRQMODERATELY_POLLUTED,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
            },
            heavily_polluted: {
                [ICON]: icons.IcDeviceAirQulitySensorHighly,
                [CATEGORY_ID]: IDS.OTHER_SENSORS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_OTHER_AIRQHEAVILY_POLLUTED,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
            },
            severely_polluted: {
                [ICON]: icons.IcDeviceAirQulitySensorHighly,
                [CATEGORY_ID]: IDS.OTHER_SENSORS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_OTHER_AIRQSEVERELY_POLLUTED,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
            },
            extremely_polluted: {
                [ICON]: icons.IcDeviceAirQulitySensorHighly,
                [CATEGORY_ID]: IDS.OTHER_SENSORS,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_OTHER_AIRQEXTREMELY_POLLUTED,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
            },
        },
        // DOORBELL category
        doorbell_state: {
            ring: {
                [ICON]: icons.IcActionsDoorbellCamera,
                [CATEGORY_ID]: IDS.DOORBELL,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_DOORBELL_RING,
                },
                [COLOR]: WARNING_COLOR,
            },
        },
    },
    // SYSTEM category
    'hub.device.added': {
        [ICON]: icons.IcCategoryDevice32,
        [CATEGORY_ID]: IDS.SYSTEM,
        [DESCRIPTION_CONFIG]: {
            translationKey: lt.HST_SYS_DEVICEADDED,
            placeholdersPaths: {
                'result.category': 'data.result.category',
                'result.name': 'data.result.name',
            },
        },
    },
    'hub.reboot': {
        [ICON]: icons.IcControlsRotate,
        [CATEGORY_ID]: IDS.SYSTEM,
        [DESCRIPTION_CONFIG]: {
            translationKey: lt.HST_SYS_HUBREBOOT,
        },
    },
    'hub.network.modem.reregistration.failed': {
        [ICON]: icons.IcDeviceNo4GCrossed,
        [CATEGORY_ID]: IDS.SYSTEM,
        [DESCRIPTION_CONFIG]: {
            translationKey: lt.HST_SYS_HUBNETWORKMODEMFAIL,
        },
    },
    'system_protect_alert_start_alert_burglary': {
        [ICON]: icons.IcBurglaryAlarm,
        [CATEGORY_ID]: IDS.ALARM,
        [DESCRIPTION_CONFIG]: {
            translationKey: lt.HST_SEC_ALARMBURGLAR,
        },
    },
    'system_protect_alert_start_alert_fire': {
        [ICON]: icons.IcFireAlarm,
        [CATEGORY_ID]: IDS.ALARM,
        [DESCRIPTION_CONFIG]: {
            translationKey: lt.HST_SEC_ALARMFIRE,
        },
    },
    'system_protect_panic_fire': {
        [ICON]: icons.IcFireAlarm,
        [CATEGORY_ID]: IDS.ALARM,
        [DESCRIPTION_CONFIG]: {
            translationKey: lt.HST_SEC_ALARMFIRE,
        },
    },
    'system_protect_panic_medical': {
        [ICON]: icons.IcMedicalAsistanceRequest,
        [CATEGORY_ID]: IDS.ALARM,
        [DESCRIPTION_CONFIG]: {
            translationKey: lt.HST_SEC_ALARMPANICMEDICAL,
        },
    },
    'system_protect_panic_police': {
        [ICON]: icons.IcPoliceRequest,
        [CATEGORY_ID]: IDS.ALARM,
        [DESCRIPTION_CONFIG]: {
            translationKey: lt.HST_SEC_ALARMPANICPOLICE,
        },
    },
    'system_protect_panic_cancel': {
        [ICON]: icons.IcAssistanceRequestCancelled,
        [CATEGORY_ID]: IDS.ALARM,
        [DESCRIPTION_CONFIG]: {
            translationKey: lt.HST_SEC_ALARMPANICCANCEL,
        },
    },
    'system_protect_panic_dispatch': {
        [ICON]: icons.IcAssistanceRequestCancelled,
        [CATEGORY_ID]: IDS.ALARM,
        [DESCRIPTION_CONFIG]: {
            translationKey: lt.HST_SEC_ALARMPANICVERIFY,
        },
    },
    'system_protect_test_mode_on': {
        [ICON]: icons.IcTestModeTurnedOn,
        [CATEGORY_ID]: IDS.ALARM,
        [DESCRIPTION_CONFIG]: {
            translationKey: lt.HST_SEC_TESTON,
        },
    },
    'system_protect_test_mode_off': {
        [ICON]: icons.IcTestModeTurnedOff,
        [CATEGORY_ID]: IDS.ALARM,
        [DESCRIPTION_CONFIG]: {
            translationKey: lt.HST_SEC_TESTOFF,
        },
    },
    // DOOR_LOCKS category
    [HUB_USER_NOTIFICATION]: {
        // result.type == "pin_code_is_used"
        pin_code_is_used: {
            [ICON]: icons.IcDeviceMiscKeypad,
            [CATEGORY_ID]: IDS.DOOR_LOCKS,
            [DESCRIPTION_CONFIG]: {
                translationKey: lt.HISTORY_LOCK_USERPINCODE,
                placeholdersPaths: {
                    'result.params.user': 'data.result.params.user',
                    'result.params.deviceName': 'data.result.params.deviceName',
                },
            },
        },
    },
};

// SYSTEM category
export const hubInfoChangedParams = {
    battery: {
        status: {
            initializing: {
                [ICON]: icons.IcActionsBatteryHalf,
                [CATEGORY_ID]: IDS.SYSTEM,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_SYS_BATTERYINIT,
                },
            },
            missing: {
                [ICON]: icons.IcActionsNoBattery,
                [CATEGORY_ID]: IDS.SYSTEM,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_SYS_BATTERYMISSING,
                },
            },
            charging: {
                [ICON]: icons.IcActionsBatteryChargingFull,
                [CATEGORY_ID]: IDS.SYSTEM,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_SYS_BATTERYCHARGING,
                },
            },
            full: {
                [ICON]: icons.IcActionsBatteryFull,
                [CATEGORY_ID]: IDS.SYSTEM,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_SYS_BATTERYFULL,
                },
            },
            discharging: {
                [ICON]: icons.IcActionsBatteryHalf,
                [CATEGORY_ID]: IDS.SYSTEM,
                [DESCRIPTION_CONFIG]: {
                    translationKey: lt.HST_SYS_BATTERYDISCHARGING,
                    placeholdersPaths: {
                        device_name: 'data.result.deviceName',
                    },
                },
            },
        },
        stateOfCharge: {
            [ICON]: icons.IcActionsBatteryHalf,
            [CATEGORY_ID]: IDS.SYSTEM,
            [DESCRIPTION_CONFIG]: {
                translationKey: lt.HST_SYS_BATTERYLEVEL,
                placeholdersPaths: {
                    'battery.stateOfCharge': 'data.result.battery.stateOfCharge',
                },
            },
        },
        remainingTime: {
            [ICON]: icons.IcActionsBatteryHalf,
            [CATEGORY_ID]: IDS.SYSTEM,
            [DESCRIPTION_CONFIG]: {
                translationKey: lt.HST_SYS_BATTERYREMTIME,
                placeholdersPaths: {
                    'battery.remainingTime': 'data.result.battery.remainingTime',
                },
            },
        },
        health: {
            [ICON]: icons.IcActionsBatteryHalf,
            [CATEGORY_ID]: IDS.SYSTEM,
            [DESCRIPTION_CONFIG]: {
                translationKey: lt.HST_SYS_BATTERYHEALTH,
                placeholdersPaths: {
                    'battery.health': 'data.result.battery.health',
                },
            },
        },
    },
};

export const networkChangedToDownStatusEventParams = {
    [CATEGORY_ID]: IDS.SYSTEM,
    [ICON]: icons.IcEthernetPort,
    [DESCRIPTION_CONFIG]: {
        translationKey: lt.HST_SYS_HUBNETWORKDOWN,
    },
};

export const networkChangedEventParams = {
    [ICON]: icons.IcEthernetPort,
    [CATEGORY_ID]: IDS.SYSTEM,
    [DESCRIPTION_CONFIG]: {
        translationKey: lt.HST_SYS_HUBNETWORKUPDATE,
        placeholdersPaths: {
            interface: 'data.result.interfaces[0]._id',
            status: 'data.result.interfaces[0].status',
        },
    },
};

export const paramsOfSubclassTypeProgress = {
    'hub.software.update.progress': {
        success: {
            [ICON]: icons.IcTabbarDeviceSecureB,
            [CATEGORY_ID]: IDS.SYSTEM,
            [DESCRIPTION_CONFIG]: {
                translationKey: lt.HST_SYS_HUBUPDATEPROGRESS,
                placeholdersPaths: {
                    'result.completed': 'data.result.completed',
                },
            },
        },
        error: {
            [ICON]: icons.IcControlsClose32,
            [CATEGORY_ID]: IDS.SYSTEM,
            [DESCRIPTION_CONFIG]: {
                translationKey: lt.HST_SYS_HUBUPDATEFAIL,
            },
        },
    },
    'hub.backup.restore.progress': {
        success: {
            [ICON]: icons.IcTabbarDeviceSecureB,
            [CATEGORY_ID]: IDS.SYSTEM,
            [DESCRIPTION_CONFIG]: {
                translationKey: lt.HST_SYS_HUBRESTOREPROGRESS,
                placeholdersPaths: {
                    'result.status': 'data.result.status',
                    'result.completed': 'data.result.completed',
                },
            },
        },
        error: {
            [ICON]: icons.IcControlsClose32,
            [CATEGORY_ID]: IDS.SYSTEM,
            [DESCRIPTION_CONFIG]: {
                translationKey: lt.HST_SYS_HUBRESTOREFAIL,
            },
        },
    },
    'hub.backup.create.progress': {
        success: {
            [ICON]: icons.IcTabbarDeviceSecureB,
            [CATEGORY_ID]: IDS.SYSTEM,
            [DESCRIPTION_CONFIG]: {
                translationKey: lt.HST_SYS_HUBBACKUPPROGRESS,
                placeholdersPaths: {
                    'result.status': 'data.result.status',
                    'result.completed': 'data.result.completed',
                },
            },
        },
        error: {
            [ICON]: icons.IcControlsClose32,
            [CATEGORY_ID]: IDS.SYSTEM,
            [DESCRIPTION_CONFIG]: {
                translationKey: lt.HST_SYS_HUBBACKUPFAIL,
            },
        },
    },
};

export const hubModesSwitchedParamsById = {
    // home mode id
    1: {
        [ICON]: icons.IcModesAtHome,
        [CATEGORY_ID]: IDS.ALARM,
        [DESCRIPTION_CONFIG]: {
            translationKey: lt.HST_SEC_DISARMED,
        },
        [COLOR]: GREEN_COLOR,
    },
    // away mode id
    2: {
        [ICON]: icons.IcModesAway,
        [CATEGORY_ID]: IDS.ALARM,
        [DESCRIPTION_CONFIG]: {
            translationKey: lt.HST_SEC_ARMAWAY,
        },
        [COLOR]: RED_COLOR,
    },
    // night mode id
    3: {
        [ICON]: icons.IcModesGoodNight,
        [CATEGORY_ID]: IDS.ALARM,
        [DESCRIPTION_CONFIG]: {
            translationKey: lt.HST_SEC_ARMSTAY,
        },
        [COLOR]: WARNING_COLOR,
    },
};

/**
 * @type {AlertHistoryEventParams}
 */
export const modesAlarmedByPass = {
    [ICON]: icons.IcGenericSensorWithBypass,
    [CATEGORY_ID]: IDS.ALARM,
    [DESCRIPTION_CONFIG]: {
        translationKey: lt.HST_SEC_SENSORBYPASS,
        placeholdersPaths: {
            'Device Name': 'data.result.deviceName',
        },
    },
};
export const modesAlarmedEntryDelayFinish = {
    [ICON]: icons.IcEntryDelayFinish,
    [CATEGORY_ID]: IDS.ALARM,
    [DESCRIPTION_CONFIG]: {
        translationKey: lt.HST_SEC_ENTRYDELAYFINISH,
        placeholdersPaths: {
            modeId: 'data.result.modeId',
        },
    },
};
export const modesAlarmedMainDone = {
    [ICON]: icons.IcAlarmIcon,
    [CATEGORY_ID]: IDS.ALARM,
    [DESCRIPTION_CONFIG]: {
        translationKey: lt.HST_SEC_ALARM,
    },
};
export const modesAlarmedEntryDelayBegin = {
    [ICON]: icons.IcEntryDelayFinish,
    [CATEGORY_ID]: IDS.ALARM,
    [DESCRIPTION_CONFIG]: {
        translationKey: lt.HST_SEC_ENTRYDELAYSTART,
        placeholdersPaths: {
            modeId: 'data.result.modeId',
        },
    },
};
export const hubModesBypassDevicesRemovedParams = {
    [ICON]: icons.IcCategoryDevice32,
    [CATEGORY_ID]: IDS.ALARM,
    [DESCRIPTION_CONFIG]: {
        translationKey: lt.HST_SEC_DEVICEREMOVEFROMBYPASS,
        placeholdersPaths: {
            'deviceId1': 'data.result.deviceId1',
            ' deviceId2': 'data.result.deviceId2',
        },
    },
};
export const hubModesBypassDevicesAddedParams = {
    [ICON]: icons.IcCategoryDevice32,
    [CATEGORY_ID]: IDS.ALARM,
    [DESCRIPTION_CONFIG]: {
        translationKey: lt.HST_SEC_DEVICEBYPASSED,
        placeholdersPaths: {
            deviceId1: 'data.result.deviceId1',
            deviceId2: 'data.result.deviceId2',
        },
    },
};
