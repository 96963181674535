import * as types from '../constants/ActionTypes';
import wsm from '../helpers/wsm';
import GenericActions from './GenericActions';
import { bugsnagNotify } from '../containers/ErrorBoundary/utils';

const actions = {
    startcallGatewayMethod: (method) => ({
        type: types.START_CALL_GATEWAY_METHOD,
        method,
    }),

    endcallGatewayMethod: (method) => ({
        type: types.END_CALL_GATEWAY_METHOD,
        method,
    }),

    callGatewayMethod: (serial, gatewayId, method, params) => (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(actions.startcallGatewayMethod(method));
            wsm.send(
                serial,
                'callGatewayMethod',
                { _id: gatewayId, method: method, ...params },
                function () {
                    resolve();
                    dispatch(actions.endcallGatewayMethod(method));
                },
                function (error) {
                    bugsnagNotify(error, { serial, gatewayId, method, params });
                    reject();
                    dispatch(GenericActions.showError(error));
                },
            );
        }),

    setGatewayBusy: (serial, _id, busy) => ({
        type: types.SET_GATEWAY_BUSY,
        serial,
        _id,
        busy,
    }),

    setGatewayDeviceManageState: (serial, _id, state) => ({
        type: types.SET_GATEWAY_DEVICE_MANAGE_STATE,
        serial,
        _id,
        state,
    }),

    updateGatewayActionStatus: (gatewayMethodEventResult) => ({
        type: types.GATEWAY_METHOD_EVENT_DATA,
        gatewayMethodEventResult,
    }),
};

export default actions;
