import * as types from '../constants/ActionTypes/EzloGroup';
import wsm from '../helpers/wsm';
import GenericActions from './GenericActions';
import EzloActions from './EzloActions';
import { tokenParser } from '../helpers/auth';
import * as localStorageKeys from '../constants/localStorageKeys';
import { apiDevices } from '../api/apiDevices';
import GroupsActions from './GroupsActions';
import { buildControllerGetReportParams, setLastUsedControllersGroupUuid } from '../containers/Ezlo/EzloGroups/utils';
import {
    extractUuidsFromGroupControllers,
    mapControllerGetReportToGroupState,
    //Hidden for task 3085 called "Remove grouping of hubs on UI".
    // wrapControllerToGroupControllers,
    wrapControllersToGroupControllers,
} from '../helpers/groupUtils';
// import { GET_OFFLINE_EZLO } from '../constants/URLs';
import * as controllerTypes from '../constants/ActionTypes/Controller';
import GroupsAcrions from '../actions/GroupsActions';
import { bugsnagNotify } from '../containers/ErrorBoundary/utils';
import { PK_ACCOUNT_CHILD_EMPTY, NMA_CONTROLLER_STATUS_ONLINE } from '../constants/Devices';
import { apiGetControllersUuid } from '../api/apiScenes';

const actions = {
    //Todo handle 403 error for controller info request
    getGroupControllersInfo: (groupControllers) => async (dispatch) => {
        try {
            const { PK_Account, PK_AccountChild } = tokenParser(localStorage.getItem(localStorageKeys.IDENTITY));
            const currentPK_Account =
                PK_Account && PK_AccountChild != PK_ACCOUNT_CHILD_EMPTY ? PK_AccountChild : PK_Account;
            const devices = await apiDevices(currentPK_Account, true);
            const controllersUuids = await apiGetControllersUuid(devices.map(({ PK_Device }) => PK_Device));

            const ezlosArr = devices.map((groupItem) => {
                const { PK_Device } = groupItem;

                return dispatch(EzloActions.getEzlosInfo(PK_Device));
            });

            const ezlosRes = await Promise.all(ezlosArr);
            const ezlos = ezlosRes.map((controller, index) => ({
                ...devices[index],
                ...controller,
                serial: devices[index].PK_Device,
                connected: !!controller.NMAControllerStatus,
                controller_uuid: controllersUuids[controller.PK_Device],
            }));

            const groupEzlos = ezlos.filter((controller) =>
                groupControllers.find((i) => i.id === controller.PK_Device),
            );

            dispatch(EzloActions.receiveEzlos(groupEzlos));
            dispatch(GroupsAcrions.setAllControllerInfoToGroupsState(ezlos));
        } catch (error) {
            //Todo handle 403 error for controller info request
            bugsnagNotify(error, { groupControllers });
            // dispatch(GenericActions.showError('Error, cannot get controllers info'));
        }
    },

    setInitialGroupState: () => ({}),

    connectEzloGroupStart: (group) => ({
        type: types.CONNECT_EZLO_GROUP,
        group,
    }),

    selectGroup: (group) => async (dispatch) => {
        try {
            // possible improvement: save group to localStorage as last used
            // 1. Set selected group to state
            dispatch({ type: types.SELECT_GROUP, group });

            // 2. Connect to selected group
            return dispatch(actions.connectEzloGroup(group));
        } catch (error) {
            bugsnagNotify(error, { group });
            throw error;
        }
    },

    connectEzloGroup: (group) => async (dispatch, getState) => {
        // 1. Disconnect prev group
        await dispatch(actions.disconnectEzloGroup());

        // 2. Start connecting process
        dispatch(actions.connectEzloGroupStart());

        // 3. Fetch general group controllers info, fill 'ezlos' reducer
        await dispatch(actions.getGroupControllersInfo(group.controllers));

        // 4. Fetch controllers report: devices, items, scenes
        const uuids = extractUuidsFromGroupControllers(group.controllers);

        const controllerGetReportRes = await dispatch(
            GroupsActions.fetchControllerGetReport(buildControllerGetReportParams(uuids)),
        );

        // 5. Set initial group data
        const groupData = mapControllerGetReportToGroupState(controllerGetReportRes, group.controllers);
        dispatch(actions.updateEzloGroupData(groupData));

        // 6. Initialize websocket connections
        const groupControllers = getState().ezlos.items;
        await wsm.initialize(groupControllers, null, null, (message) =>
            dispatch(GenericActions.showError(message, 'Notification')),
        );

        // 7. Fetch controllers info via websockets connections, use only connected controllers
        const connectEzlosArr = groupControllers
            .filter((c) => c.connected)
            .map((controller) => {
                return dispatch(EzloActions.connectEzloController(controller.serial));
            });
        await Promise.all(connectEzlosArr);
        await dispatch(EzloActions.setOnlineControllerList);

        // 8. Select default controller
        await dispatch(EzloActions.selectDefaultController());

        // todo: need to fix routing at first, because redirecting is not properly working
        // 9. Redirect to HOME page
        // dispatch(GenericActions.linkTo(MAIN));

        // 10. Save last used controllers group to localStorage for further usage (after next login)
        setLastUsedControllersGroupUuid(group);

        // 11. Finish connecting process
        dispatch(actions.connectEzloGroupSuccess());
    },

    getControllersExtendedInfo: (serials) => async (dispatch) => {
        const result = await Promise.all(
            serials.map(async (serial) => {
                const extendedInfo = await dispatch(EzloActions.getEzlosInfo(serial));

                return extendedInfo;
            }),
        );

        return result;
    },

    //Hidden for task 3085 called "Remove grouping of hubs on UI".
    // todo: check and apply refactoring
    // initializeDefaultGroupCompatibleMode: () => async (dispatch, getState) => {
    //     // Emulate controllers group

    //     // 1. Fetch available devices for the account
    //     const { PK_Account, PK_AccountChild } = tokenParser(localStorage.getItem(localStorageKeys.IDENTITY));
    //     const currentPK_Account =
    //         PK_Account && PK_AccountChild != PK_ACCOUNT_CHILD_EMPTY ? PK_AccountChild : PK_Account;
    //     const devices = await apiDevices(currentPK_Account, true);

    //     if (devices && devices.length > 0) {
    //         // 2. Select default controller and wrap as group controllers
    //         const [controllerBaseInfo] = devices;
    //         const controllerExtendedInfo = await dispatch(EzloActions.getEzlosInfo(controllerBaseInfo.PK_Device));
    //         const controller = { ...controllerBaseInfo, ...controllerExtendedInfo };
    //         const groupControllers = wrapControllerToGroupControllers(controller);

    //         // 3. Connect to 'emulated' group
    //         const group = { controllers: groupControllers };
    //         await dispatch(actions.connectEzloGroup(group));

    //         // 4. Check and redirect if controller is offline,
    //         const ezloData = getState().ezlo.data;
    //         const ezlo = ezloData && Object.values(ezloData)[0];
    //         if (ezlo && !ezlo.isConnected) {
    //             dispatch(GenericActions.linkTo(GET_OFFLINE_EZLO(ezlo.serial)));
    //         }
    //         dispatch({ type: controllerTypes.SELECT_EZLO_CONTROLLER, serial: controllerBaseInfo.PK_Device });
    //     }
    // },

    // The method changed for task 3085 called "Remove grouping of hubs on UI".
    initializeDefaultGroupCompatibleMode: () => async (dispatch) => {
        // 1. Emulate a group from all account's controllers
        // 1.1. Fetch available devices for the account
        const { PK_Account, PK_AccountChild } = tokenParser(localStorage.getItem(localStorageKeys.IDENTITY));
        const currentPK_Account =
            PK_Account && PK_AccountChild != PK_ACCOUNT_CHILD_EMPTY ? PK_AccountChild : PK_Account;
        const devices = await apiDevices(currentPK_Account, true);

        if (devices && devices.length > 0) {
            // 1.2. Fetch the serial numbers of the controllers
            const serials = devices.map((controller) => controller.PK_Device);

            // 1.3. Fetch info about controllers
            const controllersExtendedInfo = await dispatch(actions.getControllersExtendedInfo(serials));

            //1.4. Find online controller
            const onlineController = controllersExtendedInfo.find(
                (controller) => controller.NMAControllerStatus === NMA_CONTROLLER_STATUS_ONLINE,
            );

            // 1.5. Emulate a group from all account's controllers
            const groupControllers = wrapControllersToGroupControllers(controllersExtendedInfo);

            // 2. Connect to 'emulated' group
            const group = { controllers: groupControllers };
            await dispatch(actions.connectEzloGroup(group));

            // 3. Select controller
            if (onlineController) {
                dispatch({ type: controllerTypes.SELECT_EZLO_CONTROLLER, serial: onlineController.PK_Device });
            }
        }
    },

    connectEzloGroupSuccess: () => ({
        type: types.CONNECT_EZLO_GROUP_SUCCESS,
    }),

    connectEzloGroupError: () => ({
        type: types.CONNECT_EZLO_GROUP_ERROR,
    }),

    disconnectGroupNMA: () => async () => {
        return wsm.closeAll();
    },

    disconnectEzloGroup: () => async (dispatch) => {
        await dispatch(actions.disconnectGroupNMA());
        dispatch(actions.disconnectEzloGroupSuccess());
    },

    disconnectEzloGroupSuccess: () => ({
        type: types.DISCONNECT_EZLO_GROUP_SUCCESS,
    }),

    updateEzloGroupData: (data) => ({
        type: types.UPDATE_EZLO_GROUP_DATA,
        data,
    }),
};

export default actions;
