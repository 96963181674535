import React from 'react';

export const EyeIcon = (props) => (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M27.9998 21.8C30.2998 21.8 32.3998 22.7 33.9998 24.3L37.6998 28L33.9998 31.7C32.3998 33.3 30.1998 34.2 27.9998 34.2C25.7998 34.2 23.5998 33.3 21.9998 31.7L18.2998 28L21.9998 24.3C23.5998 22.7 25.6998 21.8 27.9998 21.8ZM27.9998 18.8C24.9998 18.8 22.0998 20 19.7998 22.2L15.3998 26.6C14.5998 27.4 14.5998 28.6 15.3998 29.4L19.7998 33.9C22.0998 36.2 24.9998 37.3 27.9998 37.3C30.9998 37.3 33.8998 36.2 36.1998 33.9L40.5998 29.5C41.3998 28.7 41.3998 27.5 40.5998 26.7L36.1998 22.3C33.8998 20 30.8998 18.8 27.9998 18.8ZM27.9998 25.1C26.2998 25.1 24.9998 26.4 24.9998 28.1C24.9998 29.8 26.2998 31.1 27.9998 31.1C29.6998 31.1 30.9998 29.8 30.9998 28.1C30.9998 26.4 29.5998 25.1 27.9998 25.1Z"
            fill="#1C1E21"
        />
    </svg>
);

export default EyeIcon;
