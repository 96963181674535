import React, { useState } from 'react';
import { IconButton } from 'lib/@mui';
import { Delete } from 'lib/@mui/Icons';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { ConfirmModal } from '../ConfirmationModal/ConfirmationModal';

import EzloActions from '../../actions/EzloActions';
import MesheneActions from '../../actions/MesheneActions';
import MeshBotAction from '../../actions/MeshBotAction';
import { MESHBOT_TYPES } from '../../containers/Ezlo/EzloMeshbots/constants';

import styles from './MeshbotDelete.module.scss';
import { useTranslate } from '../../features/languages';
import {
    EZLOGIC_TITLE_DELETE,
    EZLOGIC_TITLE_DELETE_MESHBOT,
    EZLOGIC_TITLE_DELETE_MESHBOT_MESSAGE,
    EZLOGIC_TOAST_MESHBOT_SUCCESSFULLY_DELETED,
} from '../../constants/language_tokens';
import { toast, TOAST_TYPE } from '../Toast';
import GenericActions from '../../actions/GenericActions';
import { MESHBOT_TIMEOUT } from '../../constants/MeshbotConstant';

const MeshbotDelete = ({ renderedCellValue: params }) => {
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const { t } = useTranslate();
    const dispatch = useDispatch();

    const handleSelectRule = () => {
        if (!params.isDisabled) {
            setIsConfirmModalVisible(true);
        }
    };

    // todo: refactoring
    const handleDelete = async () => {
        if (params.serial) {
            const rules = {
                _id: params.id,
            };
            await dispatch(EzloActions.deleteRule(params.serial, rules));
        } else if (
            params.type === MESHBOT_TYPES.CLOUD ||
            params.type === MESHBOT_TYPES.CLOUD_NOTIFICATION ||
            params.type === MESHBOT_TYPES.CLOUD_INTERACTION
        ) {
            const data = {
                uuid: params.id,
                delete: 1,
            };
            dispatch(GenericActions.setLineLoading(true));
            const result = await dispatch(MesheneActions.setMeshScene(data, 'delete'));

            if (result && result.data && result.data.complete === 1) {
                toast(t(EZLOGIC_TOAST_MESHBOT_SUCCESSFULLY_DELETED), { type: TOAST_TYPE.SUCCESS });
                setIsConfirmModalVisible(false);
                await dispatch(MesheneActions.getPromiseWithTimeout(MESHBOT_TIMEOUT, MeshBotAction.getCloudMeshBot()));

                return result;
            }
        }
    };

    const handleConfirmation = (isConfirmed) => {
        if (isConfirmed) {
            handleDelete();
        }
        setIsConfirmModalVisible(false);
    };

    return (
        <>
            <div className={styles.meshbotDeleteWrapper}>
                <IconButton
                    color="primary"
                    onClick={handleSelectRule}
                    disabled={params.isDisabled}
                    className={classNames({ [styles.disabled]: params.isDisabled })}
                >
                    <Delete color="primary" />
                </IconButton>
            </div>

            <ConfirmModal
                isOpened={isConfirmModalVisible}
                onClose={setIsConfirmModalVisible}
                title={t(EZLOGIC_TITLE_DELETE_MESHBOT)}
                text={t(EZLOGIC_TITLE_DELETE_MESHBOT_MESSAGE)}
                confirmButtonTitle={t(EZLOGIC_TITLE_DELETE)}
                onCancel={() => handleConfirmation(false)}
                onConfirm={() => handleConfirmation(true)}
            />
        </>
    );
};

export default MeshbotDelete;
