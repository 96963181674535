import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormControl, MenuItem } from 'lib/@mui';

import SelectComponent from '../../../../../EzloMeshbot/components/SelectComponent';

import { systemHistoryActions } from '../../../actions';
import { EVENT_TYPES } from '../../../constants';

import styles from './systemHistorySearchByEventType.module.scss';
import { useTranslate } from 'features/languages';

const SystemHistorySearchByEventType = () => {
    const dispatch = useDispatch();
    const [selectedEventType, setSelectedEventType] = useState(0);
    const { t } = useTranslate();

    const onChange = (value) => {
        setSelectedEventType(value);
        dispatch(systemHistoryActions.setHistorySearchByEventType(value));
    };

    return (
        <FormControl className={styles.searchByEventWrapper}>
            <SelectComponent value={selectedEventType} onChange={(e) => onChange(e.target.value)}>
                {EVENT_TYPES.map((event) => {
                    return (
                        <MenuItem key={event.value} value={event.value}>
                            <div>{t(event.label)}</div>
                        </MenuItem>
                    );
                })}
            </SelectComponent>
        </FormControl>
    );
};

export default SystemHistorySearchByEventType;
