export const GATEWAY_METHOD_EVENTS = {
    STARTED: 'started',
    STOPPED: 'stopped',
    FAILED: 'failed',
    DONE: 'done',
    TIMEOUTED: 'timeout',
    LEARN_STARTED: 'controller_learn_started',
    LEARN_STARTED_ERR: 'controller_learn_starting_error',
    LEARN_FINISHED: 'controller_learn_finished',
    LEARN_FINISHED_ERR: 'controller_learn_finished_error',
    S2_SEND_FULL_DSK: 's2_send_full_dsk',
    S2_SELECT_UI: 's2_this_ui',
};

export const GATEWAY_METHOD_NAMES = {
    REPLACE: 'replace_failed_node',
    START_EXCLUDE: 'start_exclude',
    STOP_EXCLUDE: 'stop_exclude',
    REDISCOVER_NETWORK: 'rediscover_network',
    RESET: 'hard_reset',
    LEARN_STOP: 'controller_learn_stop',
};

/**
 * Statuses for 'hub.gateway.updated' broadcast
 * */
export const HUB_GATEWAY_UPDATED_STATUSES = {
    NOT_STARTED: 'not_started',
    STARTING: 'starting',
    READY: 'ready',
};

export const HUB_GATEWAY_THROTTLE_TIME = 5 * 1000;
