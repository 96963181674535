import { languageService } from '../services/language';

// const LANGUAGE_TO_DIRECTORY_NAME_CONTAINER = {
//     en_US: 'en',
//     ru_RU: 'ru',
//     zh_CN: 'zh',
//     tr_TR: 'tr',
// };

/**
 * Returns directory name for translations.json file by language
 * @param {string} language - current language
 * @returns {string} directory name
 * */
// const getLocalesDirectoryName = (language) => {
//     return LANGUAGE_TO_DIRECTORY_NAME_CONTAINER[language] || 'en';
// };

/**
 * Dynamically import translations.json file based on current language
 * @returns {Promise<Object>} locales object
 * */
const fetchLocales = async () => {
    // todo: caching
    const language = languageService.getLanguageKey();

    return import(`../locales/${language}/translation.json`);
};

/**
 * Save locales in 'window'. Legacy approach.
 * @param {Object} payload - response from /localization API
 * */
const saveLocales = (payload) => {
    window.translationData = payload;
};

/**
 * Fetch and register locales on client side.
 * @return {Promise}
 * */
export const registerLocales = async () => {
    const localesPayload = await fetchLocales();
    saveLocales(localesPayload);
};
