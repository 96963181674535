import React from 'react';

const IcTabbarDeviceSecureB = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.07837 24.1805C2.85572 23.351 2.81061 24.6936 4.17093 25.6742C6.17757 27.1214 10.6396 27.7605 15.9681 27.766H16.0319C21.3604 27.7605 25.8224 27.1214 27.8291 25.6742C29.1894 24.6936 29.1443 23.351 27.9216 24.1805C25.929 25.5341 22.261 25.9937 16 26C9.73897 25.9937 6.07102 25.5341 4.07837 24.1805ZM4.12271 22.1798C6.10835 23.4499 9.7623 23.9937 16 24C22.2377 23.9937 25.8917 23.4499 27.8773 22.1798C29.2252 21.318 29.8435 19.8582 29.9858 18.1174C30.0442 17.4059 29.9928 12.625 28.4202 9.82418C27.3842 7.97871 25.1979 6.99971 23.9729 6.47952C22.0191 5.65083 18.9921 5.09286 16 5C13.0079 5.09286 9.98086 5.65083 8.0271 6.47952C6.80212 6.99971 4.61581 7.97871 3.57982 9.82418C2.00718 12.625 1.95585 17.4059 2.01418 18.1174C2.15651 19.8582 2.77484 21.318 4.12271 22.1798ZM16 21C17.1046 21 18 20.5523 18 20C18 19.4477 17.1046 19 16 19C14.8954 19 14 19.4477 14 20C14 20.5523 14.8954 21 16 21Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcTabbarDeviceSecureB;
