import React from 'react';
import ReactDOM from 'react-dom/client';
import AppRoot from './AppRoot';
import { registerLocales } from './api/apiLocales';
import { initializeEzlogic } from './features/app';

const rootElement = ReactDOM.createRoot(document.getElementById('app'));

// Render the React application to the DOM
function startApp() {
    return rootElement.render(<AppRoot />);
}

initializeEzlogic()
    .then(async () => await registerLocales())
    .then(startApp);
