import React from 'react';

import MeshbotSerial from '../../../components/MeshbotSerial';
import MeshbotSwitch from '../../../components/MeshbotSwitch';
import MeshbotEditRule from '../../../components/MeshbotEditRule';
import MeshbotDelete from '../../../components/MeshbotDelete';
import MeshBotNameCell from './components/MeshBotNameCell/MeshBotNameCell';

import { HIDE } from '../EzloCustomization/constants';
import MeshbotDuplicate from '../../../components/DuplicateMeshbot';
import {
    EZLOGIC_TITLE_ACTIVE,
    EZLOGIC_TITLE_CHECKBOX_SELECTION,
    EZLOGIC_TITLE_DELETE,
    EZLOGIC_TITLE_DUPLICATE,
    EZLOGIC_TITLE_EDGE_COMPUTER,
    EZLOGIC_TITLE_EDIT,
    EZLOGIC_TITLE_GROUP_NAME,
    EZLOGIC_TITLE_ID_MESHBOT,
    EZLOGIC_TITLE_MESHBOT_NAME,
    EZLOGIC_TITLE_MESHBOT_TYPE,
    EZLOGIC_TITLE_RUN_ONCE,
} from '../../../constants/language_tokens';
import MeshbotType from 'components/MeshbotType';
import { isArray, isNumber } from 'lodash';
import {
    MESHBOT_TYPES,
    SYSTEM,
    INITIAL_COLUMN_VISIBILITY,
    meshBotsTypeCellConfig,
    CONTROLLER_MODELS,
    GET_CONTROLLERS_CONFIG_KEYS,
    BOOLEAN,
    NOT_GROUP,
} from './constants';
import { getMeshBotLabelsUuidsFromMeshBot } from 'features/Labels/MeshBotsLabels/utils';
import MeshbotRunWrapper from 'components/MeshbotRun/MeshbotRunWrapper';
import { DATA_MESHBOT_ATTRIBUTE, ZERO_INT } from 'constants/MeshbotConstant';
import { t } from 'helpers/language';
import { SELECTION_COLUMN_ID, TABLE_DENSITY_OPTIONS } from 'lib/material-react-table';
import { DENSITY_OPTIONS, SELECTION_COLUMN_FIELD } from 'lib/@mui';
import { COLUMN_SIZING, COLUMN_VISIBILITY, DENSITY, SHOW_SYSTEM_MESHBOTS } from 'services/kvs';
import { isControllerTypeMatch } from '../EzloGroups/utils';
import { UNSUPPORTED_PAGES_URLS } from 'constants/URLs';

/**
 * Retrieves the configuration for a Meshbot type based on the provided Meshbot data.
 *
 * @param {Object} meshBotData - Data of Meshbot.
 * @returns {Object|null} Configuration for the Meshbot type, or null if not found.
 */
export const getMeshBotTypeCellConfig = (meshBotData) => {
    if (!meshBotData) {
        throw new Error('missing meshBotData in getMeshBotTypeCellConfig fn. meshBotData is required');
    }

    if (meshBotData?.lastModifiedBy === SYSTEM) {
        return meshBotsTypeCellConfig[SYSTEM];
    }

    if (meshBotsTypeCellConfig?.hasOwnProperty(meshBotData.type)) {
        return meshBotsTypeCellConfig[meshBotData.type];
    }

    return null;
};

/**
 * Retrieves the value used for sorting in the MeshBot Type column.
 *
 * @param {Object} param - The parameter object containing information about the row and API.
 * @param {Object} param.api - The API object.
 * @param {string} param.id - The ID of the row.
 * @returns {string} The value to be used for sorting in the MeshBot Type column.
 */
function getValueForSort(param) {
    const row = param.api.getRow(param.id);
    const cellConfig = getMeshBotTypeCellConfig(row);

    if (cellConfig) {
        return t(cellConfig.tableTitleKey);
    }

    return row.type;
}

/**
 * Comparator function for sorting values in the MeshBot Type column.
 *
 * @param {any} v1 - The first value to compare.
 * @param {any} v2 - The second value to compare.
 * @param {Object} param1 - The parameter object for the first value.
 * @param {Object} param2 - The parameter object for the second value.
 * @returns {number} A number indicating the sorting order (-1, 0, or 1).
 */
export function sortComparatorOfMeshBotTypeColumn(v1, v2, param1, param2) {
    const typeValue1 = getValueForSort(param1);
    const typeValue2 = getValueForSort(param2);

    return typeValue1.localeCompare(typeValue2);
}

/**
 * MeshBots table columns initial config
 * More info you can find here {@link https://confluence.mios.com/display/PDMT/PRD+Meshbot+CRUD PRD}
 * */
export const meshBotsTableColumnsInitialConfig = [
    {
        columnName: '__check__',
        headerName: EZLOGIC_TITLE_CHECKBOX_SELECTION,
        width: 50,
        hide: false,
        field: '__check__',
        headerAlign: 'center',
        align: 'center',
        sortable: false,
    },
    {
        field: 'id',
        headerAlign: 'center',
        headerName: EZLOGIC_TITLE_ID_MESHBOT,
        minWidth: 340,
        sortable: false,
        disableColumnMenu: true,
        hide: true,
        disableSelectionOnClick: true,
    },
    {
        field: 'name',
        headerAlign: 'center',
        headerName: EZLOGIC_TITLE_MESHBOT_NAME,
        headerClassName: 'grid-field',
        minWidth: 400,
        hide: false,
        disableSelectionOnClick: true,
        renderCell: (params) => <MeshBotNameCell params={params.row} />,
    },
    {
        field: 'groupId',
        headerAlign: 'center',
        headerName: EZLOGIC_TITLE_GROUP_NAME,
        headerClassName: 'grid-field',
        minWidth: 168,
        disableSelectionOnClick: true,
        hide: true,
    },
    {
        field: 'type',
        headerAlign: 'left',
        align: 'left',
        headerName: EZLOGIC_TITLE_MESHBOT_TYPE,
        headerClassName: 'grid-field',
        minWidth: 184,
        hide: false,
        disableSelectionOnClick: true,
        renderCell: (params) => {
            return <MeshbotType params={params} />;
        },
        sortComparator: sortComparatorOfMeshBotTypeColumn,
    },
    {
        // width:'10%',
        field: 'serial',
        headerAlign: 'center',
        headerName: EZLOGIC_TITLE_EDGE_COMPUTER,
        headerClassName: 'grid-field',
        minWidth: 120,
        hide: false,
        sortable: false,
        disableSelectionOnClick: true,
        renderCell: (params) => {
            return <MeshbotSerial params={params.row} />;
        },
    },
    {
        field: 'active',
        headerAlign: 'center',
        headerName: EZLOGIC_TITLE_ACTIVE,
        headerClassName: 'grid-empty-field grid-field',
        width: 90,
        sortable: false,
        hide: false,
        disableColumnMenu: true,
        disableClickEventBubbling: true,
        disableSelectionOnClick: true,
        renderCell: (params) => {
            return <MeshbotSwitch params={params.row} />;
        },
    },
    {
        field: 'run',
        headerAlign: 'center',
        headerName: EZLOGIC_TITLE_RUN_ONCE,
        headerClassName: 'grid-empty-field grid-field',
        width: 112,
        hide: false,
        sortable: false,
        disableColumnMenu: true,
        disableClickEventBubbling: true,
        disableSelectionOnClick: true,
        renderCell: (params) => {
            return <MeshbotRunWrapper params={params.row} />;
        },
    },
    {
        field: 'edit',
        headerAlign: 'center',
        headerName: EZLOGIC_TITLE_EDIT,
        headerClassName: 'grid-empty-field grid-field',
        width: 90,
        sortable: false,
        hide: false,
        disableColumnMenu: true,
        disableClickEventBubbling: true,
        disableSelectionOnClick: true,
        renderCell: (params) => {
            return <MeshbotEditRule params={params.row} />;
        },
    },
    {
        field: 'delete',
        headerAlign: 'center',
        headerName: EZLOGIC_TITLE_DELETE,
        headerClassName: 'grid-empty-field grid-field',
        width: 90,
        sortable: false,
        hide: false,
        disableColumnMenu: true,
        disableClickEventBubbling: true,
        disableSelectionOnClick: true,
        renderCell: (params) => {
            return <MeshbotDelete params={params.row} />;
        },
    },
    {
        field: 'duplicate',
        headerAlign: 'center',
        headerName: EZLOGIC_TITLE_DUPLICATE,
        headerClassName: 'grid-empty-field grid-field',
        width: 105,
        sortable: false,
        hide: false,
        disableColumnMenu: true,
        disableClickEventBubbling: true,
        disableSelectionOnClick: true,
        renderCell: (params) => {
            return <MeshbotDuplicate params={params.row} />;
        },
    },
];

/**
 * Returns array for MeshBot listing Ui
 * @param {array} listingColumn - list data of meshbot columns
 *  @param {array} updatedListingUIColumns - list data of meshbot column customization
 * @returns {array} array of meshbot columns.
 * */

export const getUpdatedListingUiColumns = (listingColumn, updatedListingUIColumns) => {
    listingColumn?.filter((meshbotTableColumnItem) => {
        updatedListingUIColumns?.some((updatedListColumnItem) => {
            if (meshbotTableColumnItem?.field === updatedListColumnItem?.field) {
                meshbotTableColumnItem[HIDE] = updatedListColumnItem?.hide;
            }
        });
    });

    return listingColumn;
};

/**
 * Collects cloud mesbots in the format needed to display them in the table;
 * @param {array} cloudMeshs - list of cloud MeshBots from cloud side
 * @returns {array} array of cloud mesbots in the format needed to display them in the table
 * */
export const createCloudMeshBotForTable = (cloudMeshs) => {
    if (!cloudMeshs || !isArray(cloudMeshs)) {
        return [];
    }

    return cloudMeshs.map((item) => {
        return {
            id: item.uuid,
            enabled: Boolean(item.enabled),
            groupId: 'not group',
            name: item.name,
            type: item?.meta?.type || MESHBOT_TYPES.CLOUD,
            serial: '',
            labelsUuids: getMeshBotLabelsUuidsFromMeshBot(item) || [],
            lastModifiedBy: item?.last_modified_by_entity_type,
        };
    });
};

export const getUpdatedCloudMeshbots = (cloudMeshbots, checked) => {
    if (!Array.isArray(cloudMeshbots) || cloudMeshbots?.length === ZERO_INT) {
        return [];
    }

    if (checked) {
        return cloudMeshbots;
    }

    return cloudMeshbots?.filter((meshbot) => !(meshbot?.lastModifiedBy === SYSTEM));
};

/**
 * Builds a data structure that organizes selected meshbots by their types, based on their IDs.
 *
 * @param {Array} selectedMeshBotsIds - An array of IDs representing the selected meshbots.
 * @param {Array} allMeshbots - An array of all available meshbots with all data.
 * @returns {Object} An object containing selected mesh bots grouped by their types.
 *
 * @example
 * const selectedIds = [1, 3, 5];
 * const allMeshbots = [
 *     { id: 1, serial: "ABC", name: "Bot1", labelsUuids: [101, 102], type: "TypeA" },
 *     { id: 2, serial: "DEF", name: "Bot2", labelsUuids: [103, 104], type: "TypeB" },
 *     { id: 3, serial: "GHI", name: "Bot3", labelsUuids: [105, 106], type: "TypeA" },
 *     // ...
 * ];
 *
 * const selectedMeshBotsData = buildSelectedMeshBotsDataByType(selectedIds, allMeshbots);
 * console.log(selectedMeshBotsData);
 * // Output: {
 * //   "TypeA": {
 * //     1: { id: 1, serial: "ABC", name: "Bot1", labelsUuids: [101, 102] },
 * //     3: { id: 3, serial: "GHI", name: "Bot3", labelsUuids: [105, 106] }
 * //   },
 * //   // ...
 * // }
 */
export const buildSelectedMeshBotsDataByType = (selectedMeshBotsIds, allMeshbots) => {
    return allMeshbots.reduce((prevState, currentMeshBot) => {
        if (selectedMeshBotsIds.includes(currentMeshBot.id)) {
            const { id, serial, name, labelsUuids, type } = currentMeshBot;
            prevState[type] = {
                ...prevState[type],
                [id]: { id, serial, name, labelsUuids },
            };
        }

        return prevState;
    }, {});
};
/**
 * Prepare MeshBots by type for query
 * @param {Object} selectedMeshBotsByType - selected MeshBots by type (MeshBots` list in Object format)
 * @returns {{localMeshBots: Object, cloudMeshBots: Object}} MeshBots by type for query
 * */
export const prepareMeshBotsByTypeForQuery = (selectedMeshBotsByType) => {
    return {
        localMeshBots: { ...selectedMeshBotsByType[MESHBOT_TYPES.LOCAL] },
        cloudMeshBots: {
            ...selectedMeshBotsByType[MESHBOT_TYPES.CLOUD],
            ...selectedMeshBotsByType[MESHBOT_TYPES.CLOUD_NOTIFICATION],
            ...selectedMeshBotsByType[MESHBOT_TYPES.CLOUD_INTERACTION],
        },
    };
};

export const getMeshBotDropZoneAttributes = (params) => ({ [DATA_MESHBOT_ATTRIBUTE]: JSON.stringify(params) });

/**
 * Retrieves the pagination count for a MeshBots table or returns the total count of all MeshBots if unavailable.
 *
 * @param {object|null} meshBotsTableState - The state of the MeshBots table, including pagination information.
 * @param {number} meshBotsTableState.pagination.rowCount - The number of rows per page in the table.
 * @param {Array} allMeshBots - An array containing all MeshBots.
 * @returns {number} The pagination count if available; otherwise, the total count of all MeshBots.
 */
export const getMeshBotPaginationCount = (meshBotsTableState, allMeshBots) => {
    const count = meshBotsTableState?.pagination?.rowCount;

    if (!isNumber(count)) {
        return allMeshBots.length;
    }

    return count;
};

/**
 * Get the column configuration for mesh bots based on the provided settings from kvs.
 *
 * This function constructs the column configuration object for mesh bots, including column visibility and sizing
 * settings, based on the input configuration. It defaults to an initial configuration, which can be customized
 * with specific column data, visibility, and sizing settings from the provided configuration object.
 *
 * @param {Object} kvsMeshBotsPageConfig - A key-value configuration object for mesh bot settings.
 * @returns {Object} An object containing column visibility and sizing settings.
 * @property {Object} columnVisibility - Configuration for column visibility settings.
 * @property {Object} columnSizing - Configuration for column sizing settings.
 *
 * @example
 * const kvsMeshBotsPageConfig = {
 *   'columnData': [
 *     { field: 'id', hide: false, width: 100 },
 *     { field: 'name', hide: true, width: 150 },
 *     // Other column data settings
 *   ],
 *   'columnVisibility': { 'id': true, 'name': false },
 *   'columnSizing': { 'id': 120, 'name': 160 },
 *   // Other key-value settings
 * };
 * const columnConfig = getColumnConfig(kvsMeshBotsPageConfig);
 * // Result:
 * // {
 * //   columnVisibility: { 'id': true, 'name': false, 'mrt-row-select': true },
 * //   columnSizing: { 'id': 100, 'name': 150, 'mrt-row-select': 50 },
 * // }
 */
const getColumnConfig = (kvsMeshBotsPageConfig = {}) => {
    let columnVisibility = INITIAL_COLUMN_VISIBILITY;
    let columnSizing = {};
    if (kvsMeshBotsPageConfig.columnData) {
        kvsMeshBotsPageConfig.columnData.forEach((column) => {
            let key = column.field;
            if (key === SELECTION_COLUMN_FIELD) {
                key = SELECTION_COLUMN_ID;
            }
            columnVisibility[key] = !column.hide;
            columnSizing[key] = column.width;
        });
    }

    if (kvsMeshBotsPageConfig[COLUMN_VISIBILITY]) {
        columnVisibility = kvsMeshBotsPageConfig[COLUMN_VISIBILITY];
    }

    if (kvsMeshBotsPageConfig[COLUMN_SIZING]) {
        columnSizing = kvsMeshBotsPageConfig[COLUMN_SIZING];
    }

    return { columnVisibility, columnSizing };
};

/**
 * Get the density configuration value for a mesh bot page based on provided settings.
 *
 * This function determines the density configuration value to be used for the mesh bot page based on the input
 * configuration. It first checks if there is a specific density value set, and if not, falls back to the default
 * TABLE_DENSITY_OPTIONS.COMPACT.
 *
 * @param {Object} kvsMeshBotsPageConfig - A key-value configuration object for mesh bot settings.
 * @returns {string} The density configuration value for the mesh bot page.
 *
 * @example
 * const kvsMeshBotsPageConfig = {
 *   'densityValue': 'comfortable',
 *   // Other key-value settings
 * };
 * const densityConfig = getDensityConfig(kvsMeshBotsPageConfig);
 * // Result: 'comfortable'
 */
const getDensityConfig = (kvsMeshBotsPageConfig = {}) => {
    if (kvsMeshBotsPageConfig.densityValue && kvsMeshBotsPageConfig.densityValue !== DENSITY_OPTIONS.STANDARD) {
        return kvsMeshBotsPageConfig.densityValue;
    }

    if (kvsMeshBotsPageConfig[DENSITY]) {
        return kvsMeshBotsPageConfig[DENSITY];
    }

    return TABLE_DENSITY_OPTIONS.COMPACT;
};

/**
 * Generate a UI mesh bot configuration object based on key-value settings(kvs).
 *
 * @param {Object} kvsMeshBotsPageConfig - A key-value configuration object for mesh bot settings.
 * @returns {Object} A UI mesh bot configuration object with specific properties.
 * @property {boolean} showSystemMeshBots - Indicates whether to show system mesh bots.
 * @property {Object} columnVisibility - Configuration for column visibility settings.
 * @property {Object} columnSizing - Configuration for column sizing settings.
 * @property {string} density - Configuration for density settings.
 *
 * @example
 * const kvsMeshBotsPageConfig = {
 *   'showSystemMeshBots': true,
 *   'density': 'comfortable',
 *   'columnVisibility': { // Column visibility settings  },
 *   'columnSizing': { // Column sizing settings },
 * };
 * const uiMeshBotConfig = generateUiMeshBotConfig(kvsMeshBotsPageConfig);
 * // Result:
 * // {
 * //   showSystemMeshBots: true,
 * //   columnVisibility: { // Column visibility settings  },
 * //    columnSizing: { // Column sizing settings },
 * //    density: 'comfortable',
 * // }
 **/
export const generateUiMeshBotConfig = (kvsMeshBotsPageConfig) => {
    const columnConfig = getColumnConfig(kvsMeshBotsPageConfig);

    return {
        [COLUMN_VISIBILITY]: columnConfig[COLUMN_VISIBILITY],
        [COLUMN_SIZING]: columnConfig[COLUMN_SIZING],
        [DENSITY]: getDensityConfig(kvsMeshBotsPageConfig),
        [SHOW_SYSTEM_MESHBOTS]: kvsMeshBotsPageConfig[SHOW_SYSTEM_MESHBOTS] || false,
    };
};

/**
 * Get an object representing the row selection state for existing mesh bots.
 *
 * @param {Object} rowSelection - An object representing the row selection state.
 * @param {Array} allMeshbots - An array of mesh bot objects.
 * @returns {Object} An object where keys are mesh bot IDs and values indicate their selection state.
 * @example
 * const rowSelection = {
 *   'botId1': true,
 *   'botId2': true,
 *   // ...
 * };
 * const allMeshbots = [
 *   { id: 'botId1', //...other properties },
 *   { id: 'botId2', //...other properties },
 *
 * ];
 * const selection = getExistMeshBotsRowSelection(rowSelection, allMeshbots);
 * // Result:
 * // {
 * //   'botId1': true,
 * //   'botId2': true,
 * //   // ...
 * // }
 **/
export const getExistMeshBotsRowSelection = (rowSelection, allMeshbots) => {
    const selectedMeshBotsIds = Object.keys(rowSelection);

    return allMeshbots.reduce((ids, { id }) => {
        if (selectedMeshBotsIds.includes(id)) {
            ids[id] = true;
        }

        return ids;
    }, {});
};

/**
 * This function checks that the controller matches a configuration key called "isOnline"
 * @param {object} controller - controller
 * @param {boolean} isOnline - state of the controller
 * @returns {boolean}
 */
export const isControllerOnline = (controller, isOnline) => {
    if (!controller || typeof isOnline !== BOOLEAN) {
        return;
    }

    return controller?.isConnected === isOnline;
};

export const FILTER_CONTROLLERS_BY_CONFIG_FUNCTION_CONTAINER = {
    [GET_CONTROLLERS_CONFIG_KEYS.TYPE]: isControllerTypeMatch,
    [GET_CONTROLLERS_CONFIG_KEYS.IS_ONLINE]: isControllerOnline,
};

/**
 * This function returns filtered controllers by configuration
 * @param {array} controllers - all the controllers of the account
 * @param {object} config - configuration for the filtering of the controllers
 * @returns {array} - filtered the controllers
 */
export const getControllersByConfig = (controllers, config) => {
    let filteredControllers = controllers;
    const configKeys = Object.keys(config);

    for (const configKey of configKeys) {
        const filterFn = FILTER_CONTROLLERS_BY_CONFIG_FUNCTION_CONTAINER[configKey];

        if (filterFn) {
            filteredControllers = filteredControllers.filter((controller) => filterFn(controller, config[configKey]));
        }
    }

    return filteredControllers;
};

/**
 * This function returns object of arrays of local and ezlopi MeshBots for MeshBot table
 * @param {array} rules - list of local and ezlopi MeshBots
 * @returns {object} - object of arrays
 */
export const createMeshBotForTableCombined = (rules) => {
    const localMeshBotsForTable = [];
    const ezlopiMeshBotsForTable = [];

    rules.forEach((item) => {
        const meshBotItem = {
            id: item._id,
            enabled: item.enabled,
            groupId: item.group_id ? item.group_id : NOT_GROUP,
            name: item.name,
            serial: item.serial,
            isDisabled: !item.isConnected,
            labelsUuids: getMeshBotLabelsUuidsFromMeshBot(item) || [],
        };

        if (isControllerTypeMatch(item, CONTROLLER_MODELS.EZLOPI)) {
            ezlopiMeshBotsForTable.push({
                ...meshBotItem,
                type: MESHBOT_TYPES.EZLOPI,
            });
        } else {
            localMeshBotsForTable.push({
                ...meshBotItem,
                type: MESHBOT_TYPES.LOCAL,
            });
        }
    });

    return {
        localMeshBotsForTable,
        ezlopiMeshBotsForTable,
    };
};
export const sortByUuid = (a, b) => a.uuid.localeCompare(b.uuid);
export const sortById = (a, b) => a._id.localeCompare(b._id);

/**
 * This function checks if the current page is supported by "ezlopi" controllers
 * @param {string} currentUrl - the current page URL
 * @returns {boolean}
 */
export const isPageNotSupportedByEzlopi = (currentUrl) => {
    return UNSUPPORTED_PAGES_URLS.includes(currentUrl);
};
