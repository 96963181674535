import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import oemManager from '../../services/oem';
import { WEBUI_VERSION } from '../../constants/App';
import { NAME } from '../../constants/localStorageKeys';
import { BUGSNAG_ENABLED_BREADCRUMB_TYPES, BUGSNAG_METADATA_KEYS } from './constants';

/**
 * Returns Bugsnag config validation result
 * @param {object} config - bugsnag config
 * @returns validation result
 * */
const isBugsnagConfigValid = (config) => {
    if (config && config.apiKey) {
        return true;
    }

    return false;
};

const startBugsnag = (config) => {
    Bugsnag.start({
        appVersion: WEBUI_VERSION,
        plugins: [new BugsnagPluginReact()],
        releaseStage: window.location.hostname,
        enabledBreadcrumbTypes: BUGSNAG_ENABLED_BREADCRUMB_TYPES,
        onError: function (event) {
            event.setUser(localStorage.getItem(NAME), '', localStorage.getItem(NAME));
            event.addMetadata(BUGSNAG_METADATA_KEYS.APP_INFO, {
                url: window.location.href,
                env: oemManager?.getEnvironmentKey(),
                appVersion: WEBUI_VERSION,
            });
        },
        ...config,
    });
};

/**
 * Used to determine Bugsnag initialization state
 * */
export let isBugsnagInitialized = false;

/**
 * Initialize Bugsnag instance.
 * @param {object} config - bugsnag config
 * @return {boolean} initialization result
 * */
export const initializeBugsnag = (config) => {
    if (!isBugsnagConfigValid(config)) {
        isBugsnagInitialized = false;

        return false;
    }

    startBugsnag(config);

    isBugsnagInitialized = true;

    return true;
};
