import { PAGINATION_ARROWS } from 'constants/App';
import { ALERT_HISTORY, CURRENT_PAGE, SELECTED_DATE_TIMESTAMP_IN_MS } from './constants';
import { convertTimestampMsToSec } from 'services/utilityService';
import { IDS } from 'services/alertHistory';
import { isArray, isNumber } from 'lodash';

/**
 * Calculates the timestamp representing the end of the day based on the given start of the day timestamp.
 *
 * @param {number} startOfDayTimestamp - The timestamp representing the start of the day.
 * @returns {number} The timestamp representing the end of the day.
 */
export const getEndOfDayTimestamp = (startOfDayTimestamp) => {
    // Calculate the total milliseconds in a day
    const millisecondsInDay = 24 * 60 * 60 * 1000;

    // Calculate the end of the day timestamp by adding milliseconds in a day
    return startOfDayTimestamp + millisecondsInDay;
};
/**
 * Calculates the page number based on the arrow type and the current page.
 *
 * @param {string} type - The type of arrow ('prev' or 'next').
 * @param {number} currentPage - The current page number.
 * @returns {number} The calculated page number.
 */
export const calculatePageNumberByArrowType = (type, currentPage) => {
    if (type === PAGINATION_ARROWS.PREV && currentPage !== 0) {
        return currentPage - 1;
    }

    if (type === PAGINATION_ARROWS.NEXT) {
        return currentPage + 1;
    }

    return currentPage;
};
/**
 * Calculates the page number based on the provided parameters.
 *
 * @param {Object} options - The options object containing pageType and pageNumber.
 * @param {string} options.pageType - The type of page navigation ('prev' or 'next').
 * @param {number} [options.pageNumber] - The specific page number to navigate to (optional).
 * @param {number} currentPage - The current page number.
 * @returns {number} The calculated page number.
 */
export const calculatePageNumber = ({ pageType, pageNumber }, currentPage) => {
    if (isNumber(pageNumber)) {
        return pageNumber;
    }

    return calculatePageNumberByArrowType(pageType, currentPage);
};
/**
 * Retrieves the timestamp representing the start of the current day (00:00:00).
 *
 * @returns {number} The Unix timestamp representing the start of the current day in milliseconds.
 */
export const getStartOfCurrentDayTimestamp = () => {
    // Get the current date and time
    const currentDate = new Date();

    // Set the time to midnight (00:00:00)
    currentDate.setHours(0, 0, 0, 0);

    // Get the Unix timestamp in milliseconds
    return currentDate.getTime();
};

/**
 * Retrieves parameters from the state and payload to construct an object.
 *
 * @param {Object} state - The state object containing relevant data.
 * @param {Object} payload - The payload object containing additional data.
 * @returns {Object} An object containing parameters extracted from the state and payload.
 */
export const getParamsFromState = (state, payload) => {
    const serial = state?.ezlo?.serial;
    const { controller_uuid } = state?.ezlos?.items?.find((item) => item.serial === serial) || {};
    const selectedDateTimestamp = state[ALERT_HISTORY][SELECTED_DATE_TIMESTAMP_IN_MS];
    const currentPage = state[ALERT_HISTORY][CURRENT_PAGE];

    return {
        timestamp_start: convertTimestampMsToSec(selectedDateTimestamp),
        timestamp_end: convertTimestampMsToSec(getEndOfDayTimestamp(selectedDateTimestamp)),
        list: [{ source: controller_uuid }],
        page: calculatePageNumber(payload, currentPage),
    };
};
/**
 * Filters events by category based on the selected category ID.
 *
 * @param {Array<HistoryEvent>} eventList - The list of events to filter.
 * @param {string} selectedCategoryId - The selected category ID.
 * @returns {Array<HistoryEvent>} The filtered list of events.
 */
export const filterEventsByCategory = (eventList, selectedCategoryId) => {
    if (selectedCategoryId === IDS.ALL_ACTIONS) {
        return eventList;
    }

    return eventList.filter((event) => event?.uiData?.categoryId === selectedCategoryId);
};

/**
 * Filters events by search value.
 *
 * @param {Array<HistoryEvent>} eventList - The list of events to filter.
 * @param {string} searchValue - The search value to filter events by.
 * @returns {Array<HistoryEvent>} The filtered list of events.
 */
export const filterEventsBySearchValue = (eventList, searchValue) => {
    if (!searchValue) {
        return eventList;
    }

    return eventList.filter((event) => {
        const { description, time } = event?.uiData || {};
        const collectedDataInString = `${description}  ${time}`;

        return collectedDataInString.toLowerCase().includes(searchValue.toLowerCase());
    });
};
/**
 * Filters events based on selected category ID and search value.
 *
 * @param {Array<HistoryEvent>} eventList - The list of events to filter.
 * @param {string} selectedCategoryId - The selected category ID to filter events by.
 * @param {string} searchValue - The search value to filter events by.
 * @returns {Array<HistoryEvent>} The filtered list of events.
 */
export const filterEvents = (eventList, selectedCategoryId, searchValue) => {
    const filteredEventsByCategory = filterEventsByCategory(eventList, selectedCategoryId);

    return filterEventsBySearchValue(filteredEventsByCategory, searchValue);
};
export const getSortedListByDate = (alertHistoryList) => {
    if (!isArray(alertHistoryList)) {
        return [];
    }

    return [...alertHistoryList].sort((a, b) => b.cloudData.timestamp_ms - a.cloudData.timestamp_ms);
};
