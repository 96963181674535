import { useSelector } from 'react-redux';
import {
    addNewLabelsUuids,
    buildMeshBotLabelsInArrayFormat,
    kvsMeshBotLabelsSelector,
    getNewAddedLabelsSelector,
} from '../../utils';
import { useMemo } from 'react';

const useMeshBotLabelsState = (labelsUuids, meshBotUuid) => {
    const { labels } = useSelector(kvsMeshBotLabelsSelector);
    const { newAddedLabels } = useSelector(getNewAddedLabelsSelector(meshBotUuid));

    return useMemo(() => {
        const allLabelsUuids = addNewLabelsUuids(labelsUuids, newAddedLabels);

        return buildMeshBotLabelsInArrayFormat(allLabelsUuids, labels);
    }, [labels, labelsUuids, newAddedLabels]);
};

export default useMeshBotLabelsState;
