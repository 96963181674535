import React from 'react';

export const EzloOfflineIcon = (props) => {
    return (
        <svg viewBox="0 0 118 118" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M23.998 10.995C15.161 10.995 7.99799 18.158 7.99799 26.995V55.995V99.995C7.99799 106.622 13.371 111.995 19.998 111.995H99.998C106.625 111.995 111.998 106.622 111.998 99.995V55.995H23.998V10.995ZM111.998 26.995V39.995H95.998V10.995C104.835 10.995 111.998 18.158 111.998 26.995Z" />
            <path d="M11.743 117.877L0.429993 106.564L95.998 10.995L106.253 0.73999L117.567 12.053L11.743 117.877Z" />
            <path d="M19.998 111.995H40.252L57.811 94.4351C52.969 93.4261 49.331 89.1361 49.331 83.9951C49.331 82.2231 49.769 80.5561 50.534 79.0861L17.83 111.79C18.535 111.918 19.257 111.995 19.998 111.995Z" />
            <path d="M95.998 39.995H111.998V26.995C111.998 24.283 111.318 21.731 110.127 19.493L95.998 33.622V39.995Z" />
            <path d="M73.625 55.995L46.401 83.22C47.871 82.456 38.144 93.676 39.916 93.676C45.058 93.676 48.778 97.619 49.787 102.461L96.253 55.995H73.625Z" />
            <path d="M59.998 94.158C65.6109 94.158 70.161 89.6079 70.161 83.995C70.161 78.3822 65.6109 73.832 59.998 73.832C54.3851 73.832 49.835 78.3822 49.835 83.995C49.835 89.6079 54.3851 94.158 59.998 94.158Z" />
        </svg>
    );
};

export default EzloOfflineIcon;
