import React from 'react';

export const ImageEvent = () => (
    <svg viewBox="0 0 32 32" fill="none">
        <path
            d="M25 4H7C5.3 4 4 5.3 4 7V25C4 26.7 5.3 28 7 28H25C26.7 28 28 26.7 28 25V7C28 5.3 26.7 4 25 4ZM11 9C12.4 9 13.5 10.1 13.5 11.5C13.5 12.9 12.4 14 11 14C9.6 14 8.5 12.9 8.5 11.5C8.5 10.1 9.6 9 11 9ZM25 25H7C6.6 25 6.4 24.5 6.6 24.2L11 18L14 22L19 15L25.4 24.2C25.7 24.5 25.4 25 25 25Z"
            fill="#1C1E21"
        />
    </svg>
);

export default ImageEvent;
