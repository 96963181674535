import { getLanguageByBrowserOrUserDefine, getLanguageDataByKey, languageConfigure } from './utils';

class LanguageService {
    constructor() {}

    getLanguageKey() {
        return getLanguageByBrowserOrUserDefine();
    }

    getTranslation(languageKey) {
        const langData = getLanguageDataByKey(languageKey);

        return langData;
    }

    configureLangauge(langKey, langPayload) {
        return languageConfigure(langKey, langPayload);
    }
}
export { LanguageService };
