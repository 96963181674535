import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../actions';
import Navigation from './Navigation';

const mapStateToProps = (state) => ({
    isCustomizationProgressing: state.account.isCustomizationProgressing,
    currentPkAccount: state.account?.data?.PK_Account,
    isShowModalClearMeshBot: state.main.isShowModalClearMeshBot,
    isEzlos: state.ezlos && state.ezlos.items && state.ezlos.items.length > 0,
});

const mapDispatchToProps = (dispatch) => {
    const obj = {};

    Object.keys(Actions).map((actionsName) => {
        if (actionsName !== '__esModule') {
            obj[actionsName] = bindActionCreators(Actions[actionsName], dispatch);
        }
    });

    return obj;
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    redux: {
        state: stateProps,
        actions: dispatchProps,
    },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Navigation);
