import React from 'react';

export const LocksDoorIcon = (props) => {
    return (
        <svg {...props} width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M44 21C44 23.2 42.2 25 40 25H28C26.9 25 26 24.1 26 23C26 21.9 26.9 21 28 21H44ZM37 27V33C37 38 33 42 28 42C23 42 19 38 19 33V23C19 18 23 14 28 14C31.5 14 34.6 16 36.1 19H28C25.8 19 24 20.8 24 23C24 25.2 25.8 27 28 27H37ZM28 31C27 31 26.2 31.9 26.3 33L26.8 36C26.9 36.6 27.4 37 28 37C28.6 37 29.1 36.6 29.2 36L29.7 33C29.8 31.9 29 31 28 31Z" />
        </svg>
    );
};

export default LocksDoorIcon;
