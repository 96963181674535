import { toast as toastifyToast } from 'react-toastify';
import { TOKENS_FLOWS, TOKENS_FLOWS_PROPERTIES } from '../constants';
import { ERROR_TYPES, EzloError } from 'services/utilityService';
import { EZLOGIC_TITLE_USER_LOGGED_OUT } from 'constants/language_tokens';
import { t } from 'helpers/language';

const { TOAST_ID, IS_TOKENS_OBSOLETE } = TOKENS_FLOWS_PROPERTIES;

/**
 * Clears the timeout identified by the given ID.
 *
 * @param {number|null} timeoutId - The ID of the timeout to be cleared.
 */
export const clearTokensFlowTimeout = (timeoutId) => {
    if (timeoutId) {
        clearTimeout(timeoutId);
    }
};
/**
 * Dismisses the toast identified by the given ID using the react-toastify library.
 *
 * @param {string|null} toastId - The ID of the toast to be dismissed.
 */
export const dismissTokensFlowToast = (toastId) => {
    if (toastId) {
        toastifyToast.dismiss(toastId);
    }
};
/**
 * Calculates the remaining lifetime of an MMS token based on the expiration timestamp.
 *
 * @param {number} expirationTimestamp - The timestamp when the token expires.
 * @returns {number} The remaining lifetime of the token in seconds.
 */
export const calculateRemainingLifetimeOfMmsToken = (expirationTimestamp) => {
    // Get the current timestamp
    const currentTimestamp = Math.floor(Date.now() / 1000);

    // Calculate the remaining lifetime and time to get new tokens in seconds
    return (expirationTimestamp - currentTimestamp) * 0.9;
};
/**
 * Calls the given function with a random delay between the specified range.
 *
 * @param {Function} cb - The function to be called with a random delay.
 * @param {number} [maxDelay=20000] - The maximum delay in milliseconds.
 * @param {number} [minDelay=1000] - The minimum delay in milliseconds.
 * @returns {Promise} A promise that resolves with the result of the function call.
 */
export const callWithRandomDelay = (cb, maxDelay = 20000, minDelay = 1000) => {
    return new Promise((resolve) => {
        // Generate a random delay between 1 and 20 seconds
        const delay = Math.floor(Math.random() * (maxDelay - minDelay)) + minDelay; // Random value between 1000 ms (1 second) and 20000 ms (20 seconds)

        // Schedule the function call with the calculated delay
        setTimeout(async () => {
            const result = await cb();
            resolve(result);
        }, delay);
    });
};

/**
 * Checks if a token is still valid based on its expiration timestamp.
 *
 * @param {number} tokenValidUntil - The timestamp when the token is valid until.
 * @returns {boolean} Returns true if the token is still valid, otherwise false.
 */
export const isValidToken = (tokenValidUntil) => {
    const currentTime = Date.now() / 1000;

    return currentTime <= tokenValidUntil;
};
/**
 * Extracts relevant token flows state properties from the Redux state.
 *
 * @param {Object} state - The Redux state object.
 * @returns {Object} An object containing the extracted properties (showedToastId, isTokensObsolete).
 */
export const extractTokenFlowsState = (state) => {
    const tokensFlowState = state?.[TOKENS_FLOWS];

    return {
        showedToastId: tokensFlowState?.[TOAST_ID],
        isTokensObsolete: tokensFlowState?.[IS_TOKENS_OBSOLETE],
    };
};
/**
 * Extracts the account logged value from the Redux state.
 *
 * @param {Object} state - The Redux state object.
 * @returns {boolean} Returns true if the user is logged in, otherwise false.
 */
const extractAccountLoggedValue = (state) => state?.account?.logged;
/**
 * Checks if the user is currently logged in based on the Redux state.
 *
 * @param {Object} state - The Redux state object.
 * @returns {boolean} Returns true if the user is logged in, otherwise false.
 */
export const isLoggedIn = (state) => extractAccountLoggedValue(state);

/**
 * Validates that the user is logged in, throwing an error if not.
 *
 * @param {Object} state - The Redux state object.
 * @throws {EzloError} Throws an error if the user is not logged in.
 */
export const validateAccountToSaveData = (state) => {
    const isUserLoggedIn = isLoggedIn(state);

    if (!isUserLoggedIn) {
        throw new EzloError(t(EZLOGIC_TITLE_USER_LOGGED_OUT), ERROR_TYPES.ACCESS_CLOSED);
    }
};
