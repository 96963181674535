import * as types from '../constants/ActionTypes';

export const WrapperCatch = (err, variable, dispatch, isGeneral) => {
    if (err.data) {
        dispatch({ type: variable, data: err.data });
        if (isGeneral) {
            dispatch({ type: types.SHOW_ERROR, data: err.data });
        }
    }
};
