import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { IconButton } from 'lib/@mui';
import { ImportantDevicesOutlined } from 'lib/@mui/Icons';

import { setListDevicesInstalledWifi } from '../../utils';

import { prepareDevices } from '../../../EzloDevices/utils';
import styles from '../../PluginStyled.module.scss';

const CountInstalledIPDevices = (props) => {
    const data = useSelector((state) => state.ezlo.data);
    const privateIPTemplates = useSelector((state) => state.plugins.privateWifiTemplates) || [];
    const { params, onHandlerShowDevicesModal } = props;
    const devices = prepareDevices(data) || [];

    const countInstalledDevices = useMemo(() => {
        return setListDevicesInstalledWifi(privateIPTemplates, devices);
    }, [privateIPTemplates, devices]);

    return (
        <div className={styles.pluginsListCount}>
            <span className={styles.pluginsListSumPlugins}>{countInstalledDevices[params?.row?.original?.nameId]}</span>
            <IconButton onClick={() => onHandlerShowDevicesModal(params)}>
                <ImportantDevicesOutlined />
            </IconButton>
        </div>
    );
};

export default CountInstalledIPDevices;
