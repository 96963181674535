import React from 'react';
import { SvgIcon } from 'lib/@mui';

export const IcActionsMeshBot = (props) => (
    <SvgIcon color="primary" {...props} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
        <path d="M28 24.2857C28 23.5714 28.5714 23 29.2857 23H30.7143C31.4286 23 32 23.5714 32 24.2857V25.7143C32 26.4286 31.4286 27 30.7143 27H29.2857C28.5714 27 28 26.4286 28 25.7143V24.2857Z" />
        <path d="M0 6.28571C0 5.57143 0.571429 5 1.28571 5H2.71429C3.42857 5 4 5.57143 4 6.28571V7.71429C4 8.42857 3.42857 9 2.71429 9H1.28571C0.571429 9 0 8.42857 0 7.71429V6.28571Z" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 12.0714C5 8.14286 8.14286 5 12.0714 5H19.9286C23.8571 5 27 8.14286 27 12.0714V19.9286C27 23.8571 23.8571 27 19.9286 27H12.0714C8.14286 27 5 23.8571 5 19.9286V12.0714ZM22.187 14.625C22.187 13.4562 21.2932 12.5625 20.1245 12.5625C18.9557 12.5625 18.062 13.4563 18.062 14.625H22.187ZM14.6242 14.625H10.4992C10.4992 13.4563 11.393 12.5625 12.5617 12.5625C13.7305 12.5625 14.6242 13.4562 14.6242 14.625Z"
        />
    </SvgIcon>
);

export default IcActionsMeshBot;
