import { createTheme } from 'lib/@mui';
import * as at from '../containers/cutomThemeColors';

export const customTheme = (colorObject) =>
    createTheme({
        palette: {
            primary: {
                main: colorObject['--primary-color-theme'] || at.PRIMARY_COLOR,
            },
            error: {
                main: at.ERROR_COLOR,
            },
        },
        components: {
            MuiTextField: {
                defaultProps: {
                    variant: 'standard',
                },
            },
            MuiListItem: {
                styleOverrides: {
                    root: {
                        color: colorObject['--navigation-menu-text-color'],
                    },
                },
            },
            MuiSelect: {
                styleOverrides: {
                    select: {
                        '&:focus': {
                            backgroundColor: 'transparent',
                        },
                    },
                    icon: {
                        fontSize: '24px',
                        color: at.MAIN_DARK_COLOR,
                        top: 'calc(50% - 12px)',
                    },
                },
                defaultProps: {
                    variant: 'standard',
                },
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        'color': at.LABEL_COLOR,
                        'fontFamily': at.MAIN_FONT_FAMILY,
                        'fontWeight': at.FONT_WEIGHT_SEMI,
                        'fontSize': '12px',
                        '&.Mui-focused': {
                            fontFamily: at.MAIN_FONT_FAMILY,
                            fontSize: '12px',
                            fontWeight: at.FONT_WEIGHT_REGULAR,
                        },
                    },
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        'fontFamily': at.MAIN_FONT_FAMILY,
                        'fontWeight': at.FONT_WEIGHT_REGULAR,
                        'fontSize': '16px',
                        'color': at.MAIN_DARK_COLOR,
                        '& legend': {
                            fontSize: '0.6em',
                        },
                    },
                    input: {
                        padding: '4px 0 4px',
                    },

                    multiline: {
                        padding: '4px 0 4px',
                    },
                },
            },
            MuiInput: {
                styleOverrides: {
                    underline: {
                        '&:before': {
                            borderBottom: `2px solid rgba( ${at.INPUT_BORDER_BOTTOM_COLOR}, 0.5)`,
                        },
                    },
                },
            },
            MuiAutocomplete: {
                styleOverrides: {
                    inputRoot: {
                        '&&[class*="MuiInput-root"]': {
                            padding: '4px 0',
                        },
                    },
                    endAdornment: {
                        '& svg': {
                            fontSize: '24px',
                            color: at.MAIN_DARK_COLOR,
                        },
                    },
                    listbox: {
                        'scrollbarWidth': 'thin',
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: at.SCROLLBAR_TRACK,
                        },
                        '&::-webkit-scrollbar': {
                            backgroundColor: at.SCROLLBAR,
                            width: '6px',
                            height: '6px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: at.SCROLLBAR_THUMB,
                            borderRadius: '0px',
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        fontFamily: at.MAIN_FONT_FAMILY,
                        fontWeight: at.FONT_WEIGHT_SEMI,
                        fontSize: '16px',
                        textTransform: 'capitalize',
                    },
                    containedSecondary: {
                        'backgroundColor': `rgba( ${at.SECONDARY_BUTTON_BG}, 0.15)`,
                        'color': at.SECONDARY_BUTTON_COLOR,
                        '&:hover': {
                            backgroundColor: `rgba( ${at.SECONDARY_BUTTON_BG}, 0.35)`,
                        },
                    },
                    containedPrimary: {
                        'backgroundColor': colorObject['--primary-button-color'] || at.PRIMARY_BUTTON_COLOR,
                        'color': at.WHITE_COLOR,
                        '&:hover': {
                            backgroundColor: colorObject['--primary-button-color'] || at.PRIMARY_BUTTON_COLOR,
                        },
                    },
                    outlinedPrimary: {
                        'border': `1px solid ${colorObject['--primary-button-color'] || at.PRIMARY_BUTTON_COLOR}`,
                        'backgroundColor': colorObject['--primary-button-color'] || at.PRIMARY_BUTTON_COLOR,
                        'color': at.WHITE_COLOR,
                        '&:hover': {
                            backgroundColor: colorObject['--primary-button-color'] || at.PRIMARY_BUTTON_COLOR,
                            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
                        },
                    },
                    sizeLarge: {
                        fontSize: '16px',
                    },
                },
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        fontFamily: at.MAIN_FONT_FAMILY,
                        fontWeight: at.FONT_WEIGHT_REGULAR,
                        fontSize: '16px',
                        color: at.MAIN_DARK_COLOR,
                    },
                },
            },
            MuiTablePagination: {
                styleOverrides: {
                    selectLabel: at.TABLE_PAGINATION_TYPOGRAPHY_STYLES,
                    select: at.TABLE_PAGINATION_TYPOGRAPHY_STYLES,
                    displayedRows: at.TABLE_PAGINATION_TYPOGRAPHY_STYLES,
                },
            },
            MuiFormControl: {
                defaultProps: {
                    variant: 'standard',
                },
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        top: '3px',
                        zIndex: 1,
                    },
                },
            },
        },
    });
