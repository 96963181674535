import * as yup from 'yup';
import {
    DARK_LABEL_FONT_COLOR,
    LABEL_FIELDS,
    SELECT_NEGATIVE_STATUSES,
    SELECT_STATUSES,
    THROTTLE_TIMEOUT_FOR_UPDATE_LABELS_REQUEST,
    UPDATED_MESHBOT_LABELS_ACTIONS,
    WHITE_FONT_COLOR,
} from '../constants';
import { MESHBOT_LABELS } from '../../../../services/kvs';
import { isArray, isEmpty, isObject, isString } from 'lodash';
import {
    LABELS,
    NEW_ADDED_LABELS_UUIDS_BY_MESHBOTS,
    SELECTED_LABEL_UUID_FOR_FILTERING,
} from '../../../../reducers/labels';
import { CLOUD, LOCAL } from '../../../../containers/Ezlo/EzloMeshbot/constant';
import { generateStringFromArguments, throttleThunk } from '../../../../services/utilityService';
import styles from '../../MeshBotsLabels/components/MeshBotLabelsList/styles.module.scss';
import { CheckBoxOutlineBlankOutlined, IndeterminateCheckBoxOutlined } from 'lib/@mui/Icons';

/**
 * Label Meshbot Form Validation Scheme
 * */
export const validationSchema = yup.object({
    [LABEL_FIELDS.NAME]: yup.string().required(),
    [LABEL_FIELDS.BACKGROUND_COLOR]: yup.string().required(),
});

/**
 * Builds a list of labels(which have not been deleted) in array format
 * @param {Object} labels - all labels in Object format
 * @returns {Object[]} list of meshBot labels in array format
 * @example
 * buildNonDeletedLabelsInArrayFormat({'4125vfrt14b': { 'name': 'label', ...other}})
 * */
export const buildLabelsInArrayFormat = (labels) => {
    const labelsInArrayFormat = [];

    for (const key in labels) {
        const label = labels[key];
        labelsInArrayFormat.push({ id: key, ...label });
    }

    return labelsInArrayFormat;
};

/**
 * Selector that returns state from redux state.labels
 * @param {Object} state - redux State
 * @returns {{selectedLabelUuidForFiltering: String|Null}} state from redux state.labels
 * @example
 * useSelector(labelsSelector)
 * */
export const labelsSelector = (state) => {
    return {
        [SELECTED_LABEL_UUID_FOR_FILTERING]: state[LABELS][SELECTED_LABEL_UUID_FOR_FILTERING],
        [NEW_ADDED_LABELS_UUIDS_BY_MESHBOTS]: state[LABELS][NEW_ADDED_LABELS_UUIDS_BY_MESHBOTS],
    };
};
/**
 * Creates a selector to get the states of new labels by mashbot
 * @param {String} meshBotUuid - meshBot uuid
 * @returns {Function} Selector
 * @example
 * useSelector(getNewAddedLabelsSelector('1265mbg45'))
 * */
export const getNewAddedLabelsSelector = (meshBotUuid) => (state) => {
    return { newAddedLabels: state[LABELS][NEW_ADDED_LABELS_UUIDS_BY_MESHBOTS][meshBotUuid] || null };
};

/**
 * Pulls out a list of labels from meta
 * @param {Object} meshBot - meshBot data
 * @returns {String[]} List of mesh bot labels uuids
 * @example
 * getMeshBotLabelsUuidsFromMeshBot({meta: { labels: ['21536hbf', 'bcdff1265']}})
 * */
export const getMeshBotLabelsUuidsFromMeshBot = (meshBot) => {
    if (!meshBot.meta?.labels || !isArray(meshBot.meta?.labels)) {
        return [];
    }

    return meshBot.meta.labels.filter((labelId) => isString(labelId)) || [];
};
/**
 * Filters the list of all meshbots by the selected label
 * @param {String} selectedLabelUuid - selected label uuid
 * @param {Object[]} allMeshBots - all MeshBots(cloud + local)
 * @returns {Object[]} feltered list of all meshbots by the selected label
 * @example
 * filterMeshBotsBySelectedLabelUuid('4125vfrt14b', [{labelsUuids: ['8965vfrt14b','4125vfrt14b']}])
 * */
export const filterMeshBotsBySelectedLabelUuid = (selectedLabelUuid, allMeshBots) => {
    if (!isArray(allMeshBots)) {
        return [];
    }

    if (!selectedLabelUuid) {
        return allMeshBots;
    }

    return allMeshBots.filter((meshBot) => meshBot.labelsUuids?.includes(selectedLabelUuid));
};

/**
 * Builds a list of labels in array format
 * @param {String[]} labelsUuids - list of label's uuid
 * @param {Object} labels - all labels
 * @returns {Object[]} list of meshBot labels in array format
 * @example
 * buildMeshBotLabelsInArrayFormat('4125vfrt14b', {'4125vfrt14b': { 'name': 'label', ...other}})
 * */
export const buildMeshBotLabelsInArrayFormat = (labelsUuids, labels) => {
    if (!isArray(labelsUuids)) {
        return [];
    }

    if (!isObject(labels)) {
        return [];
    }

    return labelsUuids.reduce((prevState, labelUuid) => {
        if (!labels.hasOwnProperty(labelUuid)) {
            return prevState;
        }

        return [...prevState, { uuid: labelUuid, ...labels[labelUuid] }];
    }, []);
};
/**
 * Provides the last label in the label's list
 * @param {Array} labelsList - all labels
 * @returns {Object} the last label in the label's list
 * @example
 * getLastLabelsInList([{ 'name': 'label', ...other}])
 * */
export const getLastLabelsInList = (labelsList) => {
    if (!isArray(labelsList)) {
        throw new Error('labelsList must be an array');
    }

    if (isEmpty(labelsList)) {
        throw new Error('labelsList is empty. not valid labelsList');
    }

    return labelsList[labelsList.length - 1];
};
/**
 * Selector that returns isLoading from redux state.kvs
 * @param {Object} reduxState - redux State
 * @returns {{isLoading: boolean}} object with isLoading value
 * @example
 * useSelector(kvsIsLoadingSelector)
 * */
export const kvsIsLoadingSelector = (reduxState) => {
    if (!reduxState?.kvs) {
        throw new Error('reduxState is not valid, kvs state is missing');
    }

    return { isLoading: reduxState.kvs.isLoading };
};

/**
 * Selector that returns meshbot_labels from redux state.kvs
 * @param {Object} reduxState - redux State
 * @returns {{labels: Object}} meshbot_labels from redux state.kvs
 * @example
 * useSelector(kvsMeshBotLabelsSelector)
 * */
export const kvsMeshBotLabelsSelector = (reduxState) => {
    if (!reduxState?.kvs) {
        throw new Error('reduxState is not valid, kvs state is missing');
    }

    if (!reduxState.kvs[MESHBOT_LABELS]) {
        throw new Error('reduxState is not valid, meshbot_labels in kvs state is missing');
    }

    return { labels: reduxState.kvs[MESHBOT_LABELS] };
};
/**
 * Builds an initial list of labels for the scene(with full information about labels)
 * @param {Object} kvsLabels - labels from kvs {<label's uuid>: {name: <label's name>, backgroundColor: <label's background color>}}
 * @param {String[]} sceneLabels - List of label's uuids that have been added to the scene
 * @returns {Object[]} Initial list of labels with full information about labels
 * @example
 * useSelector(kvsMeshBotLabelsSelector)
 * */
export const getInitialLabels = (kvsLabels, sceneLabels) => {
    const result = [];
    Object.entries(kvsLabels).forEach(([kvsLabelKey, labelObj]) => {
        sceneLabels.forEach((sceneLabelKey) => {
            if (kvsLabelKey === sceneLabelKey) {
                result.push({ ...labelObj, id: sceneLabelKey });
            }
        });
    });

    return result;
};
/**
 * Retrieves the UUIDs of existing mesh bot labels from the given list of UUIDs,
 * based on their presence in the provided key-value store (kvs) of labels.
 *
 * @param {Object} kvsLabels - The key-value store of mesh bot labels where UUIDs will be checked.
 * @param {Array} meshBotsLabelsUuids - An array of labels UUIDs of meshbot.
 * @returns {Array} An array containing UUIDs of existing meshbot labels found in the kvsLabels.
 *
 * @example
 * const kvsLabels = {
 *     uuid1: { name: "abc" },
 *     uuid2: { name: "def" },
 *     // ...
 * };
 *
 * const meshBotsLabelsUuids = ["uuid1", "uuid2", "uuid3"];
 *
 * const existingUuids = getExistingMeshBotLabelsUuids(kvsLabels, meshBotsLabelsUuids);
 * console.log(existingUuids); // Output: ["uuid1", "uuid2"]
 */
export const getExistingMeshBotLabelsUuids = (kvsLabels = {}, meshBotsLabelsUuids = []) => {
    return meshBotsLabelsUuids.reduce((prevState, currentLabelsUuid) => {
        if (currentLabelsUuid in kvsLabels) {
            prevState.push(currentLabelsUuid);
        }

        return prevState;
    }, []);
};
/**
 * Function that returns contrast color for label
 * @param {String} background
 * @returns {String} color
 * */
export const getContrast = (background) => {
    const backgroundRGB = background
        .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
        .substring(1)
        .match(/.{2}/g)
        .map((x) => parseInt(x, 16));

    const brightness = Math.sqrt(
        0.299 * Math.pow(backgroundRGB[0], 2) +
            0.587 * Math.pow(backgroundRGB[1], 2) +
            0.114 * Math.pow(backgroundRGB[2], 2),
    );

    return brightness > 127.5 ? DARK_LABEL_FONT_COLOR : WHITE_FONT_COLOR;
};

export const getCloseButtonClassName = (color) => {
    return color === WHITE_FONT_COLOR ? styles.deleteBtnWhite : styles.deleteBtnDark;
};

/**
 * Combines two labels uuids arrays into one
 * @param {String[]} labelsUuids - labels Uuids that have been saved
 * @param {String[]} newLabelsUuids - new labels that have not yet been saved
 * @returns {String[]} Array with merged labels without repetitions
 * @example
 * addNewLabelsUuids(['4123gbh'], ['fvgb412', 'gbh4225'])
 * */
export function addNewLabelsUuids(labelsUuids = [], newLabelsUuids = []) {
    if (!isArray(labelsUuids)) {
        throw new Error('labelsUuids is not valid, labelsUuids should be an array');
    }

    const allLabelsUuids = isArray(newLabelsUuids) ? labelsUuids.concat(newLabelsUuids) : labelsUuids;
    const uniqueIdSet = new Set(allLabelsUuids);

    return [...uniqueIdSet];
}

/**
 * Updates meshbot data, adds labels
 * @param {Object} meshBot - meshBot data
 * @param {String[]} labelsUuids - new labels that have not yet been saved
 * @returns {Object} Meshbot data with new labels
 * @example
 * getMeshBotWithUpdatedLabels({name: 'MeshBot', meta: {labels:[]} ...other}, ['fvgb412', 'gbh4225'])
 * */
export const getMeshBotWithUpdatedLabels = ({ meshBot, labelsUuids }) => {
    return {
        ...meshBot,
        meta: {
            ...meshBot.meta,
            labels: labelsUuids,
        },
    };
};
/**
 * Updates labels uuids list depending on the action
 * @param {Object} meshBotMeta - meshBot meta
 * @param {String} action - indicates the nature of the label update
 * @param {Object} kvsLabels - The key-value store of mesh bot labels in kvs.
 * @param {String[]} newLabelsUuids - new labels that have not yet been saved
 * @returns {String[]} A list of updated labels depending on the action
 * @example
 * const kvsLabels = {
 *     fvgb412: { name: "abc" },
 *     gbh4225: { name: "def" },
 *     123: { name: "def" },
 *     // ...
 * };
 * getNewMeshBotLabelsUuids('ADD', {labels:['123'], ...other}, ['fvgb412', 'gbh4225'], kvsLabels)
 * // Result will be something like:
 * // ['123', 'fvgb412', 'gbh4225']
 * */
export const getNewMeshBotLabelsUuids = (action, meshBotMeta, newLabelsUuids = [], kvsLabels) => {
    switch (action) {
        case UPDATED_MESHBOT_LABELS_ACTIONS.ADD:
            const existingMeshBotLabelsUuids = getExistingMeshBotLabelsUuids(kvsLabels, meshBotMeta?.labels);

            return addNewLabelsUuids(existingMeshBotLabelsUuids, newLabelsUuids);
        default:
            return newLabelsUuids;
    }
};
/**
 * Updates the Meshbot in the list and provides that list
 * @param {Object} params - params to update meshbot
 * @param {Object[]} meshBotsList - meshBots list
 * @param {Object} kvsLabels - The key-value store of mesh bot labels in kvs.
 * @returns {Object[]} Meshbots list with a updated meshbot
 * @example
 * const kvsLabels = {
 *     fvgb412: { name: "abc" },
 *     gbh4225: { name: "def" },
 *     // ...
 * };
 * getMeshBotsListWithUpdatedLabelsInMeshBot({meshBotUuid: 'fvgb425', newLabelsUuids: ['fvgb412', 'gbh4225']}, [{name: 'MeshBot', meta: {labels:[]} ...other}], kvsLabels)
 * */
export const getMeshBotsListWithUpdatedLabelsInMeshBot = (
    { meshBotUuid, newLabelsUuids, action },
    meshBotsList,
    kvsLabels,
) => {
    return meshBotsList.map((meshBot) => {
        if (meshBot.uuid === meshBotUuid || meshBot._id === meshBotUuid) {
            const labelsUuids = getNewMeshBotLabelsUuids(action, meshBot.meta, newLabelsUuids, kvsLabels);

            return getMeshBotWithUpdatedLabels({ meshBot, labelsUuids });
        }

        return meshBot;
    });
};
/**
 * Builds a payload to update Meshbot on the cloud
 * @param {Object} meshBot - meshbot data from Cloud
 * @param {String[]} newLabelsUuids - New unsaved labels on the cloud
 * @param {String } action - Labels update action
 * @param {Object} kvsLabels - The key-value store of mesh bot labels in kvs.
 * @returns {Object} Payload to update the mashbot on the cloud
 * @example
 * const kvsLabels = {
 *     fvgb412: { name: "abc" },
 *     gbh4225: { name: "def" },
 *     // ...
 * };
 * buildCloudMeshBotPayloadWithUpdatedLabels({name: 'MeshBot', meta: {labels:[]} ...other}, ['fvgb412', 'gbh4225'], 'ADD", kvsLabels)
 * */
export const buildCloudMeshBotPayloadWithUpdatedLabels = (
    { uuid, name, triggers, actions, meta },
    newLabelsUuids,
    action = '',
    kvsLabels,
) => {
    const labelsUuids = getNewMeshBotLabelsUuids(action, meta, newLabelsUuids, kvsLabels);

    return {
        uuid,
        name,
        triggers,
        actions,
        meta: {
            ...meta,
            labels: labelsUuids,
        },
    };
};

/**
 * Generates an id for Throttle function that meshbot will use for update;
 * @param {String} meshBotUuid - meshBot uuid
 * @returns {String} a generated Id
 * @example
 * generateMeshBotLabelThrottleId('412fdevgb412')
 * */
export function generateMeshBotLabelThrottleId(meshBotUuid) {
    return generateStringFromArguments([MESHBOT_LABELS, meshBotUuid], '_');
}

/**
 * Provides throttle function to update labels in MesBots
 * @param {String} meshBotUuid - meshBot uuid
 * @param {Function} thunkCreatorFn - thunkCreator Fn
 * @returns {Function} Throttle fn for update labels in MeshBot
 * @example
 * getThrottleForUpdateLabelsInMeshBot('412fdevgb412', someThunkCreatorFn)
 * */
export function getThrottleForUpdateLabelsInMeshBot(meshBotUuid, thunkCreatorFn) {
    return throttleThunk({
        id: generateMeshBotLabelThrottleId(meshBotUuid),
        timeout: THROTTLE_TIMEOUT_FOR_UPDATE_LABELS_REQUEST,
        thunkCreatorFn,
    });
}

/**
 * Depending on the type of Meshbot, the function finds a list of labels uuid in the meshbot and returns it
 * @param {Object} meshBot - meshBot from redux
 * @param {String} meshbotType - meshBot type
 * @returns {String[]} list of labels uuid
 * @example
 * getMeshBotLabelsUuids({ cloud: {meta: {labels: ['124mj]}}}, 'cloud')
 * */
const getMeshBotLabelsUuids = (meshBot, meshbotType) => {
    const meshBotByType = meshBot[meshbotType];
    if (meshbotType === LOCAL) {
        return meshBotByType.selectedRule.meta.labels;
    }

    if (meshbotType === CLOUD) {
        return meshBotByType.meta.labels;
    }

    return [];
};

/**
 * Selector creator that returns selector by meshbotType which will provide a list of MeshBots labels uuids
 * @param {String} meshbotType -  meshBot type
 * @returns {Function} selector by meshbotType which will provide a list of MeshBots labels uuids (meshBotLabelsUuids in Obj)
 * @example
 * useSelector(meshBotLabelsUuidByTypeSelector('cloud'))
 * */
export const meshBotLabelsUuidByTypeSelector =
    (meshbotType) =>
    ({ meshBot }) => ({
        meshBotLabelsUuids: getMeshBotLabelsUuids(meshBot, meshbotType),
    });

/**
 * Determines, depending on the passed params, whether the label is selected or not
 * @param {Object} params - params for determining the selection
 * @param {Object} label - label data
 * @returns {Boolean} will return true if the label is selected
 * @example
 * getIsLabelSelected({id: 'fcv412'}, {labelsWithSelectStatus: {...some field}})
 * */
export const getIsLabelSelected = (label, params) => {
    if (params.labelsWithSelectStatus) {
        return params.labelsWithSelectStatus[label.id] === SELECT_STATUSES.ALL_SELECT;
    }

    return params.selectedLabelsUuids.includes(label.id);
};

/**
 * provides UnChecked icon component depending on the passed parameters
 * @param {Object} params - params for determining the selection
 * @param {Object} label - label data
 * @returns {React.ReactNode} will return SvgIcon Component
 * @example
 * getLabelItemUnCheckedIcon({id: 'fcv412'}, {labelsWithSelectStatus: {...some field}})
 * */
export const getLabelItemUnCheckedIcon = (label, params) => {
    if (params.labelsWithSelectStatus?.[label.id] === SELECT_STATUSES.SOME_SELECT) {
        return IndeterminateCheckBoxOutlined;
    }

    return CheckBoxOutlineBlankOutlined;
};
/**
 * Toggles the status of the item
 * @param {String} id - item id
 * @param {Object} itemsWithSelectStatus - list of items in the format of the object, the value of each item is the status of the choice of the item
 * @param {Object} initItemsWithSelectStatus - List of items with the initial value of the status
 * @returns {'ALL_SELECT' | 'SOME_SELECT' | 'ALL_UNSELECT'} select status for item
 * @example
 * toggleStatus('fcv412', {'fcv412': 'ALL_SELECT', ...other}, {'fcv412': 'SOME_SELECT', ...other})
 * */
export const toggleStatus = (id, itemsWithSelectStatus, initItemsWithSelectStatus) => {
    if (
        initItemsWithSelectStatus[id] === SELECT_STATUSES.SOME_SELECT &&
        itemsWithSelectStatus[id] === SELECT_STATUSES.ALL_UNSELECT
    ) {
        return SELECT_STATUSES.SOME_SELECT;
    }

    if (SELECT_NEGATIVE_STATUSES.includes(itemsWithSelectStatus[id])) {
        return SELECT_STATUSES.ALL_SELECT;
    }

    if (itemsWithSelectStatus[id] === SELECT_STATUSES.ALL_SELECT) {
        return SELECT_STATUSES.ALL_UNSELECT;
    }
};

/**
 * Determines the status of each label that the selected MeshBots has. The status will depend on the availability of the label in the selected Meshbots. If the label is in all selected meshbots, then it will have a status ALL_SELECT otherwise it will have a status SOME_SELECT
 * @param {Object} selectedMeshBotsByType - Selected meshbots in object format divided by type
 * @param {Object[]} allMeshbots - all meshbots list
 * @returns {Object} will return a list of labels(in object format) that are available in the selected meshbots with a status that indicates the presence of this label in MeshBots
 * @example
 * // Example usage:
 * const selectedMeshbots = {'local': {'fcv412': {name: 'label'}, ...other}};
 * const allMeshbots = [{id: 'fcv412', type: 'local'}, ...other];
 * getSelectedMeshbotsLabelsStatus(selectedMeshbots, allMeshbots)
 * // Result will be something like:
 * // {'fcv412': 'ALL_SELECT'}
 * */
export function getSelectedMeshbotsLabelsStatus(selectedMeshBotsByType, allMeshbots) {
    let countOfSelectedMeshBots = 0;
    const labels = {};
    const selectedMeshbotsLabelsStatus = {};

    for (const type in selectedMeshBotsByType) {
        const meshbots = selectedMeshBotsByType[type];

        for (const meshbotId in meshbots) {
            countOfSelectedMeshBots++;
            const meshBot = allMeshbots.find((meshbot) => meshbot.id === meshbotId && meshbot.type === type);
            const labelsUuids = meshBot?.labelsUuids || [];

            labelsUuids.forEach((labelUuid) => {
                labelUuid in labels ? labels[labelUuid]++ : (labels[labelUuid] = 1);
            });
        }
    }

    for (const uuid in labels) {
        if (labels[uuid] === countOfSelectedMeshBots) {
            selectedMeshbotsLabelsStatus[uuid] = SELECT_STATUSES.ALL_SELECT;
        } else {
            selectedMeshbotsLabelsStatus[uuid] = SELECT_STATUSES.SOME_SELECT;
        }
    }

    return selectedMeshbotsLabelsStatus;
}

/**
 * Builds selected meshbots data with label updates based on label status
 * @param {Object} selectedMeshBotsByType - Selected meshbots in object format divided by type
 * @param {Object} kvsLabels - The key-value store of mesh bot labels in kvs.
 * @param {Object} labelsWithSelectStatus - List of labels in object format. The key of the object is the id of the label, and the value of the key is the status of the label
 * @returns {Object[]} returns a list of selected meshbots with updated labels based on label status
 * @example
 * // Example usage:
 * const kvsLabels = {
 *     125kj: { name: "abc" },
 *     412hg: { name: "def" },
 *     125kj: { name: "def" },
 *     // ...
 * };
 * const selectedMeshBotsByType = {'local': {'fcv412': {name: 'label', labelsUuids: ['125kj']}, ...other}};
 * const labelsWithSelectStatus = {'412hg': 'ALL_SELECT', '125kj': 'SOME_SELECT', ...other};
 * getSelectedMeshBotsWithUpdatedLabelsUuids(labelsWithSelectStatus, selectedMeshBotsByType)
 * // Result will be something like:
 * // [{id: 'fcv412', name: 'label', labelsUuids: ['412hg', '125kj'], type: 'local'}]
 * */
export const getSelectedMeshBotsWithUpdatedLabelsUuids = (
    labelsWithSelectStatus,
    selectedMeshBotsByType,
    kvsLabels,
) => {
    const labelsUuidsByStatus = {
        [SELECT_STATUSES.ALL_SELECT]: [],
        [SELECT_STATUSES.SOME_SELECT]: [],
    };
    const updatedMeshBots = [];

    for (const labelId in labelsWithSelectStatus) {
        const status = labelsWithSelectStatus[labelId];
        if (status in labelsUuidsByStatus) {
            labelsUuidsByStatus[status].push(labelId);
        }
    }

    for (const type in selectedMeshBotsByType) {
        const meshbots = selectedMeshBotsByType[type];

        for (const meshbotId in meshbots) {
            const labelsUuids = meshbots[meshbotId].labelsUuids || [];
            const newLabels = [...labelsUuidsByStatus[SELECT_STATUSES.ALL_SELECT]];
            labelsUuids.forEach((labelUuid) => {
                if (labelsUuidsByStatus[SELECT_STATUSES.SOME_SELECT].includes(labelUuid)) {
                    newLabels.push(labelUuid);
                }
            });
            const existingMeshBotLabelsUuids = getExistingMeshBotLabelsUuids(kvsLabels, newLabels);
            updatedMeshBots.push({ ...meshbots[meshbotId], type, labelsUuids: existingMeshBotLabelsUuids });
        }
    }

    return updatedMeshBots;
};

/**
 * Builds a list of all labels with statuses. Combines labels (with statuses) of selected MeshBots with all other labels that are not in the selected MeshBots.
 * @param {Object[]} labelsList - Selected meshbots in object format divided by type
 * @param {Object} labelsWithStatusesForSelectedMeshbots - List of labels in object format. The key of the object is the id of the label, and the value of the key is the status of the label
 * @returns {Object} returns a list all labels with status regarding the selected Meshbots
 * @example
 * // Example usage:
 * const labelsList = [{id: 'nbg', name: 'label'}, ...other];
 * const labelsWithStatusesForSelectedMeshbots = {'412hg': 'ALL_SELECT', '125kj': 'SOME_SELECT', ...other};
 * buildLabelsStateWithStatuses(labelsList, labelsWithStatusesForSelectedMeshbots)
 * // Result will be something like:
 * // {'dcmf': 'ALL_UNSELECT', 412hg': 'ALL_SELECT', '125kj': 'SOME_SELECT', ...other}
 * */
export const buildLabelsStateWithStatuses = (labelsList, labelsWithStatusesForSelectedMeshbots) => {
    const labelsWithStatuses = labelsList.reduce((prevState, currentLabel) => {
        return {
            ...prevState,
            [currentLabel.id]: SELECT_STATUSES.ALL_UNSELECT,
        };
    }, {});

    return {
        ...labelsWithStatuses,
        ...labelsWithStatusesForSelectedMeshbots,
    };
};

/**
 * Generates initial form values for editing a label based on provided label data.
 *
 * @param {Object} labelData - The data of the label to be edited.
 * @param {string} labelData.name - The name of the label.
 * @param {string} labelData.backgroundColor - The background color of the label.
 * @returns {Object} Initial form values with name and background color fields.
 *
 * @example
 * const labelData = {
 *     name: 'Label Name',
 *     backgroundColor: '#ffffff',
 * };
 * const initialFormValues = getInitialEditLabelFormValues(labelData);
 * // Returns: { name: 'Label Name', backgroundColor: '#ffffff' }
 */
export const getInitialEditLabelFormValues = (labelData) => ({
    [LABEL_FIELDS.NAME]: labelData?.[LABEL_FIELDS.NAME] || '',
    [LABEL_FIELDS.BACKGROUND_COLOR]: labelData?.[LABEL_FIELDS.BACKGROUND_COLOR] || '',
});
