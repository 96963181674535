import React from 'react';

const IcDeviceSensorNoSmoke = (props) => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28 30L30 28L24.925 22.925L9.05714 7.05714L2 0L0 2L7.14567 9.14567C5.79549 10.9972 5 13.2789 5 15.75C5 18.86 6.26 21.67 8.3 23.7C9.33 24.73 10.55 25.55 11.91 26.12C10.88 25.05 10.25 23.6 10.25 22C10.25 20.14 11.09 18.49 12.42 17.39C13.05 20.03 15.42 22 18.25 22C18.7862 22 19.3048 21.9299 19.798 21.798L21.9308 23.9308C21.6481 24.759 21.1891 25.5062 20.6 26.13C21.4054 25.7925 22.1616 25.3637 22.8584 24.8584L28 30ZM27.51 15.76C27.51 17.4422 27.139 19.039 26.4737 20.4737L16.3439 10.3439C17.5063 10.821 18.8344 10.7969 19.79 10.34C22.5166 9.03162 22.2207 5.85783 21.2536 4.90352L24.2 7.8C26.24 9.83 27.5 12.64 27.51 15.76ZM16.25 0C13.7673 3.72402 13.4368 6.37966 14.1129 8.11287L11.0762 5.07619L16.25 0Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcDeviceSensorNoSmoke;
