import { apiGetCustomizationData, apiSetDataToKvs } from '../api/apiScenes';
import { toast, TOAST_TYPE } from '../components/Toast';
import * as types from '../constants/ActionTypes';
import * as localStorageKeys from '../constants/localStorageKeys';
import {
    MESHBOTS,
    CUSTOMIZATION_RESET_SUCCESSFULLY,
    CUSTOMIZATION_SAVED_SUCCESSFULLY,
    customizedMenuListIds,
    KVS_KEY,
    KVS_USER_KEY,
    VERA_USER,
    colors,
    ENCODED_EMPTY_OBJECT,
} from '../containers/Ezlo/EzloCustomization/constants';
import { MENU_ITEMS } from '../containers/Navigation/constants';
import { CLOUD } from '../constants/MeshbotConstant';
import { listNodesCloud, listNodesLocal } from '../constants/rulesInSelect';

import {
    createPayloadForColumns,
    decodeCloudToken,
    finalListingUIData,
    finalMeshbotUIData,
    getCustomizationPayload,
    getUpdatedColors,
    getNodesCustomizationPayload,
    getPayloadForNodes,
    getUpdatedLogo,
    getUpdatedMenuUiData,
    payloadDataMenuItems,
    updatedListingColumnData,
    updatedListNodeAction,
    updatedListNodesCloud,
    updatedMenuListItem,
    uploadKvsSetData,
    getUpdatedColorCustomizationPayload,
} from '../containers/Ezlo/EzloCustomization/utils';
import { checkVeraUser } from '../containers/Navigation/utils';
import { checkPartnerDomain } from '../containers/AuthorizationPages/Login/utils';
import { meshBotsTableColumnsInitialConfig } from '../containers/Ezlo/EzloMeshbots/utils';
import {
    filterCloudTriggerNodesByInternalDomain,
    generateOptionsByNotificationType,
    getListNodesLocalByOemId,
} from '../containers/Ezlo/EzloMeshbot/utils';
import { getNodesListForActionsLocalSectionByOemId } from '../containers/Ezlo/EzloMeshbot/components/TriggerHouseMode/utils';
import oemManager from '../services/oem';
import { PRIMARY_COLOR } from '../containers/cutomThemeColors';

const actions = {
    initialCustomization: (data) => ({
        type: types.INITIAL_CUSTOMIZATION,
        data,
    }),

    defaultCustomization: (data) => ({
        type: types.DEFAULT_CUSTOMIZATION,
        data,
    }),
    getCustomizationForPartner: () => async (dispatch) => {
        const CloudToken = localStorage.getItem(localStorageKeys.CLOUD_TOKEN);
        const getDecodedToken = decodeCloudToken(CloudToken);
        const getUserData = JSON.parse(getDecodedToken);
        const locationUuid = getUserData.location.uuid;
        const getCustomizationData = await apiGetCustomizationData(locationUuid);
        const customizationData =
            getCustomizationData.data.data.list[0]?.value !== null ? getCustomizationData.data.data.list[0]?.value : '';
        const updatedData = atob(customizationData ? customizationData : ENCODED_EMPTY_OBJECT);
        const parseCustomizationData = JSON.parse(updatedData);
        const { ezlogicPortal, ...kvsPayload } = parseCustomizationData;
        const themeColor = kvsPayload?.colors?.themeColor ? kvsPayload?.colors?.paletteColor?.hex : PRIMARY_COLOR;
        dispatch(actions.primaryColorAction(themeColor));
        const isVeraUser = checkVeraUser();
        const isPartnerDomain = checkPartnerDomain();
        dispatch(actions.isVeraUser(isVeraUser));
        const updatedInitialCustomizationPayload = getUpdatedColorCustomizationPayload(
            ezlogicPortal?.colorTheme,
            themeColor,
        );
        if (parseCustomizationData) {
            const initialCustomizationPayload = getCustomizationPayload(
                ezlogicPortal?.menuBar,
                ezlogicPortal?.listUi,
                ezlogicPortal?.meshbot,
                ezlogicPortal?.nodesControllables,
                updatedInitialCustomizationPayload,
            );
            dispatch(actions.currentCustomization(initialCustomizationPayload));
            dispatch(actions.kvsPayloadAction(kvsPayload));
            if (isPartnerDomain === false) {
                dispatch(actions.setInitialCustomization(ezlogicPortal, kvsPayload, themeColor));
            }
            dispatch(actions.setDefaultLogoCustomization(kvsPayload));
            dispatch(actions.setDefaultCustomization());
        }
    },

    setInitialCustomization: (ezlogicPortal, kvsPayload, themeColor) => async (dispatch) => {
        let updatedColors;
        const isVeraUser = checkVeraUser();
        const menuList = updatedMenuListItem(MENU_ITEMS, customizedMenuListIds, isVeraUser);
        const payloadMenuUi = payloadDataMenuItems(menuList);
        const initialUpdatedMenu = getUpdatedMenuUiData(ezlogicPortal?.menuBar, payloadMenuUi);
        const initialUpdatedLogo = getUpdatedLogo(kvsPayload?.images?.logo);
        updatedColors = getUpdatedColors(colors, ezlogicPortal?.colorTheme);
        if (themeColor) {
            updatedColors = getUpdatedColorCustomizationPayload(updatedColors, kvsPayload?.colors?.paletteColor?.hex);
        }

        const initialColumnData = updatedListingColumnData(
            meshBotsTableColumnsInitialConfig,
            ezlogicPortal?.listUi?.listingColumns,
        );
        const initialColumnsData = createPayloadForColumns(initialColumnData);
        const initialListingUiData = finalListingUIData(ezlogicPortal?.listUi?.listingMeshbotName, initialColumnsData);

        const initialCustomizedTriggerNodes = ezlogicPortal?.meshbot?.triggers;
        const initialCustomizedActionNodes = ezlogicPortal?.meshbot?.actions;
        const initialTriggerList = updatedListNodesCloud(listNodesCloud, initialCustomizedTriggerNodes);
        const initialActionList = updatedListNodeAction(
            generateOptionsByNotificationType(CLOUD),
            initialCustomizedActionNodes,
        );
        const initialMeshbotData = finalMeshbotUIData(initialTriggerList, initialActionList);
        const initialNodesControllablesData = ezlogicPortal?.nodesControllables;
        const actionCloudNodes = generateOptionsByNotificationType(CLOUD);
        const oemId = oemManager.oem.getOemId();
        const actionLocalNodes = getNodesListForActionsLocalSectionByOemId(oemId);
        const updatedLocalListNodes = getListNodesLocalByOemId(oemId, listNodesLocal);
        const initialNodesControllables = getNodesCustomizationPayload(
            initialNodesControllablesData,
            listNodesCloud,
            updatedLocalListNodes,
            actionCloudNodes,
            actionLocalNodes,
        );
        if (initialUpdatedLogo) {
            dispatch(actions.logoAction(initialUpdatedLogo));
        }

        const initialCustomizationPayload = getCustomizationPayload(
            initialUpdatedMenu,
            initialListingUiData,
            initialMeshbotData,
            initialNodesControllables,
            updatedColors,
            initialUpdatedLogo,
        );

        dispatch(actions.initialCustomization(initialCustomizationPayload));
    },

    setDefaultLogoCustomization: (kvsPayload) => (dispatch) => {
        const isVeraUser = checkVeraUser();
        const initialUpdatedLogo = getUpdatedLogo(kvsPayload?.images?.logo);
        if (initialUpdatedLogo) {
            dispatch(actions.logoAction(initialUpdatedLogo));
        }

        dispatch(actions.defaultLogoAction(isVeraUser ? null : initialUpdatedLogo));
    },

    setDefaultCustomization: () => (dispatch) => {
        const isVeraUser = checkVeraUser();
        const menuList = updatedMenuListItem(MENU_ITEMS, customizedMenuListIds, isVeraUser);
        const defaultMenuUi = payloadDataMenuItems(menuList);
        const defaultListingColumns = updatedListingColumnData(meshBotsTableColumnsInitialConfig);
        const defaultUpdatedListingColumns = createPayloadForColumns(defaultListingColumns);
        const defaultUpatedListingUiData = finalListingUIData(MESHBOTS, defaultUpdatedListingColumns);

        const defaultTriggerNodes = updatedListNodesCloud(listNodesCloud);
        const defaultActionNodes = updatedListNodeAction(generateOptionsByNotificationType(CLOUD));
        const defaultMeshbotData = finalMeshbotUIData(defaultTriggerNodes, defaultActionNodes);
        const actionCloudNodes = generateOptionsByNotificationType(CLOUD);
        const oemId = oemManager.oem.getOemId();
        const actionLocalNodes = getNodesListForActionsLocalSectionByOemId(oemId);
        const updatedLocalListNodes = getListNodesLocalByOemId(oemId, listNodesLocal);
        const defaultNodesControllables = getPayloadForNodes(
            filterCloudTriggerNodesByInternalDomain(listNodesCloud),
            updatedLocalListNodes,
            actionCloudNodes,
            actionLocalNodes,
        );

        const defaultCustomizationPayload = getCustomizationPayload(
            defaultMenuUi,
            defaultUpatedListingUiData,
            defaultMeshbotData,
            defaultNodesControllables,
        );

        dispatch(actions.defaultCustomization(defaultCustomizationPayload));
    },
    setInitialToCurrentCustomization: (initialCustomizationData) => async (dispatch) => {
        const initialUpdatedLogo = getUpdatedLogo(initialCustomizationData?.initialLogo);
        if (initialUpdatedLogo) {
            dispatch(actions.logoAction(initialUpdatedLogo));
        }
        const initialCustomizationPayload = getCustomizationPayload(
            initialCustomizationData?.initialMenuUi,
            initialCustomizationData?.initialListingUi,
            initialCustomizationData?.initialMeshbotUi,
            initialCustomizationData?.initialNodesControllables,
            initialCustomizationData?.initialColorTheme,
        );
        dispatch(actions.currentCustomization(initialCustomizationPayload));
    },

    setDefaultStateToCurrentCustomization: (initialCustomizationData, isVeraUser) => async (dispatch) => {
        const defaultCustomizationPayload = getCustomizationPayload(
            initialCustomizationData?.defaultMenuUi,
            initialCustomizationData?.defaultListingUi,
            initialCustomizationData?.defaultMeshbotUi,
            initialCustomizationData?.defaultNodesControllables,
            initialCustomizationData?.defaultColorTheme,
        );
        dispatch(actions.currentCustomization(defaultCustomizationPayload));
        if (isVeraUser) {
            dispatch(actions.logoAction(null));
        }
    },

    setCurrentCustomizationData: (currentCustomizationData) => async (dispatch) => {
        const ezlogicPortalCustomization = currentCustomizationData?.ezlogicPortal;
        const kvsPayloadData = currentCustomizationData?.kvsPayloadData;
        const initialCustomizationData = getCustomizationPayload(
            ezlogicPortalCustomization?.menuBar,
            ezlogicPortalCustomization?.listUi,
            ezlogicPortalCustomization?.meshbot,
            ezlogicPortalCustomization?.nodesControllables,
            ezlogicPortalCustomization?.colorTheme,
            kvsPayloadData?.images?.logo,
        );
        dispatch(actions.initialCustomization(initialCustomizationData));
    },

    setDefaultStateToInitialCustomization:
        (initialCustomizationData, isVeraUser, currentKvsPayloadCustomizationData) => async (dispatch) => {
            const initialCustomizationPayload = getCustomizationPayload(
                initialCustomizationData?.defaultMenuUi,
                initialCustomizationData?.defaultListingUi,
                initialCustomizationData?.defaultMeshbotUi,
                initialCustomizationData?.defaultNodesControllables,
                initialCustomizationData?.defaultColorTheme,
                isVeraUser ? initialCustomizationData?.defaultLogo : currentKvsPayloadCustomizationData?.images?.logo,
            );
            dispatch(actions.initialCustomization(initialCustomizationPayload));
        },

    handleKvsSetCustomization: (isVeraUser, encodedCustomizationData, t, isReset) => async (dispatch) => {
        const finalCustomizationData = uploadKvsSetData(
            isVeraUser,
            encodedCustomizationData,
            KVS_KEY,
            KVS_USER_KEY,
            VERA_USER,
        );

        dispatch(actions.handleKvsSetData(finalCustomizationData, isReset, t));
    },

    handleKvsSetData: (data, isReset, t) => async () =>
        apiSetDataToKvs(data)
            .then(() => {
                toast(isReset ? t(CUSTOMIZATION_RESET_SUCCESSFULLY) : t(CUSTOMIZATION_SAVED_SUCCESSFULLY), {
                    type: TOAST_TYPE.SUCCESS,
                });
            })
            .catch((err) => {
                toast(err, { type: TOAST_TYPE.ERROR });
            }),

    listingUiAction: (data) => ({
        type: types.LISTING_UI_ACTION,
        data,
    }),

    meshbotUiAction: (data) => ({
        type: types.MESHBOT_UI_ACTION,
        data,
    }),

    nodesControllablesAction: (data) => ({
        type: types.NODES_CONTROLLABLE_ACTION,
        data,
    }),

    kvsPayloadAction: (data) => ({
        type: types.KVS_PAYLOAD_ACTION,
        data,
    }),

    menuUiAction: (data) => ({
        type: types.MENU_UI_ACTION,
        data,
    }),

    currentCustomization: (data) => ({
        type: types.CURRENT_CUSTOMIZATION,
        data,
    }),

    primaryColorAction: (data) => ({
        type: types.PRIMARY_COLOR_ACTION,
        data,
    }),

    isVeraUser: (data) => ({
        type: types.IS_VERA_USER,
        data,
    }),

    ezlogicColorAction: (data) => ({
        type: types.EZLOGIC_PORTAL_COLOR_THEME,
        data,
    }),

    updateColorTheme: (data) => ({
        type: types.COLOR_THEME_ACTION,
        data,
    }),

    logoAction: (data) => ({
        type: types.LOGO,
        data,
    }),
    defaultLogoAction: (data) => ({
        type: types.DEFAULT_LOGO,
        data,
    }),
};

export default actions;
