/**
 * Another clever approach of writing reducers:
 *
 * export default function(state = initialState, action) {
 *   const actions = {
 *      [ACTION_TYPE]: () => [action.payload.data, ...state]
 *   };
 *
 *   return (_.isFunction(actions[action.type])) ? actions[action.type]() : state
 * }
 */

import * as types from '../constants/ActionTypes';
import update from 'immutability-helper';

const defaultState = {
    executingTechMethod: false,
    gatewayMethodEventData: {},
    throttlingData: {},
};

export default function (state = defaultState, action) {
    switch (action.type) {
        case types.START_CALL_GATEWAY_METHOD:
            return update(state, {
                executingTechMethod: action.method,
                gatewayMethodEventData: {},
            });
        case types.END_CALL_GATEWAY_METHOD:
            return update(state, {
                executingTechMethod: null,
            });
        case types.GATEWAY_METHOD_EVENT_DATA:
            return update(state, {
                gatewayMethodEventData: action.gatewayMethodEventResult,
            });
        case types.LOGOUT:
            return defaultState;
        default:
            return state;
    }
}
