import {
    ALERT_HISTORY,
    CURRENT_PAGE,
    IS_EMPTY_ALERT_HISTORY_LIST,
    IS_LOADING,
    LIST,
    SEARCH_VALUE,
    SELECTED_CATEGORY_ID,
    SELECTED_DATE_TIMESTAMP_IN_MS,
} from './constants';
import { isArray } from 'lodash';
import { filterEvents, getSortedListByDate } from './utils';
import { createSelector } from 'reselect';

/**
 * Represents the selected category ID selector for the alert history state.
 *
 * @param {Object} state - The Redux state object.
 * @returns {string} The selected category ID.
 */
export const selectedCategoryIdSelector = (state) => state[ALERT_HISTORY][SELECTED_CATEGORY_ID];
/**
 * Represents the selected date timestamp in milliseconds selector for the alert history state.
 *
 * @param {Object} state - The Redux state object.
 * @returns {number} The selected date timestamp in milliseconds.
 */
export const selectedDateTimestampInMsSelector = (state) => state[ALERT_HISTORY][SELECTED_DATE_TIMESTAMP_IN_MS];
/**
 * Represents the search value selector for the alert history state.
 *
 * @param {Object} state - The Redux state object.
 * @returns {string} The search value.
 */
export const searchValueSelector = (state) => state[ALERT_HISTORY][SEARCH_VALUE];
/**
 * Represents the loading state selector for the alert history state.
 *
 * @param {Object} state - The Redux state object.
 * @returns {boolean} The loading state.
 */
export const isLoadingSelector = (state) => state[ALERT_HISTORY][IS_LOADING];
/**
 * Represents the current page selector for pagination in the alert history state.
 *
 * @param {Object} state - The Redux state object.
 * @returns {number} The current page number.
 */
export const currentPageSelector = (state) => state[ALERT_HISTORY][CURRENT_PAGE];
/**
 * Selector to get the 'list' property from the 'ALERT_HISTORY' object in the application state.
 *
 * @param {Object} state - The application state.
 * @returns {Array<HistoryEvent> | null} - The list of alerts from the alert history.
 */
export const listSelector = (state) => state?.[ALERT_HISTORY]?.[LIST] || null;

/**
 * Represents the alert history list selector with filtering and sorting.
 *
 * @param {Object} state - The Redux state object.
 * @returns {Array<HistoryEvent> | null} The filtered and sorted alert history list.
 */
export const alertHistoryListSelector = createSelector(
    [listSelector, selectedCategoryIdSelector, searchValueSelector],
    (list, selectedCategoryId, searchValue) => {
        if (!isArray(list)) {
            return [];
        }

        const filteredList = filterEvents(list, selectedCategoryId, searchValue);

        return getSortedListByDate(filteredList);
    },
);
/**
 * Selector function to check if the alert history list in the provided state is empty.
 *
 * @param {AlertHistoryState} state - The current application state.
 * @returns {boolean} - True if the alert history list is empty, false otherwise.
 */
export const isEmptyAlertHistoryListSelector = (state) => {
    return state?.[ALERT_HISTORY]?.[IS_EMPTY_ALERT_HISTORY_LIST];
};
