import React from 'react';

export const IcWarningDelete = (props) => {
    return (
        <svg width="104" height="120" viewBox="0 0 104 120" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M66 0H38V8H66V0Z" fill="#FE5C15" />
            <path
                d="M80 48V104H24V48H80ZM96 32H8V108C8 114.627 13.3726 120 20 120H84C90.6274 120 96 114.627 96 108V32Z"
                fill="#FE5C15"
            />
            <path d="M104 16H0V32H104V16Z" fill="#FE5C15" />
            <path d="M96 32H8V48H96V32Z" fill="#E82E10" />
            <path d="M46 92L46 60H38L38 92H46Z" fill="#FE5C15" />
            <path d="M66 92V60H58L58 92H66Z" fill="#FE5C15" />
        </svg>
    );
};

export default IcWarningDelete;
