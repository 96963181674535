import React from 'react';
import { SvgIcon } from 'lib/@mui';

export const InstallIcon = () => (
    <SvgIcon width="17" height="17" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.92773 8.28585H5.42773L8.29206 11.1502C8.68258 11.5407 9.31575 11.5407 9.70627 11.1502L12.5706 8.28585H10.0706V0.428711H7.92773V8.28585ZM17.5706 15.4287C17.5706 16.643 16.642 17.5716 15.4277 17.5716H2.57059C1.35631 17.5716 0.427734 16.643 0.427734 15.4287H2.57059H15.4277H17.5706Z"
            fill="#F9FAFB"
        />
    </SvgIcon>
);
export default InstallIcon;
