import * as at from '../constants/ActionTypes/Controller';
import { bugsnagNotifyWrapper } from '../containers/ErrorBoundary/utils';
import wsm from '../helpers/wsm';
import { RANDOMIZE_VALUES } from '../constants/queryConstants';

const actions = {
    startRandomizeItemValues:
        ({ serial, timeoutValue }) =>
        async () => {
            const scriptParams = {
                cyclicality: RANDOMIZE_VALUES.CYCLICALITY_FIELD.PERIODIC,
                timeout: timeoutValue,
            };
            await wsm.send(
                serial,
                RANDOMIZE_VALUES.METHOD,
                {
                    script: RANDOMIZE_VALUES.START_SCRIPT,
                    scriptParams,
                },
                () => {},
                (error) => {
                    bugsnagNotifyWrapper(error, { type: at.START_RANDOMIZE_VALUES.rejected });
                },
            );
        },

    stopRandomizeItemValues:
        ({ serial }) =>
        async () => {
            await wsm.send(
                serial,
                RANDOMIZE_VALUES.METHOD,
                {
                    script: RANDOMIZE_VALUES.STOP_SCRIPT,
                },
                () => {},
                (error) => {
                    bugsnagNotifyWrapper(error, { type: at.STOP_RANDOMIZE_VALUES.rejected });
                },
            );
        },
};

export default actions;
