import { Component, Children } from 'react';
import { isNumber } from 'lodash';

export const isDescendant = (parent, child) => {
    let node = child.parentNode;

    while (node !== null) {
        if (node === parent) {
            return true;
        }
        node = node.parentNode;
    }

    return false;
};

export const findScrollableParent = (el) => {
    let node = el.parentNode;

    while (node !== null) {
        if (node.offsetWidth !== node.clientWidth) {
            return node;
        }
        node = node.parentNode;
    }

    return false;
};

export class SmartScrollWrapper extends Component {
    scrollValue = 0;

    componentDidMount() {
        window.addEventListener('mousewheel', this.scrollHandler);
        this.findScrollableElements();
    }

    componentWillUnmount() {
        window.removeEventListener('mousewheel', this.scrollHandler);
    }

    getElement(children, self) {
        function _traverseChildren(children, self) {
            return Children.map(children || self.props.children, (child) => {
                if (child.type.WrappedComponent && child.type.WrappedComponent.name === 'LeftPanel') {
                    return child;
                } else {
                    return child.props.children ? _traverseChildren(child.props.children, self) : undefined;
                }
            });
        }

        const elements = _traverseChildren(children, self);

        return elements;
    }

    findScrollableElements() {
        setTimeout(() => {
            // TODO убрать этот костыль, его я делал только для временного решения что бы сделать функционал
            // const leftPanelDOM = this.getElement(this.props.children, this, '.left-panel-wrapper');
            // const contentSectionDOM = this.traverseChildren(this.props.children, this, '.ezlo-section-content');
            // eslint-disable-next-line
            const leftPanelDOM = document.querySelector('.ezlo-section > .left-panel .left-panel-wrapper'); // TODO сделать поиск елементов нормальным через shadowDom в React
            const contentSectionDOM = document.querySelector('.ezlo-section > .ezlo-section-content');
            this.elements = [leftPanelDOM, contentSectionDOM];
        }, 1000);
    }

    scrollHandler = (e) => {
        const { elements, scrollValue } = this;
        const delta = e.deltaY;

        if (isNumber(delta)) {
            const result = scrollValue + delta;
            const maxScrollHeight = Math.max.apply(
                null,
                elements.map((el) => this._getMaxScrollHeight(el)),
            );
            this.scrollValue = result >= 0 ? (maxScrollHeight >= result || delta < 0 ? result : maxScrollHeight) : 0;

            elements.forEach((el) => el.scroll({ top: this._calculateIfCanScroll(el), behavior: 'smooth' }));
        }
    };

    _getMaxScrollHeight(el) {
        return el.scrollHeight - el.clientHeight;
    }

    _calculateIfCanScroll(el) {
        const { scrollValue } = this;
        const maxScrollValue = this._getMaxScrollHeight(el);

        return scrollValue >= maxScrollValue ? maxScrollValue : scrollValue;
    }

    render() {
        return this.props.children;
    }
}
