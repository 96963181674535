import { GET_CONTROLLERS_CONFIG_KEYS, CONTROLLER_MODELS, LOCAL_TYPE_CONTROLLERS } from '../../constants';

export const configEzlopiControllers = {
    [GET_CONTROLLERS_CONFIG_KEYS.TYPE]: CONTROLLER_MODELS.EZLOPI,
    [GET_CONTROLLERS_CONFIG_KEYS.IS_ONLINE]: true,
};
export const MUI_PROPS = {
    PRIMARY: 'primary',
    CONTAINED: 'contained',
};

export const configLocalControllers = {
    [GET_CONTROLLERS_CONFIG_KEYS.TYPE]: LOCAL_TYPE_CONTROLLERS,
    [GET_CONTROLLERS_CONFIG_KEYS.IS_ONLINE]: true,
};
