/**
 * Hash string, used in password's hash value generation
 * */
export const HASH_STRING = 'oZ7QE6LcLJp6fiWzdqZc';
/**
 * Constant representing the error code for a situation where there are too many login attempts.
 * This value is typically included in the error response.
 *
 * @constant {string}
 * @name TOO_MANY_LOGINS
 * @description Error code indicating that there have been too many login attempts.
 * @example
 * // Example usage in an error response object
 * {
 *   TooManyLogins: '1'
 * }
 */
export const TOO_MANY_LOGINS = 'TooManyLogins';
