export const PK_COUNTRY = {
    USA: 840,
    CANADA: 124,
};
/**
 * Management's requirement to use certain countries
 * More info: https://confluence.mios.com/display/PDMT/PRD+-+%5BEZlogic+Private+Label%5D+Customer+account+info+address+form
 * */
export const PK_COUNTRY_LIST_OF_ALLOWED_COUNTRIES = [
    840, 124, 152, 170, 188, 218, 320, 340, 484, 630, 214, 51, 48, 8, 20, 40, 31, 112, 56, 70, 100, 191, 196, 348, 352,
    372, 380, 428, 438, 440, 442, 470, 498, 499, 492, 528, 578, 616, 620, 642, 643, 674, 688, 703, 705, 724, 752, 756,
    807, 792, 804, 826, 336, 818, 254, 268, 368, 400, 398, 414, 422, 434, 462, 480, 566, 512, 608, 634, 682, 710, 795,
    784, 860, 887,
];

export const ADDRESS_ELEMENT_TYPES = {
    COUNTRIES: 'COUNTRIES',
    REGIONS: 'REGIONS',
    CITIES: 'CITIES',
};

export const VALUE_TYPES = {
    STRING: 'string',
};

export const PK_COUNTRIES_OF_COUNTRIES_WITH_ZIP = [840, 124];
export const CANADA_ZIP_VALIDATION_REGEX = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
export const USA_ZIP_VALIDATION_REGEX = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
