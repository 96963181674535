import React from 'react';

const IcDeviceNoBrokenGlass = (props) => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28 30L30 28L27.1375 25.1375L25 23L20.3414 18.3414L19.8054 17.8054L17.1938 15.1938L15.3125 13.3125L7 5L4.8625 2.8625L2 0L0 2L4 6V23C4 24.7 5.3 26 7 26H16.9L18.3 23L18.5 22.5L18.8 21.9L19.1367 21.1367L21.2045 23.2045L19.9 26H24L28 30ZM17.3231 19.3231L15.666 17.666L14.1 20.2C13.8 20.7 14.4 21.2 14.8 20.9L17.3231 19.3231ZM13.0347 15.0347L11.4 13.4L10 16.2C9.7 16.6 10.3 17.1 10.7 16.8L13.0347 15.0347ZM9.81111 11.8111L7 9V13.4L9.81111 11.8111ZM28 5V22L25 19V5H11L8 2H25C26.7 2 28 3.3 28 5ZM19.6 11.3L18.7215 12.7215L17.5903 11.5903L18.9 10.6C19.3 10.3 19.9 10.8 19.6 11.3Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcDeviceNoBrokenGlass;
