import React from 'react';

import { OEM_IDS } from '../../../services/oem/src/constants/oems';
import { MiosLogo } from '../../icons/visuals/logo/Mios';
import { EzloLogo } from '../../icons/visuals/logo/Ezlo';

export const OemLogo = ({ oemId }) => {
    switch (oemId) {
        case OEM_IDS.VERA_OEM_ID:
            return <MiosLogo />;
        case OEM_IDS.PROPERTY_OEM_ID:
            return <MiosLogo />;
        case OEM_IDS.SECURITY_OEM_ID:
            return <EzloLogo />;
        case OEM_IDS.HORUS_OEM_ID:
            return <MiosLogo />;
        default:
            return <MiosLogo />;
    }
};
