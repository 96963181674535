import wsm from '../helpers/wsm';

const actions = {
    subscribeResultRun: (serial, cb) => () => {
        wsm.subscribe(serial, 'hub.extensions.plugin.run.progress', cb);
    },

    unSubscribeResultRun: (serial) => () => {
        wsm.unsubscribe(serial, 'hub.extensions.plugin.run.progress');
    },
};

export default actions;
