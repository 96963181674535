import React from 'react';
import {
    EZLOGIC_TITLE_ALL_EVENTS,
    EZLOGIC_TITLE_DATE_AND_TIME,
    EZLOGIC_TITLE_DESCRIPTION,
    EZLOGIC_TITLE_DEVICE,
    EZLOGIC_TITLE_EVENT_TYPE,
    EZLOGIC_TITLE_IMAGE,
    EZLOGIC_TITLE_TRIGGER,
    EZLOGIC_TITLE_VIDEO,
} from 'constants/language_tokens';
import SystemHistoryEventTypeCell from '../SystemHistoryLayout/SystemHistoryTableConrainer/SystemHistoryTableUI/SysytemHistoryEventTypeCell';

export const at = {
    UPDATE_SYSTEM_HISTORY_TABLE: {
        PENDING: 'UPDATE_SYSTEM_HISTORY_TABLE/pending',
        SUCCESS: 'UPDATE_SYSTEM_HISTORY_TABLE/success',
        REJECT: 'UPDATE_SYSTEM_HISTORY_TABLE/reject',
    },

    CANNOT_GET_HISTORY_LIST: 'Cannot get history list',
    SET_HISTORY_SEARCH_BY_DATE_BEFORE: 'SET_HISTORY_SEARCH_BY_DATE_BEFORE',
    SET_HISTORY_SEARCH_BY_DATE_AFTER: 'SET_HISTORY_SEARCH_BY_DATE_AFTER',
    SET_HISTORY_SEARCH_BY_EVENT_TYPE: 'SET_HISTORY_SEARCH_BY_EVENT_TYPE',
    SET_HISTORY_SEARCH_BY_DESCRIPTION: 'SET_HISTORY_SEARCH_BY_DESCRIPTION',
    SET_IS_VALID_DATE: 'SET_IS_VALID_DATE',
};

export const buildSystemHistoryTableColumns = () => {
    return [
        {
            accessorKey: 'eventType',
            headerAlign: 'left',
            header: EZLOGIC_TITLE_EVENT_TYPE,
            muiTableHeadCellProps: { align: 'left' },
            muiTableBodyCellProps: { align: 'left' },
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnActions: false,
            Cell: ({ cell }) => {
                return <SystemHistoryEventTypeCell params={cell.row.original} />;
            },
        },
        {
            accessorKey: 'description',
            headerAlign: 'center',
            header: EZLOGIC_TITLE_DESCRIPTION,
            muiTableHeadCellProps: { align: 'center' },
            muiTableBodyCellProps: { align: 'left' },
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnActions: false,
        },
        {
            accessorKey: 'dateAndTime',
            headerAlign: 'center',
            header: EZLOGIC_TITLE_DATE_AND_TIME,
            muiTableHeadCellProps: { align: 'center' },
            muiTableBodyCellProps: { align: 'center' },
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnActions: false,
        },
    ];
};
export const TEMPERATURE = 'temperature';

export const EVENT_TYPES = [
    { value: 0, label: EZLOGIC_TITLE_ALL_EVENTS },
    { value: 1, label: EZLOGIC_TITLE_IMAGE },
    { value: 2, label: EZLOGIC_TITLE_VIDEO },
    { value: 3, label: EZLOGIC_TITLE_TRIGGER },
    { value: 4, label: EZLOGIC_TITLE_DEVICE },
];

export const YEAR_LABEL = 'year';
export const MONTH_LABEL = 'month';
export const DAY_LABEL = 'day';

export const EZLOGIC_TITLE_SYSTEM_HISTORY = 'ezlogic.title.system_history';
export const EZLOGIC_SEARCH_TITLE = 'ezlogic.search.title';
