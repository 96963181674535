import { filterPdmRequestedCloudCapabilities } from './filterPdmRequestedCloudCapabilities';

/**
 * Filters all abstract device capabilities for trigger
 * @param { Array } capabilitiesOfAbstract - abstract device capabilities list
 * @return { Array } filtered abstract device capabilities
 * @example
 * filterCapabilitiesForCloudTriggers(['name', 'name_command'])
 */
export const filterCapabilitiesForCloudTriggers = (capabilitiesOfAbstract = []) => {
    const capabilities = filterPdmRequestedCloudCapabilities(capabilitiesOfAbstract);

    return capabilities.filter((capability) => {
        return capability?.capability_name
            ? capability?.capability_name?.includes('command')
            : !capability?.includes('command');
    });
};
