import React from 'react';

const IcActionsBatteryChargingFull = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 8H22C23.7 8 25 9.3 25 11V21C25 22.6 23.7 24 22 24H4C2.3 24 1 22.6 1 21V11C1 9.3 2.3 8 4 8ZM11.8 16.6V22L18.3 15.4H14.2V10L7.6 16.6H11.8ZM31 16C31 14.3 29.7 13 28 13V19C29.7 19 31 17.7 31 16Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcActionsBatteryChargingFull;
