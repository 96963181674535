import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Switch } from 'lib/@mui';

import EzloActions from '../../actions/EzloActions';

import { MeshBotAction } from 'actions';
import { isCloudMeshBotType } from 'containers/Ezlo/EzloMeshbot/utils';
import { MESHBOT_TYPES } from '../../containers/Ezlo/EzloMeshbots/constants';

import './style.scss';

const MeshbotSwitch = ({ renderedCellValue: params }) => {
    const [switcher, setSwitcher] = useState(true);
    const dispatch = useDispatch();
    const isDisabledSwitchBtn = params && params.isDisabled;
    const isEzlopiType = params.type === MESHBOT_TYPES.EZLOPI;

    useEffect(() => {
        setSwitcher(params.enabled);
    }, [params]);

    const handleChange = (event) => {
        const { serial, id, type } = params || {};
        const isMeshBotActive = event.target.checked;
        if (serial) {
            dispatch(EzloActions.updateLocalMeshBotStatus(serial, id, isMeshBotActive));
        } else if (isCloudMeshBotType(type)) {
            dispatch(MeshBotAction.updateCloudMeshbotStatus(params, isMeshBotActive));
        }
        setSwitcher(isMeshBotActive);
    };

    return (
        <div className="switch-wrapper">
            <Switch
                size="medium"
                disabled={isDisabledSwitchBtn || isEzlopiType}
                checked={switcher}
                onChange={handleChange}
                color="primary"
                name="switcher"
                inputProps={{ 'aria-label': 'primary checkbox' }}
            />
        </div>
    );
};

export default MeshbotSwitch;
