import React from 'react';
import { Formik } from 'formik';

import useKvsActions from '../../../../../../hooks/useKvsActions';
import { validationSchema } from '../../../utils';
import { INITIAL_VALUE_OF_CREATE_LABEL_FORM } from '../../../constants';
import { FormikLabelDialogContainer } from '../LabelDialogContainer';
import LabelForm from '../LabelForm';
import { EZLOGIC_BUTTON_CREATE, EZLOGIC_TITLE_ADD_NEW_LABEL } from '../../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../languages';

const CreateLabelDialog = ({ open, closeDialog }) => {
    const { updateMeshBotLabels } = useKvsActions();
    const { t } = useTranslate();

    const onSubmit = async (values, { setSubmitting, resetForm }) => {
        const { isError } = await updateMeshBotLabels(values);

        if (!isError) {
            closeDialog();
            resetForm();
        } else {
            setSubmitting(false);
        }
    };

    return (
        <Formik
            initialValues={INITIAL_VALUE_OF_CREATE_LABEL_FORM}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            <FormikLabelDialogContainer
                open={open}
                closeDialog={closeDialog}
                dialogTitle={t(EZLOGIC_TITLE_ADD_NEW_LABEL)}
            >
                <LabelForm submitButtonText={t(EZLOGIC_BUTTON_CREATE)} />
            </FormikLabelDialogContainer>
        </Formik>
    );
};

export default CreateLabelDialog;
