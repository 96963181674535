export const ENGLISH_KEY = 'en';
export const LANGUAGE_TO_DIRECTORY_NAME_CONTAINER = {
    en: 'en',
    fr: 'fr',
    es: 'es',
    de: 'de',
};

export const LANGUAGE = 'language';
export const TRANSLATIONS = 'translations';
