import React from 'react';

const IcActionsLevelSensor = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="#1C1E21" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.7515 8.99248L21.6132 10.0272L20.0603 15.8228L10.4014 13.2347L11.9543 7.43913L11.9559 7.43957C11.5275 9.03978 12.4772 10.6844 14.0774 11.1132C15.6776 11.542 17.3224 10.5925 17.7515 8.99248ZM22.9581 16.5992L24.511 10.8037L26.1706 11.2484C27.771 11.6772 28.7207 13.3222 28.2919 14.9226C27.8631 16.523 26.2181 17.4728 24.6177 17.0439L22.9581 16.5992ZM9.05654 6.66268L8.26519 6.45064C6.66479 6.02181 5.01978 6.97156 4.59096 8.57196C4.16213 10.1724 5.11188 11.8174 6.71227 12.2462L7.50362 12.4582L9.05654 6.66268ZM1.69318 7.7955C2.55083 4.5947 5.84085 2.69521 9.04165 3.55286L26.9471 8.3506C30.1478 9.20825 32.0473 12.4983 31.1897 15.6991C30.332 18.8999 27.042 20.7994 23.8412 19.9417L5.93582 15.144C2.73502 14.2863 0.835527 10.9963 1.69318 7.7955Z"
            />
            <path fillRule="evenodd" clipRule="evenodd" d="M0 16.6941L31 24.6941V28.6941H0V24.6941V16.6941Z" />
        </svg>
    );
};

export default IcActionsLevelSensor;
