import * as types from '../constants/ActionTypes';

import produce from 'immer';
import { SUPPORT, STATUSES } from '../constants/Support';

const INITIAL_STATE = {
    supportWebsite: '',
    users: [],
    listOfPriorities: [],
    issue: '',
    supportDescription: '',
    supportEmail: '',
    kvsToken: '',
    supportPhone: '',
    priority: SUPPORT.PRIORITY,
    isAvailable: false,
    userUuid: '',
    subdomain: '',
    api_key: '',
    listOfHelptopics: [],
    helptopic: SUPPORT.HELPTOPIC,
    partnerUuid: '',
    ticketCreationStatus: STATUSES.DEFAULT,
};

export default produce((draft, action) => {
    switch (action.type) {
        case types.UPDATE_EZLO_SUPPORT_DATA:
            draft.supportDescription = action?.data?.description;
            draft.supportEmail = action?.data?.email;
            draft.supportPhone = action?.data?.phone;
            draft.supportWebsite = action?.data?.website;
            draft.isAvailable = true;
            break;
        case types.UPDATE_USER_UUID_FROM_KVS:
            draft.userUuid = action.data;
            break;
        case types.UPDATE_SUPPORT_USERS:
            draft.users = action.data;
            break;
        case types.UPDATE_TICKET_DATA_FROM_UI:
            draft[action.data.key] = action.data.value;
            break;
        case types.UPDATE_KVS_SUPPORT_TOKEN:
            draft.kvsToken = action.data.authorization;
            draft.subdomain = action.data.subdomain;
            draft.api_key = action.data.api_key;
            break;
        case types.UPDATE_LIST_OF_PRIORITIES:
            draft.listOfPriorities = action.data;
            break;
        case types.UPDATE_LIST_OF_HELPTOPICS:
            draft.listOfHelptopics = action.data;
            break;
        case types.UPDATE_PARTNER_UUID:
            draft.partnerUuid = action.data;
            break;
        case types.SET_TICKET_CREATION_STATUS:
            draft.ticketCreationStatus = action.data;
            break;
        case types.LOGOUT:
            return INITIAL_STATE;
        default:
            return draft;
    }
}, INITIAL_STATE);
