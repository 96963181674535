import {
    EZLOGIC_TITLE_AUTHOR,
    EZLOGIC_TITLE_DESCRIPTION,
    EZLOGIC_TITLE_PLUGIN_VERSION,
    EZLOGIC_TITLE_SUPPORTED_MODELS,
} from './language_tokens';

export const DASHBOARD_CREATE = 'dashboard_create';
export const HTTP = 'http';
export const MQTT = 'mqtt';
export const MIN = 'min';
export const MAX = 'max';
export const ID = 'id';
export const OBJECT = 'object';
export const CAPABILITIES = 'capabilities';
export const BIGGER = 'bigger';
export const LOWER = 'lower';
export const EQUAL = 'equal';
export const MAIN_REQUEST_BLOCK_FIELDS = ['method', 'uri', 'checkbox', 'content_type', 'headers', 'data'];
export const SET_LEVEL = 'set_level';
export const PROTOCOL = 'protocol';
export const PATTERNS = 'patterns';
export const MATCHES_RESULT = 'matches-result';
export const FENGARI_LOAD = `return function(string, pattern)
      local r1, r2, r3, r4, r5, r6, r7, r8, r9, r10 = string.match(string, pattern)
      total = string.format("%sˍ%sˍ%sˍ%sˍ%sˍ%sˍ%sˍ%sˍ%sˍ%sˍ%s", r1, r2, r3, r4, r5, r6, r7, r8, r9, r10, r11)
      return total
    end`;

export const HIGHLITED_STRING = 'highlitedString';
export const CHECKBOX = 'checkbox';
export const LEVEL_PLACEHOLDER = '{ level: {{VALUE}} }';

export const META_DATA_FIELDS = [
    {
        name: 'author',
        labelKey: EZLOGIC_TITLE_AUTHOR,
    },
    {
        name: 'description',
        labelKey: EZLOGIC_TITLE_DESCRIPTION,
    },
    {
        name: 'supported_models',
        labelKey: EZLOGIC_TITLE_SUPPORTED_MODELS,
    },
    { name: 'plugin_version', labelKey: EZLOGIC_TITLE_PLUGIN_VERSION },
];

export const MQTT_TOPIC = 'mqtt_topic';
export const ON_STATE = 'on_state';
export const OFF_STATE = 'off_state';
export const AUTO = 'auto';
export const COOL = 'cool';
export const HEAT = 'heat';
export const APPLICATION_JSON = 'application/json';
export const ELEMENT_A = 'a';
export const JSON_FORMAT = '.json';
export const NONE = 'None';
export const BASIC = 'Basic';
export const RAW_VALUE = 'Raw value';
export const HTTP_AUTH = [
    { id: '1', value: NONE, label: NONE },
    { id: '2', value: BASIC, label: BASIC },
    { id: '3', value: RAW_VALUE, label: RAW_VALUE },
];
export const AUTHORIZATION = 'Authorization';

export const AUTH_TYPE = 'AUTH_TYPE';
export const BLOB = 'blob';
export const EZLO_DOWNLOAD_API = 'https://download-cloud.ezlo.com';
