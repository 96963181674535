import React from 'react';

const IcActionsTarget = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M14 29H11V15.5H14V29ZM7 23H4V29H7V23ZM21 17H18V29H21V17ZM28 11H25V29H28V11Z" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.0001 4L20.0723 12.0729L11.1024 11.0762L4 20V15.5L9.89742 7.92389L18.9276 8.92725L28.0001 0V4Z"
            />
        </svg>
    );
};

export default IcActionsTarget;
