import { ABSTRACT_COMMAND } from '../../../constants/cloudCallNames';
import { VOI_SUPPORTED_DEVICE_CATEGORIES } from './constants';

export const buildCreateVirtualDeviceConfiguration = (state) => {
    const configuration = { map: [] }; // by default;

    // 1. Process devices. For each device: // TODO: not implemented on the Cloud
    // 1.1 Iterate over device's capabilities
    // 1.2 Prepare map item for each capability with direct mapping
    // 2. Process services. For Each service: // TODO: not implemented on the Cloud
    // 2.1 Iterate over service's capabilities
    // 2.2 Prepare map item for each capability with direct mapping
    // 3. Handle Vera Mobile device settings

    // const checkedDevices = state[`selected${MANAGE_VIRTUAL_DEVICE_FORM_CATEGORIES.DEVICES}`];
    // const checkedServices = state[`selected${MANAGE_VIRTUAL_DEVICE_FORM_CATEGORIES.SERVICES}`];
    const { veraMobileCompatibleMap } = state;

    const devicesMappingArr = [];
    const servicesMappingArr = [];
    const veraMobileMapping = [];

    // 1.
    // Array.from(checkedDevices.values()).forEach((checkedDevice) => {
    //     Array.from(checkedDevice.capabilities).forEach((capability) => {
    //         devicesMappingArr.push({
    //             capability: capability,
    //             map_type: 'not_configured',
    //             // capability: capability,
    //             // map_type: 'direct_mapping',
    //             // map_conf: {
    //             //     abstract: checkedDevice.uuid,
    //             //     capability: capability,
    //             //     command: 'set'
    //             // }
    //         });
    //     });
    // });

    // 2.
    // Array.from(checkedServices.values()).forEach((checkedService) => {
    //     Array.from(checkedService.capabilities).forEach((capability) => {
    //         servicesMappingArr.push({
    //             capability: capability,
    //             map_type: 'not_configured',
    //             // capability: capability,
    //             // map_type:"direct_mapping",
    //             // map_conf: {
    //             //     abstract: checkedService.uuid,
    //             //     capability: capability,
    //             //     command: 'set'
    //             // }
    //         });
    //     });
    // });

    // 3.
    const [voiDeviceSettings] = Array.from(veraMobileCompatibleMap.values());
    veraMobileMapping.push({
        map_type: 'power_x_text_command',
        capability: 'power_command',
        map_conf: {
            text_device: {
                language: 'en',
                name: voiDeviceSettings.name,
            },
            command: 'send',
            flags: {
                reverse: 0,
            },
            capability: 'text_command',
            abstract: voiDeviceSettings.uuid,
        },
    });
    if (voiDeviceSettings.category === VOI_SUPPORTED_DEVICE_CATEGORIES.DIMMER) {
        veraMobileMapping.push({
            map_type: 'dimmer_x_text_command',
            capability: 'dimmer_command',
            map_conf: {
                text_device: {
                    language: 'en',
                    name: voiDeviceSettings.name,
                },
                command: 'send',
                flags: {
                    reverse: 0,
                },
                capability: 'text_command',
                abstract: voiDeviceSettings.uuid,
            },
        });
    }

    configuration.map = [...devicesMappingArr, ...servicesMappingArr, ...veraMobileMapping];

    return configuration;
};

/**
 * Returns 'params' object for CREATE virtual device request's payload.
 * More info {@link https://confluence.mios.com/display/EPD/Virtual+Device+-+Set here}
 * @param {string} name - name of virtual device
 * @param {object} configuration - configuration of virtual device
 * @returns {object} params - prepared 'params' object.
 * */
export const buildCreateVirtualDeviceParams = (name, configuration) => {
    return { name, configuration };
};

/**
 * Returns 'params' object for EDIT virtual device request's payload.
 * More info {@link https://confluence.mios.com/display/EPD/Virtual+Device+-+Set here}
 * @param {string} uuid - virtual device's abstract UUID
 * @param {string} [name] - new name of virtual device
 * @param {object} [configuration] - new configuration of virtual device
 * @returns {object} params - prepared 'params' object.
 * */
export const buildEditVirtualDeviceParams = (uuid, name, configuration) => {
    const params = { uuid };

    if (name) {
        params.name = name;
    }

    if (configuration) {
        params.configuration = configuration;
    }

    return params;
};

/**
 * Returns 'params' object for DELETE virtual device request's payload.
 * More info {@link https://confluence.mios.com/display/EPD/Virtual+Device+-+Set here}
 * @param {string} uuid - virtual device's abstract UUID
 * @returns {object} params - prepared 'params' object.
 * */
export const buildDeleteVirtualDeviceParams = (uuid) => {
    return { uuid, delete: 1 };
};

/**
 * Returns body object for 'virtual_device_set' request.
 * More info {@link https://confluence.mios.com/display/EPD/Virtual+Device+-+Set here}
 * @param {string} params - 'params' object
 * @returns {object} body - request body.
 * */
export const buildVirtualDeviceSetRequestBody = (params) => {
    return { call: 'virtual_device_set', version: '1', params };
};

/**
 * List virtual devices from the same location as the user from token.
 * Returns body object for 'virtual_device_list' request.
 * More info {@link https://confluence.mios.com/display/EPD/Virtual+Device+-+List here}
 * @param {array} [uuids] - array of uuids, request only a specific list of uuids
 * @param {int} [configuration] - read configuration flag (possible values: 0 or 1)
 * @returns {object} body - request body.
 * */
// eslint-disable-next-line
export const buildVirtualDeviceListRequestBody = (uuids, configuration) => {
    const body = { call: 'virtual_device_list' };
    const params = {};

    // todo: update
    // if (uuids)
    //     params.uuids = uuids;
    // todo: update
    // if (configuration)
    //     params.configuration = 1;

    params.configuration = 1;
    body.params = params;

    return body;
};

/**
 * List of abstract devices the user has access to, across all locations and controllers.
 * Returns body object for 'abstract_list_detailed' request.
 * More info {@link https://confluence.mios.com/display/EPD/Abstract+-+List+Abstracts+Detailed here}
 * @returns {object} body - request body.
 * */
export const buildAbstractListDetailedRequestBody = () => {
    return { call: 'abstract_list_detailed' };
};

/**
 * Returns 'params' object for 'text_command' request's payload.
 * More info {@link https://confluence.mios.com/display/EPD/Abstract+-+Run+Command here}
 * @returns {object} body - request body.
 * */
export const buildTextCommandParams = (uuid, text) => {
    return {
        type: 'command',
        capability: 'text_command',
        command: 'send',
        uuid,
        parameters: {
            text,
        },
    };
};

/**
 * Returns 'params' object for 'dimmer_command' request's payload.
 * More info {@link https://confluence.mios.com/display/EPD/Abstract+-+Run+Command here}
 * @returns {object} body - request body.
 * */
export const buildDimmerCommandParams = (uuid, targetPercentage) => {
    return {
        type: 'command',
        capability: 'dimmer_command',
        command: 'set',
        uuid,
        parameters: {
            target_percentage: 100 - targetPercentage, // TODO: need clarify
        },
    };
};

/**
 * Returns 'params' object for 'power_command' request's payload.
 * More info {@link https://confluence.mios.com/display/EPD/Abstract+-+Run+Command here}
 * @returns {object} body - request body.
 * */
export const buildPowerCommandParams = (uuid, targetStatus) => {
    return {
        type: 'command',
        capability: 'power_command',
        command: 'set',
        uuid,
        parameters: {
            target_status: targetStatus,
        },
    };
};

/**
 * Run a command for an abstract device.
 * Returns body object for 'abstract_command' request.
 * More info {@link https://confluence.mios.com/display/EPD/Abstract+-+Run+Command here}
 * @returns {object} body - request body.
 * */
export const buildAbstractCommandRequestBody = (params) => {
    return { call: ABSTRACT_COMMAND, params };
};
