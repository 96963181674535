export const CODE_KEY = 'code';
export const IS_SIMPLE_DISABLED_KEY = 'isSimpleDisabled';
export const CODE_ARRAY_KEY = 'codeArray';
export const SELECTED_SAVE_KEY = 'selectedSaveOutput';
export const MAP_TYPE_KEY = 'mapType';

export const VARIABLE = 'variable';
export const VALUE_TYPE = 'value';
export const STRING = 'string';
export const RAW_JSON = 'raw_json';

export const ITEM_OF_RESULTS = 0;
export const ITEM_CONTAINED_VALUE = 2;

export const STARTED_TEMPLATE_FOR_CODE_SIMPLE = '// Simple \n';

export const TYPES_OF_FUNCTIONS = {
    SLICE: 'slice',
    FIND: 'includes',
    JSON: 'json',
};

export const SIMPLE_CODE_FUNCTIONS = {
    SLICE: {
        uuid: TYPES_OF_FUNCTIONS.SLICE,
        name: 'get String From Position',
    },
    FIND: {
        uuid: TYPES_OF_FUNCTIONS.FIND,
        name: 'check String has word',
    },
    JSON: {
        uuid: TYPES_OF_FUNCTIONS.JSON,
        name: 'get String from json path',
    },
};

export const CUSTOM_PREFIX = 'custom_';
export const CREATE_VARIABLE = 'Add manually';
export const DRAG_ITEMS_TEXT = 'Drag your data items here';
export const CLOUD_VARIABLES_TITLE = 'Cloud variables';
export const DIALOG_BUTTON = 'Save';
export const DIALOG_VARIABLE_TITLES = {
    CREATE: 'Create custom variable',
    EDIT: 'Edit variable',
    VARIABLES: 'Your variables',
};
export const POSITION_MIN_NUMBER = 0;
export const EMPTY_LINE = '';
export const USE_VARIABLE = 'Use variable';
export const IS_USE_VARIABLE_FIELD = false;
export const FUNCTION_SELECT_LABEL = 'Function';
