import { useSelector } from 'react-redux';
import { buildLabelsInArrayFormat, kvsMeshBotLabelsSelector } from '../../utils';
import { useMemo } from 'react';
import { sortObjectsListAlphabetically } from '../../../../../services/utilityService';
import { LABEL_FIELDS } from '../../constants';

const useMeshBotLabelsStateForListing = () => {
    const { labels } = useSelector(kvsMeshBotLabelsSelector);

    return useMemo(() => {
        const filteredLabelsByDeletedField = buildLabelsInArrayFormat(labels);

        return sortObjectsListAlphabetically(filteredLabelsByDeletedField, LABEL_FIELDS.NAME);
    }, [labels]);
};

export default useMeshBotLabelsStateForListing;
