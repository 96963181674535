import React from 'react';
import { StrictMode } from 'react';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { store } from './store/configureStore';
import Main from './containers/Main';
import EzloToast from './containers/EzloToast';
import ErrorBoundary from './containers/ErrorBoundary';

import 'react-toastify/dist/ReactToastify.min.css';
import './index.scss';

const AppRoot = () => {
    return (
        <StrictMode>
            <ErrorBoundary>
                <Provider store={store}>
                    <HashRouter>
                        <Main />
                    </HashRouter>
                    <EzloToast />
                </Provider>
            </ErrorBoundary>
        </StrictMode>
    );
};

export default AppRoot;
