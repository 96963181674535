/**
 * Contains PK_PermissionRole possible values
 * */
export const PK_PERMISSION_ROLE = {
    OEM_MASTER_USER: 3,
    PARTNER: 5,
    INSTALLER: 7,
    END_USER: 10,
};

/** PK_PermissionRole property name*/
export const PK_PERMISSION_ROLE_PROP_NAME = 'PK_PermissionRole';
