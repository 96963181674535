import React, { useState, useRef } from 'react';
import classNames from 'classnames';

import { EyeIcon } from '../../assets/icons';
import Tooltip from '../Tooltip';
import { ERROR } from '../../constants/Errors';
import { SUCCESS } from '../../constants/Plugins';
import { FIELD_TYPES } from '../../constants/Users';
import { ZERO_INT } from '../../constants/MeshbotConstant';
import * as regexp from '../../constants/Regexp';

import styles from './Input.module.scss';

const Input = (props) => {
    const {
        validationState,
        className,
        style,
        type,
        inputLabel,
        inputStyle,
        errorMessage,
        tooltipPosition,
        defaultValue,
        errorStatus,
        trimValue,
        onFocus,
        onBlur,
        needToShowRedBorder,
        validate,
        onInput,
        ...restProps
    } = props;

    const [inputValidation, setInputValidation] = useState(validationState);
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const inputType = isShowPassword ? FIELD_TYPES.TEXT : type;
    const isPasswordInput = type === FIELD_TYPES.PASSWORD;
    const inputRef = useRef(null);

    const handleInput = (e) => {
        const value = trimValue && e.target.value ? e.target.value.trim() : e.target.value;
        const isValid = validate(value);

        if (!value && value.length === ZERO_INT) {
            setIsShowPassword(false);
        }

        const filteredByLatin = value.replace(regexp.NON_LATIN_CHARS, '');

        onInput(filteredByLatin, e);
        setIsFocused(true);

        if (isValid !== null) {
            setInputValidation(isValid ? SUCCESS : ERROR);
        }
    };

    const handleBlurInput = (e) => {
        if (trimValue && e.target.value) {
            e.target.value = e.target.value.trim();
        }

        setIsFocused(false);
        onBlur(e);
    };

    const handleFocusInput = (e) => {
        if (trimValue && e.target.value) {
            e.target.value = e.target.value.trim();
        }

        setIsFocused(true);

        if (onFocus) {
            onFocus(e);
        }
    };

    const toggleShowPassword = () => {
        setIsShowPassword(!isShowPassword);
    };

    const isInputNotValid = () => {
        if (needToShowRedBorder) {
            if (!isFocused && inputValidation === ERROR) {
                return true;
            }
        } else {
            if (isFocused && inputValidation === ERROR && !inputRef) {
                return false;
            } else if (!isFocused && inputValidation === ERROR) {
                return true;
            } else {
                return inputValidation === ERROR;
            }
        }
    };

    return (
        <div
            className={classNames(styles.formInput, className, {
                'error': isInputNotValid(),
                'password-input': isPasswordInput,
            })}
            style={style}
        >
            {inputLabel && <label>{inputLabel}</label>}
            <input
                {...restProps}
                type={inputType}
                ref={inputRef}
                style={inputStyle}
                onClick={setIsFocused}
                onInput={handleInput}
                onBlur={handleBlurInput}
                onFocus={handleFocusInput}
            />
            {isPasswordInput && (
                <div
                    className={classNames(styles.showPasswordBtn, {
                        'disabled': !defaultValue || defaultValue.length === ZERO_INT,
                        'password-showed': isShowPassword,
                    })}
                    onClick={toggleShowPassword}
                >
                    <EyeIcon className={styles.eyeIcon} />
                </div>
            )}
            {errorStatus && isFocused && (
                <Tooltip
                    title={errorMessage}
                    className={styles.error}
                    position={tooltipPosition}
                    show={isInputNotValid()}
                ></Tooltip>
            )}
        </div>
    );
};

export default Input;

Input.defaultProps = {
    inputLabel: '',
    defaultValue: '',
    type: 'text',
    placeholder: '',
    errorMessage: '',
    tooltipPosition: 'right',
    validationState: '',
    trimValue: false,
    readOnly: false,
    disabled: false,
    needToShowRedBorder: false,
    validate: function () {
        return null;
    },
    onInput: function () {},
    onChange: function () {},
    onKeyDown: function () {},
    onBlur: function () {},
};
