import React from 'react';

export const Upgrade = (props) => (
    <svg {...props} width="12" height="18" viewBox="0 0 12 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.28571 4H10.2857L6.70711 0.421393C6.31658 0.0308685 5.68342 0.0308683 5.29289 0.421393L1.71429 4H4.71429V13.4286H7.28571V4ZM12 17.7143C12 19.1714 10.8857 20.2857 9.42857 20.2857H2.57143C1.11429 20.2857 0 19.1714 0 17.7143H2.57143H9.42857H12Z"
            fill="#00B491"
        />
    </svg>
);
export default Upgrade;
