import React from 'react';
import MeshbotRunCell from './MeshbotRunCell';
import useLocalMeshBotRunState from 'hooks/useLocalMeshBotRunState';

const LocalMeshbotRun = ({ id, serial, isDisabled, status, runScene }) => {
    const { isButton, isButtonDisabled, handleButtonClick } = useLocalMeshBotRunState({ id, serial, status, runScene });

    return (
        <MeshbotRunCell
            handleButtonClick={handleButtonClick}
            status={status}
            isButton={isButton}
            isDisabled={isDisabled || isButtonDisabled}
        />
    );
};

export default LocalMeshbotRun;
