import React from 'react';

const IcActionsHardwareFailure = (props) => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
            <path
                d="M3.8498 6H1.9498V21C1.9498 22.7 3.2498 24 4.9498 24H5.9498V18H8.9498V24H12.4498V18H15.4498V24H18.9498V21.1L25.3498 27.5L27.5498 25.4L25.1498 23L5.9498 3.9L2.5498 0.4L0.449804 2.5L3.8498 6ZM8.9498 2.6V0H18.9498V3H21.9498V6H25.9498V19.6L8.9498 2.6Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcActionsHardwareFailure;
