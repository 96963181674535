import { API_CLOUD_EZLO_REQUEST_URL } from '../constants/URLs';
import { axiosCloudConfig } from '../api/config';
import {
    CALL_VERSION,
    DEFAULT_BUCKET,
    REQUEST_METHOD_POST,
    STORAGE_LIST_CALL,
    CONTROLLER_BACKUPS_BUCKET,
    BACKUP_CALL_DEFAULT_FILTERS,
} from '../containers/Ezlo/EzloHubBackups/constants';
import { ERROR_STATUS } from '../constants/api';
import { t } from '../helpers/language';
import { EZLOGIC_TITLE_SOMETHING_WENT_WRONG } from '../constants/language_tokens';
import { displayBackupsErrorToast } from '../containers/Ezlo/EzloHubBackups/BackupsToastErrorMessage';

const fetchStorageList = async (bucket, controller_uuid, filters) => {
    try {
        const response = await fetch(API_CLOUD_EZLO_REQUEST_URL(), {
            method: REQUEST_METHOD_POST,
            ...axiosCloudConfig(),
            body: JSON.stringify({
                call: STORAGE_LIST_CALL,
                version: CALL_VERSION,
                params: {
                    filters: {
                        ...BACKUP_CALL_DEFAULT_FILTERS,
                        bucket,
                        meta: { 'controller_info.controller_uuid': controller_uuid },
                        ...filters,
                    },
                },
            }),
        });

        const payload = await response.json();
        if (!response.ok) {
            throw new Error(payload?.error?.message);
        }

        if (payload.status === ERROR_STATUS) {
            const errorMessage =
                payload?.data?.error_text || payload?.data?.error_message || t(EZLOGIC_TITLE_SOMETHING_WENT_WRONG);

            throw new Error(errorMessage);
        }

        return payload?.data?.list;
    } catch (error) {
        displayBackupsErrorToast(error);
    }
};

export const fetchBackupsStorageList = (controller_uuid, filters) => {
    return fetchStorageList([DEFAULT_BUCKET, CONTROLLER_BACKUPS_BUCKET], controller_uuid, filters);
};
