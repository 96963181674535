import { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { GenericActions } from '../../actions';
import { useLocation } from 'react-router-dom';

const LocationContainer = ({ children }) => {
    const dispatch = useDispatch();
    const location = useLocation();

    useLayoutEffect(() => dispatch(GenericActions.locationChange(location)), [location]);

    return children;
};

export default LocationContainer;
