import React, { useMemo } from 'react';
import { Tooltip } from 'lib/@mui';

import { IcControlsArrowRight } from '../../../../../assets/icons';
import { useTranslate } from '../../../../languages';
import {
    EZLOGIC_TITLE_HIDE_LABELS_PANEL,
    EZLOGIC_TITLE_LABELS,
    EZLOGIC_TITLE_SHOW_LABELS_PANEL,
} from '../../../../../constants/language_tokens';

import styles from '../MeshBotLabelsPanel/styles.module.scss';

const LabelsPanelHeader = ({ open, toggleOpenDialog }) => {
    const { t } = useTranslate();
    const tooltipTitle = useMemo(
        () => t(open ? EZLOGIC_TITLE_HIDE_LABELS_PANEL : EZLOGIC_TITLE_SHOW_LABELS_PANEL),
        [open, t],
    );

    return (
        <div className={styles.labelsPanelHeader}>
            <h3>{t(EZLOGIC_TITLE_LABELS)}</h3>
            <Tooltip title={tooltipTitle} placement="top-start">
                <div className={styles.labelsPanelButtonBox}>
                    <div className={styles.labelsPanelButton} onClick={toggleOpenDialog}>
                        <IcControlsArrowRight />
                    </div>
                </div>
            </Tooltip>
        </div>
    );
};

export default LabelsPanelHeader;
