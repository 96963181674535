import React from 'react';

const IcThermostatSetpointIconWithFrost = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M29.1971 11.3155C29.0046 11.2713 28.8045 11.248 28.5996 11.248C27.1756 11.248 25.9596 12.4 25.9596 13.84C25.9596 15.28 27.1756 16.448 28.5996 16.448C29.111 16.448 29.5921 16.3007 29.9999 16.0462C29.975 23.7569 23.7166 30 16 30C8.26801 30 2 23.732 2 16C2 8.26801 8.26801 2 16 2C22.089 2 27.2701 5.88726 29.1971 11.3155ZM8.09244 20.736V22H14.7484V20.464H10.3644L10.3164 20.352L11.0524 19.744L12.9244 17.632C14.1404 16.256 14.7164 15.488 14.7164 14.32C14.7164 12.512 13.3084 11.248 11.2444 11.248C9.59644 11.248 8.12444 12.272 7.72444 13.632L9.32444 14.192C9.51644 13.376 10.2524 12.752 11.2124 12.752C12.3004 12.752 13.0204 13.392 13.0204 14.352C13.0204 15.008 12.6524 15.52 11.7084 16.624L8.09244 20.736ZM21.7414 13.936L17.6294 22H19.4374L24.0774 12.752V11.504H16.5734V13.088H22.2054L22.2214 13.216L21.7414 13.936ZM29.7036 13.84C29.7036 14.512 29.2396 14.96 28.5996 14.96C27.9756 14.96 27.4956 14.512 27.4956 13.84C27.4956 13.184 27.9756 12.736 28.5996 12.736C29.2396 12.736 29.7036 13.184 29.7036 13.84Z"
                fill="#1C1E21"
            />
            <circle cx="25" cy="7" r="7" fill="white" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.9002 2.5H24.2002V5.69167L21.3 4.1001L20.5 5.4001L23.4156 7.0001L20.5 8.6001L21.3 9.9001L24.2002 8.30853V11.5H25.9002V8.36362L28.7 9.9001L29.5 8.6001L26.5844 7.0001L29.5 5.4001L28.7 4.1001L25.9002 5.63658V2.5Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcThermostatSetpointIconWithFrost;
