import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { prepareDevices } from 'containers/Ezlo/EzloDevices/utils';
import { getAddressableLedDevices } from 'features/EzloAddressableLed/utils';

const useAddressableLedDevices = () => {
    const ezloData = useSelector((state) => state?.ezlo?.data);

    const addressableLedDevices = useMemo(() => {
        const ezloDevices = prepareDevices(ezloData);

        return getAddressableLedDevices(ezloDevices);
    }, [ezloData]);

    return addressableLedDevices;
};

export default useAddressableLedDevices;
