import React from 'react';

const STRING_TEMPLATE_REGEXP = /\[\[(.+?)\]\]/g;

const newlineToJSX = (string) => {
    const wordsArray = string.split('<newline>').filter((word) => word !== '');

    if (!string) {
        return '';
    }

    return (
        <span>
            {wordsArray.map((word, index) => (
                <span key={index}>
                    {word}
                    <br />
                </span>
            ))}
        </span>
    );
};

export const tt = function (template, keepNewlines) {
    if (!template || typeof template !== 'string') {
        return template;
    }

    if (STRING_TEMPLATE_REGEXP.test(template)) {
        return template.replace(STRING_TEMPLATE_REGEXP, ($0, $1) => t($1, '', keepNewlines));
    } else {
        return t(template, '', keepNewlines);
    }
};

export const t = function (key, defaultValue = '', keepNewlines = true) {
    let found = defaultValue ? defaultValue : key;

    if (!window.translationData) {
        return found;
    }

    found = window.translationData[key];

    if (!found) {
        return defaultValue ? defaultValue : key;
    }

    if (!keepNewlines) {
        return found.replace(/<newline>/g, ' ');
    }

    if (/<newline>/.test(found)) {
        return newlineToJSX(found);
    }

    return found;
};

export const generateLabelAccordingToDataType = (type) => `(auto detected as ${type}):`;
