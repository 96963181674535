import { JAVASCRIPT_TYPES } from '../../constants/common/js_types';

/**
 * Returns options for EnvironmentsSelect component
 * @param {object} environments - original app config "environments" value
 * @returns {Array} list of available options
 * */
export const buildEnvironmentsSelectOptions = (environments) => {
    if (environments && typeof environments === JAVASCRIPT_TYPES.OBJECT) {
        return Object.entries(environments).map(([envKey, config]) => {
            return {
                value: envKey,
                label: config.name || envKey,
            };
        });
    }

    return [];
};
