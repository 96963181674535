import {
    EZLOGIC_TITLE_ALL_EVENTS,
    EZLOGIC_TITLE_DEVICE,
    EZLOGIC_TITLE_IMAGE,
    EZLOGIC_TITLE_TRIGGER,
    EZLOGIC_TITLE_VIDEO,
} from './language_tokens';

export const IMAGE = 'Image';
export const ALL_EVENTS_TYPE = 0;
export const VIDEO = 'Video';
export const TRIGGER = 'Trigger';
export const DEVICE = 'Device';
export const LOGIN = 'Login';
export const SIREN = 'siren';
export const SECURITY_SENSOR = 'security_sensor';
export const GENERIC_SENSOR = 'generic_sensor';
export const LEVEL_SENSOR = 'level_sensor';
export const LIGHT_SENSOR = 'light_sensor';
export const DIMMABLE_LIGHT = 'dimmable_light';
export const LED_STRIP = 'led_strip';
export const ADDRESSABLE_MAIN = 'addressable_main';
export const HVAC = 'hvac';
export const INTERFACE = 'interface';
export const SWITCH = 'switch';
export const TEMPERATURE = 'temperature';
export const DOOR_LOCK = 'door_lock';
export const WINDOW_COV = 'window_cov';
export const DOORBELL = 'doorbell';
export const FLOW_METER = 'flow_meter';
export const GARAGE_DOOR = 'garage_door';
export const POWER_METER = 'power_meter';
export const SCENE_CONTROLLER = 'scene_controller';
export const CAMERA_SUITE = 'camera_suite';
export const HUMIDITY = 'humidity';
export const VOLTAGE_SENSOR = 'voltage_sensor';
export const UV_SENSOR = 'uv_sensor';
export const STATE_SENSOR = 'state_sensor';
export const EVENT_TYPES = [
    { value: 0, label: EZLOGIC_TITLE_ALL_EVENTS },
    { value: 1, label: EZLOGIC_TITLE_IMAGE },
    { value: 2, label: EZLOGIC_TITLE_VIDEO },
    { value: 3, label: EZLOGIC_TITLE_TRIGGER },
    { value: 4, label: EZLOGIC_TITLE_DEVICE },
];
export const COUNT_NUMBER = '100';
export const START_NUMBER = '0';
export const COUNT = 'Count';
export const START = 'Start';
export const AFTER = 'After';
export const BEFORE = 'Before';
export const GATEWAY = 'Gateway';
export const YEAR_LABEL = 'year';
export const MONTH_LABEL = 'month';
export const DAY_LABEL = 'day';
