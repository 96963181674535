/**
 * Returns user auth data templates object, unified structure for all login methods.
 * @returns {Object} user auth info templates
 *
 * @example
 *
 * Output:
 * {
 *   "data": {
 *     "path": "",
 *     "Server_Account": null,
 *     "Server_Account_Alt": null,
 *     "Identity": null,
 *     "IdentitySignature": null
 *   }
 * }
 * */
export const getUserAuthInfoTemplate = () => {
    return {
        data: {
            path: '',
            Server_Account: null,
            Server_Account_Alt: null,
            Identity: null,
            IdentitySignature: null,
            token: null,
        },
    };
};
