export const isJsonString = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }

    return true;
};

export const buildAccountsInfoData = (date) => {
    const accountsInfo = {};

    date.forEach((item) => {
        if (item[3] && isJsonString(item[3])) {
            accountsInfo[item[0]] = JSON.parse(item[3]);
        }
    });

    return accountsInfo;
};
