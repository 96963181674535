import React from 'react';

export const IcDeviceSensorPowerMeter = (props) => (
    <svg {...props} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M25 14H18V7L7 18H14V25L25 14Z" />
        <path d="M32 14.5H28.9C28.2 8 22.7 3 16 3C9.3 3 3.8 8 3.1 14.5H0V17.5H3.1C3.8 24 9.3 29 16 29C22.7 29 28.2 24 28.9 17.5H32V14.5ZM16 26C10.5 26 6 21.5 6 16C6 10.5 10.5 6 16 6C21.5 6 26 10.5 26 16C26 21.5 21.5 26 16 26Z" />
    </svg>
);

export default IcDeviceSensorPowerMeter;
