import { apiGetApplicationConfig } from '../api/oemManagerApi';
import { JAVASCRIPT_TYPES } from '../../../../../constants/common/js_types';

const validateConfig = (config) => {
    if (config && typeof config === JAVASCRIPT_TYPES.OBJECT) {
        return true;
    }

    throw new Error('Not valid config.json file!');
};

/**
 * Read and returns config file content as an JS Object.
 * @returns {Promise<Object>} Config content
 * */
export const readApplicationConfig = async () => {
    const response = await apiGetApplicationConfig();
    const config = response.data;
    validateConfig(config);

    return config;
};
