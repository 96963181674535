import { createSlice } from '@reduxjs/toolkit';
import { LOGOUT } from '../constants/ActionTypes';
import { isObject } from 'lodash';
import { removeSceneFromState, updateSceneStatusInState } from '../components/MeshbotRun/utils';

const initialState = {
    local: {},
    cloud: {},
};

const meshBotStatuses = createSlice({
    name: 'trackRunScenes',
    initialState,
    reducers: {
        setRunningLocalMeshBotState: (state, action) => {
            updateSceneStatusInState(state.local, action.payload);
        },
        removeExecutedLocalMeshBotState: (state, action) => {
            removeSceneFromState(state.local, action.payload);
        },
        setActiveCloudRunScenes: (state, action) => {
            const { cloud } = action.payload;

            const updatedCloud = { ...state.cloud };

            Object.entries(cloud).forEach(([key, value]) => {
                if (updatedCloud[key] !== value) {
                    updatedCloud[key] = value;
                }
            });

            Object.keys(updatedCloud).forEach((key) => {
                if (!(key in cloud)) {
                    delete updatedCloud[key];
                }
            });

            state.cloud = updatedCloud;
        },
        setIsSceneStoppingInitiated: (state, action) => {
            const { hubSerial, sceneId, value } = action.payload;
            if (!isObject(state?.local?.[hubSerial]?.[sceneId])) {
                return state;
            }

            state.local[hubSerial][sceneId].isSceneStoppingInitiated = value;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(LOGOUT, () => initialState);
    },
});
export const {
    setIsSceneStoppingInitiated,
    setActiveCloudRunScenes,
    setRunningLocalMeshBotState,
    removeExecutedLocalMeshBotState,
} = meshBotStatuses.actions;
export default meshBotStatuses;
