import React from 'react';

const IcSecurityAlarmPanel = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
            <circle cx="12" cy="9" r="2" fill="#1C1E21" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23 3H9C7.3 3 6 4.3 6 6V26C6 27.7 7.3 29 9 29H23C24.7 29 26 27.7 26 26V6C26 4.3 24.7 3 23 3ZM15 9C15 10.6569 13.6569 12 12 12C10.3431 12 9 10.6569 9 9C9 7.34315 10.3431 6 12 6C13.6569 6 15 7.34315 15 9ZM12 20C13.6569 20 15 18.6569 15 17C15 15.3431 13.6569 14 12 14C10.3431 14 9 15.3431 9 17C9 18.6569 10.3431 20 12 20ZM23 9C23 10.6569 21.6569 12 20 12C18.3431 12 17 10.6569 17 9C17 7.34315 18.3431 6 20 6C21.6569 6 23 7.34315 23 9ZM20 20C21.6569 20 23 18.6569 23 17C23 15.3431 21.6569 14 20 14C18.3431 14 17 15.3431 17 17C17 18.6569 18.3431 20 20 20Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcSecurityAlarmPanel;
