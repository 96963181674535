//TODO: check that file

const VERA_PK_OEM = 1;
const SECURITY_PK_OEM = 501;
const PROPERTY_PK_OEM = 502;
const HORUS_PK_OEM = 37;

export const VERA_OEM_ID = VERA_PK_OEM;
export const SECURITY_OEM_ID = SECURITY_PK_OEM;
export const PROPERTY_OEM_ID = PROPERTY_PK_OEM;
export const HORUS_OEM_ID = HORUS_PK_OEM;
export const CONSTELLATION_OEM_ID = 'constellation';

export const OEM_IDS = {
    VERA_OEM_ID,
    SECURITY_OEM_ID,
    PROPERTY_OEM_ID,
    HORUS_OEM_ID,
    CONSTELLATION_OEM_ID,
};
