import { BLOCK_WITH_FIELDS_ARRAY, COMPARISON_DATA } from '../../../../../constants/MeshbotConstant';
import { listCapabilitiesForController, rangeCapabilitiesForController } from '../../../../../constants/rulesInSelect';
import { MESHBOT_TYPES } from 'containers/Ezlo/EzloMeshbots/constants';

export const getControllerNodeValueForTrigger = (currentLocalVariableBlock, name) => {
    return currentLocalVariableBlock?.blocks[BLOCK_WITH_FIELDS_ARRAY]?.fields?.find((field) => field.name === name)
        ?.value;
};

export const getControllerNodeValueObjectFromNotBlock = (block) => {
    return block?.fields?.find((field) => field?.value)?.value || {};
};

export const getControllerNodeValueFromNotBlockByName = (block, name) => {
    return block?.fields?.find((field) => field?.name === name)?.value || '';
};

/**
 * This function filters the capabilities by hardware support of the controller and by meshbot type
 * @param {boolean} isControllerWithBattery - determines if the controller has a battery
 * @param {string} meshbotType - the meshbot type
 * @return {array} - the filtered capabilities
 */
export const filterCapabilitiesByHardwareSupportOfController = (isControllerWithBattery, meshbotType) => {
    if (isControllerWithBattery === undefined || meshbotType === undefined) {
        return [];
    }

    if (isControllerWithBattery && meshbotType !== MESHBOT_TYPES.EZLOPI) {
        return listCapabilitiesForController;
    }

    if (isControllerWithBattery === false || meshbotType === MESHBOT_TYPES.EZLOPI) {
        return listCapabilitiesForController.filter(
            (capability) => capability.value === COMPARISON_DATA.METHOD.CLOUD_CONNECTION,
        );
    }

    return [];
};
/**
 * Function that provides controller capabilities for trigger of meshbot
 * @param {boolean} isControllerWithBattery - determines if the controller has a battery
 * @param {boolean} [isGlobalRestriction] - determines if it's GlobalRestriction section
 * @param {string} meshbotType - the meshbot type
 * @return {array} - the capabilities
 */
export const getHubCapabilitiesForMeshBotTrigger = (isControllerWithBattery, isGlobalRestriction, meshbotType) => {
    if (isGlobalRestriction) {
        return rangeCapabilitiesForController;
    }

    return filterCapabilitiesByHardwareSupportOfController(isControllerWithBattery, meshbotType);
};
