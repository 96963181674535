import { JAVASCRIPT_TYPES } from '../../../constants/common/js_types';
import { NOT_VALID_HEADER } from './errors';
import { HTTP_HEADERS } from '../constants';

/**
 * Basic validation for header object.
 * @param {Object} header - key-value header
 * @returns {boolean} validation result
 * @throws {Error}
 *
 * @example
 * Valid:
 * Input: {'Content-Type': 'text/html;charset=utf-8'}
 * Output: true
 *
 * Invalid:
 * Input: 'Content-Type:text/html;charset=utf-8'
 * Output: Error
 *
 * */
export const validateHeader = (header) => {
    if (header && typeof header === JAVASCRIPT_TYPES.OBJECT && Object.keys(header).length === 1) {
        return true;
    }

    throw new Error(NOT_VALID_HEADER);
};

/**
 * Join header values by ';' symbol
 * @param {Array} headerValuesArr - array of header values
 * @return joined string
 *
 * @example
 * Input: ["application/json", "charset=UTF-8"]
 * Output: "application/json;charset=UTF-8"
 * */
export const joinHeaderValues = (headerValuesArr) => {
    return headerValuesArr.join(';');
};

/**
 * Returns the key-value object, that describes Content-Type header for JSON payload type
 * @return {Object} key-value header
 * @example
 * Output: { "Content-Type": "application/json;charset=UTF-8" }
 * */
export const getContentTypeJsonHeader = () => {
    const { key } = HTTP_HEADERS.CONTENT_TYPE;
    const value = joinHeaderValues([HTTP_HEADERS.CONTENT_TYPE.values.json, HTTP_HEADERS.CONTENT_TYPE.values.charset]);

    return {
        [key]: value,
    };
};
