import React from 'react';

const IcAssistanceRequestCancelled = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1 29L3 31L7.86184 26.1382C10.0895 27.9286 12.9196 29 16 29C23.1797 29 29 23.1797 29 16C29 12.9196 27.9286 10.0895 26.1382 7.86184L31 3L29 1L24.1382 5.86184C21.9105 4.07138 19.0804 3 16 3C8.8203 3 3 8.8203 3 16C3 19.0804 4.07138 21.9105 5.86184 24.1382L1 29ZM9.99958 24.0004C11.671 25.256 13.7486 26 16 26C21.5228 26 26 21.5228 26 16C26 13.7486 25.256 11.671 24.0004 9.99958L9.99958 24.0004ZM22.0004 7.99958L7.99958 22.0004C6.74401 20.329 6 18.2514 6 16C6 10.4772 10.4772 6 16 6C18.2514 6 20.329 6.74401 22.0004 7.99958Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcAssistanceRequestCancelled;
