import produce from 'immer';
import mainTypes from '../constants/ActionTypes/Main';

const INITIAL_STATE = {
    isLoading: true,
    isInitialized: false,
};

export default produce((draft, action) => {
    switch (action.type) {
        case mainTypes.OEM_IS_LOADING:
            draft.isLoading = true;
            break;
        case mainTypes.OEM_IS_INITIALIZED:
            draft.isInitialized = true;
            draft.isLoading = false;
            break;
        default:
            break;
    }
}, INITIAL_STATE);
