// TODO: let's add LOCALSTORAGE prefix to all LocalStorage keys and move all LocalStorage interaction to the separate Service.
// TODO: StorageService - will keep different storages: localStorage, sessionStorage etc. (only UI)
export const NAME = 'name';
export const HASH = 'hash';
export const HASH_CS = 'hashCS';
export const PK_User = 'PK_User';

export const IDENTITY = 'identity';
export const IDENTITY_SIGNATURE = 'identitySignature';
export const SERVER_ACCOUNT = 'Server_Account';
export const SERVER_ACCOUNT_ALT = 'Server_Account_Alt';

export const CLOUD_TOKEN = 'cloudToken';
export const DASHBOARD_SSO_KEY = 'dashboard_sso_key';
export const PAGE_VALUE = 'page';

export const lAST_ACTIVE_CONTROLLERS_GROUP_UUID = 'last_active_controllers_group_uuid';
export const CLOUD_ENVIRONMENT = 'cloud_environment';
export const DOMAIN_PARTNER_UUID = 'partner-uuid';
export const USER_TYPE = 'user-type';
export const LOCALSTORAGE_TOP_LEVEL_DOMAIN = 'top-level-domain';

export const LOCALSTORAGE_SSO_TYPE = 'sso_type';
export const IS_FIRST_LOAD_INTEGRATIONS = 'isFirstLoadIntegrations';
export const IS_FIRST_LOAD_NEW_INTEGRATIONS = 'isFirstLoadNewIntegrations';

/** Used to keep auth info for "firstlogin" page. */
export const LOCALSTORAGE_FIRSTLOGIN_PAGE = 'firstlogin';
