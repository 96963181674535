import React from 'react';

export const ArrowDownIcon = (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 32">
        <title>ezlo</title>
        <path d="M19.864 27.908c-1.088-0-2.074-0.442-2.786-1.155l-15.967-15.963c-0.684-0.708-1.107-1.674-1.107-2.738 0-2.177 1.765-3.941 3.941-3.941 1.064 0 2.030 0.422 2.739 1.107l13.179 13.175 13.18-13.18c0.708-0.684 1.674-1.107 2.738-1.107 2.177 0 3.941 1.765 3.941 3.941 0 1.064-0.422 2.030-1.107 2.739l-15.966 15.966c-0.713 0.713-1.698 1.154-2.786 1.155z"></path>
    </svg>
);

export default ArrowDownIcon;
