import { createSlice } from '@reduxjs/toolkit';
import { TOKENS_FLOWS, TOKENS_FLOWS_PROPERTIES } from '../constants';
import { LOGOUT } from '../../../constants/ActionTypes';
import { clearTokensFlowTimeout, dismissTokensFlowToast } from '../utils';

const { TOAST_ID, IS_TOKENS_OBSOLETE, TIMEOUT_ID } = TOKENS_FLOWS_PROPERTIES;

/**
 * Represents the structure of properties associated with token flows.
 *
 * @typedef {Object} TokensFlowsState
 * @property {string | null} TOAST_ID - The identifier for the toast.
 * @property {boolean} IS_TOKENS_OBSOLETE - A flag indicating whether tokens are obsolete.
 * @property {number | null} TIMEOUT_ID - The identifier for the timeout.
 */

/**
 * Initial state for the tokensFlows slice.
 *
 * @type {TokensFlowsState}
 */
export const initialState = {
    [TOAST_ID]: null,
    [IS_TOKENS_OBSOLETE]: false,
    [TIMEOUT_ID]: null,
};
/**
 * Redux slice for managing token flows.
 *
 * @type {import('@reduxjs/toolkit').Slice}
 */
export const tokensFlows = createSlice({
    name: TOKENS_FLOWS,
    initialState,
    reducers: {
        /**
         * Sets the toast identifier in the state.
         *
         * @param {TokensFlowsState} state - The current state.
         * @param {import('@reduxjs/toolkit').PayloadAction<string | null>} action - The action with the payload.
         */
        setToastId: (state, action) => {
            state[TOAST_ID] = action.payload;
        },
        /**
         * Sets the "isTokensObsolete" flag in the state.
         *
         * @param {TokensFlowsState} state - The current state.
         * @param {import('@reduxjs/toolkit').PayloadAction<boolean>} action - The action with the payload.
         */
        setIsTokensObsolete: (state, action) => {
            state[IS_TOKENS_OBSOLETE] = action.payload;
        },
        /**
         * Sets the timeout identifier in the state.
         *
         * @param {TokensFlowsState} state - The current state.
         * @param {import('@reduxjs/toolkit').PayloadAction<number | null>} action - The action with the payload.
         */
        setTimeoutId: (state, action) => {
            state[TIMEOUT_ID] = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(LOGOUT, (state) => {
            clearTokensFlowTimeout(state[TIMEOUT_ID]);
            dismissTokensFlowToast(state[TOAST_ID]);

            return initialState;
        });
    },
});
export const { setToastId, setIsTokensObsolete, setTimeoutId } = tokensFlows.actions;
export default tokensFlows.reducer;
