/**
 * // TODO: consider ready-made module for Object shape validation, like "jsonschema" module
 * Simplified Itarian SSO keys container.
 * */
export const ITARIAN_SSO_DATA_KEYS = {
    TOKEN: 'token',
    EXPRIRES: 'expires',
    LEGACY_TOKEN: 'legacy_token',
};

/**
 * Itarian SSO Type identifier
 * */
export const ITARIAN_SSO_TYPE = 'itarian';
