import wsm from '../helpers/wsm';
import * as types from '../constants/ActionTypes/Backups';
import { fetchBackupsStorageList } from '../api/apiBackups';
import { bugsnagNotify, bugsnagNotifyWrapper } from '../containers/ErrorBoundary/utils';
import {
    isHubBackupSupported,
    getBackupsFiltersForRequest,
    getInitialDateFields,
    getNewPage,
    getHubFeaturesList,
} from '../containers/Ezlo/EzloHubBackups/utils';
import { FIRST_BACKUP_INDEX, INITIAL_PAGE_NUMBER } from '../containers/Ezlo/EzloHubBackups/constants';
import { isArray, isEmpty } from 'lodash';

const actions = {
    /**
     * Fetch backups for a hub based on the specified filters.
     *
     * This asynchronous function fetches backups for a hub based on the provided filters. It retrieves the necessary data
     * from the Redux store's state, such as the selected controller's serial, and then fetches backups from two different
     * sources: the default bucket and the controller backups bucket using asynchronous API calls. The fetched backups are
     * combined, and a sorted list of backups is returned.
     *
     * @param {Object?} filters - The filters to apply when fetching backups.
     * @returns {Function} Function that return a promise that resolves to an array of sorted backups.
     */
    fetchHubBackups: (filters) => async (dispatch, getState) => {
        const { ezlos, ezlo } = getState();
        const selectController = ezlo.serial;
        const hubInfo = ezlos.items.find((item) => item.serial === selectController);
        const controller_uuid = hubInfo?.controller_uuid;
        const backups = await fetchBackupsStorageList(controller_uuid, filters);

        return isArray(backups) ? backups : [];
    },
    /**
     * Set selected hub backups and related information.
     *
     * This asynchronous function sets selected hub backups and related information in the Redux store. It retrieves
     * information from the Redux state, checks if the selected controller supports backups, and then fetches and sets
     * the initial backups filters. If backups are not supported, an empty array of backups is stored. Finally, it fetches
     * all backups, sets the first backup as selected, and updates the store with the fetched backups or any errors.
     *
     * @param {Function} dispatch - The Redux dispatch function to update the store.
     * @param {Function} getState - The Redux getState function to access the store's state.
     * @returns {Promise<void>} A promise that resolves after setting the selected hub backups.
     */
    setSelectedHubBackups: async (dispatch, getState) => {
        try {
            const { ezlo } = getState();
            const selectedController = ezlo.serial;
            const hubFeatureList = getHubFeaturesList(selectedController, ezlo);
            const isBackupSupported = isHubBackupSupported(ezlo?.data[selectedController], hubFeatureList);
            dispatch(actions.setBackupSupported(isBackupSupported));
            dispatch({ type: types.BACKUPS_FETCH_DEVICE_BACKUPS });
            dispatch(actions.setInitialBackupsFilters);

            if (!isBackupSupported) {
                dispatch({ type: types.BACKUPS_FETCH_DEVICE_BACKUPS_ON_SUCCESS, backups: [] });

                return;
            }

            const allBackups = await dispatch(actions.fetchHubBackups());
            dispatch(actions.setFirstBackupSelected(allBackups));

            dispatch({ type: types.BACKUPS_FETCH_DEVICE_BACKUPS_ON_SUCCESS, backups: allBackups });
        } catch (err) {
            bugsnagNotifyWrapper(err, { type: types.BACKUPS_FETCH_DEVICE_BACKUPS_ON_ERROR });
            dispatch({ type: types.BACKUPS_FETCH_DEVICE_BACKUPS_ON_ERROR, error: err });
        }
    },
    /**
     * Set backups filters and dispatch the action.
     *
     * This function creates an action to set backups filters and dispatches it to the Redux store. It is typically used to
     * update the filters for fetching backups based on specific criteria.
     *
     * @param {Object} filters - The filters to be set for fetching backups.
     * @returns {Object} An action object containing the type and filters to be dispatched.
     *
     * @example
     * const filters = { timestamp_after: 16253450000, timestamp_before: 123658968600 };
     * const action = setBackupsFilters(filters);
     * // Result: { type: 'SET_BACKUPS_FILTERS', filters: { timestamp_after: 16253450000, timestamp_before: 123658968600 } }
     */
    setBackupsFilters: (filters) => ({ type: types.SET_BACKUPS_FILTERS, filters }),
    /**
     * This object defines an action to set the initial backups filters in the Redux store.
     */
    setInitialBackupsFilters: (dispatch, getState) => {
        const ezloState = getState().ezlo;
        const timeZone = ezloState.data[ezloState.serial]?.location?.timezone;
        const fields = getInitialDateFields(timeZone);
        dispatch({ type: types.SET_INITIAL_BACKUPS_FILTERS, fields });
    },
    /**
     * Set the current page in backups filters and fetch backups based on the button type.
     *
     * This function is an asynchronous action creator that sets the current page in backups filters and then triggers the
     * fetching of backups based on the specified button type. It calculates the new page number based on the current page
     * and the button type, updates the filters, and dispatches actions to perform the search for backups.
     *
     * @param {string} buttonType - The button type that determines whether to move to the previous or next page.
     * @returns {Function}
     */
    setPageAndFetchBackupsByPage: (buttonType) => (dispatch, getState) => {
        const currentPage = getState().backups.filters.page;
        dispatch(actions.setBackupsFilters({ page: getNewPage(currentPage, buttonType) }));
        dispatch(actions.searchSelectedHubBackups);
    },
    /**
     * Search for backups with filtered criteria and reset the page number.
     *
     * This function is an action creator that sets the page number to the initial value and triggers the search for backups
     * with the current filtered criteria. It dispatches actions to update the filters and initiate the search operation.
     *
     * @param {Function} dispatch - The Redux dispatch function to update the store.
     * @returns {void}
     *
     * @example
     * searchFilteredBackups(dispatch);
     * // The page number is reset to the initial value, and a search for backups is initiated.
     */
    searchFilteredBackups: (dispatch) => {
        dispatch(actions.setBackupsFilters({ page: INITIAL_PAGE_NUMBER }));
        dispatch(actions.searchSelectedHubBackups);
    },
    /**
     * Search for backups of the selected hub based on the current filters and update the store.
     *
     * This asynchronous function searches for backups of the selected hub based on the current filters in the Redux store.
     * It retrieves the necessary filter criteria from the state, dispatches actions to initiate the fetch operation,
     * fetches the backups using the provided filters, sets the first backup as selected, and updates the store with the
     * fetched backups or any errors that may occur during the process.
     *
     * @param {Function} dispatch - The Redux dispatch function to update the store.
     * @param {Function} getState - The Redux getState function to access the store's state.
     * @returns {Promise<void>} A promise that resolves after searching for and updating backups.
     */
    searchSelectedHubBackups: async (dispatch, getState) => {
        try {
            const { backups, ezlo } = getState();
            dispatch({ type: types.BACKUPS_FETCH_DEVICE_BACKUPS });
            const timeZone = ezlo.data[ezlo.serial].location?.timezone;

            const filters = getBackupsFiltersForRequest(backups.filters, timeZone);
            const allBackups = await dispatch(actions.fetchHubBackups(filters));
            dispatch(actions.setFirstBackupSelected(allBackups));

            dispatch({ type: types.BACKUPS_FETCH_DEVICE_BACKUPS_ON_SUCCESS, backups: allBackups });
        } catch (err) {
            bugsnagNotifyWrapper(err, { type: types.BACKUPS_FETCH_DEVICE_BACKUPS_ON_ERROR });
            dispatch({ type: types.BACKUPS_FETCH_DEVICE_BACKUPS_ON_ERROR, error: err });
        }
    },
    /**
     * Set the first backup from the provided array as selected and update the store.
     *
     * This action creator sets the first backup from the provided array as the selected backup in the Redux store and
     * dispatches an action to update the store with the selected backup.
     *
     * @param {Array} allBackups - An array of backups from which the first backup will be selected.
     * @returns {void}
     *
     * @example
     * const allBackups = [backup1, backup2, backup3];
     * setFirstBackupSelected(allBackups)(dispatch);
     * // The first backup (backup1) is selected and updated in the Redux store.
     */
    setFirstBackupSelected: (allBackups) => (dispatch) => {
        const selectedBackup = !isEmpty(allBackups) ? allBackups[FIRST_BACKUP_INDEX] : null;
        dispatch({ type: types.BACKUPS_SELECT_BACKUP, selectedBackup });
    },

    createBackup:
        (serial, params = {}) =>
        async (dispatch) => {
            dispatch(
                actions.subscribeBackupProgress(serial, dispatch(actions.onBackupCreateProgressBroadcast(serial))),
            );
            await wsm.send(
                serial,
                'hub.backup.create',
                params,
                () => {
                    dispatch({ type: types.BACKUPS_CREATE_BACKUP }); // backup started!
                },
                (error) => {
                    bugsnagNotify(error, { type: types.BACKUPS_CREATE_BACKUP_ON_ERROR, serial, params });
                    dispatch({ type: types.BACKUPS_CREATE_BACKUP_ON_ERROR }); // backup failed!
                },
            );
        },

    selectBackup: (uuid) => (dispatch, getState) => {
        const state = getState();
        const { backups } = state.backups;
        const selectedBackup = backups.find((backup) => backup.uuid === uuid);
        dispatch({ type: types.BACKUPS_SELECT_BACKUP, selectedBackup });
    },

    clearSelectedBackup: () => (dispatch) => {
        dispatch({ type: types.BACKUPS_CLEAR_SELECTED_BACKUP });
    },

    selectDefaultBackup: (uuid) => (dispatch, getState) => {
        const state = getState();
        const { backups } = state.backups;
        const selectedBackup = backups.find((backup) => backup.uuid === uuid);
        dispatch({ type: types.BACKUPS_SELECT_DEFAULT_BACKUP, selectedBackup });
    },

    restoreBackup: () => (dispatch) => {
        new Promise(() => {
            dispatch({ type: types.BACKUPS_RESTORE_BACKUP });
        });
    },

    confirmRestoreBackup: (serial) => async (dispatch, getState) => {
        dispatch(actions.subscribeRestoreProgress(serial, dispatch(actions.onBackupRestoreProgressBroadcast(serial))));
        const backup = getState().backups.selectedBackup;

        await wsm.send(
            serial,
            'hub.backup.restore',
            {
                key: backup.key,
                uuid: backup.uuid,
            },
            () => {
                dispatch({ type: types.BACKUPS_RESTORE_BACKUP_CONFIRM });
            },
            (error) => {
                bugsnagNotify(error, { type: types.BACKUPS_RESTORE_BACKUP_ON_ERROR, serial });
                dispatch({ type: types.BACKUPS_RESTORE_BACKUP_ON_ERROR });
            },
        );
    },

    setBackupSupported: (isBackupSupported) => (dispatch) => {
        dispatch({ type: types.BACKUPS_IS_BACKUP_SUPPORTED, isBackupSupported });
    },
    onFinish: { type: types.BACKUPS_ON_FINISH },
    onBackupCreateProgressBroadcast: (serial) => (dispatch) => {
        return ({ result }) => {
            if (result.status === 'started' || result.status === 'process') {
                dispatch({ type: types.BACKUPS_CREATE_BACKUP_IN_PROGRESS, result });
            } else if (result.status === 'finished') {
                dispatch({ type: types.BACKUPS_CREATE_BACKUP_ON_SUCCESS, result });
                dispatch(actions.unsubscribeBackupProgress(serial));
            } else if (result.status === 'aborted') {
                dispatch({ type: types.BACKUPS_CREATE_BACKUP_ON_ERROR, result });
                dispatch(actions.unsubscribeBackupProgress(serial));
            }
        };
    },

    onBackupRestoreProgressBroadcast: (serial) => (dispatch) => {
        return ({ result }) => {
            if (result.status === 'started' || result.status === 'process') {
                dispatch({ type: types.BACKUPS_RESTORE_BACKUP_IN_PROGRESS, result });
            } else if (result.status === 'finished') {
                dispatch({ type: types.BACKUPS_RESTORE_BACKUP_ON_SUCCESS, result });
                dispatch(actions.unsubscribeRestoreProgress(serial));
            } else if (result.status === 'aborted') {
                dispatch({ type: types.BACKUPS_RESTORE_BACKUP_ON_ERROR, result });
                dispatch(actions.unsubscribeRestoreProgress(serial));
            }
        };
    },

    subscribeBackupProgress: (serial, cb) => () => {
        return wsm.subscribe(serial, 'hub.backup.create.progress', cb);
    },

    unsubscribeBackupProgress: (serial) => () => {
        return wsm.unsubscribe(serial, 'hub.backup.create.progress');
    },

    subscribeRestoreProgress: (serial, cb) => () => {
        return wsm.subscribe(serial, 'hub.backup.restore.progress', cb);
    },

    unsubscribeRestoreProgress: (serial) => () => {
        return wsm.unsubscribe(serial, 'hub.backup.restore.progress');
    },
};

export default actions;
