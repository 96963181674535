import React from 'react';
import hash from '../../../constants/uniqueHash';
import { IconButton } from 'lib/@mui';
import { AddCircleOutline, CloudDownloadOutlined, Delete, Edit, FileCopy, InfoOutlined, Public } from 'lib/@mui/Icons';
import {
    EDGE,
    ENUM,
    MARKETPLACE_GENERATOR,
    OLD_IP_DEVICE_GENERATOR_VERSION,
    PRIVATE_GENERATOR,
    PUBLISH_GENERATOR,
    TEMPLATE_INFO,
    UPGRADE,
    VALVE,
    WIFI,
    WIFI_DEVICE_GENERATOR,
    PATTERN,
    DOWNLOAD,
    PASSWORD,
    USER_NAME,
} from '../../../constants/Plugins';
import _, { isObject } from 'lodash';
import { CHECKBOX_BLOCK, CONTENT_TYPE, DATA, KEY, METHOD, OBJECT, URI } from '../../../constants/DevicePluginGenerator';
import {
    INDEX_OF_ZERO,
    SET,
    TYPE,
    TYPE_ERROR,
    GET,
    HEADERS,
    MINUS_ONE_INT,
    INDEX_OF_TWO,
} from '../../../constants/MeshbotConstant';
import {
    AUTHORIZATION,
    AUTO,
    BLOB,
    BASIC,
    BIGGER,
    COOL,
    EQUAL,
    ELEMENT_A,
    HEAT,
    HTTP,
    ID,
    LOWER,
    JSON_FORMAT,
    MAIN_REQUEST_BLOCK_FIELDS,
    MAX,
    MIN,
    MQTT,
    MQTT_TOPIC,
    NONE,
    OFF_STATE,
    ON_STATE,
    PROTOCOL,
    RAW_VALUE,
    SET_LEVEL,
    APPLICATION_JSON,
} from '../../../constants/IpTemplates';
import { formatNameWithUnderscores } from '../../../helpers/helpersMeshBot';
import { JAVASCRIPT_TYPES } from '../../../constants/common/js_types';
import { EZLO_PLUGINS_BUILD_PLUGIN_DOWNLOAD_URL_INVALID_PARAMS } from './constants/errors';
import { API_CLOUD_EZLO_DOWNLOAD_PLUGIN_URL } from '../../../constants/URLs';
import { DASHBOARD_FILE_KEY_PROPERTY_NAME } from './constants/constants';
import PluginVersionCell from './PluginEdge/PluginsPrivate/PluginVersionCell/PluginVersionCell';
import { checkVersionSupport, transformHeadersToString } from '../EzloMeshbot/utils';
import CountInstalledPlugin from './components/CountInstalledPlugin';
import CountInstalledDevices from './components/CountInstalledDevices';
import CountInstalledIPDevices from './components/CountInstalledIPDevices';
import {
    EZLO_PLUGIN_EDGE_HEADER_DEFAULT_LIST_DISABLED_BTN_TITLE,
    EZLO_PLUGIN_EDGE_HEADER_LIST_AND_WIFIGENERATOR_MARKET_BTN_TITLE,
    EZLO_PLUGIN_EDGE_HEADER_LIST_PRIVATE_BTN_TITLE,
    EZLO_PLUGIN_EDGE_HEADER_LIST_PUBLISH_BTN_TITLE,
    EZLO_PLUGIN_EDGE_HEADER_WIFIGENERATOR_PRIVATE_BTN_TITLE,
    EZLO_PLUGIN_EDGE_HEADER_WIFIGENERATOR_PUBLISH_BTN_TITLE,
    EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_CERTIFIED_TITLE,
    EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_COPY_OF_TITLE,
    EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_DELETE_TITLE,
    EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_DOWNLOAD_TITLE,
    EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_ID_TITLE,
    EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_INFO_TITLE,
    EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_IP_DEVICE_TITLE,
    EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_MANAGE_INSTALLATION_TITLE,
    EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_PUBLISH_TITLE,
    EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_STATUS_TITLE,
    EZLOGIC_BUTTON_CREATE_DEVICE,
    EZLOGIC_BUTTON_EDIT,
    EZLOGIC_HEADING_DEVICES,
    EZLOGIC_TITLE_CONTROLLER_SUCCESSFULLY_UPDATED,
    EZLOGIC_TITLE_INFORMATION,
    EZLOGIC_TITLE_MAX_VALUE_MUST_BE_MORE_THEN_MAX,
    EZLOGIC_TITLE_MIN_VALUE_MUST_BE_LESS_THEN_MAX,
    EZLOGIC_TITLE_PLEASE_ENSURE_THAT_MIN_ANS_MAX_VALUES_NOT_EQUAL,
    EZLOGIC_TITLE_PLUGIN_NAME,
    EZLOGIC_TITLE_PLUGIN_SUCCESSFULLY_UPDATED,
    EZLOGIC_TITLE_VALUE_IS_REQUIRED,
    EZLOGIC_TITLE_VERSION,
} from '../../../constants/language_tokens';
import { PluginActions } from '../../../actions';
import { PLUGIN, TEMPLATE } from '../../../constants/Integrations';
import { isFileNameValid } from 'services/utilityService';
import { replaceUrlPrefix } from '../../../services/oem';
import { STRING } from '../EzloMeshbot/constants';
import { OFF_LOVER_CASE, ON_LOVER_CASE } from '../../../constants/Devices';

import { FIELD_TYPES } from '../../../constants/Users';
import { decodeData, encodeData } from '../../../services/kvs/src/utils/utils';
import { ONE_INT, ZERO_INT } from '../../../constants/Expressions';
import style from './components/TemplateCapability/templateCapability.module.scss';

/**
 * Remove empty fields in object
 * @param {object} data - object fields for new device
 * @returns {object} returned object with full fields
 * @example
 * removeEmptyFields(data)
 * */
export const removeEmptyFields = (data) => {
    if (!data || typeof data !== 'object' || Array.isArray(data)) {
        return {};
    }

    return Object.entries(data).reduce((acc, [field, value]) => {
        // Based on legacy logic, but with proper numbers handling. Must be tested by QA
        if (value === null || value === undefined || value === '') {
            return acc;
        }

        return { ...acc, [field]: value };
    }, {});
};

/**
 * Remove empty fields in object
 * @param {array} configureInputs - array inputs
 * @param {object} inputs - object fields for new device
 * @returns {object} returned object fields with errors
 * @example
 * validateRequiredFields(data)
 * */
export const validateRequiredFields = (configureInputs, inputs) => {
    if (!configureInputs || !inputs || !Array.isArray(configureInputs) || Array.isArray(inputs)) {
        return {};
    }

    return configureInputs.reduce((acc, input) => {
        if (!inputs[input.name] && input.required) {
            return { ...acc, [input.name]: true };
        }

        return acc;
    }, {});
};

/**
 * Get list data for buttons tabs
 * @param {string} page - current page
 * @returns {array} returned array objects for  buttons tabs
 * @example
 * getListTabs('wifi')
 * */
export const getListTabs = (page) => {
    switch (page) {
        case WIFI:
            return listTabsWifiGenerator;
        case EDGE:
            return listButtons;
        default:
            return defaultListButtons;
    }
};

/**
 * Default list buttons for plugin
 * @returns {array} array of rows
 * */
export const defaultListButtons = [
    { id: hash(), name: EZLO_PLUGIN_EDGE_HEADER_DEFAULT_LIST_DISABLED_BTN_TITLE, type: 'disabled' },
    { id: hash(), name: EZLO_PLUGIN_EDGE_HEADER_DEFAULT_LIST_DISABLED_BTN_TITLE, type: 'disabled' },
    { id: hash(), name: EZLO_PLUGIN_EDGE_HEADER_DEFAULT_LIST_DISABLED_BTN_TITLE, type: 'disabled' },
];

/**
 * List buttons for plugin edge
 * @returns {array} array of rows
 * */
export const listButtons = [
    { id: hash(), name: EZLO_PLUGIN_EDGE_HEADER_LIST_PRIVATE_BTN_TITLE, type: 'private' },
    { id: hash(), name: EZLO_PLUGIN_EDGE_HEADER_LIST_PUBLISH_BTN_TITLE, type: 'publish' },
    { id: hash(), name: EZLO_PLUGIN_EDGE_HEADER_LIST_AND_WIFIGENERATOR_MARKET_BTN_TITLE, type: 'market' },
];

/**
 * List buttons for plugin wifi generator
 * @returns {array} array of rows
 * */
export const listTabsWifiGenerator = [
    { id: hash(), name: EZLO_PLUGIN_EDGE_HEADER_WIFIGENERATOR_PRIVATE_BTN_TITLE, type: 'private' },
    { id: hash(), name: EZLO_PLUGIN_EDGE_HEADER_WIFIGENERATOR_PUBLISH_BTN_TITLE, type: 'publish' },
    { id: hash(), name: EZLO_PLUGIN_EDGE_HEADER_LIST_AND_WIFIGENERATOR_MARKET_BTN_TITLE, type: 'market' },
];

const isBuildPluginDownloadUrlParamsValid = (file) => {
    return file && typeof file === JAVASCRIPT_TYPES.STRING;
};

const validateBuildPluginDownloadUrlParams = (file) => {
    if (isBuildPluginDownloadUrlParamsValid(file)) {
        return true;
    }

    throw new Error(EZLO_PLUGINS_BUILD_PLUGIN_DOWNLOAD_URL_INVALID_PARAMS);
};

const extractCloudDownloadPluginParams = (file) => {
    const url = new URL(file);
    const uuid = url.pathname.substring(2);
    const key = url.searchParams.get(DASHBOARD_FILE_KEY_PROPERTY_NAME);

    const params = { uuid };
    if (key) {
        params.key = key;
    }

    return params;
};

/**
 * Convert dashboard file value to Plugin download URL
 * @param {string} file - dashboard's file value
 * @param {number} [redirect_support] - Whether the cloud API supports redirections.
 * @return {string} URL to download the file
 *
 * @see {@link https://confluence.mios.com/pages/viewpage.action?spaceKey=EPD&title=Custom+Scripts+v.10+-+Updates| Custom Scripts v.10}
 * */
export const buildPluginDownloadUrl = (file, redirect_support) => {
    validateBuildPluginDownloadUrlParams(file);
    let params = extractCloudDownloadPluginParams(file);

    // let's keep strict comparison
    if (redirect_support !== null && redirect_support !== undefined) {
        params = { ...params, redirect_support };
    }

    const rawPluginDownloadUrl = API_CLOUD_EZLO_DOWNLOAD_PLUGIN_URL(params);

    return replaceUrlPrefix(rawPluginDownloadUrl);
};

/**
 * Rows array for Plugin table
 * @param {array} data - list of Plugin
 * @returns {array} array of rows
 * @example
 * createPluginTable(data)
 * */
export const createPluginTable = (data) => {
    return data.map((item) => {
        const row = {};

        if (Object.keys(item.meta).length > 0) {
            row.config = item.meta?.config && item.meta.config;
            row.name = item.name && item.name;
            row.nameId = item.meta?.config?.id && item.meta?.config?.id;
            row.status = '-';
            row.certified = '-';
            row.version = item.meta?.config?.version && item.meta.config.version;
            row.shouldUpdate = item?.shouldUpdate;
            row.versionForUpdate = item.versionForUpdate;
            row.archive_hash_md5 = item?.meta?.archive_hash_md5;
            row.id = item?.meta?.config?.id;
            row.uuid = item.uuid;
            row.uuidFromMarketPlace = item.uuidFromMarketPlace;
            row.linkForDownloadFromMarketPlace = item.linkForDownloadFromMarketPlace;
            row.archive_hash_md5FromMarketPlace = item.archive_hash_md5FromMarketPlace;
            row.changelog = item.meta?.changelog?.changelog;
        }

        if (item?.data?.file) {
            // TODO Delete the following line and open the commented line after Cloud configures the file download with specifying the file format.
            row.downloadUrl = buildPluginDownloadUrl(item?.data?.file);
            // row.downloadUrl = buildPluginDownloadUrl(item?.data?.file, BOOLINT.TRUE);
        }

        row.id = item.uuid;

        return row;
    });
};

export const createPluginTableGenerator = (data) => {
    return data.map((item) => {
        return {
            nameId: item.name,
            id: item.uuid,
            data: item.data,
        };
    });
};

export const buildIpTemplatesTableColumns = ({
    type,
    onConfirm,
    onRouteToPage,
    onHandlerShowDevicesModal,
    onShowInfoModal,
}) => {
    return [
        {
            accessorKey: 'nameId',
            header: EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_IP_DEVICE_TITLE,
            muiTableHeadCellProps: { align: 'center' },
            size: 250,
            enableSorting: true,
            enableColumnFilter: true,
            enableColumnActions: true,
            muiTableBodyCellProps: { align: 'left' },
        },
        {
            accessorKey: TEMPLATE_INFO,
            header: EZLOGIC_TITLE_INFORMATION,
            muiTableHeadCellProps: { align: 'center' },
            size: 190,
            enableSorting: true,
            enableColumnFilter: true,
            enableColumnActions: true,
            muiTableBodyCellProps: { align: 'center' },
            Cell: (params) => {
                return (
                    <IconButton onClick={() => onShowInfoModal(params, TEMPLATE_INFO)}>
                        <InfoOutlined />
                    </IconButton>
                );
            },
        },
        type === PRIVATE_GENERATOR && {
            accessorKey: 'devices',
            header: EZLOGIC_HEADING_DEVICES,
            width: 250,
            muiTableHeadCellProps: { align: 'center' },
            size: 170,
            enableSorting: true,
            enableColumnFilter: true,
            enableColumnActions: true,
            muiTableBodyCellProps: { align: 'center' },
            Cell: (params) => {
                return (
                    <CountInstalledIPDevices params={params} onHandlerShowDevicesModal={onHandlerShowDevicesModal} />
                );
            },
        },
        type !== MARKETPLACE_GENERATOR &&
            type !== PUBLISH_GENERATOR && {
                accessorKey: 'publish',
                header: EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_PUBLISH_TITLE,
                muiTableHeadCellProps: { align: 'center' },
                size: 160,
                enableSorting: true,
                enableColumnFilter: true,
                enableColumnActions: true,
                muiTableBodyCellProps: { align: 'center' },
                Cell: (params) => {
                    return (
                        <IconButton onClick={() => onConfirm(params, 'publish')}>
                            <Public />
                        </IconButton>
                    );
                },
            },
        type !== MARKETPLACE_GENERATOR &&
            type !== PUBLISH_GENERATOR && {
                accessorKey: 'edit',
                header: EZLOGIC_BUTTON_EDIT,
                muiTableHeadCellProps: { align: 'center' },
                size: 120,
                enableSorting: true,
                enableColumnFilter: true,
                enableColumnActions: true,
                muiTableBodyCellProps: { align: 'center' },
                Cell: (params) => {
                    return (
                        <IconButton onClick={() => onRouteToPage('edit', params)}>
                            <Edit color="primary" />
                        </IconButton>
                    );
                },
            },
        type !== MARKETPLACE_GENERATOR && {
            accessorKey: 'delete',
            header: EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_DELETE_TITLE,
            muiTableHeadCellProps: { align: 'center' },
            size: 170,
            enableSorting: true,
            enableColumnFilter: true,
            enableColumnActions: true,
            muiTableBodyCellProps: { align: 'center' },
            Cell: (params) => {
                return (
                    <IconButton onClick={() => onConfirm(params, 'remove')}>
                        <Delete color="primary" />
                    </IconButton>
                );
            },
        },
        type !== MARKETPLACE_GENERATOR &&
            type !== PUBLISH_GENERATOR && {
                accessorKey: 'createDevice',
                header: EZLOGIC_BUTTON_CREATE_DEVICE,
                muiTableHeadCellProps: { align: 'center' },
                size: 250,
                enableSorting: true,
                enableColumnFilter: true,
                enableColumnActions: true,
                muiTableBodyCellProps: { align: 'center' },
                Cell: (params) => {
                    return (
                        <IconButton onClick={() => onRouteToPage('settings', params)}>
                            <AddCircleOutline />
                        </IconButton>
                    );
                },
            },
        type === MARKETPLACE_GENERATOR && {
            accessorKey: 'copyToPrivate',
            header: EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_COPY_OF_TITLE,
            muiTableHeadCellProps: { align: 'center' },
            size: 250,
            enableSorting: true,
            enableColumnFilter: true,
            enableColumnActions: true,
            muiTableBodyCellProps: { align: 'center' },
            Cell: (params) => {
                return (
                    <IconButton onClick={() => onConfirm(params, 'copy')}>
                        <FileCopy />
                    </IconButton>
                );
            },
        },
    ].filter((columnItem) => columnItem !== false);
};

/**
 * Columns config for Plugin table
 // * @param {array} props
 * @returns {array} array of columns
 * @example
 * buildMeshBotsTableColumns(props)
 * */
// TODO: rename!
export const buildMeshBotsTableColumns = (...args) => {
    const [
        type,
        onConfirm,
        onHandlerShowInstallModal,
        onHandlerShowInfoModal,
        onHandlerShowDevicesModal,
        devices,
        pluginData,
        onDownload,
    ] = args;

    return [
        {
            accessorKey: 'id',
            header: EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_ID_TITLE,
            minSize: 340,
            muiTableHeadCellProps: { align: 'center' },
        },
        {
            accessorKey: 'name',
            header: EZLOGIC_TITLE_PLUGIN_NAME,
            minWidth: 300,
            muiTableHeadCellProps: { align: 'center' },
            size: 300,
            enableSorting: true,
            enableColumnFilter: true,
            enableColumnActions: true,
            muiTableBodyCellProps: { align: 'left' },
        },
        {
            accessorKey: 'version',
            header: EZLOGIC_TITLE_VERSION,
            minWidth: 180,
            Cell: (params) => {
                return <PluginVersionCell params={params} onClick={onHandlerShowInstallModal} type={type} />;
            },
            muiTableHeadCellProps: { align: 'center' },
            size: 180,
            enableSorting: true,
            enableColumnFilter: true,
            enableColumnActions: true,
            muiTableBodyCellProps: { align: 'center' },
        },
        type === 'marketplace' && {
            accessorKey: 'certified',
            header: EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_CERTIFIED_TITLE,
            minWidth: 150,
            muiTableHeadCellProps: { align: 'center' },
            size: 112,
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnActions: false,
            muiTableBodyCellProps: { align: 'center' },
        },
        type !== 'publish' && {
            accessorKey: 'info',
            header: EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_INFO_TITLE,
            width: 120,
            sortable: false,
            disableColumnMenu: true,
            Cell: (params) => {
                return (
                    <IconButton onClick={() => onHandlerShowInfoModal(params.row.original)}>
                        <InfoOutlined />
                    </IconButton>
                );
            },
            muiTableHeadCellProps: { align: 'center' },
            size: 112,
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnActions: false,
            muiTableBodyCellProps: { align: 'center' },
        },
        type === 'publish' && {
            accessorKey: 'status',
            header: EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_STATUS_TITLE,
            minWidth: 250,
            muiTableHeadCellProps: { align: 'center' },
            size: 112,
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnActions: false,
            muiTableBodyCellProps: { align: 'center' },
        },
        type === 'private' && {
            accessorKey: DOWNLOAD,
            header: EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_DOWNLOAD_TITLE,
            width: 120,
            Cell: (params) => {
                return (
                    <IconButton onClick={() => onDownload(params.row.original, DOWNLOAD)}>
                        <CloudDownloadOutlined />
                    </IconButton>
                );
            },
            muiTableHeadCellProps: { align: 'center' },
            size: 112,
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnActions: false,
            muiTableBodyCellProps: { align: 'center' },
        },
        type === 'private' && {
            accessorKey: 'install',
            header: EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_MANAGE_INSTALLATION_TITLE,
            width: 300,
            sortable: false,
            disableColumnMenu: true,
            Cell: (params) => {
                return <CountInstalledPlugin params={params} onClick={onHandlerShowInstallModal} />;
            },
            muiTableHeadCellProps: { align: 'center' },
            size: 200,
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnActions: false,
            muiTableBodyCellProps: { align: 'center' },
        },
        type === 'private' && {
            accessorKey: 'devices',
            header: EZLOGIC_HEADING_DEVICES,
            width: 120,
            Cell: (params) => {
                return (
                    <CountInstalledDevices
                        params={params}
                        onHandlerShowDevicesModal={onHandlerShowDevicesModal}
                        devices={devices}
                        pluginData={pluginData}
                    />
                );
            },
            muiTableHeadCellProps: { align: 'center' },
            size: 112,
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnActions: false,
            muiTableBodyCellProps: { align: 'center' },
        },
        type !== 'marketplace' && {
            accessorKey: 'delete',
            header: EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_DELETE_TITLE,
            width: 120,
            Cell: (params) => {
                return (
                    <IconButton onClick={() => onConfirm(params.row.original, 'remove')}>
                        <Delete color="primary" />
                    </IconButton>
                );
            },
            muiTableHeadCellProps: { align: 'center' },
            size: 112,
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnActions: false,
            muiTableBodyCellProps: { align: 'center' },
        },
        type === 'marketplace' && {
            accessorKey: 'copyToPrivate',
            header: EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_COPY_OF_TITLE,
            width: 180,
            Cell: (params) => {
                return (
                    <IconButton onClick={() => onConfirm(params.row.original, 'copy')}>
                        <FileCopy />
                    </IconButton>
                );
            },
            muiTableHeadCellProps: { align: 'center' },
            size: 112,
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnActions: false,
            muiTableBodyCellProps: { align: 'center' },
        },
    ].filter((columnItem) => columnItem !== false);
};

export const colors = [
    '#b71c1c',
    '#f44336',
    '#880e4f',
    '#e91e63',
    '#4a148c',
    '#673ab7',
    '#1a237e',
    '#3f50b5',
    '#28579b',
    '#4ba9f4',
    '#194e33',
    '#4caf50',
    '#827717',
    '#cddc39',
    '#f26f04',
    '#f6c108',
    '#263238',
    '#607d8b',
    '#000000',
    '#969696',
];

/**
 * Get sum installed plugins
 * @param {object} plugins - list of Plugin
 * @param {object} installedPlugins - list of installed plugins
 * @returns {object} object plugins and count installed plugins
 * @example
 * getSumInstalledPlugins(plugins, installedPlugins)
 * */

export const getSumInstalledPlugins = (plugins, installedPlugins) => {
    return Object.values(plugins).reduce((acc, item) => {
        return {
            ...acc,
            [`${item.meta?.config?.id + '_' + item.meta?.config?.version}`]: Object.values(installedPlugins).reduce(
                (sum, controller) => {
                    let sumPlugins = 0;
                    controller?.forEach((elemFromController) => {
                        if (
                            elemFromController.plugin === item?.meta?.config?.id &&
                            elemFromController.version === item.meta.config.version
                        ) {
                            sumPlugins += 1;
                        }
                    });

                    return sum + sumPlugins;
                },
                0,
            ),
        };
    }, {});
};

/**
 * Get controllers installed plugins
 * @param {object} plugins - list of Plugin
 * @param {object} installedPlugins - list of installed plugins
 * @returns {array} returns objects in array which have controller serial
 * @example
 * getControllersPluginInstalled(plugins, installedPlugins)
 * */

export const getControllersPluginInstalled = (plugins, installedPlugins) => {
    return plugins.map((plugin) => {
        return Object.entries(installedPlugins).reduce((acc, [serial, controller]) => {
            const installed = controller?.find(
                (item) => item.plugin === plugin.meta?.config?.id && item?.version === plugin.meta?.config?.version,
            );

            return [
                ...acc,
                {
                    id: `${plugin.meta?.config?.id + '_' + plugin.meta?.config?.version}`,
                    serial: installed ? serial : false,
                    version: plugin.meta?.config?.version,
                },
            ];
        }, []);
    });
};

/**
 * Set list devices installed
 * @param {array} data - list of plugin
 * @param {array} devices - list devices
 * @returns {object} returns objects with count installed devices
 * @example
 * setListDevicesInstalled(data, devices)
 * */

export const setListDevicesInstalled = (data, devices) => {
    return data.reduce((acc, plugin) => {
        return {
            ...acc,
            [plugin[0]?.id]: devices.reduce((sum, device) => {
                let sumPlugins = 0;

                plugin.forEach((elem) => {
                    if (
                        device.serial === elem.serial &&
                        `${device?.gateway?.pluginId + '_' + device?.version}` === elem.id
                    ) {
                        sumPlugins += 1;
                    }
                });

                return sum + sumPlugins;
            }, 0),
        };
    }, {});
};

export const getDevicesWithVersion = (devices, listInstalledPlugins, gateways) => {
    return devices.map((device) => {
        if (listInstalledPlugins[device.serial]) {
            const pluginId = gateways?.find((gateway) => gateway?._id === device?.gatewayId)?.pluginId;
            device.version =
                listInstalledPlugins?.[device?.serial]?.find((plugin) => plugin?.plugin === pluginId)?.version || '';
        }

        return device;
    });
};

/**
 * Set list devices installed wifi
 * @param {array} data - list of plugin
 * @param {array} devices - list devices
 * @returns {object} returns objects with count installed devices
 * @example
 * setListDevicesInstalledWifi(data, devices)
 * */

export const setListDevicesInstalledWifi = (data, devices) => {
    if (!Array.isArray(data) || !Array.isArray(devices)) {
        throw 'Error! The type of the transmitted data does not match the required one';
    }

    return data.reduce((acc, plugin) => {
        return {
            ...acc,
            [plugin.name]: devices.reduce((sum, device) => {
                let sumPlugins = 0;

                if (device?.info?.hasOwnProperty('template')) {
                    if (plugin.uuid === device.info.template) {
                        sumPlugins += 1;
                    }
                }

                return sum + sumPlugins;
            }, 0),
        };
    }, {});
};

/**
 * Remove plugin
 * @param {object} installedPlugins - list of plugin
 * @param {object} pluginData - current data
 * @param t - translation
 * @param  dispatch - dispatch
 * @example
 * removePlugin(installedPlugins, pluginData, actions)
 * */

export const removePlugin = (installedPlugins, pluginData, dispatch, t) => {
    const namePlugin = pluginData?.nameId;
    Object.entries(installedPlugins).forEach((item) => {
        if (item[1].find((plugin) => plugin.plugin === pluginData?.nameId && plugin.version === pluginData?.version)) {
            dispatch(
                PluginActions.subscribeUpdateController(
                    item[0],
                    dispatch(PluginActions.notificationUpdateController(item[0], 'remove', t)),
                ),
            );
            dispatch(PluginActions.removePlugin(item[0], namePlugin));
        }
    });
};

/**
 * Get installed plugins
 * @param {object} installedPlugins - list of plugin
 * @param {object} pluginData - current data
 * @example
 * getInstalledPlugin(installedPlugins, pluginData)
 * */

export const getInstalledPlugin = (installedPlugins, pluginData) => {
    if (!installedPlugins || !pluginData) {
        throw TYPE_ERROR;
    }

    return Object.entries(installedPlugins).reduce((acc, controller) => {
        const installed = controller?.[1]?.some((item) => {
            return item.plugin === pluginData?.nameId && item.version === pluginData?.version;
        });

        if (!installed) {
            return acc;
        }

        return [...acc, installed];
    }, []);
};

/**
 * Controllers plugin installed
 * @param {object} installedPlugins - list of plugin
 * @param {object} pluginData - current data
 * @example
 * controllersPluginInstalled(installedPlugins, pluginData)
 * */

export const controllersPluginInstalled = (installedPlugins, pluginData) => {
    return Object.entries(installedPlugins).reduce((acc, [serial, controller]) => {
        const installed = controller?.find(
            (item) => item.plugin === pluginData?.nameId && item.version === pluginData?.version,
        );

        if (!installed) {
            return acc;
        }

        return { ...acc, [serial]: { id: installed.plugin, serial: serial, version: installed.version } };
    }, {});
};

export const getPluginsInstalledForIntegration = (installedPlugins, pluginData) => {
    return Object.entries(installedPlugins).reduce((acc, [serial, controller]) => {
        const installed = controller?.find((item) => item.plugin === pluginData?.meta?.config?.id);

        if (!installed) {
            return acc;
        }

        return { ...acc, [serial]: { id: installed.plugin, serial: serial, version: installed.version } };
    }, {});
};

/**
 * List devices installed
 * @param {array} devices - list devices
 * @param {object} pluginInstalled - controllers plugin installed
 * @example
 * listDevicesInstalled(devices, pluginInstalled)
 * */

export const listDevicesInstalled = (devices, pluginInstalled) => {
    return devices.reduce((acc, device) => {
        const controller = pluginInstalled && pluginInstalled[device.serial];
        if (controller && device.serial === controller.serial && device?.gateway?.pluginId === controller.id) {
            return [...acc, { serial: device.serial, deviceName: device.name, ...device }];
        }

        return acc;
    }, []);
};

export const filterDevicesBySerial = (devices, serial) => {
    return devices.filter((device) => device.serial === serial);
};

export const getCountInstalledDevicesByIntegration = (devices, integration, integrationType) => {
    if (integrationType === PLUGIN) {
        return devices.filter((device) => device?.gateway?.pluginId === integration?.meta?.config?.id);
    }

    if (integrationType === TEMPLATE) {
        return devices.filter((device) => device?.info?.template === integration?.uuid);
    }
};

/**
 * List devices installed
 * @param {object} template - list current template
 * @param {array} devices - list devices
 * @example
 * listDevicesInstalledWifi(template, devices)
 * */
export const listDevicesInstalledWifi = (template, devices) => {
    if (!Array.isArray(devices)) {
        throw 'Error! The type of the transmitted data does not match the required one';
    }

    return devices?.reduce((acc, device) => {
        if (device?.info?.hasOwnProperty('template')) {
            if (template?.id === device?.info?.template || template?.uuid === device?.info?.template) {
                return [...acc, { serial: device?.serial, deviceName: device?.name, ...device }];
            }
        }

        return [...acc];
    }, []);
};

/**
 * List installed node
 * @param {object} listPlugins - list installed nodes
 * @returns {object} returns object with serials number controllers which node installed
 * @example
 * installedOnControllers(listInstalledPlugins)
 * */

export const installedOnControllers = (listPlugins) => {
    if (!listPlugins) {
        throw 'Error! The type of the transmitted data does not match the required one';
    }

    return Object.entries(listPlugins).reduce((acc, [serial, controller]) => {
        const installed = controller?.find((node) => node.shortName === WIFI_DEVICE_GENERATOR);

        return { ...acc, [serial]: installed ? true : false };
    }, {});
};

/**
 * List capabilities for select
 * @param {object} capabilities - list capabilities
 * @returns {array} returns an array with the correct structure for the select
 * @example
 * createCapabilityForSelect(capabilities)
 * */

export const createCapabilityForSelect = (capabilities) => {
    if (!capabilities) {
        throw 'Error! The type of the transmitted data does not match the required one';
    }

    return Object.entries(capabilities).reduce((acc, [action, value]) => {
        return [...acc, { id: hash(), name: action, actions: value }];
    }, []);
};

/**
 * Capabilities transform for add devices
 * @param {object} capabilities - list capabilities
 * @param {string} plugin_version - template plugin version
 * @returns {object} returns an object with the correct structure
 * @example
 * transformCapabilitiesFrom(data)
 * */

export const transformCapabilitiesFrom = (capabilities, plugin_version) => {
    if (!capabilities) {
        throw 'Error! The type of the transmitted data does not match the required one';
    }

    return Object.entries(capabilities).reduce((acc, [action, capability]) => {
        return { ...acc, [action]: createActionsFrom(capability, plugin_version) };
    }, {});
};

/**
 * Transform incoming capabilities
 * @param {object} capabilities - list capabilities
 * @returns {array} returns an array with the correct structure
 * @example
 * transformCapabilitiesTo(data)
 * */

export const transformCapabilitiesTo = (capabilities) => {
    if (!capabilities) {
        throw 'Error! The type of the transmitted data does not match the required one';
    }

    return Object.entries(capabilities).reduce((acc, [action, capability]) => {
        return [
            ...acc,
            {
                id: hash(),
                command: action,
                actions: _.cloneDeep(capability),
                isValid: {
                    shouldValidate: false,
                    isValid: true,
                },
            },
        ];
    }, []);
};

/**
 * Search for sub-categories by category name
 * @param {array} categories - list categories
 * @param {string} nameCategory - name category
 * @returns {array} returns an array with subcategory
 * @example
 * getSubCategory(nameCategory)
 * */

export const getSubCategory = (categories, nameCategory) => {
    let subcategory = [];

    if (!Array.isArray(categories)) {
        throw 'Error! The type of the transmitted data does not match the required one';
    }

    categories.forEach((category) => {
        if (category.value === nameCategory && category.subcategory.length > 0) {
            // eslint-disable-next-line
            subcategory = category.subcategory;
        }
    });

    return subcategory;
};

/**
 * Update capability item
 * @param {array} list - capability list
 * @param {string} name - action name
 * @param {string} value - action value
 * @param {string} id - id item
 * @param {string} actionName - id action
 * @param {object} isValid - field validation
 * @returns {array} returned updated capability array
 * @example
 * updatedCapabilityList(data, commands, name, value, id, action)
 * */
export const updatedCapabilityList = (list, name, value, id, actionName, isValid, objKey) => {
    if (!Array.isArray(list)) {
        throw 'Error! The type of the transmitted data does not match the required one';
    }

    return list.map((item) => {
        if (item.id === id) {
            const newItem = { ...item };

            newItem.actions = Array.isArray(newItem.actions) ? newItem.actions : [];
            newItem.actions = newItem.actions.map((action) => {
                if (action.id === actionName) {
                    if (objKey) {
                        return {
                            ...action,
                            [objKey]: {
                                ...action[objKey],
                                [name]: value,
                            },
                        };
                    } else {
                        return { ...action, [name]: value };
                    }
                }

                return action;
            });
            newItem.isValid = isValid;

            return newItem;
        }

        return item;
    });
};

const generateActionsList = (actionsList = []) => {
    return actionsList?.map(([item, data]) => {
        let action = { id: item };

        if (typeof data === STRING) {
            action[item] = '';
            action.type = data;
        }

        if (typeof data === OBJECT) {
            action = { ...action, ...getRequestFields(data) };
        }

        return action;
    });
};

/**
 * Set capability command
 * @param {array} list - capability list
 * @param {string} id - id item
 * @param {array} commands - commands list
 * @param {string} value - action value
 * @param {boolean} isMqttInclude - is mqtt protocol exist in protocols list
 * @param {string} protocol - protocol value
 * @returns {array} returned updated capability array
 * @example
 * setCapabilityCommand(list, id, commands, value)
 * */
export const setCapabilityCommand = (list, id, commands, value, isMqttInclude, protocol) => {
    if (!Array.isArray(list) || !Array.isArray(commands)) {
        throw 'Error! The type of the transmitted data does not match the required one';
    }

    return list.map((item) => {
        if (item.id === id) {
            const currentCommand = commands.find((command) => command.name === value);

            if (currentCommand) {
                const actionsList = isMqttInclude
                    ? Object.entries(orderKeys(currentCommand?.actions?.[protocol]))
                    : Object.entries(orderKeys(currentCommand?.actions));

                item = { ...item, actions: [...generateActionsList(actionsList), { protocol }] };
            }

            item.command = value;
            item.protocol = protocol;
            item.isValid = { shouldValidate: false, isValid: true };
        }

        return item;
    });
};

const orderKeys = (obj = {}) => {
    const primitiveKeys = Object.keys(obj).filter((key) => typeof obj[key] !== OBJECT);
    const objectKeys = Object.keys(obj).filter((key) => typeof obj[key] === OBJECT);

    const reorderedObj = {};

    primitiveKeys.forEach((key) => {
        reorderedObj[key] = obj[key];
    });

    objectKeys.forEach((key) => {
        reorderedObj[key] = obj[key];
    });

    return reorderedObj;
};
const getField = (value) => {
    if (Array.isArray(value)) {
        return value.map((item) => {
            return { value: item, label: formatNameWithUnderscores(item) };
        });
    } else {
        return value.split(',').map((item) => {
            return { value: item, label: formatNameWithUnderscores(item) };
        });
    }
};
const hasNonStringProperty = (obj) => {
    for (const key in obj) {
        if (obj.hasOwnProperty(key) && typeof obj[key] !== 'string') {
            return true;
        }
    }

    return false;
};
const flattenObject = (obj, defaultValue = '') => {
    const result = {};
    const flatten = (data, parentKey = '') => {
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const currentKey = parentKey ? `${parentKey}.${key}` : key;

                if (typeof data[key] === JAVASCRIPT_TYPES.OBJECT && data[key] !== null) {
                    flatten(data[key], currentKey);
                } else {
                    result[currentKey] = defaultValue;
                }
            }
        }
    };

    flatten(obj);

    return result;
};
const createNestedObject = (flatObject) => {
    const nestedResult = {};
    for (const key in flatObject) {
        const keys = key.split('.');
        keys.reduce((acc, currentKey, index) => {
            if (!acc[currentKey]) {
                acc[currentKey] = index === keys.length - 1 ? flatObject[key] : {};
            }

            return acc[currentKey];
        }, nestedResult);
    }

    return nestedResult;
};
const getRequestFields = (data) => {
    if (hasNonStringProperty(data) && !data.hasOwnProperty(ENUM)) {
        return createNestedObject(flattenObject(data, ''));
    }

    if (data.hasOwnProperty(ENUM)) {
        return {
            [ENUM]: getField(data[ENUM]),
            ...Object.entries(data).reduce((acc, [field, value]) => {
                return field !== ENUM ? { ...acc, [field]: value } : acc;
            }, {}),
        };
    }

    return Object.keys(data).reduce((acc, field) => {
        return { ...acc, [field]: '' };
    }, {});
};

/**
 * Capability transform for config
 * @param {array} capabilityList - capability list
 * @returns {object} returns an object with capability
 * @example
 * capabilityTransformForConfig(capabilityList)
 * */

export const capabilityTransformForConfig = (capabilityList) => {
    if (!Array.isArray(capabilityList)) {
        throw 'Error! The type of the transmitted data does not match the required one';
    }

    return capabilityList.reduce((acc, item) => {
        // eslint-disable-next-line no-unused-vars
        const { isValid, ...rest } = item;

        return { ...acc, [rest.command]: rest.actions };
    }, {});
};

const createActionsFrom = (data, plugin_version) => {
    return Array.from(data)?.reduce((sum, action) => {
        if (action.hasOwnProperty(METHOD)) {
            const key = action.id;
            const fields = _.cloneDeep(action);
            delete fields.id;

            return { ...sum, [key]: fields };
        }

        return getObjectForDeviceByPluginCreating(sum, action, plugin_version);
    }, {});
};

const getObjectForDeviceByPluginCreating = (sum, action, plugin_version) => {
    if (plugin_version === OLD_IP_DEVICE_GENERATOR_VERSION) {
        return { ...sum, [action.id]: action[action.id] };
    } else {
        return { ...sum, [action.id]: action[action.id], protocol: action.protocol };
    }
};

/**
 * Set headers in create template
 // * @param {array} capabilityList - capability list
 * @returns {object} returns an object with capability
 * @example
 * setHeaders(list, id, field, value)
 * */

export const setHeaders = (list, id, field, value, authField) => {
    if (!Array.isArray(list)) {
        throw 'Error! The type of the transmitted data does not match the required one';
    }

    return list.map((item) => {
        if (item.id === id) {
            if (field === KEY && !value) {
                item[field] = `__empty-${hash()}`;

                return item;
            }

            item[field] = value;
        }

        if (authField) {
            return {
                ...item,
                value: value,
            };
        }

        return item;
    });
};

export const isPluginShouldUpgrade = ({ marketplacePlugin = {}, privatePlugin = {}, listInstalledPlugins = [] }) => {
    const currentPlugin = getPluginsInstalled(listInstalledPlugins).find(
        (plugin) =>
            plugin?.plugin === privatePlugin?.meta?.config?.id &&
            plugin?.version === privatePlugin?.meta?.config?.version,
    );

    return (
        privatePlugin?.meta?.config?.version &&
        marketplacePlugin?.meta?.config?.version &&
        privatePlugin?.meta?.config?.version !== marketplacePlugin?.meta?.config?.version &&
        currentPlugin?.plugin === marketplacePlugin?.meta?.config?.id &&
        currentPlugin?.version === privatePlugin?.meta?.config?.version &&
        !checkVersionSupport(currentPlugin?.version, marketplacePlugin?.meta?.config?.version)
    );
};

export const pluginsArrayWithUpdateInformation = (
    privatePlugins = [],
    marketplacePlugins = [],
    listInstalledPlugins,
) => {
    return privatePlugins?.map((privatePlugin) => {
        marketplacePlugins?.forEach((marketplacePlugin) => {
            if (
                isPluginShouldUpgrade({
                    marketplacePlugin,
                    privatePlugin,
                    listInstalledPlugins,
                })
            ) {
                privatePlugin = {
                    ...privatePlugin,
                    shouldUpdate: true,
                    versionForUpdate: marketplacePlugin?.meta?.config?.version,
                    uuidFromMarketPlace: marketplacePlugin?.uuid,
                    linkForDownloadFromMarketPlace: buildPluginDownloadUrl(JSON.parse(marketplacePlugin.data)?.file),
                    archive_hash_md5FromMarketPlace: marketplacePlugin?.meta?.archive_hash_md5,
                };
            }
        });

        return privatePlugin;
    });
};

export const getPluginsInstalled = (listInstalledPlugins = []) => {
    return Object.values(listInstalledPlugins).reduce((acc = [], controller) => {
        return acc.concat(controller);
    }, []);
};
export const filterByName = (data = []) => {
    return data.sort((a, b) => a.name.localeCompare(b.name));
};

export const getFilteredControllers = (controllers) => {
    return controllers.filter((controller) => {
        return controller?.hardware !== VALVE;
    });
};

export const getFilteredControllersForIPDevices = (controllers, integration) => {
    return controllers.filter((controller) => {
        return (
            controller?.hardware !== VALVE &&
            controller?.devices?.find((item) => item?.info?.template === integration?.uuid)
        );
    });
};

export const getValveControllers = (controllers) => {
    return controllers.filter((controller) => controller?.hardware === VALVE);
};

export const filteredEzlosControllers = (controllers, valveControllers) => {
    return controllers.filter((controller) => controller.serial !== findControllerById(valveControllers)?.serial);
};

export const findControllerById = (valveControllers) => {
    return valveControllers.find((valveController) => valveController.serial);
};

export const isPluginDownloadedFromMarketplace = ({ marketplacePlugins, hash_md5, privatePlugins, pluginData }) => {
    const pluginFromMarketPlace = getPluginFromMarketplaceByHash({ marketplacePlugins, hash_md5, pluginData });

    return privatePlugins.find(
        (plugin) =>
            plugin?.meta?.config?.id === pluginFromMarketPlace?.meta?.config?.id &&
            plugin?.meta?.config?.version === pluginFromMarketPlace?.meta?.config?.version,
    );
};

const getPluginFromMarketplaceByHash = ({ marketplacePlugins = [], hash_md5 = '', pluginData }) => {
    return marketplacePlugins?.find((plugin) => {
        return plugin?.meta.archive_hash_md5 === hash_md5 || plugin?.uuid === pluginData?.id;
    });
};

export const getPluginFromMarketplace = ({ marketplacePlugins = [], installedPlugin = {} }) => {
    return marketplacePlugins.find(
        (plugin) =>
            plugin?.meta?.config?.id === installedPlugin?.plugin ||
            plugin?.meta?.config?.id === installedPlugin?.meta?.config?.id ||
            plugin?.version,
    );
};
export const getInstalledPluginFromRow = ({ pluginsList = [], plugin = {} }) => {
    return pluginsList?.find(
        (item) =>
            item?.plugin === plugin?.row?.nameId ||
            item?.plugin === plugin?.nameId ||
            item?.plugin === plugin?.meta?.config?.id ||
            item?.plugin === plugin?.plugin,
    );
};

export const getUpdatedPluginRow = ({ currentPluginRow, pluginFromMarketPlace }) => {
    return {
        ...currentPluginRow,
        versionForUpdate: pluginFromMarketPlace?.meta?.config?.version,
        uuidFromMarketPlace: pluginFromMarketPlace?.uuid,
        linkForDownloadFromMarketPlace: buildPluginDownloadUrl(JSON.parse(pluginFromMarketPlace?.data)?.file),
        archive_hash_md5FromMarketPlace: pluginFromMarketPlace?.meta?.archive_hash_md5,
    };
};

export const notificationControllerUpdateMessage = (type) => {
    return type === UPGRADE ? EZLOGIC_TITLE_PLUGIN_SUCCESSFULLY_UPDATED : EZLOGIC_TITLE_CONTROLLER_SUCCESSFULLY_UPDATED;
};

export const isPluginInstalled = (listInstalledPlugins, serial, integration) => {
    return listInstalledPlugins[serial]?.filter(
        (item) => item.plugin === integration.meta?.config?.id || item.plugin === integration,
    )?.length;
};
/**
 * Checks only first img file for validity
 * @param {File[]} acceptedFiles - img file list
 * @returns {Boolean} If the file is valid, it will return true
 * @example
 * imgValidation([{name: 'logo.png'}])
 */
export const imgValidation = (acceptedFiles) => {
    const imgFile = acceptedFiles?.[0];
    const isValid = isFileNameValid(imgFile?.name);

    return Boolean(isValid && imgFile);
};

export const getDeviceTypeValue = (value, arr) => {
    return arr.find((device) => device.value === value);
};

export const isEmptyStringInArray = (arr = []) => {
    return !arr?.length || (arr?.length && arr.some((str) => str === ''));
};

export const isMinMaxValid = (capabilityList) => {
    for (const key in capabilityList) {
        const arr = capabilityList[key];

        const minObj = arr.find((obj) => obj.id === MIN);
        const maxObj = arr.find((obj) => obj.id === MAX);

        if (minObj && maxObj) {
            const isMin = minObj.min;
            const isMax = maxObj.max;

            return isMin < isMax;
        }
    }

    return true;
};

export const isTemplateValid = ({ model, category, capabilityList, subcategoryList, subcategory }) => {
    return !!(
        (model &&
            category &&
            !isEmptyStringInArray(Object.keys(capabilityTransformForConfig(capabilityList))) &&
            subcategoryList.length &&
            subcategory &&
            isMinMaxValid(capabilityTransformForConfig(capabilityList)) &&
            isAllCapabilitiesValid(capabilityList)) ||
        (model &&
            category &&
            !isEmptyStringInArray(Object.keys(capabilityTransformForConfig(capabilityList))) &&
            !subcategoryList.length &&
            !subcategory &&
            isMinMaxValid(capabilityTransformForConfig(capabilityList)) &&
            isAllCapabilitiesValid(capabilityList))
    );
};

export const isAllCapabilitiesValid = (arr) => {
    return arr.every((obj) => obj.isValid?.isValid);
};

/**
 * Retrieves the command associated with a specific capability ID from a list of capabilities.
 *
 * @param {Array} capabilityList - An array of capability objects.
 * @param {string} targetId - The ID of the target capability.
 * @returns {string|undefined} The command associated with the specified capability ID,
 * or `undefined` if the capability or command is not found.
 *
 * @example
 * const capabilities = [
 *     {
 *         "id": "d80124ed-727e-3cdd-0ae0-50fcd516e392",
 *         "command": "motion",
 *     },
 * ];
 * const targetId = "d80124ed-727e-3cdd-0ae0-50fcd516e392";
 * const command = getCommand(capabilities, targetId);
 * console.log(command); // Returns 'motion'
 */
export const getCommand = (capabilityList, targetId) => {
    return capabilityList
        ?.map((obj) => {
            if (obj.id === targetId) {
                return obj;
            }
        })
        ?.find((obj) => obj?.command)?.command;
};

/**
 * Retrieves the command associated with a specific capability ID from a list of capabilities during editing.
 *
 * @param {Array} capabilityList - An array of capability objects.
 * @param {string} id - The ID of the capability being edited.
 * @returns {string|undefined} The command associated with the specified capability ID during editing,
 * or `undefined` if the capability or command is not found.
 *
 * @example
 * const capabilities = [
 *     {
 *         "id": "d80124ed-727e-3cdd-0ae0-50fcd516e392",
 *         "command": "motion",
 *     },
 * ];
 * const editingCapabilityId = "d80124ed-727e-3cdd-0ae0-50fcd516e392";
 * const command = getCommandFromEditing(capabilities, editingCapabilityId);
 * console.log(command); // Returns 'motion'
 */
export const getCommandFromEditing = (capabilityList = [], id = '') => {
    return capabilityList.find((capability) => capability.id === id)?.command;
};

export const getCommandObj = (commandList = [], value = '') => {
    return commandList.find((command) => command.name === value);
};

export const getProtocolsList = (command = {}) => {
    if (command?.actions && HTTP in command.actions) {
        return Object.keys(command.actions);
    } else {
        return [HTTP];
    }
};

export const isMqttInclude = (protocolsList = []) => {
    return protocolsList.includes(MQTT);
};

export const getObjectInArrayById = (id, capabilityList) => {
    return capabilityList.find((capability) => capability.id === id);
};
export const getIsMinMaxValid = (id, capabilityList) => {
    const obj = getObjectInArrayById(id, capabilityList);

    const min = obj?.actions.find((action) => action?.id === MIN)?.min;
    const max = obj?.actions.find((action) => action?.id === MAX)?.max;

    if (!min || !max) {
        return false;
    }

    return min < max;
};

export const getIpDeviceGeneratorVersion = (listInstalledPlugins = {}, serial = '', pluginName = '') => {
    return listInstalledPlugins?.[serial]?.find((plugin) => plugin.plugin === pluginName)?.version;
};

export const getMainBlockKeys = (action) => {
    const commonKeyPresent = MAIN_REQUEST_BLOCK_FIELDS.some((key) => action.hasOwnProperty(key));

    return commonKeyPresent ? MAIN_REQUEST_BLOCK_FIELDS : [];
};

export const isObjectHasAnyKeys = (obj, keys) => keys.some((key) => obj.hasOwnProperty(key));

export const createArrayOfNonObjectKeys = (obj) => {
    return Object.keys(obj).filter((key) => {
        const excludedKeys = [METHOD, URI, CHECKBOX_BLOCK, HEADERS, DATA, ID, TYPE, CONTENT_TYPE];

        return !excludedKeys.includes(key) && typeof obj[key] !== OBJECT;
    });
};

export const getObjectValuedKeys = (obj) => {
    const result = {};

    Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === OBJECT) {
            result[key] = obj[key];
        }
    });

    return result;
};

export const getTemplateVersionStatus = (templateVersion, ipDeviceGeneratorPluginVersion) => {
    const templateVersionArr = templateVersion?.split('.').map(Number);
    const ipDeviceGeneratorPluginVersionArr = ipDeviceGeneratorPluginVersion?.split('.').map(Number);

    const maxLength = Math.max(templateVersionArr?.length, ipDeviceGeneratorPluginVersionArr?.length);

    for (let i = 0; i < maxLength; i++) {
        const templateVersionNum = templateVersionArr[i] || 0;
        const ipDeviceGeneratorPluginVersionNum = ipDeviceGeneratorPluginVersionArr[i] || 0;

        if (templateVersionNum > ipDeviceGeneratorPluginVersionNum) {
            return BIGGER;
        } else if (templateVersionNum < ipDeviceGeneratorPluginVersionNum) {
            return LOWER;
        }
    }

    return EQUAL;
};

export const findMaxVersionPlugin = (marketplacePlugins, pluginId) => {
    let result = null;
    let maxVersion = 0;

    for (const plugin of marketplacePlugins) {
        const currentPluginId = plugin?.meta?.config?.id;

        if (currentPluginId === pluginId) {
            const pluginVersion = plugin?.meta?.config?.version || '0.0.0';
            const versionNumber = pluginVersion.split('.').reduce((acc, val) => acc * 100 + parseInt(val), 0);

            if (versionNumber > maxVersion) {
                maxVersion = versionNumber;
                result = plugin;
            }
        }
    }

    return result;
};

export const getInstalledPluginByName = (pluginsList = [], pluginName = '') => {
    return pluginsList.find((plugin) => plugin?.plugin === pluginName);
};
export const getIsFieldsShouldBeVisible = (action) => {
    return (
        !action.hasOwnProperty(METHOD) &&
        !action.hasOwnProperty(PROTOCOL) &&
        action.id !== GET &&
        action.id !== SET &&
        action.id !== SET_LEVEL
    );
};

export const isMacAddressSupported = (installedPlugin) => {
    return getTemplateVersionStatus(installedPlugin, OLD_IP_DEVICE_GENERATOR_VERSION) === EQUAL;
};

export const getCreateIpDeviceFromTemplateParams = ({
    deviceName,
    macAddress,
    ipAddress,
    port,
    userName,
    password,
    uuid,
    data,
    isMacAddressVisible,
}) => {
    const plugin_version = data?.meta?.plugin_version;
    const params = {
        device_name: deviceName,
        ip_address: ipAddress,
        port: port,
        username: userName,
        password: password,
        template: {
            uuid: uuid,
            model: data?.config?.model,
            device_type: data?.config?.device_type,
            category: data?.config?.category,
            subcategory: data?.config?.subcategory,
            capabilities: transformCapabilitiesFrom(data?.config?.capabilities, plugin_version),
        },
    };

    if (isMacAddressVisible) {
        return {
            ...params,
            mac_address: macAddress,
        };
    } else {
        return params;
    }
};

export const getClassName = (field) => {
    switch (field) {
        case PATTERN:
            return style.pattern;
        case MQTT_TOPIC:
            return style.mqttTopic;
        case ON_STATE:
            return style.onState;
        case OFF_STATE:
            return style.offState;
        case MIN:
            return style.min;
        case MAX:
            return style.max;
        case AUTO:
            return style.auto;
        case COOL:
            return style.cool;
        case HEAT:
            return style.heat;
        case OFF_LOVER_CASE:
            return style.off;
        case ON_LOVER_CASE:
            return style.on;
        default:
            return '';
    }
};

export const getClassNameForObjField = (subObject) => {
    if (isObject(subObject)) {
        return Object.keys(subObject).reduce((acc, name) => {
            acc[name] = getClassName(name);

            return acc;
        }, {});
    }
};

export const getClassNameForUnknownFields = (randomRequestFields) => {
    return randomRequestFields.reduce((acc, field) => {
        acc[field] = getClassName(field);

        return acc;
    }, {});
};

export const getClassNamesForActionFields = (capability) => {
    return capability.actions.reduce((acc, action) => {
        if (getIsFieldsShouldBeVisible(action)) {
            acc[action.id] = getClassName(action.id);
        }

        return acc;
    }, {});
};

export const getConfigureInputType = (type, plugin, name, showPassword) => {
    if (name === PASSWORD && !showPassword) {
        return FIELD_TYPES.PASSWORD;
    }

    if (name === PASSWORD && showPassword) {
        return FIELD_TYPES.TEXT;
    }

    if ((type === plugin.INT && name !== PASSWORD) || (type === plugin.FLOAT && name !== PASSWORD)) {
        return plugin.NUMBER;
    }

    return type;
};

export const getAuthValue = (value, authType, type, passwordValue, usernameValue) => {
    return type === RAW_VALUE
        ? value
        : `${authType} ${
              type === USER_NAME ? encodeData(value + ':' + passwordValue) : encodeData(usernameValue + ':' + value)
          }`;
};

export const appendStringWithAmpersand = (encodeValue = '', appendString = '') => {
    return encodeValue ? encodeValue + '&' + appendString : appendString;
};

export const removeAuthorizationFromArray = (inputArray = []) => {
    const filteredArray = inputArray.filter((item) => !item.startsWith('Authorization:'));

    return filteredArray.length === 1 && filteredArray[0] === '' ? [] : filteredArray;
};

export const transformAuthorizationHeaders = (listHeaders = []) => {
    return listHeaders.map((header) => {
        const headerElements = header.split(':');

        return { id: hash(), key: headerElements[0], value: headerElements[1] || '' };
    });
};

export const getAuthorizationObj = (listHeaders = []) => {
    return listHeaders.find((header) => header.key === AUTHORIZATION);
};

export const getAuthType = (authorizationObj) => {
    if (!authorizationObj) {
        return NONE;
    }

    if (authorizationObj?.value && authorizationObj?.value.includes(BASIC)) {
        return BASIC;
    }

    if (
        (authorizationObj?.value && !authorizationObj?.value.includes(BASIC)) ||
        (!authorizationObj?.value && authorizationObj?.key === AUTHORIZATION)
    ) {
        return RAW_VALUE;
    }
};

export const getAuthorizationValue = (authorizationObj = {}) => {
    if (authorizationObj?.value?.includes(BASIC)) {
        const encodedValue = authorizationObj?.value?.split(' ')[ONE_INT];
        const decodedValue = decodeData(encodedValue);

        if (decodedValue.includes(':')) {
            const [username, password] = decodedValue.split(':');

            return { username, password };
        }
    } else {
        return { value: authorizationObj?.value };
    }
};

export const removeSubstringBeforeFirstAmpersand = (arr = []) => {
    const index = arr.indexOf('&');
    if (index !== MINUS_ONE_INT) {
        return arr.slice(index + ONE_INT);
    } else {
        return transformHeadersToString(arr);
    }
};

export const moveAuthorizationToFront = (str = '') => {
    if (str.includes('&')) {
        const authorizationIndex = str.indexOf('Authorization:');
        if (authorizationIndex !== -1) {
            const firstPart = str.slice(0, authorizationIndex);
            const authorizationPart = str.slice(authorizationIndex);

            return authorizationPart + '&' + firstPart;
        }
    }

    return str;
};

export const removeTrailingAmpersand = (str = '') => {
    return str.endsWith('&') ? str.slice(0, -1) : str;
};

export const isArrHasEmptyValuesInObj = (arr) => {
    return arr.some((obj) => {
        return Object.values(obj).some((value) => {
            return value === '' || (typeof value === 'string' && value.toLowerCase().includes('empty'));
        });
    });
};

export const isStringIncludesBlobParam = (string = '') => {
    return string.includes(BLOB);
};

/**
 * Removes all objects from the array except the first one.
 * @param {Array} array - The input array.
 * @returns {Array} - The array with only the first object.
 */
export const removeObjectsExceptFirst = (array = []) => {
    if (array.length > ONE_INT) {
        return array.slice(INDEX_OF_ZERO, ONE_INT);
    }

    return array;
};

/**
 * Checks if the object with the given capabilityId is the first object in the capabilityList array.
 * @param {Array} capabilityList - The list of capabilities.
 * @param {string} capabilityId - The ID of the capability to check.
 * @returns {boolean} - True if the object with the given capabilityId is the first object, otherwise false.
 */
export const isFirstObjectById = ({ capabilityList = [], capabilityId = '' }) => {
    const foundIndex = capabilityList.findIndex((obj) => obj.id === capabilityId);

    return foundIndex === ZERO_INT;
};

/**
 * Returns the value of the 'protocol' key from the first object in the array.
 * @param {Array} array - The input array of objects.
 * @returns {Array} - An array containing the value of the 'protocol' key from the first object.
 */
export const getFirstProtocolValueArray = (array = []) => {
    const firstObject = array.find((obj) => obj?.protocol || obj?.command);
    const getProtocolFromActionsArray = firstObject?.actions?.find((action) => action?.protocol)?.protocol;

    return [firstObject?.protocol || getProtocolFromActionsArray || ''];
};

/**
 * Returns the first value of an array without using index.
 * @param {[]} array - The input array.
 * @returns {string} - The first value of the array, or an empty string if the array is empty.
 */
export const getFirstValueFromArray = (array = []) => array.find((item) => item) || '';

/**
 * Returns the protocol from the template object.
 * @param {object} template - The template object.
 * @returns {string} - The protocol, or 'HTTP' if protocol is not found.
 */
export const getProtocol = (template) => {
    if (template?.data?.config?.capabilities) {
        const capabilities = Object.values(template?.data?.config?.capabilities);

        return capabilities.reduce((acc, capabilities) => {
            return capabilities.find((capability) => capability?.protocol)?.protocol || HTTP;
        }, '');
    }

    return HTTP;
};

export const getValueAfterBasic = (str) => {
    const startIndex = str.indexOf(BASIC);
    if (startIndex !== -1) {
        const base64String = str.slice(startIndex + BASIC.length).trim();

        return decodeData(base64String);
    }

    return '';
};

export const isEncodeValueValid = (encodeValue) => {
    if (!encodeValue) {
        return true;
    }
    const arrFromEncodeValue = encodeValue.split(':');

    return arrFromEncodeValue.length === INDEX_OF_TWO && !isEmptyStringInArray(arrFromEncodeValue);
};

export const getSrcForSupportedDevicePreview = ({ currentDevice, deviceImgFieldsState }) => {
    if (!deviceImgFieldsState?.imgFile?.preview && !deviceImgFieldsState?.logoFieldValue) {
        return currentDevice?.deviceImage;
    }

    return deviceImgFieldsState?.imgFile?.preview || deviceImgFieldsState?.logoFieldValue;
};

export const getIsAddCapabilityDisabled = ({ capabilityList }) => {
    return !!capabilityList.length && !getFirstProtocolValueArray(capabilityList)[ZERO_INT];
};

/**
 * Downloads an object as a JSON file.
 * @param {Object} objectData - The JSON object to download.
 * @param {string} filename - The filename without extension.
 */
export const downloadObjectAsJson = (objectData, filename) => {
    const jsonData = JSON.stringify(objectData);
    const blob = new Blob([jsonData], { type: APPLICATION_JSON });
    const link = document.createElement(ELEMENT_A);
    link.href = window.URL.createObjectURL(blob);
    link.download = filename + JSON_FORMAT;
    link.click();
};

export const getValidationText = (action, t, capability) => {
    if (isMinAndMaxAreEqual(capability)) {
        return t(EZLOGIC_TITLE_PLEASE_ENSURE_THAT_MIN_ANS_MAX_VALUES_NOT_EQUAL);
    }

    if ((action.id === MIN && !action?.min) || (action.id === MAX && !action?.max)) {
        return t(EZLOGIC_TITLE_VALUE_IS_REQUIRED);
    }

    if (action.id === MIN && action?.min) {
        return t(EZLOGIC_TITLE_MIN_VALUE_MUST_BE_LESS_THEN_MAX);
    }

    if (action.id === MAX && action?.max) {
        return t(EZLOGIC_TITLE_MAX_VALUE_MUST_BE_MORE_THEN_MAX);
    }
};

const isMinAndMaxAreEqual = (actions) => {
    const min = actions.find((action) => action?.id === MIN)?.min;
    const max = actions.find((action) => action?.id === MAX)?.max;

    return min && max && min === max;
};
export const isSubstringExists = (mainString = '', substring = '') => mainString?.includes(substring);
