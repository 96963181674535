import React from 'react';

const IcControlsOnOff = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.5 3H17.5V14.5C17.5 15.3284 16.8284 16 16 16C15.1716 16 14.5 15.3284 14.5 14.5V3ZM24.8449 11.3325C24.0705 9.86898 24.0192 7.9809 25.19 6.81006C27.54 9.16006 29 12.4101 29 16.0001C29 23.1801 23.18 29.0001 16 29.0001C8.82 29.0001 3 23.1801 3 16.0001C3 12.4101 4.46 9.16006 6.81 6.81006C7.98084 7.9809 7.92948 9.86898 7.15506 11.3325C6.41766 12.7261 6 14.3146 6 16.0001C6 21.5201 10.48 26.0001 16 26.0001C21.52 26.0001 26 21.5201 26 16.0001C26 14.3146 25.5823 12.7261 24.8449 11.3325Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcControlsOnOff;
