import { IcControlsDelete, IcControlsEdit } from '../../../../assets/icons';
import React from 'react';
import { EZLOGIC_BUTTON_EDIT, EZLOGIC_TITLE_DELETE } from '../../../../constants/language_tokens';

export const LABEL_FIELDS = {
    NAME: 'name',
    BACKGROUND_COLOR: 'backgroundColor',
    DELETED: 'deleted',
};

export const INITIAL_LABEL_BACKGROUND_COLOR = '#FCB20D';
export const INITIAL_VALUE_OF_CREATE_LABEL_FORM = {
    [LABEL_FIELDS.NAME]: '',
    [LABEL_FIELDS.BACKGROUND_COLOR]: INITIAL_LABEL_BACKGROUND_COLOR,
};

export const LABEL_MENU_VALUES = {
    EDIT: 'edit',
    DELETE: 'delete',
};

export const LABEL_MENU_ITEMS = [
    {
        labelTranslationKey: EZLOGIC_BUTTON_EDIT,
        value: LABEL_MENU_VALUES.EDIT,
        icon: <IcControlsEdit />,
    },
    {
        labelTranslationKey: EZLOGIC_TITLE_DELETE,
        value: LABEL_MENU_VALUES.DELETE,
        icon: <IcControlsDelete />,
    },
];
// The maximum number of labels opened by default in the table row
export const MAX_NUM_OF_LABELS_OPENED_BY_DEFAULT = 3;
// Range for labels trimming
export const SLICE_RANGE_FOR_LABELS_TRIMMING = [0, 2];
export const MAX_LENGTH_TEXT_FIELD = 256;
export const DARK_LABEL_FONT_COLOR = '#1c1e21';
export const WHITE_FONT_COLOR = '#fff';
export const THROTTLE_TIMEOUT_FOR_UPDATE_LABELS_REQUEST = 5000;
export const SELECT_STATUSES = {
    ALL_SELECT: 'ALL_SELECT',
    ALL_UNSELECT: 'ALL_UNSELECT',
    SOME_SELECT: 'SOME_SELECT',
};
export const SELECT_NEGATIVE_STATUSES = [SELECT_STATUSES.SOME_SELECT, SELECT_STATUSES.ALL_UNSELECT];
export const UPDATED_MESHBOT_LABELS_ACTIONS = {
    ADD: 'ADD',
};

export const DRAGGING_ELEMENT_DATA = 'DRAGGING_ELEMENT_DATA';
export const DROP_ZONE_DATA = 'DROP_ZONE_DATA';
