import React, { forwardRef } from 'react';
import Button from '@mui/material/Button';
import classname from 'classnames';

import styles from './styles.module.scss';

const DefaultButton = (props, ref) => {
    const classNames = classname(styles.defaultButton, {
        [props.className]: props.className,
    });

    return <Button ref={ref} color="inherit" {...props} className={classNames} />;
};

export default forwardRef(DefaultButton);
