import React from 'react';
import { SvgIcon } from 'lib/@mui';

export const DuplicateIcon = () => {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" color="primary">
            <path
                fillRule="evenodd"
                d="M18,14H8c-1.1,0-2-.9-2-2V2c0-1.1,.9-2,2-2h10c1.1,0,2,.9,2,2V12c0,1.1-.9,2-2,2Zm-6,6H2c-.53,0-1.04-.21-1.41-.59-.38-.38-.59-.88-.59-1.41V8c0-.53,.21-1.04,.59-1.41,.38-.38,.88-.59,1.41-.59h2V14c0,.32,.08,.63,.22,.91,.09,.18,.22,.35,.37,.5,.38,.38,.88,.59,1.41,.59H14v2c0,.53-.21,1.04-.59,1.41-.38,.38-.88,.59-1.41,.59Z"
            />
        </SvgIcon>
    );
};

export default DuplicateIcon;
