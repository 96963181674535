import React from 'react';

import LabelItem from '../LabelItem';
import useSelectedLabelForFiltering from '../../hooks/useSelectedLabelForFiltering';

import useMeshBotLabelsStateForListing from '../../hooks/useMeshBotLabelsStateForListing';

import styles from '../MeshBotLabelsPanel/styles.module.scss';

const LabelsList = () => {
    const labels = useMeshBotLabelsStateForListing();
    const { handlerClickLabel, selectedLabelUuid } = useSelectedLabelForFiltering();

    return (
        <div className={styles.listContainer}>
            {labels.map((label) => (
                <LabelItem
                    key={label.id}
                    labelUuid={label.id}
                    labelData={label}
                    handlerClickLabel={handlerClickLabel}
                    selectedLabelUuid={selectedLabelUuid}
                />
            ))}
        </div>
    );
};

export default LabelsList;
