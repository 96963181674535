import React from 'react';

export const LoginCategory = () => (
    <svg viewBox="0 0 32 32" fill="none">
        <path
            d="M23.0162 14.4782L17.5 8.96193C16.3283 7.79026 14.4291 7.79025 13.2574 8.96193L18.3344 14.039L3.00024 14.0383L3.00024 17.0379L18.3351 17.0379L13.2573 22.1157C14.429 23.2874 16.3282 23.2874 17.4999 22.1157L23.0162 16.5995L23.0162 14.4782Z"
            fill="#1C1E21"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26 16C26 10.5 21.5 6 16 6C12.3181 6 9.08431 8.01668 7.34872 11H3.99C5.94231 6.29456 10.5735 3 16 3C23.2 3 29 8.8 29 16C29 23.2 23.2 29 16 29C10.1967 29 5.30297 25.232 3.62268 20H6.83974C8.39044 23.5238 11.9201 26 16 26C21.5 26 26 21.5 26 16Z"
            fill="#1C1E21"
        />
    </svg>
);

export default LoginCategory;
