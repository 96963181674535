import { apiGetIntegrationPreview } from '../../EzloRule/EditForm/RuleSettings/components/PAAS/PaasAPI/paas-api-requests';

/**
 * Fetches the integration preview for a given integration UUID.
 *
 * @async
 * @param {string} integrationUuid - The UUID of the integration to fetch the preview for.
 * @returns {Promise<Object|undefined>} The integration preview object, or undefined if the status is 0.
 */
export const fetchIntegrationPreview = async (integrationUuid) => {
    const { status, integrationPreview } = await apiGetIntegrationPreview(integrationUuid);

    if (status === 0) {
        return;
    }

    return integrationPreview;
};
