import React from 'react';

const IcDeviceSensorCo = (props) => {
    return (
        <svg width="28" height="20" viewBox="0 0 28 20" fill="#1C1E21" {...props}>
            <path d="M18 0C13.8 0 10.2 2.6 8.7 6.2C8.2 6.1 7.6 6 7 6C3.1 6 0 9.1 0 13C0 16.9 3.1 20 7 20C8.9 20 15.7 20 18 20C23.5 20 28 15.5 28 10C28 4.5 23.5 0 18 0ZM5.6 14.4C6 14.8 6.5 15 7 15C7.5 15 8 14.8 8.4 14.4L9.8 15.8C9.1 16.6 8.1 17 7 17C5.9 17 4.9 16.6 4.2 15.8C3.4 15.1 3 14.1 3 13C3 11.9 3.4 10.9 4.2 10.2C4.9 9.4 5.9 9 7 9C8.1 9 9.1 9.4 9.8 10.2L8.4 11.6C8 11.2 7.5 11 7 11C6.5 11 6 11.2 5.6 11.6C5.2 12 5 12.5 5 13C5 13.5 5.2 14 5.6 14.4ZM15 17C12.8 17 11 15.2 11 13C11 10.8 12.8 9 15 9C17.2 9 19 10.8 19 13C19 15.2 17.2 17 15 17ZM17 13C17 14.1 16.1 15 15 15C13.9 15 13 14.1 13 13C13 11.9 13.9 11 15 11C16.1 11 17 11.9 17 13Z" />
        </svg>
    );
};

export default IcDeviceSensorCo;
