import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Select } from 'lib/@mui';
import classNames from 'classnames';

import './style.scss';
import styles from './styles.module.scss';

const ControllerGroupsSelect = (props) => {
    const onMenuItemClick = (e) => {
        const { onSelectGroup } = props;
        const groupStr = e.target.value;
        onSelectGroup(JSON.parse(groupStr));
    };

    const getSelectContent = () => {
        const { groups } = props;

        return Object.entries(groups)
            .reverse()
            .map(([key, group]) => {
                return (
                    <MenuItem key={key} value={JSON.stringify(group)}>
                        {group.name}
                    </MenuItem>
                );
            });
    };

    const { selectedGroup, groups, className } = props;
    const isGroupsAvailable = groups && Object.keys(groups).length > 0;

    return (
        isGroupsAvailable && (
            <Select
                labelId="group_selector_label"
                id="group_selector"
                variant="outlined"
                MenuProps={{
                    classes: { paper: styles.selectPaper },
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                }}
                value={JSON.stringify(selectedGroup)}
                onChange={onMenuItemClick}
                className={classNames('controller-groups-select', { [className]: className })}
            >
                {getSelectContent()}
            </Select>
        )
    );
};

ControllerGroupsSelect.propTypes = {
    groups: PropTypes.object.isRequired,
    selectedGroup: PropTypes.object,
    onSelectGroup: PropTypes.func.isRequired,
};

export default ControllerGroupsSelect;
