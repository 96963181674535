import React from 'react';

export const IcTabbarUsersLike = (props) => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M28.2857 22.3919L25.1135 24.6989L26.3151 28.4479L23.1428 26.1409L19.9706 28.4479L21.1722 24.6989L18 22.3919H21.9412L23.1428 18.6429L24.3445 22.3919H28.2857Z"
                fill="#1C1E21"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.0714 16.0714C17.8714 16.0714 19.2857 14.6571 19.2857 12.8571C19.2857 11.0571 17.8714 9.64285 16.0714 9.64285C14.2714 9.64285 12.8571 11.0571 12.8571 12.8571C12.8571 14.6571 14.2714 16.0714 16.0714 16.0714ZM18.9643 17.7429L19.2857 16.7143C20.3785 16.7143 21.4071 17.4857 21.6 18.6429L20.9058 21.1061H18C17.4431 21.1061 16.9495 21.4647 16.7773 21.9943C16.6051 22.5238 16.7934 23.1041 17.2438 23.4317L19.6641 25.1919L19.4966 25.7143H8.99998L10.5428 18.5786C10.7357 17.4857 11.7 16.7143 12.8571 16.7143L13.1785 17.7429C13.3714 18.2571 13.8214 18.6429 14.4 18.6429H17.7428C18.3214 18.6429 18.7714 18.2571 18.9643 17.7429ZM20.1108 23.927L18 22.3919H20.5434L20.1108 23.927Z"
                fill="#1C1E21"
            />
        </svg>
    );
};
