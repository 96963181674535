import React from 'react';

const IcActionsDoorlockError = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
            <path
                d="M9.70846 21C8.70846 21 7.90846 21.9 8.00846 23L8.50846 26C8.60846 26.6 9.10846 27 9.70846 27C10.3085 27 10.8085 26.6 10.9085 26L11.4085 23C11.5085 21.9 10.7085 21 9.70846 21Z"
                fill="#1C1E21"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18 1.00001C14.9674 0.172044 12.9941 0.000511302 10 8.56373e-07C7.00593 -0.000499509 5.25443 0.218136 2 1.00001V31C5.24784 31.8196 6.98983 32.0243 10 32C13.0102 31.9757 14.6757 31.7682 18 31V23.0645C15.0366 21.5918 13 18.5337 13 15C13 13.3848 13.4255 11.8691 14.1704 10.5586C14.0628 10.3991 14 10.2069 14 10C14 9.44772 14.4477 9 15 9C15.0903 9 15.1778 9.01198 15.261 9.03442C16.0266 8.1703 16.9557 7.45448 18 6.93552V1.00001ZM15 24C15 26.7614 12.7614 29 10 29C7.23858 29 5 26.7614 5 24C5 21.2386 7.23858 19 10 19C12.7614 19 15 21.2386 15 24ZM5 16C5.55228 16 6 15.5523 6 15C6 14.4477 5.55228 14 5 14C4.44772 14 4 14.4477 4 15C4 15.5523 4.44772 16 5 16ZM6 10C6 10.5523 5.55228 11 5 11C4.44772 11 4 10.5523 4 10C4 9.44772 4.44772 9 5 9C5.55228 9 6 9.44772 6 10ZM5 6C5.55228 6 6 5.55229 6 5C6 4.44772 5.55228 4 5 4C4.44772 4 4 4.44772 4 5C4 5.55229 4.44772 6 5 6ZM11 15C11 15.5523 10.5523 16 10 16C9.44772 16 9 15.5523 9 15C9 14.4477 9.44772 14 10 14C10.5523 14 11 14.4477 11 15ZM10 11C10.5523 11 11 10.5523 11 10C11 9.44772 10.5523 9 10 9C9.44772 9 9 9.44772 9 10C9 10.5523 9.44772 11 10 11ZM11 5C11 5.55229 10.5523 6 10 6C9.44772 6 9 5.55229 9 5C9 4.44772 9.44772 4 10 4C10.5523 4 11 4.44772 11 5ZM15 6C15.5523 6 16 5.55229 16 5C16 4.44772 15.5523 4 15 4C14.4477 4 14 4.44772 14 5C14 5.55229 14.4477 6 15 6ZM25.1819 9.69666L27.3033 11.818L24.1213 15L27.3033 18.1819L25.1819 20.3033L22 17.1213L18.818 20.3033L16.6967 18.1819L19.8786 15L16.6967 11.818L18.818 9.69666L22 12.8786L25.1819 9.69666Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcActionsDoorlockError;
