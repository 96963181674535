import React from 'react';

import LabelsList from '../LabelsList';
import { CreateLabelDialog } from '../LabelsManager';
import useDialogState from '../../../../../hooks/useDialogState/useDialogState';
import CreateLabelButton from '../LabelsDropdown/CreateLabelButton';

import styles from '../MeshBotLabelsPanel/styles.module.scss';

const LabelsPanelBody = () => {
    const { open, toggleOpenDialog, setOpen } = useDialogState();
    const closeDialog = () => setOpen(false);

    return (
        <div className={styles.bodyContainer}>
            <CreateLabelButton onClick={toggleOpenDialog} />
            <CreateLabelDialog open={open} closeDialog={closeDialog} />
            <LabelsList />
        </div>
    );
};

export default LabelsPanelBody;
