export const NAMES_OF_CATEGORIES = {
    DIMMABLE_LIGHT: 'dimmable_light',
    SWITCH: 'switch',
    HVAC: 'hvac',
    DOOR_LOCK: 'door_lock',
    CAMERA: 'camera',
    CAMERA_SUITE: 'camera_suite',
    DOORBELL: 'doorbell',
    WINDOW_COV: 'window_cov',
    GARAGE_DOOR: 'garage_door',
    SECURITY_SENSOR: 'security_sensor',
    LIGHT_SENSOR: 'light_sensor',
    TEMPERATURE: 'temperature',
    HUMIDITY: 'humidity',
    VOLTAGE_SENSOR: 'voltage_sensor',
    LEVEL_SENSOR: 'level_sensor',
    UV_SENSOR: 'uv_sensor',
    STATE_SENSOR: 'state_sensor',
    FLOW_METER: 'flow_meter',
    POWER_METER: 'power_meter',
    SCENE_CONTROLLER: 'scene_controller',
    KEYPAD: 'keypad',
    IR_TX: 'ir_tx',
    REMOTE_CONTROL: 'remote_control',
    ALARM_PANEL: 'alarm_panel',
    ALARM_PARTITION: 'alarm_partition',
    GENERIC_SENSOR: 'generic_sensor',
    GENERIC_IO: 'generic_io',
    AV: 'av',
    APPLIANCE: 'appliance',
    SIREN: 'siren',
    INTERFACE: 'interface',
    ZWAVE_INT: 'zwave_int',
    INSTEON_INT: 'insteon_int',
    PHILIPS_CONTROLLER: 'philips_controller',
    SERIAL_PORT: 'serial_port',
    MOUSE_TRAP: 'mouse_trap',
    WEATHER: 'weather',
    GOOGLE_HOME: 'googleHome',
    AMAZON_ALEXA: 'amazonAlexa',
};

export const FULL_LIST_OF_CATEGORIES = [
    {
        categoryName: NAMES_OF_CATEGORIES.DIMMABLE_LIGHT,
        priorityIndex: 1,
    },
    {
        categoryName: NAMES_OF_CATEGORIES.SWITCH,
        priorityIndex: 2,
    },
    {
        categoryName: NAMES_OF_CATEGORIES.HVAC,
        priorityIndex: 3,
    },
    {
        categoryName: NAMES_OF_CATEGORIES.DOOR_LOCK,
        priorityIndex: 4,
    },
    {
        categoryName: NAMES_OF_CATEGORIES.CAMERA,
        priorityIndex: 5,
    },
    {
        categoryName: NAMES_OF_CATEGORIES.CAMERA_SUITE,
        priorityIndex: 6,
    },
    {
        categoryName: NAMES_OF_CATEGORIES.DOORBELL,
        priorityIndex: 7,
    },
    {
        categoryName: NAMES_OF_CATEGORIES.WINDOW_COV,
        priorityIndex: 8,
    },
    {
        categoryName: NAMES_OF_CATEGORIES.GARAGE_DOOR,
        priorityIndex: 9,
    },
    {
        categoryName: NAMES_OF_CATEGORIES.SECURITY_SENSOR,
        priorityIndex: 10,
    },
    {
        categoryName: NAMES_OF_CATEGORIES.LIGHT_SENSOR,
        priorityIndex: 11,
    },
    {
        categoryName: NAMES_OF_CATEGORIES.TEMPERATURE,
        priorityIndex: 12,
    },
    {
        categoryName: NAMES_OF_CATEGORIES.HUMIDITY,
        priorityIndex: 13,
    },
    {
        categoryName: NAMES_OF_CATEGORIES.VOLTAGE_SENSOR,
        priorityIndex: 14,
    },
    {
        categoryName: NAMES_OF_CATEGORIES.LEVEL_SENSOR,
        priorityIndex: 15,
    },
    {
        categoryName: NAMES_OF_CATEGORIES.UV_SENSOR,
        priorityIndex: 16,
    },
    {
        categoryName: NAMES_OF_CATEGORIES.STATE_SENSOR,
        priorityIndex: 17,
    },
    {
        categoryName: NAMES_OF_CATEGORIES.FLOW_METER,
        priorityIndex: 18,
    },
    {
        categoryName: NAMES_OF_CATEGORIES.POWER_METER,
        priorityIndex: 19,
    },
    {
        categoryName: NAMES_OF_CATEGORIES.SCENE_CONTROLLER,
        priorityIndex: 20,
    },
    {
        categoryName: NAMES_OF_CATEGORIES.KEYPAD,
        priorityIndex: 21,
    },
    {
        categoryName: NAMES_OF_CATEGORIES.IR_TX,
        priorityIndex: 22,
    },
    {
        categoryName: NAMES_OF_CATEGORIES.REMOTE_CONTROL,
        priorityIndex: 23,
    },
    {
        categoryName: NAMES_OF_CATEGORIES.ALARM_PANEL,
        priorityIndex: 24,
    },
    {
        categoryName: NAMES_OF_CATEGORIES.ALARM_PARTITION,
        priorityIndex: 25,
    },
    {
        categoryName: NAMES_OF_CATEGORIES.GENERIC_SENSOR,
        priorityIndex: 26,
    },
    {
        categoryName: NAMES_OF_CATEGORIES.GENERIC_IO,
        priorityIndex: 27,
    },
    {
        categoryName: NAMES_OF_CATEGORIES.AV,
        priorityIndex: 28,
    },
    {
        categoryName: NAMES_OF_CATEGORIES.APPLIANCE,
        priorityIndex: 29,
    },
    {
        categoryName: NAMES_OF_CATEGORIES.SIREN,
        priorityIndex: 30,
    },
    {
        categoryName: NAMES_OF_CATEGORIES.INTERFACE,
        priorityIndex: 31,
    },
    {
        categoryName: NAMES_OF_CATEGORIES.ZWAVE_INT,
        priorityIndex: 32,
    },
    {
        categoryName: NAMES_OF_CATEGORIES.INSTEON_INT,
        priorityIndex: 33,
    },
    {
        categoryName: NAMES_OF_CATEGORIES.PHILIPS_CONTROLLER,
        priorityIndex: 34,
    },
    {
        categoryName: NAMES_OF_CATEGORIES.SERIAL_PORT,
        priorityIndex: 35,
    },
    {
        categoryName: NAMES_OF_CATEGORIES.MOUSE_TRAP,
        priorityIndex: 36,
    },
    {
        categoryName: NAMES_OF_CATEGORIES.WEATHER,
        priorityIndex: 37,
    },
    {
        categoryName: NAMES_OF_CATEGORIES.GOOGLE_HOME,
        priorityIndex: 38,
    },
    {
        categoryName: NAMES_OF_CATEGORIES.AMAZON_ALEXA,
        priorityIndex: 39,
    },
];

export const BASIC_LIST_OF_CATEGORIES = {
    dimmableLight: NAMES_OF_CATEGORIES.DIMMABLE_LIGHT,
    switch: NAMES_OF_CATEGORIES.SWITCH,
    hvac: NAMES_OF_CATEGORIES.HVAC,
    doorLock: NAMES_OF_CATEGORIES.DOOR_LOCK,
    camera: NAMES_OF_CATEGORIES.CAMERA,
    cameraSuite: NAMES_OF_CATEGORIES.CAMERA_SUITE,
    doorbell: NAMES_OF_CATEGORIES.DOORBELL,
    windowCov: NAMES_OF_CATEGORIES.WINDOW_COV,
    garageDoor: NAMES_OF_CATEGORIES.GARAGE_DOOR,
    securitySensor: NAMES_OF_CATEGORIES.SECURITY_SENSOR,
    lightSensor: NAMES_OF_CATEGORIES.LIGHT_SENSOR,
    temperature: NAMES_OF_CATEGORIES.TEMPERATURE,
    humidity: NAMES_OF_CATEGORIES.HUMIDITY,
    voltageSensor: NAMES_OF_CATEGORIES.VOLTAGE_SENSOR,
    levelSensor: NAMES_OF_CATEGORIES.LEVEL_SENSOR,
    uvSensor: NAMES_OF_CATEGORIES.UV_SENSOR,
    stateSensor: NAMES_OF_CATEGORIES.STATE_SENSOR,
    flowMeter: NAMES_OF_CATEGORIES.FLOW_METER,
    powerMeter: NAMES_OF_CATEGORIES.POWER_METER,
    sceneController: NAMES_OF_CATEGORIES.SCENE_CONTROLLER,
};
