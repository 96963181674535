import Bugsnag from '@bugsnag/js';
import { toast } from 'react-toastify';

import { TOAST_TYPE } from '../../components/Toast';
import { TOAST_MESSAGE_ON_ERROR } from '../../constants/toasts';
import { ERROR_CODE_METHOD_NOT_FOUND, RPC_METHOD_NOTFOUND } from '../../constants/MeshbotConstant';
import { t } from '../../helpers/language';
import { JAVASCRIPT_TYPES } from '../../constants/common/js_types';
import { isBugsnagInitialized } from './initialize';

export const bugsnagNotifyWrapper = (error, data = {}) => {
    bugsnagNotify(error, data);

    if ((error && error?.code === ERROR_CODE_METHOD_NOT_FOUND) || error?.data === RPC_METHOD_NOTFOUND) {
        return;
    }

    toast((error?.message || error) ?? t(TOAST_MESSAGE_ON_ERROR), { type: TOAST_TYPE.ERROR });
};

export const bugsnagNotify = (error, data = {}) => {
    if (isBugsnagInitialized) {
        return Bugsnag.notify(error, (event) => {
            event.addMetadata('Additional info:', data);
        });
    }
};

/**
 * Returns bugsnag "apiKey" validation result
 * @param {string} apiKey - "apiKey" config value
 * @returns {boolean} validation result
 * */
export const validateBugsnagApiKey = (apiKey) => {
    if (apiKey && typeof apiKey === JAVASCRIPT_TYPES.STRING) {
        return true;
    }

    return false;
};
