/**
 * Returns result of checking is value an array with items or not
 * @param {any} value - Value that we need to check
 * @returns {boolean} return true if value is array with items
 * */

export const isNonEmptyArray = (value) => {
    return Array.isArray(value) && value.length > 0;
};

/**
 * Returns result of checking is value a not empty string
 * @param {any} value - Value that we need to check
 * @returns {boolean} return true if value is array with items
 * */

export const isNonEmptyString = (value) => {
    return typeof value === 'string' && value.length > 0;
};

/**
 * Returns result of checking is all values in object are not equal to null or undefined
 * @param {object} obj - Object with values that we need to check
 * @returns {boolean} return true if all object values are not equal to null or undefined
 * */

export const isAllObjectValuesNonEmpty = (obj) => {
    return Object.values(obj).every((value) => value !== undefined && value !== null);
};

/**
 * Returns key name of an object that has passed param value in it's array property
 * @param {(string|number)} value - Object with values that we need to check
 *  @param {object} obj - Object with arrays as properties
 * @returns {string} return key name or empty string if there is no suitable key
 * */

export const getKeyFromObjectWithArrays = (obj, value) => {
    const entriesArray = Object.entries(obj);
    let result = '';

    for (const [key, arrayToSeekIn] of entriesArray) {
        if (arrayToSeekIn.includes(value)) {
            result = key;
            break;
        }
    }

    return result;
};
