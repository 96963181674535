export default {
    GET_USERS_LIST: {
        pending: 'GET_USERS_LIST/pending',
        success: 'GET_USERS_LIST/success',
        rejected: 'GET_USERS_LIST/rejected',
    },
    GET_UUID_LIST: {
        pending: 'GET_UUID_LIST/pending',
        success: 'GET_UUID_LIST/success',
        rejected: 'GET_UUID_LIST/rejected',
    },
    GET_NOTIFICATION_TEMPLATES: {
        pending: 'GET_NOTIFICATION_TEMPLATES/pending',
        success: 'GET_NOTIFICATION_TEMPLATES/success',
        reject: 'GET_NOTIFICATION_TEMPLATES/reject',
    },
    SET_REQUEST_STATUS: {
        pending: 'CREATE_NOTIFICATION_TEMPLATE/pending',
        success: 'CREATE_NOTIFICATION_TEMPLATE/success',
        rejected: 'CREATE_NOTIFICATION_TEMPLATE/rejected',
    },
    GET_CHANNELS_LIST: {
        pending: 'GET_CHANNELS_LIST/pending',
        success: 'GET_CHANNELS_LIST/success',
        rejected: 'GET_CHANNELS_LIST/rejected',
    },
    GET_DASHBOARD_LIST: {
        pending: 'GET_DASHBOARD_LIST/pending',
        success: 'GET_DASHBOARD_LIST/success',
        rejected: 'GET_DASHBOARD_LIST/rejected',
    },
    UPDATE_NOTIFICATION_TYPE: {
        pending: 'UPDATE_NOTIFICATION_TYPE/pending',
        success: 'UPDATE_NOTIFICATION_TYPE/success',
        rejected: 'UPDATE_NOTIFICATION_TYPE/rejected',
    },
    SET_NOTIFICATION_TEMPLATE: {
        pending: 'SET_NOTIFICATION_TEMPLATE/pending',
        success: 'SET_NOTIFICATION_TEMPLATE/success',
        rejected: 'SET_NOTIFICATION_TEMPLATE/rejected',
    },
    SET_NOTIFICATION_CATEGORY: {
        pending: 'SET_NOTIFICATION_CATEGORY/pending',
        success: 'SET_NOTIFICATION_CATEGORY/success',
        rejected: 'SET_NOTIFICATION_CATEGORY/rejected',
    },
    SET_NOTIFICATION_CATEGORY_SETTINGS: {
        pending: 'SET_NOTIFICATION_CATEGORY_SETTINGS/pending',
        success: 'SET_NOTIFICATION_CATEGORY_SETTINGS/success',
        rejected: 'SET_NOTIFICATION_CATEGORY_SETTINGS/rejected',
    },
    GET_NOTIFICATION_CATEGORIES: {
        pending: 'GET_NOTIFICATION_CATEGORIES/pending',
        success: 'GET_NOTIFICATION_CATEGORIES/success',
        rejected: 'GET_NOTIFICATION_CATEGORIES/rejected',
    },
    GET_NOTIFICATION_CATEGORIES_SETTINGS: {
        pending: 'GET_NOTIFICATION_CATEGORIES_SETTINGS/pending',
        success: 'GET_NOTIFICATION_CATEGORIES_SETTINGS/success',
        rejected: 'GET_NOTIFICATION_CATEGORIES_SETTINGS/rejected',
    },
    SET_SELECTED_NOTIFICATION_CAPABILITY: {
        pending: 'SET_SELECTED_NOTIFICATION_CAPABILITY/pending',
        success: 'SET_SELECTED_NOTIFICATION_CAPABILITY/success',
        rejected: 'SET_SELECTED_NOTIFICATION_CAPABILITY/rejected',
    },
    DELETE_NOTIFICATION_TEMPLATE: {
        pending: 'DELETE_NOTIFICATION_TEMPLATE/pending',
        success: 'DELETE_NOTIFICATION_TEMPLATE/success',
        rejected: 'DELETE_NOTIFICATION_TEMPLATE/rejected',
    },
};
