import { createSlice } from '@reduxjs/toolkit';

import { LOGOUT } from '../constants/ActionTypes';
import { DEFAULT_TABLE_PAGE_INDEX, MESHBOT_TABLE_DEFAULT_PAGE_SIZE } from 'containers/Ezlo/EzloMeshbots/constants';
export const MESHBOTS_FILTERS_REDUCER = 'MESHBOTS_FILTERS_REDUCER';

const initialState = {
    columnFilterState: [],
    globalFilterState: '',
    selectedMeshBotLabelsState: '',
    paginationState: {
        pageIndex: DEFAULT_TABLE_PAGE_INDEX,
        pageSize: MESHBOT_TABLE_DEFAULT_PAGE_SIZE,
    },
};

const meshBotsFilters = createSlice({
    name: MESHBOTS_FILTERS_REDUCER,
    initialState,
    reducers: {
        setMeshBotsFiltersState: (state, action) => {
            state.columnFilterState = action.payload?.columnFilterData;
            state.globalFilterState = action.payload?.globalFilterData;
            state.selectedMeshBotLabelsState = action.payload?.selectedLabelData;
            state.paginationState = action.payload?.paginationStateData;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(LOGOUT, () => initialState);
    },
});

export const { setMeshBotsFiltersState } = meshBotsFilters.actions;
export default meshBotsFilters;
export const meshbotsFiltersReducer = meshBotsFilters.reducer;
