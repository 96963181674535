import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IconButton } from 'lib/@mui';
import { ImportantDevicesOutlined } from 'lib/@mui/Icons';

import { getControllersPluginInstalled, getDevicesWithVersion, setListDevicesInstalled } from '../../utils';

import { prepareDevices } from '../../../EzloDevices/utils';
import { prepareGateways } from '../../../EzloDevice/utils';
import styles from '../../PluginStyled.module.scss';

const CountInstalledDevices = ({ params, onHandlerShowDevicesModal, devices, pluginData }) => {
    const [countInstalledDevices, setCountInstalledDevices] = useState({});
    const { privatePlugins, listInstalledPlugins } = useSelector((state) => state.plugins);
    const { serial, data } = useSelector((state) => state.ezlo);
    const state = useSelector((state) => state);

    useEffect(() => {
        if (listInstalledPlugins && privatePlugins && devices) {
            const result = getControllersPluginInstalled(privatePlugins, listInstalledPlugins);
            setCountInstalledDevices(
                setListDevicesInstalled(
                    result,
                    getDevicesWithVersion(prepareDevices(data), listInstalledPlugins, prepareGateways(state)),
                ),
            );
        }
    }, [listInstalledPlugins, devices, pluginData, serial]);

    return (
        <div className={styles.pluginsListCount}>
            <span className={styles.pluginsListSumPlugins}>
                {countInstalledDevices[`${params?.row?.original?.nameId + '_' + params?.row?.original?.version}`]}
            </span>
            <IconButton onClick={() => onHandlerShowDevicesModal(params)}>
                <ImportantDevicesOutlined />
            </IconButton>
        </div>
    );
};

export default CountInstalledDevices;
