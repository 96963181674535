import React from 'react';

const IcDeviceSensorGlasBreak = (props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="#1C1E21" {...props}>
            <path d="M21 0H3C1.3 0 0 1.3 0 3V21C0 22.7 1.3 24 3 24H12.9L14.3 21L14.5 20.5L14.8 19.9L16.3 16.5C16.5 16 16 15.6 15.6 15.9L10.8 18.9C10.4 19.2 9.8 18.7 10.1 18.2L15.6 9.3C15.9 8.8 15.3 8.3 14.9 8.6L6.7 14.8C6.3 15.1 5.7 14.6 6 14.2L8.3 9.6C8.5 9 8 8.6 7.6 8.8L3 11.4V3H21V21H17.3L15.9 24H21C22.7 24 24 22.7 24 21V3C24 1.3 22.7 0 21 0Z" />
        </svg>
    );
};

export default IcDeviceSensorGlasBreak;
