import React from 'react';

const IcDeviceSensorNoMotion = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
            <path
                d="M17.09 19.3175L19.6019 11.1421L16.4621 7.42175H11.2844H9.09242C8.5237 7.42175 8.02607 7.78905 7.86019 8.33408L7.48104 9.55445L7.13744 10.68L6.97156 11.2369L6.91232 11.4265L6.30806 13.3815L6 14.3886H8.20379C8.77251 14.3886 9.27014 14.0213 9.43602 13.4763L10.064 11.4265L10.2891 10.68L10.3483 10.5023H13.5L12.8246 12.6943L11.7583 16.1777C11.5569 16.8412 11.6991 17.5758 12.1493 18.109L16.9716 23.8317V28.9146C16.9716 29.7677 17.6588 30.4549 18.5118 30.4549H20.1232V22.9075L17.09 19.3175Z"
                fill="#1C1E21"
            />
            <path
                d="M17.5403 6.18957C18.9733 6.18957 20.1351 5.02785 20.1351 3.59479C20.1351 2.16173 18.9733 1 17.5403 1C16.1072 1 14.9455 2.16173 14.9455 3.59479C14.9455 5.02785 16.1072 6.18957 17.5403 6.18957Z"
                fill="#1C1E21"
            />
            <path
                d="M21.2251 13.0853L20.1825 16.4976L22.564 19.3294C23.0616 19.9218 23.9147 20.0522 24.5664 19.6375L26 18.737L21.2251 13.0853Z"
                fill="#1C1E21"
            />
            <path
                d="M10.7512 19.5782L9.59004 23.2394L7.96681 28.5119C7.67061 29.4716 8.39336 30.4432 9.40046 30.4432H10.5261L11.1185 28.5L12.7535 23.2868C12.9431 22.6707 12.8128 22.0072 12.3981 21.5214L10.7512 19.5782Z"
                fill="#1C1E21"
            />
            <path d="M30 30L28 32L0 4L2 2L30 30Z" fill="#1C1E21" />
            <path d="M32 28L30 30L2 2L4 0L32 28Z" fill="white" />
        </svg>
    );
};

export default IcDeviceSensorNoMotion;
