export default {
    SET_DEVICE_GROUPS_LIST: {
        success: 'SET_DEVICE_GROUPS_LIST/success',
        rejected: 'SET_DEVICE_GROUPS_LIST/rejected',
    },
    REMOVE_DEVICE_GROUP: {
        success: 'REMOVE_DEVICE_GROUP/success',
        rejected: 'REMOVE_DEVICE_GROUP/rejected',
    },
    CREATE_DEVICE_GROUP: {
        success: 'CREATE_DEVICE_GROUP/success',
        rejected: 'CREATE_DEVICE_GROUP/rejected',
    },
    GET_DEVICE_GROUP: {
        success: 'GET_DEVICE_GROUP/success',
        rejected: 'GET_DEVICE_GROUP/rejected',
    },
    UPDATE_DEVICE_GROUP: {
        success: 'UPDATE_DEVICE_GROUP/success',
        rejected: 'UPDATE_DEVICE_GROUP/rejected',
    },
    BUILD_DEVICE_GROUP_OBJECT: 'BUILD_DEVICE_GROUP_OBJECT',
    SET_DEVICE_GROUP_NAME: 'SET_DEVICE_GROUP_NAME',
    SET_DEVICE_GROUP_CATEGORY: 'SET_DEVICE_GROUP_CATEGORY',
    SET_DEVICE_GROUP_SUBCATEGORY: 'SET_DEVICE_GROUP_SUBCATEGORY',
    SET_DEVICE_GROUP_DEVICE_ITEM: 'SET_DEVICE_GROUP_DEVICE_ITEM',
    SET_DEVICE_GROUP_EXCEPT_DEVICE: 'SET_DEVICE_GROUP_EXCEPT_DEVICE',
    REMOVE_DEVICE_GROUP_CATEGORY: 'REMOVE_DEVICE_GROUP_CATEGORY',
    REMOVE_DEVICE_GROUP_SUBCATEGORY: 'REMOVE_DEVICE_GROUP_SUBCATEGORY',
    REMOVE_DEVICE_GROUP_DEVICE_ITEM: 'REMOVE_DEVICE_GROUP_DEVICE_ITEM',
    REMOVE_DEVICE_GROUP_EXCEPTED_DEVICE_ITEM: 'REMOVE_DEVICE_GROUP_EXCEPTED_DEVICE_ITEM',
    SET_DEVICE_GROUP_TREE_STATE: 'SET_DEVICE_GROUP_TREE_STATE',
    UPDATE_DEVICE_GROUP_TREE_STATE: 'UPDATE_DEVICE_GROUP_TREE_STATE',
    UPDATE_DEVICE_GROUP_TREE_STATE_BY_CATEGORY: 'UPDATE_DEVICE_GROUP_TREE_STATE_BY_CATEGORY',
    UPDATE_DEVICE_GROUP_TREE_STATE_BY_SUBCATEGORY: 'UPDATE_DEVICE_GROUP_TREE_STATE_BY_SUBCATEGORY',
    UPDATE_DEVICE_GROUP_TREE_STATE_BY_DEVICE: 'UPDATE_DEVICE_GROUP_TREE_STATE_BY_DEVICE',
    SET_INITIAL_DEVICE_GROUP_TREE_STATE: 'SET_INITIAL_DEVICE_GROUP_TREE_STATE',
    SET_DEVICE_GROUP_ID: 'SET_DEVICE_GROUP_ID',
    SET_DEVICE_GROUP_SERIAL: 'SET_DEVICE_GROUP_SERIAL',
};
