import React from 'react';

const IcSecurityPanel = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
            <path
                d="M28 4H4C2.3 4 1 5.3 1 7V25C1 26.7 2.3 28 4 28H28C29.7 28 31 26.7 31 25V7C31 5.3 29.7 4 28 4ZM4 7H28V25H4V7Z"
                fill="#1C1E21"
            />
            <rect x="7" y="10" width="10" height="3" fill="#1C1E21" />
            <rect x="25" y="22" width="5" height="3" transform="rotate(180 25 22)" fill="#1C1E21" />
            <rect x="7" y="16" width="10" height="6" fill="#1C1E21" />
            <rect x="25" y="16" width="5" height="6" transform="rotate(180 25 16)" fill="#1C1E21" />
        </svg>
    );
};

export default IcSecurityPanel;
