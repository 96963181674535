import React from 'react';

export const IcVisualsMail = (props) => {
    return (
        <svg width="104" height="110" viewBox="0 0 104 110" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M104 35.9957L67.0422 6.26884C58.2585 -0.796319 45.7415 -0.796319 36.9578 6.26884L0 35.9957H104Z"
                fill="black"
            />
            <path
                d="M91.9998 109.996H12C5.37258 109.996 0 104.623 0 97.9957V35.9957H104V97.9957C104 104.623 98.6272 109.996 91.9998 109.996Z"
                fill="black"
            />
            <path
                d="M95.9998 87.0234H8V47.9957C8 41.3683 13.3726 35.9957 20 35.9957H83.9998C90.6272 35.9957 95.9998 41.3683 95.9998 47.9957V87.0234Z"
                fill="#50C1D6"
            />
            <path
                d="M0 35.9957V97.9957C0 104.623 5.3728 109.996 12 109.996H12.0002L52 77.8219L0 35.9957Z"
                fill="#3E46AE"
            />
            <path d="M12.0002 109.996H92L52 77.8219L12.0002 109.996Z" fill="#3E46AE" />
            <path
                d="M52 77.8219L92 109.996C98.6274 109.996 104 104.623 104 97.9957V35.9957L52 77.8219Z"
                fill="#1F2581"
            />
        </svg>
    );
};

export default IcVisualsMail;
