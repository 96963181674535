import { ItarianApi } from './network/ItarianApi';
import { buildUserAuthInfoByItarianSsoData, extractItarianSsoData, getItarianSsoType } from './utils';

/**
 * @class ItarianService
 * Contains all necessary methods to manage Itarian SSO
 * */
class ItarianService {
    #api;

    constructor() {
        this.#api = new ItarianApi();
    }

    /**
     * Returns auth data by Itarian SSO token.
     * @param {string} token - Itarian SSO token
     * @returns {Promise<Object>} Auth data info
     *
     * @example
     * Input:
     * token = d9aaa2dc-483b-4d29-bdf5-3df83fda5afe
     *
     * Output:
     * {
     *   "token": "eyJhbGciOiJIUzI1NiIs...gGVdJ3MxXRjGD6Ro",
     *   "expires": 1669126012,
     *   "legacy_token": {
     *     "auth": "eyJFeHBpcmVzIjoxNj...zTmV3QWNjb3VudCI6MX0=",
     *     "sig": "j0T1f0qBppbJQ+MJEo...uCL/EQkWtai0becKs8v6NYDPTuiA=="
     *   }
     * }
     *
     * */
    async getItarianSsoDataByToken(token) {
        const response = await this.#api.fetchItarianSsoDataByToken(token);

        return extractItarianSsoData(response);
    }

    /**
     * Returns User Auth data based on Itarian SSO token.
     * @see {@link https://confluence.mios.com/display/EPD/SSO+Integration+-+Auth SSO Integration Auth}
     * @param {string} token - SSO token
     * @returns {Promise<Object>} User Auth info
     * */
    async getUserAuthDataByToken(token) {
        const ssoData = await this.getItarianSsoDataByToken(token);

        return buildUserAuthInfoByItarianSsoData(ssoData);
    }

    /**
     * Returns SSO type
     * */
    getSsoType() {
        return getItarianSsoType();
    }
}

export { ItarianService };
