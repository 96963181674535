/**
 * Returns true when successfully store payload in localstorage otherwise return false.
 * @param {string} key - key is using for store data on specific place.
 * @param {*} payload - getting data as you need to store in loacal storage.
 *
 **/
export const setLocalStorageProcess = (key, payload) => {
    if (key) {
        localStorage.setItem(key, payload);

        return true;
    }

    return false;
};

/**
 * Returns response when successfully stored payload collect with given key from localstorage otherwise return false.
 * @param {string} key - key is using for store data on specific place.
 **/
export const getLocalStorageProcess = (key) => {
    if (key) {
        const response = localStorage.getItem(key);

        return response;
    }

    return false;
};

/**
 * Returns response when successfully removed payload with given key from localstorage otherwise return false.
 * @param {string} key - key is using to remove data from localstorage.
 **/
export const removeLocalStorageProcess = (key) => {
    return localStorage.removeItem(key);
};
