import * as lt from '../../../constants/language_tokens';

export const TABLE_DENSITY_OPTIONS = { SPACIOUS: 'spacious', COMPACT: 'compact', COMFORTABLE: 'comfortable' };
export const SELECTION_COLUMN_ID = 'mrt-row-select';
export const TABLE_LOCALIZATION_TOKENS = {
    actions: lt.EZLOGIC_TABLE_ACTIONS,
    and: lt.EZLOGIC_TABLE_AND,
    cancel: lt.EZLOGIC_TABLE_CANCEL,
    changeFilterMode: lt.EZLOGIC_TABLE_CHANGE_FILTER_MODE,
    changeSearchMode: lt.EZLOGIC_TABLE_CHANGE_SEARCH_MODE,
    clearFilter: lt.EZLOGIC_TABLE_CLEAR_FILTER,
    clearSearch: lt.EZLOGIC_TABLE_CLEAR_SEARCH,
    clearSort: lt.EZLOGIC_TABLE_CLEAR_SORT,
    clickToCopy: lt.EZLOGIC_TABLE_CLICK_TO_COPY,
    collapse: lt.EZLOGIC_TABLE_COLLAPSE,
    collapseAll: lt.EZLOGIC_TABLE_COLLAPSE_ALL,
    columnActions: lt.EZLOGIC_TABLE_COLUMN_ACTIONS,
    copiedToClipboard: lt.EZLOGIC_TABLE_COPIED_TO_CLIPBOARD,
    dropToGroupBy: lt.EZLOGIC_TABLE_DROP_TO_GROUP_BY,
    edit: lt.EZLOGIC_TABLE_EDIT,
    expand: lt.EZLOGIC_TABLE_EXPAND,
    expandAll: lt.EZLOGIC_TABLE_EXPAND_ALL,
    filterArrIncludes: lt.EZLOGIC_TABLE_INCLUDES,
    filterArrIncludesAll: lt.EZLOGIC_TABLE_INCLUDES_ALL,
    filterArrIncludesSome: lt.EZLOGIC_TABLE_INCLUDES,
    filterBetween: lt.EZLOGIC_TABLE_BETWEEN,
    filterBetweenInclusive: lt.EZLOGIC_TABLE_BETWEEN_INCLUSIVE,
    filterByColumn: lt.EZLOGIC_TABLE_FILTER_BY,
    filterContains: lt.EZLOGIC_TABLE_CONTAINS,
    filterEmpty: lt.EZLOGIC_TABLE_EMPTY,
    filterEndsWith: lt.EZLOGIC_TABLE_ENDS_WITH,
    filterEquals: lt.EZLOGIC_TABLE_EQUALS,
    filterEqualsString: lt.EZLOGIC_TABLE_EQUALS,
    filterFuzzy: lt.EZLOGIC_TABLE_FUZZY,
    filterGreaterThan: lt.EZLOGIC_TABLE_GREATER_THAN,
    filterGreaterThanOrEqualTo: lt.EZLOGIC_TABLE_GREATER_THAN_OR_EQUAL_TO,
    filterInNumberRange: lt.EZLOGIC_TABLE_BETWEEN,
    filterIncludesString: lt.EZLOGIC_TABLE_CONTAINS,
    filterIncludesStringSensitive: lt.EZLOGIC_TABLE_CONTAINS,
    filterLessThan: lt.EZLOGIC_TABLE_LESS_THAN,
    filterLessThanOrEqualTo: lt.EZLOGIC_TABLE_LESS_THAN_OR_EQUAL_TO,
    filterMode: lt.EZLOGIC_TABLE_FILTER_MODE,
    filterNotEmpty: lt.EZLOGIC_TABLE_NOT_EMPTY,
    filterNotEquals: lt.EZLOGIC_TABLE_NOT_EQUALS,
    filterStartsWith: lt.EZLOGIC_TABLE_STARTS_WITH,
    filterWeakEquals: lt.EZLOGIC_TABLE_EQUALS,
    filteringByColumn: lt.EZLOGIC_TABLE_FILTERING_BY,
    goToFirstPage: lt.EZLOGIC_TABLE_GO_TO_FIRST_PAGE,
    goToLastPage: lt.EZLOGIC_TABLE_GO_TO_LAST_PAGE,
    goToNextPage: lt.EZLOGIC_TABLE_GO_TO_NEXT_PAGE,
    goToPreviousPage: lt.EZLOGIC_TABLE_GO_TO_PREVIOUS_PAGE,
    grab: lt.EZLOGIC_TABLE_GRAB,
    groupByColumn: lt.EZLOGIC_TABLE_GROUP_BY_COLUMN,
    groupedBy: lt.EZLOGIC_TABLE_GROUPED_BY,
    hideAll: lt.EZLOGIC_TABLE_HIDE_ALL,
    hideColumn: lt.EZLOGIC_TABLE_HIDE_COLUMN,
    max: lt.EZLOGIC_TABLE_MAX,
    min: lt.EZLOGIC_TABLE_MIN,
    move: lt.EZLOGIC_TABLE_MOVE,
    noRecordsToDisplay: lt.EZLOGIC_TABLE_NO_RECORDS_TO_DISPLAY,
    noResultsFound: lt.EZLOGIC_TABLE_NO_RESULTS_FOUND,
    of: lt.EZLOGIC_TABLE_OF,
    or: lt.EZLOGIC_TABLE_OR,
    pinToLeft: lt.EZLOGIC_TABLE_PIN_TO_LEFT,
    pinToRight: lt.EZLOGIC_TABLE_PIN_TO_RIGHT,
    resetColumnSize: lt.EZLOGIC_TABLE_RESET_COLUMN_SIZE,
    resetOrder: lt.EZLOGIC_TABLE_RESET_ORDER,
    rowActions: lt.EZLOGIC_TABLE_ROW_ACTIONS,
    rowNumber: lt.EZLOGIC_TABLE_ROW_NUMBER,
    rowNumbers: lt.EZLOGIC_TABLE_ROW_NUMBERS,
    rowsPerPage: lt.EZLOGIC_TABLE_ROWS_PER_PAGE,
    save: lt.EZLOGIC_TABLE_SAVE,
    search: lt.EZLOGIC_TABLE_SEARCH,
    selectedCountOfRowCountRowsSelected: lt.EZLOGIC_TABLE_SELECTED_COUNT_OF_ROW_COUNT_ROWS_SELECTED,
    select: lt.EZLOGIC_TABLE_SELECT,
    showAll: lt.EZLOGIC_TABLE_SHOW_ALL,
    showAllColumns: lt.EZLOGIC_TABLE_SHOW_ALL_COLUMNS,
    showHideColumns: lt.EZLOGIC_TABLE_SHOW_HIDE_COLUMNS,
    showHideFilters: lt.EZLOGIC_TABLE_SHOW_HIDE_FILTERS,
    showHideSearch: lt.EZLOGIC_TABLE_SHOW_HIDE_SEARCH,
    sortByColumnAsc: lt.EZLOGIC_TABLE_SORT_BY_COLUMN_ASC,
    sortByColumnDesc: lt.EZLOGIC_TABLE_SORT_BY_COLUMN_DESC,
    sortedByColumnAsc: lt.EZLOGIC_TABLE_SORTED_BY_COLUMN_ASC,
    sortedByColumnDesc: lt.EZLOGIC_TABLE_SORTED_BY_COLUMN_DESC,
    thenBy: lt.EZLOGIC_TABLE_THEN_BY,
    toggleDensity: lt.EZLOGIC_TABLE_TOGGLE_DENSITY,
    toggleFullScreen: lt.EZLOGIC_TABLE_TOGGLE_FULL_SCREEN,
    toggleSelectAll: lt.EZLOGIC_TABLE_TOGGLE_SELECT_ALL,
    toggleSelectRow: lt.EZLOGIC_TABLE_TOGGLE_SELECT_ROW,
    toggleVisibility: lt.EZLOGIC_TABLE_TOGGLE_VISIBILITY,
    ungroupByColumn: lt.EZLOGIC_TABLE_UNGROUP_BY_COLUMN,
    unpin: lt.EZLOGIC_TABLE_UNPIN,
    unpinAll: lt.EZLOGIC_TABLE_UNPIN_ALL,
    unsorted: lt.EZLOGIC_TABLE_UNSORTED,
};
export const POSITION_OPTIONS = {
    TOP: 'top',
    BOTTOM: 'bottom',
    BOTH: 'both',
    NONE: 'none',
    LEFT: 'left',
    RIGHT: 'right',
    FIRST: 'first',
    LAST: 'last',
};

export const SELECT_MODES = {
    ALL: 'all',
    PAGE: 'page',
};

export const DEFAULT_TABLE_ROWS_PER_PAGE = 10;
export const DEFAULT_TABLE_PAGE_INDEX = 0;
