import hash from '../../constants/uniqueHash';
import { isPredefinedCameraCapability } from '../../containers/Ezlo/EzloMeshbot/utils';
import { ACTION_TYPES, DEVICE_CAMERA_CAPABILITIES, ITEM, THEN_SWITCH, REQUEST } from '../../constants/MeshbotConstant';
import { SWITCH } from '../../constants/SystemHistory';

const createMeshBotActionField = (id, isEnabled) => {
    return [
        {
            name: 'sceneId',
            type: 'sceneId',
            value: id,
        },
        {
            name: 'enabled',
            type: 'bool',
            value: isEnabled,
        },
    ];
};

const createBlock = (method) => {
    switch (method) {
        case 'device':
            return {
                method: {
                    args: {
                        item: 'device',
                        value: 'deviceFlag',
                    },
                    name: 'setDeviceArmed',
                },
            };
        case 'item':
            return {
                method: {
                    args: {
                        item: 'item',
                        value: 'value',
                    },
                    name: 'setItemValue',
                },
            };
        case ACTION_TYPES.TOGGLE_VALUE:
            return {
                method: {
                    args: {
                        source: ITEM,
                    },
                    name: ACTION_TYPES.TOGGLE_VALUE,
                },
            };
        case 'script':
            return {
                method: {
                    args: {
                        script: 'script',
                    },
                    name: 'runCustomScript',
                },
            };
        case 'url':
            return {
                method: {
                    name: 'sendHttpRequest',
                    args: {
                        url: 'url',
                        credential: 'credential',
                        request: 'request',
                        contentType: 'contentType',
                        content: 'content',
                        headers: 'headers',
                        skipSecurity: 'skipSecurity',
                    },
                },
            };
        case 'cloud':
            return {
                method: {
                    name: 'sendCloudAbstractCommand',
                    args: {
                        abstractId: 'abstract',
                        capability: 'capability',
                        command: 'command',
                        parameters: 'parameters',
                    },
                },
            };
        case 'runScene':
            return {
                method: {
                    name: 'runScene',
                    args: {
                        sceneId: 'sceneId',
                    },
                },
            };
        case 'notification':
            return {
                method: {
                    name: 'cloudAPI',
                    args: {
                        method: 'cloudApiName',
                        version: 'version',
                        params: 'params',
                    },
                },
            };
        case 'enableScene':
        case 'disableScene':
            return {
                method: {
                    name: 'setSceneState',
                    args: {
                        sceneId: 'sceneId',
                        enabled: 'enabled',
                    },
                },
            };
        case 'unlatchScene':
            return {
                method: {
                    args: {
                        blockId: 'blockId',
                        sceneId: 'sceneId',
                    },
                    name: 'resetLatch',
                },
            };
        case 'unlatchAllLatches':
            return {
                method: {
                    args: {
                        sceneId: 'sceneId',
                    },
                    name: 'resetSceneLatches',
                },
            };
        default:
            return;
    }
};

export const updateHttpFields = (block = {}, value = '') => {
    return block?.fields?.reduce((acc, item) => {
        if (item.name === REQUEST) {
            item.value = value;
        }
        acc.push(item);

        return acc;
    }, []);
};

export const createFields = (type, method, id, value, optionForEnum, isEnabled, blockId, name) => {
    switch (method) {
        case 'device':
            return [
                {
                    name: 'device',
                    type: 'device',
                    value: id,
                },
                {
                    name: 'deviceFlag',
                    type: 'bool',
                    value: value,
                },
            ];
        case 'item':
            return [
                {
                    name: 'item',
                    type: 'item',
                    value: id,
                },
                {
                    name: 'value',
                    enum: optionForEnum,
                    type: type,
                    value: isPredefinedCameraCapability(name) ? DEVICE_CAMERA_CAPABILITIES[name]?.value : value,
                },
            ];
        case 'script':
            return [
                {
                    name: 'script',
                    type: 'scriptId',
                    value: value,
                },
            ];
        case 'url':
            return [
                { name: 'url', type: 'string', value: '' },
                { name: 'request', type: 'string', value: value },
                { name: 'credential', type: 'credential', value: { user: '', password: '' } },
                { name: 'contentType', type: 'string', value: '' },
                { name: 'content', type: 'string', value: '' },
                { name: 'headers', type: 'dictionary', value: {} },
                { name: 'skipSecurity', type: 'bool', value: false },
            ];

        case 'cloud':
            return [
                {
                    name: 'abstract',
                    type: value.type,
                    value: id,
                },
                {
                    name: 'capability',
                    type: value.type,
                    value: value.command,
                },
                {
                    name: 'command',
                    type: value.type,
                    value: 'send',
                },
                {
                    name: 'parameters',
                    type: 'object',
                    value: {
                        text: value.message,
                    },
                },
            ];
        case 'notification':
            // prettier-ignore
            const valueBody = value && value?.body ? value?.body
                .replace(
                    /(\r\n|\n|\r)/gm,
                    '\\n',
                ) : '';
            const valueBodyHtml = value && value?.body_html ? value?.body_html : '';
            let message = `{"name": "default","body":"${valueBody}"}`;

            if (value && value.channels && value.channels.includes('email')) {
                message = `{"name": "default","body":"${valueBody}","body_html":"${valueBodyHtml}","subject":"${value.subject.replace(
                    /(\r\n|\n|\r)/gm,
                    '\\n',
                )}"}`;
            }

            let data = '{"type":"user","uuid":""}';
            if (value && value.uuids.length > 0) {
                if (value.channels && value.channels.length > 0) {
                    const channels = value.channels.map((item) => `"${item}"`);
                    data = value.uuids.map((item) => {
                        return `{"type":"user","uuid":"${item}", "channels":[${channels}]}`;
                    });
                } else {
                    data = value.uuids.map((item) => {
                        return `{"type":"user","uuid":"${item}"}`;
                    });
                }
            }

            return [
                {
                    name: 'cloudApiName',
                    type: 'string',
                    value: 'controller_notify_user',
                },

                {
                    name: 'version',
                    type: 'int',
                    value: 1,
                },

                {
                    name: 'params',
                    type: 'string',
                    value: `{"scene_id":"${hash()}","scene_name":"${hash()}","notification":{"name":"notify-users","parameters":[${message},{"name":"target","targets":[${data}]}]}}`,
                },
            ];

        /**
         * Creates "fields" for "runScene" method
         * @see {@link https://log.ezlo.com/new/hub/scenes/action_blocks/#runscene}
         */
        case 'runScene':
            if (!id) {
                throw new Error('Missing id in createFields fn ');
            }

            return [
                {
                    name: 'sceneId',
                    type: 'sceneId',
                    value: id,
                },
            ];
        case 'enableScene':
        case 'disableScene':
            return createMeshBotActionField(id, isEnabled);
        case 'unlatchScene':
            return [
                {
                    name: 'sceneId',
                    type: 'string',
                    value: id,
                },
                {
                    name: 'blockId',
                    type: 'string',
                    value: blockId,
                },
            ];
        case 'unlatchAllLatches':
            return [
                {
                    name: 'sceneId',
                    type: 'string',
                    value: id,
                },
            ];
        case ACTION_TYPES.TOGGLE_VALUE:
            return [
                {
                    name: ITEM,
                    type: ITEM,
                    value: id,
                },
            ];
        default:
            return;
    }
};

export const createLabel = (name, method, value) => {
    switch (method) {
        case 'device':
            const device = {
                lang_tag: '',
                text: 'armed device',
            };

            return device;
        case 'item':
            const item = {
                lang_tag: 'when_switch',
                text: name,
            };

            return item;
        case 'script':
            const script = {
                lang_tag: 'script',
                text: 'script',
            };

            return script;
        case 'url':
            const url = {
                lang_tag: '',
                text: '',
            };

            return url;
        case 'cloud':
            const cloud = {
                lang_tag: value.message,
                text: value.message,
            };

            return cloud;
        case 'notification':
            const notification = {
                lang_tag: 'notification',
                text: 'notification',
            };

            return notification;
        case ACTION_TYPES.TOGGLE_VALUE:
            return { lang_tag: THEN_SWITCH, text: SWITCH };
        default:
            return;
    }
};

// TODO: split to separate functions
const blockActionTemplate = (name, type, method, id, value, optionForEnum, isEnabled, blockId) => {
    return {
        _tempId: hash(),
        blockOptions: createBlock(method),
        blockType: 'then',
        fields: createFields(type, method, id, value, optionForEnum, isEnabled, blockId, name),
        label: createLabel(name, method),
    };
};

export default blockActionTemplate;
