import React from 'react';

const IcMedicalAsistanceRequest = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
            <path
                d="M28.0566 10.8L26.5566 8.2C26.2566 7.7 25.6566 7.6 25.1566 7.8L18.5566 11.6V4C18.5566 3.4 18.1566 3 17.5566 3H14.5566C13.9566 3 13.5566 3.4 13.5566 4V11.7L6.85656 7.8C6.35656 7.6 5.75656 7.7 5.45656 8.2L3.95656 10.8C3.75656 11.3 3.85656 11.9 4.35656 12.2L10.9566 16L4.35656 19.8C3.85656 20.1 3.75656 20.7 3.95656 21.2L5.45656 23.8C5.75656 24.3 6.35656 24.4 6.85656 24.2L13.4566 20.4V28C13.4566 28.6 13.8566 29 14.4566 29H17.4566C18.0566 29 18.4566 28.6 18.4566 28V20.3L25.0566 24.1C25.5566 24.4 26.1566 24.2 26.4566 23.7L27.9566 21.1C28.2566 20.6 28.0566 20 27.5566 19.7L20.9566 16L27.5566 12.2C28.1566 11.9 28.2566 11.3 28.0566 10.8Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcMedicalAsistanceRequest;
