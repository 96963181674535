import {
    getBarrierProblemSensorsParams,
    getBatteryEventParams,
    getUpdatedButtonStateParams,
    getUserLockOperationParams,
} from './index';

/**
 * Retrieves the parameters for a hub item updated event based on the provided result and message subclass.
 * This function works specifically with the 'hub.item.updated' broadcast events.
 *
 * @param {Object} result - The result object containing information about the updated item.
 * @param {string} msg_subclass - The message subclass associated with the event.
 * @returns {AlertHistoryEventParams | undefined} The parameters for the updated item event, or undefined if the item is not recognized.
 */
export const getHubItemUpdatedParams = (result, msg_subclass) => {
    switch (result?.name) {
        case 'battery':
            return getBatteryEventParams(result);
        case 'button_state':
            return getUpdatedButtonStateParams(result, msg_subclass);
        case 'user_lock_operation':
            return getUserLockOperationParams(result, msg_subclass);
        case 'barrier_problem_sensors':
            return getBarrierProblemSensorsParams(result, msg_subclass);
        default:
            return;
    }
};
