export const getControllers = (data) => {
    return Object.entries(data).reduce((acc, [serial, controller]) => {
        if (!controller.isConnected) {
            return acc;
        }

        return [
            ...acc,
            {
                serial: serial,
                isConnected: controller.isConnected,
                uuid: controller.uuid,
                hardware: controller?.hardware,
                devices: controller?.devices,
                plugins: controller?.nodes,
                info: controller?.info,
                model: controller?.model,
                connected: controller.isConnected,
                firmware: controller?.firmware,
            },
        ];
    }, []);
};

export const getControllersAll = (data) => {
    return Object.entries(data).reduce((acc, [serial, controller]) => {
        return [
            ...acc,
            {
                serial: serial,
                isConnected: controller.isConnected,
                uuid: controller.uuid,
                hardware: controller?.hardware,
            },
        ];
    }, []);
};
