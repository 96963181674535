import {
    EZLOGIC_TITLE_ALARM,
    EZLOGIC_TITLE_ALL_ACTIONS,
    EZLOGIC_TITLE_DOOR_LOCKS,
    EZLOGIC_TITLE_GARAGE_DOORS,
    EZLOGIC_TITLE_LIGHTS_AND_SWITCHES,
    EZLOGIC_TITLE_MAINTENANCE,
    EZLOGIC_TITLE_OTHER_SENSORS,
    EZLOGIC_TITLE_SCENE_CONTROLLERS,
    EZLOGIC_TITLE_SECURITY_DEVICES,
    EZLOGIC_TITLE_SYSTEM,
    EZLOGIC_TITLE_THERMOSTATS,
    EZLOGIC_TITLE_DOORBELL,
} from 'constants/language_tokens';

export const IDS = {
    ALL_ACTIONS: 'ALL_ACTIONS',
    MAINTENANCE_LOW_BATTERY: 'MAINTENANCE_LOW_BATTERY',
    SECURITY_DEVICES: 'SECURITY_DEVICES',
    DOOR_LOCKS: 'DOOR_LOCKS',
    GARAGE_DOORS: 'GARAGE_DOORS',
    SCENE_CONTROLLERS: 'SCENE_CONTROLLERS',
    LIGHTS_AND_SWITCHES: 'LIGHTS_AND_SWITCHES',
    THERMOSTATS: 'THERMOSTATS',
    OTHER_SENSORS: 'OTHER_SENSORS',
    SYSTEM: 'SYSTEM',
    ALARM: 'ALARM',
    DOORBELL: 'DOORBELL',
};
export const EVENT_HISTORY_CATEGORIES = [
    { labelKey: EZLOGIC_TITLE_ALL_ACTIONS, id: IDS.ALL_ACTIONS },
    { labelKey: EZLOGIC_TITLE_MAINTENANCE, id: IDS.MAINTENANCE_LOW_BATTERY },
    { labelKey: EZLOGIC_TITLE_SECURITY_DEVICES, id: IDS.SECURITY_DEVICES },
    { labelKey: EZLOGIC_TITLE_DOOR_LOCKS, id: IDS.DOOR_LOCKS },
    { labelKey: EZLOGIC_TITLE_GARAGE_DOORS, id: IDS.GARAGE_DOORS },
    { labelKey: EZLOGIC_TITLE_SCENE_CONTROLLERS, id: IDS.SCENE_CONTROLLERS },
    { labelKey: EZLOGIC_TITLE_LIGHTS_AND_SWITCHES, id: IDS.LIGHTS_AND_SWITCHES },
    { labelKey: EZLOGIC_TITLE_THERMOSTATS, id: IDS.THERMOSTATS },
    { labelKey: EZLOGIC_TITLE_OTHER_SENSORS, id: IDS.OTHER_SENSORS },
    { labelKey: EZLOGIC_TITLE_SYSTEM, id: IDS.SYSTEM },
    { labelKey: EZLOGIC_TITLE_ALARM, id: IDS.ALARM },
    { labelKey: EZLOGIC_TITLE_DOORBELL, id: IDS.DOORBELL },
];
export const UNKNOWN_EVENTS = 'UNKNOWN_EVENTS';
export const LOW_BATTERY = 'low_battery';
export const HUB_USER_NOTIFICATION = 'hub.user.notification';
export const HUB_NETWORK_CHANGED = 'hub.network.changed';
export const HUB_ITEM_UPDATED = 'hub.item.updated';
export const HUB_INFO_CHANGED = 'hub.info.changed';
export const HUB_MODES_ALARMED = 'hub.modes.alarmed';
export const HUB_MODES_SWITCHED = 'hub.modes.switched';
export const HUB_MODES_BYPASS_DEVICES_REMOVED = 'hub.modes.bypass_devices.removed';
export const HUB_MODES_BYPASS_DEVICES_ADDED = 'hub.modes.bypass_devices.added';
export const HUB_SOFTWARE_UPDATE_PROGRESS = 'hub.software.update.progress';
export const HUB_BACKUP_RESTORE_PROGRESS = 'hub.backup.restore.progress';
export const HUB_BACKUP_CREATE_PROGRESS = 'hub.backup.create.progress';
export const GREEN_COLOR = '#00B491';
export const RED_COLOR = '#F44927';
export const WARNING_COLOR = '#fe5c15';
export const BLACK_COLOR = '#1c1e21';

export const CATEGORY_ID = 'categoryId';
export const DESCRIPTION_CONFIG = 'descriptionConfig';
export const ICON = 'icon';
export const COLOR = 'color';
export const VALUE_FORMATTED = 'valueFormatted';
export const VALUE = 'value';
