import React from 'react';

const IcBurglaryAlarm = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27 16C27 22.6274 22.0751 28 16 28C9.92487 28 5 22.6274 5 16C5 9.37258 9.92487 4 16 4C22.0751 4 27 9.37258 27 16ZM11.325 11.5C12.0455 11.5 12.7162 11.6937 13.2775 12.0271C13.5487 12.4036 13.9035 12.7258 14.318 12.972L14.338 13H14.3661C14.9174 13.3161 15.5705 13.499 16.2705 13.499C16.9705 13.499 17.6236 13.3161 18.1749 13H18.2112C18.2198 12.9877 18.2285 12.9755 18.2374 12.9634C18.6425 12.7202 18.9901 12.4041 19.2573 12.0356C19.8215 11.6971 20.4975 11.5 21.2242 11.5C23.1986 11.5 24.7992 12.9551 24.7992 14.75C24.7992 16.5449 23.1986 18 21.2242 18C20.4975 18 19.8215 17.8029 19.2573 17.4643C18.9901 17.0959 18.6425 16.7798 18.2374 16.5366C18.2285 16.5245 18.2198 16.5123 18.2112 16.5H18.1749C17.6236 16.1839 16.9705 16.001 16.2705 16.001C15.5705 16.001 14.9174 16.1839 14.3661 16.5H14.338L14.318 16.528C13.9035 16.7742 13.5487 17.0964 13.2775 17.4729C12.7162 17.8063 12.0455 18 11.325 18C9.35058 18 7.75 16.5449 7.75 14.75C7.75 12.9551 9.35058 11.5 11.325 11.5ZM16.2742 23.501C18.5524 23.501 20.3992 22.9413 20.3992 22.251C20.3992 21.5606 18.5524 21.001 16.2742 21.001C13.996 21.001 12.1492 21.5606 12.1492 22.251C12.1492 22.9413 13.996 23.501 16.2742 23.501Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcBurglaryAlarm;
