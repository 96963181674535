import React from 'react';

const IcActionsBatteryFull = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
            <path
                d="M22 8H4C2.4 8 1 9.4 1 11V21C1 22.6 2.4 24 4 24H22C23.6 24 25 22.6 25 21V11C25 9.4 23.6 8 22 8ZM28 19V13C29.7 13 31 14.3 31 16C31 17.7 29.7 19 28 19Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcActionsBatteryFull;
