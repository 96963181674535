import Axios from 'axios';
import { axiosConfig } from './config';
import {
    API_CLOUD_EZLO_REQUEST_URL,
    CLOUD_EZLO_TOKEN_EXCHANGE_URL,
    FORGOT_PASSWORD_URL,
    getSignUpPartnerUserParams,
    LEGACY_PARTNER_USER_CREATE,
    REGISTER_URL,
    USER_INFO_URL,
} from '../constants/URLs';

import { TOKEN_VERSION } from '../constants/Users';
import oemManager from '../services/oem';

export const apiGetUser = (user) => {
    const url = USER_INFO_URL(user);

    return Axios.get(url, axiosConfig());
};

export const apiGetCloudToken = (PK_User) => {
    const url = CLOUD_EZLO_TOKEN_EXCHANGE_URL(PK_User);

    return Axios.get(url, axiosConfig());
};

export const apiRegister = (name, email, pass) => {
    const PK_Oem = oemManager.oem.getPkOem();

    const data = {
        Username: name,
        Email: email,
        Password: pass,
        PK_Oem,
        TokenVersion: TOKEN_VERSION,
    };

    return Axios.post(REGISTER_URL(), data);
};

export const apiRegisterForPartnerUser = (name, email, pass, partnerUuid) => {
    const envUuid = oemManager.oem.getEnvironmentUuid();
    const data = {
        call: LEGACY_PARTNER_USER_CREATE,
        params: getSignUpPartnerUserParams(name, email, pass, partnerUuid, envUuid),
    };

    return Axios.post(API_CLOUD_EZLO_REQUEST_URL(), data);
};

export const apiForgotPassword = (email) => {
    const PK_Oem = oemManager.oem.getPkOem();
    const url = FORGOT_PASSWORD_URL(email);

    return Axios.put(url, { PK_Oem });
};
