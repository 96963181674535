export const CUSTOM_TEMPLATE = 1;
export const waiting = 'Please, waiting...';
export const IS_EDIT = 'isEdit';
export const NAME_BEFORE_EDITING = 'nameBeforeEditing';
export const copy = 'Copy of';
export const CREATE = 'create';
export const UPDATE = 'update';
export const ENABLED = 1;
export const DISABLED = 0;
export const DATA_FROM_BLOCK_WITH_NAME_STATIC = 1;
export const DATA_FROM_BLOCK_WITH_NAME_AV = 0;
export const DATA_FROM_VALUE_FROM_BLOCK_STATIC = 0;
export const DATA_FROM_VALUE_FROM_FIRST_BLOCK = 0;
export const DATA_FROM_VALUE_FROM_SECOND_BLOCK = 1;
export const VALUE_FROM_FIRST_VARIABLE = 0;
export const STATIC = 'static';
export const AV = 'av';
export const DATA_FROM_FIRST_SCENE = 0;
export const DATA_FROM_FIRST_ACTION = 0;
export const CAPABILITY_FOR_NOTIFICATION_TEMPLATE = '0';
export const NUMBER_OF_OBJECT_WITH_CAPABILITY_DATA = 1;
export const ARRAY_WITH_PARAMETERS = 0;
export const DATA_FROM_FIRST_BLOCK = 0;
export const DATA_FROM_SECOND_BLOCK = 1;
export const NOTIFICATION_TEMPLATE = 'notificationTemplate';
export const CATEGORY = 'category';
export const NOTIFY_USERS = 'notify-users';
export const NOTIFICATION_CATEGORIES_LIST = 'notification_categories_list';
export const NOTIFICATION_CATEGOROES_SETTINGS_LIST = 'notification_category_settings_list';
export const NOTIFICATION_CATEGORIES_SET_ALL = 'notification_categories_set_all';
export const NOTIFICATION_CATEGORY_SETTINGS_SET = 'notification_category_settings_set';
export const PARTNER_MESHBOT_TEMPLATES_SET = 'partner_meshbot_templates_set';
export const PARTNER_MESHBOT_TEMPLATES_LIST = 'partner_meshbot_templates_list';
export const NOTIFICATION_LIST_CHANNELS = 'notification_list_channels';
export const STATUS = 'status';
export const MIN_TEMPERATURE = -500;
export const NOTIFICATION_TYPE = 'notification';

export const SYNCED = 'synced';
