import Axios from 'axios';
import { axiosConfig } from './config';
import { DEVICES_URL, DEVICE_INFO_URL } from '../constants/URLs';
import { isControllerSupportedBySerial } from '../helpers/validation';

/**
 * Make API call to GET account devices array
 * @param {string} account - PK_Account value
 * @param {boolean} [extractPayload = false] - extract response payload
 * @returns {Object|array} returns devices from response if 'extractPayload' is true, otherwise - original response object
 * */
export const apiDevices = async (account, extractPayload = false) => {
    const url = DEVICES_URL(account);

    try {
        const res = await Axios.get(url, axiosConfig());

        let devices = null;
        if (extractPayload) {
            devices = res && res.data && res.data.Devices;
            if (devices) {
                devices = devices.filter((controller) => isControllerSupportedBySerial(controller.PK_Device));
            }

            return devices;
        }

        return res;
    } catch (error) {
        if (error.response && error.response.status === 404) {
            return null;
        } else {
            throw error;
        }
    }
};
export const apiDeviceInfo = (device) => {
    const url = DEVICE_INFO_URL(device);

    return Axios.get(url, axiosConfig());
};
