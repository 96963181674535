import React from 'react';
import { Formik } from 'formik';

import useKvsActions from '../../../../../../hooks/useKvsActions';
import { getInitialEditLabelFormValues, validationSchema } from '../../../utils';
import { FormikLabelDialogContainer } from '../LabelDialogContainer';
import LabelForm from '../LabelForm';
import { EZLOGIC_BUTTON_EDIT, EZLOGIC_TITLE_EDIT_LABEL } from '../../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../languages';

const EditLabelDialog = ({ open, closeDialog, labelData, labelUuid }) => {
    const { editMeshBotLabel } = useKvsActions();
    const { t } = useTranslate();

    const onSubmit = async (values, { setSubmitting, resetForm }) => {
        const { isError } = await editMeshBotLabel(values, labelUuid);

        if (!isError) {
            closeDialog();
            resetForm({ values });
        } else {
            setSubmitting(false);
        }
    };

    return (
        <Formik
            initialValues={getInitialEditLabelFormValues(labelData)}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            <FormikLabelDialogContainer
                labelData={labelData}
                open={open}
                closeDialog={closeDialog}
                dialogTitle={t(EZLOGIC_TITLE_EDIT_LABEL)}
            >
                <LabelForm submitButtonText={t(EZLOGIC_BUTTON_EDIT)} />
            </FormikLabelDialogContainer>
        </Formik>
    );
};

export default EditLabelDialog;
