import React from 'react';
import { useSelector } from 'react-redux';

import { SystemHistoryTableContainer } from './SystemHistoryTableConrainer';
import SystemHistoryHeaderContainer from './SystemHistoryHeaderContainer';
import SystemHistorySearchContainer from './SystemHistorySearchContainer';
import BoxWithLinearProgress from 'components/BoxWithLinearProgress';

import styles from '../systemHistory.module.scss';

const SystemHistoryLayout = () => {
    const { isLoading } = useSelector(({ systemHistory }) => systemHistory);

    return (
        <>
            {<BoxWithLinearProgress isLinearProgress={isLoading} />}
            <section className={styles.systemHistoryWrapper}>
                <SystemHistoryHeaderContainer />
                <SystemHistorySearchContainer />
                <SystemHistoryTableContainer />
            </section>
        </>
    );
};

export default SystemHistoryLayout;
