import React from 'react';

const IcControlsCopy = (props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
            <path
                d="M24 9V21C24 22.7 22.7 24 21 24H9C7.3 24 6 22.7 6 21H9H21V9V6C22.7 6 24 7.3 24 9ZM15 3H3V15H15V3ZM15 0C16.7 0 18 1.3 18 3V15C18 16.7 16.7 18 15 18H3C1.3 18 0 16.7 0 15V3C0 1.3 1.3 0 3 0H15Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcControlsCopy;
