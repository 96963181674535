import defer from 'lodash/defer';

export default class WSEvents {
    constructor() {
        this.pool = new Map();
    }

    /**
     * @param {string} name - channel name
     * @param {function} cb - callback
     *
     * @return {WSEvents}
     * */
    subscribe(name, cb) {
        const cbs = this.pool.get(name);

        if (!cbs) {
            this.pool.set(name, [cb]);
        } else {
            cbs.push(cb);
        }

        return this;
    }

    /**
     * @param {string} name - channel name
     * @param {function} cb - callback
     *
     * @return {WSEvents}
     * */
    unsubscribe(name, cb) {
        if (name) {
            const cbs = this.pool.get(name);

            if (cb) {
                const index = cbs.indexOf(cb);
                if (index >= 0) {
                    cbs.splice(index, 1);
                }
            } else {
                this.pool.delete(name);
            }
        }

        return this;
    }

    /**
     * @param {Object} resp - JSON-RPC event
     * */
    resolveEvent(resp) {
        const cbs = this.pool.get(resp.msg_subclass);
        if (cbs) {
            cbs.map(function (cb) {
                defer(cb, resp);
            });
        }
    }
}
