/**
 * The minimal Advanced Scenes version that supports the stopping of scenes on the hub.
 * @type {number}
 */
export const HUB_ADVANCED_SCENES_VERSION_SUPPORTING_HUB_SCENES_STOP = 1.64;

/**
 * The minimal Advanced Scenes version that supports the Global Restrictions feature of scenes on the hub.
 * @type {number}
 */
export const HUB_ADVANCED_SCENES_VERSION_SUPPORTING_HUB_GLOBAL_RESTRICTIONS = 1.71;
