import React from 'react';

const IcVisualsServicesSentinel = (props) => {
    return (
        <svg width="140" height="101" viewBox="0 0 140 101" fill="none" {...props}>
            <path
                d="M35.1192 100.944C54.515 100.944 70.2385 85.2202 70.2385 65.8244C70.2385 46.4286 54.515 30.7052 35.1192 30.7052C15.7234 30.7052 0 46.4286 0 65.8244C0 85.2202 15.7234 100.944 35.1192 100.944Z"
                fill="#FE5C15"
            />
            <path
                d="M35.1193 84.1398C45.2346 84.1398 53.4347 75.9397 53.4347 65.8244C53.4347 55.7092 45.2346 47.5091 35.1193 47.5091C25.004 47.5091 16.804 55.7092 16.804 65.8244C16.804 75.9397 25.004 84.1398 35.1193 84.1398Z"
                fill="#E82E10"
            />
            <path
                d="M89.5282 100.944C117.403 100.944 140 78.3467 140 50.4718C140 22.597 117.403 0 89.5282 0C61.6534 0 39.0564 22.597 39.0564 50.4718C39.0564 78.3467 61.6534 100.944 89.5282 100.944Z"
                fill="#3E46AE"
            />
            <path
                d="M42.7396 31.5439C40.3719 37.3906 39.0564 43.776 39.0564 50.4719C39.0564 67.1109 47.1112 81.8652 59.5308 91.0594C66.1297 84.6745 70.2385 75.7318 70.2385 65.8244C70.2385 49.0467 58.4699 35.0257 42.7396 31.5439Z"
                fill="black"
            />
        </svg>
    );
};

export default IcVisualsServicesSentinel;
