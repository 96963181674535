import {
    hubBackupRestoreApi,
    subscribeHubBackupRestoreProgressApi,
    unsubscribeHubBackupRestoreProgressApi,
} from './network/backupApi';
import { STATUSES } from '../common/constants';

/**
 * Callback function for handling hub backup and restore progress broadcasts.
 *
 * @callback OnRestoreProgressBroadcast
 * @param {Object} result - The result object containing information about the progress.
 */

/**
 * Callback function factory for handling hub backup and restore progress broadcasts.
 *
 * @param {string} serial - The serial number of the hub.
 * @param {OnRestoreProgressBroadcast} onRestoreProgressBroadcast - Callback function to handle progress broadcasts.
 * @returns {Function} - The callback function for handling progress broadcasts.
 */
export const onHubBackupRestoreProgressBroadcast =
    (serial, onRestoreProgressBroadcast) =>
    ({ result }) => {
        if (result.status === STATUSES.FINISHED || result.status === STATUSES.ABORTED) {
            unsubscribeHubBackupRestoreProgressApi(serial);
        }
        onRestoreProgressBroadcast(result);
    };

/**
 * Initiates a hub backup restore operation and subscribes to progress broadcasts.
 *
 * @async
 * @param {string} serial - The serial number of the hub.
 * @param {Object} params - The parameters for the backup restore operation.
 * @param {Function} onSuccess - Callback function to handle the success of the operation.
 * @param {Function} onError - Callback function to handle errors during the operation.
 * @param {OnRestoreProgressBroadcast} onRestoreProgressBroadcast - Callback function to handle progress broadcasts.
 * @returns {Promise} - A promise representing the result of the operation.
 */
export const restoreWithSubscribe = async (serial, params, onSuccess, onError, onRestoreProgressBroadcast) => {
    await subscribeHubBackupRestoreProgressApi(
        serial,
        onHubBackupRestoreProgressBroadcast(serial, onRestoreProgressBroadcast),
    );
    await hubBackupRestoreApi(serial, params, onSuccess, onError);
};

/**
 * Initiates a hub backup restore operation using the WebSocket Manager.
 *
 * @async
 * @param {string} serial - The serial number of the hub.
 * @param {Object} params - The parameters for the backup restore operation.
 * @param {string} params.key - The key for the backup operation.
 * @param {string} params.uuid - The UUID for the backup operation.
 * @param {Function} onSuccess - Callback function to handle the success of the operation.
 * @param {Function} onError - Callback function to handle errors during the operation.
 * @returns {Promise} - A promise representing the result of the operation.
 * @see {@link https://log.ezlo.com/new/hub/backup/#hubbackuprestore}
 */
export const restore = async (serial, params, onSuccess, onError) =>
    await hubBackupRestoreApi(serial, params, onSuccess, onError);
