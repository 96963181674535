export const getAuthUrl = (root) => {
    if (root.error_text) {
        return;
    }

    return root.body.data.data;
};

export const extractCallDefinitionListWithCallBack = (integrationPreviewResult) => {
    return (
        integrationPreviewResult?.call_definition?.list.reduce((acc, item) => {
            acc.push(item.name);

            return acc;
        }, []) ?? []
    );
};

export const extractSubscriptionDefinitionListWithCallBack = (integrationPreviewResult) => {
    return (
        integrationPreviewResult?.subscribe_definition.list.reduce((acc, item) => {
            acc.push(item.name);

            return acc;
        }, []) ?? []
    );
};

export const getNucalTriggerMethodsName = (integrationPreviewResult) => {
    return integrationPreviewResult?.subscribe_definition?.list.map((item) => {
        return item.name;
    });
};

export const getNucalTriggerMethods = (integrationPreviewResult) => {
    return getNucalTriggerMethodsName(integrationPreviewResult);
};

export const getNucalMethods = (integrationPreviewResult) => {
    const callDefinitionListWithCallBack = getNucalTriggerMethodsName(integrationPreviewResult);
    const methods = extractCallDefinitionListWithCallBack(integrationPreviewResult);

    return methods?.filter((method) => !callDefinitionListWithCallBack.includes(method));
};

export const getPaths = (integrationPreviewResult) => {
    return integrationPreviewResult?.call_definition?.list;
};

export const getNucalParjectDefinition = (integrationPreviewResult) => {
    return integrationPreviewResult?.nucal_parject_definition?.list;
};

export const getNucalSchemaKey = (integrationPreviewResult, methodName) => {
    const nucalParjectDefinition = getNucalParjectDefinition(integrationPreviewResult);

    if (!nucalParjectDefinition || !methodName) {
        return;
    }
    methodName = '/' + methodName;
    const { schema } =
        nucalParjectDefinition?.list?.parjectJson?.paths[methodName]?.post?.requestBody?.content['application/json'];

    const splittedSchema = schema['$ref']?.split('/');
    const schemaKey = splittedSchema[splittedSchema?.length - 1];

    return schemaKey;
};

export const getNucalComponentBySchemaKey = (integrationPreviewResult, schemaKey) => {
    const nucalParjectDefinition = getNucalParjectDefinition(integrationPreviewResult);

    if (!nucalParjectDefinition) {
        return;
    }

    return nucalParjectDefinition.list.parjectJson.components.schemas[schemaKey];
};

export const deleteAbstractRequestParams = (parjectUuid, abstractUuid) => {
    return {
        call: 'integrations-setup',
        params: {
            uuid: parjectUuid,
            info: {
                action: 'delete',
                uuid_index: abstractUuid,
            },
        },
    };
};

export const getParjectGeneratedId = (integrationPreviewResult) => {
    const nucal_parject_definition = integrationPreviewResult?.nucal_parject_definition;
    const list = nucal_parject_definition?.list;

    return list?.name;
};

export const getAuthenticationType = (integrationPreviewResult) => {
    const nucal_parject_definition = integrationPreviewResult?.nucal_parject_definition;

    return nucal_parject_definition?.list?.authentication?.type;
};

export const extractIntegrationUUID = (abstract) => {
    const details = abstract?.details;

    return details?.integration_uuid;
};
