import React from 'react';

const IcStoppedStatus = (props) => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" {...props}>
            <circle cx="20" cy="20" r="16.5" stroke="currentColor" strokeWidth="3" />
            <rect x="14" y="14" width="12" height="12" fill="currentColor" />
        </svg>
    );
};

export default IcStoppedStatus;
