import React from 'react';
import PropTypes from 'prop-types';

import { useTranslate } from 'features/languages';

const ToastContent = ({ contentTranslateKey }) => {
    const { t } = useTranslate();

    return <span>{t(contentTranslateKey)}</span>;
};

ToastContent.propTypes = {
    contentTranslateKey: PropTypes.string,
};

export default ToastContent;
