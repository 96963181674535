/**
 * Properties associated with token flows.
 *
 * @typedef {Object} TokensFlowsProperties
 * @property {string} TOAST_ID - The property key for toast identifier.
 * @property {string} IS_TOKENS_OBSOLETE - The property key for checking if tokens are obsolete.
 * @property {string} TIMEOUT_ID - The property key for timeout identifier.
 */

/**
 * Constants related to token flows properties.
 *
 * @type {TokensFlowsProperties}
 */
export const TOKENS_FLOWS_PROPERTIES = {
    TOAST_ID: 'toastId',
    IS_TOKENS_OBSOLETE: 'isTokensObsolete',
    TIMEOUT_ID: 'timeoutId',
};
/**
 * The key representing token flows.
 *
 * @type {string}
 */
export const TOKENS_FLOWS = 'tokensFlows';
