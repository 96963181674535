import { send, validateSerial } from '../../common/utils';
import { HUB_LOG_LOCAL_SET } from '../../common/methods';
import { isBoolean, isNumber } from 'lodash';

/**
 * Validates the parameters required for the hubLogLocalSetApi function.
 *
 * @param {Object} params - The parameters object.
 * @param {string} params.severity - Log severity setting.
 * @param {number} [params.indent] - Log indent setting.
 * @param {boolean} [params.color] - Log color setting
 * @throws {Error} - Throws an error if required parameters are missing or not of the correct type.
 * @returns {void}
 */
export const validateParams = (params) => {
    if (!params.severity) {
        throw new Error('params.severity is required');
    }

    if (params.hasOwnProperty('indent') && !isNumber(params.indent)) {
        throw new Error('params.indent must be number');
    }

    if (params.hasOwnProperty('color') && !isBoolean(params.color)) {
        throw new Error('params.indent must be boolean');
    }
};

/**
 * API function for setting local logs on the hub.
 *
 * @function
 * @param {string} serial - Controller serial number.
 * @param {Object} params - Parameters for setting local logs.
 * @param {function} onSuccess - Callback function to be executed on success.
 * @param {function} onError - Callback function to be executed on error.
 * @returns {Promise}
 */
export const hubLogLocalSetApi = (serial, params, onSuccess, onError) => {
    validateParams(params);
    validateSerial(serial);

    return send(serial, HUB_LOG_LOCAL_SET, params, onSuccess, onError);
};

/**
 * Asynchronous function for setting local logs on the hub.
 *
 * @async
 * @function
 * @param {string} serial - Controller serial number.
 * @param {Object} params - Parameters for setting local logs.
 * @param {function} onSuccess - Callback function to be executed on success.
 * @param {function} onError - Callback function to be executed on error.
 * @returns {Promise}
 *
 * @see { [hub.log.local.set](https://log.ezlo.com/new/hub/logging/#hubloglocalset) }
 */
export const set = async (serial, params, onSuccess, onError) =>
    await hubLogLocalSetApi(serial, params, onSuccess, onError);
