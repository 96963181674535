import React from 'react';

const IcEntryDelayFinish = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
            <path
                d="M27.0294 1C25.6568 2.37259 25.6569 4.59798 27.0295 5.97057C28.402 7.34315 30.6274 7.34314 32 5.97055L27.0294 1Z"
                fill="#1C1E21"
            />
            <path d="M12 1C12 3.20915 13.7909 5 16 5C18.2092 5 20 3.20915 20 1H12Z" fill="#1C1E21" />
            <path d="M16 5V17H28C28 10.3726 22.6274 5 16 5Z" fill="#1C1E21" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 30C23.1797 30 29 24.1797 29 17C29 9.8203 23.1797 4 16 4C8.8203 4 3 9.8203 3 17C3 24.1797 8.8203 30 16 30ZM16 27C21.5228 27 26 22.5228 26 17C26 11.4772 21.5228 7 16 7C10.4772 7 6 11.4772 6 17C6 22.5228 10.4772 27 16 27Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcEntryDelayFinish;
