import React from 'react';
import { Cloud } from 'lib/@mui/Icons';
import {
    ACTIONS_CAPABILITIES,
    ACTIONS_FRIENDLY_NAMES,
    CERTIFIED,
    COMMUNITY,
    CONFIGURE,
    CONFIGURE_NEW,
    IP_DEVICE_GENERATOR,
    MEDIA,
    NUCAL,
    PLACEMENT,
    PLUGIN,
    TEMPLATE,
    TRIGGERS_CAPABILITIES,
    TRIGGERS_FRIENDLY_NAMES,
    TURN_OFF,
    TURNED_OFF,
} from '../../../constants/Integrations';
import { TYPE } from '../../../constants/MeshbotConstant';
import { getEnrolmentNumber, getParjectNamesToFilter } from '../EzloRule/EditForm/RuleSettings/components/PAAS/utils';
import { CLOUD, LOCAL } from '../EzloMeshbot/constant';
import {
    getCountInstalledDevicesByIntegration,
    getDevicesWithVersion,
    getPluginsInstalledForIntegration,
} from '../EzloPlugins/utils';
import hash from '../../../constants/uniqueHash';
import * as plugin from '../../../constants/Plugins';
import { IS_FIRST_LOAD_INTEGRATIONS } from '../../../constants/localStorageKeys';
import { NUMBER } from '../../../constants/Plugins';
import { ControllerIcon } from '../../../assets/icons';
import { EZLO } from '../EzloRule/EditForm/RuleSettings/components/PAAS/paas-constants';
import {
    EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_CERTIFIED_TITLE,
    EZLOGIC_HEADING_DEVICES,
    EZLOGIC_TITLE_ACCOUNT,
    EZLOGIC_TITLE_COMMUNITY,
    EZLOGIC_TITLE_DEVICE,
    EZLOGIC_TITLE_UNASSIGNED,
    NUCAL_INTEGRATIONS_ACCOUNTS_TITLE,
} from '../../../constants/language_tokens';
import { getRoomsWithValidData, prepareDevices } from '../EzloDevices/utils';
import { ONE } from '../../../features/EzloPolicies/constants';
import { prepareGateways } from '../EzloDevice/utils';
import { SWITCH } from '../../../constants/SystemHistory';

export const getIntegrationTypeIcon = (integration) => {
    if (integration?.integrationType === NUCAL) {
        return (
            <>
                <Cloud />
                <span>{CLOUD}</span>
            </>
        );
    } else {
        return (
            <>
                <ControllerIcon />
                <span>{LOCAL}</span>
            </>
        );
    }
};
export const mergeIntegrations = (userIntegrations, detailedIntegrations) => {
    const mergedIntegrations = [];

    for (let i = 0; i < userIntegrations.length; i++) {
        const userIntegration = userIntegrations[i];

        const detailedIntegration = detailedIntegrations?.find((item) => item.uuid === userIntegration.uuid);
        if (detailedIntegration) {
            const mergedIntegration = {
                ...userIntegration,
                icon: detailedIntegration.icon,
                description: detailedIntegration.description,
            };
            mergedIntegrations.push(mergedIntegration);
        }
    }

    return mergedIntegrations;
};

export const preparePlugins = (marketplacePlugins, installedPlugins) => {
    const res = [];

    for (let i = 0; i < installedPlugins.length; i++) {
        const installedPlugin = installedPlugins[i];

        const marketplacePlugin = marketplacePlugins?.find(
            (plugin) =>
                plugin?.meta?.config?.id === installedPlugin?.plugin &&
                plugin?.meta?.config?.id !== IP_DEVICE_GENERATOR,
        );
        if (marketplacePlugin) {
            const currentPlugin = {
                ...marketplacePlugin,
            };
            res.push(currentPlugin);
        }
    }

    return res;
};
export const addTypeToObjects = (nucalArray = [], pluginsArray = [], devicesArray = []) => {
    const result = [];

    if (nucalArray) {
        for (const obj of nucalArray) {
            const newObj = { ...obj, integrationType: NUCAL };
            result.push(newObj);
        }
    }

    if (pluginsArray) {
        for (const obj of pluginsArray) {
            const newObj = { ...obj, integrationType: PLUGIN };
            result.push(newObj);
        }
    }

    if (devicesArray) {
        for (const obj of devicesArray) {
            const newObj = { ...obj, integrationType: TEMPLATE };
            result.push(newObj);
        }
    }

    return result;
};

export const isPluginInstalled = (listInstalledPlugins, serial, integration) => {
    return listInstalledPlugins[serial]?.filter(
        (item) => item.plugin === integration.meta?.config?.id || item.plugin === integration,
    )?.length;
};
// TODO check how and where we update nodes
// TODO Check do we listen broadcasts after install?
export const getUniqueArrayOfInstalledPlugins = (listInstalledPlugins = {}, listOfInstalledDevices = []) => {
    const result = {};
    for (const array of Object.values(listInstalledPlugins)) {
        if (array) {
            for (const obj of array) {
                if (isInstalledWithPlugin(listOfInstalledDevices, obj)) {
                    result[obj.plugin] = result[obj.plugin] || obj;
                }
            }
        }
    }

    return Object.values(result);
};

export const isInstalledWithPlugin = (listOfInstalledDevices = [], obj) => {
    return listOfInstalledDevices.find((device) => device?.gateway?.pluginId === obj?.plugin);
};

export const prepareDeviceIntegrations = (templates, devices) => {
    const result = [];
    for (let i = 0; i < devices.length; i++) {
        const deviceInstalled = devices[i];
        const deviceTemplateMarketplace = templates?.find(
            (template) => template?.uuid === deviceInstalled?.info?.template,
        );

        if (deviceTemplateMarketplace) {
            result.push(deviceTemplateMarketplace);
        }
    }

    return result;
};
export const prepareInformationAboutIntegration = (integration = {}) => {
    return Object.keys(integration)
        .filter((key) => key !== PLACEMENT && key !== TYPE)
        .map((key) => ({ [key]: integration[key]?.text || integration[key] }))
        .reduce((prev, current) => ({ ...prev, ...current }), {});
};

export const filterMarketplacePlugins = (marketplacePlugins) => {
    return marketplacePlugins?.filter((plugin) => plugin?.meta?.config?.id !== IP_DEVICE_GENERATOR);
};

export const filterNucalIntegrations = (integrations) => {
    const parjectsToFilter = getParjectNamesToFilter();

    return Object.values(integrations)
        .filter(({ name }) => !parjectsToFilter.includes(name))
        .sort((a, b) => {
            return getEnrolmentNumber(b.name) - getEnrolmentNumber(a.name);
        });
};

export const removeUnderscore = (str = '') => {
    const words = str.split('_');

    return words.join(' ');
};

export const SLIDER_SETTINGS = {
    dots: true,
    infinite: false,
    focusOnSelect: true,
    accessibility: false,
    lazyLoad: true,
};

export const currentTemplateData = (template) => {
    return JSON.parse(template?.data);
};

export const getInstalledPlugin = (listInstalledPlugins, integration) => {
    return Object.values(getPluginsInstalledForIntegration(listInstalledPlugins, integration)).find((value) => value);
};

export const getUniqueObjectsFromArray = (array) => {
    const uniqueObjects = [];

    for (const obj of array) {
        const isUnique = !uniqueObjects.some((uniqueObj) => uniqueObj.data === obj.data);

        if (isUnique) {
            uniqueObjects.push(obj);
        }
    }

    return uniqueObjects;
};

export const integrationIcon = async (src) => {
    return new Promise((resolve) => {
        const img = new Image();
        img.src = src;

        img.onload = () => resolve(true);
        img.onerror = () => resolve(false);
    });
};

export const getParamsForCreatingDeviceByTemplate = ({
    deviceName,
    macAddress,
    ipAddress,
    port,
    userName,
    password,
    uuid,
    data,
    currentTemplate,
    capabilities,
}) => {
    return {
        device_name: deviceName,
        mac_address: macAddress,
        ip_address: ipAddress,
        port: port,
        username: userName,
        password: password,
        template: {
            uuid: uuid,
            model: data?.config?.model || JSON.parse(currentTemplate?.data)?.config?.model,
            device_type: data?.config?.device_type || JSON.parse(currentTemplate?.data)?.config?.device_type,
            category: data?.config?.category || JSON.parse(currentTemplate?.data)?.config?.category,
            subcategory: data?.config?.subcategory || JSON.parse(currentTemplate?.data)?.config?.subcategory,
            capabilities,
        },
    };
};
export const getPluginIntegration = ({ marketplacePlugins, marketplaceWifiTemplates, uuid }) => {
    return (
        marketplacePlugins.find((plugin) => plugin.uuid === uuid) ||
        marketplaceWifiTemplates.find((template) => template.uuid === uuid)
    );
};
export const getConfigureInputs = ({ configuration }) => {
    return configuration?.inputs?.map((item) => {
        const data = {
            id: hash(),
            name: item.field,
            type: item.type,
            required: item.required,
            label: item.name,
            description: item.description,
        };

        if (item.hasOwnProperty(plugin.ENUM)) {
            data.enumList = item.enum;
        }

        return data;
    });
};

export const getFieldsForPlugin = ({ fields }) => {
    return fields?.reduce((acc, field) => {
        return { ...acc, [field]: '' };
    }, {});
};

export const getInputsValues = ({ inputs, name, type, value }) => {
    return {
        ...inputs,
        [name]: type === NUMBER && value ? Number(value) : type === NUMBER && !value ? '' : value,
    };
};
export const setIsFirstLoadIntegrations = (state) => {
    return localStorage.setItem(IS_FIRST_LOAD_INTEGRATIONS, JSON.stringify(state));
};
export const getTriggersFromSupportedItems = (devicesArr = []) => {
    return devicesArr
        ?.filter((device) => TRIGGERS_CAPABILITIES.some((key) => device.supportedItems.includes(key)))
        .flatMap((device) => {
            const filteredCapabilitiesArray = filteredArray(device.supportedItems, TRIGGERS_CAPABILITIES);
            const actions = filteredCapabilitiesArray.map((key) => ({
                ...device,
                deviceFriendlyName: TRIGGERS_FRIENDLY_NAMES[key],
                id: hash(),
            }));

            if (device.supportedItems.includes(SWITCH)) {
                actions.push({
                    ...device,
                    deviceFriendlyName: TURNED_OFF,
                    id: hash(),
                });
            }

            return actions;
        });
};
export const getActionsFromSupportedItems = (devicesArr = []) => {
    return devicesArr
        ?.filter((device) => ACTIONS_CAPABILITIES.some((key) => device.supportedItems.includes(key)))
        .flatMap((device) => {
            const filteredCapabilitiesArray = filteredArray(device.supportedItems, ACTIONS_CAPABILITIES);
            const actions = filteredCapabilitiesArray.map((key) => {
                return {
                    ...device,
                    deviceFriendlyName: ACTIONS_FRIENDLY_NAMES[key],
                    id: hash(),
                };
            });

            if (device.supportedItems.includes(SWITCH)) {
                actions.push({
                    ...device,
                    deviceFriendlyName: TURN_OFF,
                    id: hash(),
                });
            }

            return actions;
        });
};
const filteredArray = (firstArray, secondArray) => firstArray.filter((item) => secondArray.includes(item));
export const isValueIncludeInObject = (data = {}, valueToFind) => {
    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            const objectsArray = data[key] || [];
            for (const object of objectsArray) {
                if (object.plugin === valueToFind) {
                    return true;
                }
            }
        }
    }

    return false;
};
export const getPrefixText = (integrationType, integration, t) => {
    if (integrationType === NUCAL || integration?.meta?.prefix === EZLO) {
        return t(EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_CERTIFIED_TITLE);
    } else {
        return t(EZLOGIC_TITLE_COMMUNITY);
    }
};

export const getPrefixType = (integrationType, integration) => {
    if (integrationType === NUCAL || integration?.meta?.prefix === EZLO) {
        return CERTIFIED;
    } else {
        return COMMUNITY;
    }
};

export const getRoomName = ({ roomId, roomsListBySerial, t }) => {
    roomId ? getRoomName(getRoomsWithValidData({ roomsListBySerial })) : t(EZLOGIC_TITLE_UNASSIGNED);
};

export const getCurrentIpTemplateIntegration = ({ privateWifiTemplates, marketplaceWifiTemplates, uuid }) => {
    return (
        privateWifiTemplates.find((template) => template.uuid === uuid) ||
        marketplaceWifiTemplates.find((template) => template.uuid === uuid)
    );
};
export const replaceConfigureToNewConfigure = (string) => {
    if (!string.includes(CONFIGURE_NEW)) {
        return string.replace(CONFIGURE, CONFIGURE_NEW);
    }

    return string;
};
export const getDeviceOrAccountNaming = (integrationType, count, t) => {
    if (integrationType === NUCAL) {
        return getNuCAlNaming(count, t);
    } else {
        return getDevicesNaming(count, t);
    }
};

export const getNuCAlNaming = (count, t) => {
    if (count === ONE) {
        return t(EZLOGIC_TITLE_ACCOUNT);
    }

    if (count >= ONE) {
        return t(NUCAL_INTEGRATIONS_ACCOUNTS_TITLE);
    }

    return '';
};
export const getDevicesNaming = (count, t) => {
    if (count === ONE) {
        return t(EZLOGIC_TITLE_DEVICE);
    }

    if (count >= ONE) {
        return t(EZLOGIC_HEADING_DEVICES);
    }

    return '';
};
export const getNuCalAccountsCount = (integrationsWithAbstracts, uuid) => {
    return Boolean(integrationsWithAbstracts?.[uuid]?.abstracts?.length)
        ? integrationsWithAbstracts?.[uuid]?.abstracts?.length
        : '';
};

export const getIntegrationDevicesCount = (integration, listInstalledPlugins, state, data, integrationType) => {
    return Boolean(
        getCountInstalledDevicesByIntegration(
            getDevicesWithVersion(prepareDevices(data), listInstalledPlugins, prepareGateways(state)),
            integration,
            integrationType,
        )?.length,
    )
        ? getCountInstalledDevicesByIntegration(
              getDevicesWithVersion(prepareDevices(data), listInstalledPlugins, prepareGateways(state)),
              integration,
              integrationType,
          )?.length
        : '';
};
export const getIpTemplateLogo = (currentIntegration) => {
    return JSON.parse(currentIntegration?.data)?.meta?.logo;
};

export const isConsistMedia = (logo) => {
    return !logo || logo.startsWith(MEDIA);
};

export const getSupportedDevicesWithId = (devices) => {
    return devices?.map((device) => {
        return { ...device, id: hash() };
    });
};

export const getNuCalCapabilitiesWithId = (paths) => {
    return Object.values(paths).map((capability) => {
        return { ...capability, id: hash() };
    });
};
