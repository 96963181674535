import {
    HUB_BACKUP_CREATE_PROGRESS,
    HUB_BACKUP_RESTORE_PROGRESS,
    HUB_INFO_CHANGED,
    HUB_ITEM_UPDATED,
    HUB_MODES_ALARMED,
    HUB_MODES_BYPASS_DEVICES_ADDED,
    HUB_MODES_BYPASS_DEVICES_REMOVED,
    HUB_MODES_SWITCHED,
    HUB_NETWORK_CHANGED,
    HUB_SOFTWARE_UPDATE_PROGRESS,
    HUB_USER_NOTIFICATION,
} from '../constants';
import {
    eventsParams,
    hubModesBypassDevicesAddedParams,
    hubModesBypassDevicesRemovedParams,
} from '../constants/config';
import { getHubItemUpdatedParams } from './getHubItemUpdatedParams';
import {
    getHubInfoChangedParams,
    getHubModesAlarmedParams,
    getHubModesSwitchedParams,
    getHubNetworkChangedParams,
    getProgressTypeMsgSubclassesParams,
} from './index';

/**
 * Retrieves the parameters for a specific event message subclass based on the provided subclass and result.
 *
 * @param {string} msg_subclass - The message subclass.
 * @param {Object} result - The result object containing information about the event.
 * @returns {AlertHistoryEventParams | undefined} The parameters for the event message subclass, or undefined if not found.
 */
export const getEventParamsByMsgSubclass = (msg_subclass, result) => {
    switch (msg_subclass) {
        case HUB_USER_NOTIFICATION:
            return eventsParams[msg_subclass][result?.type];
        case HUB_ITEM_UPDATED:
            return getHubItemUpdatedParams(result, msg_subclass);
        case HUB_NETWORK_CHANGED:
            return getHubNetworkChangedParams(result);
        case HUB_INFO_CHANGED:
            return getHubInfoChangedParams(result);
        case HUB_MODES_ALARMED:
            return getHubModesAlarmedParams(result);
        case HUB_MODES_SWITCHED:
            return getHubModesSwitchedParams(result);
        case HUB_MODES_BYPASS_DEVICES_REMOVED:
            return hubModesBypassDevicesRemovedParams;
        case HUB_MODES_BYPASS_DEVICES_ADDED:
            return hubModesBypassDevicesAddedParams;
        case HUB_SOFTWARE_UPDATE_PROGRESS:
        case HUB_BACKUP_RESTORE_PROGRESS:
        case HUB_BACKUP_CREATE_PROGRESS:
            return getProgressTypeMsgSubclassesParams(result, msg_subclass);
        default:
            return;
    }
};
