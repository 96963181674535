import { TIME_OF_DAY } from '../constants/MeshbotConstant';
import * as meshBot from '../constants/MeshbotConstant';

export const getMethodName = (blockOptions) => {
    return blockOptions.method?.name;
};

export const getMethodArgs = (blockOptions) => {
    const args = blockOptions?.method?.args;
    const { method } = blockOptions;
    let result = '';
    if (args?.hasOwnProperty('weekdays')) {
        result = args.weekdays;
    } else if (args?.hasOwnProperty('days')) {
        result = args.days;
    } else if (args?.hasOwnProperty('interval')) {
        result = args.interval;
    } else if (method?.name === 'isOnce') {
        result = method.name;
    } else if (method?.name === 'isDateRange') {
        result = method.name;
    } else {
        result = 'daily';
    }

    return result;
};

const validateRangeFieldValue = (fields, fieldName, fieldValue) => {
    const value = fields?.find((field) => field.name === fieldName)?.value;

    return value === fieldValue;
};

export const getFieldType = (fields) => {
    const valueFields = (data, field) => data.map((item) => item[field]);
    let result = '';
    const getNameField = valueFields(fields, meshBot.NAME);

    if (
        !getNameField.includes(meshBot.SUNRISE) &&
        !getNameField.includes(meshBot.SUNSET) &&
        fields[meshBot.INDEX_SELECTED_FIELDS_ELEMENT]?.type !== meshBot.HOURS_TIME_ARRAY
    ) {
        result = meshBot.IS_DATE;
    } else if (
        getNameField.includes(meshBot.SUNRISE) &&
        validateRangeFieldValue(fields, meshBot.RANGE_FIELD_NAME, meshBot.AFTER)
    ) {
        result = meshBot.SUN_STATE.AFTER_SUNRISE;
    } else if (
        getNameField.includes(meshBot.SUNRISE) &&
        (validateRangeFieldValue(fields, meshBot.RANGE_FIELD_NAME, meshBot.AT) ||
            !getNameField.includes(meshBot.RANGE_FIELD_NAME))
    ) {
        result = meshBot.SUNRISE;
    } else if (
        getNameField.includes(meshBot.SUNRISE) &&
        validateRangeFieldValue(fields, meshBot.RANGE_FIELD_NAME, meshBot.BEFORE)
    ) {
        result = meshBot.SUN_STATE.BEFORE_SUNRISE;
    } else if (
        getNameField.includes(meshBot.SUNSET) &&
        validateRangeFieldValue(fields, meshBot.RANGE_FIELD_NAME, meshBot.AFTER)
    ) {
        result = meshBot.SUN_STATE.AFTER_SUNSET;
    } else if (
        getNameField.includes(meshBot.SUNSET) &&
        (validateRangeFieldValue(fields, meshBot.RANGE_FIELD_NAME, meshBot.AT) ||
            !getNameField.includes(meshBot.RANGE_FIELD_NAME))
    ) {
        result = meshBot.SUNSET;
    } else if (
        getNameField.includes(meshBot.SUNSET) &&
        validateRangeFieldValue(fields, meshBot.RANGE_FIELD_NAME, meshBot.BEFORE)
    ) {
        result = meshBot.SUN_STATE.BEFORE_SUNSET;
    } else if (
        getNameField.includes(meshBot.TYPE) &&
        fields[meshBot.INDEX_SELECTED_FIELDS_ELEMENT]?.type === meshBot.HOURS_TIME_ARRAY
    ) {
        result = TIME_OF_DAY;
    }

    return result;
};

export const getFieldValues = (fields) => {
    const [, valueField] = fields;
    let result = [];

    if (valueField && valueField.name === 'time') {
        let value = '';

        if (valueField.value && Array.isArray(valueField.value)) {
            value = valueField.value[0].split(':');
        } else {
            value = valueField.value.split(':');
        }

        if (value) {
            value.map((item) => result.push(Number(item)));
        }
    }

    if (valueField && valueField.type === 'bool') {
        result = valueField.value ? '1' : '0';
    }

    return result;
};
