import { getItemTemplate, JSON_FUNCTION_PATTERN } from './template';
import { ITEM_CONTAINED_VALUE, ITEM_OF_RESULTS, RAW_JSON } from './constants';
import { STRING } from '../../../../../constants/MeshbotConstant';

export const getSelectedVariable = (blocks, existingVariables) => {
    const variableName = blocks && blocks[0]?.saveResult?.args?.name ? blocks[0]?.saveResult?.args?.name : null;

    if (variableName) {
        return existingVariables.find((variable) => {
            return variable.name === variableName;
        });
    }

    return '';
};

export const getSelectedSaveOutput = (blocks) => {
    return blocks && blocks[0] ? Boolean(blocks[0]?.saveResult) : false;
};

export const updateCodeSimpleValue = (value, propertyName, codeSimple, uuid) => {
    return codeSimple.map((item) => {
        if (item.uuid === uuid) {
            return {
                ...item,
                [propertyName]: value,
            };
        }

        return item;
    });
};

export const parseValueFromIncludeOrSliceFunction = (value) => {
    const matchResult = value.match(/(?:[(])(?:['"]?)([\w\s,-]*)(?:['"]?)(?:[)])/);

    return matchResult ? matchResult[1] : '';
};

export const parseValueJsonFunction = (value) => {
    const re = new RegExp(JSON_FUNCTION_PATTERN);

    return value.replace(re, '');
};

export const addItemToCodeArray = (cloudVariableState, itemKey, dropElement) => {
    const template = getItemTemplate();
    if (dropElement) {
        template.value = `${RAW_JSON}${dropElement.path}`;
        template.capability = dropElement.treeElementName;
    }

    const updatedCodeArray = [...cloudVariableState[itemKey], template];

    return {
        ...cloudVariableState,
        [itemKey]: updatedCodeArray,
    };
};

export const updateCloudVariableState = (cloudVariableState, itemKey, value, additionalKey, additionalValue) => {
    const additionalParam = additionalKey ? { [additionalKey]: additionalValue } : {};

    return {
        ...cloudVariableState,
        [itemKey]: value,
        ...additionalParam,
    };
};

export const convertCodeFromArray = (arrayOfItems) => {
    const regex = /["'](stringify\(raw_json.*?)['"](?=[",])/gm; // extract substring started with raw_json
    const wrapToJSON = JSON.stringify(arrayOfItems);
    const result = wrapToJSON.replaceAll(regex, '$1'); // remove double quotes around extracted substring

    return result;
};

export const generateCodeTestRunCommand = (rawJson, code) => {
    return {
        input: {
            context: {},
            raw_string: '',
            raw_json: {
                ...rawJson,
            },
        },
        code: code,
        language: 'javascript',
    };
};

export const createTemplateForSliceFunc = (from, to) => {
    return `raw_json.slice(${from}, ${to})`;
};

export const createTemplateForIncludeFunc = (word) => {
    return `raw_json.includes('${word}')`;
};

export const createTemplateForJsonPath = (value) => {
    return `raw_json${value}`;
};

export const hasVariableIntoBlacklist = (variableName, code_output_blacklist) => {
    if (!variableName || !code_output_blacklist) {
        return false;
    }

    return code_output_blacklist.includes(variableName);
};

export const getVariablesForTable = (response, code_output_blacklist) => {
    if (!response) {
        return [];
    }

    return response.map((item) => {
        const name = item[ITEM_OF_RESULTS];
        const hasVariableInBlacklist = hasVariableIntoBlacklist(name, code_output_blacklist);

        return {
            name,
            value: item[ITEM_CONTAINED_VALUE],
            hasVariableInBlacklist,
        };
    });
};

const isNotEmpty = (field) => {
    return Boolean(field);
};

const getIsValidFieldOfVariable = (variableData) => {
    const { capability, value } = variableData;
    const isValidCapability = isNotEmpty(capability);
    const isValidValue = isNotEmpty(value);

    return Boolean(isValidCapability && isValidValue);
};

const getIsEditingFields = (variableData, initialStateVariable, currentActionData) => {
    const initialVariableData = initialStateVariable?.variableData;
    const initialBlackListVariable = initialStateVariable?.currentActionData?.blackListVariable;
    const currentBlackListVariable = currentActionData.blackListVariable;
    const isEditingVariableData = JSON.stringify(variableData) !== JSON.stringify(initialVariableData);
    const isEditingBlackList = JSON.stringify(currentBlackListVariable) !== JSON.stringify(initialBlackListVariable);

    return isEditingVariableData || isEditingBlackList;
};

export const getIsSaveDisabled = (variableData, initialStateVariable, currentActionData) => {
    if (!variableData || !currentActionData) {
        return true;
    }
    const isValidFieldOfVariable = getIsValidFieldOfVariable(variableData);
    const isEditingFields = getIsEditingFields(variableData, initialStateVariable, currentActionData);

    return !Boolean(isValidFieldOfVariable && isEditingFields);
};

export const getNewBlackListOfVariable = (prevBlackList, checkedValue, variableName) => {
    if (!prevBlackList || !variableName) {
        return [];
    }

    let newBlackList = [...prevBlackList];
    if (checkedValue) {
        newBlackList = newBlackList.filter((item) => item !== variableName);
    } else {
        newBlackList.push(variableName);
    }

    return newBlackList;
};

export const getVariableValueAsStr = (value) => (typeof value !== STRING ? JSON.stringify(value) : value);

export const removeUnnecessaryDataAfterBody = (path) => {
    return path.replace(/(\.body)\.data/, '$1');
};
