import rootReducer from '../reducers';
import thunk from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';

import { MAX_DEVTOOLS_STATE_SIZE, LARGE_DATA_PLACEHOLDER, PRODUCTION_ENVIRONMENT } from './constants';

const middleware = [thunk];

export default function configureEzlogicStore(preloadedState) {
    const store = configureStore({
        reducer: rootReducer,
        middleware: [...middleware],
        devTools: process.env.NODE_ENV !== PRODUCTION_ENVIRONMENT && {
            maxAge: 20,
            stateSanitizer: (state) => {
                const sanitizedState = { ...state };

                if (sanitizedState.main && sanitizedState.main.integrationList) {
                    const integrationListSize = JSON.stringify(sanitizedState.main.integrationList).length;

                    if (integrationListSize > MAX_DEVTOOLS_STATE_SIZE) {
                        sanitizedState.main = {
                            ...sanitizedState.main,
                            integrationList: LARGE_DATA_PLACEHOLDER,
                        };
                    }
                }

                return sanitizedState;
            },
        },
        preloadedState,
    });

    window.app = {};
    window.app.store = store;

    return store;
}

export const store = configureEzlogicStore();
