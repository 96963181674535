import React from 'react';
import { useSelector } from 'react-redux';
import { getIconByEventType } from '../../../../utils';

import styles from '../../systemHistoryTable.module.scss';

const SystemHistoryEventTypeCell = ({ params }) => {
    const { serial, data } = useSelector((state) => state.ezlo);
    const devicesList = data?.[serial]?.devices;
    const { eventType, deviceId } = params;
    const icon = getIconByEventType(devicesList, deviceId, eventType);

    return (
        <span className={styles.cell}>
            {icon}
            {eventType}
        </span>
    );
};

export default SystemHistoryEventTypeCell;
