import React from 'react';

const IcPoliceRequest = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
            <path
                d="M27.9 11.5C23.3 9.9 19.1 7.3 15.6 4C12.2 7.3 8.00004 9.9 3.40004 11.5C2.10004 12 1.60004 13.5 2.40004 14.6L5.60004 18.8V21.8C7.20004 25.8 11.1 28.6 15.6 28.6C20.1 28.6 24 25.8 25.6 21.8V18.8L28.8 14.6C29.7 13.5 29.2 12 27.9 11.5ZM15.6 10.8C16.7 10.8 17.6 11.7 17.6 12.8C17.6 13.9 16.7 14.8 15.6 14.8C14.5 14.8 13.6 13.9 13.6 12.8C13.6 11.7 14.5 10.8 15.6 10.8ZM23.1 21.8H8.10004V18.8H23.1V21.8Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcPoliceRequest;
