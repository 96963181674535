export const VDM_FETCH_VIRTUAL_DEVICE_LIST = 'VDM_FETCH_VIRTUAL_DEVICE_LIST';
export const VDM_FETCH_VIRTUAL_DEVICE_LIST_ON_SUCCESS = 'VDM_FETCH_VIRTUAL_DEVICE_LIST_ON_SUCCESS';
export const VDM_FETCH_VIRTUAL_DEVICE_LIST_ON_ERROR = 'VDM_FETCH_VIRTUAL_DEVICE_LIST_ON_ERROR';

export const VDM_CREATE_VIRTUAL_DEVICE = 'VDM_CREATE_VIRTUAL_DEVICE';
export const VDM_CREATE_VIRTUAL_DEVICE_ON_SUCCESS = 'VDM_CREATE_VIRTUAL_DEVICE_ON_SUCCESS';
export const VDM_CREATE_VIRTUAL_DEVICE_ON_ERROR = 'VDM_CREATE_VIRTUAL_DEVICE_ON_ERROR';

export const VDM_EDIT_VIRTUAL_DEVICE = 'VDM_EDIT_VIRTUAL_DEVICE';
export const VDM_EDIT_VIRTUAL_DEVICE_ON_SUCCESS = 'VDM_EDIT_VIRTUAL_DEVICE_ON_SUCCESS';
export const VDM_EDIT_VIRTUAL_DEVICE_ON_ERROR = 'VDM_EDIT_VIRTUAL_DEVICE_ON_ERROR';

export const VDM_DELETE_VIRTUAL_DEVICE = 'VDM_DELETE_VIRTUAL_DEVICE';
export const VDM_DELETE_VIRTUAL_DEVICE_ON_SUCCESS = 'VDM_DELETE_VIRTUAL_DEVICE_ON_SUCCESS';
export const VDM_DELETE_VIRTUAL_DEVICE_ON_ERROR = 'VDM_DELETE_VIRTUAL_DEVICE_ON_ERROR';

export const VDM_FETCH_ABSTRACT_LIST_DETAILED = 'VDM_FETCH_ABSTRACT_LIST_DETAILED';
export const VDM_FETCH_ABSTRACT_LIST_DETAILED_ON_SUCCESS = 'VDM_FETCH_ABSTRACT_LIST_DETAILED_ON_SUCCESS';
export const VDM_FETCH_ABSTRACT_LIST_DETAILED_ON_ERROR = 'VDM_FETCH_ABSTRACT_LIST_DETAILED_ON_ERROR';

export const VDM_SEND_TEXT_COMMAND = 'VDM_SEND_TEXT_COMMAND';
export const VDM_SEND_TEXT_COMMAND_ON_SUCCESS = 'VDM_SEND_TEXT_COMMAND_ON_SUCCESS';
export const VDM_SEND_TEXT_COMMAND_ON_ERROR = 'VDM_SEND_TEXT_COMMAND_ON_ERROR';

export const VDM_SUBMIT_ABSTRACT_COMMAND = 'VDM_SUBMIT_ABSTRACT_COMMAND';
export const VDM_SUBMIT_ABSTRACT_COMMAND_ON_SUCCESS = 'VDM_SUBMIT_ABSTRACT_COMMAND_ON_SUCCESS';
export const VDM_SUBMIT_ABSTRACT_COMMAND_ON_ERROR = 'VDM_SUBMIT_ABSTRACT_COMMAND_ON_ERROR';

export const VDM_SELECT_VIRTUAL_DEVICE = 'VDM_SELECT_VIRTUAL_DEVICE';

export const VDM_SET_TOKEN = 'VDM_SET_TOKEN';

export const SET_VIRTUAL_DEVICE = 'SET_VIRTUAL_DEVICE';
export const SET_SAVED_VIRTUAL_DEVICE = 'SET_SAVED_VIRTUAL_DEVICE';
export const SET_IS_INSTALL_PLUGIN = 'SET_IS_INSTALL_PLUGIN';
