import React from 'react';

const IcDeviceSensorNoGasLeak = (props) => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28 30L30 28L26 24L23 21L18.2924 16.2924L13.0161 11.0161L11.364 9.36403L9.2775 7.2775L2 0L0 2L8.51285 10.5128C8.50229 12.1537 9.03123 13.8064 10.0997 15.2C9.8016 14.1567 9.77502 13.0639 10.02 12.02L12.2866 14.2866C12.4485 14.9241 12.7862 15.5207 13.2998 16C13.7891 16.4893 14.3742 16.819 14.9891 16.9891L19 21H4V24H22L28 30ZM27.9998 18H24L23.3771 17.3771C24.0048 16.6448 24.5123 15.8524 24.8998 15H27.9998V18ZM18.6998 10.7C19.4803 11.5362 19.8257 12.6522 19.7186 13.7186L14.9999 8.9999L15.9998 8L18.6998 10.7ZM15.9997 4.7L13.3498 7.34985L10.9998 4.99985L15.9997 0L21.3997 5.3C23.9997 7.9 24.2997 12.2 21.9997 15.1C22.4997 13.1 21.9997 10.7 20.3997 9.1L15.9997 4.7ZM7.1 15H4V18H9.2C8.3 17.1 7.6 16.1 7.1 15Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcDeviceSensorNoGasLeak;
