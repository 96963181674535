import { DEVICE_GROUP_TREE_ITEM } from '../../../../constants/DevicesGroups';

const setCurrentDevicesState = (draft, payload) => {
    const { newDeviceState, categoryId, subcategoryId } = payload;

    if (
        (draft.deviceGroupTreeState.categories[categoryId]._ui.state ===
            DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.INCLUDED ||
            draft.deviceGroupTreeState.categories[categoryId].subcategories[subcategoryId]._ui.state ===
                DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INCLUDED) &&
        newDeviceState === DEVICE_GROUP_TREE_ITEM.DEVICE.STATES.INHERITED
    ) {
        return DEVICE_GROUP_TREE_ITEM.DEVICE.STATES.INHERITED_INCLUDED;
    } else {
        return newDeviceState;
    }
};

export const updateDeviceGroupTreeStateByDevice = (draft, payload) => {
    const { newDeviceState, deviceId, categoryId, subcategoryId } = payload;

    if (
        newDeviceState ===
        draft.deviceGroupTreeState.categories[categoryId].subcategories[subcategoryId].devices[deviceId]._ui.state
    ) {
        return;
    }

    draft.deviceGroupTreeState.categories[categoryId].subcategories[subcategoryId].devices[deviceId]._ui.state =
        setCurrentDevicesState(draft, payload);
};

export const updateDeviceGroupTreeStateBySubcategory = (draft, payload) => {
    const { newSubcategoryState, categoryId, subcategoryId } = payload;

    if (
        newSubcategoryState === draft.deviceGroupTreeState.categories[categoryId].subcategories[subcategoryId]._ui.state
    ) {
        return;
    }

    draft.deviceGroupTreeState.categories[categoryId].subcategories[subcategoryId]._ui.state =
        draft.deviceGroupTreeState.categories[categoryId]._ui.state ===
            DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.INCLUDED &&
        newSubcategoryState === DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED
            ? DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED_INCLUDED
            : newSubcategoryState;

    const { devices } = draft.deviceGroupTreeState.categories[categoryId].subcategories[subcategoryId];
    if (Object.keys(devices).length) {
        for (const key in devices) {
            devices[key]._ui.state =
                newSubcategoryState === DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INCLUDED ||
                (newSubcategoryState === DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED &&
                    draft.deviceGroupTreeState.categories[categoryId]._ui.state ===
                        DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.INCLUDED)
                    ? DEVICE_GROUP_TREE_ITEM.DEVICE.STATES.INHERITED_INCLUDED
                    : DEVICE_GROUP_TREE_ITEM.DEVICE.STATES.INHERITED;
        }
    }
};

const setDevicesStateBySubcategory = (subcategory, newCategoryState) => {
    const { devices } = subcategory;

    if (Object.keys(devices).length) {
        for (const key in devices) {
            devices[key]._ui.state =
                newCategoryState === DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.INCLUDED
                    ? DEVICE_GROUP_TREE_ITEM.DEVICE.STATES.INHERITED_INCLUDED
                    : DEVICE_GROUP_TREE_ITEM.DEVICE.STATES.INHERITED;
        }
    }
};

export const updateDeviceGroupTreeStateByCategory = (draft, payload) => {
    const { newCategoryState, categoryId } = payload;

    if (newCategoryState === draft.deviceGroupTreeState.categories[categoryId]._ui.state) {
        return;
    }

    draft.deviceGroupTreeState.categories[categoryId]._ui.state = newCategoryState;

    const { subcategories } = draft.deviceGroupTreeState.categories[categoryId];
    for (const key in subcategories) {
        subcategories[key]._ui.state =
            newCategoryState === DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.INCLUDED
                ? DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED_INCLUDED
                : DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED;

        setDevicesStateBySubcategory(subcategories[key], newCategoryState);
    }
};
