import {
    EZLOGIC_TITLE_15_MINUTES,
    EZLOGIC_TITLE_2_HOURS,
    EZLOGIC_TITLE_30_MINUTES,
    EZLOGIC_TITLE_3_HOURS,
    EZLOGIC_TITLE_45_MINUTES,
    EZLOGIC_TITLE_4_HOURS,
    EZLOGIC_TITLE_5_HOURS,
    EZLOGIC_TITLE_60_MINUTES,
    EZLOGIC_TITLE_6_HOURS,
} from '../../../constants/language_tokens';

export const TIME_OPTIONS = [
    { label: EZLOGIC_TITLE_15_MINUTES, value: 15 },
    { label: EZLOGIC_TITLE_30_MINUTES, value: 30 },
    { label: EZLOGIC_TITLE_45_MINUTES, value: 45 },
    { label: EZLOGIC_TITLE_60_MINUTES, value: 60 },
    { label: EZLOGIC_TITLE_2_HOURS, value: 120 },
    { label: EZLOGIC_TITLE_3_HOURS, value: 180 },
    { label: EZLOGIC_TITLE_4_HOURS, value: 240 },
    { label: EZLOGIC_TITLE_5_HOURS, value: 300 },
    { label: EZLOGIC_TITLE_6_HOURS, value: 360 },
];
export const ALLOW_INSTALLER_ACCESS_DEADLINE_KVS_KEY = 'allow_installer_access_deadline';
export const ALLOW_INSTALLER_ACCESS_KVS_KEY = 'allow_installer_access';
export const LOCATION_TYPE = 'location';
export const ZERO_INT = 0;
export const SUCCESS = 1;
export const ZERO_STRING = '0';
export const ENABLE = '1';
export const DISABLE = '0';
export const NULL_VALUE = 'null';
export const MINUTES = 'minutes';
export const DATETIME_FORMAT_12HR_AMPM_ISO8601 = 'YYYY-MM-DD hh:mm A';
export const DATETIME_FORMAT_ISO8601_WITH_OFFSET = 'YYYY-MM-DDTHH:mm:ssZZ';
export const INSTALLER_LOGGED_IN_AS_RESIDENT = 'INSTALLER_LOGGED_IN_AS_RESIDENT';
export const UPDATE_INSTALLER_ACCESS_DEADLINE_DATA = 'UPDATE_INSTALLER_ACCESS_DEADLINE_DATA';
export const ACCESS_REVOKED = '/access-revoked';
export const CHECK_ACCESS_INTERVAL_TIMEOUT = 120000;
export const INSTALLER_ACCESS = 'installerAccess';
export const SECURITY_OEM = 501;
