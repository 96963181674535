/**
 * Another clever approach of writing reducers:
 *
 * export default function(state = initialState, action) {
 *   const actions = {
 *      [ACTION_TYPE]: () => [action.payload.data, ...state]
 *   };
 *
 *   return (_.isFunction(actions[action.type])) ? actions[action.type]() : state
 * }
 */

import * as types from '../constants/ActionTypes';

const defaultState = {
    isFetching: false,
    items: [],
};

export default function (state = defaultState, action) {
    const newState = { ...state };

    switch (action.type) {
        case types.REQUEST_EZLOS:
            newState.isFetching = true;

            return newState;
        case types.RECEIVE_EZLOS:
            newState.isFetching = false;
            newState.items = action.data;

            return newState;
        case types.UPDATE_EZLO_DISABLED:
            newState.items.find((item) => item.serial === action.serial).disabled = action.disabled;

            return newState;
        case types.REMOVE_EZLO: {
            return { ...state, items: state.items.filter((item) => item.serial !== action.serial) };
        }
        case types.UPDATE_EZLO_DESCRIPTION: {
            const itemToChange = newState.items.find((item) => item.serial === action.serial);
            itemToChange.description = action.description;

            return newState;
        }
        case types.UPDATE_EZLOS_PROPERTIES: {
            const itemToChange = newState.items.find((item) => item.serial === action.serial);
            itemToChange.properties = action.props;

            return newState;
        }
        case types.UPDATE_EZLOS_IMAGE: {
            const itemToChange = newState.items.find((item) => item.serial === action.serial);
            itemToChange.imageId = action.id;

            return newState;
        }
        case types.UPDATE_EZLO_CONNECTION: {
            const itemToChange = newState.items.find((item) => item.serial === action.serial);
            itemToChange.connected = action.connected;

            return newState;
        }
        case types.UPDATE_EZLOS:
            newState.items = action.ezlos;

            return newState;
        case types.LOGOUT: {
            return defaultState;
        }
        default:
            return state;
    }
}
