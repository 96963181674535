import { getDataFromUserIdentity } from '../../../containers/AuthorizationPages/Login/utils';
import {
    calculateRemainingLifetimeOfMmsToken,
    callWithRandomDelay,
    dismissTokensFlowToast,
    extractTokenFlowsState,
    isValidToken,
    validateAccountToSaveData,
} from '../utils';
import { setIsTokensObsolete, setTimeoutId, setToastId } from '../reducer';
import { showWarningToast } from '../components/WarningToast';
import {
    EZLOGIC_TITLE_TOKENS_FLOW_SUCCESS_MESSAGE,
    EZLOGIC_TITLE_TOKENS_FLOW_WARNING_MESSAGE,
} from 'constants/language_tokens';
import { isNumber } from 'lodash';
import { showSuccessToast } from 'components/Toast';
import { getJwtToken, getSetMmsTokens } from './thunks';
import { calculateTimestampInMs, ERROR_TYPES } from 'services/utilityService';

/**
 * Thunk creator which return Thunk that calls the provided thunk if the user is logged in, validating the account status.
 *
 * @param {Function} thunk - The thunk to be called if the user is logged in.
 * @returns {Function} A Redux thunk.
 */
const callIfLoggedIn = (thunk) => async (dispatch, getState) => {
    validateAccountToSaveData(getState());

    return await dispatch(thunk);
};
/**
 * Thunk creator which return Thunk that updates MMS tokens, handling recursive token retrieval and warnings.
 *
 * @param {number} tokenValidUntil - The timestamp when the token is valid until.
 * @returns {Function} A Redux thunk.
 */
const updateMmsTokens = (tokenValidUntil) => async (dispatch) => {
    try {
        const getMmsTokensThunk = callIfLoggedIn(getSetMmsTokens);
        const { Identity } = (await dispatch(getMmsTokensThunk)) || {};
        const { Expires } = getDataFromUserIdentity(Identity);

        return Expires;
    } catch (e) {
        // Abort recursive token retrieval if we get an access closure error
        if (e.type === ERROR_TYPES.ACCESS_CLOSED) {
            return;
        }
        dispatch(handleInvalidToken(tokenValidUntil));
        const updateMmsTokensCb = async () => await dispatch(updateMmsTokens(tokenValidUntil));

        // Repeat the request with random duration between 1-20 seconds
        return await callWithRandomDelay(updateMmsTokensCb);
    }
};
/**
 * Thunk creator which return Thunk that handles invalid tokens, shows warnings, and sets tokens to obsolete if needed.
 *
 * @param {number} tokenValidUntil - The timestamp when the token is valid until.
 * @returns {Function} A Redux thunk.
 */
const handleInvalidToken = (tokenValidUntil) => (dispatch, getState) => {
    if (isValidToken(tokenValidUntil)) {
        return;
    }
    const { showedToastId, isTokensObsolete } = extractTokenFlowsState(getState());
    if (showedToastId) {
        return;
    }
    const onClose = () => dispatch(setToastId(null));
    const toastId = showWarningToast(EZLOGIC_TITLE_TOKENS_FLOW_WARNING_MESSAGE, onClose);
    dispatch(setToastId(toastId));
    if (!isTokensObsolete) {
        dispatch(setIsTokensObsolete(true));
    }
};
/**
 * Thunk creator which return  Thunk that updates JWT token, handling recursive token retrieval and warnings.
 *
 * @param {number} jwtTokenExpires - The timestamp when the token is valid until.
 * @param {number} updatedMmsTokensExpires - The timestamp when the updated mms tokens is valid until, and we can use it to update jwt token.
 * @returns {Function} A Redux thunk.
 */
const updateJwtToken = (jwtTokenExpires, updatedMmsTokensExpires) => async (dispatch) => {
    try {
        // If the mms tokens are not valid, and we cannot use them to update the jwt token then we start updating
        // all tokens from the beginning, first mms and after jwt
        if (!isValidToken(updatedMmsTokensExpires)) {
            dispatch(updateAllTokens(updatedMmsTokensExpires));

            return;
        }
        const getJwtTokenThunk = callIfLoggedIn(getJwtToken);

        return await dispatch(getJwtTokenThunk);
    } catch (e) {
        // Abort recursive token retrieval if we get an access closure error
        if (e.type === ERROR_TYPES.ACCESS_CLOSED) {
            return;
        }
        dispatch(handleInvalidToken(jwtTokenExpires));
        const updateJwtTokenCb = async () => await dispatch(updateJwtToken(jwtTokenExpires, updatedMmsTokensExpires));

        // Repeat the request with random duration between 1-20 seconds
        return await callWithRandomDelay(updateJwtTokenCb);
    }
};
/**
 * Thunk creator which return Thunk that handles updated tokens, dismisses warnings, shows success toasts, and triggers token flows.
 *
 * @param {number} updatedTokensExpires - The timestamp when the updated tokens are valid until.
 * @returns {Function} A Redux thunk.
 */
const handleUpdatedTokens = (updatedTokensExpires) => (dispatch, getState) => {
    const { showedToastId, isTokensObsolete } = extractTokenFlowsState(getState());
    // Closing the warning toast that the tokens are obsolete.
    dismissTokensFlowToast(showedToastId);
    // If we updated tokens during the period when our tokens were obsolete,
    // we show the toaster that we updated successfully the tokens and change isTokensObsolete to 'false'
    if (isTokensObsolete) {
        dispatch(setIsTokensObsolete(false));
        showSuccessToast(EZLOGIC_TITLE_TOKENS_FLOW_SUCCESS_MESSAGE);
    }

    // Run tokens flow if we have updatedTokensExpires .
    if (updatedTokensExpires && isNumber(updatedTokensExpires)) {
        dispatch(runTokensFlow(updatedTokensExpires));
    }
};
/**
 * Thunk creator which return Thunk that updates both MMS and JWT tokens recursively until successful, handling the result.
 *
 * @param {number} tokensExpires - The timestamp when the tokens are valid until.
 * @returns {Function} A Redux thunk.
 */
const updateAllTokens = (tokensExpires) => async (dispatch) => {
    // Update mms tokens until we update them(recursive update)
    const updatedTokensExpires = await dispatch(updateMmsTokens(tokensExpires));
    // Update jwt token until we update it(recursive update)
    const jwtToken = await dispatch(updateJwtToken(tokensExpires, updatedTokensExpires));

    if (updatedTokensExpires && jwtToken) {
        // Process the result of updating all tokens
        dispatch(handleUpdatedTokens(updatedTokensExpires));
    }
};
/**
 * Thunk creator Thunk that runs the tokens flow, updating all tokens based on the provided expiration timestamp.
 *
 * @param {number} expires - The timestamp when the tokens are valid until.
 * @returns {Function} A Redux thunk.
 */
export const runTokensFlow = (expires) => (dispatch) => {
    const remainingLifetime = calculateRemainingLifetimeOfMmsToken(expires);

    if (!isNumber(remainingLifetime) && !isNumber(expires)) {
        return;
    }

    const tokensFlowTimeoutId = setTimeout(() => {
        dispatch(updateAllTokens(expires));
    }, calculateTimestampInMs(remainingLifetime));
    dispatch(setTimeoutId(tokensFlowTimeoutId));
};
