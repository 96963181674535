/**
 * Another clever approach of writing reducers:
 *
 * export default function(state = initialState, action) {
 *   const actions = {
 *      [ACTION_TYPE]: () => [action.payload.data, ...state]
 *   };
 *
 *   return (_.isFunction(actions[action.type])) ? actions[action.type]() : state
 * }
 */

import * as types from '../constants/ActionTypes';
import * as groupTypes from '../constants/ActionTypes/EzloGroup';
import * as controllerTypes from '../constants/ActionTypes/Controller';
import atDevice from '../constants/ActionTypes/Devices';
import { associationConstants } from '../constants/ActionTypes/Associations';
import meshbot from '../constants/ActionTypes/MeshBot';
import { isNumber, uniqueId } from 'lodash';
import update from 'immutability-helper';
import hash from '../constants/uniqueHash';
import { getMethodName, getMethodArgs, getFieldType } from '../constants/MethodBlock';
import blockActionTemplate from '../components/blockActionTemplate';
import { SETTING_OPERATION_REMOVED } from '../constants/Devices';
import { HUB_GATEWAY_UPDATED_STATUSES } from '../constants/Gateway';
import { isChangedHotzones } from '../containers/Ezlo/EzloMeshbot/utils';
import atDeviceGroups from '../constants/ActionTypes/DeviceGroups';
import * as itemGroups from '../constants/ActionTypes/ItemGroups';

const defaultState = {
    isConnecting: false,
    isConnected: false,
    serial: '', // selected controller serial
    data: {}, // controllers group
    sharedUsers: [],
    usersToShare: [],
    showReachableDevices: true,
    devicesSortMap: {},
    whatHappened: {
        startTime: null,
        endTime: null,
        startRecords: 0,
        limitRecords: 8,
        deviceFilter: [],
        roomsFilter: [],
        typeFilter: [],
        eventDataItems: {},
        _id: '',
    },
    selectedRuleBlock: {},
    expressionList: [],
    ruleTriggers: [],
    groupActions: [],
    currentBlocks: [],
    onlineControllerList: [],
};

// TODO: switch to immer
export default function (state = defaultState, action) {
    const newState = { ...state };
    switch (action.type) {
        case controllerTypes.GET_NEW_EZLO_DATA.success: {
            action.ezloNewData.properties.ezloLocation = state.data[action.serial]?.properties?.ezloLocation;
            const updatedDataEzlo = Object.assign(state.data[action.serial], action.ezloNewData);

            return update(state, {
                data: {
                    [action.serial]: { $set: updatedDataEzlo },
                },
            });
        }
        case controllerTypes.SET_ONLINE_CONTROLLER_LIST: {
            return update(state, {
                onlineControllerList: {
                    $set: action.onlineControllerList,
                },
            });
        }

        case atDevice.GET_SETTINGS_DEVICE.success: {
            const settingInd = state.data[action.serial].settings.findIndex((item) => item._id === action.setting._id);
            const settings = [...state.data[action.serial].settings];

            settings.splice(settingInd, 1, action.setting);

            return update(state, {
                data: {
                    [action.serial]: {
                        settings: { $set: settings },
                    },
                },
            });
        }

        case atDevice.GET_DEVICE_LIST.success: {
            return update(state, {
                data: {
                    [action.serial]: {
                        devices: { $set: action.devices },
                    },
                },
            });
        }

        case associationConstants.GET_SETTINGS.success: {
            return update(state, {
                data: {
                    [action.serial]: {
                        settings: { $set: action.setting },
                    },
                },
            });
        }

        case types.UPDATE_EZLO_DATA:
            return update(state, {
                data: {
                    [action.serial]: {
                        $set: action.data,
                    },
                },
            });
        case types.UPDATE_EZLO_EXPRESSIONS: {
            return update(state, {
                data: {
                    [action.serial]: {
                        expressions: { $set: action.expressions },
                    },
                },
            });
        }
        case types.UPDATE_FIRMWARE_VERSION:
            if (newState.data[action.serial]) {
                newState.data[action.serial].info.firmware = action.data.firmware;
                newState.data[action.serial].info.ezlo_firmware_version = action.data.firmware;
            }

            return newState;
        case types.UPDATE_FIRMWARE_PROGRESS:
            if (newState.data[action.serial]) {
                newState.data[action.serial].fw_update_status = action.data;
            }

            return newState;
        case types.SET_ITEM_VALUE: {
            const itemInd = state.data[action.serial].items.findIndex((item) => item._id === action._id);
            const items = [...state.data[action.serial].items];
            items.splice(itemInd, 1, {
                ...items[itemInd],
                value: action.value,
                valueFormatted: action.valueFormatted,
            });

            return {
                ...state,
                data: {
                    ...state.data,
                    [action.serial]: {
                        ...state.data[action.serial],
                        items,
                    },
                },
            };
        }
        case types.SET_ITEM_ENUM: {
            const item = newState.data[action.serial].items.find((item) => item._id === action._id);

            if (item) {
                item.enum = action.enum;
            }

            return newState;
        }
        case types.SET_ITEM_VALUE_SUFFIX: {
            const item = newState.data[action.serial].items.find((item) => item._id === action._id);

            if (item) {
                item.suffix = action.suffix;
            }

            return newState;
        }
        case types.SET_ITEM_REACHABLE: {
            const item = newState.data[action.serial].items.find((item) => item._id === action._id);

            if (item) {
                item.reachable = action.reachable;
            }

            return newState;
        }
        case types.SET_ITEM_DEFAULT: {
            const deviceItem = newState.data[action.serial].items.find((item) => item._id === action._id);
            const defaultItem = newState.data[action.serial].items.find(
                (item) => item.isDefault && item.deviceId === deviceItem.deviceId,
            );

            if (defaultItem) {
                defaultItem.isDefault = false;
            }

            if (deviceItem) {
                deviceItem.isDefault = true;
            }

            return newState;
        }

        case types.SET_DEVICE_REACHABLE: {
            const device = newState.data[action.serial].devices.find((device) => device._id === action._id);

            if (device) {
                device.reachable = action.reachable;
            }

            return newState;
        }
        case types.ADD_DEVICE: {
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.serial]: {
                        ...state.data[action.serial],
                        devices: [...state.data[action.serial].devices.concat(action.data)],
                    },
                },
            };
        }
        case types.REMOVE_DEVICE:
            newState.data[action.serial].devices = newState.data[action.serial].devices.filter(
                (device) => device._id !== action._id,
            );

            return newState;
        case types.ADD_ITEM:
            newState.data[action.serial].items.push(action.data);

            return newState;
        case types.UPDATE_ITEM:
            //Todo fix race conditions for update_item broadcast
            const itemId = newState.data[action.serial]?.items?.findIndex((item) => item._id === action.data._id);
            if (itemId && itemId !== -1) {
                const updateItems = newState?.data[action.serial]?.items[itemId];
                updateItems.value = action.data.value;

                if (updateItems && action.data.name && action.data.valueFormatted) {
                    updateItems.name = action.data.name;
                    updateItems.valueFormatted = action.data.valueFormatted;
                    newState.data[action.serial].items[itemId] = updateItems;
                }

                if (updateItems && updateItems.name === 'hotzones') {
                    const currentHotzones = updateItems.value;
                    const newHotzones = action.data.value;

                    if (isChangedHotzones(currentHotzones, newHotzones)) {
                        updateItems.value = action.data.value;
                        newState.data[action.serial].items[itemId] = updateItems;
                    }
                }
            }

            return newState;

        /**
         * Redux reducer case for handling the 'SET_UPDATED_ITEM' action.
         *
         * @param {Object} state - The current state.
         * @param {Object} action - The action object.
         * @param {string} action.type - The action type.
         * @param {string} action.serial - The serial number.
         * @param {Object} action.updatedItem - The updated item.
         * @param {string} action.item_id - The ID of the item to be updated.
         * @returns {Object} The updated state.
         */
        case types.SET_UPDATED_ITEM:
            const { serial, updatedItem, item_id } = action;
            const updatedItemIndex = state.data?.[serial]?.items?.findIndex((item) => item._id === item_id);

            if (!isNumber(updatedItemIndex) || !serial || !updatedItem) {
                return state;
            }

            return update(state, {
                data: {
                    [serial]: {
                        items: { [updatedItemIndex]: { $set: updatedItem } },
                    },
                },
            });

        case types.UPDATE_DEVICE:
            const deviceId = newState.data?.[action.serial]?.devices?.findIndex(
                (item) => item._id === action.device._id,
            );
            const currentDevice = newState.data[action.serial]?.devices?.[deviceId];
            const updatedDevice = { ...currentDevice, ...action.device };
            newState.data[action.serial].devices[deviceId] = updatedDevice;

            return newState;
        case types.REMOVE_ITEM:
            newState.data[action.serial].items = newState.data[action.serial].items.filter(
                (item) => item._id !== action._id,
            );

            return newState;

        case meshbot.UPDATE_MESHBOT:
            const ruleIndex = newState.data[action.serial].rules.findIndex((item) => item._id === action.data._id);
            const sceneIndex = newState?.data?.[action.serial]?.scenes.findIndex(
                (item) => item._id === action.data._id,
            );
            const newRule = newState.data[action.serial].rules[ruleIndex];
            if (newRule) {
                newRule.enabled = action.data.enabled;
                newRule.name = action.data.name;
                newState.data[action.serial].rules[ruleIndex] = newRule;
                newState.data[action.serial].scenes[sceneIndex] = action.data;
            }

            return newState;

        case meshbot.REMOVE_MESHBOT:
            newState.data[action.serial].rules = newState.data[action.serial].rules.filter(
                (item) => item._id !== action.id,
            );

            return newState;

        case types.UPDATE_SHOW_DEFAULT_ITEM: {
            const deviceItem = newState.data[action.serial].items.find((item) => item._id === action.itemId);

            if (deviceItem) {
                deviceItem.showInDashboard = action.showInDashboard;
            }

            return newState;
        }
        case types.UPDATE_GATEWAY: {
            if (newState.data[action.serial]) {
                const gateway =
                    newState.data[action.serial] &&
                    newState.data[action.serial].gateways.find((gateway) => gateway._id === action.gateway._id);
                if (action.gateway.result === HUB_GATEWAY_UPDATED_STATUSES.READY && newState.data[action.serial]) {
                    newState.data[action.serial].isConnected = true;
                }

                if (gateway) {
                    Object.assign(gateway, action.gateway);
                }
            }

            return newState;
        }
        case types.REMOVE_GATEWAY:
            newState.data[action.serial].gateways = newState.data[action.serial].gateways.filter(
                (gateway) => gateway._id !== action.gateway._id,
            );

            return newState;
        case types.ADD_GATEWAY:
            newState.data[action.serial].gateways.push(action.gateway);

            return newState;
        case types.SET_GATEWAY_BUSY: {
            const gateway = newState.data[action.serial].gateways.find((gateway) => gateway._id === action._id);

            if (gateway) {
                gateway.busy = action.busy;
            }

            return newState;
        }
        case types.SET_GATEWAY_DEVICE_MANAGE_STATE: {
            const gateway = newState.data.gateways.find((gateway) => gateway._id === action._id);

            if (gateway) {
                gateway.deviceManageState = action.state;
            }

            return newState;
        }
        case types.UPDATE_EZLO_PROPERTIES:
            return update(state, {
                data: {
                    [action.serial]: {
                        properties: {
                            // based on legacy code
                            $set: {
                                ...state.data[action.serial].properties,
                                ...action.props,
                            },
                        },
                    },
                },
            });
        case types.UPDATE_EZLO_DISABLED:
            return update(state, {
                data: {
                    [action.serial]: {
                        disabled: {
                            $set: action.disabled,
                        },
                    },
                },
            });
        case types.UPDATE_EZLO_NAME:
            return update(state, {
                data: {
                    [action.serial]: {
                        description: {
                            $set: action.name,
                        },
                    },
                },
            });
        case types.UPDATE_DEVICE_SETTINGS:
            const settings = [...state.data[action.serial].settings];
            const settingIndex = state.data[action.serial].settings.findIndex(
                (setting) => setting._id === action.props._id,
            );

            settings.splice(settingIndex, 1, {
                ...settings[settingIndex],
                ...action.props,
            });

            return update(state, {
                data: {
                    [action.serial]: {
                        settings: {
                            $set: settings,
                        },
                    },
                },
            });
        case types.UPDATE_DEVICE_DICTIONARY_SETTINGS:
            const { operation } = action.props;
            const deviceSetting = state.data[action.serial].settings.find(
                (setting) => setting._id === action.props._id,
            );
            const { value } = deviceSetting;
            const [element] = Object.keys(action.props.element);
            const elementValues = action.props.element[element].value;
            const hasDeviceSettings = value && Object.keys(value).length;

            if (hasDeviceSettings) {
                if (operation === SETTING_OPERATION_REMOVED) {
                    const [result] = Object.keys(value).filter((setting) => setting === element);
                    delete deviceSetting.value[result];
                } else {
                    value[element] = elementValues;
                }
            } else {
                deviceSetting.value = action.props.element[element];
            }

            return update(state, {
                data: {
                    [action.serial]: {
                        $set: {
                            // based on legacy code, todo: check
                            ...state.data[action.serial],
                            ...state.data[action.serial].settings,
                        },
                    },
                },
            });
        case types.UPDATE_DEVICE_NAME: {
            const device = newState.data[action.serial].devices.find((device) => device._id === action._id);

            if (device) {
                device.name = action.name;
            }

            return newState;
        }
        case types.UPDATE_DEVICE_ROOM: {
            const devices = [...state.data[action.serial].devices];
            const deviceIndex = state.data[action.serial].devices.findIndex((device) => device._id === action.deviceId);
            devices.splice(deviceIndex, 1, {
                ...devices[deviceIndex],
                roomId: action.roomId,
            });

            return update(state, {
                data: {
                    [action.serial]: {
                        devices: {
                            $set: devices,
                        },
                    },
                },
            });
        }
        case types.UPDATE_SHOW_IN_TEMPLATE: {
            const item = newState.data[action.serial].items.find((item) => item._id === action._id);

            if (item) {
                item.showInTemplate = action.visibility;
            }

            return newState;
        }
        case types.UPDATE_ITEM_LABEL: {
            const item = newState.data[action.serial].items.find((item) => item._id === action._id);

            if (item) {
                item.label = action.label;
            }

            return newState;
        }
        case types.UPDATE_ROOMS:
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.serial]: {
                        ...state.data[action.serial],
                        pages: action.rooms,
                    },
                },
            };
        case types.UPDATE_ITEMS:
            newState.data[action.serial].items = action.items || [];

            return newState;
        case types.DELETE_ROOM:
            newState.data[action.serial].pages = newState.data[action.serial].pages.filter(
                (page) => page._id !== action.roomId,
            );

            return newState;
        case types.REMOVE_EZLO:
            delete newState.data[action.serial];

            if (action.stayedController) {
                newState.serial = action.stayedController;
            } else {
                // eslint-disable-next-line
                newState.serial = Object.keys(newState.data)[0];
            }

            return newState;
        case types.UPDATE_TEMPERATURE_UNITS:
            newState.data[action.serial].temperatureUnits = action.temperatureUnits;

            return newState;
        case types.UPDATE_FAVORITES:
            return update(state, {
                data: {
                    [action.serial]: {
                        favorites: {
                            $set: action.favorites,
                        },
                    },
                },
            });
        case types.FAVORITE_DEVICE_ADDED:
            return update(state, {
                data: {
                    [action.serial]: {
                        favorites: {
                            devices: {
                                $set: newState.data[action.serial].favorites.devices.concat(action.deviceId),
                            },
                        },
                    },
                },
            });
        case types.FAVORITE_DEVICE_REMOVED:
            return update(state, {
                data: {
                    [action.serial]: {
                        favorites: {
                            devices: {
                                $set: newState.data[action.serial].favorites.devices.filter(
                                    (deviceId) => deviceId !== action.deviceId,
                                ),
                            },
                        },
                    },
                },
            });
        case types.FAVORITE_ITEM_ADDED:
            if (!newState.data[action.serial].favorites.find((favorite) => favorite.itemId === action.itemId)) {
                newState.data[action.serial].favorites = [
                    ...newState.favorites,
                    {
                        itemId: action.itemId,
                    },
                ];
            }

            return newState;
        case types.FAVORITE_ITEM_REMOVED:
            newState.data[action.serial].favorites = newState.data[action.serial].favorites.filter(
                (favorite) => favorite.itemId && favorite.itemId !== action.itemId,
            );

            return newState;
        case types.RESET_SELECTED_RULE: {
            newState.ruleTriggers = defaultState.ruleTriggers;
            newState.groupActions = defaultState.groupActions;

            return newState;
        }
        case types.RESET_SELECTED_RULE_BLOCK: {
            newState.selectedRuleBlock = defaultState.selectedRuleBlock;

            return newState;
        }
        // TODO: need to refactor
        case types.UPDATE_SELECTED_RULE: {
            const addTempIdToBlocks = (blocks, type) => {
                return blocks.map((block) => {
                    if (type === 'when') {
                        const newBlock = {
                            id: hash(),
                            not: false,
                            blocks: [block],
                            optionType: 'and',
                        };
                        if (getMethodName(block.blockOptions) === 'not') {
                            newBlock.not = true;
                            newBlock.blocks = [block.fields[0].value];
                            newBlock.optionType = block.optionType;
                        } else if (block.hasOwnProperty('blockName')) {
                            newBlock.not = block.not;
                            newBlock.blockName = block.blockName;
                            newBlock.blocks = block.fields;
                            newBlock.optionType = getMethodName(block.blockOptions);
                        } else if (block.hasOwnProperty('optionType')) {
                            newBlock.optionType = block.optionType;
                        }

                        return newBlock;
                    }

                    if (type === 'then') {
                        if (block && !block._tempId) {
                            block._tempId = hash();
                        }

                        return {
                            id: hash(),
                            _tempId: hash(),
                            selectedFieldTrigger: 'deviceState',
                            blocks: [block],
                        };
                    }
                    block._tempId = uniqueId('rule-block');

                    return block;
                });
            };

            const createTemplateForGroupItem = (blocks) => {
                const { devices } = action.data;

                return blocks.map((item) => {
                    const [block] = item.blocks;

                    if (devices.length > 0 && block.hasOwnProperty('blockId')) {
                        const id = getId(block);
                        const { name } = devices.find((item) => item.itemId === id);

                        return {
                            id: item.id,
                            not: item.not,
                            name: name,
                            firstBlock: block.blockId,
                            selectedFieldTrigger: 'deviceState',
                            blocks: item.blocks,
                        };
                    }

                    return {
                        id: item.id,
                        selectedFieldTrigger: 'dataAndTime',
                        selectedFieldDate: getMethodArgs(block.blockOptions),
                        selectedSpecificDate: getFieldType(block.fields),
                        blocks: item.blocks,
                    };
                });
            };

            const getId = (data) => data.fields[0].value;
            const ruleData = { ...action.data.rule };
            let newTriggers = [];
            let actionTriggers = [];

            if (ruleData.when) {
                ruleData.when = addTempIdToBlocks(ruleData.when, 'when');
            }

            if (ruleData.then) {
                ruleData.then = addTempIdToBlocks(ruleData.then, 'then');
            }

            if (ruleData.when) {
                newTriggers = ruleData.when.map((item) => {
                    const [block] = item.blocks;

                    if (item.hasOwnProperty('blockName')) {
                        return {
                            id: item.id,
                            type: 'group',
                            blockName: item.blockName,
                            not: item.not,
                            optionType: item.optionType,
                            blocks: createTemplateForGroupItem(item.blocks),
                        };
                    } else if (action.data.devices.length > 0 && block.hasOwnProperty('blockId')) {
                        const { devices } = action.data;
                        const id = getId(block);
                        const { name } = devices.find((item) => item.itemId === id);

                        return {
                            id: item.id,
                            not: item.not,
                            firstBlock: block.blockId,
                            selectedFieldTrigger: 'deviceState',
                            optionType: item.optionType,
                            blocks: item.blocks,
                            name: name,
                        };
                    }

                    return {
                        id: item.id,
                        selectedFieldTrigger: 'dataAndTime',
                        selectedFieldDate: getMethodArgs(block.blockOptions),
                        selectedSpecificDate: getFieldType(block.fields),
                        optionType: item.optionType,
                        blocks: item.blocks,
                    };
                });
            }

            if (ruleData.then) {
                actionTriggers = ruleData.then.map((item) => {
                    const [block] = item.blocks;
                    if (block.hasOwnProperty('_tempId')) {
                        const { items, devices } = state.data[action.data.serial];
                        const id = getId(block);

                        const name = devices.find((item) => item._id === id);

                        if (name) {
                            return {
                                id: item.id,
                                _tempId: hash(),
                                firstBlock: block._tempId,
                                selectedFieldTrigger: 'deviceState',
                                blocks: [
                                    blockActionTemplate(
                                        name.name,
                                        'device',
                                        'device',
                                        name._id,
                                        block.fields[1].value,
                                        [],
                                    ),
                                ],
                                name: name.name,
                            };
                        } else {
                            const deviceId = items.find((item) => item._id === id);
                            if (deviceId && deviceId.name) {
                                const name = devices.find((item) => item._id === deviceId.deviceId);

                                return {
                                    id: item.id,
                                    _tempId: hash(),
                                    firstBlock: block._tempId,
                                    selectedFieldTrigger: 'deviceState',
                                    blocks: [
                                        blockActionTemplate(
                                            name.name,
                                            deviceId.valueType,
                                            'item',
                                            deviceId._id,
                                            block.fields[1].value,
                                            deviceId && deviceId.enum ? deviceId.enum : [],
                                        ),
                                    ],
                                    name: name.name,
                                };
                            } else if (block && block.fields && block.fields[0].name === 'script') {
                                return {
                                    id: item.id,
                                    _tempId: hash(),
                                    firstBlock: block._tempId,
                                    selectedFieldTrigger: 'script',
                                    blocks: [
                                        blockActionTemplate(
                                            'script',
                                            'script',
                                            'script',
                                            '',
                                            block.fields[0].value,
                                            [],
                                        ),
                                    ],
                                    name: 'runCustomScript',
                                };
                            } else if (block && block.fields && block.fields[0].name === 'url') {
                                const params = {
                                    url: '',
                                    method: '',
                                    skipSecurite: false,
                                    credential: null,
                                    request: null,
                                };
                                if (block && block.fields && block.fields.length) {
                                    block.fields.map((item) => {
                                        if (item.name === 'url') {
                                            return (params.url = item.value);
                                        } else if (item.name === 'credential') {
                                            return (params.credential = item.value);
                                        } else if (item.name === 'request') {
                                            return (params.method = item.value);
                                        } else if (item.name === 'headers') {
                                            return (params.request = item.value);
                                        } else if (item.name === 'skipSecurite') {
                                            return (params.skipSecurite = item.value);
                                        }
                                    });
                                    // block.fields;
                                }

                                return {
                                    id: item.id,
                                    _tempId: hash(),
                                    firstBlock: block._tempId,
                                    selectedFieldTrigger: 'sendHttpRequest',
                                    blocks: [blockActionTemplate('url', 'url', 'url', '', params, [])],
                                    name: 'sendHttpRequest',
                                };
                            } else if (
                                block &&
                                block.blockOptions &&
                                block.blockOptions.method &&
                                block.blockOptions.method.name === 'sendCloudAbstractCommand'
                            ) {
                                // item.name, params.type, 'cloud', params.uuid, params, []
                                const params = {
                                    command: 'text_command',
                                    type: 'string',
                                    message: block.fields[3].value.text,
                                    uuid: block.fields[0].value,
                                };

                                return {
                                    id: item.id,
                                    _tempId: hash(),
                                    firstBlock: block._tempId,
                                    selectedFieldTrigger: 'sendCloudAbstractCommand',
                                    blocks: [
                                        blockActionTemplate(
                                            'cloud',
                                            'string',
                                            'cloud',
                                            block.fields[0].value,
                                            params,
                                            [],
                                        ),
                                    ],
                                    name: 'sendCloudAbstractCommand',
                                };
                            } else {
                                return {
                                    id: item.id,
                                    _tempId: hash(),
                                    firstBlock: block._tempId,
                                    selectedFieldTrigger: 'deviceState',
                                    blocks: [],
                                    name: 'sendCloudAbstractCommand',
                                };
                            }
                        }
                    }
                });
            }

            if (ruleData.when) {
                newState.ruleTriggers = newTriggers;
            }

            if (ruleData.then) {
                newState.groupActions = actionTriggers;
            }

            return newState;
        }
        case types.UPDATE_SELECTED_RULE_BLOCK:
            newState.selectedRuleBlock = { ...action.data };

            return newState;
        case types.UPDATE_SELECTED_RULE_BLOCK_FIELD:
            newState.selectedRuleBlock.fields.find((field) => field.name === action.fieldName).value =
                action.fieldValue;

            return newState;

        case types.ADD_RULE:
            newState.data[action.serial].rules.push(action.data);

            return newState;

        case types.REMOVE_RULE:
            return update(state, {
                data: {
                    [action.serial]: {
                        rules: {
                            $set: newState.data[action.serial].rules.filter((rule) => rule._id !== action._id),
                        },
                        scenes: {
                            $set: newState.data[action.serial].scenes.filter((scene) => scene._id !== action._id),
                        },
                    },
                },
            });
        case types.UPDATE_CURRENT_EZLO_CONNECTION:
            newState.data[action.serial].isConnected = action.connected;

            return newState;
        case types.UPDATE_EZLO_STATE: {
            const devices = [...state.data[action.serial].devices] || [];
            const deviceIndex = state.data[action.serial].devices.findIndex((device) => device._id === action.id);
            devices.splice(deviceIndex, 1, {
                ...devices[deviceIndex],
                armed: action.armed,
            });

            return update(state, {
                data: {
                    [action.serial]: {
                        devices: {
                            $set: devices,
                        },
                    },
                },
            });
        }

        // case at.CLEAR_FIELD_RULE_TRIGGERS.success:
        //     return update(state, {
        //         ruleTriggers: { $set: action.data },
        //     });
        // case at.UPDATE_TRIGGER.success:
        //     return update(state, {
        //         ruleTriggers: { $set: action.data },
        //     });
        // case at.UPDATE_TRIGGER_ACTION.success:
        //     return update(state, {
        //         groupActions: { $set: action.data },
        //     });
        // case at.UPDATE_FIELD_BLOCK.success:
        //     return update(state, {
        //         ruleTriggers: { $set: action.data },
        //     });
        // case at.UPDATE_FIELD_BLOCK_ACTION.success:
        //     return update(state, {
        //         groupActions: { $set: action.data },
        //     });

        case types.UPDATE_EZLO_LIST_SCENES:
            return update(state, {
                data: {
                    [action.serial]: {
                        rules: { $set: action.data },
                    },
                },
            });
        // todo: update: rename and move meshenes on top lvl => ezlo.meshenes
        case types.UPDATE_MESH_LIST_SCENES:
            return update(state, {
                data: {
                    $set: {
                        ...state.data,
                        globalScenes: {
                            ...state.data.globalScenes,
                            list: action.data,
                        },
                    },
                },
            });
        // todo: update: rename and move meshenes on top lvl => ezlo.meshenes
        case types.UPDATE_MESH_SCENES_ABSTRACTS:
            return update(state, {
                data: {
                    $set: {
                        ...state.data,
                        globalScenes: {
                            ...state.data.globalScenes,
                            abstracts: action.data,
                        },
                    },
                },
            });
        // todo: update: rename and move meshenes on top lvl => ezlo.meshenes
        case types.UPDATE_MESH_SCENES_CAPABILITIES:
            return update(state, {
                data: {
                    $set: {
                        ...state.data,
                        globalScenes: {
                            ...state.data.globalScenes,
                            capabilities: action.data,
                        },
                    },
                },
            });
        case groupTypes.CONNECT_EZLO_GROUP:
            return update(state, {
                isConnecting: { $set: true },
                isConnected: { $set: false },
            });
        case groupTypes.CONNECT_EZLO_GROUP_SUCCESS:
            return update(state, {
                isConnecting: { $set: false },
                isConnected: { $set: true },
            });
        case groupTypes.CONNECT_EZLO_GROUP_ERROR:
            return update(state, {
                isConnecting: { $set: false },
                isConnected: { $set: false },
            });
        case groupTypes.UPDATE_EZLO_GROUP_DATA:
            return update(state, {
                data: { $set: action.data },
            });
        case controllerTypes.CONNECT_EZLO_CONTROLLER:
            return update(state, {
                data: {
                    [action.serial]: {
                        isConnecting: { $set: true },
                        isConnected: { $set: false },
                    },
                },
            });
        case controllerTypes.CONNECT_EZLO_CONTROLLER_SUCCESS:
            return update(state, {
                data: {
                    [action.serial]: {
                        isConnecting: { $set: false },
                        isConnected: { $set: true },
                    },
                },
            });
        case controllerTypes.CONNECT_EZLO_CONTROLLER_ERROR:
            return update(state, {
                data: {
                    [action.serial]: {
                        isConnecting: { $set: false },
                        isConnected: { $set: false },
                    },
                },
            });
        case controllerTypes.UPDATE_CONTROLLER_DATA:
            return update(state, {
                data: {
                    [action.serial]: {
                        $set: {
                            ...state.data[action.serial],
                            ...action.data,
                        },
                    },
                },
            });
        case controllerTypes.SELECT_EZLO_CONTROLLER:
            return update(state, {
                serial: { $set: action.serial },
            });
        case groupTypes.DISCONNECT_EZLO_GROUP_SUCCESS:
            return defaultState;
        case types.LOGOUT:
            return defaultState;

        case controllerTypes.GET_BLOCK_DATA_LIST.success: {
            return update(state, {
                data: {
                    [action.serial]: {
                        blockDataList: { $set: action.data },
                    },
                },
            });
        }
        // case at.GET_EXPRESSION_LIST.success:
        //     return update(state, {
        //         expressionList: { $set: action.data }
        //     });
        // case at.UPDATE_EXPRESSION.success:
        //     return update(state, {
        //         ruleTriggers: { $set: action.data }
        //     });
        // case at.SAVE_DEVICE_BLOCKS.success:
        //     return update(state, {
        //         currentBlocks: { $set: action.data}
        //     });
        case atDeviceGroups.SET_DEVICE_GROUPS_LIST.success: {
            return update(state, {
                data: {
                    [action.serial]: {
                        deviceGroups: {
                            $set: {
                                deviceGroupsList: action?.data?.deviceGroups,
                            },
                        },
                    },
                },
            });
        }

        case atDeviceGroups.REMOVE_DEVICE_GROUP.success: {
            return update(state, {
                data: {
                    [action.serial]: {
                        deviceGroups: {
                            deviceGroupsList: {
                                $set: newState.data[action.serial].deviceGroups.deviceGroupsList.filter(
                                    (deviceGroup) => {
                                        return deviceGroup?._id !== action.id;
                                    },
                                ),
                            },
                        },
                    },
                },
            });
        }

        case itemGroups.FETCH_ITEM_GROUPS_LIST.success: {
            return update(state, {
                data: {
                    [action.serial]: {
                        itemGroups: {
                            $set: {
                                itemGroupsList: action?.data?.itemGroups,
                            },
                        },
                    },
                },
            });
        }

        case itemGroups.SET_ITEM_GROUP.success: {
            return update(state, {
                data: {
                    [action.serial]: {
                        itemGroups: {
                            itemGroupsList: { $push: [action.itemGroup] },
                        },
                    },
                },
            });
        }

        default:
            return state;
    }
}
