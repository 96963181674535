import { FILE, QUERY_PARAMS, REDIRECT_SUPPORT_VALUE } from '../constants/network';
import { SVG_FILE_FORMAT } from '../../../../features/MediaStoragePage/constants';
import * as localStorageKeys from 'constants/localStorageKeys';
import oemManager from '../../../oem';

/**
 * Determines extension of a file
 * @param {File} file - file
 * @returns {string} file extension (png or svg or other formats )
 * @example
 * getFileExtension({name: 'logo.png'})
 */
export function getFileExtension(file) {
    const fileName = file.name;
    const dotIndex = fileName.lastIndexOf('.');

    if (dotIndex === -1) {
        return '';
    }

    return fileName.substring(dotIndex + 1).toLowerCase();
}

/**
 * Build img link params
 * @param {string} uuid - img uuid
 * @param {string} key - img key
 * @param {string} file_format - file format
 * @returns {Object} Img link params
 * @example
 * getImgLinkParams('12hb', 'png')
 */
export const getImgLinkParams = (uuid, file_format, key) => {
    const cloudParams = { [QUERY_PARAMS.UUID]: uuid, [QUERY_PARAMS.KEY]: key };

    return file_format === SVG_FILE_FORMAT
        ? cloudParams
        : { ...cloudParams, [QUERY_PARAMS.REDIRECT_SUPPORT]: REDIRECT_SUPPORT_VALUE };
};

/**
 * Build img link params for download
 * @param {string} uuid - img uuid
 * @param {string} key - img key
 * @returns {Object} Img link params
 * @example
 * getDownloadImgLinkParams('12hb')
 */
export const getDownloadImgLinkParams = (uuid, key) => {
    return { [QUERY_PARAMS.UUID]: uuid, [QUERY_PARAMS.KEY]: key };
};

/**
 * Builds headers for a file upload request.
 *
 * @function
 * @param {Object} options - The options for building headers.
 * @param {File} options.file - The file being uploaded.
 * @param {string} [options.bucket='default'] - The bucket for the file upload (default is 'default').
 * @param {string} options.type - The type of the file upload.
 * @returns {Object} - The headers for the file upload request.
 */
export const buildFileUploadHeaders = ({ file, bucket = 'default', type }) => {
    const cloudToken = localStorage.getItem(localStorageKeys.CLOUD_TOKEN);
    const fileExtension = getFileExtension(file);

    return {
        headers: {
            'Authorization': `Bearer ${cloudToken}`,
            'X-APP': oemManager.oem.getXApp(),
            'p-bucket': bucket,
            'p-type': type,
            'p-file_format': fileExtension,
            'p-filename': file.name,
        },
    };
};

/**
 * Build file in FormData format
 * @param {File} file - file
 * @returns {Object} image file in FormData format
 * @example
 * buildFileFormData(file)
 */
export function buildFileFormData(file) {
    const multipartFormData = new FormData();
    multipartFormData.append(FILE, file);

    return multipartFormData;
}
