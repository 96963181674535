import Axios from 'axios';
import { API_CLOUD_EZLO_REQUEST_URL } from 'constants/URLs';
import { axiosCloudConfig } from 'api/config';
import { COM_HISTORY_LIST, SECOND_VERSION } from '../constants/network';
import { isArray } from 'lodash';
import { ERROR_STATUS } from 'constants/api';
import { EZLOGIC_TITLE_SOMETHING_WENT_WRONG } from 'constants/language_tokens';
import { t } from 'helpers/language';

/**
 * Builds the payload for the com_history_list call.
 *
 * @param {Object} params - Parameters for the API call.
 * @param {number} params.limit - Limit for the number of results.
 * @param {number} params.page - Page number for the results.
 * @param {string} params.<custom_param> - Additional parameters as needed.
 * @returns {Object} The payload object.
 * @see {[Doc](https://confluence.mios.com/pages/viewpage.action?spaceKey=EPD&title=Com+History+-+List+v.2)}
 */
const buildPayload = (params) => {
    return {
        call: COM_HISTORY_LIST,
        params: {
            limit: 100,
            ...params,
        },
        version: SECOND_VERSION,
    };
};

/**
 * Calls the com_history_list API to fetch the alert history list.
 *
 * @param {Object} params - Parameters for the API call.
 * @returns {Promise<Object>} A promise that resolves to object(response) containing the alert history list.
 * @throws {Error} If an error occurs during the API call.
 */
const getComHistoryListApi = (params) => {
    const payload = buildPayload(params);

    return Axios.post(API_CLOUD_EZLO_REQUEST_URL(), payload, axiosCloudConfig());
};

/**
 * Calls the com_history_list API to fetch the alert history list and .
 *
 * @param {Object} params - Parameters for the API call.
 * @returns {Promise<Array>} A promise that resolves to an array containing the alert history list.
 * @throws {Error} If an error occurs during the API call or the response is not as expected.
 */
export const getAlertHistoryList = async (params) => {
    const response = await getComHistoryListApi(params);

    if (response?.data?.status === ERROR_STATUS) {
        const errorMessage =
            response.data.data?.error_message ||
            response.data.data?.error_text ||
            t(EZLOGIC_TITLE_SOMETHING_WENT_WRONG);
        throw new Error(errorMessage);
    }

    if (!isArray(response?.data?.data?.range)) {
        throw new Error(t(EZLOGIC_TITLE_SOMETHING_WENT_WRONG));
    }

    return response.data.data.range;
};
