import React from 'react';

const IcDeviceSensorDoorSensor = (props) => {
    return (
        <svg width="24" height="30" viewBox="0 0 24 30" fill="#1C1E21" {...props}>
            <path d="M2.4 2.5C1 2.8 0 4 0 5.5V24.6C0 26 1 27.3 2.4 27.5L15 30V0L2.4 2.5ZM12 17C10.9 17 10 16.1 10 15C10 13.9 10.9 13 12 13V17ZM21 3C22.7 3 24 4.3 24 6V24C24 25.7 22.7 27 21 27H18V24.2H21C21 24.1 21 5.8 21 5.7H18V3H21Z" />
        </svg>
    );
};

export default IcDeviceSensorDoorSensor;
