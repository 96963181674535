import React from 'react';
import { useSelector } from 'react-redux';
import { Close as CloseIcon } from 'lib/@mui/Icons';
import { Dialog, IconButton, LinearProgress } from 'lib/@mui';

import { kvsIsLoadingSelector } from '../../../utils';

import styles from './styles.module.scss';

const LabelDialogContainer = ({ open, closeDialog, dialogTitle, children }) => {
    const { isLoading } = useSelector(kvsIsLoadingSelector);

    return (
        <Dialog className={styles.muiDialog} open={open} onClose={closeDialog} onClick={(e) => e.stopPropagation()}>
            {isLoading && <LinearProgress />}
            <div className={styles.dialogContainer}>
                <div className={styles.closeDialogIconBox}>
                    <IconButton onClick={closeDialog}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <div className={styles.dialogTitle}>{dialogTitle}</div>
                {children}
            </div>
        </Dialog>
    );
};

export default LabelDialogContainer;
