export const STORAGE_DELETE_CALL = 'storage_delete';
export const STORAGE_LIST_CALL = 'storage_list';
export const MULTIMEDIA_TYPE = 'multimedia';
export const MEDIA_BUCKET_NAME = 'media';
export const QUERY_PARAMS = {
    REDIRECT_SUPPORT: 'redirect_support',
    UUID: 'uuid',
    KEY: 'key',
};
export const REDIRECT_SUPPORT_VALUE = 1;
export const SUCCESS_API_STATUS_CODE = 200;
export const SUCCESS_API_STATUS = 1;
export const FILE = 'file';
