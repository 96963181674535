import { TOAST_TYPE } from '../components/Toast';
import { EDGE_PLUGINS_LINK, PLUGINS_SETTINGS_LINK, WIFI_GENERATOR_LINK } from '../containers/Navigation/constants';

const AUTO_CLOSE_TOAST_TIME_MS = 300000;

export const ERROR_UNTIL_ACKNOWLEDGED_OPTIONS = {
    type: TOAST_TYPE.ERROR,
    autoClose: AUTO_CLOSE_TOAST_TIME_MS,
    hideProgressBar: true,
};
export const SUCCESS_OPTIONS = {
    type: TOAST_TYPE.SUCCESS,
};
export const INFO_OPTIONS = {
    type: TOAST_TYPE.INFO,
};

export const PAGES_PATHS = [EDGE_PLUGINS_LINK, WIFI_GENERATOR_LINK, PLUGINS_SETTINGS_LINK];
