/**
 * Converts base64 data to a File object.
 *
 * @param {string} base64Data - The base64-encoded data.
 * @param {string} filename - The name to be given to the resulting File.
 * @param {string} [mimeType] - The MIME type of the data (optional).
 * @returns {File} - The File object created from the base64 data.
 */
export const base64toFile = (base64Data, filename, mimeType) => {
    mimeType = mimeType || (base64Data.match(/^data:([^;]+);/) || '')[1];
    const byteCharacters = atob(base64Data.split(',')[1]);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: mimeType });

    return new File([blob], filename, { type: mimeType });
};

/**
 * Crops an image based on pixel coordinates and converts it to a File object.
 *
 * @param {string} base64Data - The base64-encoded image data.
 * @param {Object} pixelCrop - The pixel coordinates for cropping.
 * @param {number} pixelCrop.width - The width of the cropped area.
 * @param {number} pixelCrop.height - The height of the cropped area.
 * @param {number} pixelCrop.x - The x-coordinate of the top-left corner of the cropped area.
 * @param {number} pixelCrop.y - The y-coordinate of the top-left corner of the cropped area.
 * @param {string} type - The MIME type of the image.
 * @param {string} name - The name to be given to the resulting File.
 * @returns {Promise<File>} - A promise that resolves with the cropped File object.
 */
export const cropImage = (base64Data, pixelCrop, type, name) => {
    const { width, height, x, y } = pixelCrop;

    return new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(image, -x, -y);
            resolve(base64toFile(canvas.toDataURL(), name, `${type}`));
        };
        image.onerror = reject;
        image.src = base64Data;
    });
};

/**
 * Handles the change of an image file, sets the image file state, and triggers related actions.
 *
 * @param {File} selectedFile - The selected image file.
 * @param {Function} setImageFile - Function to set the image file state.
 * @param {Function} setImage - Function to set the image state.
 * @param {Function} toggleOpenDialog - Function to toggle the open state of a dialog.
 * @param {Object} fileInputRef - Reference to the file input element.
 */
export const handleChangeImage = (selectedFile, setImageFile, setImage, toggleOpenDialog, fileInputRef) => {
    setImageFile(selectedFile);
    const reader = new FileReader();

    reader.onloadend = () => {
        if (reader.result) {
            setImage(reader.result);
        }
    };

    if (selectedFile) {
        reader.readAsDataURL(selectedFile);
    }
    toggleOpenDialog();
    if (fileInputRef.current) {
        fileInputRef.current.value = null;
    }
};
