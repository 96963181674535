import React from 'react';
import classNames from 'classnames';

const DropdownMenuItem = ({ children, label, className, baseClassName, active, ...otherProps }) => (
    <div
        {...otherProps}
        className={classNames(`${baseClassName}-menu-item`, className, {
            active: active,
        })}
    >
        {label && <span>{label}</span>}
        {children}
    </div>
);

// DropdownMenuItem.propTypes = {
//   onClick: PropTypes.func,
//   index: PropTypes.number,
//   label: PropTypes.any,
//   value: PropTypes.any,
//   baseClassName: PropTypes.string,
//   className: PropTypes.string
// };

export default DropdownMenuItem;
