import React from 'react';
import { SvgIcon } from 'lib/@mui';

export const ControllerIcon = (props) => (
    <SvgIcon color="primary" {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 0C1.3 0 0 1.3 0 3V12V21C0 22.7 1.3 24 3 24H21C22.7 24 24 22.7 24 21V12H3V0ZM12 16C13.1 16 14 16.9 14 18C14 19.1 13.1 20 12 20C10.9 20 10 19.1 10 18C10 16.9 10.9 16 12 16ZM24 3V9H21V0C22.7 0 24 1.3 24 3Z" />
    </SvgIcon>
);

export default ControllerIcon;
