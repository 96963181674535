import { EZLOGIC_TITLE_MY_INTEGRATIONS, EZLOGIC_TITLE_NEW_INTEGRATION } from './language_tokens';
import { SWITCH } from './SystemHistory';

export const MANAGE_PLUGINS = 'Manage plugins';
export const MANAGE_IP_TEMPLATES = 'Manage IP Templates';
export const MANAGE_CLOUD_INTEGRATIONS = 'Manage Cloud Integrations';
export const ADVANCED = 'Advanced';
export const INTEGRATION_INFO = '/ezlo/integration-info';
export const INTEGRATION_INFO_CREATE = '/ezlo/integration-info-create';
export const INTEGRATIONS = '/ezlo/integrations';
export const MY_INTEGRATIONS = '/ezlo/integrations/my-integrations';
export const NEW_INTEGRATIONS = '/ezlo/integrations/create-integration';
export const CREATE_INTEGRATION_DEVICE_FROM_PLUGIN = '/ezlo/create-integration-plugin-device';
export const CREATE_INTEGRATION_DEVICE_FROM_TEMPLATE = '/ezlo/create-integration-template-device';
export const CREATE_INTEGRATION_DEVICE_FROM_EXIST_TEMPLATE = '/ezlo/create-integration-exist-template-device';
export const NEW_INTEGRATION_TYPE = '/ezlo/create-integration-type';
export const PLUGIN = 'plugin';
export const NUCAL = 'nucal';
export const TEMPLATE = 'template';
export const IP_DEVICE_GENERATOR = 'ezlo.wifi_device_generator';
export const PLACEMENT = 'placement';
export const MANAGE_DROPDOWN_OPTIONS = [MANAGE_PLUGINS, MANAGE_IP_TEMPLATES, MANAGE_CLOUD_INTEGRATIONS];
export const MANAGE = 'Manage';
export const INSTALLED = 'installed';
export const EMPTY_INTEGRATION_ICON = '"../../../../../assets/icons/actions/ic_actions_ic-integration"';

export const CONFIGURE = 'configure';
export const DEVICE_MODEL = 'device_model';
export const DEVICE_NAME = 'device_name';
export const MAC_ADDRESS = 'mac_address';
export const MAC_ADDRESS_PLACEHOLDER = '00:00:5e:00:53:af';
export const IP_ADDRESS = 'ip_address';
export const IP_ADDRESS_PLACEHOLDER = '10.0.0.34';
export const PORT = 'port';
export const PORT_PLACEHOLDER = '80';
export const USERNAME = 'username';
export const NEW_PASSWORD = 'new-password';
export const END = 'end';
export const START = 'start';
export const OUTLINED = 'outlined';
export const BREAKPOINT_LAPTOP = 730;
export const BREAKPOINT_MOBILE = 500;
export const LAPTOP_SLIDERS_TO_SHOW = 2;
export const MOBILE_SLIDERS_TO_SHOW = 1;
export const LAPTOP_SLIDERS_TO_SCROLL = 2;
export const MOBILE_SLIDERS_TO_SCROLL = 1;
export const LAPTOP_SLIDERS_INITIAL = 2;
export const SLIDERS_TO_SHOW_BASIC = 4;
export const SLIDERS_TO_SCROLL_BASIC = 4;
export const SLIDERS_TO_SCROLL_ZERO = 0;
export const UPDATE = 'Update';
export const UNINSTALL = 'Uninstall';
export const CONFIGURE_UPPERCASE = 'Configure';
export const INTEGRATION_CONTAINER = 'integration-container';
export const TOGGLE_OPTIONS = [EZLOGIC_TITLE_MY_INTEGRATIONS, EZLOGIC_TITLE_NEW_INTEGRATION];
export const ADD_NEW_INTEGRATION = 'ADD_NEW_INTEGRATION';
export const ADD_NEW_DEVICE = 'ADD_NEW_DEVICE';
export const MY_INTEGRATIONS_TAB = 0;
export const NEW_INTEGRATIONS_TAB = 1;
export const DIMMER = 'dimmer';
export const RGB_COLOR = 'rgbcolor';
export const RGB_COLOR_DOWN = 'rgbcolor_down';
export const RGB_COLOR_UP = 'rgbcolor_up';
export const RGB_COLOR_STOP = 'rgbcolor_stop';
export const DIMMER_DOWN = 'dimmer_down';
export const DIMMER_UP = 'dimmer_up';
export const DIMMER_STOP = 'dimmer_stop';
export const X_EVENTS = 'x-events';
export const THERMOSTAT_FAN_STATE = 'thermostat_fan_state';
export const THERMOSTAT_FAN_MODE = 'thermostat_fan_mode';
export const TEMP = 'temp';
export const THERMOSTAT_SETPOINT_HEATING = 'thermostat_setpoint_heating';
export const THERMOSTAT_SETPOINT_COOLING = 'thermostat_setpoint_cooling';
export const THERMOSTAT_SETPOINT = 'thermostat_setpoint';
export const THERMOSTAT_MODE = 'thermostat_mode';
export const SOUND_PLAYBACK = 'sound_playback';
export const SCENE = 'scene';
export const COLOR_TEMPERATURE = 'color_temperature';
export const ECO = 'eco';
export const POWER = 'power';
export const MUTE = 'mute';
export const MOTION = 'motion';
export const COLOR_MODE = 'color_mode';
export const INPUT = 'input';
export const HUMIDITY = 'humidity';
export const CO2_LEVEL = 'co2_level';
export const CO2_ALARM = 'co2_alarm';
export const CONFIGURE_NEW = 'configure_new';
export const SOUND_VOLUME = 'sound_volume';

export const TRIGGERS_CAPABILITIES = [
    SWITCH,
    DIMMER,
    RGB_COLOR,
    SOUND_VOLUME,
    THERMOSTAT_MODE,
    THERMOSTAT_FAN_STATE,
    THERMOSTAT_FAN_MODE,
    TEMP,
    THERMOSTAT_SETPOINT_HEATING,
    THERMOSTAT_SETPOINT_COOLING,
    THERMOSTAT_SETPOINT,
    SOUND_PLAYBACK,
    SCENE,
    POWER,
    MUTE,
    MOTION,
    COLOR_TEMPERATURE,
    COLOR_MODE,
    INPUT,
    HUMIDITY,
    ECO,
    CO2_LEVEL,
    CO2_ALARM,
    RGB_COLOR,
];
export const ACTIONS_CAPABILITIES = [
    RGB_COLOR_UP,
    DIMMER_UP,
    SWITCH,
    RGB_COLOR_DOWN,
    DIMMER_DOWN,
    SOUND_VOLUME,
    THERMOSTAT_MODE,
    THERMOSTAT_FAN_STATE,
    THERMOSTAT_FAN_MODE,
    THERMOSTAT_SETPOINT_HEATING,
    THERMOSTAT_SETPOINT_COOLING,
    THERMOSTAT_SETPOINT,
    SCENE,
    COLOR_TEMPERATURE,
    ECO,
    DIMMER_STOP,
    DIMMER,
    RGB_COLOR_STOP,
    RGB_COLOR,
    POWER,
    MUTE,
    SOUND_PLAYBACK,
    INPUT,
];

export const TRIGGERS_FRIENDLY_NAMES = {
    sound_volume: 'Volume level',
    switch: 'Turned on',
    thermostat_mode: 'Thermostat mode',
    thermostat_fan_state: 'Thermostat fan state',
    thermostat_fan_mode: 'Thermostat fan mode',
    temp: 'Temperature',
    thermostat_setpoint_heating: 'Target temperature for heat mode',
    thermostat_setpoint_cooling: 'Target temperature for cool mode',
    thermostat_setpoint: 'Target temperature',
    sound_playback: 'Sound playback status',
    scene: 'Scene set',
    power: 'Power status',
    mute: 'Muted',
    motion: 'Motion detected',
    color_temperature: 'Lightbulb color temperature',
    color_mode: 'Lightbulb color mode',
    input: 'Input',
    humidity: 'Humidity level',
    eco: 'Eco mode',
    dimmer: 'Dimmer level set to a level',
    co2_level: 'CO2 level',
    co2_alarm: 'CO2 alarm event',
    rgbcolor: 'Bulb color',
};

export const ACTIONS_FRIENDLY_NAMES = {
    rgbcolor_up: 'Turn up warmth by %',
    dimmer_up: 'Turn up dimmer by %',
    switch: 'Turn on',
    rgbcolor_down: 'Turn down warmth by %',
    dimmer_down: 'Turn down dimmer by %',
    sound_volume: 'Set volume',
    thermostat_mode: 'Set thermostat mode',
    thermostat_fan_state: 'Set thermostat fan state',
    thermostat_fan_mode: 'Set thermostat fan mode',
    thermostat_setpoint_heating: 'Set target temperature for heat mode',
    thermostat_setpoint_cooling: 'Set target temperature for cool mode',
    thermostat_setpoint: 'Set target temperature',
    scene: 'Set scene',
    color_temperature: 'Set lightbulb color temperature',
    eco: 'Set eco mode',
    dimmer_stop: 'Set dimmer to current level',
    dimmer: 'Set dimmer',
    rgbcolor_stop: 'Set color to current level',
    rgbcolor: 'Set bulb color',
    power: 'Power',
    mute: 'Mute',
    sound_playback: 'Manage playback',
    input: 'Input',
};
export const TURN_OFF = 'Turn off';
export const TURNED_OFF = 'Turned off';
export const CERTIFIED = 'certified';
export const COMMUNITY = 'community';
export const MEDIA = 'media';
export const CONFIRM_PLUGIN_INTEGRATION_UPDATE = 'CONFIRM_PLUGIN_INTEGRATION_UPDATE';
