import React from 'react';

export const IcVisualsPluginDownload = (props) => {
    return (
        <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0_13030_295)">
                <path
                    d="M128.571 66C109.118 66 92.4964 77.997 85.5786 94.9695C82.9786 94.272 80.2857 93.9 77.5 93.9C59.5321 93.9 45 108.455 45 126.45C45 144.446 59.5321 159 77.5 159H128.571C154.2 159 175 138.168 175 112.5C175 86.832 154.2 66 128.571 66Z"
                    fill="#3E46AE"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M157.977 148.477L108 98.5H101V159H128.571C139.727 159 149.969 155.053 157.977 148.477Z"
                    fill="#1F2581"
                />
                <path
                    d="M108.571 42C89.1179 42 72.4964 53.997 65.5786 70.9695C62.9786 70.272 60.2857 69.9 57.5 69.9C39.5321 69.9 25 84.4545 25 102.45C25 120.446 39.5321 135 57.5 135H108.571C134.2 135 155 114.168 155 88.5C155 62.832 134.2 42 108.571 42Z"
                    fill="#50C1D6"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M135.719 126.219L108 98.5H101V135H108.571C118.704 135 128.083 131.743 135.719 126.219Z"
                    fill="#38A7C1"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M92.6992 98H107.899V133.4H120.5L100.3 158.8L80 133.4H92.6992V98Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_13030_295">
                    <rect width="200" height="200" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
