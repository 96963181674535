import { isNonEmptyArray, isNonEmptyString } from './common';

/**
 * Returns Username by User's Personal Key from User Data Object from array of User Data Objects
 * @param {array} usersList - array of objects with User's Data
 * @param {string} PK_User - User's personal key
 * @returns {string} Username
 * */

export const getUsernameByPk = (usersList, PK_User) => {
    if (!isNonEmptyArray(usersList)) {
        throw new Error('#getUsernameByPk: usersList should be non empty array');
    }

    if (!isNonEmptyString(PK_User)) {
        throw new Error('#getUsernameByPk: PK_User should be non empty string');
    }

    const username = usersList.find((userData) => userData.PK_User === PK_User)?.Username;

    if (!username) {
        throw new Error(`#getUsernameByPk: user with PK: ${PK_User} in usersList hasn't got Username property`);
    }

    return username;
};

/**
 * Returns array of objects, each object contain next User's data: PK, UUID, Username
 * @param {Object} usersPkToUuidObj - Object with PK of User's as keys and UUID as values
 * @param {array} usersList - array of objects with User's Data
 * @returns {array} array with structured users data
 * */

export const createUsersDataArray = (usersPkToUuidObj, usersList) => {
    if (typeof usersPkToUuidObj !== 'object' || Array.isArray(usersPkToUuidObj) || usersPkToUuidObj === null) {
        throw new Error('#createUsersDataArray: usersPkToUuidObj should be an object');
    }

    if (!Object.values(usersPkToUuidObj).length) {
        throw new Error('#createUsersDataArray: usersPkToUuidObj should be non empty object');
    }

    return Object.entries(usersPkToUuidObj).map(([PK_User, uuid]) => ({
        PK_User,
        uuid,
        Username: getUsernameByPk(usersList, PK_User),
    }));
};
