import React from 'react';

export const IcWarning = (props) => {
    return (
        <svg width="112" height="112" viewBox="0 0 112 112" fill="none" {...props}>
            <path
                d="M56 104C82.5097 104 104 82.5097 104 56C104 29.4903 82.5097 8 56 8C29.4903 8 8 29.4903 8 56C8 82.5097 29.4903 104 56 104Z"
                stroke="#FE5C15"
                strokeWidth="16"
                strokeMiterlimit="10"
            />
            <path d="M56 62.5V26.5" stroke="#FE5C15" strokeWidth="16" strokeMiterlimit="10" />
            <path
                d="M56 86C60.4183 86 64 82.4183 64 78C64 73.5817 60.4183 70 56 70C51.5817 70 48 73.5817 48 78C48 82.4183 51.5817 86 56 86Z"
                fill="#FE5C15"
            />
            <path
                d="M110.3 69.8L96 55.5C96 55.7 96 55.8 96 56C96 76.3 80.9 93 61.3 95.6L74.5 108.8C92.1 102.7 105.7 88 110.3 69.8Z"
                fill="#E82E10"
            />
        </svg>
    );
};
