import React from 'react';

export const FunctionIcon = () => (
    <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.7438 2.41721C12.2943 2.29675 11.0161 3.2725 10.8843 4.60964L10.5549 8.09101H14.2709V10.5003H10.3177L9.73792 16.6077C9.67894 17.2379 9.48435 17.8512 9.16534 18.4124C8.84634 18.9736 8.40919 19.4717 7.87902 19.878C7.34884 20.2843 6.73608 20.5909 6.07593 20.7801C5.41578 20.9693 4.72124 21.0374 4.03221 20.9805C3.21489 20.9172 2.42496 20.6806 1.72506 20.2896C1.02515 19.8986 0.43452 19.3639 0 18.7279L1.97657 16.9209C2.29283 17.8124 3.16252 18.499 4.25622 18.5833C5.70571 18.7038 6.98389 17.728 7.11567 16.3909L7.68228 10.5003H3.72914V8.09101H7.9063L8.26208 4.3928C8.51244 1.74262 11.0688 -0.220923 13.9678 0.0200029C15.694 0.152512 17.1435 1.03189 18 2.27266L16.0234 4.0796C15.7072 3.18818 14.8375 2.50154 13.7438 2.41721Z"
            fill="#90A0AF"
        />
    </svg>
);

export default FunctionIcon;
