import React from 'react';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import { IconButton } from 'lib/@mui';
import { Edit } from 'lib/@mui/Icons';

import {
    DEVICE_GROUP_OTHERS_SUBCATEGORY_ID,
    DEVICE_GROUP_TREE_ITEM,
    TREE_ELEMENTS_STATE_OPTIONS_LIST,
    MENU_BUTTONS_STATES,
    PAYLOAD_STRUCTURE,
    CATEGORY,
    SUBCATEGORIES,
    SELECTED_ELEMENT_INDEX,
} from '../../../constants/DevicesGroups';
import {
    FULL_LIST_OF_CATEGORIES,
    BASIC_LIST_OF_CATEGORIES,
} from './components/DeviceGroupTreeContainerPriorityOrder/constants';
import { EMPTY_STRING, SINGLE_ZERO, PRIMARY } from '../../../constants/MeshbotConstant';
import { LARGE } from '../../../constants/DeviceAssociations';
import DeviceGroupDelete from './components/DeviceGroupDelete';
import MeshbotSerial from '../../../components/MeshbotSerial';
import { EZLOGIC_TITLE_DELETE, EZLOGIC_TITLE_EDGE_COMPUTER, EZLOGIC_TITLE_EDIT } from 'constants/language_tokens';
import { LISTING_PAGE } from './LanguageTokensConstants';
import { t } from '../../../helpers/language';

export const buildDeviceGroupsTableColumns = (...args) => {
    const [handleEditClick, handleDeleteClick] = args;

    return [
        {
            accessorKey: 'id',
            header: 'Id',
            size: 250,
            muiTableHeadCellProps: { align: 'center' },
            muiTableBodyCellProps: { align: 'center' },
            enableSorting: false,
            enableColumnActions: false,
        },
        {
            accessorKey: 'groupName',
            header: t(LISTING_PAGE),
            size: 270,
            muiTableHeadCellProps: { align: 'center' },
        },
        {
            accessorKey: 'serial',
            header: t(EZLOGIC_TITLE_EDGE_COMPUTER),
            size: 150,
            muiTableHeadCellProps: { align: 'center' },
            muiTableBodyCellProps: { align: 'center' },
            enableSorting: false,
            Cell: MeshbotSerial,
        },
        {
            accessorKey: 'edit',
            header: t(EZLOGIC_TITLE_EDIT),
            size: 90,
            muiTableHeadCellProps: { align: 'center' },
            muiTableBodyCellProps: { align: 'center' },
            enableSorting: false,
            enableColumnActions: false,
            Cell: ({ row }) => {
                return (
                    <IconButton size={LARGE} onClick={() => handleEditClick(row.original)}>
                        <Edit color={PRIMARY} />
                    </IconButton>
                );
            },
        },
        {
            accessorKey: 'delete',
            header: t(EZLOGIC_TITLE_DELETE),
            size: 90,
            muiTableHeadCellProps: { align: 'center' },
            muiTableBodyCellProps: { align: 'center' },
            enableSorting: false,
            enableColumnActions: false,
            Cell: ({ row }) => {
                const { groupName } = row?.original;

                return (
                    <DeviceGroupDelete name={groupName} onDeleteDeviceGroup={() => handleDeleteClick(row.original)} />
                );
            },
        },
    ];
};

const filterByDeviceGroups = (data) => {
    return Object.entries(data).filter(
        (elem) =>
            elem[SELECTED_ELEMENT_INDEX].hasOwnProperty('deviceGroups') &&
            Object.values(elem[SELECTED_ELEMENT_INDEX].deviceGroups).length,
    );
};

export const buildDeviceGroupsTableRows = (data) => {
    const filteredDeviceGroups = filterByDeviceGroups(data);
    const getDataForTableRows = filteredDeviceGroups
        ?.map(([serial, dataBySerial]) =>
            dataBySerial.deviceGroups.deviceGroupsList.map((deviceGroupItem) => {
                return {
                    id: deviceGroupItem._id,
                    groupName: deviceGroupItem.name,
                    serial: serial,
                };
            }),
        )
        .flat();

    return getDataForTableRows;
};

export const buildDeviceGroupObject = () => {
    return {
        name: '',
        categories: [],
        devices: [],
        exceptions: [],
    };
};

export const setCategoriesValues = (categories, devicesList) => {
    Object.entries(categories).forEach(([categoryName, categoryValue]) => {
        const { subcategories } = categoryValue;

        const devicesListByCategory = devicesList.filter((device) => {
            return device?.category === categoryName;
        });

        setSubcategoriesDevices(subcategories, devicesListByCategory);
    });
};

export const setSubcategoriesDevices = (subcategories, devicesList) => {
    Object.entries(subcategories).forEach(([subcategoryName, subcategoryValue]) => {
        const filteredDevices = devicesList.filter((device) => {
            if (subcategoryName === DEVICE_GROUP_OTHERS_SUBCATEGORY_ID && device.subcategory === '') {
                return device;
            }

            return device.subcategory === subcategoryName;
        });

        subcategoryValue.devices = assignFilteredDevicesToSubcategory(filteredDevices, subcategoryValue);
    });
};

export const assignFilteredDevicesToSubcategory = (filteredDevices, subcategoryValue) => {
    return filteredDevices.reduce((devicesContainer, device) => {
        devicesContainer[device._id] = {
            id: device._id,
            device_name: device.name,
            subcategory_name: subcategoryValue.id,
            _ui: {
                type: DEVICE_GROUP_TREE_ITEM.DEVICE.TYPE,
                state: DEVICE_GROUP_TREE_ITEM.DEVICE.STATES.INHERITED,
            },
        };

        return devicesContainer;
    }, {});
};

export const setInitialDeviceGroupTreeValues = (props) => {
    setInitialCategoriesDeviceGroupTreeValues(props);
};

export const setInitialCategoriesDeviceGroupTreeValues = (props) => {
    const { categories, devices, exceptions, newDeviceGroupTreeState } = props;

    Object.entries(newDeviceGroupTreeState.categories).forEach(([categoryName, categoryValue]) => {
        const selectedCategory = categories?.find(
            (category) => category.category === categoryName && !category.hasOwnProperty('subcategories'),
        );

        if (selectedCategory) {
            categoryValue._ui.state = DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.INCLUDED;
        }

        setInitialSubCategoriesDeviceGroupTreeValues({
            categoryValue,
            categories,
            devices,
            exceptions,
            selectedCategory,
        });
    });
};

const setInitialSubCategoriesDeviceGroupTreeValues = (props) => {
    const { categoryValue, devices, exceptions, selectedCategory, categories } = props;
    const { subcategories } = categoryValue;

    Object.entries(subcategories).forEach(([subcategoryName, subcategoryValue]) => {
        const selectedSubcategory = categories?.find(
            (category) =>
                category.hasOwnProperty('subcategories') &&
                category.subcategories?.find((subcategory) => subcategory === subcategoryName),
        );

        if (selectedSubcategory) {
            subcategoryValue._ui.state = DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INCLUDED;
        }

        if (selectedCategory) {
            subcategoryValue._ui.state = DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED_INCLUDED;
        }

        const { devices: existingDevices } = subcategoryValue;

        existingDevices &&
            Object.values(existingDevices).find((device) => {
                const isIncluded = devices?.find((includedId) => includedId === device.id);
                if (isIncluded) {
                    device._ui.state = DEVICE_GROUP_TREE_ITEM.DEVICE.STATES.INCLUDED;
                }
            });

        existingDevices &&
            Object.values(existingDevices).find((device) => {
                const isExcluded = exceptions?.find((includedId) => includedId === device.id);
                if (isExcluded) {
                    device._ui.state = DEVICE_GROUP_TREE_ITEM.DEVICE.STATES.EXCLUDED;
                }

                if (!isExcluded && (selectedCategory || selectedSubcategory)) {
                    device._ui.state = DEVICE_GROUP_TREE_ITEM.DEVICE.STATES.INHERITED_INCLUDED;
                }
            });
    });
};

export const getTreeElementsStateCategoryOptions = () => [
    {
        value: DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.EXCLUDED,
        name: TREE_ELEMENTS_STATE_OPTIONS_LIST[DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.EXCLUDED],
    },
    {
        value: DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.INCLUDED,
        name: TREE_ELEMENTS_STATE_OPTIONS_LIST[DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.INCLUDED],
    },
];

export const getTreeElementsStateSubcategoryOptions = (categoryState) => {
    switch (categoryState) {
        case DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.EXCLUDED:
            return [
                {
                    value: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    name: TREE_ELEMENTS_STATE_OPTIONS_LIST[DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED],
                },
                {
                    value: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INCLUDED,
                    name: TREE_ELEMENTS_STATE_OPTIONS_LIST[DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INCLUDED],
                },
            ];
        case DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.INCLUDED:
            return [
                {
                    value: DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
                    name: TREE_ELEMENTS_STATE_OPTIONS_LIST[DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED],
                },
            ];
        default:
            return [];
    }
};

export const getTreeElementsStateDeviceOptions = (
    categoryState,
    subcategoryState = DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED,
) => {
    if (
        categoryState === DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.EXCLUDED &&
        subcategoryState === DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED
    ) {
        return [
            {
                value: DEVICE_GROUP_TREE_ITEM.DEVICE.STATES.INHERITED,
                name: TREE_ELEMENTS_STATE_OPTIONS_LIST[DEVICE_GROUP_TREE_ITEM.DEVICE.STATES.INHERITED],
            },
            {
                value: DEVICE_GROUP_TREE_ITEM.DEVICE.STATES.INCLUDED,
                name: TREE_ELEMENTS_STATE_OPTIONS_LIST[DEVICE_GROUP_TREE_ITEM.DEVICE.STATES.INCLUDED],
            },
        ];
    }

    if (
        categoryState === DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.EXCLUDED &&
        subcategoryState === DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INCLUDED
    ) {
        return [
            {
                value: DEVICE_GROUP_TREE_ITEM.DEVICE.STATES.INHERITED,
                name: TREE_ELEMENTS_STATE_OPTIONS_LIST[DEVICE_GROUP_TREE_ITEM.DEVICE.STATES.INHERITED],
            },
            {
                value: DEVICE_GROUP_TREE_ITEM.DEVICE.STATES.EXCLUDED,
                name: TREE_ELEMENTS_STATE_OPTIONS_LIST[DEVICE_GROUP_TREE_ITEM.DEVICE.STATES.EXCLUDED],
            },
        ];
    }

    if (
        (categoryState === DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.INCLUDED &&
            subcategoryState === DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED) ||
        (categoryState === DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.INCLUDED &&
            subcategoryState === DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED_INCLUDED)
    ) {
        return [
            {
                value: DEVICE_GROUP_TREE_ITEM.DEVICE.STATES.INHERITED,
                name: TREE_ELEMENTS_STATE_OPTIONS_LIST[DEVICE_GROUP_TREE_ITEM.DEVICE.STATES.INHERITED],
            },
            {
                value: DEVICE_GROUP_TREE_ITEM.DEVICE.STATES.EXCLUDED,
                name: TREE_ELEMENTS_STATE_OPTIONS_LIST[DEVICE_GROUP_TREE_ITEM.DEVICE.STATES.EXCLUDED],
            },
        ];
    }

    return [];
};

export const getMenuButtonState = (treeElementState) => {
    if (!treeElementState) {
        return;
    }

    if (
        treeElementState === DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.EXCLUDED ||
        treeElementState === DEVICE_GROUP_TREE_ITEM.DEVICE.STATES.EXCLUDED
    ) {
        return MENU_BUTTONS_STATES.EXCLUDED;
    }

    if (
        treeElementState === DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.INCLUDED ||
        treeElementState === DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INCLUDED ||
        treeElementState === DEVICE_GROUP_TREE_ITEM.DEVICE.STATES.INCLUDED
    ) {
        return MENU_BUTTONS_STATES.INCLUDED;
    }

    if (
        treeElementState === DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED ||
        treeElementState === DEVICE_GROUP_TREE_ITEM.DEVICE.STATES.INHERITED
    ) {
        return MENU_BUTTONS_STATES.INHERITED;
    }

    if (
        treeElementState === DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INHERITED_INCLUDED ||
        treeElementState === DEVICE_GROUP_TREE_ITEM.DEVICE.STATES.INHERITED_INCLUDED
    ) {
        return MENU_BUTTONS_STATES.INHERITED_INCLUDED;
    }
};

const buildInitialDeviceGroupPayload = (name, id) => {
    if (id) {
        return {
            [PAYLOAD_STRUCTURE.ID]: id,
            [PAYLOAD_STRUCTURE.NAME]: name,
            [PAYLOAD_STRUCTURE.CATEGORIES]: [],
            [PAYLOAD_STRUCTURE.DEVICES]: [],
            [PAYLOAD_STRUCTURE.EXCEPTIONS]: [],
        };
    }

    return {
        [PAYLOAD_STRUCTURE.NAME]: name,
        [PAYLOAD_STRUCTURE.CATEGORIES]: [],
        [PAYLOAD_STRUCTURE.DEVICES]: [],
        [PAYLOAD_STRUCTURE.EXCEPTIONS]: [],
    };
};

const buildDeviceGroupPayloadByDevice = (device, payload) => {
    if (device._ui.state === DEVICE_GROUP_TREE_ITEM.DEVICE.STATES.INCLUDED) {
        payload.devices.push(device.id);
    }

    if (device._ui.state === DEVICE_GROUP_TREE_ITEM.DEVICE.STATES.EXCLUDED) {
        payload.exceptions.push(device.id);
    }
};

const buildDeviceGroupPayloadBySubcategory = (subcategory, payload) => {
    Object.values(subcategory.devices).forEach((device) => {
        buildDeviceGroupPayloadByDevice(device, payload);
    });
};

const buildDeviceGroupPayloadByCategory = (category, payload) => {
    if (category._ui.state === DEVICE_GROUP_TREE_ITEM.CATEGORY.STATES.INCLUDED) {
        payload.categories.push({
            [CATEGORY]: category.id,
        });
    }

    const selectedSubcategories = [];

    Object.values(category.subcategories).forEach((subcategory) => {
        if (subcategory._ui?.state === DEVICE_GROUP_TREE_ITEM.SUBCATEGORY.STATES.INCLUDED) {
            selectedSubcategories.push(subcategory.id);
        }
    });

    if (selectedSubcategories.length) {
        payload.categories.push({
            [CATEGORY]: category.id,
            [SUBCATEGORIES]: selectedSubcategories,
        });
    }

    Object.values(category.subcategories).forEach((subcategory) => {
        buildDeviceGroupPayloadBySubcategory(subcategory, payload);
    });
};

const buildDeviceGroupPayloadByDeviceGroupTree = (tree, payload) => {
    if (!tree || !payload) {
        throw new Error('No data!');
    }

    Object.values(tree.categories).forEach((category) => {
        buildDeviceGroupPayloadByCategory(category, payload);
    });
};

export const buildDeviceGroupPayload = (tree, name, id) => {
    const payload = buildInitialDeviceGroupPayload(name, id);

    buildDeviceGroupPayloadByDeviceGroupTree(tree, payload);

    return payload;
};

/**
 * The function prepares a list of integrated devices for UI
 * @param {object} data - the list of integrated devices
 * @returns {array} - the prepared list of integrated devices for UI
 */
export const prepareListIntegratedDevicesForUI = (data) => {
    if (!data) {
        throw new Error('No data available');
    }

    const listIntegratedDevices = data.devices.map((device) => ({
        manufacturer: data.manufacturers[device.manufacturerId],
        category: data.categories[device.categoryId].name,
        subcategory:
            device.subcategoryId === SINGLE_ZERO
                ? EMPTY_STRING
                : data.categories[device.categoryId].subcategories[device.subcategoryId],
        name: device.name,
        model: device.model,
        id: `${device.manufacturerId}_${device.variables.productType}_${device.deviceId}`,
    }));

    return listIntegratedDevices;
};

/**
 * The function converts the full list of device tree categories into a reduced list .
 * @param {object} categories - category list
 * @returns {object} - the reduced list of categories
 */
export const prepareReducedListOfCategories = (categories) => {
    const orderedCategories = {};

    Object.values(BASIC_LIST_OF_CATEGORIES).forEach((categoryName) => {
        if (categories?.hasOwnProperty(categoryName)) {
            orderedCategories[categoryName] = { ...categories[categoryName] };
        }
    });

    return orderedCategories;
};

/**
 * The function changes the version of the displayed list of categories (full or reduced) of the device tree
 * @param {object} deviceGroupTreeState - device tree with a full list of categories
 * @param {boolean} isShowMore - button state Show more/Show less
 * @returns {object} - device tree with a full or reduced list of categories
 */
export const changeCategoryListOption = (deviceGroupTreeState, isShowMore) => {
    if (!isShowMore) {
        const reducedCategoriesList = prepareReducedListOfCategories(deviceGroupTreeState?.categories);

        return {
            ...deviceGroupTreeState,
            categories: reducedCategoriesList,
        };
    }

    return deviceGroupTreeState;
};

/**
 * The function sorts the categories of the device tree according to the set priorities
 * @param {object} categories - category list
 * @returns {array} - sorted list of categories
 */
export const sortCategoriesByPriority = (categories) => {
    if (categories) {
        const categoriesEntries = Object.entries(categories);
        const categoryIndixes = {};

        FULL_LIST_OF_CATEGORIES.forEach((item) => {
            categoryIndixes[item.categoryName] = item.priorityIndex;
        });

        categoriesEntries.sort(([aCategoryName], [bCategoryName]) => {
            const aIndex = categoryIndixes[aCategoryName];
            const bIndex = categoryIndixes[bCategoryName];

            return aIndex - bIndex;
        });

        return categoriesEntries;
    }

    return [];
};

/**
 * This function compares device group payloads (initial and current)
 * @param {*} obj1 - initial device group payload
 * @param {*} obj2 - current device group payload
 * @returns {boolean}
 */
export const compareDeviceGroupPayloads = (obj1, obj2) => {
    const propertiesToCompare = [PAYLOAD_STRUCTURE.CATEGORIES, PAYLOAD_STRUCTURE.DEVICES, PAYLOAD_STRUCTURE.EXCEPTIONS];

    return isEqual(pick(obj1, propertiesToCompare), pick(obj2, propertiesToCompare));
};
