import React from 'react';

const IcTestModeTurnedOn = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.25 4.09677L16 3L13.75 4.09677L15.1271 6.77984L16 6.35436L16.8729 6.77984L18.25 4.09677ZM12.25 4.82795L13.6271 7.51102L9.12713 9.70456L7.75 7.02149L12.25 4.82795ZM4 14.1317H7.07841V17.3011C7.07841 18.1845 7.21254 19.0319 7.45994 19.828L4.51369 20.6976C4.17962 19.6227 4 18.4823 4 17.3011V14.1317ZM5.41458 22.8159L8.12852 21.4C8.96099 22.9153 10.242 24.1643 11.7958 24.9759L10.3416 27.62C8.25268 26.5289 6.53345 24.8526 5.41458 22.8159ZM12.5149 28.4988L13.4081 25.6279C14.2247 25.8692 15.0939 26 16 26C16.9061 26 17.7753 25.8692 18.5919 25.6279L19.4851 28.4988C18.3822 28.8248 17.212 29 16 29C14.788 29 13.6178 28.8248 12.5149 28.4988ZM21.6584 27.62L20.2042 24.9759C21.758 24.1643 23.039 22.9153 23.8715 21.4L26.5854 22.8159C25.4665 24.8526 23.7473 26.5289 21.6584 27.62ZM28 12.0188H24.9216V10.7032L24.3729 10.4357L25.75 7.75267L28 8.84943V12.0188ZM24.25 7.02149L22.8729 9.70456L18.3729 7.51102L19.75 4.82795L24.25 7.02149ZM28 14.1317H24.9216V17.3011C24.9216 18.1845 24.7875 19.0319 24.5401 19.828L27.4863 20.6976C27.8204 19.6227 28 18.4823 28 17.3011V14.1317ZM4 12.0188H7.07841V10.7032L7.62713 10.4357L6.25 7.75266L4 8.84943V12.0188Z"
                fill="#1C1E21"
            />
            <path d="M16 10L10 13V17C10 21 14 23 16 23V10Z" fill="#1C1E21" />
        </svg>
    );
};

export default IcTestModeTurnedOn;
