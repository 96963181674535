import update from 'immutability-helper';
import at from '../constants/ActionTypesAutomation';
import { LOGOUT } from '../constants/ActionTypes';

const defaultState = {
    groupRules: [],
    groupsInfo: [],
    groupActions: [],
    savedGroup: [],
    savedActions: [],
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case at.CREATE_ITEM.success:
            return update(state, {
                groupRules: { $push: [action.data] },
            });
        case at.DELETE_ITEM.success:
            return update(state, {
                groupRules: { $set: action.data },
            });

        case at.CREATE_ITEM_IN_GROUP.success:
            return update(state, {
                groupRules: { $set: action.data },
            });
        case at.DELETE_ITEM_IN_GROUP.success:
            return update(state, {
                groupRules: { $set: action.data },
            });
        case at.DELETE_GROUP_INFO.success:
            return update(state, {
                groupsInfo: { $set: action.data },
            });
        case at.CHANGE_TO_GROUP.success:
            return update(state, {
                groupRules: { $set: action.data },
            });

        case at.UPDATE_ITEM.success:
            return update(state, {
                groupRules: { $set: action.data },
            });
        case at.CREATE_ITEM_SELECT.success:
            return update(state, {
                groupRules: { $set: action.data },
            });
        case at.GROUP_INFO.success:
            return update(state, {
                groupsInfo: { $set: action.data },
            });
        case at.CREATE_ACTION.success:
            return update(state, {
                groupActions: { $set: action.data },
            });
        case at.CREATE_ACTION_GROUP_TRIGGER.success:
            return update(state, {
                groupActions: { $set: action.data },
            });
        case at.UPDATE_ACTION_FIELD.success:
            return update(state, {
                groupActions: { $set: action.data },
            });
        case at.UPDATE_FIELD_ACTION.success:
            return update(state, {
                groupActions: { $set: action.data },
            });
        case at.COPE_ACTION.success:
            return update(state, {
                groupActions: { $set: action.data },
            });
        case at.SAVED_GROUP.success:
            return update(state, {
                savedGroup: { $set: action.data },
            });
        case at.SAVED_ACTIONS.success:
            return update(state, {
                savedActions: { $set: action.data },
            });
        case LOGOUT:
            return defaultState;

        default:
            return state;
    }
};
