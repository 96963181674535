import React from 'react';

const IcModesAway = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="#1C1E21" {...props}>
            <path d="M23.55 7.11L26 12H6L8.45 7.11C8.79 6.43 9.48 6 10.24 6H21.77C22.52 6 23.21 6.43 23.55 7.11ZM29 19V24V27H25C23.9 27 23 26.1 23 25V24H9V25C9 26.1 8.1 27 7 27H3V24V19C3 16.79 4.79 15 7 15H25C27.21 15 29 16.79 29 19ZM9 19C9 17.9 8.1 17 7 17C5.9 17 5 17.9 5 19C5 20.1 5.9 21 7 21C8.1 21 9 20.1 9 19ZM21 18H11C11 19.1 11.9 20 13 20H19C20.1 20 21 19.1 21 18ZM23 19C23 20.1 23.9 21 25 21C26.1 21 27 20.1 27 19C27 17.9 26.1 17 25 17C23.9 17 23 17.9 23 19ZM29 9C27.34 9 26 10.34 26 12H30V9H29ZM3 9H2V12H6C6 10.34 4.66 9 3 9Z" />
        </svg>
    );
};

export default IcModesAway;
