import React from 'react';
import { getContrast } from '../../utils';
import classname from 'classnames';

import styles from './styles.module.scss';

const MeshBotLabelInTableCell = ({ name, backgroundColor, className }) => {
    const color = getContrast(backgroundColor);

    return (
        <div
            title={name}
            style={{ backgroundColor, color }}
            className={classname(styles.label, { [className]: className })}
        >
            {name}
        </div>
    );
};

export default MeshBotLabelInTableCell;
