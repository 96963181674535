import { TABLE_LOCALIZATION_TOKENS } from '../constants';

export const getTableLocalization = (t, customLocalization = {}) => {
    const localization = {};
    for (const key in TABLE_LOCALIZATION_TOKENS) {
        localization[key] = t(TABLE_LOCALIZATION_TOKENS[key]);
    }

    return { ...localization, ...customLocalization };
};
