import React from 'react';

const IcDeviceSensorTemperature = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="#1C1E21" {...props}>
            <path d="M22 15.3V7C22 3.7 19.3 1 16 1C12.7 1 10 3.7 10 7V15.3C8.9 16.3 8 17.6 7.5 19H10.8C11.1 18.5 11.5 18 12 17.5L13 16.6V15.3V7C13 5.3 14.3 4 16 4C17.7 4 19 5.3 19 7V15.3V16.6L20 17.5C21.3 18.6 22 20.3 22 22H14.9H10H7C7 27 11 31 16 31C21 31 25 27 25 22C25 19.3 23.8 17 22 15.3Z" />
        </svg>
    );
};

export default IcDeviceSensorTemperature;
