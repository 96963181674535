import React from 'react';

const IcClimateThermostat = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
            <path
                d="M26 7.7C26 7.8 26 7.9 26 8C26 9.1 25.1 10 24 10C22.9 10 22 9.1 22 8C22 6.9 22.9 6 24 6C24.1 6 24.2 6 24.3 6C22 4.1 19.1 3 16 3C8.8 3 3 8.8 3 16C3 23.2 8.8 29 16 29C23.2 29 29 23.2 29 16C29 12.8 27.9 9.9 26 7.7ZM21 14H17.5V21H14.5V14H11V11H14.5H17.5H21V14Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcClimateThermostat;
