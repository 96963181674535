import * as types from '../constants/ActionTypes/Groups';
import * as ezloGroupTypes from '../constants/ActionTypes/EzloGroup';
import produce from 'immer';
import { LOGOUT } from '../constants/ActionTypes';

const INITIAL_STATE = {
    groups: {},
    selectedGroup: null,
    isGroupsLoading: true,
    fetchGroupsError: null,
    groupUUIDToDelete: null,
    controllerGroupReport: {},
    ezlos: [],
    allControllersInfo: [],
};

export default produce((draft, action) => {
    switch (action.type) {
        case types.GROUPS_FETCH_CONTROLLER_GROUP_LIST: {
            draft.isGroupsLoading = true;
            draft.groups = {};
            draft.fetchGroupsError = null;
            draft.ezlos = [];
            break;
        }
        case types.GROUPS_FETCH_CONTROLLER_GROUP_LIST_ON_SUCCESS: {
            draft.isGroupsLoading = false;
            draft.groups = action.groups;
            if (!draft.selectedGroup) {
                // eslint-disable-next-line
                draft.selectedGroup = Object.values(action.groups)[0];
            }
            break;
        }
        case types.GROUPS_FETCH_CONTROLLER_GROUP_LIST_ON_ERROR: {
            draft.isGroupsLoading = false;
            draft.groups = {};
            draft.ezlos = [];
            draft.fetchGroupsError = action.error;
            break;
        }
        case types.GROUPS_FETCH_ACCOUNT_EZLOS_SUCCESS: {
            draft.ezlos = action.ezlos;
            break;
        }
        case types.SET_ALL_CONTROLLERS_INFORMATION_TO_GROUPS_STATE: {
            draft.allControllersInfo = action.allControllersInfo;
            break;
        }
        case types.GROUPS_FETCH_ACCOUNT_EZLOS_ERROR: {
            draft.ezlos = [];
            break;
        }
        case ezloGroupTypes.SELECT_GROUP: {
            draft.selectedGroup = action.group;
            break;
        }
        case types.GROUPS_CONTROLLER_GET_REPORT_ON_SUCCESS: {
            draft.controllerGroupReport = action.payload.data;
            break;
        }
        case types.CLEAR_GROUPS_STATE_FROM_REMOVED_CONTROLLER: {
            draft.ezlos = draft.ezlos.filter(({ serial }) => serial !== action.serial);
            draft.allControllersInfo = draft.allControllersInfo.filter(({ serial }) => serial !== action.serial);
            if (action.groupKey && draft.groups[action.groupKey]) {
                draft.groups[action.groupKey].controllers = draft.groups[action.groupKey].controllers.filter(
                    ({ id }) => id !== action.serial,
                );
                draft.selectedGroup = {
                    ...draft.selectedGroup,
                    controllers: draft.selectedGroup.controllers.filter(({ id }) => id !== action.serial),
                };
            }
            break;
        }
        case LOGOUT: {
            return INITIAL_STATE;
            break;
        }
    }
}, INITIAL_STATE);
