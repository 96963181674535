import React from 'react';

const IcModesGoodNight = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="#1C1E21" {...props}>
            <path d="M24.3 20.7001C17.1 20.7001 11.3 14.9001 11.3 7.7001C11.3 6.3001 11.5 4.9001 12 3.6001C6.7 5.4001 3 10.2001 3 16.0001C3 23.2001 8.8 29.0001 16 29.0001C21.8 29.0001 26.6 25.3001 28.3 20.1001C27.1 20.5001 25.7 20.7001 24.3 20.7001Z" />
        </svg>
    );
};

export default IcModesGoodNight;
